<template>
  <v-container fluid>
    <div>
      <v-col cols="11">
        <div class="header-title">MTM Allergies</div>
        <div class="contact-sub-title mg-top-15">
          Enter the beneficiary's allergies in this field Record the allergen
          and note what happaned to the beneficiary. The sponsor should also
          include significant non-drug allergies (such as anaphylactic reactions
          to shellfish or peanuts)
        </div>
        <div class="mg-top-4rm"></div>
        <div class="title-bold">Known Allergies</div>
        <div class="contact-sub-title">
          Below is a list of allergies which are known from the member's
          profile. Any changes to the list will also populate the member's
          profile Deactive and Activete shall only effect the MTM and shall not
          change the data for the member profile.
        </div>
        <v-row v-if="selectisReview">
          <v-col cols="12" class="mg-left-10 mg-right-15">
            <div class="review-cart-bg-color">
              <div class="header-quality-control">Quality Control Review</div>
              <v-row class="mg-top-5">
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="text-align-inherit fs-14"
                      >{{ "Submitted for Review date:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="text-align-inherit fs-14"
                  v-if="smtForReviewDate !== null"
                  >{{smtForReviewDate }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Clinician:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ "DR. "+selectClinicianName }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Member:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ memberName }}</v-col
                >
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="pd-10"
          v-if="selectisReview === null || selectisReview === false">
          <v-col
            cols="2"
            class="mouse-cursor"
            @click="handleAddAllergy"
            :class="{ 'disabled-element': itemEdit, 'gray-primary': itemEdit}"
          >
            <v-row>
              <v-icon
                size="27"
                class="fs-14"
                :class="{ 'gray-primary': itemEdit, 'blue-color': itemEdit == false}"
              >
                mdi-plus
              </v-icon>
              <div
                class="primary-color mg-top-5 fs-14"
                :class="{ 'gray-primary': itemEdit}"
              >
                ADD ALLERGY
              </div>
            </v-row>
          </v-col>
        </v-row>
        <div v-for="(item, index) in allergiesForm" :key="index">
          <v-card class="mg-top-30 card-border-blue">
            <v-row class="mg-top-0">
              <v-col cols="6" class="allergy-card">
                <v-col cols="6">
                  <v-select
                    :items="allergiesCodeList"
                    item-value="codeValue"
                    v-model="item.allergyCode"
                    @update:modelValue="handleAllergiesCode(index, $event, 'allegyForm')"
                    hide-details="auto"
                    item-title="shortDesc"
                    variant="outlined"
                    density="comfortable"
                    label="ALLERGY"
                    :error="item.allergyError.isError"
                    :error-messages="item.allergyError.errorMessage"
                  />
                </v-col>
              </v-col>
              <v-col cols="6" class="pd-25">
                <v-col cols="10">
                  <v-text-field
                    label="NOTES"
                    @input="handleAllergiesNotes(index, $event, 'allegyForm')"
                    v-model="item.notes"
                    :error="item.notesError.isError"
                    :error-messages="item.notesError.errorMessage"
                    variant="outlined"
                    density="comfortable"
                  >
                  </v-text-field>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-row>
                <v-col cols="4">
                  <v-btn color="black" variant="text" @click="handleCancelAllergy(index)">
                    Cancel
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="black"
                    variant="text"
                    @click="handleClearAllergy(index, 'allegyForm')"
                  >
                    Clear
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="blue"
                    variant="text"
                    @click="handleMtmAddAllergies(index)"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-if="allegyList !== undefined" :class="{ 'mg-left-30': selectisReview, 'mg-right-30': selectisReview}">
          <div
            v-for="(allegyItem, index) in allegyList"
            :key="index"
            class="mg-top-20"
          >
          <v-card class="mg-bottom-40 card-border-blue" height="100%" :class="{ 'card-border-blue': allegyItem.isActive, 'card-border-gray': !allegyItem.isActive}">
              <v-row class="mg-top-0 pd-10" style="padding: 0px;">
                <v-col cols="6" class="border-right pd-30">
                  <div v-if="allegyItem.isEdit" class="mg-left-20">
                    <v-col cols="6">
                      <v-select
                        :items="allergiesCodeList"
                        v-model="allegyItem.allergyCode"
                        item-value="codeValue"
                        @update:modelValue="handleAllergiesCode(index, $event, 'allergiesList')"
                        hide-details="auto"
                        item-title="shortDesc"
                        variant="outlined"
                        density="comfortable"
                        label="Select Allergy"
                        class="mg-left-5"
                      />
                    </v-col>
                  </div>
                  <div v-else>
                    <v-row>
                      <div class="title-bold mg-left-5">ALLERGY</div>
                    </v-row>
                    <v-row class="pd-top-5">
                      <div class="mg-left-20">{{allegyItem.newDescription}}</div>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="6" class="pd-15">
                  <v-row>
                    <v-col cols="11" class="pd-30">
                      <v-row v-if="allegyItem.isEdit == false">
                        <div class="title-bold mg-left-5">NOTES</div>
                      </v-row>
                      <v-row class="pd-top-5">
                        <div
                          class="mg-left-30"
                          v-if="allegyItem.isEdit == false"
                        >
                          {{allegyItem.notes}}
                        </div>
                        <v-col cols="11" v-if="allegyItem.isEdit">
                          <v-text-field
                            label="NOTES"
                            class="mg-left-5"
                            v-model="allegyItem.notes"
                            @input="handleAllergiesNotes(index, $event, 'allergiesList')"
                            variant="outlined"
                            density="comfortable"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-menu
                        bottom
                        left
                        v-if="(selectisReview === false || selectisReview === null) && (itemEdit==false || isConfirm)"
                      >
                        <template v-slot:activator="{ props }">
                          <v-icon
                            dark
                            icon
                            v-bind="props"
                            class="mg-right-20"
                            size="20"
                            color="black"
                          >
                            mdi-dots-vertical</v-icon
                          >
                        </template>
                        <v-list>
                          <v-list-item
                            class="dot-item-list"
                            v-if="allegyItem.activeStatus === 'active'"
                          >
                            <v-list-item-title
                              @click="handleEditAllergy(index)"
                              >{{ "Edit" }}</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            class="dot-item-list"
                            v-if="allegyItem.activeStatus === 'active'"
                          >
                            <v-list-item-title
                              @click="handleActAndDeactive(index, 'deactive')"
                              >{{ "Deactivate" }}</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            class="dot-item-list"
                            v-if="allegyItem.activeStatus === 'deactive'"
                          >
                            <v-list-item-title
                              @click="handleActAndDeactive(index, 'active')"
                              >{{ "Active" }}</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item class="dot-item-list">
                            <v-list-item-title
                              @click="handleDeleteAllergy(allegyItem.id)"
                              >{{ "Delete" }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <v-row v-if="allegyItem.isEdit">
              <v-spacer></v-spacer>
              <v-col cols="3">
                <v-row>
                  <v-col cols="4">
                    <v-btn
                      color="black"
                      variant="text"
                      @click="handleCancelAllergy(index, 'allegyList')"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="black"
                      variant="text"
                      @click="handleClearAllergy(index, 'allegyList')"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="blue"
                      variant="text"
                      @click="handleAllergyUpdate(index)"
                      :disabled="(allegyItem.allergyCode == '' || allegyItem.notes == '')"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="selectisReview !== null || selectisReview">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset"
                v-if="!allegyItem.isShowReviewComment"
              >
                <v-col cols="9"></v-col>
                <v-col
                  cols="3"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isCommentAdd}"
                  @click="handleIsReviewerComment(index)"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isCommentAdd, 'blue-color': isCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isCommentAdd}">ADD REVIEWER COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30" v-if="allegyItem.isShowReviewComment">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  auto-grow
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="allegyItem.remark"
                  @input="changeCommentReview($event, index)"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview) && !allegyItem.isReviewComment"
                  :readonly="!selectisReview"
                  label="QC Reviewer Comment"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview !== null || selectisReview) && allegyItem.isShowReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReviewComment(index)" 
                    v-if="allegyItem.isShowReviewBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReviewComment(index)"
                    v-if="allegyItem.isShowReviewBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReviewComment(index)"
                    v-if="allegyItem.isReviewComment && allegyItem.isShowReviewBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                  v-if="allegyItem.isReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReviewComment(index)"
                    >{{allegyItem.isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            <div v-if="selectisReview === null || selectisReview === false">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset"
                v-if="allegyItem.isShowReviewComment && allegyItem.isShowReplyReviewComment === false"
              >
                <v-col cols="8"></v-col>
                <v-col
                  cols="4"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isReplyCommentAdd}"
                  @click="handleIsReplyReviewerComment(index)"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isReplyCommentAdd, 'blue-color': isReplyCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isReplyCommentAdd}">ADD REPLY FOR THE ABOVE COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30" v-if="(selectisReview && allegyItem.isShowReviewComment && allegyItem.isShowReplyReviewComment) || (allegyItem.isShowReplyReviewComment)">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  auto-grow
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="allegyItem.remarkComment"
                  @input="changeReplyCommentReview($event, index)"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview === true) && !allegyItem.isReplyReviewComment"
                  :readonly="selectisReview"
                  label="Reply For QC Reviewer Comment"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview === null || selectisReview === false) && allegyItem.isShowReplyReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReplyReviewComment(index)" 
                    v-if="allegyItem.isShowReviewReplyBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReplyReviewComment(index)"
                    v-if="allegyItem.isShowReviewReplyBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReplyReviewComment(index)"
                    v-if="allegyItem.isReplyReviewComment && allegyItem.isShowReviewReplyBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                  v-if="allegyItem.isReplyReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReplyComment(index)"
                    >{{allegyItem.isShowReviewReplyBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="selectisReview !== null || selectisReview">
          <div class="mg-top-6rm txt-align-center fs-25 font-weight-400">
            Document to Review
          </div>
          <div class="mg-top-20">
            <iframe
              id="pdf"
              :src="url"
              frameborder="1"
              scrolling="auto"
              width="100%"
              height="600px"
              class="pdf-viewer"
            ></iframe>
          </div>
        </div>
      </v-col>
    </div>
    <AlertDialogs
      v-if="isHideShowAlertDialog"
      :alert-type="alertType"
      :alert-message="alertMessage"
      :alert-message-type="alertMessageType"
      @closeDialog="closeAlertDialog"
      @onConfirmOK="handleAlertConfirmation"
    />
    <v-snackbar
      v-model="snackbar"
      v-bind:color="snackColor"
      :timeout="snackTimeout"
    >
      <span
        :class="{ 'black--text': snackColor== '#CDDC39', 'white--text': snackColor== '#DD2C00' }"
        class=""
      >
        {{snackStatus}}
      </span>
    </v-snackbar>
  </v-container>
</template>
<script>
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import axios from 'axios'
import store from '../store'
import interceptorSetup from '../helpers/httpInterceptor'
export default {
  name: "MtmAllergies",
  components: {
    AlertDialogs
  },
  data() {
    return {
      gray: '#E1DA9B',
      alertType: '',
      alertMessage: '',
      confirmType: '',
      pageReload: false,
      isHideShowAlertDialog: false,
      clientData: null,
      memberId : null,
      documentId: null,
      alertMessageType: "",
      allergiesCodeList: undefined,
      allergiesForm: [],
      allegyList: undefined,
      snackbar: false,
      snackStatus: null,
      snackColor: "black",
      interval: null,
      snackTimeout: "20000",
      autoSaveTimeout: "30000",
      errorTimout: null,
      autoSaveValidation: false,
      cancelIndex: "",
      allergyType: "",
      clearIndex: "",
      itemEdit: false,
      prevAllergy: null,
      prevNote: null,
      isConfirm: true,
      url: null,
      smtForReviewDate: null,
      memberDetails: undefined,
      memberName: '',
      isCommentAdd: false,
      isReplyCommentAdd: false
    }
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.errorTimout);
  },
  computed: {
    selectisReview() {
      return store.getters.selectisReview
    },
    selectedMemberId () {
      return store.getters.selectedMemberId
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    selectdocumentId() {
      return store.getters.selectdocumentId;
    },
    memberDetail () {
      return store.getters.memberDetail;
    },
    selectClinicianName() {
      return store.getters.selectClinicianName
    },
    selectReviewerId() {
      return store.getters.selectReviewerId;
    }
  },
  methods: {
    changeCommentReview(value, index) {
      this.allegyList.forEach(items => {
        items.isReviewComment = false
      });
      this.isCommentAdd = true
      this.allegyList[index].isReviewComment = true
      this.allegyList[index].remark = value.target.value
    },
    changeReplyCommentReview(value, index) {
      this.allegyList.forEach(items => {
        items.isReplyReviewComment = false
      });
      this.isReplyCommentAdd = true
      this.allegyList[index].isReplyReviewComment = true
      this.allegyList[index].remarkComment = value.target.value
    },
    handleClearReplyReviewComment(index) {
      this.allegyList[index].remarkComment = ""
    },
    handleClearReviewComment(index) {
      this.allegyList[index].remark = ""
    },
    handleCancelReviewComment(index) {
      this.allegyList.forEach(items => {
        items.isReviewComment = true
        items.remark = ""
      });
      this.allegyList[index].isShowReviewComment = false
      this.isCommentAdd = false
    },
    handleCancelReplyReviewComment(index) {
      this.allegyList.forEach(items => {
        items.isReplyReviewComment = true
        items.remarkComment = ""
      });
      this.allegyList[index].isShowReplyReviewComment = false
      this.isReplyCommentAdd = false
    },
    handleIsReviewerComment(index){
      this.allegyList[index].isShowReviewComment = true
      this.isCommentAdd = true
    },
    handleIsReplyReviewerComment(index){
      this.allegyList[index].isShowReplyReviewComment = true
      this.isReplyCommentAdd = true
    },
    handleAddReviewComment(index) {
      const allergyObj = this.allegyList[index]
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmAllergies (allergyObj, clientId, index)
      }
    },
    handleAddReplyComment(index) {
      const allergyObj = this.allegyList[index]
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmAllergies (allergyObj, clientId, index)
      }
    },
    handleDeleteReviewComment(index) {
      const allergyObj = this.allegyList[index]
      allergyObj.remark = null
      allergyObj.remarkComment = null
      allergyObj.isShowReviewComment = false
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmAllergies (allergyObj, clientId,  index)
      }
    },
    handleDeleteReplyReviewComment(index) {
      const allergyObj = this.allegyList[index]
      allergyObj.remarkComment = null
      allergyObj.isShowReplyReviewComment = false
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmAllergies (allergyObj, clientId,  index)
      }
    },
    handleDeleteAllergy (allergiesId) {
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.getMtmallergiesdelete(allergiesId, this.clientData.clientId)
      }
    },
    getMtmallergiesdelete(allergiesId, clientId) {
    axios
      .delete('/mtm/allergies/getdeleteById?clientId='+ clientId +'&Id='+allergiesId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            const responseIndex = this.allegyList.findIndex(item => item.id === allergiesId)
            if (responseIndex !== -1) {
              this.allegyList.splice(responseIndex, 1)
            }
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
  },
    handleEditAllergy (index) {
      this.allegyList[index].isEdit = true
      this.prevAllergy = this.allegyList[index].allergyCode;
      this.prevNote = this.allegyList[index].notes;
      this.itemEdit = true;
      this.isConfirm = false;
    },
    handleAddAllergy(){
      this.allergiesForm.push({
        companyId: 1,
        clientId: "",
        memberId: "",
        documentId: "",
        activeStatus: "active",
        allergyCode: "",
        notes:"",
        allergyError: { isError: false, errorMessage: '' },
        notesError: { isError: false, errorMessage: '' }
      });
      this.itemEdit = true;
      this.isConfirm = true;
    },
    handleClearAllergy (index, allergyType) {
      this.clearIndex = index
      this.allergyType = allergyType
      this.alertMessage = 'Are you sure you want to clear the content?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'clearAllergy'
      this.isHideShowAlertDialog = true
    },
    clearAllergy (index, allergyType) {
      if (allergyType === 'allegyList') {
        this.allegyList[index].allergyCode = ''
        this.allegyList[index].notes = ''
      } else {
        this.allergiesForm[index].allergyCode = ''
        this.allergiesForm[index].notes = ''
      }
    },
    handleCancelAllergy (cancelIndex, allergyType) {
      this.cancelIndex = cancelIndex
      this.allergyType = allergyType
      this.alertMessage = 'Are you sure you want to cancel?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'cancelAllergy'
      this.isHideShowAlertDialog = true
    },
    cancelAllergy (index, allergyType) {
      this.itemEdit = false;
      this.isConfirm = true;
      if (allergyType == 'allegyList') {
        this.allegyList[index].isEdit = false
        this.allegyList[index].allergyCode = this.prevAllergy;
        this.allegyList[index].notes = this.prevNote;
      } else {
        if(this.allergiesForm.length > 0){
          if(this.allergiesForm[index].hasOwnProperty('id')) {
            axios
              .delete('/mtm/allergies/getdeleteById?clientId='+ this.clientData.clientId +'&Id='+this.allergiesForm[index].id)
              .then(response => {
                if (response.status === 200) {
                  if (response.data.code === 201) {
                    // this.autoSaveStatus(false);
                  } else {
                    this.autoSaveStatus(true);
                  }
                }
              });
            this.allergiesForm.splice(index, 1)
          } else {
            this.allergiesForm.splice(index, 1)
          }
        }
      }
    },
    handleAllergiesCode (index, value, allergiesType) {
      if(allergiesType === 'allegyForm') {
        this.allergiesForm[index].allergyCode = value
        this.allergiesForm[index].allergyError.isError = false
        this.allergiesForm[index].allergyError.errorMessage = ""
      } else {
        this.allegyList[index].allergyCode = value
      }
    },
    handleAllergiesNotes (index, value, allergiesType) {
      if(allergiesType === 'allegyForm') {
        this.allergiesForm[index].notes = value.target.value
        this.allergiesForm[index].notesError.isError = false
        this.allergiesForm[index].notesError.errorMessage = ""
      } else {
        this.allegyList[index].notes = value.target.value
      }
    },
    handleDeleteDocument () {
      this.alertMessage = 'Please confirm if you want delete the currently incomplete MTM document'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'remove'
      this.isHideShowAlertDialog = true
    },
    closeAlertDialog () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      this.confirmType = ''
      if(this.pageReload) {
        if (this.clientData.clientId !== null && this.memberId !== null) {
          store.dispatch("showLoadingSpinner");
          this.getAllMtmAllergies(this.clientData.clientId, this.memberId, this.documentId);
        }
      }
    },
    handleAlertConfirmation (index) {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      if (this.confirmType === 'remove') {
        if(index != -1){
          this.mainCartDraf.splice(index, 1)
        }
      } else if(this.confirmType === 'cancelAllergy'){
        this.cancelAllergy(this.cancelIndex, this.allergyType)
      } else if(this.confirmType === 'clearAllergy'){
        this.clearAllergy(this.clearIndex, this.allergyType)
      }
    },
    handleMtmAddAllergies (index) {
      const tempAlleryiesForm = this.allergiesForm[index]
      tempAlleryiesForm.clientId = this.clientData.clientId
      tempAlleryiesForm.memberId = this.memberId
      tempAlleryiesForm.documentId = this.documentId
      let isValid = true
      if (this.allergiesForm[index].allergyCode === '') {
        this.allergiesForm[index].allergyError.isError = true
        this.allergiesForm[index].allergyError.errorMessage = 'You must enter Allergy.'
        isValid = false
      }
      if (this.allergiesForm[index].notes === '') {
        this.allergiesForm[index].notesError.isError = true
        this.allergiesForm[index].notesError.errorMessage = 'You must enter Notes.'
        isValid = false
      }
      if(isValid) {
        if (this.clientData.clientId !== null && this.memberId !== null) {
          if(!this.autoSaveValidation) {
            store.dispatch("showLoadingSpinner");
            this.createMtmAllergies(tempAlleryiesForm, this.clientData.clientId, index)
          } else {
            this.saveByInterval(tempAlleryiesForm, this.clientData.clientId, index, this.documentId);
          }
        }
      } else if(this.autoSaveValidation) {
        this.autoSaveStatus(false, index);
      }
      this.autoSaveValidation = false;
    },
    createMtmAllergies (allergiesForm, clientId,  index) {
      axios
        .post('/mtm/allergies/addmtmAllergies?clientId=' +clientId, allergiesForm)
        .then(response => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            if (response.data.code === 201) {
              this.itemEdit = false
              this.isConfirm = true
              this.alertType = 'Success'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
              this.pageReload = true
              this.isCommentAdd = false
              this.isReplyCommentAdd = false
              if(this.selectisReview === null || this.selectisReview === false) {
                this.allergiesForm.splice(index, 1);
              }
            } else if (response.data.code === 401) {
              this.alertType = 'Failed'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    autoSaveByTwoMin() {
      this.interval = setInterval(() => {
        clearInterval(this.errorTimout);
        this.allergiesForm.map((allergy, index) => {
          allergy.clientId = this.clientData.clientId;
          allergy.memberId = this.memberId;
          allergy.documentId = this.documentId;
          this.autoSaveValidation = true;
          this.handleMtmAddAllergies (index);
        });
      },this.autoSaveTimeout);
    },
    saveByInterval (allergiesForm, clientId, indexOfAllergy, documentId) {
      axios
        .post('/mtm/allergies/addmtmAllergies?clientId=' +clientId, allergiesForm)
        .then(response => {
          if (response.status == 200) {
            if (response.data.code === 201 && !allergiesForm.hasOwnProperty('id')) {
              axios
                .get('/mtm/allergies/getMtmAllergies?clientId=' +clientId +'&memberId=' +memberId +'&documentId=' +documentId)
                .then(response => {
                  if (response.status === 200) {
                    if (response.data.code === 201) {
                      if(response.data.dataLists.length > 0) {
                        response.data.dataLists.map((allergy, index) => {
                          var exist = this.allegyList?.some(element => element.id == allergy.id);
                          if(!exist) {
                            this.allergiesForm[indexOfAllergy]['id'] = allergy.id;
                            this.autoSaveStatus(true, indexOfAllergy);
                          }
                        })
                      } else {
                        this.autoSaveStatus(false, indexOfAllergy);
                      }
                    } else {
                      this.autoSaveStatus(false, indexOfAllergy);
                    }
                  } else {
                    this.autoSaveStatus(false, indexOfAllergy);
                  }
                });

            } else if(allergiesForm.hasOwnProperty('id')) {
              this.autoSaveStatus(true, indexOfAllergy);
            }
          } else {
             this.autoSaveStatus(false, indexOfAllergy);
          }
        })
        .catch((error) => {
          this.autoSaveStatus(false, indexOfAllergy);
        })
    },
    autoSaveStatus(success, index) {
      if(!success) {
        this.snackStatus = "Autosave Unsuccessful. Trying again in 15s";
        if(index == this.allergiesForm.length-1) {
          this.snackColor = "#DD2C00";
          this.snackbar = true
          this.autoSaveTimeout = 15000
          clearInterval(this.interval);
          this.autoSaveByTwoMin();
          var seconds = 15;
          this.snackTimeout = 15000;
          this.errorTimout = setInterval(() => {
            this.snackColor = "#DD2C00";
            seconds = seconds - 1;
            if(seconds == 0) {
              this.snackTimeout = 20000;
              this.snackbar = false;
            }
            this.snackStatus = "Autosave Unsuccessful. Trying again in "+ seconds +"s";
          }, 1000);
        }
      } else {
        this.autoSaveTimeout = 60000
        this.snackStatus = "Autosave Completed at "+ this.formatAMPM(new Date());
        this.snackColor = "#CDDC39";
        if(index == this.allergiesForm.length-1) {
          this.snackbar = true
        }
      }
    },
    handleAllergyUpdate (index) {
      const tempAlleryiesForm = this.allegyList[index]
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.allergyUpdate(tempAlleryiesForm, this.clientData.clientId)
      }
    },
    allergyUpdate (allergiesForm, clientId, memberId) {
      axios
        .post('/mtm/allergies/addmtmAllergies?clientId=' +clientId, allergiesForm)
        .then(response => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              store.dispatch("hideLoadingSpinner");
              this.alertType = 'Success'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
              this.pageReload = true;
              this.itemEdit = false;
              this.isConfirm = true;
            } else if (response.data.code === 401) {
              store.dispatch("hideLoadingSpinner");
              this.alertType = 'Failed'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
      },
      mapResponseWithFormData (allergiesData) {
        const tempAllergiesData = [...allergiesData]
        tempAllergiesData.forEach(items => {
        items.isReviewComment = true;
        items.isReplyReviewComment = true;
        items.isEdit = false
        if(items.remark === null || items.remark === ''){
          items.isShowReviewComment = false
          items.isShowReviewBtn = false
        } else {
          items.isShowReviewComment = true
          items.isShowReviewBtn = true
        }
        if(items.remarkComment === null || items.remarkComment === ''){
          items.isShowReplyReviewComment = false
          items.isShowReviewReplyBtn = false
        } else {
          items.isShowReplyReviewComment = true
          items.isShowReviewReplyBtn = true
        }
        if(items.activeStatus === "active") {
          items.isActive = true
        } else {
          items.isActive = false
        }
      });
      if(this.allergiesCodeList !== undefined) {
        tempAllergiesData.forEach(element => {
        this.allergiesCodeList.forEach(element2 => {
          if(element.allergyCode === element2.codeValue){
            element.newDescription = element2.shortDesc
          }
        });
      });
      }
      this.allegyList = tempAllergiesData
    },
    getAllMtmAllergies (clientId, memberId, documentId) {
      axios
        .get('/mtm/allergies/getMtmAllergies?clientId=' +clientId +'&memberId=' +memberId +'&documentId=' +documentId)
        .then(response => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            if (response.data.code === 201) {
              const [year, month, day] =  response.data.document.displaySentDate.split("-");
              this.smtForReviewDate = `${month}/${day}/${year}`;
              this.memberName = this.memberDetails.member.firstName +' '+this.memberDetails.member.lastName
              if(this.selectisReview == null || this.selectisReview === false) {
                if(response.data.document?.allergiesStatus === 1) {
                  store.dispatch("setSelectDocumenStatus", 'Complete');
                } else {
                  store.dispatch("setSelectDocumenStatus", 'Draft');
                }
              }
              if(response.data.dataLists.length > 0) {
                this.mapResponseWithFormData(response.data.dataLists)
              }
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
    },
    handleActAndDeactive (index, value){
      this.allegyList[index].activeStatus = value
      const allergiesForm = this.allegyList[index];
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.actAndDeactive(allergiesForm, this.clientData.clientId)
      }
    },
    actAndDeactive(allergiesForm, clientId) {
    axios
      .post('/mtm/allergies/addmtmAllergies?clientId=' +clientId, allergiesForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    getAllergyCode () {
      axios
        .get('/mtm/codes/getByCodeType?codeType=AL')
        .then(response => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              if(response.data.dataLists.length > 0) {
                const tempAllergiesCodeList = response.data.dataLists
                tempAllergiesCodeList.unshift({id: "", codeType: "", codeValue: "", shortDesc: "--Select Allergy--", longDesc: ""})
                this.allergiesCodeList = tempAllergiesCodeList
              }
            }
          } else {
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
    },
    getReviewDocument(clientId, memberId, documentId) {
    store.dispatch("showLoadingSpinner");
      axios
      .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
        {responseType: "blob"},
      )
      .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            this.url = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    handleAllergies () {
      this.clientData = this.selectedClient
      this.memberId = this.selectedMemberId
      this.documentId = this.selectdocumentId
      store.dispatch("showLoadingSpinner");
      if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        this.getAllergyCode()
        this.getAllMtmAllergies(this.clientData.clientId, this.memberId, this.documentId)
        if(this.selectisReview){
          this.getDocReviewSectionStatus(this.clientData.clientId, this.selectReviewerId)
          setTimeout(() => {
            this.getReviewDocument(this.clientData.clientId , this.memberId, this.documentId)
          }, 1000);
        }
      }
    },
    getDocReviewSectionStatus (clientId, reviewerId) {
      axios
      .get('/mtm/review?clientId='+clientId +'&reviewerId='+reviewerId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataList?.allergiesStatus === 1) {
              store.dispatch("setSelectDocumenStatus", 'Reviewer');
            } else {
              store.dispatch("setSelectDocumenStatus", 'NotReviewer');
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    }
  },
  mounted () {
    this.handleAllergies ();
    interceptorSetup.refreshToken();
    store.dispatch("setSelectDocumenStatus", 'Draft');
    this.clientData = this.selectedClient;
    this.memberId = this.selectedMemberId;
    this.documentId = this.selectdocumentId;
    this.memberDetails = this.memberDetail;
    if(this.selectisReview == null|| this.selectisReview === false) {
     this.autoSaveByTwoMin();
    }
  },
  unmounted(){ 
   clearInterval(this.interval)
   clearInterval(this.errorTimout);
  }
};
</script>
