<template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="490"
        max-height="600"
        scrollable
        class="z-index-1000"
      >
        <v-card class="card-border">
          <v-card-title>
            <v-row class="pd-10">
              <v-col cols="11">
                <span class="pd-10 modal-title">Terms of Service</span>
              </v-col>
            <v-col cols="1">
              <v-icon
                left
                size="20"
                class="font-weight-700"
                @click="$emit('closeDialog')"
              >
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <h4 class="mg-top-10 font-weight-300">
              <strong>Introduction</strong>
            </h4>
            <div class="content">
              These terms and conditions govern your use of this website; by using
              this website, you accept these terms and conditions in full.
            </div>
            <br /><br />
            <div class="content font-weight-600 gray-primary">
              PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEB SITE (Site).
              BY ACCESSING OR USING OUR SITES AND OUR SERVICES, YOU HEREBY AGREE
              TO BE BOUND BY THE TERMS AND ALL TERMS INCORPORATED HEREIN BY
              REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR
              PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE
              PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF
              THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITES
              OR OUR SERVICES. THIS TERMS OF SERVICE AGREEMENT IS EFFECTIVE AS OF
              06/01/2016.
            </div>
            <div div class="content">
              This Site is provided by MEMBER FIRST and may be used for
              informational purposes only. By using the Site or downloading
              materials from the Site, you agree to abide by the Terms of Service
              set forth in this Notice. If you do not agree to abide by these
              Terms of Service, do not use the Site or download materials from the
              Site.
            </div>
            <br />
            <div class="sub-title">Limited License</div>
            <br />
            <div class="content">
              Subject to the terms and conditions set forth in these Terms of
              Service to which you have agreed (Agreement), MEMBER FIRST grants
              you a license that is non-exclusive, non-transferable, with a
              limited right to access, use and display this Site and the
              informational materials (Materials) contained in the Site. You agree
              not to interrupt or attempt to interrupt the operation of the Site
              in any way.
            </div>
            <br /><br />
            <div class="content">
              Any and all visitors to our site, despite whether they are
              registered or not, shall be deemed as "users" of the herein
              contained Services provided for the purpose of this Terms of
              Service. Once an individual registers for our Services, through the
              process of creating an account, the user shall then be considered a
              "member."
            </div>
            <br />
            <div class="content">
              MEMBER FIRST authorizes members to view and download the Materials
              at this Site for your personal, non-commercial use only. This
              authorization is not a transfer of title in the Materials or copies
              of the Materials and is subject to the following restrictions: 1)
              you must retain, on all copies of the Materials downloaded, all
              copyright and other proprietary notices contained in the Materials;
              2) you may not modify the Materials in any way or reproduce or
              publicly display, perform, or distribute or otherwise use them for
              any public or commercial purpose; and 3) you must not transfer the
              Materials to any other person unless you give them notice of, and
              they agree to accept, the obligations arising under these Terms of
              Service. You agree to abide by all additional restrictions displayed
              on the Site as it may be updated from time to time. By using the
              Site, you acknowledge and agree that all content, including but not
              limited to text, software, music, sound, photographs, graphics,
              video, or other material contained in advertisements or information
              presented to you on this Site is protected by our or other third
              parties' copyrights, trademarks, service marks, patents, or other
              proprietary rights and laws. You agree to comply with all copyright
              and other laws worldwide in your use of this Site and to prevent any
              unauthorized copying of the Materials. Except as expressly provided,
              MEMBER FIRST does not grant any express or implied right to you
              under any patents, trademarks, copyrights or trade secret
              information.
            </div>
            <br />
            <div class="sub-title">Registration</div>
            <br />
            <div class="content">
              To register and become a "member" of the Site, you must be at least
              18 years of age to enter into and form a legally binding contract
              subject to applicable state and federal law. In addition, you must
              be in good standing and not an individual that has been previously
              barred from receiving MEMBER FIRST's Services under the laws and
              statutes of the United States or other applicable jurisdiction.
            </div>
            <br />
            <div class="content">
              When you register, MEMBER FIRST may collect information such as your
              name, e-mail address, birth date, gender identity, mailing address,
              and other information provide by you.
              <u
                >You can edit your account information at any time. Once you
                register with MEMBER FIRST and sign in to our Services, you are no
                longer anonymous to us.</u
              >
            </div>
            <br />
            <div class="content">
              Furthermore, the registering party hereby acknowledges, understands
              and agrees to:
            </div>
            <br />
            <ul>
              <li class="content">
                a. furnish factual, correct, current and complete information with
                regards to yourself as may be requested by the data registration
                process, and,
              </li>
              <li class="content">
                b. maintain and promptly update your registration and profile
                information in an effort to maintain accuracy and completeness at
                all times.
              </li>
            </ul>
            <br />
            <div class="content">
              If anyone knowingly provides any information of a false, untrue,
              inaccurate or incomplete nature, MEMBER FIRST will have sufficient
              grounds and rights to suspend or terminate the member in violation
              of this aspect of the Agreement, and as such refuse any and all
              current or future use of MEMBER FIRST Services, or any portion
              thereof.
            </div>
            <br />
            <div class="content">
              It is MEMBER FIRST's priority to ensure the safety and privacy of
              all its visitors, users and members, especially that of children.
              Therefore, it is for this reason that the parents of any child under
              the age of 18 that permit their child or children access to the
              MEMBER FIRST website platform Services must create a "family"
              account, which will certify that the individual creating the
              "family" account is of 18 years of age and as such, the parent or
              legal guardian of any child or children registered under the
              "family" account. As the creator of the "family" account, s/he is
              thereby granting permission for his/her child or children to access
              the various Services provided, including, but not limited to,
              message boards, email, and/or instant messaging. It is the parent's
              and/or legal guardian's responsibility to determine whether any of
              the Services and/or content provided are age-appropriate for his/her
              child.
            </div>
            <br />
            <div class="content">
              You will have an option to disable receiving electronic notification
              after Registration. Messages will continue to be generated and
              stored on the site. You agree that until you Terminate your account
              with MEMBER FIRST, MEMBER FIRST may periodically send a program
              update via electronic notifications.
            </div>
            <br />
            <div class="content">
              Member Account, User Name, and Password Security
            </div>
            <br />
            <div class="content">
              When you set up an account, you are the sole authorized user of your
              account. You shall be responsible for maintaining the secrecy and
              confidentiality of your password and for all activities that
              transpire on or within your account. It is your responsibility for
              any act or omission of any user(s) that access your account
              information that, if undertaken by you, would be deemed a violation
              of the Terms of Service. It shall be your responsibility to notify
              MEMBER FIRST immediately if you notice any unauthorized access or
              use of your account or password or any other breach of security.
              MEMBER FIRST shall not be held liable for any loss and/or damage
              arising from any failure to comply with this term and/or condition
              of the Terms of Service.
            </div>
            <br />
            <div class="sub-title">Third Party Sites</div>
            <br />
            <div class="content">
              As a convenience to you, MEMBER FIRST may provide, on this Site,
              links to Web sites operated by other entities. If you use those
              other sites, you will leave this Site. If you decide to visit any
              linked site, you do so at your own risk and it is your
              responsibility to take all protective measures to guard against
              viruses or other destructive elements. MEMBER FIRST makes no
              warranty or representation regarding, and does not endorse, any
              linked sites or the information appearing there or any of the
              products or services described there. Links do not imply that MEMBER
              FIRST or this Site sponsors, endorses, is affiliated or associated
              with, or is legally authorized to use any trademark, trade name,
              logo or copyright symbol displayed in or accessible through the
              links, or that any linked site is authorized to use any trademark,
              trade name, logo or copyright symbol of MEMBER FIRST or any of its
              affiliates or subsidiaries.
            </div>
            <br /><br />
            <div class="sub-title">
              Use of Website and General Storage Practices
            </div>
            <br />
            <div class="content">
              You agree to use the Site only for lawful purposes. You agree not to
              use the Site for illegal purposes or for the transmission of
              material that is unlawful, harassing, libelous, invasive of
              another's privacy, abusive, threatening, harmful, vulgar, obscene,
              or otherwise objectionable, or that infringes or may infringe the
              intellectual property or rights of another or that violates any
              international, federal, state, or local law.
            </div>
            <br />
            <div class="content">
              You herein acknowledge that MEMBER FIRST may set up any such
              practices and/or limits regarding the use of our Services, without
              limitation of the maximum number of days that any email, message
              posting or any other uploaded content shall be retained by MEMBER
              FIRST, nor the maximum number of email messages that may be sent
              and/or received by any member, the maximum volume or size of any
              email message that may be sent from or may be received by an account
              on our Service, the maximum disk space allowable that shall be
              allocated on MEMBER FIRST's servers on the member's behalf, and/or
              the maximum number of times and/or duration that any member may
              access our Services in a given period of time. In addition, you also
              agree that MEMBER FIRST has absolutely no responsibility or
              liability for the removal or failure to maintain storage of any
              messages and/or other communications or content maintained or
              transmitted by our Services. You also herein acknowledge that we
              reserve the right to delete or remove any account that is no longer
              active for an extended period of time. Furthermore, MEMBER FIRST
              shall reserve the right to modify, alter and/or update these general
              practices and limits at our discretion.
            </div>
            <br />
            <div class="content">
              You agree that MEMBER FIRST may in its sole discretion terminate
              your access to our Site, without notice, if we believe you have in
              any way violated these Terms of Service.
            </div>
            <br />
            <div class="sub-title">Member Submissions</div>
            <br />
            <div class="content">
              The personal information you submit to the Site is governed by the
              Site's Privacy Statement which is incorporated into these Terms of
              Service and forms a part of your Agreement with us. When we use the
              term "personal information" we mean information such as your
              first/middle initial or name and last name, street address, town or
              city, state, ZIP code, telephone number, email address, gender and
              any other information that would allow someone to identify you or
              contact you.
            </div>
            <br />
            <div class="content">
              You agree that you will not send, upload or transmit any
              communication, content or Material of any type to the email address
              listed on the "Contact Us" link or otherwise to MEMBER FIRST that
              infringes or violates any rights of any party or violates these
              Terms of Service. Any user submitting or otherwise exchanging
              communications, content or Material, including, without limitation,
              any personal or commercial information, idea, concept or invention,
              irrevocably grants to MEMBER FIRST an unrestricted, worldwide,
              royalty free license to use reproduce, display publicly, perform,
              publish, transmit and distribute such Materials and you further
              agree that MEMBER FIRST is free to use any ideas, concepts or
              know-how that you or individuals acting on your behalf provide to
              MEMBER FIRST.
            </div>
            <br />
            <div class="sub-title">Electronic Communications</div>
            <br />
            <div class="content">
              When you send emails to us, you are communicating with us
              electronically and consent to receive return communications, if any,
              from us electronically. You agree that all agreements, notices,
              disclosures and other communications that we provide to you
              electronically satisfy any legal requirement that such
              communications be in writing.
            </div>
            <br />
            <div class="sub-title">Health-Related Information</div>
            <br />
            <div class="content">
              Any health information contained in the Site is provided for
              informational purposes only and is not meant to substitute for the
              advice provided by your doctor or other health care professional.
            </div>
            <br />
            <div class="content">
              You should not use the information available on or through the Site
              for diagnosing or treating a health problem or disease or
              prescribing any medication. Always consult with your doctor or other
              health care professional before starting any new diet, fitness
              routine, supplement regimen or other health-related program. If you
              have or suspect that you have a medical problem, promptly contact
              your doctor or other health care professional. If you think you may
              have a medical emergency, call 911 or your doctor (or other
              appropriate emergency phone number) immediately.
            </div>
            <br />
            <div class="sub-title">
              Limitation of Warranty & Disclaimer of Liability
            </div>
            <br />
            <div class="content">
              The Materials on this Site may contain inaccuracies and
              typographical errors. MEMBER FIRST does not warrant the accuracy or
              completeness of the Materials or the reliability of any advice,
              opinion, statement or other information displayed or distributed
              through the Site. You acknowledge that any reliance on any such
              opinion, advice, statement, memorandum, or information shall be at
              your sole risk. MEMBER FIRST reserves the right, in its sole
              discretion, to correct any errors or omissions in any portion of the
              Site. MEMBER FIRST may make any other changes to the Site, the
              Materials on the Site and the products, programs, services or prices
              (if any) described in the Site at any time without notice. The use
              of this Site and its content is at your own risk and the Site and
              content are provided "as is." Transmissions over the Internet and
              communications networks are not in our control and can never be
              completely secure. Accordingly, we cannot and shall not be liable
              for any delay, failure, interruption, compromise or corruption of
              any data or other information transmitted in connection with use of
              the Site, including information you provide to us or our Site.
            </div>
            <br />
            <div class="content">
              To the fullest extent permitted by law, MEMBER FIRST and the site
              disclaim all warranties, express and implied, statutory and
              otherwise, including but not limited to the implied warranties of
              merchantability, title, non-infringement, and fitness for particular
              purpose.
            </div>
            <br />
            <div class="content">
              In no event shall MEMBER FIRST, the site, or the site's licensors,
              suppliers and content providers be liable for any damages,
              including, without limitation, direct, indirect, incidental,
              consequential, special, exemplary and special damages or damages
              resulting from lost data or business interruption, regardless of the
              form of action or the basis of the claim, whether based on warranty,
              contract, tort, strict liability or any other legal theory, and
              whether or not a party has been advised of the possibility of
              damages.
            </div>
            <br />
            <div class="content">
              If, for any reason, MEMBER FIRST or an affiliate or service provider
              shall be found to be liable, the aggregate liability to you or any
              other party or parties claiming with, under or through you, shall be
              limited to ten dollars (U.S. $10.00), notwithstanding any claim that
              such remedy fails of its essential purpose. No claim or action
              arising from or concerning the Site, content or otherwise hereunder
              may be brought later than one (1) year from the date the claim or
              cause of action arose.
            </div>
            <br />
            <div class="content">
              Some jurisdictions do not allow the disclaimer of certain types of
              damages or liability in whole or in part with respect to consumer
              agreements, and although the exclusions, limitations and disclaimers
              in these Terms of Service shall always be construed to take full
              advantage of their meaning to the extent permitted by law, you
              should consult your own legal advisor should you wish to determine
              the laws and regulations that apply to you.
            </div>
            <br />
            <div class="sub-title">Idemnity</div>
            <br />
            <div class="content">
              All users and/or members herein agree to insure and hold MEMBER
              FIRST, Inc., our subsidiaries, affiliates, agents, employees,
              officers, partners and/or licensors blameless or not liable for any
              claim or demand, which may include, but is not limited to,
              reasonable attorney fees made by any third party which may arise
              from any content a member or user of our site may submit, post,
              modify, transmit or otherwise make available through our Services,
              the use of MEMBER FIRST Services or your connection with these
              Services, your violations of the Terms of Service and/or your
              violation of any such rights of another person.
            </div>
            <br />
            <div class="sub-title">Modifications</div>
            <div class="content">
              MEMBER FIRST reserves the right, at its sole discretion, to change,
              modify, add or remove any portion of these Terms of Service in whole
              or in part, at any time. Changes in these Terms of Service will be
              effective when notice of such change is posted. Your continued use
              of the Site after any changes to those Terms of Service have been
              posted on-line will be deemed acceptance of such revised Terms of
              Service.
            </div>
            <br />
            <div class="content">
              We reserve the right to modify or discontinue, temporarily or
              permanently, any or all of the Site and/or any or all features,
              products, services or information appearing on or available through
              any or all of the Site with or without notice to you. You agree that
              we shall not be liable to you or any third-party for any
              modification or discontinuance of such features, products, services,
              information or the Site. MEMBER FIRST may terminate the
              authorization, rights and license given above and, upon such
              termination, you shall immediately destroy all Materials in your
              possession. No waiver of any of these Conditions of Use shall be
              deemed a further or continuing waiver of such term or condition.
            </div>
            <br />
            <div class="sub-title">Termination</div>
            <br />
            <div class="content">
              As a member of our site, you may cancel or terminate your account,
              associated email address and/or access to our Services by updating
              your member profile on our site.
            </div>
            <br />
            <div class="content">
              As a member, you agree that MEMBER FIRST, Inc. may, without any
              prior written notice, immediately suspend, terminate, discontinue
              and/or limit your account, any email associated with your account,
              and access to any of our Services. The cause for such termination,
              discontinuance, suspension and/or limitation of access shall
              include, but is not limited to:
            </div>
            <br />
            <ul>
              <li class="content">
                a. any breach or violation of our Terms of Service or any other
                incorporated agreement, regulation and/or guideline;
              </li>
              <li class="content">
                b. by way of requests from law enforcement or any other
                governmental agencies;
              </li>
              <li class="content">
                c. the discontinuance, alteration and/or material modification to
                our Services, or any part thereof;
              </li>
              <li class="content">
                d. unexpected technical or security issues and/or problems;
              </li>
              <li class="content">
                e. any extended periods of inactivity; and/or
              </li>
              <li class="content">
                f. any engagement by you in any fraudulent or illegal activities.
              </li>
            </ul>
            <br />
            <div class="content">
              Furthermore, you herein agree that any and all terminations,
              suspensions, discontinuances, and or limitations of access for cause
              shall be made at our sole discretion and that we shall not be liable
              to you or any other third party with regards to the termination of
              your account, associated email address and/or access to any of our
              Services.
            </div>
            <br />
            <div class="content">
              The termination of your account with our site shall include any
              and/or all of the following:
            </div>
            <ul>
              <li class="content">
                a. the removal of any access to all or part of the Services
                offered within our site;
              </li>
              <li class="content">
                b. the deletion of your password and any and all related
                information, files, and any such content that may be associated
                with or inside your account, or any part thereof; and
              </li>
              <li class="content">
                c. the barring of any further use of all or part of our Services.
              </li>
            </ul>
            <br />
            <div class="sub-title">Advertisers</div>
            <br />
            <div class="content">
              Any correspondence or business dealings with, or the participation
              in any promotions of, advertisers located on or through our
              Services, which may include the payment and/or delivery of such
              related goods and/or Services, and any such other term, condition,
              warranty and/or representation associated with such dealings, are
              and shall be solely between you and any such advertiser. Moreover,
              you herein agree that MEMBER FIRST, Inc. shall not be held
              responsible or liable for any loss or damage of any nature or manner
              incurred as a direct result of any such dealings or as a result of
              the presence of such advertisers on our website.
            </div>
            <div class="sub-title">Links</div>
            <br />
            <div class="content">
              Either MEMBER FIRST or any third parties may provide links to other
              websites and/or resources. Thus, you acknowledge and agree that we
              are not responsible for the availability of any such external sites
              or resources, and as such, we do not endorse nor are we responsible
              or liable for any content, products, advertising or any other
              materials, on or available from such third party sites or resources.
              Furthermore, you acknowledge and agree that MEMBER FIRST shall not
              be responsible or liable, directly or indirectly, for any such
              damage or loss which may be a result of, caused or allegedly to be
              caused by or in connection with the use of or the reliance on any
              such content, goods or services made available on or through any
              such site or resource.
            </div>
            <br />
            <div class="sub-title">Release</div>
            <br />
            <div class="content">
              In the event you have a dispute, you agree to release MEMBER FIRST
              (and its officers, directors, employees, agents, parent
              subsidiaries, affiliates, co-branders, partners and any other third
              parties) from claims, demands and damages (actual and consequential)
              of every kind and nature, known and unknown, suspected or
              unsuspected, disclosed and undisclosed, arising out of or in any way
              connected to such dispute.
            </div>
            <br />
            <div class="sub-title">Notice</div>
            <div class="content">
              MEMBER FIRST may furnish you with notices, including those with
              regards to any changes to the Terms of Service, including but not
              limited to email, regular mail, MMS or SMS, text messaging, postings
              on our website Services, or other reasonable means currently known
              or any which may be herein after developed. Any such notices may not
              be received if you violate any aspects of the Terms of Service by
              accessing our Services in an unauthorized manner. Your acceptance of
              this Terms of Service constitutes your agreement that you are deemed
              to have received any and all notices that would have been delivered
              had you accessed our Services in an authorized manner.
            </div>
            <br />
            <div class="sub-title">Contacting Member First</div>
            <br />
            <div class="content">
              If you have any questions about the content of these Terms of
              Service or about the practices of this Site or your dealings with
              this Site, please contact the MEMBER FIRST Privacy Office at the
              following address:
            </div>
            <br />
            <div class="content">
              Member First<br />
              Attn: Privacy Officer<br />
              13809 Research Blvd.<br />
              Suite 500<br />
              Austin, TX 78750
            </div>
            <br />
            <div class="content">
              Email:
              <a href="mailto:compliance@memberfirstpharmacy.com"
                >compliance@memberfirstpharmacy.com</a
              >
            </div>
            <br />
            <div class="content">
              If you have any questions about the practices of this Site or your
              dealings with this Site, please contact:
            </div>
            <br />
            <div class="content">
              Member First<br />
              Attn: Privacy Officer<br />
              13809 Research Blvd.<br />
              Suite 500<br />
              Austin, TX 78750
            </div>
            <br />
            <div class="content">
              Email:
              <a href="mailto:memberservices@memberfirstpharmacy.com"
                >memberservices@memberfirstpharmacy.com</a
              >
            </div>
            <br />
            <div class="content fs-25 dark-color">General Information</div>
            <br /><br />
            <div class="sub-title">Entire Agreement</div>
            <br />
            <div class="content">
              This Terms of Service constitutes the entire agreement between you
              and MEMBER FIRST and shall govern the use of our Services,
              superseding any prior version of this Terms of Service between you
              and us with respect to MEMBER FIRST services. You may also be
              subject to additional terms and conditions that may apply when you
              use or purchase certain other MEMBER FIRST services, affiliate
              services, third-party content or third-party software.
            </div>
            <br />
            <div class="sub-title">Choice of Law and International Users</div>
            <br />
            <div class="content">
              This Site is controlled, operated and administered by MEMBER FIRST
              from its offices within the United States of America. MEMBER FIRST
              makes no representation that Materials at this Site are appropriate
              or available for use at other locations outside of the United States
              and access to them from territories where their content is illegal
              or restricted is prohibited. You may not use the Site or export the
              Materials in violation of U. S. export laws and regulations. If you
              access this Site from a location outside of the United States, you
              are responsible for compliance with all local laws. These Terms of
              Service shall be governed by the laws of the United States and of
              the State of Colorado, without giving effect to its conflict of laws
              provisions.
            </div>
            <br />
            <div class="content">
              Your Agreement to these Terms of Service constitutes the entire
              agreement between MEMBER FIRST and you with respect to your use of
              the Site. If for any reason a court of competent jurisdiction finds
              any provision of the Terms of Service which form the basis of our
              Agreement or any portion thereof, to be unenforceable, that
              provision shall be enforced to the maximum extent permissible so as
              to affect the intent of the Agreement, and the remainder of this
              Agreement shall continue in full force and effect.
            </div>
            <br />
            <div class="sub-title">
              No Right of Survivorship Non-Transferability
            </div>
            <br />
            <div class="content">
              You acknowledge, understand and agree that your account is
              non-transferable and any rights to your ID and/or contents within
              your account shall terminate upon your death. Upon receipt of a copy
              of a death certificate, your account may be terminated and all
              contents therein permanently deleted.
            </div>
            <br /><br />
            <div class="flex-nowrap">
              <v-icon left size="14"> mdi-file </v-icon>
              70008 r1.4
            </div>
          </v-card-text>
          <v-card-actions class="card-actions">
            <v-spacer></v-spacer>
            <v-btn
              class="mg-left-20 mg-top-10 pd-10"
              variat="outlined"
              color="black"
              @click="$emit('closeDialog')"
            >
              Close
            </v-btn>
            <v-btn
              depressed
              color="error"
              class="mg-left-20 mg-top-10 pd-10"
              @click="openTermsServicePrintable"
            >
              <v-icon left size="15"> mdi-printer </v-icon>
              Print
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
  export default {
  name: 'TermsOfServiceModel',
  props: {
  },
  components: {
  },
  data: () => ({
    dialog: true
  }),
  computed: {
  },
  methods: {
    openTermsServicePrintable(){
      const routeData = this.$router.resolve({name: 'TermsService'});
      window.open(routeData.href, '_blank');
      // this.$router.push({ name: 'TermsService'})
    }
  },
  mounted () {
  }
  }
  </script>
  <style>
  .modal-title {
    font-style: oblique;
    margin-bottom: 0;
    line-height: 1.5;
  }
  strong {
    font-weight: bolder;
    font-style: oblique;
  }
  h4 {
    font-size: 18px;
    letter-spacing: normal;
    margin: 0 0 14px 0;
  }
  h4 {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #414141;
    position: relative;
    margin: 0 0 30px 0;
    line-height: 1.5;
    z-index: 0;
    -webkit-font-smoothing: antialiased;
  }
  .content {
    font-style: oblique;
    line-height: 25px;
    font-size: 16px;
    color: #696969;
  }
  .sub-title {
    line-height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #000000 !important;
  }
  .card-border {
    border-top: 4px solid #63db4b !important;
  }
  ul {
    list-style-type: none;
  }
  .card-actions {
    border-top: 1px solid rgb(65 65 65 / 18%);
  }
  </style>
  