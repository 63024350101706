<template>
  <v-container fluid>
    <div>
      <div class="header-info mg-top-15">MTM Medication</div>
      <v-col cols="12">
        <div>
            <v-row>
              <v-col cols="3">
                <b>Instructions</b>
              </v-col>
              <v-col cols="8">
              </v-col>
              <v-col cols="1">
                <v-icon
                  small
                  v-if="isShowIntruction"
                  class="ml-2"
                  @click="handleTopAndDown"
                  >mdi-arrow-down</v-icon>
                <v-icon
                  small
                  v-if="!isShowIntruction"
                  @click="handleTopAndDown"
                  class="ml-2"
                  >mdi-arrow-up</v-icon>
              </v-col>
            </v-row>
            <div v-if="isShowIntruction">
            <div class="mg-top-10">
              <b>Medication</b><br /><br />
              Enter the medication's generic drug name (and brand name if
              applicable), strength, and dosage form for medications currently
              being used by the beneficiary, including starter supplies (e.g.,
              samples), prescription medications, OTCS, dietary therapies,
              herbal supplements and vitamins.
            </div>
            <div>
              For brand drugs and branded generics, list both generic and brand
              names, such as "Generic Name (Brand Name)". An example is
              Furosemide (Lasix). For generic drugs, list the medication name as
              "Generic Name" (e.g., Furosemide). This would ensure a consistent
              format of: "Generic Name (Brand Name if applicable)".
            </div>
            <div>
              Information about medication-related devices should be included in
              the field for the applicable medication(s) in the "How I take it"
              field where appropriate.
            </div>
            <div>
              <b>How I Take It</b><br /><br />
              Enter the directions for use and supplemental instructions for
              using the medication. Directions should be specific and include
              the dose, frequency and route of administration (as ordered for
              prescribed products, or as being taken for self-selected
              products). MTM providers should document how the beneficiary is
              taking the medication and add any discrepancies from written or
              prescriber instructions to the "MTM Other information" section as
              well as in the "MTM To-Do List."
            </div>
            <div>
              For the dose that the beneficiary takes, it should, when
              appropriate and reasonable, include both the number of
              tablets/capsules/teaspoonfuls, etc., and the strength (e.g., 3
              teaspoonfuls (27mg) by mouth every 8 hours). For topical dosage
              forms, such as gels, creams, lotions, ointments, and drops, the
              dose strength does not need to be included in the directions
              (e.g., apply to affected area every 12 hours). For other non-oral
              dosage forms, such as injections, nasal
            </div>
            <div>
              <b>Why I Use It</b><br /><br />
              Enter the reason for use, indication, or intended purpose. It may
              be appropriate to include a lay term (e.g., high blood pressure)
              more easily understood by the beneficiary, rather than or in
              addition to the medical term (e.g., hypertension). The MTM
              provider may also describe the goal(s) of therapy in this field.
            </div>
            <div>
              <b>Prescriber</b><br /><br />
              Enter the name of the authorized practitioner who ordered the
              medication for the beneficiary. This field may also include other
              prescriber data, such as designation of practitioner type (e.g.,
              MD, PA, or NP), telephone number, address, site, etc., such as J.
              Johnson-Smith, NP. For non-prescribed OTCS, enter "self" or leave
              this field blank.
            </div>
            </div>
        </div>
        <v-row v-if="selectisReview">
          <v-col cols="11" class="mg-left-30">
            <div class="review-cart-bg-color">
              <div class="header-quality-control">Quality Control Review</div>
              <v-row class="mg-top-5">
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="text-align-inherit fs-14"
                      >{{ "Submitted for Review date:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="text-align-inherit fs-14"
                  v-if="smtForReviewDate !== null"
                  >{{smtForReviewDate }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Clinician:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ "DR. "+selectClinicianName }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Member:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ memberName }}</v-col
                >
              </v-row>
            </div>
          </v-col>
        </v-row>
        <div class="pd-top-30" :class="{'mg-top-2rem':selectisReview}"><b>Medication From Claims</b></div>
        <div
          class="pd-top-10 mg-bottom-25"
          v-if="selectisReview === null || selectisReview === false"
        >
          <b
            >This section has claim information based on your configured
            lookback timeframe</b
          >
        </div>
        <div class="mg-top-10" v-if="medicinesList.length > 0">
          <div v-for="(items, indexmeds) in medicinesList" :key="indexmeds">
            <v-card class="mg-bottom-30 card-border-blue" :class="{ 'card-border-blue': items.isActive, 'card-border-gray': !items.isActive}" height="100%">
              <v-row class="pd-top-10">
                <v-col cols="1"> </v-col>
                <v-col cols="5">
                  <v-row class="font-size-12">
                    <v-col cols="7">
                      <b>Medication: &nbsp;</b> {{ items.medName }}
                    </v-col>
                    <v-col cols="2">
                      <b>QTY: &nbsp;</b> {{ items.quantity }}
                    </v-col>
                    <v-col cols="3">
                      <b>DS: &nbsp;</b> {{ items.daysSupply }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <v-row class="font-size-12 mg-left-25">
                    <v-col cols="6">
                      <b>Prescriber Name: &nbsp;</b> {{ items.prescrFirstName +" "+items.prescrLastName }}
                    </v-col>
                    <v-col cols="6">
                      <b>Last Service Date: &nbsp;</b>
                      {{ items.displayDateOfService }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-menu
                    bottom
                    left
                    v-if="(selectisReview === false || selectisReview === null) && (isItemEdit==false && items.isActive)"
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        dark
                        icon
                        v-bind="props"
                        class="mg-left-30"
                        size="20"
                        color="black"
                      >
                        mdi-dots-vertical</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item class="dot-item-list">
                        <v-list-item-title
                          @click="edit(indexmeds, 'medsList')"
                          >{{ "Edit" }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item class="dot-item-list">
                        <v-list-item-title
                          @click="handleRemove(indexmeds, 'medsList')"
                          >{{ "Delete" }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1">
                  <v-switch
                    color="blue"
                    class="pd-left-15"
                    v-model="items.isActive"
                    :disabled="selectisReview || isItemEdit && !items.included"
                    @input="handleActAndDeactiveMedication(items)"
                  />
                </v-col>
                <v-col cols="5" class="mg-right-20 card-parent">
                  <v-row class="font-size-12">
                    <v-col>
                      <v-text-field
                        label="How I Take It"
                        v-model="items.howText"
                        @input="handleHowText($event, indexmeds, 'medsList', false)"
                        @blur="onBlur($event)"
                        @focus="onFocus('medsList',items.included)"
                        clear-icon="mdi-close-circle"
                        :clearable="items.included"
                        @click:clear="handleHowText('', indexmeds, 'medsList', false)"
                        :readonly="selectisReview || !items.included"
                        persistent-hint
                        variant="outlined"
                        density="comfortable"
                        :error="items.howTextError.isError"
                        :error-messages="items.howTextError.errorMessage"
                      ></v-text-field>
                      <v-card
                        class="card-height"
                        tile
                        v-if="results.length > 0 && showListMeds && items.included"
                      >
                        <v-list flat>
                          <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in results"
                              :key="i"
                            >
                              <v-list-item-content @click="handleHowText(item.code, indexmeds, 'medsList', true)">
                                <v-list-item-title>{{ item.code }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mg-0">
                    <div
                      class="mg-left-5"
                      v-if="items.editmedDescription != ''"
                    >
                      <span style="text-transform: uppercase">{{ items.editmedDescription }}</span>
                    </div>
                    <div
                      class="mg-left-5 red--text"
                      v-if="items.found == false"
                    >
                      <b
                        >Cannot process SIG codes. Please review your input or
                        try another set of codes.</b
                      >
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="5" class="mg-left-20">
                  <v-row class="font-size-12">
                    <v-col>
                      <v-text-field
                        label="Why I Use It"
                        v-model="items.whyText"
                        @input="handleWhyText($event, indexmeds, 'medsList')"
                        persistent-hint
                        variant="outlined"
                        density="comfortable"
                        :error="items.whyTextError.isError"
                        :error-messages="items.whyTextError.errorMessage"
                        :readonly="selectisReview || !items.included"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="margin-right-40"
                v-if="items.included"
              >
                <v-btn
                  class="ma-1 primary--text"
                  plain
                  variant="text"
                  @click="handleEditCancel(indexmeds, 'medsList')"
                  >Cancel</v-btn
                >
                <v-btn
                  class="ma-1 primary--text"
                  plain
                  variant="text"
                  @click="handleClear(indexmeds, 'medsList')"
                >
                  Clear
                </v-btn>
                <v-btn
                  variant="text"
                  class="ma-1"
                  color="blue white--text"
                  @click="handleMedsSave(indexmeds, 'medsList')"
                >
                  Save
                </v-btn>
              </v-row>
              <v-row class="pd-top-10"> </v-row>
            </v-card>
            <div v-if="selectisReview !== null || selectisReview">
              <v-row
                class="mg-bottom-10 mg-left-10 pd-unset"
                v-if="!items.isShowReviewComment"
              >
                <v-col cols="9"></v-col>
                <v-col
                  cols="3"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isCommentMeds}"
                  @click="handleIsReviewerComment(indexmeds, 'medsList')"
                >
                <v-row>
                    <v-icon size="23" :class="{'gray-primary': isCommentMeds, 'blue-color': isCommentMeds === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isCommentMeds}">ADD REVIEWER COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30 mg-bottom-5" v-if="items.isShowReviewComment">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  auto-grow
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="items.remark"
                  :disabled="(selectisReview !== null || selectisReview) && !items.isReviewComment"
                  @input="changeCommentReview($event, indexmeds, 'medsList')"
                  rows="4"
                  label="QC Reviewer Comment"
                  :readonly="!selectisReview"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-bottom-20 mg-right-10 pd-unset"
              v-if="(selectisReview !== null || selectisReview) && items.isShowReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReviewComment(indexmeds, 'medsList')" 
                  v-if="items.isShowReviewBtn === false"
                  >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReviewComment(indexmeds, 'medsList')"
                    v-if="items.isShowReviewBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReviewComment(indexmeds, 'medsList')"
                    v-if="items.isReviewComment && items.isShowReviewBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                    v-if="items.isReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReviewComment(indexmeds, 'medsList')"
                    >{{items.isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            <div v-if="selectisReview === null || selectisReview === false">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset mg-bottom-10"
                v-if="items.isShowReviewComment && items.isShowReplyReviewComment === false"
              >
                <v-col cols="8"></v-col>
                <v-col
                  cols="4"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isReplyCommentMeds}"
                  @click="handleIsReplyReviewerComment(indexmeds, 'medsList')"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isReplyCommentMeds, 'blue-color': isReplyCommentMeds === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isReplyCommentMeds}">ADD REPLY FOR THE ABOVE COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30 mg-bottom-10" v-if="(selectisReview && items.isShowReviewComment && items.isShowReplyReviewComment) || (items.isShowReplyReviewComment)">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  auto-grow
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="items.remarkComment"
                  @input="changeReplyCommentReview($event, indexmeds, 'medsList')"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview === true) && !items.isReplyReviewComment"
                  :readonly="selectisReview"
                  label="Reply For QC Reviewer Comment"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview === null || selectisReview === false) && items.isShowReplyReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReplyReviewComment(indexmeds, 'medsList')" 
                    v-if="items.isShowReviewReplyBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReplyReviewComment(indexmeds, 'medsList')"
                    v-if="items.isShowReviewReplyBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReplyReviewComment(indexmeds, 'medsList')"
                    v-if="items.isReplyReviewComment && items.isShowReviewReplyBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                  v-if="items.isReplyReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReplyComment(indexmeds, 'medsList')"
                    >{{items.isShowReviewReplyBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="(selectisReview === null || selectisReview === false) && claimHistList.length > 0">
          <div v-for="(items, indexmeds) in claimHistList"
            :key="indexmeds">
            <v-card
            class="mg-bottom-50 card-border-blue"
            height="100%"
            :class="{ 'card-border-blue': items.isActive, 'card-border-gray': !items.isActive}">
            <v-row class="pd-top-10">
              <v-col cols="1"> </v-col>
              <v-col cols="5">
                <v-row class="font-size-12">
                  <v-col cols="6">
                    <b>Medication: &nbsp;</b> {{ items.drugName }}
                  </v-col>
                  <v-col cols="2">
                    <b>QTY: &nbsp;</b> {{ items.qtyDisp }}
                  </v-col>
                  <v-col cols="2">
                    <b>DS: &nbsp;</b> {{ items.daysSupplyDisp }}
                  </v-col>
                  <v-col cols="2" v-if="items.compoundFlag === 'Y'">
                    <b>Cmpnd: &nbsp;</b> {{"Yes" }}
                  </v-col>
                  <v-col cols="2" v-if="items.compoundFlag === 'N'">
                    <b>Cmpnd: &nbsp;</b> {{"Yes" }}
                  </v-col>
                  <v-col cols="2" v-else>
                    <b>Cmpnd: &nbsp;</b> {{"Unkown" }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-row class="font-size-12">
                  <v-col cols="6">
                    <b>Prescriber Name: &nbsp;</b>
                    {{ items.prescrFirstName +" "+ items.prescrLastName }}
                  </v-col>
                  <v-col cols="6">
                    <b>Last Service Date: &nbsp;</b>
                    {{items.displayDateService }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-menu
                  bottom
                  right
                  v-if="(selectisReview === false || selectisReview === null) && (isItemEdit==false && items.isActive)"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      dark
                      icon
                      v-bind="props"
                      class="mg-left-30"
                      size="20"
                      color="black"
                    >
                      mdi-dots-vertical</v-icon
                    >
                  </template>
                  <v-list>
                    <v-list-item class="dot-item-list">
                      <v-list-item-title
                        @click="edit(indexmeds, 'claimHist')"
                        >{{ "Edit" }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">
                <v-switch
                  color="blue"
                  class="pd-left-15"
                  v-model="items.isActive"
                  :disabled="isItemEdit && !items.included"
                  @click="handleStatusClaimHist(indexmeds)"
                />
              </v-col>
              <v-col cols="5">
                <v-row class="font-size-12 card-parent">
                  <v-col>
                    <v-text-field
                      label="How I Take It"
                      v-model="items.howText"
                      :error="items.howTextError.isError"
                      :error-messages="items.howTextError.errorMessage"
                      @input="handleHowText($event, indexmeds, 'claimHist', false)"
                      @blur="onBlur($event)"
                      :readonly="!items.included"
                      @focus="onFocus('claimHist',items.included)"
                      :clearable="items.included"
                      clear-icon="mdi-close-circle"
                      @click:clear="handleHowText('', indexmeds, 'claimHist', false)"
                      :disabled="!items.isActive"
                      persistent-hint
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                    <v-card
                      class="card-height"
                      tile
                      v-if="results.length > 0 && showListMedsClaim && items.included"
                    >
                      <v-list flat>
                        <v-list-item-group
                          v-model="selectedItem"
                          color="primary"
                        >
                          <v-list-item
                            v-for="(item, i) in results"
                            :key="i"
                          >
                            <v-list-item-content @click="handleHowText(item.code, indexmeds, 'claimHist', true)">
                              <v-list-item-title>{{ item.code }}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="mg-0">
                  <div class="mg-left-5" v-if="items.editmedDescription != ''">
                    <span style="text-transform: uppercase">{{ items.editmedDescription}}</span>
                  </div>
                  <div class="mg-left-5 red--text" v-if="items.found == false">
                    <b
                      >Cannot process SIG codes. Please review your input or try
                      another set of codes.</b
                    >
                  </div>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-row class="font-size-12">
                  <v-col>
                    <v-text-field
                      label="Why I Use It"
                      v-model="items.whyText"
                      :disabled="!items.isActive"
                      :error="items.whyTextError.isError"
                      :error-messages="items.whyTextError.errorMessage"
                      @input="handleWhyText($event, indexmeds, 'claimHist')"
                      :readonly="!items.included"
                      persistent-hint
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="end" class="margin-right-40" v-if="items.included">
              <v-btn
                class="ma-1 primary--text"
                plain
                variant="text"
                @click="handleEditCancel(indexmeds, 'claimHist')"
                >Cancel</v-btn
              >
              <v-btn
                class="ma-1 primary--text"
                plain
                variant="text"
                @click="handleClear(indexmeds, 'claimHist')"
              >
                Clear
              </v-btn>
              <v-btn
                variant="text"
                class="ma-1"
                color="blue white--text"
                @click="handleMedsSave(indexmeds, 'claimHist')"
              >
                Save
              </v-btn>
            </v-row>
            <!-- <v-row justify="end" class="margin-right-40" v-else>
              <v-btn class="ma-1" text disabled> Clear </v-btn>
              <v-btn class="ma-1" text disabled> Save </v-btn>
            </v-row> -->
            <v-row class="pd-top-10"> </v-row>
          </v-card>
          </div>  
        </div>
        <div class="pd-top-30"><b>Additional Medications</b></div>
        <div class="pd-top-10 mg-bottom-25">
          <b
            >This section should be used to document additional over-the-counter
            drugs, herbal, vitamins or minerals. this section is optional and
            the member shall be given their own blank rows for documenting items
            after the fact.</b
          >
        </div>
        <v-row
          class="additinal-medications mg-bottom-25"
          v-if="selectisReview === null || selectisReview === false"
        >
          <v-col
            cols="3"
            class="mouse-cursor"
            @click="addMedications()"
            :class="{ 'disabled-element': isItemEdit, 'gray-primary': isItemEdit}"
          >
            <v-row class="flex-nowrap">
              <v-icon
                size="27"
                class="pd-0 fs-14"
                :class="{ 'gray-primary': isItemEdit, 'blue-color': isItemEdit == false}"
              >
                mdi-plus
              </v-icon>
              <div
                class="primary-color mg-top-5 fs-14"
                :class="{ 'gray-primary': isItemEdit}"
              >
                <b>ADD ADDITIONAL MEDICATIONS</b>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <div class="mg-top-10" v-for="(items, indexmeds) in medicationsAddForm" :key="indexmeds">
          <v-card class="mg-bottom-50 card-border-blue" :class="{ 'card-border-blue': items.isActive, 'card-border-gray': !items.isActive}" height="100%">
            <v-row class="pd-top-30">
              <v-col cols="1">
                <v-switch
                  color="blue"
                  v-model="additionalMedincluded"
                  true-value="true"
                  false-value="false"
                  class="pd-left-15"
                  @input="medsTogglePrivacyModify(indexmeds, 'medsAddForm')"
                />
              </v-col>
              <v-col cols="5" class="mg-right-20">
                <v-row class="font-size-12">
                  <v-col>
                    <v-text-field
                      label="Medication"
                      v-model="items.medName"
                      @input="handleAddMedName($event, indexmeds, 'medsAddForm')"
                      :error="items.medNameError.isError"
                      :error-messages="items.medNameError.errorMessage"
                      persistent-hint
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5" class="mg-left-20">
                <v-row class="font-size-12">
                  <v-col>
                    <v-text-field
                      label="Prescriber"
                      v-model="items.prescriberName"
                      @input="handlePrescriberName($event, indexmeds, 'medsAddForm')"
                      persistent-hint
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1"> </v-col>
              <v-col cols="5" class="mg-right-20 card-parent">
                <v-row class="font-size-12">
                  <v-col>
                    <v-text-field
                      label="How I Take It"
                      v-model="items.howText"
                      @input="handleHowText($event, indexmeds, 'medsAddForm', false);"
                      @blur="onBlur($event)"
                      @focus="onFocus('medsAddForm',items.included)"
                      :clearable="items.included"
                      clear-icon="mdi-close-circle"
                      @click:clear="handleHowText('', indexmeds, 'medsAddForm', false)"
                      :error="items.howTextError.isError"
                      :error-messages="items.howTextError.errorMessage"
                      persistent-hint
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                    <v-card
                      class="card-height"
                      tile
                      v-if="results.length > 0 && showListMedsAddl && items.included"
                    >
                      <v-list flat>
                        <v-list-item-group
                          v-model="selectedItem"
                          color="primary"
                        >
                          <v-list-item
                            v-for="(item, i) in results"
                            :key="i"
                          >
                            <v-list-item-content @click="handleHowText(item.code, indexmeds, 'medsAddForm', true)">
                              <v-list-item-title>{{ item.code }}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="mg-0">
                  <div class="mg-left-5" v-if="items.editmedDescription != ''">
                    <span style="text-transform: uppercase">{{ items.editmedDescription }}</span>
                  </div>
                  <div class="mg-left-5 red--text" v-if="items.found == false">
                    <b
                      >Cannot process SIG codes. Please review your input or try
                      another set of codes.</b
                    >
                  </div>
                </v-row>
              </v-col>
              <v-col cols="5" class="mg-left-20">
                <v-row class="font-size-12">
                  <v-col>
                    <v-text-field
                      label="Why I Use It"
                      v-model="items.whyText"
                      persistent-hint
                      :error="items.whyTextError.isError"
                      :error-messages="items.whyTextError.errorMessage"
                      @input="handleWhyText($event, indexmeds, 'medsAddForm')"
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="end" class="margin-right-40">
              <v-btn
                class="ma-1 primary--text"
                plain
                variant="text"
                @click="handleRemove(indexmeds, 'medsAddForm')"
                >Cancel</v-btn
              >
              <v-btn
                class="ma-1 primary--text"
                plain
                variant="text"
                @click="handleClear(indexmeds, 'medsAddForm')"
              >
                Clear
              </v-btn>
              <v-btn
                class="ma-1"
                variant="text"
                color="blue white--text"
                @click="handleMedsSave(indexmeds, 'medsAddForm')"
              >
                Save
              </v-btn>
            </v-row>
          </v-card>
        </div>
        <div class="mg-top-10" v-if="(mtmAddlMedicationsList.length > 0)">
          <div
            v-for="(items, indexmeds) in mtmAddlMedicationsList"
            :key="indexmeds"
          >
            <v-card class="mg-bottom-30 card-border-blue" :class="{ 'card-border-blue': items.isActive, 'card-border-gray': !items.isActive}" height="100%">
              <v-row class="pd-top-30">
                <v-col cols="1">
                  <v-switch
                    color="blue"
                    class="pd-left-10"
                    :disabled="selectisReview || isItemEdit && !items.included"
                    v-model="items.isActive"
                    @input="handleActAndDeactiveAdditional(items)"
                  />
                </v-col>
                <v-col cols="5" class="mg-right-20">
                  <v-row class="font-size-12">
                    <v-col>
                      <v-text-field
                        label="Medication"
                        v-model="items.medName"
                        @input="handleAddMedName($event, indexmeds, 'medsAddFormUpdate')"
                        :readonly="selectisReview || !items.included"
                        persistent-hint
                        variant="outlined"
                        density="comfortable"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <v-row class="font-size-12">
                    <v-col>
                      <v-text-field
                        label="Prescriber"
                        v-model="items.prescriberName"
                        @input="handlePrescriberName($event, indexmeds, 'medsAddFormUpdate')"
                        :readonly="selectisReview || !items.included"
                        persistent-hint
                        variant="outlined"
                        density="comfortable"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <div>
                  <v-menu
                    bottom
                    right
                    v-if="(selectisReview === false || selectisReview === null) && (isItemEdit==false && items.isActive)"
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        dark
                        icon
                        v-bind="props"
                        size="20"
                        color="black"
                      >
                        mdi-dots-vertical</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item class="dot-item-list">
                        <v-list-item-title
                          @click="edit(indexmeds, 'medsAddFormUpdate')"
                          >{{ "Edit" }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item class="dot-item-list">
                        <v-list-item-title
                          @click="handleRemove(indexmeds, 'medsAddFormUpdate')"
                          >{{ "Delete" }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-row>
              <v-row>
                <v-col cols="1"> </v-col>
                <v-col cols="5" class="card-parent">
                  <v-row class="font-size-12">
                    <v-col>
                      <v-text-field
                        label="How I Take It"
                        v-model="items.howText"
                        @input="handleHowText($event, indexmeds, 'medsAddFormUpdate', false)"
                        @blur="onBlur($event)"
                        :readonly="selectisReview || !items.included"
                        @focus="onFocus('medsAddFormUpdate',items.included)"
                        :clearable="items.included"
                        clear-icon="mdi-close-circle"
                        @click:clear="handleHowText('', indexmeds, 'medsAddFormUpdate', false)"
                        persistent-hint
                        variant="outlined"
                        density="comfortable"
                      ></v-text-field>
                      <v-card
                        class="card-height"
                        tile
                        v-if="results.length > 0 && showListMedsAddlUpdate && items.included"
                      >
                        <v-list flat>
                          <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                          >
                            <v-list-item
                              v-for="(item, i) in results"
                              :key="i"
                            >
                              <v-list-item-content @click="handleHowText(item.code, indexmeds, 'medsAddFormUpdate', true)">
                                <v-list-item-title>{{ item.code }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mg-0">
                    <div
                      class="mg-left-5"
                      v-if="items.editmedDescription != ''"
                    >
                      <span style="text-transform: uppercase">{{ items.editmedDescription}}</span>
                    </div>
                    <div
                      class="mg-left-5 red--text"
                      v-if="items.found == false"
                    >
                      <b
                        >Cannot process SIG codes. Please review your input or
                        try another set of codes.</b
                      >
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="5" class="mg-left-20">
                  <v-row class="font-size-12">
                    <v-col>
                      <v-text-field
                        label="Why I Use It"
                        v-model="items.whyText"
                        persistent-hint
                        @input="handleWhyText($event, indexmeds, 'medsAddFormUpdate')"
                        :readonly="selectisReview || !items.included"
                        variant="outlined"
                        density="comfortable"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                class="margin-right-40"
                v-if="items.included"
              >
                <v-btn
                  class="ma-1 primary--text"
                  plain
                  variant="text"
                  @click="handleEditCancel(indexmeds, 'medsAddFormUpdate')"
                  >Cancel</v-btn
                >
                <v-btn
                  variant="text"
                  class="ma-1 primary--text"
                  plain
                  @click="handleClear(indexmeds, 'medsAddFormUpdate')"
                >
                  Clear
                </v-btn>
                <v-btn
                  variant="text"
                  class="ma-1"
                  color="blue white--text"
                  @click="handleMedsSave(indexmeds, 'medsAddFormUpdate')"
                >
                  Save
                </v-btn>
              </v-row>
              <v-row class="pd-top-10"> </v-row>
            </v-card>
            <div v-if="selectisReview !== null || selectisReview">
              <v-row
                class="mg-bottom-10 mg-left-10 pd-unset"
                v-if="!items.isShowReviewComment">
                <v-col cols="9"></v-col>
                <v-col
                  cols="3"
                  :class="{ 'disabled-element': isCommentAddl}"
                  class="mouse-cursor"
                  @click="handleIsReviewerComment(indexmeds, 'addlMeds')">
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isCommentAddl, 'blue-color': isCommentAddl === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isCommentAddl}">ADD REVIEWER COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30" v-if="items.isShowReviewComment">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  auto-grow
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="items.remark"
                  @input="changeCommentReview($event, indexmeds, 'addlMeds')"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview) && !items.isReviewComment"
                  label="QC Reviewer Comment"
                  :readonly="!selectisReview"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-bottom-5 mg-right-10 pd-unset"
              v-if="(selectisReview !== null || selectisReview) && items.isShowReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReviewComment(indexmeds, 'addlMeds')" 
                  v-if="items.isShowReviewBtn === false"
                  >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReviewComment(indexmeds, 'addlMeds')"
                    v-if="items.isShowReviewBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReviewComment(indexmeds, 'addlMeds')"
                    v-if="items.isReviewComment && items.isShowReviewBtn"
                    >DELETE</v-btn
                  >
                  <v-btn v-if="items.isReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReviewComment(indexmeds, 'addlMeds')"
                    >{{items.isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            <div v-if="selectisReview === null || selectisReview === false">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset mg-bottom-5"
                v-if="items.isShowReviewComment && items.isShowReplyReviewComment === false"
              >
                <v-col cols="8"></v-col>
                <v-col
                  cols="4"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isReplyCommentAddl}"
                  @click="handleIsReplyReviewerComment(indexmeds, 'addlMeds')"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isReplyCommentAddl, 'blue-color': isReplyCommentAddl === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isReplyCommentAddl}">ADD REPLY FOR THE ABOVE COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30 mg-bottom-10" v-if="(selectisReview && items.isShowReviewComment && items.isShowReplyReviewComment) || (items.isShowReplyReviewComment)">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  auto-grow
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="items.remarkComment"
                  @input="changeReplyCommentReview($event, indexmeds, 'addlMeds')"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview === true) && !items.isReplyReviewComment"
                  :readonly="selectisReview"
                  label="Reply For QC Reviewer Comment"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview === null || selectisReview === false) && items.isShowReplyReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReplyReviewComment(indexmeds, 'addlMeds')" 
                    v-if="items.isShowReviewReplyBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReplyReviewComment(indexmeds, 'addlMeds')"
                    v-if="items.isShowReviewReplyBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReplyReviewComment(indexmeds, 'addlMeds')"
                    v-if="items.isReplyReviewComment && items.isShowReviewReplyBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                  v-if="items.isReplyReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReplyComment(indexmeds, 'addlMeds')"
                    >{{items.isShowReviewReplyBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="selectisReview !== null || selectisReview">
          <div class="mg-top-6rm txt-align-center fs-25 font-weight-400">
            Document to Review
          </div>
          <div class="mg-top-20">
            <iframe
              id="pdf"
              :src="url"
              frameborder="1"
              scrolling="auto"
              width="100%"
              height="600px"
              class="pdf-viewer"
            ></iframe>
          </div>
        </div>
      </v-col>
    </div>
    <AlertDialogs
      v-if="isHideShowAlertDialog"
      :alert-type="alertType"
      :alert-message="alertMessage"
      :alert-message-type="alertMessageType"
      @closeDialog="closeAlertDialog"
      @onConfirmOK="handleAlertConfirmation"
    />
    <v-snackbar
      v-model="snackbar"
      v-bind:color="snackColor"
      :timeout="snackTimeout"
    >
      <span
        :class="{ 'black--text': snackColor== '#CDDC39', 'white--text': snackColor== '#DD2C00' }"
        class=""
      >
        {{snackStatus}}
      </span>
    </v-snackbar>
  </v-container>
</template>
<script>
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import store from '../store'
import axios from 'axios'
import interceptorSetup from '../helpers/httpInterceptor'
export default {
  name: "MtmMeds",
  components: {
    AlertDialogs
  },
  data() {
    return {
      medicationsAddForm: [],
      medicinesList: [],
      claimHistList: [],
      alertType: "",
      alertMessage: "",
      alertMessageType: "",
      confirmType: "",
      isHideShowAlertDialog: false,
      pageReload: false,
      translationDict: [],
      mtmAddlMedicationsList: [],
      isItemEdit: false,
      addlMedsClearIndex: '',
      addlMedsCancel: '',
      medsListClearIndex: '',
      isUpdate: false,
      snackbar: false,
      snackStatus: null,
      snackColor: "black",
      interval: null,
      snackTimeout: "20000",
      autoSaveTimeout: "30000",
      errorTimout: null,
      autoSaveValidation: false,
      prevHowText: '',
      prevWhyText: '',
      prevPrescriber: '',
      prevMedName: '',
      prevDesc: '',
      url: null,
      memberDetails: undefined,
      smtForReviewDate: null,
      memberName: '',
      isCommentMeds: false,
      isReplyCommentMeds: false,
      isCommentAddl: false,
      isReplyCommentAddl: false,
      results: [],
      selectedItem: null,
      showListMeds: false,
      showListMedsAddl: false,
      showListMedsAddlUpdate: false,
      showListMedsClaim: false,
      isClaimHistActive: true,
      isShowIntruction: true,
      additionalMedincluded: "false"
    }
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.errorTimout);
  },
  computed: {
    selectedMemberId () {
      return store.getters.selectedMemberId
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    selectdocumentId() {
      return store.getters.selectdocumentId;
    },
    selectisReview() {
      return store.getters.selectisReview
    },
    memberDetail () {
      return store.getters.memberDetail;
    },
    selectClinicianName() {
      return store.getters.selectClinicianName
    },
    selectReviewerId() {
      return store.getters.selectReviewerId;
    }
  },
  methods: {
    handleTopAndDown(){
      if(this.isShowIntruction){
        this.isShowIntruction = false
      } else {
        this.isShowIntruction = true
      }
    },
    handleStatusClaimHist(index){
      this.isClaimHistActive = false
      const tempClaim = this.claimHistList[index];
      const objData = {
        companyId:1,
        clientId: this.clientData.clientId,
        memberId: this.memberId,
        documentId: this.documentId,
        gpi8 : tempClaim.drugGPI,
        whyText: tempClaim.whyText,
        howText: tempClaim.howText,
        description: {
          gpi8 : tempClaim.drugGPI,
          whyText : tempClaim.whyText,
          howText: tempClaim.howText
        },
        medName: tempClaim.drugName,
        quantity: tempClaim.qtyDisp,
        daysSupply: tempClaim.daysSupplyDisp ,
        prescriberName: tempClaim.prescrFirstName +' '+ tempClaim.prescrLastName,
        dateOfService: tempClaim.dateService,
        included: false,
        uniqueTxNbr: tempClaim.uniqueTxNbr,
        activeStatus: "active"
      }
      if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        store.dispatch("showLoadingSpinner");
        this.createMtmMeds(objData, this.clientData.clientId, 'medsList');
      }
    },
    search(searchText) {
      if(searchText == '' || searchText == null) {
        this.results = [];
      } else {
        this.results = this.translationDict.filter((value) => value.code.toLowerCase().includes(searchText) || value.desc.toLowerCase().includes(searchText));
      } 
    },
    onBlur() {
      setTimeout(() => {
        this.results = [];
        this.showListMeds = false;
        this.showListMedsAddl = false; 
        this.showListMedsClaim = false; 
        this.showListMedsAddlUpdate = false;
      }, 200);
    },
    onFocus(medType, isEdit) {
      if(medType === "medsList" && isEdit) {
        this.showListMeds = true;
      } else if(medType === "medsAddForm" && isEdit) {
        this.showListMedsAddl = true;
      } else if(medType === "claimHist" && isEdit) {
        this.showListMedsClaim = true;
      } else if(medType === "medsAddFormUpdate" && isEdit) {
        this.showListMedsAddlUpdate = true;
      }
    },
    handleAddReviewComment(index, medType) {
      if(medType === "medsList") {
        const medsObj = this.medicinesList[index]
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateMtmMedicationDetails(medsObj)
        }
      } else {
        const addlMedsObj = this.mtmAddlMedicationsList[index]
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateAddlMtmMedicationDetails(addlMedsObj)
        }
      }
    },
    handleAddReplyComment(index, medType) {
      if(medType === "medsList") {
        const medsObj = this.medicinesList[index]
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateMtmMedicationDetails(medsObj)
        }
      } else {
        const addlMedsObj = this.mtmAddlMedicationsList[index]
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateAddlMtmMedicationDetails(addlMedsObj)
        }
      }
    },
    handleDeleteReviewComment(index, medType) {
      if(medType === "medsList") {
        const medsObj = this.medicinesList[index]
        medsObj.remark = null
        medsObj.remarkComment = null
        medsObj.isShowReviewComment = false
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateMtmMedicationDetails(medsObj)
        }
      } else {
        const addlMedsObj = this.mtmAddlMedicationsList[index]
        addlMedsObj.remark = null
        addlMedsObj.remarkComment = null
        addlMedsObj.isShowReviewComment = false
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateAddlMtmMedicationDetails(addlMedsObj)
        }
      }
    },
    handleDeleteReplyReviewComment(index, medType) {
      if(medType === "medsList") {
        const medsObj = this.medicinesList[index]
        medsObj.remarkComment = null
        medsObj.isShowReplyReviewComment = false
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateMtmMedicationDetails(medsObj)
        }
      } else {
        const addlMedsObj = this.mtmAddlMedicationsList[index]
        addlMedsObj.remarkComment = null
        addlMedsObj.isShowReplyReviewComment = false
        const clientId = this.clientData.clientId
        if (clientId !== null && this.memberId !== null ) {
          this.updateAddlMtmMedicationDetails(addlMedsObj)
        }
      }
    },
    changeCommentReview(value, index, medType) {
      const newValue = value.target.value
      if(medType === "medsList") {
        this.medicinesList.forEach(items => {
          items.isReviewComment = false
        });
        this.isCommentMeds = true
        this.medicinesList[index].isReviewComment = true
        this.medicinesList[index].remark = newValue
      } else {
        this.mtmAddlMedicationsList.forEach(items => {
          items.isReviewComment = false
        });
        this.isCommentAddl = true
        this.mtmAddlMedicationsList[index].isReviewComment = true
        this.mtmAddlMedicationsList[index].remark = newValue
      }
    },
    changeReplyCommentReview(value, index, medType) {
      let newValue = value.target.value
      if(medType === "medsList") {
        this.medicinesList.forEach(items => {
          items.isReplyReviewComment = false
        });
        console.log("newValue ReplyCommentReview"+newValue);
        this.isReplyCommentMeds = true
        this.medicinesList[index].isReplyReviewComment = true
        this.medicinesList[index].remarkComment = newValue
      } else {
        this.mtmAddlMedicationsList.forEach(items => {
          items.isReplyReviewComment = false
        });
        this.isReplyCommentAddl = true
        this.mtmAddlMedicationsList[index].isReplyReviewComment = true
        this.mtmAddlMedicationsList[index].remarkComment = newValue
      }
    },
    handleClearReviewComment(index, medType) {
      if(medType === "medsList") {
        this.medicinesList[index].remark = ""
      } else {
        this.mtmAddlMedicationsList[index].remark = ""
      }
    },
    handleClearReplyReviewComment(index, medType) {
      if(medType === "medsList") {
        this.medicinesList[index].remarkComment = ""
      } else {
        this.mtmAddlMedicationsList[index].remarkComment = ""
      }
    },
    handleCancelReviewComment(index, medType) {
      if(medType === "medsList") {
        this.medicinesList.forEach(items => {
          items.isReviewComment = true
          items.remark = ""
        });
        this.medicinesList[index].isShowReviewComment = false
        this.isCommentMeds = false
      } else {
        this.mtmAddlMedicationsList.forEach(items => {
          items.isReviewComment = true
          items.remark = ""
        });
        this.mtmAddlMedicationsList[index].isShowReviewComment = false
        this.isCommentAddl = false
      }
    },
    handleCancelReplyReviewComment(index, medType) {
      if(medType === "medsList") {
        this.medicinesList.forEach(items => {
          items.isReplyReviewComment = true
          items.remarkComment = ""
        });
        this.medicinesList[index].isShowReplyReviewComment = false
        this.isCommentMeds = false
      } else {
        this.mtmAddlMedicationsList.forEach(items => {
          items.isReplyReviewComment = true
          items.remarkComment = ""
        });
        this.mtmAddlMedicationsList[index].isShowReplyReviewComment = false
        this.isCommentAddl = false
      }
    },
    handleIsReviewerComment(index, medType) {
      if(medType === "medsList") {
        this.medicinesList[index].isShowReviewComment = true
        this.isCommentMeds = true
        this.isReplyCommentMeds = true
      } else {
        this.mtmAddlMedicationsList[index].isShowReviewComment = true
        this.isReplyCommentAddl = true
      }
    },
    handleIsReplyReviewerComment(index, medType) {
      if(medType === "medsList") {
        this.medicinesList[index].isShowReplyReviewComment = true
        this.isCommentMeds = true
      } else {
        this.mtmAddlMedicationsList[index].isShowReplyReviewComment = true
        this.isCommentAddl = true
      }
    },
    handleAlertConfirmation () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      if(this.confirmType === 'clearAddlMeds') {
        this.clearAddlMeds (this.addlMedsClearIndex)
      } else if(this.confirmType === 'cancelAddlMeds') {
        this.additionalMedincluded = "false"
        this.cancelAddlMeds (this.addlMedsClearIndex)
      } else if (this.confirmType === 'clearMedsList') {
        this.cancelMedsList (this.medsListClearIndex)
      } else if(this.confirmType === 'claimHist') {
        this.handleCancel(this.addlMedsClearIndex, this.confirmType);
      } else if(this.confirmType === 'medsList') {
        this.handleCancel(this.addlMedsClearIndex, this.confirmType);
      } else if (this.confirmType === 'medsAddFormUpdate') {
        this.handleCancel(this.addlMedsClearIndex, this.confirmType);
      }
    },
    clearAddlMeds(index) {
      this.mtmAddlMedicationsList[index].medName = ''
      this.mtmAddlMedicationsList[index].prescriberName = ''
      this.mtmAddlMedicationsList[index].whyText = ''
      this.mtmAddlMedicationsList[index].howText = ''
      this.mtmAddlMedicationsList[index].editmedDescription = ''
    },
    cancelAddlMeds(index) {
      if(this.isUpdate) {
        this.getDeleteAddlMedications(this.medicationsAddForm[0].id)
      }
      this.medicationsAddForm.splice(index, 1);
      this.isItemEdit = false
    },
    cancelMedsList(index) {
      this.medicinesList[index].whyText =''
      this.medicinesList[index].howText =''
      this.medicinesList[index].editmedDescription =''
    },
    handleActAndDeactiveMedication(item) {
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        var activeStatus;
        if(item.isActive == true) {
          activeStatus = 'active';
        } else {
          activeStatus = 'deactive';
        }
        this.updateStatusActionMedication(item.id, activeStatus);
      }
    },
    updateStatusActionMedication (id, action) {
      axios
      .get(
        "/mtm/medications/medicationupdateaction?clientId=" +this.clientData.clientId+ "&id=" +
          id +
          "&action=" +
          action
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    handleActAndDeactiveAdditional(item) {
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        var activeStatus;
        if(item.isActive == true) {
          activeStatus = 'active';
        } else {
          activeStatus = 'deactive';
        }
        this.updateStatusActionAdditional(item.id, activeStatus);
      }
    },
    updateStatusActionAdditional (id, action) {
      axios
      .get(
        "/mtm/addlMedications/addlmedicationupdateaction?clientId=" +this.clientData.clientId+ "&id=" +
          id +
          "&action=" +
          action
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    edit(index, medsType) {
      if (medsType === 'claimHist') {
        this.prevHowText = this.claimHistList[index].howText;
        this.prevWhyText = this.claimHistList[index].whyText;
        this.claimHistList[index].included = true;
        this.isItemEdit = true;
      } else if (medsType === 'medsList') {
        this.prevHowText = this.medicinesList[index].howText;
        this.prevWhyText = this.medicinesList[index].whyText;
        this.medicinesList[index].included = true;
        this.isItemEdit = true;
      } else if (medsType === 'medsAddFormUpdate') {
        this.mtmAddlMedicationsList[index].included = true;
        this.isItemEdit = true;
        this.prevHowText = this.mtmAddlMedicationsList[index].howText;
        this.prevWhyText = this.mtmAddlMedicationsList[index].whyText;
        this.prevPrescriber = this.mtmAddlMedicationsList[index].prescriberName;
        this.prevMedName = this.mtmAddlMedicationsList[index].medName;
      }
    },
    handleCancel(index, medsType) {
      if (medsType === 'claimHist') {
        this.claimHistList[index].howText =  this.prevHowText;
        this.claimHistList[index].whyText = this.prevWhyText;
        this.claimHistList[index].included = false;
        this.claimHistList[index].found = true;
        this.handleHowText(this.claimHistList[index].howText, index, medsType, true);
        this.isItemEdit = false;
      } else if (medsType === 'medsList') {
        this.medicinesList[index].howText = this.prevHowText;
        this.medicinesList[index].whyText = this.prevWhyText;
        this.medicinesList[index].included = false;
        this.medicinesList[index].found = true;
        this.handleHowText(this.medicinesList[index].howText, index, medsType, true);
        this.isItemEdit = false;
      } else if (medsType === 'medsAddFormUpdate') {
        this.mtmAddlMedicationsList[index].howText = this.prevHowText;
        this.mtmAddlMedicationsList[index].whyText = this.prevWhyText;
        this.mtmAddlMedicationsList[index].prescriberName = this.prevPrescriber;
        this.mtmAddlMedicationsList[index].medName = this.prevMedName;
        this.mtmAddlMedicationsList[index].included = false;
        this.isItemEdit = false;
        this.mtmAddlMedicationsList[index].found = true;
        this.handleHowText(this.mtmAddlMedicationsList[index].howText, index, medsType, true);
      }
    },
    handleHowText(value, index, medsType, isSelected) {
      let newValue = ""
      if (isSelected) {
        newValue = value.target?.value
      } else {
        newValue = value.target?.value
      }
      this.search(newValue);
      if (medsType === 'medsList') {
        if(newValue !== "" && newValue !== null) {
          var text = newValue.toString().toLowerCase();
          if(text.includes(" or ")){
            text = text.split(" or ")[0];
          }
          //text = text.replace(/\s/g, '');
          this.medicinesList[index].howText = text;
          this.medicinesList[index].found = false;
          this.translationDict.some(items => {  
            if(items.code.toString().toLowerCase() === text || items.code.toString().toLowerCase() === newValue.toString().toLowerCase()) {
              this.medicinesList[index].editmedDescription = items.desc
              this.medicinesList[index].howTextError.isError = false
              this.medicinesList[index].howTextError.errorMessage = ""
              this.medicinesList[index].found = true;
            }
          });
          if(!this.medicinesList[index].found) {
            this.medicinesList[index].editmedDescription = ""
          }
        } else {
          this.medicinesList[index].editmedDescription = ""
        }
      } else if(medsType === "claimHist")  {
        if(newValue !== "" && newValue !== null) {
          var text = newValue.toString().toLowerCase();
          if(text.includes(" or ")){
            text = text.split(" or ")[0];
            // console.log("text",text)
          }
          //text = text.replace(/\s/g, '');
          this.claimHistList[index].howText = text;
          this.claimHistList[index].found = false;
          this.translationDict.some(items => {
            if(items.code.toString().toLowerCase() === text || items.code.toString().toLowerCase() === newValue.toString().toLowerCase()) {
              this.claimHistList[index].editmedDescription = items.desc
              this.claimHistList[index].howTextError.isError = false
              this.claimHistList[index].howTextError.errorMessage = ""
              this.claimHistList[index].found = true;
            }
          });
          if(!this.claimHistList[index].found) {
            this.claimHistList[index].editmedDescription = ""
          }
        } else {
          this.claimHistList[index].editmedDescription = ""
        }
      } else if(medsType === "medsAddForm") {
          if(newValue !== "" && newValue !== null) {
            var text = newValue.toString().toLowerCase();
            if(text.includes(" or ")){
              text = text.split(" or ")[0];
            }
            //text = text.replace(/\s/g, '');
            this.medicationsAddForm[index].howText = text;
            this.medicationsAddForm[index].found = false;
            this.translationDict.some(items => {
              if(items.code.toLowerCase() === text || items.code.toLowerCase() === newValue.toString().toLowerCase()) {
                this.medicationsAddForm[index].found = true;
                this.medicationsAddForm[index].editmedDescription = items.desc
                this.medicationsAddForm[index].howTextError.isError = false
                this.medicationsAddForm[index].howTextError.errorMessage = ""
              }
            });
            if(!this.medicationsAddForm[index].found) {
              this.medicationsAddForm[index].editmedDescription = ""
            }
          } else {
            this.medicationsAddForm[index].editmedDescription = ""
          }
      } else if(medsType === "medsAddFormUpdate") {
          if(newValue !== "" && newValue !== null) {
            var text = newValue.toString().toLowerCase();
            if(text.includes(" or ")){
              text = text.split(" or ")[0];
            }
            text = text.toLowerCase().toString().replace(/\s/g, '');
            this.mtmAddlMedicationsList[index].howText = text;
            this.mtmAddlMedicationsList[index].found = false;
            this.translationDict.some(items => {
              if(items.code.toString().toLowerCase() === text || items.code.toString().toLowerCase() === newValue.toString().toLowerCase()) {
                this.mtmAddlMedicationsList[index].editmedDescription = items.desc
                this.mtmAddlMedicationsList[index].found = true;
              }
            });
            if(!this.mtmAddlMedicationsList[index].found) {
              this.mtmAddlMedicationsList[index].editmedDescription = ""
            }
          } else {
            this.mtmAddlMedicationsList[index].editmedDescription = ""
        }
      }
    },
    handleWhyText(value, index, medsType) {
      const newValue = value.target.value
      if (medsType === 'medsList') {
        this.medicinesList[index].description.whyText = newValue
        this.medicinesList[index].whyTextError.isError = false
        this.medicinesList[index].whyTextError.errorMessage = ""
      } else if (medsType === "claimHist") {
        this.claimHistList[index].whyText = newValue
        this.claimHistList[index].whyTextError.isError = false
        this.claimHistList[index].whyTextError.errorMessage = ""
      } else if (medsType === "medsAddForm") {
        this.medicationsAddForm[index].whyText = newValue
        this.medicationsAddForm[index].whyTextError.isError = false
        this.medicationsAddForm[index].whyTextError.errorMessage = ""
      } else if(medsType === "medsAddFormUpdate") {
        this.mtmAddlMedicationsList[index].whyText = newValue
      }
    },
    handleAddMedName(value, index, medsType) {
      const newValue = value.target.value
      if(medsType === "medsAddFormUpdate"){
        this.mtmAddlMedicationsList[index].medName = newValue
      } else if(medsType === "medsAddForm") {
        this.medicationsAddForm[index].medName = newValue
        this.medicationsAddForm[index].medNameError.isError = false
        this.medicationsAddForm[index].medNameError.errorMessage = ""
      }
    },
    handlePrescriberName(value, index, medsType){
      const newValue = value.target.value
      if(medsType === "medsAddFormUpdate"){
        this.mtmAddlMedicationsList[index].prescriberName = newValue
      } else if(medsType === "medsAddForm") {
        this.medicationsAddForm[index].prescriberName = newValue
      }
    },
    handleClear(index, medsType) {
      if(medsType === 'medsAddForm') {
          this.medicationsAddForm[index].medName = ''
          this.medicationsAddForm[index].prescriberName = ''
          this.medicationsAddForm[index].whyText = ''
          this.medicationsAddForm[index].editmedDescription = ''
          this.medicationsAddForm[index].howText = ''
          this.medicationsAddForm[index].found = true
      } else if(medsType === 'medsAddFormUpdate') {
          this.addlMedsClearIndex = index
          this.alertMessage = 'Are you sure you want to clear the content?'
          this.alertType = 'Confirmation'
          this.alertMessageType= 'No'
          this.confirmType = 'clearAddlMeds'
          this.isHideShowAlertDialog = true
      } else {
          this.medsListClearIndex = index
          this.alertMessage = 'Are you sure you want to clear the content?'
          this.alertType = 'Confirmation'
          this.alertMessageType= 'No'
          this.confirmType = 'clearMedsList'
          this.isHideShowAlertDialog = true
      }
    },
    handleEditCancel(indexmeds, medsType) {
      this.addlMedsClearIndex = indexmeds
      this.alertMessage = 'Are you sure you want to cancel?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = medsType
      this.isHideShowAlertDialog = true
    },
    handleRemove(index, medsType) {
      if (medsType === 'claimHist') {
        this.alertType = "Failed"
        this.alertMessage = "You do not delete ClaimHist!"
        this.isHideShowAlertDialog = true
      } else if (medsType === 'medsAddForm') {
        this.addlMedsCancel = index
        this.alertMessage = 'Are you sure you want to cancel?'
        this.alertType = 'Confirmation'
        this.alertMessageType= 'No'
        this.confirmType = 'cancelAddlMeds'
        this.isHideShowAlertDialog = true
      } else if (medsType === 'medsList') {
        const id = this.medicinesList[index].id;
        this.getDeleteMedications(id)
      } else if (medsType === 'medsAddFormUpdate') {
        const id = this.mtmAddlMedicationsList[index].id;
        this.getDeleteAddlMedications(id)
      }
    },
    getDeleteMedications(id) {
      axios
      .delete("/mtm/medications/getdeleteById?clientId=" +this.clientData.clientId+ "&Id=" + id)
      .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              const responseIndex = this.medicinesList.findIndex((item) => item.id === id);
              if (responseIndex !== -1) {
                this.medicinesList.splice(responseIndex, 1);
              }
              this.alertType = "Success";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
              this.pageReload = true;
            } else if (response.data.code === 401) {
              this.alertType = "Failed";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
              this.pageReload = true;
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
          }
        })
        .catch((error) => {
          console.log(error);
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
          this.pageReload = true;
      });
    },
    getDeleteAddlMedications(id) {
      axios
      .delete("/mtm/addlMedications/getdeleteById?clientId=" +this.clientData.clientId+ "&Id=" + id)
      .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              const responseIndex = this.mtmAddlMedicationsList.findIndex((item) => item.id === id);
              if (responseIndex !== -1) {
                this.mtmAddlMedicationsList.splice(responseIndex, 1);
              }
              this.alertType = "Success";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
              this.pageReload = true;
              // this.isItemEdit = false
            } else if (response.data.code === 401) {
              this.alertType = "Failed";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
              this.pageReload = true;
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
          }
        })
        .catch((error) => {
          console.log(error);
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
          this.pageReload = true;
      });
    },
    closeAlertDialog () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      this.confirmType = ''
      if(this.pageReload) {
        if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
          store.dispatch("showLoadingSpinner");
          this.getAllMtmMeds(this.clientData.clientId, this.memberId, this.documentId)
        }
      }
    },
    handleActAndDeactiveClaimHist(index, medsType) {
    const tempClaim= this.claimHistList[index];
      const objData = {
        companyId:1,
        clientId: this.clientData.clientId,
        memberId: this.memberId,
        documentId: this.documentId,
        gpi8 : tempClaim.drugGPI,
        whyText: tempClaim.whyText,
        howText: tempClaim.howText,
        description: {
          gpi8 : tempClaim.drugGPI,
          whyText : tempClaim.whyText,
          howText: tempClaim.howText
        },
        medName: tempClaim.drugName,
        quantity: tempClaim.qtyDisp,
        daysSupply: tempClaim.daysSupplyDisp ,
        prescriberName: tempClaim.prescrFirstName +' '+ tempClaim.prescrLastName,
        dateOfService: tempClaim.dateService,
        included: false,
        uniqueTxNbr: tempClaim.uniqueTxNbr,
        activeStatus: "active"
      }
      let isValid = true
      if (this.claimHistList[index].whyText === '' || this.claimHistList[index].whyText === null) {
        this.claimHistList[index].whyTextError.isError = true
        this.claimHistList[index].whyTextError.errorMessage = 'You must enter why text.'
        isValid = false
      }
      if (this.claimHistList[index].howText === '' || this.claimHistList[index].howText === null) {
        this.claimHistList[index].howTextError.isError = true
        this.claimHistList[index].howTextError.errorMessage = 'You must enter how text.'
        isValid = false
      }
      if(isValid) {
        if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
          store.dispatch("showLoadingSpinner");
          this.createMtmMeds(objData, this.clientData.clientId, this.memberId, medsType);
        }
      } else {
        this.claimHistList[index].isActive = true;
        this.claimHistList[index].included = !this.claimHistList[index].included;
        this.isItemEdit = !this.isItemEdit
      }
    },
    handleMedsSave(index, medsType) {
      if(medsType === 'medsAddForm') {
        if(!Object.prototype.hasOwnProperty.call(this.medicationsAddForm[index], "id")) {
          const tempMedicationsAddForm= this.medicationsAddForm[index];
          tempMedicationsAddForm.clientId = this.clientData.clientId;
          tempMedicationsAddForm.memberId = this.memberId;
          tempMedicationsAddForm.documentId = this.documentId;
          if(this.isValidation()) {
            if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
              store.dispatch("showLoadingSpinner");
              this.createMtmAddMeds(tempMedicationsAddForm, index);
            }
          }
        } else {
          this.handleUpdateAllMedicationDetails(index)
        }
      } else if (medsType === 'medsList') {
        this.handleUpdateMedicationDetails(index)
      } else if(medsType === 'medsAddFormUpdate') {
        this.handleUpdateAllMedicationDetails(index)
      }else {
        let isValid = true
        if (this.claimHistList[index].whyText === '' || this.claimHistList[index].whyText === null) {
          this.claimHistList[index].whyTextError.isError = true
          this.claimHistList[index].whyTextError.errorMessage = 'You must enter why text.'
          isValid = false
        }
        if (this.claimHistList[index].howText === '' || this.claimHistList[index].howText === null) {
          this.claimHistList[index].howTextError.isError = true
          this.claimHistList[index].howTextError.errorMessage = 'You must enter how text.'
          isValid = false
        }
        const tempClaim= this.claimHistList[index];
        const objData = {
          companyId:1,
          clientId: this.clientData.clientId,
          memberId: this.memberId,
          documentId: this.documentId,
          gpi8 : tempClaim.drugGPI,
          whyText: tempClaim.whyText,
          howText: tempClaim.howText,
          description: {
            gpi8 : tempClaim.drugGPI,
            whyText : tempClaim.whyText,
            howText: tempClaim.howText
          },
          medName: tempClaim.drugName,
          quantity: tempClaim.qtyDisp,
          daysSupply: tempClaim.daysSupplyDisp ,
          prescriberName: tempClaim.prescrFirstName +' '+ tempClaim.prescrLastName,
          dateOfService: tempClaim.dateService,
          included: false,
          uniqueTxNbr: tempClaim.uniqueTxNbr,
          activeStatus: tempClaim.isActive ? "active": "deactive"
        }
        if(isValid) {
          if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
            store.dispatch("showLoadingSpinner");
            this.createMtmMeds(objData, this.clientData.clientId, 'medsList');
          }
        }
      }
    },
    createMtmMeds (objData, clientId, medsType) {
    axios
      .post(
        "/mtm/medications/save?clientId="+clientId,
        objData
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            if(this.isClaimHistActive){
              this.alertType = "Success";
              if(medsType === 'claimHist') {
                this.alertMessage = "MTM Medication has been " + objData.activeStatus + " Successfull";
              } else {
                this.alertMessage = response.data.message;
              }
              this.isHideShowAlertDialog = true;
              this.isItemEdit = false;
            } else {
              this.getAllMtmMeds(this.clientData.clientId, this.memberId, this.documentId)
            }
            this.pageReload = true;
            this.isClaimHistActive = true
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    createMtmAddMeds (objData, index) {
    axios
      .post(
        "/mtm/addlMedications/save",
        objData
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
            this.isItemEdit = false;
            this.additionalMedincluded = "false"
            this.medicationsAddForm.splice(index, 1);
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    handleUpdateAllMedicationDetails(index) {
      var tempAddlmedicinesForm;
      if(this.medicationsAddForm.length > 0 && this.medicationsAddForm[index].hasOwnProperty('id')) {
        tempAddlmedicinesForm = this.medicationsAddForm[index];
      } else  {
        tempAddlmedicinesForm = this.mtmAddlMedicationsList[index];
      }

      if(tempAddlmedicinesForm !== null && this.clientData.clientId != null && this.memberId != null && this.documentId != null && tempAddlmedicinesForm.clientId === this.clientData.clientId){
        this.updateAddlMtmMedicationDetails (tempAddlmedicinesForm)
      }
    },
    updateAddlMtmMedicationDetails (tempAddlmedicinesForm) {
    axios
      .post('/mtm/addlMedications/update?id=' +tempAddlmedicinesForm.id, tempAddlmedicinesForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.additionalMedincluded = "false"
            this.isHideShowAlertDialog = true
            if(this.medicationsAddForm.length > 0 && Object.prototype.hasOwnProperty.call(this.medicationsAddForm[0], "id")) {
              this.medicationsAddForm.splice(0, 1);
              this.pageReload = true;
              this.isItemEdit = false;
            }
            this.pageReload = true;
            this.isItemEdit = false;
            this.isCommentAddl = false
            this.isReplyCommentAddl = false
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
        }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
      })
    },
    handleUpdateMedicationDetails(index) {
      const tempmedicinesForm = this.medicinesList[index];
      let isValid = true
      if (this.medicinesList[index].whyText === '') {
        this.medicinesList[index].whyTextError.isError = true
        this.medicinesList[index].whyTextError.errorMessage = 'You must enter why text.'
        isValid = false
      }
      if (this.medicinesList[index].howText === '') {
        this.medicinesList[index].howTextError.isError = true
        this.medicinesList[index].howTextError.errorMessage = 'You must enter how text.'
        isValid = false
      }
      if(isValid) {
        if(tempmedicinesForm !== null && this.clientData.clientId != null && this.memberId != null && this.documentId != null && tempmedicinesForm.clientId === this.clientData.clientId){
          this.updateMtmMedicationDetails (tempmedicinesForm)
        }
      }
    },
    updateMtmMedicationDetails (tempmedicinesForm) {
    axios
      .post('/mtm/medications/updatemedications?clientId=' +this.clientData.clientId, tempmedicinesForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true
            this.isItemEdit = false;
            this.isCommentMeds = false
            this.isReplyCommentMeds = false
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
        }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
      })
    },
    mapResponseWithFormData (medsData) {
      this.translationDict = medsData.medicationsTranslationDict
      if(medsData.mtmMedications !== null) {
        const tempMedsData = [...medsData.mtmMedications]
        tempMedsData.forEach((items) => {
          items.isReviewComment = true;
          items.isReplyReviewComment = true;
          items.found = true;
          items.included = false;
          items.isActive = true;
          if(items.remark == null){
            items.isShowReviewComment = false
            items.isShowReviewBtn = false
          } else {
            items.isShowReviewComment = true
            items.isShowReviewBtn = true
          }
          if(items.remarkComment === null || items.remarkComment === ''){
            items.isShowReplyReviewComment = false
            items.isShowReviewReplyBtn = false
          } else {
            items.isShowReplyReviewComment = true
            items.isShowReviewReplyBtn = true
          }
          if(items.activeStatus == 'active') {
            items.isActive = true;
          } else {
            items.isActive = false;
          }
	        items.whyTextError = { isError: false, errorMessage: '' };
          items.howTextError = { isError: false, errorMessage: '' };
          items.editmedDescription = '';
          this.translationDict.some(tansItems => {
            var how = items.howText;
            var code = tansItems.code;
            if(how && how.toLowerCase() === code.toLowerCase()) {
              items.editmedDescription = tansItems.desc;
            }
          })
        });
        this.medicinesList = tempMedsData;
      }
      if(medsData.claimHist !== null || medsData.claimHist.length > 0) {
        const tempClaimData = [...medsData.claimHist]
        tempClaimData.forEach((items) => {
          items.found = true;
          items.included = false
          items.activeStatus = "active"
          items.isActive= false;
	        items.whyTextError = { isError: false, errorMessage: '' };
          items.howTextError = { isError: false, errorMessage: '' };
          items.editmedDescription = '';
          this.translationDict.some(tansItems => {
            var how = items.howText;
            var code = tansItems.code
            if( how && how.toLowerCase() === code.toLowerCase()) {
              items.editmedDescription = tansItems.desc;
            }
          })
        });
        this.claimHistList = tempClaimData;
      }
      const tempMtmAddlMedications = [...medsData.mtmAddlMedications]
      tempMtmAddlMedications.forEach((items) => {
        items.isReviewComment = true;
        items.isReplyReviewComment = true;
        if(items.remark == null){
          items.isShowReviewComment = false
          items.isShowReviewBtn = false
        } else {
          items.isShowReviewComment = true
          items.isShowReviewBtn = true
        }
        if(items.remarkComment === null || items.remarkComment === ''){
          items.isShowReplyReviewComment = false
          items.isShowReviewReplyBtn = false
        } else {
          items.isShowReplyReviewComment = true
          items.isShowReviewReplyBtn = true
        }
        items.found = true;
        items.included = false;
        items.isActive = true;
        if(items.activeStatus == 'active') {
          items.isActive = true;
        } else {
          items.isActive = false;
        }
        items.editmedDescription = '';
        this.translationDict.some(tansItems => {
          var how = items.howText;
          var code = tansItems.code
          if(how && how.toLowerCase() === code.toLowerCase()) {
            items.editmedDescription = tansItems.desc
          }
        })
      });
      this.mtmAddlMedicationsList = tempMtmAddlMedications
    },
    getAllMtmMeds (clientId, memberId, documentId) {
      axios
      .get(
        "/mtm/medications/getReserved?clientId=" +
          clientId +
          "&memberId=" +
          memberId +"&documentId=" +documentId
      )
      .then((response) => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            if (response.data.code === 201) {
              if(response.data.dataLists !== null) {
                const [year, month, day] = response.data.document.displaySentDate.split("-");
                this.smtForReviewDate = `${month}/${day}/${year}`;
                this.memberName = this.memberDetails.member.firstName +' '+this.memberDetails.member.lastName
                if(this.selectisReview == null || this.selectisReview === false){
                  if(response.data.document?.medsStatus === 1) {
                    store.dispatch("setSelectDocumenStatus", 'Complete');
                  } else {
                    store.dispatch("setSelectDocumenStatus", 'Draft');
                  }
                }
                this.mapResponseWithFormData(response.data.dataLists);
              }
            } else if (response.data.code === 401) {
              this.alertType = "Failed";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
            }
          } else {
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
            store.dispatch("hideLoadingSpinner");
          }
        })
        .catch((error) => {
        console.log(error);
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    addMedications() {
      this.medicationsAddForm.push({
        companyId : 1,
        clientId : "",
        memberId: "",
        documentId: "",
        medName: "",
        medNameError: { isError: false, errorMessage: '' },
        prescriberName: "",
        whyText: "",
        whyTextError: { isError: false, errorMessage: '' },
        howText : "",
        howTextError: { isError: false, errorMessage: '' },
        included: false,
        uniqueTxNbr: null,
        editmedDescription: "",
        activeStatus: "active",
        found: true
      })
      this.isItemEdit = true;
      this.isUpdate= false;
    },
    medsTogglePrivacyModify(index, medsType) {
      let value = this.additionalMedincluded === "true" ? true : false
      if(medsType === 'medsList') {
        this.medicinesList[index].included = value;
      } else if(medsType === 'medsAddForm'){
          this.medicationsAddForm[index].included = value;
          this.medicationsAddForm[index].isActive = value;
          if(value){
            this.isItemEdit = true
            this.medicationsAddForm[index].activeStatus = "active";
          } else {
            this.isItemEdit = false
            this.medicationsAddForm[index].activeStatus = "deactive";
          }
      } else if(medsType === 'medsAddFormUpdate') {
        this.mtmAddlMedicationsList[index].included = value;
        if(value){
          this.isItemEdit = true
        } else {
          this.isItemEdit = false
        }
      }else if(medsType === 'claimHistList') {
        this.claimHistList[index].included = value;
      }
    },
    handleUpdateMeds (index) {
      const tempMedicationForm = this.medicinesList[index]
      if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        store.dispatch("showLoadingSpinner");
        this.updateMtmMedications(tempMedicationForm, this.clientData.clientId)
      }
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    isValidation () {
      let isValid = true
      if (this.medicationsAddForm[0].medName === '') {
        this.medicationsAddForm[0].medNameError.isError = true
        this.medicationsAddForm[0].medNameError.errorMessage = 'You must enter medication name.'
        isValid = false
      }
      if (this.medicationsAddForm[0].whyText === '') {
        this.medicationsAddForm[0].whyTextError.isError = true
        this.medicationsAddForm[0].whyTextError.errorMessage = 'You must enter why text.'
        isValid = false
      }
      if (this.medicationsAddForm[0].howText === '') {
        this.medicationsAddForm[0].howTextError.isError = true
        this.medicationsAddForm[0].howTextError.errorMessage = 'You must enter how text.'
        isValid = false
      }
      return isValid;
    },
    autoSaveByTwoMin() {
      this.interval = setInterval(() => {
      clearInterval(this.errorTimout);
      if(this.medicationsAddForm.length > 0) {
          this.medicationsAddForm[0].clientId = this.clientData.clientId;
          this.medicationsAddForm[0].memberId = this.memberId;
          this.medicationsAddForm[0].documentId = this.documentId;
          this.autoSaveValidation = true;
          if(this.isValidation()) {
            if(!this.autoSaveValidation || this.isUpdate) {
              this.updateMedsInfoAutoSave(this.medicationsAddForm[0]);
            } else {
              this.saveByInterval (this.medicationsAddForm[0]);
            }
          } else if(this.autoSaveValidation) {
           this.autoSaveStatus(false);
        }
        this.autoSaveValidation = false;
        }
      },this.autoSaveTimeout);
    },
    getupdatedMtmMeds (clientId, memberId, documentId) {
      axios
      .get(
        "/mtm/medications/getReserved?clientId=" +
          clientId +
          "&memberId=" +
          memberId +"&documentId=" +documentId
      )
      .then((response) => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              if(response.data.dataLists !== null) {
                if(response.data.dataLists.mtmAddlMedications.length > 0){
                    response.data.dataLists.mtmAddlMedications.map((items) => {
                    var exist = this.mtmAddlMedicationsList?.some(element => element.id == items.id);
                    if(!exist) {
                      this.medicationsAddForm[0]['id'] = items.id;
                      this.medicationsAddForm[0].description['id'] = items.description.id;
                      this.autoSaveStatus(true);
                    }
                  });
                }
              } else {
                this.autoSaveStatus(false);
              }
            } else {
              this.autoSaveStatus(false);
            }
          }
        })
        .catch((error) => {
          // this.autoSaveStatus(false);
      });
    },
    saveByInterval (objData) {
      axios
      .post(
        "/mtm/addlMedications/save",
        objData
      )
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.isUpdate= true;
            this.autoSaveStatus(true);
            this.getupdatedMtmMeds(this.clientData.clientId, this.memberId, this.documentId);
          } else if (response.data.code === 401) {
            this.autoSaveStatus(false);
          }
        } else {
          this.autoSaveStatus(false);
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false);
      })
    },
    updateMedsInfoAutoSave(tempAddlmedicinesForm) {
      axios
      .post('/mtm/addlMedications/update?id=' +tempAddlmedicinesForm.id, tempAddlmedicinesForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.autoSaveStatus(true);
          } else if (response.data.code === 401) {
            this.autoSaveStatus(false);
          }
        } else {
          this.autoSaveStatus(false);
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false);
      })
    },
    autoSaveStatus(success) {
      if(!success) {
        this.snackStatus = "Autosave Unsuccessful. Trying again in 15s";
          this.snackColor = "#DD2C00";
          this.snackbar = true
          this.autoSaveTimeout = 15000
          clearInterval(this.interval);
          this.autoSaveByTwoMin();
          var seconds = 15;
          this.snackTimeout = 15000;
          this.errorTimout = setInterval(() => {
            this.snackColor = "#DD2C00";
            seconds = seconds - 1;
            if(seconds == 0) {
              this.snackTimeout = 20000;
              this.snackbar = false;
            }
            this.snackStatus = "Autosave Unsuccessful. Trying again in "+ seconds +"s";
          }, 1000);
      } else {
        this.autoSaveTimeout = 60000
        this.snackStatus = "Autosave Completed at "+ this.formatAMPM(new Date());
        this.snackColor = "#CDDC39";
        this.snackbar = true
      }
    },
    updateMtmMedications (tempMedicationForm, clientId) {
      axios
        .post('/mtm/medications/updatemedications?clientId=' +clientId, tempMedicationForm)
        .then(response => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              store.dispatch("hideLoadingSpinner");
              this.alertType = 'Success'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
              this.pageReload = true
            } else if (response.data.code === 401) {
              store.dispatch("hideLoadingSpinner");
              this.alertType = 'Failed'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
      },
      getReviewDocument(clientId, memberId, documentId) {
      store.dispatch("showLoadingSpinner");
      axios
      .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
        {responseType: "blob"},
      )
      .then((response) => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            this.url = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    getDocReviewSectionStatus (clientId, reviewerId) {
      axios
      .get('/mtm/review?clientId='+clientId +'&reviewerId='+reviewerId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataList?.medsStatus === 1) {
              store.dispatch("setSelectDocumenStatus", 'Reviewer');
            } else {
              store.dispatch("setSelectDocumenStatus", 'NotReviewer');
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    }
  },
  mounted () {
    this.clientData = this.selectedClient
    this.memberId = this.selectedMemberId
    this.documentId = this.selectdocumentId
    this.memberDetails = this.memberDetail;
    interceptorSetup.refreshToken();
    if(this.selectisReview == null|| this.selectisReview === false) {
      this.autoSaveByTwoMin();
    }
    store.dispatch("setSelectDocumenStatus", 'Draft');
    if(this.clientData.clientId !== null && this.memberId!== null && this.documentId !== null) {
      store.dispatch("showLoadingSpinner");
      this.getAllMtmMeds(this.clientData.clientId, this.memberId, this.documentId)
      if(this.selectisReview){
        this.getDocReviewSectionStatus(this.clientData.clientId, this.selectReviewerId)
        setTimeout(() => {
          this.getReviewDocument(this.clientData.clientId , this.memberId, this.documentId)
        }, 1000);
      }
    }
  },
  unmounted(){ 
   clearInterval(this.interval)
   clearInterval(this.errorTimout);
  }
}
</script>
