// import Vue from 'vue';
import { createApp } from 'vue'
import App from './App.vue';
import store from './store';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Datepicker from 'vue3-datepicker'
import VueDatePicker from '@vuepic/vue-datepicker';
// import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
// import emitter from './emitter';
axios.defaults.baseURL = import.meta.env.VITE_APP_ROOT_API
const vuetify = createVuetify({
  components,
  directives,
  store,
  router,
  VueDatePicker,
  Datepicker
})
console.warn = () => {};
createApp(App)
.use(store)
.use(router)
.use(vuetify)
// app.config.globalProperties.$emitter = emitter;
.mount('#app')

