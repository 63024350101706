<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
      scrollable
      class="z-index-1000"
  >
      <v-card>
        <v-card-title class="text-h5">
          {{"Alert "}}
          <v-icon
            v-if="alertType=='Confirmation' || alertType=='PartialConfirmation'"
            class="icon-alert-box"
            size="25"
            color="#e4bb00"
          >
          mdi-alert-octagon
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div class="flex-row">
            <div class="alert-message-text" v-html="alertMessage"></div>
            <v-icon
              v-if="alertType=='Success' || alertType =='PartialSuccess'"
              size="25"
              class="icon-alert-box"
              color="success"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-if="alertType=='Failed'"
              class="icon-alert-box"
              size="25"
              color="error"
            >
              mdi-close-circle
            </v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="alertType=='Failed' || alertType=='Success' || alertType=='PartialSuccess'"
            color="green darken-1"
            variant="text"
            @click="$emit('closeDialog')"
          >
            Ok
          </v-btn>
          <v-btn
            v-if="alertType=='Confirmation' || alertType=='PartialConfirmation'"
            color="green darken-1"
            variant="text"
            @click="$emit('onConfirmOK')"
          >
            Ok
          </v-btn>
          <v-btn
            v-if="alertMessageType=='Yes' && alertType=='Confirmation' || alertType=='PartialConfirmation'"
            color="green darken-1"
            variant="text"
           @click="$emit('noDialog')"
          >
            No
          </v-btn>
          <v-btn
            v-if="alertMessageType!='Yes' && alertType=='Confirmation' || alertType=='PartialConfirmation'"
            color="green darken-1"
            variant="text"
           @click="$emit('closeDialog')"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
name: 'AlertDialogs',
props: {
  alertType: { type: String, default: 'Failed' },
  alertMessage: { type: String, default: '' },
  alertMessageType: { type: String, default: '' }
},
components: {
},
data: () => ({
  dialog: true
}),
computed: {
},
methods: {
  handleOnEnterKeyPressed (event) {
    if (event.keyCode === 13 || event.code === 'Enter') {
      if (this.alertType === 'Confirmation') {
        this.$emit('onConfirmOK')
      } else {
        this.$emit('closeDialog')
      }
    }
  }
},
mounted () {
  document.addEventListener('keyup', this.handleOnEnterKeyPressed, false)
},
beforeDestroy () {
  document.removeEventListener('keyup', this.handleOnEnterKeyPressed, false)
}
}
</script>
