<template>
  <v-row class="mg-top-10">
    <!-- <v-col cols="12" sm="6" md="4" lg="3"> -->
      <!-- <v-select
        :items="criteriaOptions"
        :value="selectedReport"
        @update:modelValue="reportSelected"
        label="Criteria Type"
        item-title="name"
        item-value="id"
        return-object
        variant="outlined"
        density="comfortable"
      ></v-select> -->
    <!-- </v-col> -->
    <v-col  cols="12" sm="6" md="4" lg="3">
        <h1 class="mg-left-6rem"> {{ reportNameTarget }} </h1>
      </v-col>
    <v-col cols="12" sm="7" offset-md="2" offset-lg="2">
      <v-btn
        class="ma-2 custom-btn"
        small
        :color="disableButtons ? '' : '#030c4d'"
        :disabled="disableButtons"
        @click="markQualified"
      >
        Mark Qualified
      </v-btn>
      <v-btn
        class="ma-2"
        small
        :disabled="disableButtons"
        @click="markNotQualified"
      >
        Mark Not Qualified
      </v-btn>
      <v-btn 
        class="ma-2"
        :color="disableButtons ? '' : '#f3a11a'"
        small
        :disabled="disableButtons"
        @click="markRetired"
      >
        Retire from Program
      </v-btn>
      <v-btn
        class="ma-2"
        small
        :disabled="disableDownloadButton"
        @click="downloadReport"
      >
        Download as Excel
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";

export default {
  name: "SelectCriteriaReport",
  props: ['reportNameTarget'],
  methods: {
    reportSelected: function(val) {
      store.dispatch("setSelectedReport", val);
      store.commit("SET_REPORT_MEMBERS", []);
      store.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", []);
      store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
      store.dispatch("getReportMembers");
    },
    markQualified() {
      store.dispatch("targetMembers", {
        status: 2,
        memberIds: this.reportSelectedMemberIds,
        reloadMethod: "criteriaReport"
      });
    },
    markNotQualified() {
      store.dispatch("targetMembers", {
        status: 100,
        memberIds: this.reportSelectedMemberIds,
        reloadMethod: "criteriaReport"
      });
    },
    markRetired() {
      store.dispatch("targetMembers", {
        status: 99,
        memberIds: this.reportSelectedMemberIds,
        reloadMethod: "criteriaReport"
      });
    },
    downloadReport() {
      store.dispatch("downloadXLSReport", {
        reportType: "criteriaReport",
        reportId: this.selectedReport.id,
        reloadMethod: null
      });
    }
  },
  computed: {
    criteriaOptions() {
      return store.getters.criteriaOptions;
    },
    selectedReport() {
      return store.getters.selectedReport;
    },
    reportSelectedMemberIds() {
      return store.getters.criteriaReportSelectedMemberIds;
    },
    reportMembers() {
      return store.getters.reportMembers;
    },
    allCriteriaReportMembers() {
      return store.getters.allCriteriaReportMembers;
    },
    disableButtons() {
      if (
        this.reportSelectedMemberIds != null &&
        this.reportSelectedMemberIds.length > 0
      )
        return false;
      else return true;
    },
    disableDownloadButton() {
      if (
        this.selectedReport != null &&
        ((this.selectedReport.id > 0 &&
          this.reportMembers != null &&
          this.reportMembers.length > 0) ||
          ((this.selectedReport.id == 0 || this.selectedReport.id == 101) &&
            this.allCriteriaReportMembers != null &&
            this.allCriteriaReportMembers.length > 0))
      )
        return false;
      else return true;
    }
  }
};
</script>
