<template>
    <v-container fluid>
      <v-row class="mg-top-5">
        <!-- <v-col cols="12" sm="6" md="4" lg="3"> -->
          <!-- <v-select
            :items="qualifiedOptions"
            :value="selectedQualifiedReport"
            @update:modelValue="reportSelected"
            label="Select Report Type"
            item-title="name"
            item-value="id"
            return-object
            variant="outlined"
            density="comfortable"
          ></v-select> -->
        <!-- </v-col> -->
  
        <v-col  cols="12" sm="6" md="4" lg="3">
          <h1 class="mg-left-6rem"> {{ "Graduated" }} </h1>
        </v-col>
  
        <v-col cols="12" sm="6" md="8" lg="9">
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                (this.selectedQualifiedReport.id == 2 ||
                  this.selectedQualifiedReport.id == 98 ||
                  this.selectedQualifiedReport.id == 99 ||
                  this.selectedQualifiedReport.id == 100)
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(1)"
          >
            Reset to Targeted
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                (this.selectedQualifiedReport.id == 100 ||
                  this.selectedQualifiedReport.id == 101)
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(2)"
          >
            Mark Qualified
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                (this.selectedQualifiedReport.id == 2 ||
                  this.selectedQualifiedReport.id == 101)
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(100)"
          >
            Mark Not Qualified
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 2
            "
            class="ms-4 mb-2 custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(10)"
          >
            Invite to Team 1
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 2
            "
            class="ms-4 mb-2 custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(20)"
          >
            Invite to Team 2
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 20
            "
            class="ms-4 mb-2 custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(10)"
          >
            Switch To Team 1
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 10
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(20)"
          >
            Switch To Team 2
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 21
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(11)"
          >
            Switch To Team 1
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 11
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(21)"
          >
            Switch To Team 2
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 22
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(12)"
          >
            Switch To Team 1
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 12
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(22)"
          >
            Switch To Team 2
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                (this.selectedQualifiedReport.id == 20 ||
                  this.selectedQualifiedReport.id == 10)
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(2)"
          >
            Cancel Invite
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 11
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(12)"
          >
            Elevate to Managed
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 21
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(22)"
          >
            Elevate to Managed
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 12
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(11)"
          >
            Remove from Managed
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 22
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#030c4d'"
            small
            :disabled="disableButtons"
            @click="convertBtnClick(21)"
          >
            Remove from Managed
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id != 99
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#f3a11a'"
            small
            :disabled="disableButtons"
            @click="removeBtnClick()"
          >
            Retire from Program
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                (this.selectedQualifiedReport.id == 12 ||
                  this.selectedQualifiedReport.id == 22)
            "
            class="ms-4 mb-2  custom-btn"
            :color="disableButtons ? '' : '#f3a11a'"
            small
            :disabled="disableButtons"
            @click="qualifiedBtnClick(98)"
          >
            Complete Program
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 10
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="printLettersClick(10)"
          >
            Print Letters
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 20
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="printLettersClick(20)"
          >
            Print Letters
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 10
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="downloadReportClick(10)"
          >
            Download Report
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 20
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="downloadReportClick(20)"
          >
            Download Report
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 11
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="downloadReportClick(11)"
          >
            Download Report
          </v-btn>
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id == 21
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="downloadReportClick(21)"
          >
            Download Report
          </v-btn>
  
          <v-btn
            v-if="
              this.selectedQualifiedReport != null &&
                this.selectedQualifiedReport.id >= 10 &&
                this.appUser.admin != null &&
                this.qualifiedReportSelectedMemberIds != null &&
                this.qualifiedReportSelectedMemberIds.indexOf(3129) > -1
            "
            class="ms-4 mb-2"
            small
            :disabled="disableButtons"
            @click="resetDemoBtnClick()"
          >
            Reset Demo Member
          </v-btn>
          <v-btn
            class="ms-4 mb-2"
            small
            :disabled="disableXLSButton"
            @click="downloadXLSReport()"
          >
            Download as Excel
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="qualifiedSelectedMembers"
            @input="inputEvent"
            :items="qualifiedReportMembers"
            :headers="headers"
            item-value="memberId"
            show-select
            class="elevation-2"
            multi-sort
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100, -1]
            }"
            :items-per-page="20"
          >
            <template v-slot:item.memberId="{ item }">
              <span @click="goToMemberView(item.memberId)" class="pointer">
                <a>{{ item.memberId }}</a>
              </span>
  
              <v-tooltip bottom v-if="item.hasAddress">
                <template v-slot:activator="{ props }">
                  <v-icon
                    style="float:right;"
                    small
                    color="green"
                    v-bind="props"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                </template>
                <span>Has Address</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!item.hasAddress">
                <template v-slot:activator="{ props }">
                  <v-icon
                    style="float:right;"
                    small
                    color="#e4e4e4"
                    v-bind="props"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                </template>
                <span>Address Incomplete</span>
              </v-tooltip>
            </template>
  
            <template v-slot:item.member.dateOfBirth="{ item }">
              <span v-if="!privacyMode" style="white-space: nowrap">{{
                item.member.dateOfBirth
              }}</span>
              <span v-if="privacyMode" style="white-space:nowrap;">{{
                showAge(item.member?.dateOfBirth)
              }}</span>
            </template>
  
            <template v-slot:item.member.firstName="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ props }">
                  <span v-bind="props" style="display:inline-block;">
                    {{ privacyMode ? "******" : item.member.firstName }}
                  </span>
                </template>
                <div v-if="!privacyMode">
                  <span
                    v-if="
                      item.memberAddresses != null &&
                        item.memberAddresses.length > 0
                    "
                  >
                    <span v-if="item.memberAddresses[0].streetAddress">
                      {{ item.memberAddresses[0].streetAddress }}<br />
                    </span>
                    <span v-if="item.memberAddresses[0].streetAddress2">
                      &nbsp;&nbsp; {{ item.memberAddresses[0].streetAddress2
                      }}<br />
                    </span>
                    <span
                      v-if="
                        item.memberAddresses[0].city ||
                          item.memberAddresses[0].state ||
                          item.memberAddresses[0].zipCode
                      "
                    >
                      {{
                        item.memberAddresses[0].city
                          ? item.memberAddresses[0].city
                          : "____"
                      }}
                      ,
                      {{
                        item.memberAddresses[0].state
                          ? item.memberAddresses[0].state
                          : "__"
                      }}
                      {{
                        item.memberAddresses[0].zipCode
                          ? item.memberAddresses[0].zipCode
                          : "____"
                      }}
                      <br />
                    </span>
                  </span>
                  <span
                    v-if="
                      item.memberPhones != null && item.memberPhones.length > 0
                    "
                  >
                    {{ item.memberPhones[0].phoneNumber }}
                    <br />
                  </span>
                  <span
                    v-if="
                      item.memberEmails != null && item.memberEmails.length > 0
                    "
                  >
                    {{ item.memberEmails[0].emailAddress }}
                    <br />
                  </span>
                </div>
                <div v-if="privacyMode">
                  ******
                </div>
              </v-tooltip>
            </template>
            <template v-slot:item.member.lastName="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ props }">
                  <span v-bind="props" style="display:inline-block;">
                    {{ privacyMode ? "******" : item.member.lastName }}
                  </span>
                </template>
                <div v-if="!privacyMode">
                  <span
                    v-if="
                      item.memberAddresses != null &&
                        item.memberAddresses.length > 0
                    "
                  >
                    <span v-if="item.memberAddresses[0].streetAddress">
                      {{ item.memberAddresses[0].streetAddress }}<br />
                    </span>
                    <span v-if="item.memberAddresses[0].streetAddress2">
                      &nbsp;&nbsp; {{ item.memberAddresses[0].streetAddress2
                      }}<br />
                    </span>
                    <span
                      v-if="
                        item.memberAddresses[0].city ||
                          item.memberAddresses[0].state ||
                          item.memberAddresses[0].zipCode
                      "
                    >
                      {{
                        item.memberAddresses[0].city
                          ? item.memberAddresses[0].city
                          : "____"
                      }}
                      ,
                      {{
                        item.memberAddresses[0].state
                          ? item.memberAddresses[0].state
                          : "__"
                      }}
                      {{
                        item.memberAddresses[0].zipCode
                          ? item.memberAddresses[0].zipCode
                          : "____"
                      }}
                      <br />
                    </span>
                  </span>
                  <span
                    v-if="
                      item.memberPhones != null && item.memberPhones.length > 0
                    "
                  >
                    {{ item.memberPhones[0].phoneNumber }}
                    <br />
                  </span>
                  <span
                    v-if="
                      item.memberEmails != null && item.memberEmails.length > 0
                    "
                  >
                    {{ item.memberEmails[0].emailAddress }}
                    <br />
                  </span>
                </div>
                <div v-if="privacyMode">
                  ******
                </div>
              </v-tooltip>
            </template>
  
            <template v-slot:item.memberCriteria="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ props }">
                  <span v-bind="props" style="display:inline-block;">
                    {{ item.memberCriteria.length }}
                  </span>
                </template>
                <div>
                  <span
                    v-for="(criteria, index) in item.memberCriteria"
                    v-bind:key="index"
                  >
                    {{ criteria.criteriaName }} {{ criteria.year }}-{{
                      criteria.period
                    }}<br />
                  </span>
                </div>
              </v-tooltip>
            </template>
  
            <template v-slot:item.memberRegistration.dateInvited="{ item }">
              <v-tooltip
                bottom
                v-if="
                  item.memberRegistration != null &&
                    item.memberRegistration.dateInvited != null
                "
              >
                <template v-slot:activator="{ props }">
                  <a
                    v-bind="props"
                    v-on:click="
                      downloadFile(item.memberRegistration.invitationFileId)
                    "
                  >
                    {{ showLocalDate(item.memberRegistration.dateInvited) }}
                  </a>
                </template>
                <span>Download File</span>
              </v-tooltip>
            </template>
  
            <template v-slot:no-data>
              <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                <span>No data available.</span>
              </div>
          </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <style scoped></style>
  <script>
  import store from "@/store";
  // import { VDataTable } from 'vuetify/labs/VDataTable'
  export default {
    name: "GraduatedReport",
    components: {
      // VDataTable
    },
    props: ['reportNames'],
    methods: {
      showLocalDate(dateInvited) {
        // convert UTC to local then format
        var date = new Date(dateInvited + "Z");
        var month = date.getMonth() + 1;
        return (
          date.getFullYear() +
          "-" +
          (month > 9 ? month : "0" + month) +
          "-" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate())
        );
      },
      downloadFile(fileId) {
        store.dispatch("downloadFile", {
          fileId: fileId,
          reloadMethod: null
        });
      },
      reportSelected(val) {
        // console.log("report " + val + " selected");
        store.dispatch("setSelectedQualifiedReport", val);
        store.dispatch("getQualifiedReportMembers");
        this.qualifiedSelectedMembers = [];
      },
      inputEvent() {
        store.commit(
          "SET_QUALIFIED_REPORT_SELECTED_MEMBER_IDS",
          this.qualifiedReportSelectedMemberIds
        );
      },
      goToMemberView(mId) {
        store.commit("SET_SELECTED_MEMBER_ID", mId);
        store.dispatch("getMemberDetail");
        this.qualifiedSelectedMembers = [];
        // store.dispatch("setMainTab", 1);
        this.$router.push('/displayMemberDetails')
        
      },
      qualifiedBtnClick(statusId) {
        if (
          this.qualifiedReportSelectedMemberIds != null &&
          this.qualifiedReportSelectedMemberIds.length > 0
        ) {
          store.dispatch("targetMembers", {
            status: statusId,
            memberIds: this.qualifiedReportSelectedMemberIds,
            reloadMethod: "qualifiedReport"
          });
          this.qualifiedSelectedMembers = [];
        }
      },
      convertBtnClick(statusId) {
        if (
          this.qualifiedReportSelectedMemberIds != null &&
          this.qualifiedReportSelectedMemberIds.length > 0
        ) {
          store.dispatch("unTargetMembers", {
            status: statusId,
            memberIds: this.qualifiedReportSelectedMemberIds,
            reloadMethod: "qualifiedReport"
          });
          this.qualifiedSelectedMembers = [];
        }
      },
      removeBtnClick() {
        if (
          confirm(
            "Are you sure you wish to retire these patients from the MemberFirst program?"
          )
        ) {
          // console.log("yup, drop em");
          store.dispatch("targetMembers", {
            status: 99,
            memberIds: this.qualifiedReportSelectedMemberIds,
            reloadMethod: "qualifiedReport"
          });
        }
      },
      printLettersClick(statusId) {
        if (
          this.qualifiedReportSelectedMemberIds != null &&
          this.qualifiedReportSelectedMemberIds.length > 0
        ) {
          store.dispatch("printLetters", {
            status: statusId,
            memberIds: this.qualifiedReportSelectedMemberIds,
            reloadMethod: "printLetters"
          });
          this.qualifiedSelectedMembers = [];
        }
      },
      downloadReportClick(statusId) {
        if (
          this.qualifiedReportSelectedMemberIds != null &&
          this.qualifiedReportSelectedMemberIds.length > 0
        ) {
          store.dispatch("downloadReport", {
            status: statusId,
            memberIds: this.qualifiedReportSelectedMemberIds,
            reloadMethod: "downloadReport"
          });
          this.qualifiedSelectedMembers = [];
        }
      },
      resetDemoBtnClick() {
        if (
          this.qualifiedReportSelectedMemberIds != null &&
          this.qualifiedReportSelectedMemberIds.length == 1 &&
          this.qualifiedReportSelectedMemberIds[0] == 3129
        ) {
          store.dispatch("resetDemoAcceptedInvite", {
            reloadMethod: "qualifiedReport"
          });
          this.qualifiedSelectedMembers = [];
        }
      },
      downloadXLSReport() {
        store.dispatch("downloadXLSReport", {
          reportType: "qualifiedReport",
          reportId: this.selectedQualifiedReport.id,
          reloadMethod: null
        });
      },
      showAge(dob) {
        if( dob !== undefined){
        var year = Number(dob.substr(0, 4));
        var month = Number(dob.substr(4, 2)) - 1;
        var day = Number(dob.substr(6, 2));
        var today = new Date();
        var age = today.getFullYear() - year;
        if (
          today.getMonth() < month ||
          (today.getMonth() == month && today.getDate() < day)
        ) {
          age--;
        }
        if (age < 2) {
          age = today.getMonth() - month;
          if (today.getDate() < day) {
            age--;
          }
          return age + " months";
        }
        return age + " years";
      }
    }
    },
    data() {
      return {
        initIntervalRef: undefined,
        qualifiedSelectedMembers: [],
        reportData: undefined
      };
    },
    computed: {
      selectedClient() {
        return store.getters.selectedClient;
      },
      appUser() {
        return store.getters.appUser;
      },
      privacyMode() {
        return store.getters.privacyMode;
      },
      qualifiedOptions() {
        var options = store.getters.qualifiedOptions.filter(x => {
          return x.id != 1 && x.id != 30 && x.id != 101;
        });
  
        return options;
        //return store.getters.qualifiedOptions;
      },
      selectedQualifiedReport() {
        return store.getters.selectedQualifiedReport;
      },
      qualifiedReportMembers() {
        return store.getters.qualifiedReportMembers;
      },
      qualifiedReportSelectedMemberIds() {
        var memberIds = [];
        this.qualifiedSelectedMembers.forEach(function(item) {
          if (memberIds.indexOf(item.memberId) == -1)
            memberIds.push(item.memberId);
        });
        return memberIds;
      },
  
      disableButtons() {
        if (
          this.qualifiedReportSelectedMemberIds == null ||
          this.qualifiedReportSelectedMemberIds.length == 0
        )
          return true;
        else return false;
      },
      disableXLSButton() {
        if (
          this.selectedQualifiedReport != null &&
          this.qualifiedReportMembers != null &&
          this.qualifiedReportMembers.length > 0
        )
          return false;
        else return true;
      },
      headers() {
        if (
          this.selectedQualifiedReport != null &&
          (this.selectedQualifiedReport.id == 10 ||
            this.selectedQualifiedReport.id == 20)
        ) {
          return [
            {
              title: "Member ID",
              key: "memberId",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "First Name",
              key: "member.firstName",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Last Name",
              key: "member.lastName",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "DOB",
              key: "member.dateOfBirth",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Status",
              key: "statusName",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Criteria Count",
              key: "memberCriteria",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Invitation Code",
              key: "memberRegistration.invitationCode",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Date Invited",
              key: "memberRegistration.dateInvited",
              sortable: true,
              filterable: false,
              divider: true
            }
          ];
        } else {
          return [
            {
              title: "Member ID",
              key: "memberId",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "First Name",
              key: "member.firstName",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Last Name",
              key: "member.lastName",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "DOB",
              key: "member.dateOfBirth",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Status",
              key: "statusName",
              sortable: true,
              filterable: false,
              divider: true
            },
            {
              title: "Criteria Count",
              key: "memberCriteria",
              sortable: true,
              filterable: false,
              divider: true
            }
          ];
        }
      }
    },
    mounted () { 
      this.reportData = this.$route.params.selectedValue !== "" ? this.$route.params.selectedValue : undefined
      if ( this.selectedClient != null) {
        if (this.reportData !== undefined) {
          store.dispatch("showLoadingSpinner");
          store.dispatch("setSelectedQualifiedReport", JSON.parse(this.reportData));
          store.dispatch("getQualifiedReportMembers");
        }
      } else {
        const intervalRef = setInterval(() => {
          if (this.selectedClient !== null) {
            clearInterval(this.initIntervalRef)
            if (this.reportData !== undefined) {            
              store.dispatch("showLoadingSpinner");
              store.dispatch("setSelectedQualifiedReport", JSON.parse(this.reportData));
              store.dispatch("getQualifiedReportMembers");
            }
          }
      }, 1000)
      this.initIntervalRef = intervalRef
    }
    }
  };
  </script>
  <style>
  .custom-btn {
    color: white;
  }
  .center-content {
    display: flex;
    /* justify-content: center; */
    padding-left: 62px;
    align-items: center;
    height: 100%; /* Ensure the v-col takes the full height to center content */
  }
  </style>
  