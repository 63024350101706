<template>
  <v-container fluid>
    <div>
      <v-col cols="11">
        <div class="header-title">MTM TO-DO</div>
        <div class="contact-sub-title mg-top-10">
          The Recommended To-Do List(TDL) describes the medication action plan
          resulting from the Comprehensive Medication Review (CMR) and
          beneficiary's responsibilities in the TDL. The TDL should not include
          detailed action plans of the MTM provider and is not intended to be a
          template for communications with other healthcare providers. The MTM
          provider should determine the most important action items for the
          beneficiary based on their concern, need, and ability to understand
          and complete the recommended activities.
        </div>
        <div class="mg-top-20"></div>
        <div class="contact-sub-title">
          There may be CMRs that do not identify current medication therapy
          problems or result in beneficiary-specify action items. The TDL allows
          sponsors to list other statements as appropriate for the beneficiary,
          such as reinforcing compliance, maintaining beneficiary's actions, and
          acknowledging beneficiary's success in their medication therapy. This
          provides a deliverable consistent with the Format for all
          beneficiaries, even if there are no new action items.
        </div>
        <v-row v-if="selectisReview">
          <v-col cols="12" class="mg-left-10 mg-right-15">
            <div class="review-cart-bg-color">
              <div class="header-quality-control">Quality Control Review</div>
              <v-row class="mg-top-5">
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="text-align-inherit fs-14"
                      >{{ "Submitted for Review date:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="text-align-inherit fs-14"
                  v-if="smtForReviewDate !== null"
                  >{{ smtForReviewDate }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Clinician:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ "DR. "+selectClinicianName }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Member:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ memberName }}</v-col
                >
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row
         class="pd-10"
          v-if="selectisReview === null || selectisReview === false"
        >
          <v-col
            cols="3"
            class="mouse-cursor"
            @click="handleAddTodo"
            :class="{ 'disabled-element': isItemEdit, 'gray-primary': isItemEdit}"
          >
            <v-row class="mg-top-10">
              <v-icon
                size="24"
                class="pd-0 fs-14" 
                :class="{ 'gray-primary': isItemEdit, 'blue-color': isItemEdit == false}"
              >
                mdi-plus
              </v-icon>
              <div
                class="primary-color mg-top-3 fs-14"
                :class="{ 'gray-primary': isItemEdit}"
              >
                ADD TO MY TO DO LIST
              </div>
            </v-row>
          </v-col>
        </v-row>
        <div
          class="to-do-border mg-top-15"
          v-for="(items, index) in todoForm"
          :key="index"
        >
          <div
            v-for="(discussionsitems, discussionsIndex) in items.mtmTodoDiscussions"
            :key="discussionsIndex"
          >
            <v-row class="mg-0 mg-top-15 mg-left-10 pd-10">
              <v-col cols="6" class="pd-0">
                <v-col cols="10" class="pd-0">
                  <v-textarea
                    variant="outlined"
                    density="comfortable"
                    rows="4"
                    name="input"
                    v-model="discussionsitems.discussionTopic"
                    @input="handleDiscussionItem(index, discussionsIndex, $event)"
                    label="Discussion Topic"
                    :error="discussionsitems.discussionsError.isError"
                    :error-messages="discussionsitems.discussionsError.errorMessage"
                  ></v-textarea>
                </v-col>
              </v-col>
              <v-col cols="6" class="pd-0">
                <v-col cols="10" class="pd-0">
                  <v-row
                    class="mg-0"
                    v-for="(actionItem, indexActionItem) in discussionsitems.mtmTodoAction"
                    :key="indexActionItem"
                  >
                    <v-text-field
                      class="pd-0"
                      variant="outlined"
                      density="comfortable"
                      v-model="actionItem.actionItem"
                      :error="actionItem.actionItemError.isError"
                      :error-messages="actionItem.actionItemError.errorMessage"
                      @input="addMyActionItem($event, index, discussionsIndex, indexActionItem)"
                    >
                    </v-text-field>
                    <v-icon
                      class="mg-top-15"
                      color="red"
                      @click="HandleDeleteActionitem(index, discussionsIndex, indexActionItem, 'todoForm')"
                    >
                      mdi-close
                    </v-icon>
                  </v-row>
                </v-col>
                <v-row class="pd-unset pd-0">
                  <v-col cols="3"></v-col>
                  <v-col
                    cols="5"
                    class="mouse-cursor"
                    @click="handleAddTodoaction(index, discussionsIndex, 'todoForm')"
                  >
                    <v-row class="flex-nowrap">
                      <v-icon color="blue" class="mg-top-20"> mdi-plus </v-icon>
                      <div class="primary-color mg-top-15 add-action-item">
                        ADD ACTION ITEM
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="pd-unset pd-0">
                  <v-col cols="5"></v-col>
                  <v-col cols="5">
                    <v-row>
                      <v-col cols="4">
                        <v-btn
                          class="clear-and-cancel-btn"
                          @click="handleCancelTodo(index, discussionsIndex, '')"
                          variant="text"
                        >
                          Cancel
                        </v-btn>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          class="clear-and-cancel-btn"
                          @click="handleClearTodo(index, discussionsIndex, '')"
                          variant="text"
                        >
                          Clear
                        </v-btn>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          color="#2196f3"
                          variant="text"
                          class="save-btn"
                          @click="handleMtmAddTodo(index)"
                        >
                          Save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          v-if="toDoList !== undefined"
          :class="{ 'mg-left-30': selectisReview, 'mg-right-30': selectisReview}"
        >
          <div
            v-for="(toDoitems, todoIndex) in toDoList"
            :key="todoIndex"
            class="mg-top-20"
          >
            <div
              v-for="(discussionsItems, discussionsIndex) in toDoitems.mtmTodoDiscussions"
              :key="discussionsIndex"
            >
              <v-card class="mg-bottom-40 card-border-blue" height="100%" v-bind:class="{ 'card-border-blue': toDoitems.isActive, 'card-border-gray': !toDoitems.isActive }">
                <v-row height="160">
                  <v-col cols="6" class="mg-top-13 pd-30 border-right">
                    <div style="padding: 3px 9px 35px 20px;">
                      <v-row>
                        <div class="section-intro">WHAT WE TALK ABOUT:</div>
                      </v-row>
                      <div class="mg-top-20"></div>
                      <v-row>
                        <div v-if="toDoitems.todoActionType !== 'Edit'">
                          {{discussionsItems.discussionTopic}}
                        </div>
                        <v-textarea
                          auto-grow
                          v-if="toDoitems.todoActionType === 'Edit'"
                          variant="outlined"
                          density="comfortable"
                          :error="discussionsItems.discussionsError.isError"
                          :error-messages="discussionsItems.discussionsError.errorMessage"
                          v-model="discussionsItems.discussionTopic"
                          @input="changeValueDiscussionTopic(todoIndex, discussionsIndex, $event)"
                        >
                        </v-textarea>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="6" class="pd-15 mg-top-5">
                    <v-row>
                      <v-col cols="11" class="pd-30">
                        <v-row class="pd-10">
                          <div class="section-intro">WHAT I SHOULD DO</div>
                        </v-row>
                        <div class="mg-top-20"></div>
                        <v-row
                          v-for="(item, index) in discussionsItems.mtmTodoAction"
                          :key="index"
                          class="flex-nowrap"
                        >
                          <template v-if="item.actionItem !== ''">
                            <v-icon
                              v-if="toDoitems.todoActionType !== 'Edit' || item.isAction === false"
                              color="color: #575151"
                              class="pd-0 mg-left-10"
                              size="25"
                            >
                              mdi-checkbox-blank-outline
                            </v-icon>
                          </template>
                          <v-col
                            cols="12"
                            class="pd-0"
                            v-if="toDoitems.todoActionType === 'Edit' && item.isAction === true"
                          >
                            <v-row class="flex-nowrap mg-top-20 pd-10">
                              <v-text-field
                                variant="outlined"
                                density="comfortable"
                                v-model="item.actionItem"
                                :error="item.actionItemError.isError"
                                :error-messages="item.actionItemError.errorMessage"
                                @input="changeValueActionItem(todoIndex, discussionsIndex, index, $event)"
                              >
                              </v-text-field>
                              <v-icon
                                size="30"
                                class="mg-top-20"
                                color="red"
                                @click="HandleDeleteActionitem(todoIndex, discussionsIndex, index, 'todoList')"
                              >
                                mdi-close
                              </v-icon>
                            </v-row>
                          </v-col>
                          <div
                            class="pd-3"
                            v-if="toDoitems.todoActionType !== 'Edit' || item.isAction === false"
                          >
                            {{item.actionItem}}
                          </div>
                        </v-row>
                        <v-row
                          class="pd-unset pd-0"
                          v-if="toDoitems.todoActionType === 'Edit'"
                        >
                          <v-col cols="3"></v-col>
                          <v-col
                            cols="5"
                            class="mouse-cursor"
                            @click="handleAddTodoaction(todoIndex, discussionsIndex, 'todoList')"
                          >
                            <v-row class="flex-nowrap">
                              <v-icon color="blue" class="mg-top-5">
                                mdi-plus
                              </v-icon>
                              <div
                                class="primary-color add-action-item"
                              >
                                ADD ACTION ITEM
                              </div>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="1"
                        v-if="(selectisReview === false || selectisReview === null) && (isItemEdit===false || isConfirm)"
                      >
                        <v-menu bottom left>
                          <template v-slot:activator="{ props }">
                            <v-icon
                              dark
                              icon
                              v-bind="props"
                              size="20"
                              color="black"
                            >
                              mdi-dots-vertical</v-icon
                            >
                          </template>
                          <v-list>
                            <v-list-item
                              class="dot-item-list"
                              v-if="toDoitems.activeStatus === 'active'"
                            >
                              <v-list-item-title
                                @click="handleIsEdit(todoIndex, discussionsIndex)"
                                >{{ "Edit" }}</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item
                              class="dot-item-list"
                              v-if="toDoitems.activeStatus === 'active'"
                            >
                              <v-list-item-title
                                @click="handleTodoActAndDec(toDoitems.id, 'deactive')"
                                >{{ "Deactivate" }}</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item
                              class="dot-item-list"
                              v-if="toDoitems.activeStatus === 'deactive'"
                            >
                              <v-list-item-title
                                @click="handleTodoActAndDec(toDoitems.id, 'active')"
                                >{{ "Active" }}</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item class="dot-item-list">
                              <v-list-item-title
                                @click="handleDeleteTodo(toDoitems.id)"
                                >{{ "Delete" }}</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
              <v-row
                class="mg-top-10"
                v-if="toDoitems.todoActionType == 'Edit'"
              >
                <v-col cols="9"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        color="black"
                        variant="text"
                        @click="handleCancelTodo(todoIndex, discussionsIndex, 'todo')"
                      >
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        color="black"
                        variant="text"
                        @click="handleClearTodo(todoIndex, discussionsIndex, 'todo')"
                      >
                        Clear
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        color="#2196f3"
                        variant="text"
                        @click="handleUpdateTodo(todoIndex)"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div v-if="selectisReview !== null || selectisReview">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset"
                v-if="!toDoitems.isShowReviewComment"
              >
                <v-col cols="9"></v-col>
                <v-col
                  cols="3"
                  :class="{ 'disabled-element': isCommentAdd}"
                  class="mouse-cursor"
                  @click="handleIsReviewerComment(todoIndex)"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isCommentAdd, 'blue-color': isCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isCommentAdd}">ADD REVIEWER COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div v-if="toDoitems.isShowReviewComment">
              <v-row class="mg-top-30">
                <v-col cols="12" class="pd-0">
                  <v-textarea
                    auto-grow
                    variant="outlined"
                    density="comfortable"
                    class="mx-2"
                    :readonly="!selectisReview"
                    v-model="toDoitems.remark"
                    :disabled="(selectisReview !== null || selectisReview) && !toDoitems.isReviewComment"
                    @input="changeCommentReview($event, todoIndex)"
                    rows="4"
                    label="QC Reviewer Comment"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview !== null || selectisReview) && toDoitems.isShowReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReviewComment(todoIndex)" 
                  v-if="toDoitems.isShowReviewBtn === false"
                  >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReviewComment(todoIndex)"
                    v-if="toDoitems.isShowReviewBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReviewComment(todoIndex)"
                    v-if="toDoitems.isReviewComment && toDoitems.isShowReviewBtn"
                    >DELETE</v-btn
                  >
                  <v-btn 
                    v-if="toDoitems.isReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReviewComment(todoIndex)"
                    >{{toDoitems.isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            <div v-if="selectisReview === null || selectisReview === false">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset"
                v-if="toDoitems.isShowReviewComment && toDoitems.isShowReplyReviewComment === false"
              >
                <v-col cols="8"></v-col>
                <v-col
                  cols="4"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isReplyCommentAdd}"
                  @click="handleIsReplyReviewerComment(todoIndex)"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isReplyCommentAdd, 'blue-color': isReplyCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isReplyCommentAdd}">ADD REPLY FOR THE ABOVE COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30" v-if="(selectisReview && toDoitems.isShowReviewComment && toDoitems.isShowReplyReviewComment) || (toDoitems.isShowReplyReviewComment)">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  auto-grow
                  variant="outlined"
                    density="comfortable"
                  class="mx-2"
                  v-model="toDoitems.remarkComment"
                  @input="changeReplyCommentReview($event, todoIndex)"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview === true) && !toDoitems.isReplyReviewComment"
                  :readonly="selectisReview"
                  label="Reply For QC Reviewer Comment"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview === null || selectisReview === false) && toDoitems.isShowReplyReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReplyReviewComment(todoIndex)" 
                    v-if="toDoitems.isShowReviewReplyBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReplyReviewComment(todoIndex)"
                    v-if="toDoitems.isShowReviewReplyBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReplyReviewComment(todoIndex)"
                    v-if="toDoitems.isReplyReviewComment && toDoitems.isShowReviewReplyBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                  v-if="toDoitems.isReplyReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReplyComment(todoIndex)"
                    >{{toDoitems.isShowReviewReplyBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="selectisReview !== null || selectisReview">
          <div class="mg-top-6rm txt-align-center fs-25 font-weight-400">
            Document to Review
          </div>
          <div class="mg-top-20">
            <iframe
              id="pdf"
              :src="url"
              frameborder="1"
              scrolling="auto"
              width="100%"
              height="600px"
              class="pdf-viewer"
            ></iframe>
          </div>
        </div>
      </v-col>
    </div>
    <div>
      <AlertDialogs
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        :alert-message-type="alertMessageType"
        @closeDialog="closeAlertDialog"
        @onConfirmOK="handleAlertConfirmation"
      />
      <v-snackbar
        v-model="snackbar"
        v-bind:color="snackColor"
        :timeout="snackTimeout"
      >
        <span
          :class="{ 'black--text': snackColor== '#CDDC39', 'white--text': snackColor== '#DD2C00' }"
          class=""
        >
          {{snackStatus}}
        </span>
      </v-snackbar>
    </div>
  </v-container>
</template>
<script>
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import store from '../store'
import axios from 'axios'
import interceptorSetup from '../helpers/httpInterceptor'
export default {
  name: "MtmTodo",
  components: {
    AlertDialogs
  },
  data() {
    return {
      gray: '#E1DA9B',
      alertType: '',
      alertMessage: '',
      confirmType: '',
      isHideShowAlertDialog: false,
      alertMessageType: "",
      toDoList: undefined,
      clientData: null,
      memberId : null,
      documentId: null,
      todoForm: [],
      clearTodoIndex: '',
      clearDiscussionsIndex: '',
      clearTodoActionType: '',
      cancelTodoIndex: '',
      cancelDiscussionsIndex: '',
      cancelTodoType: '',
      isItemEdit: false,
      snackbar: false,
      snackStatus: null,
      snackColor: "black",
      interval: null,
      snackTimeout: "20000",
      autoSaveTimeout: "30000",
      errorTimout: null,
      autoSaveValidation: false,
      isConfirm: true,
      url: null,
      memberDetails: undefined,
      smtForReviewDate: null,
      memberName: '',
      isCommentAdd: false,
      isReplyCommentAdd: false,
      pageReload: false
    }
  },
  computed: {
    selectisReview() {
      return store.getters.selectisReview
    },
    selectedMemberId () {
      return store.getters.selectedMemberId
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    selectdocumentId() {
      return store.getters.selectdocumentId;
    },
    memberDetail () {
      return store.getters.memberDetail;
    },
    selectClinicianName() {
      return store.getters.selectClinicianName
    },
    selectReviewerId() {
      return store.getters.selectReviewerId;
    }
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.errorTimout);
  },
  methods: {
    handleGenerateUUID() {
      const timestamp = Date.now().toString();
      const randomNumber = Math.floor(Math.random() * 10000).toString().padStart(5, '0');
      return  timestamp + randomNumber;
    },
    handleAddReviewComment (index) {
      const todoObj = this.toDoList[index]
      const clientId = this.clientData.clientId
      if (this.clientData.clientId !== null && this.memberId !== null) {
        this.addMtmTodo(todoObj, clientId)
      }
    },
    handleAddReplyComment (index) {
      const todoObj = this.toDoList[index]
      const clientId = this.clientData.clientId
      if (this.clientData.clientId !== null && this.memberId !== null) {
        this.addMtmTodo(todoObj, clientId)
      }
    },
    handleDeleteReviewComment (index) {
      const todoObj =  this.toDoList[index]
      todoObj.remark = null
      todoObj.remarkComment = null
      todoObj.isShowReviewComment = false
      const clientId = this.clientData.clientId
      if (this.clientData.clientId !== null && this.memberId !== null) {
        this.addMtmTodo(todoObj, clientId, index)
      }
    },
    handleDeleteReplyReviewComment(index) {
      const todoObj =  this.toDoList[index]
      todoObj.remarkComment = null
      todoObj.isShowReplyReviewComment = false
      const clientId = this.clientData.clientId
      if (this.clientData.clientId !== null && this.memberId !== null) {
        this.addMtmTodo(todoObj, clientId, index)
      }
    },
    changeCommentReview(value, index) {
      this.toDoList.forEach(items => {
        items.isReviewComment = false
      });
      this.isCommentAdd = true
      this.toDoList[index].isReviewComment = true
      this.toDoList[index].remark = value.target.value
    },
    changeReplyCommentReview(value, index) {
      this.toDoList.forEach(items => {
        items.isReplyReviewComment = false
      });
      this.isReplyCommentAdd = true
      this.toDoList[index].isReplyReviewComment = true
      this.toDoList[index].remarkComment = value.target.value
    },
    handleClearReviewComment (index) {
      this.toDoList[index].remark = ""
    },
    handleClearReplyReviewComment(index) {
      this.toDoList[index].remarkComment = ""
    },
    handleIsReviewerComment(index){
      this.toDoList[index].isShowReviewComment = true
      this.isCommentAdd = true
    },
    handleIsReplyReviewerComment(index){
      this.toDoList[index].isShowReplyReviewComment = true
      this.isReplyCommentAdd = true
    },
    handleCancelReviewComment(index){
      this.toDoList.forEach(items => {
        items.isReviewComment = true
        items.remark = ""
      });
      this.toDoList[index].isShowReviewComment = false
      this.isCommentAdd = false
    },
    handleCancelReplyReviewComment(index) {
      this.toDoList.forEach(items => {
        items.isReplyReviewComment = true
        items.remarkComment = ""
      });
      this.toDoList[index].isShowReplyReviewComment = false
      this.isReplyCommentAdd = false
    },
    handleDeleteTodo (todoId) {
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.getMtmTododelete(todoId, this.clientData.clientId)
      }
    },
    getMtmTododelete(todoId, clientId) {
    axios
      .delete('/mtm/todo/getdeleteById?clientId=' + clientId + '&Id='+todoId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            const responseIndex = this.toDoList.findIndex(item => item.id === todoId)
            if (responseIndex !== -1) {
              this.toDoList.splice(responseIndex, 1)
            }
            this.pageReload = true
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    handleIsEdit (index, discussionsIndex) {
      this.toDoList[index].todoActionType = "Edit"
      this.toDoList[index].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.forEach((item) => {
        item.isAction = true
      })
      this.isItemEdit = true
      this.isConfirm = false
    },
    handleAddTodo(){
      this.todoForm.push({
        companyId: 1,
        clientId: this.clientData.clientId,
        memberId: this.memberId,
        documentId: this.documentId,
        activeStatus:"active",
        mtmTodoDiscussions :[{
        discussionTopic: "",
        discussionsError: { isError: false, errorMessage: '' },
        mtmTodoAction :[{
          id: this.handleGenerateUUID(),
          actionItem:"",
          itemNum: 1,
          isTodoActionNew: true,
          actionItemError: { isError: false, errorMessage: '' }
        }]
      }]
    });
    this.isItemEdit = true
    this.isConfirm = true
    this.pageReload = false
    },
    handleAddTodoaction(index, discussionsIndex, todoType){
      if(todoType === 'todoForm'){
          this.todoForm[index].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.push(
          { 
            id: this.handleGenerateUUID(),
            actionItem : "",
            itemNum : 1,
            isTodoActionNew: true,
            actionItemError: { isError: false, errorMessage: '' }
          }
        )
      } else {
        this.toDoList[index].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.push(
          {
            id: this.handleGenerateUUID(),
            actionItem : "",
            itemNum : 1,
            actionItemError: { isError: false, errorMessage: '' },
            isAction: true,
            isTodoActionNew: true,
            todoActionType: 'Edit'
          }
        )
      }
    },
    HandleDeleteActionitem (mainIndex, discussionsIndex, actionItemIndex, todoActionType) {
      if(todoActionType === 'todoList') {
        const actionId = this.toDoList[mainIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].id
        if(actionId !== undefined) {
          this.getMtmActionItemDelete(actionId, this.clientData.clientId, mainIndex, discussionsIndex, actionItemIndex)
        } else {
          this.toDoList[mainIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.splice(actionItemIndex, 1)
        }
      } else {
        if (this.todoForm[mainIndex].mtmTodoAction !== -1) this.todoForm[mainIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.splice(actionItemIndex, 1)
      }
    },
    getMtmActionItemDelete(id, clientId, mainIndex, discussionsIndex, actionItemIndex) {
    axios
      .delete('/mtm/todo/getdeleteActionItemById?clientId=' + clientId + '&Id='+id)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.toDoList[mainIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.splice(actionItemIndex, 1)
            this.pageReload = false
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    handleDropAction(todoIndex, discussionsIndex, actionItemIndex) {
      this.toDoList[todoIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].isAction = true
    },
    changeValueActionItem(todoIndex, discussionsIndex, actionItemIndex, value) {
      this.toDoList[todoIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].actionItem = value.target.value
      this.toDoList[todoIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].actionItemError.isError = false
      this.toDoList[todoIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].actionItemError.errorMessage = ""
    },
    changeValueDiscussionTopic(todoIndex, discussionsIndex, value) {
      this.toDoList[todoIndex].mtmTodoDiscussions[discussionsIndex].discussionTopic = value.target.value
      this.toDoList[todoIndex].mtmTodoDiscussions[discussionsIndex].discussionsError.isError = false
      this.toDoList[todoIndex].mtmTodoDiscussions[discussionsIndex].discussionsError.errorMessage = ""
    },
    handleClearTodo (index, discussionsIndex, todoActionType) {
      this.clearTodoIndex = index
      this.clearDiscussionsIndex = discussionsIndex
      this.clearTodoActionType = todoActionType
      this.alertMessage = 'Are you sure you want to clear the content?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'clearTodo'
      this.isHideShowAlertDialog = true
    },
    clearTodo (index, discussionsIndex, todoActionType) {
      if (todoActionType == 'todo' ) {
        this.toDoList[index].mtmTodoDiscussions[discussionsIndex].discussionTopic = ""
        this.toDoList[index].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.forEach((item) => {
          item.actionItem = ""
        })
      } else {
        this.todoForm[index].mtmTodoDiscussions[discussionsIndex].discussionTopic = ""
        this.todoForm[index].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.forEach((item) => {
          item.actionItem = ""
        })
      }
    },
    handleCancelTodo (index, discussionsIndex, todoType) {
      if(todoType == "todo"){
        this.pageReload = true
      }
      this.cancelTodoIndex= index
      this.cancelDiscussionsIndex= discussionsIndex
      this.cancelTodoType= todoType
      this.alertMessage = 'Are you sure you want to cancel?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'cancelTodo'
      this.isHideShowAlertDialog = true
    },
    cancelTodo (index, discussionsIndex, todoType) {
      this.isItemEdit = false
      this.isConfirm = true
      if(todoType === 'todo') {
        this.toDoList[index].todoActionType = ""
        this.toDoList[index].mtmTodoDiscussions[discussionsIndex].mtmTodoAction.forEach((item) => {
          item.isAction = false
        })
      } else {
        if(this.todoForm.length > 0){
          if(Object.prototype.hasOwnProperty.call(this.todoForm[index], "id")) {
            axios
            .delete('/mtm/todo/getdeleteById?clientId=' + this.clientData.clientId + '&todoId='+this.todoForm[index].id)
              .then(response => {
                if (response.status === 200) {
                  if (response.data.code === 201) {
                    // this.autoSaveStatus(false);
                  } else {
                    this.autoSaveStatus(true);
                  }
                }
              });
              this.todoForm.splice(index, 1);
          } else {
            this.todoForm.splice(index, 1);
          }
        }
      }
  },
  closeAlertDialog () {
    this.isHideShowAlertDialog = false
    this.alertType = ''
    this.alertMessage = ''
    this.confirmType = ''
    if(this.pageReload) {
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.getAllMtmTodo(this.clientData.clientId, this.memberId, this.documentId);
      }
    }
  },
  handleAlertConfirmation (index) {
    this.isHideShowAlertDialog = false
    this.alertType = ''
    this.alertMessage = ''
    if (this.confirmType === 'remove') {
      if(index != -1){
        this.mainCartDraf.splice(index, 1)
      }
    } else if(this.confirmType === 'clearTodo') {
      this.clearTodo (this.clearTodoIndex,  this.clearDiscussionsIndex,  this.clearTodoActionType)
    } else if(this.confirmType === 'cancelTodo') {
      this.cancelTodo (this.cancelTodoIndex,  this.cancelDiscussionsIndex,  this.cancelTodoType )
      if(this.pageReload) {
        if (this.clientData.clientId !== null && this.memberId !== null) {
          store.dispatch("showLoadingSpinner");
          this.getAllMtmTodo(this.clientData.clientId, this.memberId, this.documentId);
        }
      }
    }
  },
  handleDiscussionItem (todoIndex, discussionsIndex, value) {
    this.todoForm[todoIndex].mtmTodoDiscussions[discussionsIndex].discussionTopic = value.target.value;
    this.todoForm[todoIndex].mtmTodoDiscussions[discussionsIndex].discussionsError.isError = false;
    this.todoForm[todoIndex].mtmTodoDiscussions[discussionsIndex].discussionsError.errorMessage = "";
  },
  addMyActionItem (value, mainIndex, discussionsIndex, actionItemIndex) {
    this.todoForm[mainIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].actionItem = value.target.value;
    this.todoForm[mainIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].actionItemError.isError = false;
    this.todoForm[mainIndex].mtmTodoDiscussions[discussionsIndex].mtmTodoAction[actionItemIndex].actionItemError.errorMessage = "";
  },
  handleUpdateTodo (index) {
    const tempTodoForm = this.toDoList[index]
    let isValid = true
    tempTodoForm.mtmTodoDiscussions.forEach(discussionsItems => {
      if (discussionsItems.discussionTopic === '') {
        discussionsItems.discussionsError.isError = true
        discussionsItems.discussionsError.errorMessage = 'You must enter discussion topic.'
        isValid = false
      }
      if (discussionsItems.discussionTopic !== '') {
        if(discussionsItems.discussionTopic.length > 400) {
          discussionsItems.discussionsError.isError = true
          discussionsItems.discussionsError.errorMessage = 'The Discussion Topic needs to be less than 400 characters.'
          isValid = false
        }
      }
      discussionsItems.mtmTodoAction.forEach(actionItems => {
        if (actionItems.actionItem === '') {
          actionItems.actionItemError.isError = true
          actionItems.actionItemError.errorMessage = 'You must enter action item.'
          isValid = false
        }
        if(actionItems.actionItem !== '') {
          if (actionItems.actionItem.length > 200) {
            actionItems.actionItemError.isError = true
            actionItems.actionItemError.errorMessage = 'The Action Item needs to be less than 200 characters.'
            isValid = false
          }
        }
      });
    });
    if(isValid) {
      if(this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.addMtmTodo(tempTodoForm, this.clientData.clientId)
      }
    }
  },
  handleMtmAddTodo (index) {
    const tempTodoForm = this.todoForm[index];
    tempTodoForm.clientId = this.clientData.clientId
    tempTodoForm.memberId = this.memberId
    tempTodoForm.documentId = this.documentId
    let isValid = true
    tempTodoForm.mtmTodoDiscussions.forEach(discussionsItems => {
      if (discussionsItems.discussionTopic === '') {
        discussionsItems.discussionsError.isError = true
        discussionsItems.discussionsError.errorMessage = 'You must enter discussion topic.'
        isValid = false
      }
      if (discussionsItems.discussionTopic !== '') {
        if(discussionsItems.discussionTopic.length > 400) {
          discussionsItems.discussionsError.isError = true
          discussionsItems.discussionsError.errorMessage = 'The Discussion Topic needs to be less than 400 characters.'
          isValid = false
        }
      }
      discussionsItems.mtmTodoAction.forEach(actionItems => {
        if (actionItems.actionItem === '') {
          actionItems.actionItemError.isError = true
          actionItems.actionItemError.errorMessage = 'You must enter action item.'
          isValid = false
        }
        if(actionItems.actionItem !== '') {
          if (actionItems.actionItem.length > 200) {
            actionItems.actionItemError.isError = true
            actionItems.actionItemError.errorMessage = 'The Action Item needs to be less than 200 characters.'
            isValid = false
          }
        }
      });
    });
    if(isValid) {
    if (tempTodoForm.clientId !== null && tempTodoForm.memberId !== null && tempTodoForm.documentId !== null) {
      if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        if(!this.autoSaveValidation) {
          store.dispatch("showLoadingSpinner");
          this.addMtmTodo(tempTodoForm, this.clientData.clientId, index)
        } else {
          this.saveByInterval(tempTodoForm, this.clientData.clientId, index, this.memberId, this.documentId);
        }
      }
    }
    } else if(this.autoSaveValidation) {
      this.autoSaveStatus(false, index);
    }
    this.autoSaveValidation = false;
  },
  addMtmTodo (todoForm, clientId, index) {
    axios
      .post('/mtm/todo/addmtmTodo?clinetId='+clientId, todoForm)
      .then(response => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.isItemEdit = false
            this.isConfirm = true
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true
            this.isCommentAdd = false
            this.isReplyCommentAdd = false
            if(this.selectisReview === null || this.selectisReview === false) {
              this.todoForm.splice(index, 1)
            }
          } else if (response.data.code === 401) {
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
        }
        } else {
          store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
      })
    },
    mapResponseWithFormData(dataLists){
      const tempDataList = [...dataLists]
      tempDataList.forEach(items => {
        items.isReviewComment = true;
        items.isReplyReviewComment = true;
        items.todoActionType = "";
        if(items.remark === null){
          items.isShowReviewComment = false
          items.isShowReviewBtn = false
        } else {
          items.isShowReviewComment = true
          items.isShowReviewBtn = true
        }
        if(items.remarkComment === null || items.remarkComment === ''){
          items.isShowReplyReviewComment = false
          items.isShowReviewReplyBtn = false
        } else {
          items.isShowReplyReviewComment = true
          items.isShowReviewReplyBtn = true
        }
        if(items.activeStatus === "active") {
          items.isActive = true
        } else {
          items.isActive = false
        }
        items.mtmTodoDiscussions.forEach(disItems => {
          disItems.discussionsError = { isError: false, errorMessage: '' };
          disItems.mtmTodoAction.forEach(actionItems => {
            actionItems.actionItemError = { isError: false, errorMessage: '' };
          });
        });
      });
      this.toDoList = tempDataList
    },
    getAllMtmTodo (clientId, memberId, documentId) {
      axios
        .get('/mtm/todo/getAlltodo?clientId='+clientId +'&memberId='+memberId+'&documentId='+documentId)
        .then(response => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              store.dispatch("hideLoadingSpinner");
              const [year, month, day] = response.data.document.displaySentDate.split("-");
              this.smtForReviewDate = `${month}/${day}/${year}`;
              this.memberName = this.memberDetails.member.firstName +' '+this.memberDetails.member.lastName
              if(this.selectisReview == null || this.selectisReview === false) {
                if(response.data.document?.todoStatus === 1) {
                  store.dispatch("setSelectDocumenStatus", 'Complete');
                } else {
                  store.dispatch("setSelectDocumenStatus", 'Draft');
                }
              }
              if(response.data.dataLists.length > 0) {
                this.mapResponseWithFormData(response.data.dataLists)
              } 
            } else if (response.data.code === 401) {
              store.dispatch("hideLoadingSpinner");
              this.alertType = 'Failed'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
    },
    handleTodoActAndDec (todoId, action) {
      if(this.clientData.clientId !== null && this.memberId !== null) {
        const tempTodoForm = {}
        if (this.clientData.clientId !== null && this.memberId !== null) {
          store.dispatch("showLoadingSpinner");
          this.updateStatusAction (todoId, action, tempTodoForm, this.clientData.clientId)
        }
      }
    },
    updateStatusAction (todoId, action, tempTodoForm, clientId) {
    axios
      .post('/mtm/todo/updateaction?clientId=' +clientId+ '&todoId=' +todoId +'&action=' +action, tempTodoForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    autoSaveByTwoMin() {
      this.interval = setInterval(() => {
        clearInterval(this.errorTimout);
        this.todoForm.map((items, index) => {
          items.clientId = this.clientData.clientId;
          items.memberId = this.memberId;
          items.documentId = this.documentId;
          this.autoSaveValidation = true;
          this.handleMtmAddTodo (index);
        });
      },this.autoSaveTimeout);
    },
    saveByInterval (todoForm, clientId, indexOfTodo, memberId, documentId) {
    axios
      .post('/mtm/todo/addmtmTodo?clinetId='+clientId, todoForm)
      .then(response => {
        if (response.status == 200) {
          if (response.data.code === 201 && !Object.prototype.hasOwnProperty.call(todoForm, "id")) {
            axios
              .get('/mtm/todo/getAlltodo?clientId='+clientId +'&memberId='+memberId+'&documentId='+documentId)
              .then(response => {
                if (response.status === 200) {
                  if (response.data.code === 201) {
                    if(response.data.dataLists.length > 0) {
                      response.data.dataLists.map((items) => {
                        var exist = this.toDoList?.some(element => element.id == items.id);
                        if(!exist) {
                          this.todoForm[indexOfTodo]['id'] = items.id;
                          this.todoForm[indexOfTodo].mtmTodoDiscussions[0].id = items.mtmTodoDiscussions[0].id;
                          this.todoForm[indexOfTodo].mtmTodoDiscussions[0].mtmTodoAction.forEach(items => { 
                            items.isTodoActionNew = false
                          })
                          this.autoSaveStatus(true, indexOfTodo);
                        }
                      })
                    } else {
                      this.autoSaveStatus(false, indexOfTodo);
                    }
                  } else {
                    this.autoSaveStatus(false, indexOfTodo);
                  }
                } else {
                  this.autoSaveStatus(false, indexOfTodo);
                }
              });
            } else if(Object.prototype.hasOwnProperty.call(todoForm, "id")) {
              this.autoSaveStatus(true, indexOfTodo);
              this.todoForm[indexOfTodo].mtmTodoDiscussions[0].mtmTodoAction.forEach(items => { 
                items.isTodoActionNew = false
              })
            }
        } else {
           this.autoSaveStatus(false, indexOfTodo);
           this.todoForm[indexOfTodo].mtmTodoDiscussions[0].mtmTodoAction.forEach(items => { 
            items.isTodoActionNew = false
          })
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false, indexOfTodo);
      })
    },
    autoSaveStatus(success, index) {
      if(!success) {
        this.snackStatus = "Autosave Unsuccessful. Trying again in 15s";
        if(index == this.todoForm.length-1) {
          this.snackColor = "#DD2C00";
          this.snackbar = true
          this.autoSaveTimeout = 15000
          clearInterval(this.interval);
          this.autoSaveByTwoMin();
          var seconds = 15;
          this.snackTimeout = 15000;
          this.errorTimout = setInterval(() => {
            this.snackColor = "#DD2C00";
            seconds = seconds - 1;
            if(seconds == 0) {
              this.snackTimeout = 20000;
              this.snackbar = false;
            }
            this.snackStatus = "Autosave Unsuccessful. Trying again in "+ seconds +"s";
          }, 1000);
        }
      } else {
        this.autoSaveTimeout = 60000
        this.snackStatus = "Autosave Completed at "+ this.formatAMPM(new Date());
        this.snackColor = "#CDDC39";
        if(index == this.todoForm.length-1) {
          this.snackbar = true
        }
      }
    },
    getReviewDocument(clientId, memberId, documentId) {
    store.dispatch("showLoadingSpinner");
      axios
      .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
        {responseType: "blob"},
      )
      .then((response) => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            this.url = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    getDocReviewSectionStatus (clientId, reviewerId) {
      axios
      .get('/mtm/review?clientId='+clientId +'&reviewerId='+reviewerId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataList?.todoStatus === 1) {
              store.dispatch("setSelectDocumenStatus", 'Reviewer');
            } else {
              store.dispatch("setSelectDocumenStatus", 'NotReviewer');
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    }
  },
  mounted () {
    this.clientData = this.selectedClient
    this.memberId = this.selectedMemberId
    this.documentId = this.selectdocumentId
    this.memberDetails = this.memberDetail
    interceptorSetup.refreshToken();
    store.dispatch("setSelectDocumenStatus", 'Draft');
    store.dispatch("showLoadingSpinner");
    if(this.clientData.clientId !== null && this.memberId!== null && this.documentId !== null) {
      this.getAllMtmTodo(this.clientData.clientId, this.memberId, this.documentId)
      if(this.selectisReview){
        this.getDocReviewSectionStatus(this.clientData.clientId, this.selectReviewerId)
        setTimeout(() => {
          this.getReviewDocument(this.clientData.clientId , this.memberId, this.documentId)
        }, 1500);
      }
    }
    if(this.selectisReview == null|| this.selectisReview === false) {
      this.autoSaveByTwoMin();
    }
  },
  unmounted(){ 
   clearInterval(this.interval)
   clearInterval(this.errorTimout);
  }
};
</script>
