<template>
  <v-container fluid>
    <div>
    <!-- Header -->
    <!-- <Header/> -->
    <!-- Header End -->
    <!-- Body -->
    <div id="userInformation">
    <div class="page-header">
      <h1 class="mg-left-6rem">{{ "MemberFirst My Profile" }}</h1>
    </div>
    <div
      class="mg-left-6rem mg-top-10 mg-bottom-30"
      v-if="appUser.user !== null">
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Username"
            readonly
            v-model="appUser.user.username"
            variant="outlined"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="First Name"
            :readonly="isUserEdit"
            v-model="firstName"
            @input="handleFirstName"
            variant="outlined"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Last Name"
            :readonly="isUserEdit"
            v-model="lastName"
            @input="handleLastName"
            variant="outlined"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Email"
            :readonly="isUserEdit"
            v-model="emailId"
            @input="handleEmailId"
            variant="outlined"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Phone"
            :readonly="isUserEdit"
            v-model="phoneNumber"
            @input="handlePhoneNumber"
            variant="outlined"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!isUserEdit" class="pd-left-right">
        <v-btn
          @click="handleEditUserInfo"
          depressed
          color="light-green"
          class="mg-top-10 pd-10">
          <v-icon left size="15" color="#ffffff"> mdi-check </v-icon>
          <span class="font-color-light">Submit Changes</span>
        </v-btn>
        <v-btn variant="outlined"
            style="height: 38px"
            density="comfortable" class="mg-left-5 mg-top-10" @click="handleUserCancel">
          Cancel
        </v-btn>
      </v-row>
      <v-row class="pd-left-right" v-else>
        <v-btn variant="outlined"
          style="height: 38px"
          density="comfortable" color="gray" @click="handleUserEdit">
          <v-icon left> mdi-pencil-outline </v-icon>
          Edit User Info
        </v-btn>
        <v-btn
          style="height: 38px"  
          variant="outlined"
          density="comfortable"
          color="gray"
          class="mg-left-5"
          @click="handleGotoChangePassword"
        >
          <v-icon left> mdi-pencil-outline </v-icon>
          Change Password
        </v-btn>
      </v-row>
    </div>
  </div>
    <!-- Body End -->
    <!--Footer  -->
    <Footer />
    </div>
    <div>
      <AlertDialogs
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        @closeDialog="closeAlertDialog"
      />
    </div>
    <!-- Footer End -->
  </v-container>
</template>
 <script>
 import AlertDialogs from "@/components/dialogs/AlertDialogs.vue";
 import Footer from "@/components/Footer.vue"
 import axios from "axios";
 import store from "@/store";
 export default {
   name: "MyProfile",
   components: {
     Footer,
     AlertDialogs
   },
   data() {
   return {
     isUserEdit: true,
     firstName: '',
     lastName: '',
     emailId: '',
     phoneNumber: '',
     isHideShowAlertDialog: false,
     alertType: "",
     alertMessage: ""
   }
 },
 computed: {
  appUser() {
    return store.getters.appUser;
  }
 },
 methods: {
   handleGotoChangePassword(){
     this.$router.push({ name: 'ChangePassword'})
   },
   handleUserCancel(){
     this.firstName = this.appUser.user.firstname
     this.lastName = this.appUser.user.lastname
     this.emailId = this.appUser.user.email
     this.phoneNumber = this.appUser.user.phone
     this.isUserEdit = true
   },
   handleUserEdit(){
     this.isUserEdit = false
   },
   handleFirstName (value){
     this.firstName = value.target.value
   },
   handleLastName (value){
     this.lastName = value.target.value
   },
   handleEmailId (value){
     this.emailId = value.target.value
   },
   handlePhoneNumber (value){
     this.phoneNumber = value.target.value
   },
   closeAlertDialog () {
     this.isHideShowAlertDialog = false
     this.alertType = ''
     this.alertMessage = ''
   },
   handleEditUserInfo(){
     const userObj = {
       username: this.appUser.user.username,
       firstname: this.firstName !== '' ? this.firstName : this.appUser.user.firstname, 
       lastname: this.lastName !== '' ? this.lastName : this.appUser.user.lastname,
       email: this.emailId !== '' ? this.emailId : this.appUser.user.email,
       phone: this.phoneNumber !== '' ? this.phoneNumber : this.appUser.user.phone,
     }
     this.editUserInfo(userObj)
   },
   editUserInfo(userObj) {
     axios
     .post('/user/editUserInfo', userObj)
     .then(response => {
       if (response.status === 200) {
         if (response.data.code === 201) {
           this.isUserEdit = true
           //store.dispatch("hideLoadingSpinner");
           this.alertType = 'Success'
           this.alertMessage = response.data.message
           this.isHideShowAlertDialog = true
         } else if (response.data.code === 401) {
           //store.dispatch("hideLoadingSpinner");
           this.alertType = 'Failed'
           this.alertMessage = response.data.message
           this.isHideShowAlertDialog = true
         }
       } else {
         //store.dispatch("hideLoadingSpinner");
         this.alertType = 'Failed'
         this.alertMessage = 'Something went wrong'
         this.isHideShowAlertDialog = true
       }
     })
     .catch((error) => {
       console.log(error)
       //store.dispatch("hideLoadingSpinner");
       this.alertType = 'Failed'
       this.alertMessage = 'Something went wrong'
       this.isHideShowAlertDialog = true
     })
   }
 },
 mounted () {
  setTimeout(()=>{
  if(this.appUser !== null){
   this.firstName = this.appUser.user.firstname
   this.lastName = this.appUser.user.lastname
   this.emailId = this.appUser.user.email
   this.phoneNumber = this.appUser.user.phone
  }else{
    this.$router.push({name:'MemberSearch'})
  }
  },10);
 }
 };
 </script>
  <style>
  .header-logo {
    width: 86px;
    margin: 0px 0px 0px 10rem;
    height: 95px;
  }
  .theme--light.v-btn.v-btn--has-bg {
    background-color: #ffffff;
  }
  .v-btn--is-elevated {
    box-shadow: 0px 0px 0px 0px;
  }
  .v-sheet.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 0px 3px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 2%) !important;
  }
  .container {
    padding: 0px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 1px 4px;
  }
  .text-center-xs {
    text-align: center !important;
    float: none !important;
  }
  #footer > .container {
    padding-top: 60px;
    margin-bottom: 60px;
  }
  #footer address .footer-sprite.address {
    background-position: 0 0;
  }
  #footer address .footer-sprite {
    margin-bottom: 20px;
    padding-left: 0px;
  }
  .copyright {
    background-color: #222222f7;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
    padding: 25px 0;
    font-size: 13px;
    display: block;
    color: rgba(255, 255, 255, 0.6);
  }
  #footer ul {
    margin-bottom: 0;
  }
  ul.list-inline > li {
    display: inline-block;
  }
  .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
  #footer a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
  }
  .copyright a {
    color: rgba(255, 255, 255, 0.6) !important;
    text-decoration: none;
  }
  #footer h1,
  .footer h2,
  .footer h3,
  .footer h4,
  .footer h5,
  .footer h6 {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 600;
  }
  #footer address .footer-sprite.address {
    background-position: 0 0;
  }
  #footer {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    display: block;
    background: #313131;
    background: -moz-linear-gradient(top, #555555 0%, #313131 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #1a1d2b),
      color-stop(100%, #313131)
    );
    background: -webkit-linear-gradient(top, #555555 0%, #313131 100%);
    background: -o-linear-gradient(top, #555555 0%, #313131 100%);
    background: -ms-linear-gradient(top, #555555 0%, #313131 100%);
    background: linear-gradient(to bottom, #555555 0%, #313131 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#555555', endColorstr='#313131',GradientType=0 );
  }
  #footer address {
    background: url(../assets/world-map.png) no-repeat center;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .fs-20 {
    font-size: 20px !important;
  }
  #footer h2 {
    font-size: 26px;
    text-shadow: rgb(0 0 0 / 30%) 3px 3px 5px;
  }
  .text-center-xs {
    text-align: center !important;
    float: none !important;
  }
  v-btn__content {
    font-weight: 100;
  }
  .page-header {
    position: relative;
    padding: 50px 0 50px 0;
    border-top: 0;
    margin-top: 0;
    margin-bottom: 0 !important;
    background-color: rgba(148, 149, 150, 0.05);
    border-bottom: 0;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
  }
  
  h1 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 300;
    color: black;
  }
  #userInformation{
    position: relative;
    top: 0px;
    height: 112vh;
    min-height: 860px;
  }

  #userInformation .pd-left-right{
    padding: 0 22px;
  }
  </style>
  
