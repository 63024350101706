<template>
    <v-container class="main_container" fluid>
       <v-col style="padding-left: 0px;">
        <h1 class="heading">Online Privacy Policy Agreement</h1> 
        <v-divider></v-divider>
       
        <div class="pd-16">
            <v-btn v-if="isShow"
            class="mg-right-10"
                @click="cancel()">
                Cancel
            </v-btn>
            <v-btn v-if="isShow"
                @click="print()"
                class="print-btn">
                <v-icon
                size="20"
                class="mg-right-10"
                color="white"
                >mdi-printer-outline
                </v-icon>
                <span>Print</span>
            </v-btn>
        </div>
        <v-col class="paragraph" style="padding-left: 0px;">
            <h4 class="sub-heading">
                Introduction
            </h4>

            <p class="p-style">
                <i>
                    This online privacy and policy agreement governs your use of this 
                     website; by using this website, you accept this policy in full. 
                </i>
            </p>
            
            <p> Member First, Inc. (MEMBER FIRST), is committed to keeping any and all personal information collected
                of those individuals that visit our website and make use of our online facilities and services
				accurate, confidential, secure and private. Our privacy policy has been designed and created to 
                ensure those affiliated with MEMBER FIRST of our commitment and realization of our obligation not
				only to meet but to exceed most existing privacy standards.
            </p>

            <p>This site is owned and operated by MEMBER FIRST.  This Privacy Policy describes the choices you
               can make about the way your information is collected and used. Our Privacy Policy applies to the
			   information collected on our site and to any other information that MEMBER FIRST collects about you.
            </p>

            <p>For information about the privacy of your personal health information, please review the MEMBER FIRST 
               Notice of Privacy Practices.
            </p>

            <h4 class="sub-heading">Your Consent to Use Our Site</h4>

            <p>By using our site, you signify your consent to our collection, use, and disclosure of your personal 
               information in accordance with this Privacy Policy. If you do not agree with the practices described
			   in this Privacy Policy, please do not use our site.
            </p>

            <h4 class="sub-heading">Collection Information</h4>
            <p>This website collects various types of information, such as:</p>

            <ul class="mg-b-list" style="list-style-type:disc;">
                <li>Voluntarily provided information which may include your name, address, email address, to deliver the services you
                    have requested.</li>
                <li>Protected Health Information necessary for analyzing prescription orders between your prescriber and pharmacy of 
                    choice.  This information is also protected under our Notice of Privacy
                    Practices.
                </li>
                <li>Technical information automatically collected when visiting our website, which may include cookies, third party 
                    tracking technologies and server logs.
                </li>
			</ul>

            <p>MEMBER FIRST collects information in four primary ways:</p>

            <ul class="mg-b-list" style="list-style-type:disc;">
                <li>When you provide us with information when you interact with us about products and services offered by MEMBER FIRST.</li>
                <li>When you register as a member of our site</li>
                <li>When we collect technical information automatically when you visit our site.</li>
                <li>When we obtain information about you from your employer or health care plan in connection with your prescription orders.</li>
			</ul>

            <h4 class="sub-heading">Use of Information Collected</h4>

            <p>MEMBER FIRST may collect and may make use of personal information to assist in the operation of our website
               and to ensure delivery of the services you need and request. At times, we may find it necessary to
          	   use personally identifiable information as a means to keep you informed of other possible products and/or services 
               that may be available to you from our site. MEMBER FIRST may also be in contact with you with
			   regards to completing surveys and/or research questionnaires related to your opinion of current or potential future
               services that may be offered.
            </p>

            <p>MEMBER FIRST does not now, nor will it in the future, sell, rent or lease any of our customer lists and/or 
               names to any third parties.
            </p>
        
            <p>MEMBER FIRST may feel it necessary, from time to time, to make contact with you on behalf of other external 
               business partners with regards to a potential new offer which may be of interest to you. If you
			   consent or show interest in presented offers, then, at that time, specific identifiable information,
               such as name, email address and/or telephone number, may be shared with the third party.
            </p>
        
        
            <p>MEMBER FIRST may find it beneficial to share specific data with our trusted partners in an effort to conduct statistical
               analysis, provide you with email and/or postal mail, deliver support and/or arrange
               for deliveries to be made. Those third parties shall be strictly prohibited from making use of your personal 
               information, other than to deliver those services which you requested, and as such they are thus required,
               in accordance with this agreement, to maintain the strictest of confidentiality with regards to all your information.
            </p>
        
            <p>MEMBER FIRST may disclose your personal information, without prior notice to you, only if required to do so in 
               accordance with applicable laws and/or in a good faith belief that such action is deemed
			   necessary or is required in an effort to:</p>
        
            <ul class="mg-b-list" style="list-style-type:disc;">
                <li>Remain in conformance with any decrees, laws and/or statutes or in an effort to comply with any process which may be served upon MEMBER FIRST and/or our website.</li>
                <li>Maintain, safeguard and/or preserve all the rights and/or property of MEMBER FIRST.</li>
                <li>Perform under demanding conditions in an effort to safeguard the personal safety of users of our site and/or the general public.</li>
                <li>We do not sell your personal information to anyone for marketing purposes.</li>
            </ul>

            <p>Anonymous and aggregate information is collected by Crystal Clear Rx for historical, statistical, 
                and business planning purposes.  This data is:
            </p>

            <ul class="mg-b-list" style="list-style-type:disc;">
                <li>Technical, non-personal information about site users</li>
                <li>Anonymous, by removing personally identifiable information</li>
                <li>Cannot be used to identify any individual</li>
            </ul>

            <h4 class="sub-heading">Online Policy for Children</h4>

            <p>Our site is not intended or designed to attract users under the age of 18.  We do not collect personally 
               identifiable information from any person we know to be under the age of 18 and instruct users under the
			   age of 18 not to send any information to MEMBER FIRST through our site.
            </p>

            <h4 class="sub-heading">Unsubscribe or Opt-Out</h4>

            <p>You have the ability to review, modify, or request the removal of personal information we collect about you.
               Certain information may be retained, however, to satisfy legal obligations.  You may choose to
               limit the types and frequency of communication you receive from Crystal Clear Rx.  You may choose to Terminate your
               relationship with MEMBER FIRST at any point.</p>


            <p>If you wish to unsubscribe or opt-out from any third party websites, you must go to that specific website to unsubscribe 
                and/or opt-out.</p>


            <h4 class="sub-heading">Links to Other Web Sites</h4>

            <p>Our website may contain links to affiliate and other websites. MEMBER FIRST does not claim nor accept responsibility for any
               privacy policies, practices and/or procedures of other such websites. Therefore,
               we encourage all users and visitors to be aware when they leave our website and to read the privacy statements of each and every 
               website that collects personally identifiable information. The aforementioned Privacy
               Policy Agreement applies only and solely to the information collected by our site.</p>


            <h4 class="sub-heading">Security</h4>

            <p>MEMBER FIRST shall endeavor and shall take every precaution to maintain adequate physical, procedural and technical
               security with respect to our offices and information storage facilities so as to prevent any
               loss, misuse, unauthorized access, disclosure or modification of the user's personal information under our control.
            </p>


            <p>The company also uses Transport Socket Layer (TSL) for authentication and private communications in an effort to build
               users' trust and confidence in the internet and website use by providing simple and secure
               access and communication of personal information.
            </p>


            <h4 class="sub-heading">Changes to Privacy Policy Agreement</h4>

            <p>MEMBER FIRST reserves the right to update and/or change the terms of our privacy policy, and as such we will post 
               those change to our website homepage at our site, so that our users and/or visitors are always
               aware of the type of information we collect, how it will be used, and under what circumstances, if any, we may disclose
               such information. If at any point in time MEMBER FIRST decides to make use of any personally
               identifiable information on file, in a manner vastly different from that which was stated when this information was initially
               collected, the user or users shall be promptly notified by email. Users at that time shall
               have the option as to whether or not to permit the use of their information in this separate manner.
            </p>

            <h4 class="sub-heading">Acceptance of Terms</h4>

            <p>Through the use of this website, you are hereby accepting the terms and conditions stipulated within the aforementioned
               Online Privacy Policy Agreement. If you are not in agreement with our terms and conditions,
               then you should refrain from further use of our sites. In addition, your continued use of our website following the 
               posting of any updates or changes to our terms and conditions shall mean that you are in agreement
               and acceptance of such changes.
            </p>

            <h4 class="sub-heading">How to Contact Us</h4>

            <p>If you have any questions or concerns regarding the Privacy Policy Agreement related to our website, please feel 
               free to contact us at the following email, telephone number or mailing address.
            </p>

            <p><b>Email:</b> <a href="mailto:compliance@memberfirstpharmacy.com">compliance@memberfirstpharmacy.com</a></p>

            <p>Mailing Address:</p>

            <ul class="list-unstyled mg-b-list">
                    <li>Member First, Inc.</li>
                    <li>Attn: Privacy Officer</li>
                    <li>13809 Research Blvd., Suite 500<br>  Austin, TX 78750</li>
            </ul>

                <p>06/01/2019</p>
                <div class="col-md-8">
                <h6><i class="fa fa-file-text-o" aria-hidden="true"></i> 70009 r1.4</h6>
            </div>

        </v-col>
        
    </v-col> 
    </v-container>
  </template>
  <script>
  export default {
    name: "Privacy",
    components: {
    },
    data() {
      return {
        isShow:true,
    };
  },
  computed: {
  },
  methods: {
    cancel()
    {
        this.$router.push({ name: 'Register'})
    },
    print()    
    { 
       this.isShow=false
       setTimeout(()=>{
            window.print();
            this.isShow=true;
      },50)
    }
  },
  mounted () {
  }
  };
  </script>

  <style>
    .main_container{
       padding:30px !important; 
    }
    
    .theme--light.v-btn.v-btn--has-bg{
        border: 1px solid #ccc;
    }
    
    .print-btn{
       background-color: #007bff !important;
       
    }
    .print-btn span{
        color: white;
    }
    
    .p-style{
    font-family: 'Open Sans',Arial,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.5;
    margin: 1.6px 0 30px 0;
    }
    
    
</style>