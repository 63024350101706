import axios from 'axios';
import store from "@/store";
import emitter from '../emitter';
const TOKEN_STORAGE_KEY = 'auth_token';
let intervalRef = null;
let appUserIntervalRef = null;
let tokenLife = null
function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Content-type'] = 'application/json';
}

function saveToken(token) {
  localStorage.setItem(TOKEN_STORAGE_KEY, token);
  setAuthToken(token);
}

function removeToken() {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  delete axios.defaults.headers.common['Authorization'];
}

function getAuthToken() {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
}

// function getRefreshToken() {
//   return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
// }

function expiryTokenWarning () {
  appUserIntervalRef = setInterval(()=>{
    if(store.getters.appUser !== null) {
      clearInterval(appUserIntervalRef)
    if (intervalRef !== null) {
      clearInterval(intervalRef)
      intervalRef = null
    }
    let currentTime = null
    currentTime = new Date().toISOString()
    let fiveMinuteBeforeTokenExpiryTime = null
    fiveMinuteBeforeTokenExpiryTime = new Date(Date.now() + ((store.getters.appUser.tokenLife / 60) - 5) * 60 * 1000).toISOString()
    const exFiveMinuteBeforeTokenExpiryTime = new Date(fiveMinuteBeforeTokenExpiryTime)
    exFiveMinuteBeforeTokenExpiryTime.setSeconds(exFiveMinuteBeforeTokenExpiryTime.getSeconds() - 2)
    const updatedFiveMinuteBeforeTokenExpiryTime = exFiveMinuteBeforeTokenExpiryTime.toISOString()
    let tokenExpiryTime = null
    tokenExpiryTime = new Date(Date.now() + (store.getters.appUser.tokenLife / 60) * 60 * 1000).toISOString()
    const exTokenExpiryTime = new Date(tokenExpiryTime)
    exTokenExpiryTime.setSeconds(exTokenExpiryTime.getSeconds() - 2)
    const updatedTokenExpiryTime = exTokenExpiryTime.toISOString()
    let count = 0
    // Compare times
    intervalRef = setInterval(() => {
      if (currentTime > updatedFiveMinuteBeforeTokenExpiryTime && currentTime < updatedTokenExpiryTime) {
        currentTime = new Date().toISOString()
        if (count === 0) {
          count = count + 1
          const data = {
            tokenExpiryTime: tokenExpiryTime,
            currentTime: currentTime,
            status: 'continue'
          }
          emitter.emit('openAlertExpiryToken', data);
        }
      } else if (currentTime >= updatedTokenExpiryTime) {
        currentTime = new Date().toISOString()
        const data = {
          tokenExpiryTime: tokenExpiryTime,
          currentTime: currentTime,
          status: 'stop'
        }
        emitter.emit('openAlertExpiryToken', data)
        clearInterval(intervalRef)
      } else {
        currentTime = new Date().toISOString()
      }
    }, 1 * 1000)
  }
},1000)
}

async function refreshToken() {
  const refreshToken = getAuthToken();
  if (refreshToken) {
    try {
      setAuthToken(refreshToken)
      const response = await axios.get('/api/secure/refreshToken');
      const  token = response.data;
      setAuthToken(token);
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
      expiryTokenWarning()
    } catch (error) {
      console.log("Something went wrong!!")
    }
  } else {
    console.log("Token not available!!")
  }
}
function getUser() {
  store.dispatch("showLoadingSpinner");
  if (!store.getters.appStandAlone) {
    axios
      .get("/clinicians/ajax/getAppUser", {
        headers: {
          "Content-type": "application/json"
        }
      })
      .then(response => {
        var responseObj = response.data;
        store.commit("SET_APP_USER", responseObj);
        if (responseObj.user) {
          store.dispatch(
            "setSelectedClientById",
            responseObj.user.lastClientId
          );
        }
        store.dispatch("hideLoadingSpinner");
      })
      .catch(error => {
        var messageObj = {
          message:
            error.response && error.response.status == 504
              ? "Network timeout - user not loaded"
              : "Error loading user details...",
          class: "errorMessage"
        };
        store.dispatch("setMessage", messageObj);
        store.dispatch("hideLoadingSpinner");
      });
  } else {
    store.commit("SET_APP_USER", store.getters.demoUser);
    console.log("setting timeout...");
    store.dispatch(
      "setSelectedClientById",
      store.getters.demoUser.user.lastClientId
    );
    store.dispatch("setMessage", {
      message: "demo user loaded",
      class: "successMessage"
    });
    store.dispatch("hideLoadingSpinner");
  }
}

export default {
  saveToken,
  removeToken,
  getAuthToken,
  refreshToken,
};
