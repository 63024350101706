<template>
  <!-- <v-container fluid>
  </v-container> -->
  <div id="preloader" v-if="showLoader">
    <div class="inner">
      <div  class="loader">
      </div>
    </div>
  </div>
  <div id="landingPage" v-else>
    <Header />
    <div style="min-height:500px; height: 100vh;">
    <div  id="background_image" class="background_img_login">
      <v-col style="padding:50px">
        <v-row>
          <h1 class="animated animatedFadeInUp fadeInUp main_heading">Welcome to the Member First Portal</h1>
        </v-row>
        <v-row>
          <p class="animated animatedFadeInUp fadeInUp sub_heading">I have received a letter</p>
        </v-row>
        <v-row>
          <router-link to="/register" class="sign_me_up">
            <span class="sign-me-up-span">
              <v-icon
                  size="20"
                  class="mg-right-10"
                  color="white"
                >mdi-widgets 
              </v-icon>
              SIGN ME UP
            </span>
          </router-link>
        </v-row>
      </v-col> 
    </div>
  </div>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import interceptorSetup from '../helpers/httpInterceptor'
import store from "@/store";
export default {
  name: "LandingPage",
  components: {
    Header,
    Footer
  },
  data() {
  return {
    showLoader: true
  };
},
computed: {
 
},
methods: {
},
mounted () {
  if (!interceptorSetup.getAuthToken()) {
    interceptorSetup.removeToken();
    store.commit("SET_APP_USER", null);
  }
  setTimeout(() => {
    this.showLoader = false;
  }, 3000);
}
};
</script>
<style>
.sign_me_up:active{
background-color: #005cbf;
}

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,120px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.v-container {
    width: 100%;
    padding: 0px !important;
    margin-right: auto;
    margin-left: auto;
}
.background_img_login{
  display: block;
  background: url(../assets/grain_bg.png);
  background-size: cover;
    height: 100% !important;
    position: relative;
    top: 100px;
    padding: 100px 0;
 }
</style>
