<template>
  <v-app>
    <v-app-bar app color="#f4f4f4" height="82" v-if="appUser != null">
      <v-app-bar-nav-icon @click="handleIsNavigationShow"></v-app-bar-nav-icon>
      <v-toolbar-title>
        MemberFirst Clinician Portal
        <transition name="fade">
          <span v-if="showMessage" :class="showMessageClass">
            {{ message }}
            <v-icon v-on:click="hideMessage">mdi-close-circle</v-icon>
          </span>
        </transition>
      </v-toolbar-title>
      <v-row>
        <v-col cols="5"></v-col>
        <v-col cols="3">
          <v-switch
            v-model="privacyModes"
            color="#030c4d"
            label="Privacy Mode"
            class="mt-6 mr-3 privacyToggle"
            @change="togglePrivacySwitch($event)"
            />
        </v-col>
        <v-col cols="4" class="mg-top-15">
          <v-select
            v-if="clientItems !== null"
            :items="clientItems"
            v-model="clientSelectModel"
            item-title="shortName"
            item-value="clientId"
            :disabled="clientSelectDisabled"
            hide-details="auto"
            variant="outlined"
            density="comfortable"
            dense
            class="clientSelect"
            @update:modelValue="selectClientById"
            return-object
          />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="isDrawer" absolute temporary width="300">
        <div class="mg-left-20 font-weight-700 mg-top-10 fs-22">{{ selectedClient !== null ? (privacyModes == true ? "Client Id" + selectedClient.clientId : selectedClient.shortName) : "" }}</div>
        <v-list>
          <v-list-item
            prepend-icon="mdi-home"
            @click="gotoHome"
            title="Home">
          </v-list-item>
          <v-list-item
            prepend-icon="mdi mdi-account-search-outline"
            @click="gotoMemberSearch"
            title="Member Search">
          </v-list-item>
          <v-list-item
            prepend-icon="mdi mdi-account-search-outline"
            @click="gotoMemberDetails"
            title="Member Details">
          </v-list-item>
          <!-- <v-list-item
            prepend-icon="mdi-account"
            @click="gotoMyProfile"
            title="User Info">
          </v-list-item> -->
          <v-list-item
            prepend-icon="mdi-table"
            @click="gotoQualityControlQueue"
            title="Quality Control Queue">
          </v-list-item>
          <!-- <v-list-item prepend-icon="mdi-hospital-building" @click="gotoClinicianPortal" title="Clinician Portal"></v-list-item> -->
          <v-list-group value="Reporting">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi mdi-file-document-edit-outline"
                title="Reporting">
              </v-list-item>
            </template>
            <v-list-group value="Targeting_Reports">
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" title="Targeting Reports"></v-list-item>
              </template>
              <v-list-item
                v-for="([title], i) in targetReportingList"
                :key="i"
                :title="title"
                :value="title"
                @click="gotoTargetingReportsSubMenu(title)"
              ></v-list-item>
            </v-list-group>
            <v-list-group value="patientStatusReportsList">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  title="Patient Status Reports"
                ></v-list-item>
              </template>
              <v-list-item
                v-for="([title], i) in patientstatusreportsList"
                :key="i"
                :value="title"
                :title="title"
                @click="gotoPatientStatusReportsSubMenu(title)"
              ></v-list-item>
            </v-list-group>
            <v-list-group value="Intervention_Reports">
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" title="Intervention Reports"></v-list-item>
              </template>
              <v-list-item
                v-for="([title], i) in interventionReportsList"
                :key="i"
                :title="title"
                :value="title"
                @click="gotoInterventionReports(title)"
              ></v-list-item>
            </v-list-group>
          </v-list-group>
          <v-list-item
            prepend-icon="mdi-account-circle"
            title="My Profile"
            @click="gotoMyProfile"
          ></v-list-item>
          <!-- <v-list-item
            prepend-icon="mdi mdi-security"
            title="Administration"
          ></v-list-item> -->
          <v-list-item
            prepend-icon="mdi-logout"
            @click="gotoLogout"
            :title="appUser != null ? 'Logout ' + appUser?.user.username : ''"
          ></v-list-item>
        </v-list>
    </v-navigation-drawer>
      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
        <div class="loadingSpinner" id="loadingSpinner">
      <div class="loadingSpinnerImg" id="loadingSpinnerImg">
        <img src="/css/images/spinner.gif" id="spinnerImg" />
      </div>
    </div>
    </v-main>
    <AlertTokenExpiryDialogs v-if="alertExpiryToken"
     @closeDialog="closeTokenExpiryDialogs"
     :sign-type="type" 
     :token-expiry = "tokenExpiryTime"/>
  </v-app>
</template>
<style>
@import './assets/StyleSheets/global.css';
@import './assets/StyleSheets/ag-grid.css';
@import './assets/StyleSheets/ag-theme-alpine.css';
</style>
<script>
import DisplayAllCriteriaReport from "@/views/DisplayAllCriteriaReport.vue";
import DisplayDrugCriteriaReport from "@/views/DisplayDrugCriteriaReport.vue";
import DisplayICDCriteriaReport from "@/views/DisplayICDCriteriaReport.vue";
import DisplayMoneyCriteriaReport from "@/views/DisplayMoneyCriteriaReport.vue";
import DisplayPharmacyCriteriaReport from "@/views/DisplayPharmacyCriteriaReport.vue";
import DisplayPrescriberCriteriaReport from "@/views/DisplayPrescriberCriteriaReport.vue";
import DisplayReTargetedReport from "@/views/DisplayReTargetedReport.vue";
import GPIInclusionReport from "@/views/GPIInclusionReport.vue";
import AlertTokenExpiryDialogs from './components/dialogs/AlertTokenExpiryDialogs.vue'
// import DisplayMemberDetails from "@/components/DisplayMemberDetails.vue";
import interceptorSetup from './helpers/httpInterceptor'
  import axios from "axios";
  import store from "@/store";
  import emitter from './emitter';
  export default {
  name: 'App',
  components: {
    AlertTokenExpiryDialogs,
    DisplayReTargetedReport,
    GPIInclusionReport,
    DisplayAllCriteriaReport,
    DisplayDrugCriteriaReport,
    DisplayICDCriteriaReport,
    DisplayMoneyCriteriaReport,
    DisplayPharmacyCriteriaReport,
    DisplayPrescriberCriteriaReport
  },
  data: () => ({
    tokenExpiryTime: '',
    selectedClientByid: '',
    privacyModes: true,
    isDrawer: false,
    clientSelectModel: null,
    open: ['Users'],
    targetReportingList: [
      ['All Criteria'],
      ['GPI Limit'],
      ['Prescriber Limit'],
      ['Pharmacy Limit'],
      ['Top Rx Spend'],
      ['Top Medical Spend'],
      ['GPI Inclusion'],
      ['ICD-10 Inclusion'],
      ['Re-Targeted']
    ],
    patientstatusreportsList: [
      ['Dormant'],
      ['Declined'],
      ['Targeted'],
      ['Invited'],
      ['Accepted'],
      ['Managed'],
      ['Retired'],
      ['Graduated']
    ],
    interventionReportsList: [
      ['Ad-hoc Report']
    ],
    type: '',
    alertExpiryToken: false,
    intervalRef: undefined
  }),
  computed: {
    memberStatusItems() {
      return store.getters.memberStatusItems;
    },
    qualifiedOptions() {
      var options = store.getters.qualifiedOptions.filter(x => {
        return x.id != 1 && x.id != 30 && x.id != 101;
      });
      return options;
    },
    criteriaOptions() {
      return store.getters.criteriaOptions;
    },
    clientSelectDisabled() {
      if (
        this.appUser != null &&
        this.appUser.clients != null &&
        this.appUser.clients.length > 1
      ) {
        return false;
      }
      return true;
    },
    clientItems() {
      if (this.appUser != null && this.appUser.clients != null) {
        if (this.privacyModes) {
          var privateClients = [];
          for (var i = 0; i < this.appUser.clients.length; i++) {
            privateClients.push({
              clientId: this.appUser.clients[i].clientId,
              clientCode: this.appUser.clients[i].clientCode,
              active: this.appUser.clients[i].active,
              shortName: "Client Id " + this.appUser.clients[i].clientId,
              longName:
                "Demo Imaginary Client " + this.appUser.clients[i].clientId
            });
          }
          this.clientSelectModel = privateClients.find((data) => data.clientId === this.selectedClientByid.clientId).shortName
          return privateClients;
        } else {
          this.clientSelectModel = this.appUser.clients.find((data) => data.clientId === this.selectedClientByid.clientId).shortName
          return this.appUser.clients;
        }
      }
      return null;
    },
    selectedReport() {
      return store.getters.selectedReport;
    },
    showMessage() {
      return store.getters.showMessage;
    },
    hideMessage() {
      store.commit("SET_SHOW_MESSAGE", false);
    },
    message() {
      return store.getters.message;
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    memberDetail() {
      return store.getters.memberDetail;
    },
    mainTab() {
      return store.getters.mainTab;
    },
    appUser() {
      return store.getters.appUser;
    },
    privacyMode: {
      get() {
        return store.getters.privacyMode;
      },
      set(value) {
        this.$data.privacyModes = value;
      }
    }
  },
  watch: {
    selectedClient(newClient) {
      this.selectedClientByid = newClient
      if (this.privacyModes) {
        this.clientSelectModel = 'clientId ' + newClient.clientId;
      } else{
        this.clientSelectModel =   newClient.shortName;
      }
    }
  },
  mounted () {
    emitter.on('openAlertExpiryToken', (data) => {
      this.openAlertExpiryTokenChange(data)
    });
    document.addEventListener('click', this.userDidSomething)
    document.addEventListener('keydown', this.userDidSomething)
  },
  methods: {
    userDidSomething () {
      interceptorSetup.refreshToken();
    },
    closeTokenExpiryDialogs () {
      this.alertExpiryToken = false
    },
    openAlertExpiryTokenChange (data) {
      this.type = data.status
      this.tokenExpiryTime = data.tokenExpiryTime
      this.alertExpiryToken = true
    },
    gotoLogout () {
      this.drawer = false
      this.$router.push('/logout')
    },
    togglePrivacySwitch(switchState) {
      store.commit("SET_PRIVACY_MODE", this.privacyModes);
    },
    selectClientById(value) {
      this.clientSelectModel = value.shortName
      this.selectedClientByid = value
      if (value.clientId !== this.selectedClient.clientId) {
        store.dispatch("setSelectedClientById", value.clientId);
      }
    },
    gotoPatientStatusReportsSubMenu (title) {
      this.isDrawer = false
      store.dispatch("hideLoadingSpinner");
      this.reportSelectedPatientStatusReports()
      const selectedVal = this.memberStatusItems.find(data => data.text === title)
      const tempSelectedVal = {
        id: selectedVal.value,
        text: selectedVal.text
      }
      if(selectedVal.text === "Dormant"){
        this.$router.push({ name: 'DormantReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      } else if(selectedVal.text === "Declined"){
        this.$router.push({ name: 'DeclinedReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      } else if(selectedVal.text === "Targeted"){
        this.$router.push({ name: 'TargetedReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      } else if(selectedVal.text === "Invited"){
        this.$router.push({ name: 'InvitedReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      } else if(selectedVal.text === "Accepted"){
        this.$router.push({ name: 'AcceptedReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      } else if(selectedVal.text === "Managed"){
        this.$router.push({ name: 'ManagedReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      } else if(selectedVal.text === "Retired"){
        this.$router.push({ name: 'RetiredReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      } else if(selectedVal.text === "Graduated"){
        this.$router.push({ name: 'GraduatedReport', params: { selectedValue: JSON.stringify(tempSelectedVal)}})
      }
    },
    gotoTargetingReportsSubMenu (title) {
      this.isDrawer = false
      store.dispatch("hideLoadingSpinner");
      this.reportSelectedTargetingReports()
      const selectedVal = this.criteriaOptions.find(data => data.name === title)
      if (selectedVal != null && selectedVal.id === 0) {
        this.$router.push({ name: 'DisplayAllCriteriaReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal != null &&  selectedVal.id === 101) {
        this.$router.push({ name: 'DisplayReTargetedReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal !== null && selectedVal.id === 4) {
          this.$router.push({ name: 'DisplayTopRxSpendReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal !== null && selectedVal.id === 5) {
          this.$router.push({ name: 'TopMedicalSpendReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal !== null && selectedVal.id === 2) {
        this.$router.push({ name: 'DisplayPrescriberCriteriaReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal !== null && selectedVal.id === 1) {
        this.$router.push({ name: 'DisplayDrugCriteriaReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal !== null && selectedVal.id === 6) {
        this.$router.push({ name: 'GPIInclusionReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal !== null && selectedVal.id === 3) {
        this.$router.push({ name: 'DisplayPharmacyCriteriaReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      } else if (selectedVal !== null && selectedVal.id === 7) {
        this.$router.push({ name: 'DisplayICDCriteriaReport', params: { selectedValue: JSON.stringify(selectedVal)}})
      }
    },
    handleIsNavigationShow () {
      this.isDrawer = ! this.isDrawer
    },
    gotoMyProfile () {
      this.drawer = false
      this.$router.push('/myprofile')
    },
    gotoClinicianPortal () {
      this.isDrawer = false
      this.$router.push('/clinicianPortal')
    },
    gotoQualityControlQueue () {
      this.isDrawer = false
      this.$router.push('/qualityControlQueue')
    },
    gotoHome () {
      this.isDrawer = false
      this.$router.push('/')
    },
    gotoInterventionReports (value) {
      this.drawer = false
      if (value === 'Ad-hoc Report') {
        this.$router.push('/adHocReport')
      }
    },
    gotoMemberDetails () {
      this.isDrawer = false
      this.$router.push('/displayMemberDetails')
    },
    gotoMemberSearch () {
      this.isDrawer = false
      this.$router.push('/memberSearch')
    },
    reportSelectedTargetingReports: function() {
      store.dispatch("setSelectedReport", null);
      store.commit("SET_REPORT_MEMBERS", []);
      store.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", []);
      store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
      // store.dispatch("getReportMembers");
    },
    reportSelectedPatientStatusReports() {
      store.dispatch("setSelectedQualifiedReport", null);
      store.dispatch("getQualifiedReportMembers");
    },
    showMessageClass() {
      return "messageDiv " + store.getters.showMessageClass;
    }
  }
}
</script>
