<template>
    <v-container fluid>
        <div class="pd-30">
            <h1 class="heading">Terms of Service</h1>
            <v-divider></v-divider>
            <div class="pd-16">
                <v-btn v-if="isShow"
                    @click="print()"
                    class="print-btn">
                    <v-icon
                    size="20"
                    class="mg-right-10"
                    color="white"
                    >mdi-printer-outline
                    </v-icon>
                    <span>Print</span>
                </v-btn>
            </div>
            <v-col class="paragraph" style="padding-left: 0px;">
                <h4 class="sub-heading">Introduction</h4>
                <p>These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full.</p>
                <p><span class="content font-weight-600 gray-primary">PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEB SITE (Site). BY ACCESSING OR USING OUR SITES AND OUR SERVICES, YOU HEREBY AGREE TO BE BOUND BY THE TERMS AND ALL TERMS INCORPORATED 
                HEREIN BY REFERENCE. IT IS THE RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT EXPRESSLY 
                AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR SITES OR OUR SERVICES. THIS TERMS OF SERVICE AGREEMENT IS EFFECTIVE AS OF 06/01/2016.
                </span><span>This Site is provided by MEMBER FIRST
                and may be used for informational purposes only. By using the Site or downloading materials from the Site, you agree to abide by the Terms of Service set forth in this Notice. If you do not agree
                to abide by these Terms of Service, do not use the Site or download materials from the Site.</span></p>

                <h4 class="sub-heading">Limited License</h4>
                <p>Subject to the terms and conditions set forth in these Terms of Service to which you have agreed (Agreement), MEMBER FIRST grants you a license that is non-exclusive, non-transferable, with a limited
                right to access, use and display this Site and the informational materials (Materials) contained in the Site. You agree not to interrupt or attempt to interrupt the operation of the Site in any
                way.</p>
                <p>Any and all visitors to our site, despite whether they are registered or not, shall be deemed as "users" of the herein contained Services provided for the purpose of this Terms of Service. 
                Once an individual registers for our Services, through the process of creating an account, the user shall then be considered a "member."</p>
                <p>MEMBER FIRST authorizes members to view and download the Materials at this Site for your personal, non-commercial use only. This authorization is not a transfer of title in the Materials or copies of
                the Materials and is subject to the following restrictions: 1) you must retain, on all copies of the Materials downloaded, all copyright and other proprietary notices contained in the Materials;
                2) you may not modify the Materials in any way or reproduce or publicly display, perform, or distribute or otherwise use them for any public or commercial purpose; and 3) you must not transfer
                the Materials to any other person unless you give them notice of, and they agree to accept, the obligations arising under these Terms of Service. You agree to abide by all additional restrictions
                displayed on the Site as it may be updated from time to time. By using the Site, you acknowledge and agree that all content, including but not limited to text, software, music, sound, photographs,
                graphics, video, or other material contained in advertisements or information presented to you on this Site is protected by our or other third parties' copyrights, trademarks, service marks,
                patents, or other proprietary rights and laws. You agree to comply with all copyright and other laws worldwide in your use of this Site and to prevent any unauthorized copying of the Materials.
                Except as expressly provided, MEMBER FIRST does not grant any express or implied right to you under any patents, trademarks, copyrights or trade secret information.</p>
                
                <h4 class="sub-heading">Registration</h4>
                <p>To register and become a "member" of the Site, you must be at least 18 years of age to enter into and form a legally binding contract subject to applicable state and federal law. In addition,
                you must be in good standing and not an individual that has been previously barred from receiving MEMBER FIRST's Services under the laws and statutes of the United States or other applicable jurisdiction.</p>
                <p>When you register, MEMBER FIRST may collect information such as your name, e-mail address, birth date, gender identity, mailing address, and other information provide by you. <i><u>You can edit your account information 
                at any time. Once you register with MEMBER FIRST and sign in to our Services, you are no longer anonymous to us.</u></i></p>
                <p>Furthermore, the registering party hereby acknowledges, understands and agrees to:</p>
                
                    <ol type="a">
                        <li>furnish factual, correct, current and complete information with regards to yourself as may be requested by the data registration process, and,</li>
                        <li>maintain and promptly update your registration and profile information in an effort to maintain accuracy and completeness at all times.</li>
                    </ol>
                
                <p>If anyone knowingly provides any information of a false, untrue, inaccurate or incomplete nature, MEMBER FIRST will have sufficient grounds and rights to suspend or terminate the member in violation of this aspect of the Agreement,
                and as such refuse any and all current or future use of MEMBER FIRST Services, or any portion thereof.</p>
                <p>It is MEMBER FIRST's priority to ensure the safety and privacy of all its visitors, users and members, especially that of children. Therefore, it is for this reason that the parents of any child under the age of 18 that permit
                their child or children access to the MEMBER FIRST website platform Services must create a "family" account, which will certify that the individual creating the "family" account is of 18 years of age and as such, the parent or legal
                guardian of any child or children registered under the "family" account. As the creator of the "family" account, s/he is thereby granting permission for his/her child or children to access the various Services provided, 
                including, but not limited to, message boards, email, and/or instant messaging. It is the parent's and/or legal guardian's responsibility to determine whether any of the Services and/or content provided are
                age-appropriate for his/her child.</p>
                <p>You will have an option to disable receiving electronic notification after Registration.  Messages will continue to be generated and stored on the site.  You agree that until you Terminate your account with MEMBER FIRST, MEMBER FIRST may
                periodically send a program update via electronic notifications.</p>
                
                <h4 class="sub-heading">Member Account, User Name, and Password Security</h4>
                <p>When you set up an account, you are the sole authorized user of your account. You shall be responsible for maintaining the secrecy and confidentiality of your password and for all activities that
                transpire on or within your account. It is your responsibility for any act or omission of any user(s) that access your account information that, if undertaken by you, would be deemed a violation of
                the Terms of Service. It shall be your responsibility to notify MEMBER FIRST immediately if you notice any unauthorized access or use of your account or password or any other breach of security. MEMBER FIRST shall
                not be held liable for any loss and/or damage arising from any failure to comply with this term and/or condition of the Terms of Service.</p>
                
                <h4 class="sub-heading">Third Party Sites</h4>
                <p></p>As a convenience to you, MEMBER FIRST may provide, on this Site, links to Web sites operated by other entities. If you use those other sites, you will leave this Site. If you decide to visit any linked site,
                you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements. MEMBER FIRST makes no warranty or representation regarding,
                and does not endorse, any linked sites or the information appearing there or any of the products or services described there. Links do not imply that MEMBER FIRST or this Site sponsors, endorses, is 
                affiliated or associated with, or is legally authorized to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any linked site is 
                authorized to use any trademark, trade name, logo or copyright symbol of MEMBER FIRST or any of its affiliates or subsidiaries.<p></p>
                
                <h4 class="sub-heading">Use of Website and General Storage Practices</h4>
                <p>You agree to use the Site only for lawful purposes. You agree not to use the Site for illegal purposes or for the transmission of material that is unlawful, harassing, libelous, 
                invasive of another's privacy, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable, or that infringes or may infringe the intellectual property or rights of another
                or that violates any international, federal, state, or local law.</p>
                <p>You herein acknowledge that MEMBER FIRST may set up any such practices and/or limits regarding the use of our Services, without limitation of the maximum number of days that any email, message 
                posting or any other uploaded content shall be retained by MEMBER FIRST, nor the maximum number of email messages that may be sent and/or received by any member, the maximum volume or size of any 
                email message that may be sent from or may be received by an account on our Service, the maximum disk space allowable that shall be allocated on MEMBER FIRST's servers on the member's behalf, and/or 
                the maximum number of times and/or duration that any member may access our Services in a given period of time.  In addition, you also agree that MEMBER FIRST has absolutely no responsibility or liability
                for the removal or failure to maintain storage of any messages and/or other communications or content maintained or transmitted by our Services. You also herein acknowledge that we reserve
                the right to delete or remove any account that is no longer active for an extended period of time. Furthermore, MEMBER FIRST shall reserve the right to modify, alter and/or update these general 
                practices and limits at our discretion.</p>
                <p>You agree that MEMBER FIRST may in its sole discretion terminate your access to our Site, without notice, if we believe you have in any way violated these Terms of Service.</p>
            
                <h4 class="sub-heading">Member Submissions</h4>
                <p>The personal information you submit to the Site is governed by the Site's Privacy Statement which is incorporated into these Terms of Service and forms a part of your Agreement with us. 
                When we use the term "personal information" we mean information such as your first/middle initial or name and last name, street address, town or city, state, ZIP code, telephone number, 
                email address, gender and any other information that would allow someone to identify you or contact you.</p>
                <p>You agree that you will not send, upload or transmit any communication, content or Material of any type to the email address listed on the "Contact Us" link or otherwise to MEMBER FIRST that infringes
                or violates any rights of any party or violates these Terms of Service. Any user submitting or otherwise exchanging communications, content or Material, including, without limitation, any 
                personal or commercial information, idea, concept or invention, irrevocably grants to MEMBER FIRST an unrestricted, worldwide, royalty free license to use reproduce, display publicly, perform, publish,
                transmit and distribute such Materials and you further agree that MEMBER FIRST is free to use any ideas, concepts or know-how that you or individuals acting on your behalf provide to MEMBER FIRST.</p>
            
                <h4 class="sub-heading">Electronic Communications</h4>
                <p>When you send emails to us, you are communicating with us electronically and consent to receive return communications, if any, from us electronically. You agree that all agreements, 
                notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                
                <h4 class="sub-heading">Health-Related Information</h4>
                <p>Any health information contained in the Site is provided for informational purposes only and is not meant to substitute for the advice provided by your doctor or other health care professional.</p>
                <p>You should not use the information available on or through the Site for diagnosing or treating a health problem or disease or prescribing any medication. Always consult with your doctor or other health
                care professional before starting any new diet, fitness routine, supplement regimen or other health-related program.  If you have or suspect that you have a medical problem, promptly contact your doctor
                or other health care professional.  If you think you may have a medical emergency, call 911 or your doctor (or other appropriate emergency phone number) immediately.</p> 
                
                <h4 class="sub-heading">Limitation of Warranty &amp; Disclaimer of Liability</h4>
                <p>The Materials on this Site may contain inaccuracies and typographical errors. MEMBER FIRST does not warrant the accuracy or completeness of the Materials or the reliability of any advice, opinion,
                statement or other information displayed or distributed through the Site. You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your
                sole risk. MEMBER FIRST reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Site. MEMBER FIRST may make any other changes to the Site, the Materials on the Site
                and the products, programs, services or prices (if any) described in the Site at any time without notice.  The use of this Site and its content is at your own risk and the Site and content are
                provided "as is." Transmissions over the Internet and communications networks are not in our control and can never be completely secure. Accordingly, we cannot and shall not be liable for any
                delay, failure, interruption, compromise or corruption of any data or other information transmitted in connection with use of the Site, including information you provide to us or our Site.</p>
                <p>To the fullest extent permitted by law, MEMBER FIRST and the site disclaim all warranties, express and implied, statutory and otherwise, including but not limited to the implied warranties of 
                merchantability, title, non-infringement, and fitness for particular purpose.</p>
                <p>In no event shall MEMBER FIRST, the site, or the site's licensors, suppliers and content providers be liable for any damages, including, without limitation, direct, indirect, incidental, consequential,
                special, exemplary and special damages or damages resulting from lost data or business interruption, regardless of the form of action or the basis of the claim, whether based on warranty, 
                contract, tort, strict liability or any other legal theory, and whether or not a party has been advised of the possibility of damages.</p>
                <p>If, for any reason, MEMBER FIRST or an affiliate or service provider shall be found to be liable, the aggregate liability to you or any other party or parties claiming with, under or through you, shall 
                be limited to ten dollars (U.S. $10.00), notwithstanding any claim that such remedy fails of its essential purpose. No claim or action arising from or concerning the Site, content or otherwise 
                hereunder may be brought later than one (1) year from the date the claim or cause of action arose.</p>
                <p>Some jurisdictions do not allow the disclaimer of certain types of damages or liability in whole or in part with respect to consumer agreements, and although the exclusions, limitations and 
                disclaimers in these Terms of Service shall always be construed to take full advantage of their meaning to the extent permitted by law, you should consult your own legal advisor should you wish 
                to determine the laws and regulations that apply to you.</p>
                
                <h4 class="sub-heading">Idemnity</h4>
                <p>All users and/or members herein agree to insure and hold MEMBER FIRST, Inc., our subsidiaries, affiliates, agents, employees, officers, partners and/or licensors blameless or not liable for any claim or 
                demand, which may include, but is not limited to, reasonable attorney fees made by any third party which may arise from any content a member or user of our site may submit, post, modify, transmit 
                or otherwise make available through our Services, the use of  MEMBER FIRST Services or your connection with these Services, your violations of the Terms of Service and/or your violation of any such rights of 
                another person.</p>
                
                <h4 class="sub-heading">Modifications</h4>
                <p>MEMBER FIRST reserves the right, at its sole discretion, to change, modify, add or remove any portion of these Terms of Service in whole or in part, at any time. Changes in these Terms of Service will be effective 
                when notice of such change is posted. Your continued use of the Site after any changes to those Terms of Service have been posted on-line will be deemed acceptance of such revised Terms of Service.</p>
                <p>We reserve the right to modify or discontinue, temporarily or permanently, any or all of the Site and/or any or all features, products, services or information appearing on or available through any or all							
                of the Site with or without notice to you.  You agree that we shall not be liable to you or any third-party for any modification or discontinuance of such features, products, services, information or the Site.
                MEMBER FIRST may terminate the authorization, rights and license given above and, upon such termination, you shall immediately destroy all Materials in your possession.  No waiver of any of these Conditions of Use shall
                be deemed a further or continuing waiver of such term or condition.</p>
                
                <h4 class="sub-heading">Termination</h4>
                <p>As a member of our site, you may cancel or terminate your account, associated email address and/or access to our Services by updating your member profile on our site.</p>
                <p>As a member, you agree that MEMBER FIRST, Inc. may, without any prior written notice, immediately suspend, terminate, discontinue and/or limit your account, any email associated with your account, and access to any 
                of our Services. The cause for such termination, discontinuance, suspension and/or limitation of access shall include, but is not limited to:</p>
                    
                <ol type="a">
                    <li>any breach or violation of our Terms of Service or any other incorporated agreement, regulation and/or guideline;</li>
                    <li>by way of requests from law enforcement or any other governmental agencies;</li>
                    <li>the discontinuance, alteration and/or material modification to our Services, or any part thereof;</li>
                    <li>unexpected technical or security issues and/or problems;</li>
                    <li>any extended periods of inactivity; and/or</li>
                    <li>any engagement by you in any fraudulent or illegal activities.</li>									
                </ol>
                    
                <p>Furthermore, you herein agree that any and all terminations, suspensions, discontinuances, and or limitations of access for cause shall be made at our sole discretion and that we shall not be liable to you
                or any other third party with regards to the termination of your account, associated email address and/or access to any of our Services.</p>
                <p>The termination of your account with our site shall include any and/or all of the following:</p>
                    
                <ol type="a">
                    <li>the removal of any access to all or part of the Services offered within our site;</li>
                    <li>the deletion of your password and any and all related information, files, and any such content that may be associated with or inside your account, or any part thereof; and</li>
                    <li>the barring of any further use of all or part of our Services.</li>
                </ol>		
                
                <h4 class="sub-heading">Advertisers</h4>
                <p>Any correspondence or business dealings with, or the participation in any promotions of, advertisers located on or through our Services, which may include the payment and/or delivery of
                such related goods and/or Services, and any such other term, condition, warranty and/or representation associated with such dealings, are and shall be solely between you and any such advertiser.
                Moreover, you herein agree that MEMBER FIRST, Inc. shall not be held responsible or liable for any loss or damage of any nature or manner incurred as a direct result of any such dealings or as a 
                result of the presence of such advertisers on our website.</p>
                
                <h4 class="sub-heading">Links</h4>
                <p>Either MEMBER FIRST or any third parties may provide links to other websites and/or resources. Thus, you acknowledge and agree that we are not responsible for the availability of any such external
                sites or resources, and as such, we do not endorse nor are we responsible or liable for any content, products, advertising or any other materials, on or available from such third party sites
                or resources. Furthermore, you acknowledge and agree that MEMBER FIRST shall not be responsible or liable, directly or indirectly, for any such damage or loss which may be a result of, caused or
                allegedly to be caused by or in connection with the use of or the reliance on any such content, goods or services made available on or through any such site or resource.</p>
                
                <h4 class="sub-heading">Release</h4>
                <p>In the event you have a dispute, you agree to release MEMBER FIRST (and its officers, directors, employees, agents, parent subsidiaries, affiliates, co-branders, partners and any other third parties) 
                from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected or unsuspected, disclosed and undisclosed, arising out of or in any way connected to 
                such dispute.</p>
                
                <h4 class="sub-heading">Notice</h4>
                <p>MEMBER FIRST may furnish you with notices, including those with regards to any changes to the Terms of Service, including but not limited to email, regular mail, MMS or SMS, text messaging, postings
                on our website Services, or other reasonable means currently known or any which may be herein after developed. Any such notices may not be received if you violate any aspects of the Terms of Service 
                by accessing our Services in an unauthorized manner. Your acceptance of this Terms of Service constitutes your agreement that you are deemed to have received any and all notices that would have been 
                delivered had you accessed our Services in an authorized manner.</p>
                
                <h4 class="sub-heading">Contacting Member First</h4>
                <p>If you have any questions about the content of these Terms of Service or about the practices of this Site or your dealings with this Site, please contact the MEMBER FIRST Privacy Office at the following address:</p>
                    
                    <ul class="list-unstyled mg-b-list pd-0">
                        <li>Member First</li>
                        <li>Attn: Privacy Officer</li>
                        <li>13809 Research Blvd., Suite 500<br>  Austin, TX 78750</li>
                    </ul>
                <p><b>Email:</b> <a href="mailto:compliance@memberfirstpharmacy.com">compliance@memberfirstpharmacy.com</a></p> <!-- Neeed to update with actual email address -->
                
                <p>If you have any questions about the practices of this Site or your dealings with this Site, please contact:</p>
                
                    <ul class="list-unstyled mg-b-list pd-0" >
                        <li>Member First</li>
                        <li>Attn: Member Services</li>
                        <li>13809 Research Blvd., Suite 500<br>  Austin, TX 78750</li>
                    </ul>
                <p><b>Email:</b> <a href="mailto:memberservices@memberfirstpharmacy.com">memberservices@memberfirstpharmacy.com</a></p> <!-- Neeed to update with actual email address -->
                    
                
                <h3><b>General Information</b></h3>
                <h4 class="sub-heading">Entire Agreement</h4>
                <p>This Terms of Service constitutes the entire agreement between you and MEMBER FIRST and shall govern the use of our Services, superseding any prior version of this Terms of Service between you and us with
                respect to MEMBER FIRST services. You may also be subject to additional terms and conditions that may apply when you use or purchase certain other MEMBER FIRST services, affiliate services, third-party content
                or third-party software.</p>
                
                <h4 class="sub-heading">Choice of Law and International Users</h4>
                <p>This Site is controlled, operated and administered by MEMBER FIRST from its offices within the United States of America. MEMBER FIRST makes no representation that Materials at this Site are appropriate or available for use
                at other locations outside of the United States and access to them from territories where their content is illegal or restricted is prohibited. You may not use the Site or export the Materials in violation of 
                U. S. export laws and regulations. If you access this Site from a location outside of the United States, you are responsible for compliance with all local laws. These Terms of Service shall be governed by the 
                laws of the United States and of the State of Colorado, without giving effect to its conflict of laws provisions.</p>
                <p>Your Agreement to these Terms of Service constitutes the entire agreement between MEMBER FIRST and you with respect to your use of the Site. If for any reason a court of competent jurisdiction finds any provision of 
                the Terms of Service which form the basis of our Agreement or any portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to affect the intent of the Agreement,
                and the remainder of this Agreement shall continue in full force and effect.</p>
                
                <h4 class="sub-heading">No Right of Survivorship Non-Transferability</h4>
                <p>You acknowledge, understand and agree that your account is non-transferable and any rights to your ID and/or contents within your account shall terminate upon your death. Upon receipt of a copy of a death certificate,
                your account may be terminated and all contents therein permanently deleted.</p>	

                <div class="col-md-8">
                    <h6><v-icon left size="14"> mdi-file </v-icon>70008 r1.4</h6>
                </div>
            </v-col>
        </div>
          
    </v-container>
  </template>
  <script>
  export default {
    name: "TermsService",
    components: {
    },
    data() {
    return {
        isShow:true,
    };
  },
  computed: {
  },
  methods: {
    print()    
    { 
       this.isShow=false
       setTimeout(()=>{
            window.print();
            this.isShow=true;
      },50)
    }
  },
  mounted () {
  }
  };
  </script>
  <style>
     .v-application ol {
        padding-left: 40px;
     }
    .pd-0{
        padding: 0px !important;
    }
    .v-application a {
        color:blue;
    }
   .content{
      font-style: oblique;
      line-height: 25px;
      font-size: 16px;
      color: #696969;
    }
    
  
  .theme--light.v-btn.v-btn--has-bg {
    background-color: #ffffff;
  }
  .v-btn--is-elevated {
    box-shadow: 0px 0px 0px 0px;
  }
  .container {
    padding: 0px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 1px 4px;
  }
  .text-center-xs {
    text-align: center !important;
    float: none !important;
  }
</style>
  