<template>
  <v-container fluid class="pd-container">
    <v-row v-show="this.memberDetail === null && this.selectisReview === null || this.selectisReview === false" class="mt-0">
      <v-col cols="6" sm="6" md="4" lg="3">
        <v-text-field
          v-model="memberIdInput"
          @keyup.enter="loadMemberById"
          label="Enter Member Id"
          variant="outlined"
          density="comfortable"
          append-inner-icon="mdi-magnify small"
          hide-details="auto"
          @click:append-inner="loadMemberById()"
          style="font-weight:bold;"
        >
          <!-- <template v-slot:append>
            <v-btn
              class="ma-2"
              small
              icon
              @click="loadMemberById()"
            >
              <v-icon>fa-magnifying-glass</v-icon>
            </v-btn>
          </template> -->
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="this.memberDetail != null"
      style="align-items: center; margin: 0px; height: 120px; border-bottom: 1px solid #333333; box-shadow: 5px 0px 5px rgba(51,51,51,0.5);"
      :class="{'review-bg-color': selectisReview, 'bg-white-color': this.selectisReview === null || this.selectisReview === false}">
      <v-col cols="2" md="1" style="margin-bottom: 3rem;">
        <v-btn icon x-large
          @click="toggleMemberDetails">
          <v-icon size="44" v-if="this.expandMemberDetails">mdi-chevron-down</v-icon>
          <v-icon size="44" v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10" md="4" style="margin-bottom: 3rem; margin-top:10px">
        <v-row class="text-h5 font-weight-bold">
          <span style="font-size:0.9em;" class="mr-2">Member:</span>
          <span v-if="!this.privacyMode"> 
            {{ memberDetail.member.firstName }}
            {{
              memberDetail.member.middleName != null &&
              memberDetail.member.middleName.length > 0
                ? memberDetail.member.middleName.substring(0, 1) + ". "
                : ""
            }}{{ memberDetail.member.lastName }}</span> 
          <span v-if="this.privacyMode">******** ********</span>
          <v-dialog v-model="showEditMember" width="1000" v-if="this.selectisReview === null || this.selectisReview === false">
            <template v-slot:activator="{ props }">
              <div color="info" class="ml-4 mouse-cursor"
                v-bind="props"
                @click="setEditableMemberDetails">
                <v-icon  size="25" color="blue">mdi-pencil</v-icon>
            </div>
            </template>
            <v-card>
              <v-card-title>Edit Member Details</v-card-title>
              <v-card-text>
                <div class="mg-bottom-20 fs-16">Member Details</div>
                <v-row>
                <v-col cols="5">
                  <v-row class="member-border">
                  <v-col cols="12">
                  <div class="pa-2">
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.member.firstName"
                      label="First Name"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-4"
                      hide-details="auto"
                    ></v-text-field>
                  </div>
                  <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.member.middleName"
                      label="Middle Name"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.member.lastName"
                      label="Last Name"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.member.nameSuffix"
                      label="Suffix"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.memberAddresses[0].streetAddress"
                      label="Street Address"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.memberAddresses[0].streetAddress2"
                      label="Street Address 2"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.memberAddresses[0].city"
                      label="City"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.memberAddresses[0].state"
                      label="State"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <div class="pd-5">
                      <v-row>
                      <v-col cols="7">
                      <v-text-field
                        v-model="editableMember.memberPhones[0].phoneNumber"
                        label="Phone"
                        variant="outlined"
                      density="comfortable"
                        dense
                        hide-details="auto"
                      ></v-text-field>
                      </v-col>
                      <v-col>
                          <v-row class="flex-nowrap mg-top-10">
                          <v-switch
                            color="blue"
                            :disabled="editableMember.memberPhones[0].phoneNumber === null || editableMember.memberPhones[0].phoneNumber === ''"
                            v-model="editableMember.memberPhones2.isPhoneNo"
                            class="privacyToggle mg-top-5 pd-unset">
                          </v-switch>
                          <div class="mg-top-15 fs-14 blue-color">{{ "Mobile Phone" }}</div>
                        </v-row>
                      </v-col>
                      </v-row>
                    </div>
                    <div class="pd-5">
                    <v-text-field
                      v-model="editableMember.memberAddresses[0].zipCode"
                      label="Zip"
                      variant="outlined"
                      density="comfortable"
                      dense
                      class="mb-2"
                      hide-details="auto"
                    ></v-text-field>
                    </div>
                    <v-row>
                      <v-col cols="6">
                      <div class="pd-5">
                        <v-select
                          :items="memberGenderList"
                          v-model="editableMember.member.gender"
                          item-value="value"
                          item-title="title"
                          label="Gender"
                          variant="outlined"
                          density="comfortable"
                          dense
                          class="mb-2"
                          hide-details="auto"
                        ></v-select>
                      </div>
                      </v-col>
                      <v-col cols="6">
                      <div class="pd-5">
                        <v-select
                          :items="maritalStatusList"
                          v-model="editableMember.member.maritalStatus"
                          label="Marital Status"
                          item-value="value"
                          item-title="title"
                          variant="outlined"
                          density="comfortable"
                          dense
                          class="mb-2"
                          hide-details="auto"
                        ></v-select>
                      </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                </v-row>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="6">
                  <div class="mg-bottom-10 fs-16">Edit Care Giver Contact Methods</div>
                  <fieldset class="pa-2 member-border">
                    <v-row class="pd-5">
                      <v-col cols="8">
                        <v-text-field
                          v-model="editableMember.memberPhones2.careGiverName"
                          label="Care Giver's Name"
                          variant="outlined"
                          density="comfortable"
                          dense
                          hide-details="auto">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pd-5">
                      <v-col cols="8">
                        <v-text-field
                          v-model="editableMember.memberPhones2.relationshipPatient"
                          label="Relationship to patient"
                          variant="outlined"
                          density="comfortable"
                          dense
                          hide-details="auto">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pd-5">
                      <v-col cols="8">
                          <v-text-field
                            v-model="editableMember.memberPhones2.careGiverContact"
                            label="Care Giver Contact Info"
                            variant="outlined"
                            density="comfortable"
                            :disabled="!isEditCareGivenPhone"
                            dense
                            hide-details="auto"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-row class="flex-nowrap mg-top-10">
                          <v-switch
                            color="blue"
                            :disabled="editableMember.memberPhones2.careGiverContact === null || editableMember.memberPhones2.careGiverContact === ''"
                            v-model="editableMember.memberPhones2.isCareGivenPhone"
                            class="privacyToggle mg-top-5 pd-unset">
                          </v-switch>
                          <div class="mg-top-15 blue-color fs-14">{{ "Mobile Phone" }}</div>
                        </v-row>
                      </v-col>
                      <div class="blue-color fs-14 mg-left-23 mouse-cursor" @click="handlePhoneOrEmailEdit('caregivenphone')">{{ "UPDATE CARE GIVER CONTACT INFO" }}</div>
                    </v-row>
                    <v-row class="pd-5">
                      <v-col cols="8">
                        <v-text-field
                          v-model="editableMember.memberEmails[0].emailAddress"
                          label="Email"
                          variant="outlined"
                          density="comfortable"
                          :disabled="!isEditEmail"
                          dense
                          hide-details="auto">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                      </v-col>
                      <div class="blue-color fs-14 mg-left-23 mouse-cursor" @click="handlePhoneOrEmailEdit('email')">{{ "UPDATE EMAIL" }}</div>
                  </v-row>
                  </fieldset>
                  <div class="mt-10 fs-16 mg-bottom-10">Edit Consent / Notifications</div>
                  <fieldset class="member-border pa-2">
                    <v-switch
                      v-model="editableMember.member.vp_consent"
                      color="blue"
                      label="Member has given verbal consent to PARTICIPATE IN THIS PROGRAM."
                      class="privacyToggle"
                    ></v-switch>
                    <v-row v-show="editableMember.member.vp_consent_user != null">
                      <v-col cols="6"><div class="mg-left-3rem fs-12 gray-primary">Updated at: {{showLocalDate(editableMember.member.vp_consent_time)}}</div></v-col>
                      <v-col cols="6"><div class="fs-12 gray-primary">Updated by: {{editableMember.member.vp_consent_user}}</div></v-col>
                    </v-row>
                    <hr/>
                    <v-switch
                      v-model="editableMember.member.email_consent"
                      label="Member has given verbal consent to RECEIVE EMAILS regarding their participation in this program."
                      class="privacyToggle"
                      color="blue"
                    ></v-switch>
                    <v-row v-show="editableMember.member.email_consent_user != null">
                      <v-col cols="6"><div class="mg-left-3rem fs-12 gray-primary">Updated at: {{showLocalDate(editableMember.member.email_consent_time)}}</div></v-col>
                      <v-col cols="6"><div class="fs-12 gray-primary">Updated by: {{editableMember.member.email_consent_user}}</div></v-col>
                    </v-row>
                    <hr/>
                    <v-switch
                      v-model="editableMember.member.phone_consent"
                      label="Member has given verbal consent to RECEIVE TEXT MESSAGES regarding their participation in this program."
                      class="privacyToggle"
                      color="blue"
                    ></v-switch>
                    <v-row v-show="editableMember.member.phone_consent_user != null">
                      <v-col cols="6"><div class="mg-left-3rem fs-12 gray-primary">Updated at: {{showLocalDate(editableMember.member.phone_consent_time)}}</div></v-col>
                      <v-col cols="6"><div class="fs-12 gray-primary">Updated by: {{editableMember.member.phone_consent_user}}</div></v-col>
                    </v-row>
                  </fieldset>
                </v-col>
              </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small variant="text" color="error" class="ma-2" @click="showEditMember = false">
                  Cancel Changes
                </v-btn>
                <v-btn  color="blue white--text" small @click="saveMember" class="ma-2">
                  Save Member Details
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="this.isDeleteNoteDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline">
                Confirm Delete
              </v-card-title>
              <v-card-text>{{ "Are you sure you wish to delete this note?" }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  :disabled="disableDeleteBtn"
                  @click="deleteMemberDetailsNote(true)"
                >
                  Delete
                </v-btn>
                <v-btn
                  color="darken-1"
                  text
                  @click="deleteMemberDetailsNote(false)"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showChangeMember" width="400">
            <template v-slot:activator="{ props }">
                <v-icon color="blue" v-bind="props"
                v-if="this.selectisReview === null"
                size="30"
                class="mg-left-20 mg-top-5"
                @click="showChangeMember = true">mdi-magnify</v-icon>
                <v-icon v-else color="black"
                size="30"
                class="mg-left-20 mg-top-5">mdi-magnify</v-icon>
            </template>
            <v-card>
              <v-card-title>Change Member</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="memberIdInput"
                  @keyup.enter="loadMemberById"
                  label="Enter Member Id"
                  variant="outlined"
                  density="comfortable"
                  dense
                  hide-details="auto"
                  append-inner-icon="mdi-magnify large"
                  @click:append-inner="loadMemberById()"
                  style="font-weight:bold;font-size:1.2em;"
                >
                  <!-- <template v-slot:append>
                    <v-btn
                      class="ma-2"
                      small
                      icon
                      color="blue"
                      @click="loadMemberById()"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template> -->
                </v-text-field>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row class="text-center" style="white-space:nowrap;">
          <v-col cols="3">Age<br/>{{ showAge(memberDetail.member.dateOfBirth) }}</v-col>
          <v-col cols="3">Gender<br/>{{ showGender(memberDetail.member.gender) }}</v-col>
          <v-col cols="3">Member&nbsp;ID<br/>{{ memberDetail.member.memberId }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="8" md="5" style="margin-bottom: 3rem;">
        <v-row style="align-items: center;">
          <v-col cols="2" class="font-weight-bold text-right">
            Clinician Team
          </v-col>
          <v-col cols="4">
            <v-select
            :items="clinicianTeamItems"
            v-model="clinicianTeamModel"
            :disabled="clinicianTeamDisabled"
            variant="solo"
            density="comfortable"
            hide-details="auto"
            dense
            class="clientSelect"
            @update:modelValue="selectClinicianTeam"
          ></v-select>
          </v-col>
          <v-col cols="2" class="font-weight-bold text-right">
            Member Status
          </v-col>
          <v-col cols="4">
            <v-select
              :items="memberStatusItemsFiltered"
              v-model="memberStatusModel"
              variant="solo"
              item-title="text"
              hide-details="auto"
              density="comfortable"
              dense
              class="clientSelect"
              @update:modelValue="selectMemberStatus"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" md="1" class="text-center" style="margin-bottom: 3rem;" >
        <v-icon size="60"
          :color="profileIconColor">
          {{ profileIcon }}
        </v-icon>
      </v-col>
      <v-col cols="2" md="1" class="text-center">
        <v-dialog v-model="showNotes" width="800">
          <template v-slot:activator="{ props }">
            <v-btn small variant="text" v-if="selectisReview" class="ma-1"
              v-bind="props"
              style="margin-bottom: -1rem!important;"
              >
              <span class="fs-12">Notes</span>
            </v-btn>
            <v-btn small variant="text" v-else color="info" class="ma-1"
              v-bind="props"
              style="margin-bottom: -1rem!important;">
              <span class="fs-12">Notes</span>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              Notes
              <v-spacer></v-spacer>
              <v-btn
                size="x-small"
                style="float:right"
                v-on:click="toggleAddNote"
                :class="noteBtnClass"
              >
                {{ addNoteBtnLabel }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              
              <div v-if="!showAddNote" class="notesDiv mt-2 mb-1">
                <v-card
                  v-for="note in memberDetail.notes"
                  :key="note.userNoteId"
                  class="mb-2 note pa-2"
                  elevation="1"
                >
                  <v-tooltip
                    bottom
                    v-if="
                      appUser.user.userId == note.userId ||
                        appUser.admin == true
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        small
                        style="float:right;"
                        v-bind="props"
                        v-on:click="memberDetailsDeleteNote(note)"
                        >mdi-close</v-icon
                      >
                    </template>
                    <span>Delete</span>
                  </v-tooltip>

                  <v-tooltip
                    bottom
                    v-if="
                      appUser.user.userId == note.userId ||
                        appUser.admin == true
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        small
                        color="blue"
                        style="float:right;"
                        v-bind="props"
                        v-on:click="showEditNote(note)"
                        >mdi-pencil-outline</v-icon
                      >
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  {{ note.note }}

                  <div class="noteDetail">
                    {{ note.username }} -
                    {{
                      new Date(note.dateCreated + "Z").toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric"
                        }
                      )
                    }}
                  </div>
                </v-card>
              </div>

              <div v-if="showAddNote" class="mt-2 mb-1">
                <v-textarea
                  v-model="newNote.note"
                  :label="addNoteTitle"
                  variant="outlined"
                  density="comfortable"
                  clearable
                  auto-grow
                  rows="2"
                  hide-details="auto"
                  class="mt-3"
                  background-color="#fff"
                ></v-textarea>
                <v-btn
                  color="primary"
                  v-on:click="addNote"
                  class="ma-2"
                  :disabled="addNoteBtnDisabled"
                >
                  Save Note
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small variant="outlined"
                density="comfortable" class="ma-2" @click="showNotes = false">
                Exit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showFiles" width="800">
          <template v-slot:activator="{ props }">
            <v-btn small variant="text" class="ma-1"
              style="margin-bottom: -1rem!important;"
              v-bind="props"
              v-if="selectisReview">
              Files
            </v-btn>
            <v-btn small variant="text" color="info" class="ma-1"
              style="margin-bottom: -1rem!important;"
              v-bind="props"
              v-else>
              Files
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="fs-12">Files</span>
              <v-spacer></v-spacer>
              <v-btn
                size="x-small"
                style="float:right"
                v-on:click="toggleAddFile"
                :class="fileBtnClass"
              >
                {{ addFileBtnLabel }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-dialog v-model="deleteDialog" persistent max-width="400">
                <v-card>
                  <v-card-title class="headline">
                    Confirm Delete
                  </v-card-title>
                  <v-card-text>{{ deleteDialogText }}</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary darken-1"
                      text
                      :disabled="disableDeleteBtn"
                      @click="confirmDeleteFile(true)"
                    >
                      Delete
                    </v-btn>
                    <v-btn
                      color="darken-1"
                      text
                      @click="confirmDeleteFile(false)"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="renameDialog" persistent max-width="420">
                <v-card>
                  <v-card-title class="headline">
                    Rename File
                  </v-card-title>
                  <v-card-text>
                    <span class="fs-15 mg-left-10">Enter new filename for {{ this.selectedFileName }}</span>

                    <v-text-field
                      v-model="newFileName"
                      label="New Filename"
                      variant="outlined"
                      density="comfortable"
                      dense
                      hide-details="auto"
                      class="mt-3"
                      background-color="#fff"
                      :suffix="renameSuffix"
                      v-on:keyup.enter="confirmRenameFile(true)"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      :disabled="disableRenameBtn"
                      @click="confirmRenameFile(true)"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      color="darken-1"
                      text
                      @click="confirmRenameFile(false)"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div v-if="!showAddFile" class="notesDiv mt-2 mb-1">
                <v-table>
                    <thead>
                      <tr>
                        <th style="min-width:74px;">&nbsp;</th>
                        <th>File Name</th>
                        <th>Size</th>
                        <th>Date</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in memberFiles" :key="item.fileId">
                        <td>
                          <v-tooltip
                            bottom
                            v-if="
                              appUser.admin != null ||
                                item.appUserOwnerId == appUser.user.userId
                            "
                          >
                            <template v-slot:activator="{ props }">
                              <v-icon
                                small
                                style=""
                                v-bind="props"
                                v-on:click="
                                  deleteFileClick(
                                    item.fileId,
                                    item.fileName
                                  )
                                "
                                >mdi-trash-can-outline</v-icon
                              >
                            </template>
                            <span>Delete</span>
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            v-if="
                              appUser.admin != null ||
                                item.appUserOwnerId == appUser.user.userId
                            "
                          >
                            <template v-slot:activator="{ props }">
                              <v-icon
                                small
                                class="ml-2"
                                v-bind="props"
                                v-on:click="
                                  renameFileClick(
                                    item.fileId,
                                    item.fileName
                                  )
                                "
                                >mdi-lead-pencil</v-icon
                              >
                            </template>
                            <span>Rename</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ props }">
                              <a
                                v-bind="props"
                                v-on:click="downloadFile(item.fileId)"
                                >{{ item.fileName }}</a
                              >
                            </template>
                            <span>Download</span>
                          </v-tooltip>
                        </td>
                        <td class="nowrap">
                          {{ showFilesize(item.fileSize) }}
                        </td>
                        <td class="nowrap">
                          {{ showLocalDate(item.assignedOn) }}
                        </td>
                        <td>
                          {{ item.appUserOwnerId == 0 ? "system" : "user" }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          memberFiles == null || memberFiles.length == 0
                        "
                      >
                        <td colspan="5"><i>no files</i></td>
                      </tr>
                    </tbody>
              </v-table>
              </div>

              <div v-if="showAddFile" class="mt-2 mb-1">
                <v-file-input
                  ref="addFileInput"
                  v-model="addFileObj"
                  label="Select File up to 4 MB"
                  variant="outlined"
                  density="comfortable"
                  dense
                  hide-details="auto"
                  class="mt-3"
                  background-color="#fff"
                ></v-file-input>
                <v-btn
                  color="primary"
                  v-on:click="addFile"
                  class="ma-2"
                  :disabled="addFileBtnDisabled"
                >
                  Upload File
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small variant="outlined"
                density="comfortable" class="ma-2" @click="showFiles = false">
                Exit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showMTM" v-if="this.selectisReview === null || this.selectisReview === false">
          <template v-slot:activator="{ props }">
            <v-btn small variant="text" color="info" class="ma-1"
              style="margin-bottom: -1rem!important;"
              v-bind="props"
              @click="openMTM"
              >
              <span class="fs-12">MTM</span>
            </v-btn>
          </template>
          <v-card  min-height="300">
            <v-card-title></v-card-title>
            <v-card-text>
              <SideNavBar ref="MTMevent"></SideNavBar>
            </v-card-text>
            <v-card-actions> 
              <v-spacer></v-spacer>
              <v-btn small variant="outlined"
                density="comfortable" class="m-2" @click="exitMTM">
                Exit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          style="margin-bottom: 2rem;"
          :color="selectisReview === null ? 'blue': 'black'"
          variant="text"
          @click="handleIntervention()">
          <span class="fs-12 mg-right-5">Interventions</span>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="this.selectisReview && this.memberDetail != null">
      <SideNavBar/> 
    </div>
    <div v-else>
      <v-expand-transition>
        <v-row v-if="expandMemberDetails == true" style="margin-top: -1px; margin-left: 0px;" class="gradA memberSummary">
          <v-col cols="12" sm="6" md="3">
            <table>
              <tr>
                <td class="label">Marital Status:</td>
                <td>{{ memberDetail.member.maritalStatus }}</td>
              </tr>
              <tr>
                <td class="label">Address:</td>
                <td v-if="!privacyMode">
                  {{
                    memberDetail.memberAddresses != null &&
                    memberDetail.memberAddresses.length > 0
                      ? memberDetail.memberAddresses[0].streetAddress
                      : ""
                  }}<br />
                  <span
                    v-if="
                      memberDetail.memberAddresses != null &&
                        memberDetail.memberAddresses.length > 0 &&
                        memberDetail.memberAddresses[0].streetAddress2
                    "
                    >{{ memberDetail.memberAddresses[0].streetAddress2 }}<br
                  /></span>
                  {{
                    memberDetail.memberAddresses != null &&
                    memberDetail.memberAddresses.length > 0
                      ? memberDetail.memberAddresses[0].city +
                        ", " +
                        memberDetail.memberAddresses[0].state +
                        " " +
                        memberDetail.memberAddresses[0].zipCode
                      : ""
                  }}
                </td>

                <td v-if="privacyMode">
                  ******
                </td>
              </tr>
              <tr>
                <td class="label">Phone:</td>
                <td v-if="!privacyMode">
                  {{
                    memberDetail.memberPhones != null &&
                    memberDetail.memberPhones.length > 0
                      ? memberDetail.memberPhones[0].phoneNumber
                      : ""
                  }}
                </td>
                <td v-if="privacyMode">******</td>
              </tr>
              <tr>
                <td class="label">Email:</td>
                <td v-if="!privacyMode">
                  {{
                    memberDetail.memberEmails != null &&
                    memberDetail.memberEmails.length > 0
                      ? memberDetail.memberEmails[0].emailAddress
                      : ""
                  }}
                </td>
                <td v-if="privacyMode">******</td>
              </tr>

              <tr>
                <td class="label">Group Id:</td>
                <td><span v-html="memberGroupId"></span></td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <span class="label">Care Giver Contact Info:</span>
            <table>
                <tr
                  class="mb-4 fmember"
                >
                  <td colspan="2" class="pl-6">
                    <table>
                      <tr>
                        <td class="label">Contact Info:</td>
                        <td colspan="2" v-if="memberDetail.memberPhones2 !== null && memberDetail.memberPhones2.careGiverContact !== null">
                        {{ memberDetail.memberPhones2.careGiverContact }}
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Care Giver Name:</td>
                        <td colspan="2" v-if="memberDetail.memberPhones2 !== null && memberDetail.memberPhones2.careGiverName !== null">
                        {{ memberDetail.memberPhones2.careGiverName }}
                        </td>
                      </tr>
                      <tr>
                        <td class="label">relationship Patient:</td>
                        <td colspan="2" v-if="memberDetail.memberPhones2 !== null && memberDetail.memberPhones2.relationshipPatient !== null">
                        {{ memberDetail.memberPhones2.relationshipPatient }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="memberDetails">
            <table>
              <tr>
                <td class="label">Unique Id:</td>
                <td>{{ uniqueId }}</td>
              </tr>
              <tr>
                <td class="label">DOB:</td>
                <td v-if="!privacyMode">
                  {{ memberDetail.member.dateOfBirth }}
                </td>
                <td v-if="privacyMode">
                  {{ showAge(memberDetail.member.dateOfBirth) }}
                </td>
              </tr>
              <tr>
                <td class="label">Invitation Code:</td>
                <td>
                  <v-tooltip
                    bottom
                    v-if="
                      memberDetail.memberRegistration != null &&
                        memberDetail.memberRegistration.dateInvited != null
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <a
                                                v-bind="props"
                        v-on:click="
                          downloadFile(
                            memberDetail.memberRegistration.invitationFileId
                          )
                        "
                      >
                        {{ memberDetail.memberRegistration.invitationCode }}
                      </a>
                    </template>
                    <span>Download File</span>
                  </v-tooltip>
                  <span
                    v-if="
                      memberDetail.memberRegistration != null &&
                        memberDetail.memberRegistration.dateInvited == null
                    "
                  >
                    {{ memberDetail.memberRegistration.invitationCode }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="label">Total Rx:</td>
                <td>$ {{ totalRxCost.toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="label">Total Medical:</td>
                <td>$ {{ totalMedCost.toFixed(2) }}</td>
              </tr>
              <tr>
                <td class="label">Consent:</td>
                <td>
                  
                  <v-icon
                    :color="memberDetail.member.vp_consent == true ? '#43a047' : '#8ea6be'"
                    class="ml-2"
                    size="24"
                    >{{ memberDetail.member.vp_consent == true ? 'fa-solid' : 'fa-regular'}} mdi mdi-thumb-up-outline
                  </v-icon>
                  <v-icon
                    :color="memberDetail.member.email_consent == true ? '#e53935' : '#8ea6be'"
                    class="ml-2"
                    size="24"
                    >{{ memberDetail.member.email_consent == true ? 'fa-solid' : 'fa-regular'}} mdi mdi-email-outline
                  </v-icon>
                  <v-icon
                    :color="memberDetail.member.phone_consent == true ? '#43a047' : '#8ea6be'"
                    class="ml-2"
                    size="24"
                    >mdi mdi-message-alert-outline
                  </v-icon>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" md="3">
            <span class="label">Other Family Members:</span>
            <div class="familyDiv" v-if="memberDetail.familyMembers != null && memberDetail.familyMembers.length > 0">
              <table>
                <tr
                  v-for="fMember in memberDetail.familyMembers"
                  :key="fMember.memberId"
                  class="mb-4 fmember"
                >
                  <td colspan="2" class="pl-6">
                    <table>
                      <tr>
                        <td colspan="2" v-if="!privacyMode">
                          {{ fMember.firstName }}
                          {{
                            fMember.middleName != null &&
                            fMember.middleName.length > 0
                              ? fMember.middleName.substring(0, 1) + "."
                              : ""
                          }}
                          {{ fMember.lastName }}
                        </td>
                        <td colspan="2" v-if="privacyMode">******</td>
                      </tr>
                      <tr>
                        <td class="label">DOB:</td>
                        <td v-show="!privacyMode">
                          {{ fMember.dateOfBirth }}
                        </td>
                        <td v-show="privacyMode">
                          <span style="font-size:0.7em">
                            {{ showAge(fMember.dateOfBirth) }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="label">Member Id:</td>
                        <td>
                          <span
                            @click="goToMemberView(fMember.memberId)"
                            class="pointer"
                            ><a>{{ fMember.memberId }}</a>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-row v-if="memberDetail != null" class="mt-2">
      <v-col cols="12">
        <v-tabs v-model="memberTab" show-arrows>
          <v-tab
            v-for="tab in memberTabHeaders"
            v-bind:key="tab.key"
            max-width="250"
            class="memberDetailsTab"
          >
            <!-- {{
              tab.title.length > 40 ? tab.title.substring(0, 40) + "..." : tab.title
            }} -->
            {{
              tab.title && tab.title.length > 40 ? tab.title.substring(0, 40) + "..." : tab.title
            }}
          </v-tab>
          </v-tabs> 
          <v-window v-model="memberTab">
          <v-window-item value="Summary" class="mt-1">
            <v-row class="gradA">
              <v-col cols="12" md="6" lg="4">
                  <div class="ma-1">All Qualifying Criteria</div>
                  <v-data-table
                    :items="aqcItems"
                    :headers="aqcHeaders"
                    class="ma-1"
                    multi-sort
                    item-class="nowrap"
                  >
                    <template v-slot:item.dateIdentified="{ item }">
                      <span style="white-space: nowrap">{{item.dateIdentified}}</span>
                    </template>
                    <template v-slot:no-data>
                      <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                        <span>No data available.</span>
                      </div>
                    </template>
                  </v-data-table>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <div class="ma-1">All Drugs
                  <v-checkbox
                    v-model="maintFilter"
                    label="Maintenance Drugs Only"
                    dense
                    hide-details="true"
                    style="margin: -14px 2px; float:right;"
                    class=" mg-bottom-20 maintFilter"
                  ></v-checkbox>
                </div>
                <v-data-table
                  :items="adItems"
                  :headers="adHeaders"
                  class="ma-1"
                  :sort-by="[{ key: 'recentFillDate', order: 'asc' }]"
                  sort-desc
                >
                  <template v-slot:item.drugName="{ item }">
                    <span v-on:click="addDrugTab(item)" class="mouse-cursor">{{ item.drugName }}</span>
                  </template>
                  <template v-slot:item.startDate="{ item }">
                    <span style="white-space: nowrap">{{ item.startDate }}</span>
                  </template>
                  <template v-slot:item.recentFillDate="{ item }">
                    <span style="white-space: nowrap">{{
                      item.recentFillDate
                    }}</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                      <span>No data available.</span>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <div class="ma-1">Diagnosis Codes</div>
                <v-data-table
                  :items="dcItems"
                  :headers="dcHeaders"
                  class="ma-1"
                  key="code"
                  :sort-by="[{ key: 'code', order: 'asc' }]"
                >
                  <template v-slot:item.dateRecorded="{ item }">
                    <span style="white-space: nowrap">{{
                      item.dateRecorded
                    }}</span>
                  </template>
                  <template v-slot:no-data>
                    <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                      <span>No data available.</span>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-window-item>
         
          <v-window-item value="allRxClaims" class="mt-1">
            <RxClaims filter="" @drugNameClicked="drugNameClicked" />
          </v-window-item>

          <v-window-item value="allMedClaims" class="mt-1">
            <MedicalClaims filter="" />
          </v-window-item>

          <v-window-item value="drugTab" class="mt-1" v-if="selectedDrug != null" >
            <RxClaims :filter="selectedDrug.genericID" />
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
    </div>

  </v-container>
</template>
<style>
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.4) !important;
}
.gradA {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(244, 244, 244, 1) 0%,
    rgba(250, 250, 250, 1) 100%
  );
}
.label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8em;
  font-variant: small-caps;
}
div.maintFilter {
  margin: 0;
}
div.maintFilter label.v-label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12.8px;
  font-variant: small-caps;
  color: rgba(0, 0, 0, 0.6) !important;
}
.noteDetail {
  font-size: 0.9em;
  color: #aaa;
  display: block;
  text-align: right;
}
.memberSummary td.label {
  width: 120px;
}
.memberSummary .note {
  font-size: 0.9em;
}
.notesDiv {
  max-height: 400px;
  overflow: auto;
}
.familyDiv {
  max-height: 146px;
  overflow: auto;
}
.memberDetails table {
  width: 100%;
}
.fmember > td {
  border-bottom: #ddd solid 1px;
}
/*
.notefiletab .v-tabs-bar {
  background: none !important;
}
.notefiletab .v-tab {
  background: #fff;
}
.editMemberBtn {
  float: right;
}
*/
div.v-text-field__slot {
  cursor: pointer;
}

</style>
<script>
// let memberIdInputTimer = null;
import store from "@/store";
import RxClaims from "@/components/RxClaims.vue";
import MedicalClaims from "@/components/MedicalClaims.vue";
import SideNavBar from "@/components/SideNavBar.vue"
/* import { VDataTable } from 'vuetify/labs/VDataTable' */
export default {
  name: "DisplayMemberDetails",
  components: {
    RxClaims,
    MedicalClaims,
    SideNavBar,
    // VDataTable
  },
  methods: {
    drugNameClicked(drugGPI) {
      let selectedGenericID = null
      for(var i=0; i< this.memberDetail.drugs.length; i++) {
        // console.log("checking drug " + (i+1) + " of " + this.adItems.length)
        // console.log("does " + this.memberDetail.drugs[i].gpicode + " = " + drugGPI + "?")
        if(this.memberDetail.drugs[i].gpicode == drugGPI){
          selectedGenericID = this.memberDetail.drugs[i].genericID
          break
        }
      }
      if (selectedGenericID != null) {
        for (var j=0; j<this.adItems.length; j++) {
          if (this.adItems[j].genericID == selectedGenericID ) {
            this.addDrugTab(this.adItems[j])
            break
          }
        }
      }
    },
    resetTabs() {
      this.memberTab = 0;
      if (this.memberTabHeaders.length > 3) this.memberTabHeaders = this.memberTabHeaders.slice(0, -1)
    },
    handleIntervention () {
      this.$router.push('/interventionRecords')
    },
    handlePhoneOrEmailEdit(type){
      if(type === 'phone') {
        this.isEditPhone = true
      } else if(type === 'caregivenphone') {
        this.isEditCareGivenPhone = true
      } else {
        this.isEditEmail = true 
      }
    },
    showLocalDate(dateInvited) {
      // convert UTC to local then format
      if(dateInvited != null) {
        if(dateInvited.substr(dateInvited.length-1).toLowerCase() != 'z'){
          dateInvited = dateInvited + "Z";
        }
        var date = new Date(dateInvited);
        var month = date.getMonth() + 1;
        return (
          date.getFullYear() +
          "-" +
          (month > 9 ? month : "0" + month) +
          "-" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate())
        );
      }
    },
    showAge(dob) {
      if (dob !== undefined) {
      var year = Number(dob.substr(0, 4));
      var month = Number(dob.substr(4, 2)) - 1;
      var day = Number(dob.substr(6, 2));
      var today = new Date();
      var age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() == month && today.getDate() < day)
      ) {
        age--;
      }
      if (age < 2) {
        age = today.getMonth() - month;
        if (today.getDate() < day) {
          age--;
        }
        return age + " months";
      }
      return age + " years";
    }
    },
    showGender(val) {
      if(val != null ){
        switch(val.toLowerCase()) {
          case "m":
          case "male":
            return "Male";
          case "f":
          case "female":
            return "Female";
          case "o":
          case "other":
            return "Other";
          case "nb":
          case "n-b":
          case "non-binary":
            return "Non-Binary";
          default:
            return "";
        }
      }
      return "";
    },
    loadMemberById() {
      // console.log('load member ' + this.memberIdInput);
      this.showChangeMember = false;
      if(this.memberIdInput != null && this.memberIdInput > 0) {
        this.goToMemberView(this.memberIdInput);
      }
    },
    toggleMemberDetails() {
      this.expandMemberDetails = !this.expandMemberDetails;
    },
    goToMemberView(mId) {
      store.commit("SET_SELECTED_MEMBER_ID", mId);
      store.dispatch("getMemberDetail");
      // store.dispatch("setMainTab", 1);
      this.$router.push('/displayMemberDetails')
    },
    selectClinicianTeam(value) {
      if(this.memberStatusId >= 10 && this.memberStatusId < 20 && value == 2){
        store.dispatch("unTargetMembers", {
          status: this.memberStatusId+10,
          memberIds: [this.selectedMemberId],
          reloadMethod: "memberDetail"
        });
      }else if(this.memberStatusId >= 20 && this.memberStatusId < 30 && value == 1){
        store.dispatch("unTargetMembers", {
          status: this.memberStatusId-10,
          memberIds: [this.selectedMemberId],
          reloadMethod: "memberDetail"
        });
      }
    },
    selectMemberStatus(value) {
      // console.log("current status", this.memberStatusId);
      // console.log("set status", value);
      // console.log("team " + this.clinicianTeamModel);
      if(this.memberStatusId > 0) {
        if(this.clinicianTeamModel==2 && (value > 9 && value < 20)) {
          value = value + 10;
        }
        if(this.memberStatusId < value) {
          store.dispatch("targetMembers", {
            status: value,
            memberIds: [this.selectedMemberId],
            reloadMethod: "memberDetail"
          });
        }else if(this.memberStatusId > value) {
          store.dispatch("unTargetMembers", {
            status: value,
            memberIds: [this.selectedMemberId],
            reloadMethod: "memberDetail"
          });
        }
      }
    },
    setEditableMemberDetails() {
      //set defaults
      this.editableMember = {
        member: {...this.memberDetail.member},
        memberPhones:
          this.memberDetail.memberPhones == null ||
          this.memberDetail.memberPhones.length == 0
            ? [
                {
                  memberPhoneId: -1,
                  memberId: this.memberDetail.member.memberId,
                  phoneName: null,
                  phoneNumber: null,
                  confirmed: false,
                  archived: false,
                  dateCreated: null
                }
              ]
            : [...this.memberDetail.memberPhones],
        memberPhones2:
          this.memberDetail.memberPhones2 == null
            ?  {
                  id: null,
                  memberId: this.memberDetail.member.memberId,
                  careGiverContact: null,
                  careGiverName: null,
                  relationshipPatient: null,
                  isCareGivenPhone: false,
                  isPhoneNo: false
                }
            : {...this.memberDetail.memberPhones2},
        memberEmails:
          this.memberDetail.memberEmails == null ||
          this.memberDetail.memberEmails.length == 0
            ? [
                {
                  memberEmailId: -1,
                  memberId: this.memberDetail.member.memberId,
                  emailName: null,
                  emailAddress: null,
                  confirmed: false,
                  archived: false,
                  dateCreated: null
                }
              ]
            : [...this.memberDetail.memberEmails],
        memberAddresses:
          this.memberDetail.memberAddresses == null ||
          this.memberDetail.memberAddresses.length == 0
            ? [
                {
                  memberAddressId: -1,
                  memberId: this.memberDetail.member.memberId,
                  addressName: null,
                  streetAddress: null,
                  streetAddress2: null,
                  city: null,
                  state: null,
                  zipCode: null,
                  zipPlus4: null,
                  dateCreated: null,
                  dateModified: null
                }
              ]
            : [...this.memberDetail.memberAddresses]
      };
    },
    saveMember() {
      store.dispatch("saveMemberDetails", this.editableMember);
      this.showEditMember = false;
    },
    toggleAddNote() {
      this.showAddNote = !this.showAddNote;
      if (this.showAddNote == true) {
        //reset fields on show
        this.addNoteTitle = "Add Note";
        this.addFileTitle = "Add File";
        this.newNote = {
          userNoteId: 0,
          memberId: this.memberDetail.member.memberId,
          userId: this.appUser.user.userId,
          username: this.appUser.user.username,
          dateCreated: null,
          note: null
        };
      }
    },
    addNote() {
      if (this.addNoteTitle == "Add Note") {
        store.dispatch("addUserNote", this.newNote);
      } else if (this.addNoteTitle == "Edit Note") {
        store.dispatch("editUserNote", this.newNote);
      }
      this.toggleAddNote();
    },
    memberDetailsDeleteNote(note) {
      this.memberNotesObj = note
      this.isDeleteNoteDialog = true
    },
    deleteMemberDetailsNote(event) {
      if(event){
        store.dispatch("deleteNote", this.memberNotesObj.userNoteId);
      }
      this.isDeleteNoteDialog = false
    },
    showEditNote(note) {
      this.toggleAddNote();
      this.newNote = note;
      this.newNote.userId = this.appUser.user.userId;
      this.newNote.username = this.appUser.user.username;
      this.addNoteTitle = "Edit Note";
      this.addNoteText = note.note;
    },
    toggleAddFile() {
      this.showAddFile = !this.showAddFile;
      if (this.showAddFile == true) {
        //reset fields on show
        this.addFileObj = null;
      }
    },
    addFile() {
      var myfile = this.$refs.addFileInput.files[0];
      this.addFileObj = this.$refs.addFileInput.files[0];
      //console.log(myfile.size);
      if (myfile != null) {
        if (myfile.size < 4096000) {
          let fd = new FormData();
          fd.append("file", myfile);
          fd.append("mid", this.selectedMemberId);
          fd.append("cid", this.selectedClient.clientId);
          store.dispatch("uploadFile", {
            reloadMethod: "memberDetail",
            formData: fd
            //memberId: this.selectedMemberId
          });
        } else {
          store.dispatch("setMessage", {
            message: "File Larger Than 4MB",
            class: "errorMessage"
          });
        }
      }
      this.toggleAddFile();
    },
    confirmRenameFile(answer) {
      this.renameDialog = false;
      if (answer && this.newFileName != null && this.newFileName.length > 0) {
        // strip duplicate file extension
        let all = this.newFileName.length;
        if (all > 0) {
          let slength = this.renameSuffix.length;
          let lastindex = this.newFileName.lastIndexOf(this.renameSuffix);
          if (lastindex == -1 || lastindex != all - slength) {
            this.newFileName = this.newFileName + this.renameSuffix;
          }
        }

        store.dispatch("renameFile", {
          fileId: this.selectedFileId,
          memberId: this.selectedMemberId,
          newFileName: this.newFileName
        });
      }

      this.newFileName = null;
      this.selectedFileId = 0;
      this.selectedFileName = null;
    },
    renameFileClick(fileId, fileName) {
      this.selectedFileId = fileId;
      this.selectedFileName = fileName;
      this.renameDialog = true;
    },
    confirmDeleteFile(answer) {
      store.commit("SET_DELETE_DIALOG", false);
      if (answer && this.fileDeleteable) {
        store.dispatch("deleteFile", {
          fileId: this.selectedFileId,
          memberId: this.selectedMemberId
        });
      }
      this.selectedFileId = 0;
      this.selectedFileName = null;
    },
    deleteFileClick(fileId, fileName) {
      this.selectedFileId = fileId;
      this.selectedFileName = fileName;
      store.commit("SET_DELETE_DIALOG_TEXT", fileName);

      store.dispatch("getFileAssignments", {
        fileId: this.selectedFileId
      });
    },
    exitMTM() {
      this.showMTM = false;
      this.$refs.MTMevent.resetTabs();
      this.isFirstTimeShow = true;
    },
    openMTM() {
      if (this.isFirstTimeShow) {
        this.showMTM = true;
        if (this.$refs.MTMevent) {
          this.$refs.MTMevent.setTabs();
        }
      }
    },
    addDrugTab(item) {
      console.log(item)
      console.log(item.raw)
      this.selectedDrug = (item.raw != undefined) ? item.raw : item;
      console.log(this.selectedDrug)
      if(this.memberTabHeaders.length < 4){
        this.memberTabHeaders.push({ 
          title: this.selectedDrug.drugName, 
          key: "drugTab" 
        });
      }else{
        this.memberTabHeaders[3].title = this.selectedDrug.drugName;
      }
      this.memberTab = 3;
    },
    qualifiedBtnClick(statusId) {
      store.dispatch("targetMembers", {
        status: statusId,
        memberIds: [this.selectedMemberId],
        reloadMethod: "memberDetail"
      });
    },
    convertBtnClick(statusId) {
      store.dispatch("unTargetMembers", {
        status: statusId,
        memberIds: [this.selectedMemberId],
        reloadMethod: "memberDetail"
      });
    },
    removeBtnClick() {
      if (
        confirm(
          "Are you sure you wish to retire this patient from the MemberFirst program?"
        )
      ) {
        // console.log("yup, drop em");
        store.dispatch("targetMembers", {
          status: 99,
          memberIds: [this.selectedMemberId],
          reloadMethod: "memberDetail"
        });
      }
    },
    manualTargetBtnClick() {
      store.dispatch("targetMembers", {
        status: 1,
        memberIds: [this.selectedMemberId],
        reloadMethod: "memberDetail"
      });
    },
    resetDemoBtnClick() {
      if (
        this.selectedMemberId == 3129 &&
        (this.memberStatusId == 11 || this.memberStatusId == 21)
      ) {
        store.dispatch("resetDemoAcceptedInvite", {
          reloadMethod: "memberDetail"
        });
      }
    },
    downloadFile(fileId) {
      store.dispatch("downloadFile", {
        fileId: fileId,
        reloadMethod: null
      });
    },
    showFilesize(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [" kB", " MB", " GB", " TB", " PB", " EB", " ZB", " YB"];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }
  },
  data() {
    return {
      isDeleteNoteDialog: false,
      memberNotesObj: null,
      memberIdInput: null,
      expandMemberDetails: false,
      memberTab: null,
      // noteFileTab: "notes",
      addNotetitle: null,
      addNoteTitle: "Add Note",
      showAddNote: false,
      showAddFile: false,
      showEditMember: false,
      showNotes: false,
      showFiles: false,
      showChangeMember:false,
      showMTM: false,
      selectedFileId: 0,
      selectedFileName: null,
      newFileName: null,
      renameDialog: false,
      maintFilter: false,
      newNote: {
        userNoteId: 0,
        memberId: 0,
        userId: 0,
        username: null,
        dateCreated: null,
        note: null
      },
      addFileObj: null,
      memberStatusModel: null,
      clinicianTeamModel: null,
      clinicianTeamItems: [{title:'Team 1', key:1},{title:'Team 2',key:2}],
      editableMember: {
        member: {
          memberId: -1,
          firstName: null,
          lastName: null,
          middleName: null,
          nameSuffix: null,
          dateOfBirth: null,
          gender: null,
          maritalStatus: null,
          dateCreated: null,
          dateModified: null
        },
        memberPhones: [
          {
            memberPhoneId: -1,
            memberId: -1,
            phoneName: null,
            phoneNumber: null,
            confirmed: false,
            archived: false,
            dateCreated: null
          }
        ],
        memberPhones2:
          {
            id: null,
            memberId: -1,
            careGiverContact: null,
            careGiverName: null,
            relationshipPatient: null,
            isCareGivenPhone: false,
            isPhoneNo: false
          },
        memberEmails: [
          {
            memberEmailId: -1,
            memberId: -1,
            emailName: null,
            emailAddress: null,
            confirmed: false,
            archived: false,
            dateCreated: null
          }
        ],
        memberAddresses: [
          {
            memberAddressId: -1,
            memberId: -1,
            addressName: null,
            streetAddress: null,
            streetAddress2: null,
            city: null,
            state: null,
            zipCode: null,
            zipPlus4: null,
            dateCreated: null,
            dateModified: null
          }
        ],
        isFirstTimeShow: false
      },
      memberTabHeaders: [
        { title: "Summary", key: "Summary" },
        { title: "All Rx Claims", key: "allRxClaims" },
        { title: "All Medical Claims", key: "allMedClaims" }
      ],
      selectedDrug: null,
      mappedDrugs: [],
      aqcHeaders: [
        {
          title: "Criteria Name",
          key: "criteriaName"
        },
        {
          title: "Date Identified",
          key: "dateIdentified"
        },
        {
          title: "Count",
          key: "codelist.length"
        }
      ],
      adHeaders: [
        {
          title: "Drug Name",
          key: "drugName"
        },
        {
          title: "Start Date",
          key: "startDate"
        },
        {
          title: "Most Recent Fill",
          key: "recentFillDate"
        },
        {
          title: "PDC",
          key: "pdc"
        }
      ],
      dcHeaders: [
        { title: "Code", key: "code" },
        { title: "Description", key: "description" },
        { title: "First Recorded", key: "dateRecorded" }
      ],
      memberGenderList: [
        { title: "Male", key: "M" },
        { title: "Female", key: "F" },
        { title: "Other", key: "O" }
      ],
      maritalStatusList: [
        { title: "Single", key: "S" },
        { title: "Married", key: "M" },
        { title: "Widowed", key: "W" },
        { title: "Not Disclosed", key: "ND" },
      ],
      isEditPhone: false,
      isEditEmail: false,
      isEditCareGivenPhone: false
    };
  },
  computed: {
    selectisReview() {
      return store.getters.selectisReview
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    clinicianTeamDisabled() {
      if (
        this.memberStatusId != null &&
        this.memberStatusId >= 1 &&
        this.memberStatusId < 30
      ) {
        return false;
      }
      return true;
    },
    deleteDialog() {
      return store.getters.deleteDialog;
    },
    deleteDialogText() {
      return store.getters.deleteDialogText;
    },
    fileDeleteable() {
      return store.getters.fileDeleteable;
    },
    selectedMemberId() {
      return this.memberDetail != null
        ? this.memberDetail.member.memberId
        : null;
    },
    buttonsDisabled() {
      return this.selectedMemberId != null && this.selectedMemberId > 0
        ? false
        : true;
    },
    disableRenameBtn() {
      return this.newFileName != null && this.newFileName.length > 0
        ? false
        : true;
    },
    disableDeleteBtn() {
      return !this.fileDeleteable;
    },
    renameSuffix() {
      return this.selectedFileName != null
        ? this.selectedFileName.lastIndexOf(".") > -1
          ? this.selectedFileName.substr(this.selectedFileName.lastIndexOf("."))
          : null
        : null;
    },
    qualifiedOptions() {
      return store.getters.qualifiedOptions;
    },
    memberStatusItems() {
      return store.getters.memberStatusItems;
    },
    memberStatusItemsFiltered() {
      if(this.memberDetail != null &&
        this.memberStatusId > 0) {
          return this.memberStatusItems.slice(1);
      }
      return this.memberStatusItems;
    },
    memberDetail() {
      return store.getters.memberDetail;
    },
    privacyMode() {
      return store.getters.privacyMode;
    },
    memberStatusId() {
      if (this.aqcItems != null) {
        var status = 0;
        for (var i = 0; i < this.aqcItems.length; i++) {
          if (this.aqcItems[i].statusId > status)
            if (
              this.aqcItems[i].statusId != 3 &&
              this.aqcItems[i].statusId != 30
            ) {
              status = this.aqcItems[i].statusId;
            }
        }
        if(status >= 20 && status < 30) {
          this.clinicianTeamModel = 'Team' + 2;
        }else{ 
          this.clinicianTeamModel = 'Team' + 1;
        }
        var qoption = this.qualifiedOptions.find(option => option.id==status);
        if(qoption != null){
          if(qoption.id>19&&qoption.id<30){
            this.memberStatusModel =  qoption.id - 10;
          }else if(qoption.id==101){
            this.memberStatusModel = 1;
          }else{
            this.memberStatusModel= qoption.id;
          }
        }else{
          this.memberStatusModel = 0;
        }
        return status;
      }
      return null;
    },
    memberStatus() {
      if (this.memberStatusId != null) {
        /*var status = 0;
        for (var i = 0; i < this.aqcItems.length; i++) {
          if (this.aqcItems[i].statusId > status)
            status = this.aqcItems[i].statusId;
        }*/
        for (var j = 0; j < this.qualifiedOptions.length; j++) {
          if (this.qualifiedOptions[j].id == this.memberStatusId) {
            return this.qualifiedOptions[j].name;
          }
        }
        return "Dormant";
      }
      return null;
    },
    profileIcon() {
      switch(this.memberStatusId){
        case 1:
        case 2:
        case 101:
          return "mdi-bullseye";
          // return "fa-bullseye";
        case 10:
        case 20:
          return "mdi-email";
          // return "fa-envelope";
        case 11:
        case 21:
          return "mdi-check-circle";
          // return "fa-circle-check";
        case 12:
        case 22:
          return "mdi-format-list-checks";
          // return "fa-list-check";
        case 99:
          return "mdi-island";
          //return "fa-island-tropical";
          // return "fa-mountain-sun";
        case 98:
          return "mdi-school";
          // return "fa-graduation-cap";
        case 19:
        case 29:
          return "mdi-cancel";
          // return "fa-ban";
        default: 
          return "mdi-bed-clock";
          // return "fa-bed";
      }
    },
    profileIconColor() {
      switch(this.memberStatusId){
        case 1:
        case 2:
        case 101:
        case 10:
        case 20:
          return "#ecc52d";
        case 11:
        case 21:
        case 12:
        case 22:
        case 99:
        case 98:
          return "#b5cf90";
        case 19:
        case 29:
          return "d95b54";
        default: 
          return "#e3ebfe";
      }
    },
    appUser() {
      return store.getters.appUser;
    },
    aqcItems() {
      return this.memberDetail != null
        ? this.memberDetail.memberCriterias
        : null;
    },
    memberFiles() {
      return this.memberDetail != null ? this.memberDetail.memberFiles : null;
    },
    addNoteBtnLabel() {
      return this.showAddNote ? "Cancel" : "Add Note";
    },
    addFileBtnLabel() {
      return this.showAddFile ? "Cancel" : "Upload File";
    },
    /*
    editMemberBtnLabel() {
      return this.showEditMember ? "Cancel" : "Edit Member";
    },
    editMemberBtnClass() {
      return this.showEditMember ? "secondary editMemberBtn" : "editMemberBtn";
    },
    */
    addNoteBtnDisabled() {
      return this.newNote != null &&
        this.newNote.note != null &&
        this.newNote.note != ""
        ? false
        : true;
    },
    addFileBtnDisabled() {
      return this.addFileObj != null ? false : true;
    },
    noteBtnClass() {
      return this.showAddNote ? "bg-color-orange addNoteBtn" : "addNoteBtn";
    },
    fileBtnClass() {
      return this.showAddFile ? "bg-color-orange addNoteBtn" : "addNoteBtn";
    },
    dcItems() {
      return this.memberDetail != null
        ? this.memberDetail.diagnosisCodes
        : null;
    },
    adItems() {
      var mappedDrugs = [];
      if (
        this.memberDetail &&
        this.memberDetail.rxClaims &&
        this.memberDetail.rxClaims.length > 0 &&
        this.memberDetail.drugs
      ) {
        var mappedGenericCodes = [];
        // build list of unique generic drugs
        for (var a = 0; a < this.memberDetail.drugs.length; a++) {
          if (
            mappedGenericCodes.indexOf(this.memberDetail.drugs[a].genericID) ==
            -1
          ) {
            mappedGenericCodes.push(this.memberDetail.drugs[a].genericID);
            mappedDrugs.push({
              drugName: this.memberDetail.drugs[a].genericName,
              genericID: this.memberDetail.drugs[a].genericID,
              startDate: null,
              recentFillDate: null,
              maintenance: this.memberDetail.drugs[a].maintenance
            });
          }
        }

        //loop through unique generics
        for (var h = 0; h < mappedDrugs.length; h++) {
          var finalLength = 0;
          var totalFilled = 0;
          var labels = [];
          // loop through drugs for matching generic ids to get gpi
          for (var i = 0; i < this.memberDetail.drugs.length; i++) {
            if (
              this.memberDetail.drugs[i].genericID == mappedDrugs[h].genericID
            ) {
              
              // loop through rxs claims for matching gpi
              for (var j = 0; j < this.memberDetail.rxClaims.length; j++) {
                // if match, set dates
                if ( (this.memberDetail.rxClaims[j].drugGPI == this.memberDetail.drugs[i].gpicode)
                   || (this.memberDetail.rxClaims[j].drugGPI == null && this.memberDetail.rxClaims[j].genericGPI == this.memberDetail.drugs[i].gpicode) )
                {
                  // build list of label names for this generic
                  if(this.memberDetail.rxClaims[j].drugName != null && this.memberDetail.rxClaims[j].drugName != ''){
                    if(!labels.includes(this.memberDetail.rxClaims[j].drugName)){
                      labels.push(this.memberDetail.rxClaims[j].drugName);
                    }
                  }else if(!labels.includes(this.memberDetail.rxClaims[j].genericName)){
                      labels.push(this.memberDetail.rxClaims[j].genericName);
                    }

                  totalFilled += this.memberDetail.rxClaims[j].daysSupplyDisp;

                  if (
                    !mappedDrugs[h].startDate ||
                    mappedDrugs[h].startDate >
                      this.memberDetail.rxClaims[j].dateService
                  ) {
                    mappedDrugs[h].startDate = this.memberDetail.rxClaims[
                      j
                    ].dateService;
                  }
                  if (
                    !mappedDrugs[h].recentFillDate ||
                    mappedDrugs[h].recentFillDate <
                      this.memberDetail.rxClaims[j].dateService
                  ) {
                    mappedDrugs[h].recentFillDate = this.memberDetail.rxClaims[
                      j
                    ].dateService;
                    finalLength = this.memberDetail.rxClaims[j].daysSupplyDisp;
                  }
                }
              }
            }
          }
          var sd = new Date(mappedDrugs[h].startDate);
          var ed = new Date(mappedDrugs[h].recentFillDate);
          var timeBetween = ed.getTime() - sd.getTime();
          var daysBetween = timeBetween / (1000 * 3600 * 24);
          var pdc = Math.floor(
            (100 * totalFilled) / (daysBetween + finalLength)
          );
          mappedDrugs[h].pdc = pdc + "%";
          // combine label names
          var combinedLabels = "";
          labels.forEach(label => combinedLabels = combinedLabels + label + "<br/>");
          mappedDrugs[h].labelName = combinedLabels.slice(0,-5);
        }

        mappedDrugs.sort((a, b) =>
          a.recentFillDate > b.recentFillDate ? -1 : 1
        );
      }





      if (this.maintFilter) {
        return mappedDrugs.filter(
          drug => drug.maintenance != null && drug.maintenance != ""
        );
      }

      return mappedDrugs;
    },
    totalRxCost() {
      if (this.memberDetail && this.memberDetail.rxClaims) {
        var tot = 0;
        for (var i = 0; i < this.memberDetail.rxClaims.length; i++) {
          if (
            !isNaN(parseInt(this.memberDetail.rxClaims[i].totalRxCost * 100))
          ) {
            tot += parseInt(this.memberDetail.rxClaims[i].totalRxCost * 100);
          }
        }
        return tot / 100;
      }
      return null;
    },
    totalMedCost() {
      if (this.memberDetail && this.memberDetail.medClaims) {
        var tot = 0;
        for (var i = 0; i < this.memberDetail.medClaims.length; i++) {
          if (
            !isNaN(parseInt(this.memberDetail.medClaims[i].totalPaidAmt * 100))
          ) {
            tot += parseInt(this.memberDetail.medClaims[i].totalPaidAmt * 100);
          }
        }
        return tot / 100;
      }
      return null;
    },
    uniqueId() {
      if (this.memberDetail && this.memberDetail.memberSharedIdentities) {
        var obj = this.memberDetail.memberSharedIdentities.find(
          identity =>
            identity.memberId == this.memberDetail.member.memberId &&
            identity.identityTypeId == 1
        );
        if (obj != null) {
          return obj.identityValue;
        }
      }
      return null;
    },
    memberGroupId() {
      if (this.memberDetail && this.memberDetail.rxClaims) {
        var sortedRx = this.memberDetail.rxClaims.slice().sort((a, b) => {
          const valA = a.dateService.toUpperCase();
          const valB = b.dateService.toUpperCase();
          let comparison = 0;
          if (valA > valB) {
            comparison = 1;
          } else if (valA < valB) {
            comparison = -1;
          }
          return comparison;
        });
        let res = [];
        for (let i = 0; i < sortedRx.length; i++) {
          if (
            res.length == 0 ||
            sortedRx[i].groupId != res[res.length - 1].id
          ) {
            res.push({
              id: sortedRx[i].groupId,
              date: sortedRx[i].dateService
            });
          }
          /*
            let found = false;
            for(let j=0; j<res.length; j++){
              if(sortedRx[i].groupId == res[j].id){
                found = true;
                break;
              }
            }
            if(!found){
              res.push({ id: sortedRx[i].groupId, date: sortedRx[i].dateService});
            }
            */
        }
        if (res.length == 1) {
          return res[0].id;
        } else if (res.length > 1) {
          let out = "";
          for (let a = 0; a < res.length; a++) {
            if (a == 0) out += res[a].id;
            else out += "<br> -- " + res[a].id + " " + res[a].date;
          }
          return out;
        }
      }
      return null;
    },
  },
  watch: {
    // memberStatusId(newStatus) {
    //   if(newStatus >= 20 && newStatus < 30) {
    //     this.clinicianTeamModel = 'Team' + 2;
    //   }else{ 
    //     this.clinicianTeamModel = 'Team' + 1;
    //   }
    //   var qoption = this.qualifiedOptions.find(option => option.id==newStatus);
    //   if(qoption != null){
    //     if(qoption.id>19&&qoption.id<30){
    //       this.memberStatusModel = qoption.id - 10;
    //     }else if(qoption.id==101){
    //       this.memberStatusModel = 1;
    //     }else{
    //       this.memberStatusModel = qoption.id;
    //     }
    //   }else{
    //     this.memberStatusModel = 0;
    //   }
    // },
    memberDetail() {
      this.setEditableMemberDetails();
      this.resetTabs();
    }
  },
  mounted () {
    const selectReviewObj = JSON.parse(localStorage.getItem("selectReview"))
      if(selectReviewObj === null){
        store.dispatch("setSelectIsReview", null);
      }
      if(selectReviewObj !== null && selectReviewObj.isReview) {
        store.dispatch("showLoadingSpinner");
        store.dispatch("setSelectIsReview", selectReviewObj.isReview);
        store.dispatch("setSelectDocumentId", selectReviewObj.documentId);
        store.dispatch("setClinicianName", selectReviewObj.clinicianName);
        store.dispatch("setReviewerId", selectReviewObj.reviewerId);
        setTimeout(() => {
          this.goToMemberView(selectReviewObj.memberId);
        }, 2000);
      }
    }
};
</script>
<style>
.side-nav-card {
  min-height: 150px!important;
}
.pd-container {
  padding:unset !important;
  padding: 0px!important;
}
.v-text-field input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
}
.v-table > .v-table__wrapper > table > tbody > tr > th, .v-table > .v-table__wrapper > table > thead > tr > th, .v-table > .v-table__wrapper > table > tfoot > tr > th {
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-align: start;
    font-size: smaller;
}   
.memberSummary tr:nth-child(odd) {
  background:transparent;
}
.memberDetailsTab {
  .v-btn__content {
    white-space: normal  !important;
  } 
}
</style>
