<template>
  <v-container id="register" fluid>
    <div id="preloader" v-if="showLoader">
      <div class="inner">
        <div  class="loader">
        </div>
      </div>
    </div>
    <div v-else>
      <Header/>
      <div class="main-section" id="background_image" style="z-index: 0;">
        <div class="display-table">
          <div class="display-table-cell vertical-align-middle">
            <div class="container">
              <v-row class="mg-top-20">
                <v-col cols="8">
                  <h2 class="fs-20">
                    {{ "Member First Prescription Management Program" }}
                  </h2>
                  <p>
                    Your employer has partnered with Member First to provide
                    their employees this voluntary program. Register now to
                    begin receiving program benefits:
                  </p>
                  <ul class="mg-left-15 pres-mag-prog">
                    <li>
                      Access via email, text, or phone to your personal
                      pharmacist to ask questions about any medication-related
                      matter.
                    </li>
                    <li>
                      Review of medication compliance and any side-effects.
                    </li>
                    <li>Ways to save money on medications.</li>
                    <li>
                      Potential for no medication co-pay while you actively
                      participate in the program. *Dependent on employer option.
                    </li>
                  </ul>
                  <div class="pd-10 sub-title-reg">
                    {{"Once you have completed registration, you should expect:"}}
                  </div>
                  <div class="complete-reg">
                    <v-icon size="40"> mdi-phone-classic </v-icon
                    ><span
                      class="mg-left-15 font-weight-300"
                      >{{ "A Member First Pharmacist will contact you." }}</span
                    >
                  </div>
                  <div class="complete-reg">
                    <v-icon size="40"> mdi-clock-time-three-outline </v-icon
                    ><span
                      class="mg-left-15 font-weight-300"
                      >{{ "A 30- to 60-minute phone call to discuss medications and additional information." }}</span
                    >
                  </div>
                  <div class="complete-reg">
                    <v-icon size="40"> mdi-target </v-icon
                    ><span
                      class="mg-left-15 font-weight-300"
                      >{{ "Your Personal Pharmacist will check in about once per quarter just to see how things are going." }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-card>
                    <v-card-title>
                      <v-icon class="mg-right-20" size="20" color="black">
                        mdi-account-group
                      </v-icon>
                      {{ "Register" }}
                    </v-card-title>
                    <v-divider class="mg-bottom-10"></v-divider>
                    <v-card-text >
                      <div>
                        <v-text-field
                          variant="outlined"
                          density="comfortable"
                          dense
                          v-model="letterEnroll"
                          @input="handleLetterEnrollment" 
                          label="Registration Code (000000)"
                          :error-messages="letterEnrollError.errorMessage"
                          :error="letterEnrollError.isError"
                          class="registrationTextField"
                        />
                      </div>
                      <div class="mt-2">
                        <v-text-field
                          @input="handleMemberDOB"
                          variant="outlined"
                          density="comfortable"
                          dense
                          v-model="memberDob"
                          label="Member Date Of Birth (MM/DD/YYYY)"
                          :error-messages="memberDobError.errorMessage"
                          :error="memberDobError.isError"
                          class="registrationTextField"
                        />
                      </div>
                      <div class="mt-2">
                        <v-row class="flex-nowrap pd-unset">
                          <v-checkbox style="max-width: fit-content" 
                            @change="handleIsTermsAccepted" 
                            v-model="isTermAccepted"
                            :error-messages="isTermAcceptedError.errorMessage"
                            :error="isTermAcceptedError.isError"
                          >
                            <template v-slot:label>
                              <div >
                                I agree to the
                                <span class="term-service" @click="showTermsService">Terms of Service</span>
                              </div>
                            </template>
                          </v-checkbox>
                          
                        </v-row>
                      </div>
                      <div class="mt-2">
                        <v-btn
                          depressed
                          @click="handleUserRegister"
                          color="light-green"
                          class="pd-10 mg-top-10"
                        >
                        <v-icon left size="15" color="#ffffff">
                          mdi-check
                        </v-icon>
                        <span class="font-color-light">Submit</span>
                      </v-btn>
                    </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    <div>
    <TermsServiceModel
      v-if="isHideShowTermOfSer"
      @closeDialog="closeTermsDialog"
    />
    <AlertDialogs
      v-if="isHideShowAlertDialog"
      :alert-type="alertType"
      :alert-message="alertMessage"
      @closeDialog="closeAlertDialog"
    />
  </div>
  </v-container>
</template>
<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import TermsServiceModel from "@/components/dialogs/TermsServiceModel.vue"
import AlertDialogs from "@/components/dialogs/AlertDialogs.vue";
import axios from "axios";
export default {
  name: "Register",
  components: {
      Header,
      Footer,
      TermsServiceModel,
      AlertDialogs
  },
  data() {
    return {
      showLoader: true,
      isShowEnroll: false,
      isShowMemberDate: false,
      isHideShowTermOfSer: false,
      isHideShowAlertDialog: false,
      alertType: "",
      alertMessage: "",
      letterEnroll: "",
      letterEnrollError: { isError: false, errorMessage: "" },
      memberDob: "",
      memberDobError: { isError: false, errorMessage: "" },
      isTermAccepted: false,
      isTermAcceptedError: { isError: false, errorMessage: "" }
    };
  },
  computed: {
  },
  methods: {
  handleLetterEnrollment () {
    this.letterEnrollError.isError = false
    this.letterEnrollError.errorMessage = ''
  },
  handleMemberDOB () {
    this.memberDobError.isError = false
    this.memberDobError.errorMessage = ''
  },
  handleIsTermsAccepted (){
    this.isHideShowTermOfSer = false
    this.isTermAcceptedError.isError = false
    this.isTermAcceptedError.errorMessage = ''
  },
  closeAlertDialog () {
    this.isHideShowAlertDialog = false
    this.alertType = ''
    this.alertMessage = ''
  },
  closeTermsDialog () {
    this.isHideShowTermOfSer = false
  },
  showTermsService(e) {
    e.preventDefault()
    e.stopPropagation()
    this.isHideShowTermOfSer = true
  },
  handleUserRegister (){
    let isValid = true
    if(!this.isTermAccepted){
      this.isTermAcceptedError.isError = true
      this.isTermAcceptedError.errorMessage = 'Terms Not Accepted'
      isValid = false
    }
    if (this.letterEnroll === '') {
      this.letterEnrollError.isError = true
      this.letterEnrollError.errorMessage = 'Enrollment Code Required'
      isValid = false
    }
    if (!this.isDate(this.memberDob)) {
      this.memberDobError.isError = true
      this.memberDobError.errorMessage = 'Date of Birth Required'
      isValid = false
    }
    if(isValid){
      let dateObj = new Date(this.memberDob);
      if (!isNaN(dateObj)) {
        let day = dateObj.getDate();
        day = day < 10 ? "0" + day : day;
        let month = dateObj.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        const year = dateObj.getFullYear();
        const resultDate = `${year}-${month}-${day}`; // format yyyy-mm-dd
        const userRegObj = {
          enrollmentCode: this.letterEnroll,
          dateOfBirth: resultDate,
          termsCheckbox: this.isTermAccepted
        }
        this.userRegister(userRegObj)
      }
    }
  },
  isDate(dob) {
    return !isNaN(new Date(dob))
  },
  userRegister(userRegObj) {
    axios
    .post('/auth/register', userRegObj)
    .then(response => {
      console.log(response)
      if (response.status === 200) {
        if (response.data.errorMessage){
          this.alertType = 'Failed'
          this.alertMessage = response.data.errorMessage
          this.isHideShowAlertDialog = true
        } else if (response.data.code === 201) {
          this.isUserEdit = true
          //store.dispatch("hideLoadingSpinner");
          this.alertType = 'Success'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
          this.handleAfterRegister(response.data)  
        } else if (response.data.code === 401) {
          //store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
        }else{
          this.alertType = 'Failed'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
        }
      } else {
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      }
    })
    .catch((error) => {
      console.log(error)
      //store.dispatch("hideLoadingSpinner");
      this.alertType = 'Failed'
      this.alertMessage = 'Something went wrong'
      this.isHideShowAlertDialog = true
    })
  },
  handleAfterRegister (data){
    localStorage.regprofile = JSON.stringify(data)
    this.$router.push({ name: 'RegisterProfile' })
  }
},
  mounted () {
    setTimeout(() => {
      this.showLoader = false;
    }, 3000);
  }
};
</script>
<style>
.main-section {
  display: block;
  position: relative;
  padding: 100px 0;
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
  background-color: #fff;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  background-size: cover !important;
  box-sizing: border-box !important;
  background: url(../assets/grain_bg.png) no-repeat center;
}
.display-table {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 99;
}
.vertical-align-middle {
  vertical-align: middle;
}
.display-table-cell {
  display: table-cell;
  width: 100%;
  height: 100%;
}
h2 {
  font-size: 30px;
  margin: 0 0 32px 0;
}
p,
pre,
ol,
dl,
dd,
blockquote,
table {
  margin-bottom: 30px;
  color: #666;
}
.term-service {
  color: #8ab933;
  cursor: pointer;
}
.term-service:hover {
  color: #000000;
  cursor: pointer;
}
.pres-mag-prog > li {
  color: gray;
  font-weight: 300;
}
.complete-reg > span {
  color: gray;
}
.sub-title-reg {
  font-style: oblique;
  color: #666;
}
.v-input__icon--append .v-icon { 
    font-size: 18px
}
.v-card__subtitle, .v-card__text{
  line-height:unset !important;
}
</style>
