<template>
    <v-container fluid>
      <div class="inter-header"> <v-icon color="black" size="40">mdi-fax</v-icon>  Fax Dashboard</div>
        <div class="info-section-container mg-left-30 mg-right-30 mg-top-20">
          <div class="info-section-legend-container-home">
            <div class="info-section-legend-title-home">
              Current Fax Queue
            </div>
          </div>
          <button class="show-hide-button" @click="handleToggleCurrentFaxQueue">
            <span v-if="isShowFaxQueues"><v-icon size="30">mdi-chevron-down</v-icon></span>
            <span v-else><v-icon size="30">mdi-chevron-up</v-icon></span>
          </button>
          <div class="info-section-data-container">
            <div class="mg-top-50">
            </div>
              <div v-show="isShowFaxQueues" class="mg-top-20">
                <div class="ag-theme-alpine" style="margin-right:15px!important;">
                  <AgGridCustomPagination
                    :pageSize="currentFaxQueuePageSize"
                    :pageSizes="pageSizes"
                    :currentPage="currentfaxQueuePage"
                    :totalPages="currentFaxQueueTotalPages"
                    :visibleCount="visibleCount"
                    :start-row="faxQueueStart"
                    :end-row="faxQueueEnd"
                    :total-records="faxQueueTotalRecods"
                    @handlePageChange="handleCurrentFaxQueuePageChange"
                    @handlePageSizeChange="handleCurrentFaxQueuePageSizeChange"
                  />
                  <div class="pd-right-15" style="min-width: 800px">
                    <ag-grid-vue
                        style="width: 100%; height: 260px; margin-right: 25px;"
                        class="ag-theme-alpine"
                        :columnDefs="columnDefs"
                        :rowData="rowData">
                    </ag-grid-vue>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="mg-top-30">
            <v-row>
                <v-col cols="2">
                </v-col>
                <v-col cols="8">
                <div class="inter-header mg-top-20">Send New Fax</div>    
                <v-divider></v-divider>
                <v-row class="mg-top-15">
                    <v-col cols="5">
                        <div class="mg-top-20">
                            <v-text-field
                                label="Subject"
                                variant="outlined"
                                density="comfortable"
                                hide-details="auto">
                            </v-text-field>
                        </div> 
                        <div class="mg-top-30">
                            <v-text-field
                                label="Receiver Fax Number"
                                variant="outlined"
                                density="comfortable"
                                hide-details="auto">
                            </v-text-field>
                        </div>
                        <div class="mg-top-30 mg-left-20"> 
                            <v-row class="flex-nowrap">
                            <div class="mg-top-5">{{ "Cover Letter" }}</div> <v-switch color="#2196f3" v-model="isCoverLetter"></v-switch>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="5"></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="mg-top-5" style="margin-top: -15px">
                          <div>
                            <div class="upload-area"
                                @dragenter="handleCoverLetterFileDragEnter"
                                @dragover="handleCoverLetterFileDragOver"
                                @drop="handleCoverLetterFileDrop"
                                @click="handleClickOnCoverLetterDropBox">
                                <div class="mg-top-4rm font-weight-500" v-if="isCoverLetterUploading">
                                  {{"Uploading The Cover Letter..."}}
                                </div>
                                <div class="uploadMessage" v-else>
                                    <v-icon color="#2196f363" size="55">mdi-file-document-plus-outline</v-icon>
                                    <p class="mg-top-10 fs-14 primary-color font-weight-500">SELECT A FILE FOR COVER LETTER</p>
                                    <p class="fs-14">or drag and drop here</p>
                                </div>
                                <input
                                    class="visibility-hidden"
                                    ref="fileInput"
                                    type="file"
                                    name="file-input"
                                    multiple="True"
                                    @input="handleCoverLetterFileInput"
                                />
                            </div>
                        </div>
                    </div>
                    </v-col>
                </v-row>
                <v-row class="mg-top-15">
                    <div class="mg-left-10 fs-15">FAX:</div>
                    <v-col cols="12">
                        <div class="mg-top-5">
                          <div>
                            <div class="upload-area"
                                @dragenter="handleFaxFileDragEnter"
                                @dragover="handleFaxFileDragOver"
                                @drop="handleFaxFileDrop"
                                @click="handleClickOnFaxDropBox">
                                <div class="mg-top-4rm font-weight-500" v-if="isFaxUplaoding">
                                  {{"Uploading The Cover Letter..."}}
                                </div>
                                <div class="uploadMessage" v-else>
                                  <v-icon color="#2196f363" size="55">mdi-file-document-plus-outline</v-icon>
                                  <p class="mg-top-10 fs-14 primary-color font-weight-500">SELECT A FILE FOR FAX</p>
                                  <p class="fs-14">or drag and drop here</p>
                                </div>
                                <input
                                  class="visibility-hidden"
                                  ref="fileInput"
                                  type="file"
                                  name="file-input"
                                  multiple="True"
                                  @input="handleFaxFileInput"
                                />
                            </div>
                        </div>
                    </div>
                    </v-col>
                </v-row>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <div class="mg-bottom-10 mg-top-15 btn-center">
             <v-fab-transition>
              <v-btn
                class="floating-btn"
                style="width: 132px!important;"
                color="blue"
                dark
                absolute
                bottom
                right
                depressed
                fab
                @click="handleSendFax">
                <v-icon size="30">mdi-fax</v-icon>SEND FAX</v-btn>
             </v-fab-transition>
            </div>
        </div>
    </v-container>
</template>
<script>
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue3'
import AgGridCustomPagination from "@/components/PaginationView/AgGridCustomPagination.vue";
import rendererFaxDeshboardAction from "@/components/renderers/rendererFaxDeshboardAction.vue";
export default {
  name: "FaxDashboard",
  components: {
    AgGridVue,
    AgGridCustomPagination,
    rendererFaxDeshboardAction
  },
  data() {
    return {
        isCoverLetterUploading: false,
        isFaxUplaoding: false,
        pageSizes: [10, 20, 50, 100, 200],
        currentFaxQueueTotalPages:12,
        currentFaxQueuePageSize: 10,
        currentfaxQueuePage: 1,
        faxQueueTotalRecods: 0,
        faxQueueStart: 0,
        visibleCount: 7,
        faxQueueEnd: 0,
        columnDefs: [
            { headerName: 'Status', field: 'status', width: 80, flex: 1, sortable: true},
            { headerName: 'Filename', field: 'fileName', minWidth: 90, flex: 1, sortable: true},
            { headerName: 'Subject', field: 'subject', minWidth: 90, flex: 1, sortable: true},
            { headerName: 'ToTextNumber', field: 'toTextNumber', minWidth: 90, flex: 1, sortable: true},
            { headerName: 'Date Queued', field: 'dateQueued', minWidth: 90, flex: 1, sortable: true},
            { headerName: 'Page', field: 'page', minWidth: 90, flex: 1, sortable: true},
            { headerName: 'Ref Number', field: 'refNumber', minWidth: 120, flex: 1, sortable: true},
            { cellRenderer: 'rendererFaxDeshboardAction', width:10}
        ],
        rowData: [
            {id:"1", status: "Sent", fileName: "Sample_fax1", subject: "Sample Subject1", toTextNumber: "1-222-333-441", dateQueued: "01-01-2018", page: "1", refNumber: "236455541" },
            {id:"1", status: "Sent", fileName: "Sample_fax2", subject: "Sample Subject2", toTextNumber: "1-222-333-442", dateQueued: "01-01-2019", page: "2", refNumber: "236455542" },
            {id:"1", status: "Sent", fileName: "Sample_fax3", subject: "Sample Subject3", toTextNumber: "1-222-333-443", dateQueued: "01-01-2020", page: "3", refNumber: "236455543" },
            {id:"1", status: "Sent", fileName: "Sample_fax4", subject: "Sample Subject4", toTextNumber: "1-222-333-444", dateQueued: "01-01-2021", page: "4", refNumber: "236455544" },
            {id:"1", status: "Sent", fileName: "Sample_fax5", subject: "Sample Subject5", toTextNumber: "1-222-333-445", dateQueued: "01-01-2022", page: "5", refNumber: "236455545" },
            {id:"1", status: "Sent", fileName: "Sample_fax6", subject: "Sample Subject6", toTextNumber: "1-222-333-446", dateQueued: "01-01-2023", page: "6", refNumber: "236455546" }
        ],
        isShowFaxQueues: true,
        isCoverLetter: true,
        currentCoverLetterFilesObj: []
        }
    },
    computed: {
    },
    methods: {
    handleSendFax () {
    },
    handleCoverLetterFileInput () {
      this.isCoverLetterUploading = true
      const files = e.target.files
      const tempCurrentFiles = [...this.currentCoverLetterFilesObj]
      if (!files) return
      ([...files]).forEach(file => {
        const fileObj = { file: file }
        tempCurrentFiles.push(fileObj)
      })
      this.currentCoverLetterFilesObj = tempCurrentFiles
    },
    handleClickOnCoverLetterDropBox () {
      this.$refs.fileInput.click()
      this.$refs.fileInput.value = null
    },
    handleCoverLetterFileDrop (e) {
      this.isCoverLetterUploading = true
      const files = e.dataTransfer.files
      const tempCurrentFiles = [...this.currentCoverLetterFilesObj]
      if (!files) return
      ([...files]).forEach(file => {
        const fileObj = { file: file }
        tempCurrentFiles.push(fileObj)
      })
      this.currentCoverLetterFilesObj = tempCurrentFiles
    },
    handleCoverLetterFileDragEnter (e) {
      e = e || e
      e.preventDefault()
      e.stopPropagation()
      this.isCoverLetterUploading = true
    },
    handleCoverLetterFileDragOver (e) {
      e = e || e
      e.preventDefault()
      e.stopPropagation()
      this.isCoverLetterUploading = true
    },
    handleToggleCurrentFaxQueue () {
      this.isShowFaxQueues = !this.isShowFaxQueues
    },
    handleCurrentFaxQueuePageChange (value) {
      this.currentfaxQueuePage = value
      const userCookieData = this.$cookies.get('user')
      if (userCookieData != null && userCookieData.accessToken != null) {
        this.getClaimBatchBeingWorked(userCookieData)
      }
      // set search list scroll to top
      this.$vuetify.goTo(0, { container: '.claimScrollingBox' })
    },
    handleCurrentFaxQueuePageSizeChange (size) {
      this.currentFaxQueuePageSize = size
      setTimeout(() => {
        const userCookieData = this.$cookies.get('user')
        if (userCookieData != null && userCookieData.accessToken != null) {
          this.getClaimBatchBeingWorked(userCookieData)
        }
      }, 200)
    }
},
mounted () {
}
};
</script>        
<style>
  .inter-header{
      font-size: 30px;
      color: rgba(0,0,0,0.87);
      letter-spacing: 0;
      line-height: 56px;
      text-align: center;
      margin-top: 20px;
   } 
   .info-section-container {
        border: 2px solid #9b9a9a;
        opacity: 21;
    }
    .info-section-legend-container-home{
      margin-top: -15px;
      margin-left: 56px;
      position: absolute;
    }
    .info-section-legend-title-home{
      background-color: #000000;
      color: white;
      height: 30px;
      text-align: center;
      padding: 3px 106px;
      white-space: nowrap;
    }
    .info-section-data-container{
      margin:20px 0px 20px 20px;
    }
    .info-section-data-container-ag-grid{
      /* margin: -2rem 0px 0px 12px; */
      margin: 20px 0px 20px 0px;
    }
    .info-section-data-container > .row + .row{
      margin:0px;
    }
    .ag-theme-alpine .ag-header-row{
      font-weight: 400;
      color: var(--ag-header-foreground-color);
    }
    .info-section-container .v-input.v-text-field label.v-label {
        background: #f0f0f0;
    }
    .ag-theme-alpine {
    --ag-borders: none;
    }
    .upload-area{
        width: 100%;
        align-items: center;
        height: 143px;
        border: 2px dashed #2196f3;
        border-radius: 6px;
        text-align: center;
        overflow: hidden;
        background: #ffffff;
        height: 175px;
    }
    .uploadMessage {
        text-align: center;
        font-weight: normal;
        line-height: 1.5em;
        font-size: 18px;
        margin: 1.2em;
        color: darkslategray;
    }
    .visibility-hidden{
        visibility: hidden;
    }
    .btn-center{
        text-align: center;  
    }
    .ag-theme-alpine .mdi-dots-vertical::before {
        content: "\F01D9";
        margin-top: 43px;
    }
    .v-list-item-title{
        margin-top: 10px;
    }
</style>