<template>
  <v-container fluid>
    <v-row> 
      <div class="pd-0 width-8-percent">
        <v-col cols="12" class="side-tab" :class="{'side-tab-review':selectisReview}" v-if="isShowNav">
          <v-tabs  direction="vertical"  centered
            stacked icons-and-text @update:model-value="changeMainTab">
            <v-tab>
              <v-menu
                location="end"
                v-model="isSelected">
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <div v-if="sideTabValue === 7">
                      <v-fab-transition>
                        <!-- <v-btn color="blue" dark fab>
                          <v-icon color="white">
                            mdi-checkbox-blank-outline</v-icon
                          >
                        </v-btn> -->
                        <v-btn icon="mdi-checkbox-blank-outline" color="blue"></v-btn>
                      </v-fab-transition>
                    </div>
                    <div v-else>
                      <div v-if="isSelected">
                        <v-fab-transition>
                          <v-btn icon="mdi-check" color="light-green"></v-btn>
                        </v-fab-transition>
                      </div>
                      <div v-else>
                        <v-fab-transition
                          v-if="documentStatus === 'Complete' || selectdocumentStatus === 'Complete' || (selectisReview && selectdocumentStatus === 'Reviewer')"
                        >
                        <v-btn icon="mdi-check" color="light-green"></v-btn>
                        </v-fab-transition>
                        <v-fab-transition
                          v-else-if="documentStatus === 'Draft' || selectdocumentStatus === 'Draft' || (selectisReview && selectdocumentStatus === 'NotReviewer')"
                        >
                        <v-btn icon="mdi-checkbox-blank-outline" color="blue"></v-btn>
                        </v-fab-transition>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-if="sideTabValue !== 7">
                  <v-list class="background-none">
                    <v-list-item>
                      <div @click="handleDocumentReviewChange(0)"  v-if="selectisReview">
                        <v-fab-transition>
                          <v-btn color="black" icon="mdi-checkbox-blank-outline"></v-btn>
                        </v-fab-transition>
                        <v-btn class="mg-left-10" color="black"
                          ><span class="font-color-light" >NOT REVIEWED</span></v-btn>
                      </div>
                      <div @click="handleSectionStatus(0)"  v-else>
                        <v-fab-transition>
                          <v-btn color="black" icon="mdi-checkbox-blank-outline"></v-btn>
                        </v-fab-transition>
                        <v-btn class="mg-left-10" color="black">
                          <span class="font-color-light">DRAFT</span>
                        </v-btn>
                      </div>
                    </v-list-item>
                    <v-list-item>
                      <div @click="handleDocumentReviewChange(1)" v-if="selectisReview">
                        <v-fab-transition>
                          <v-btn color="black" icon="mdi-check"></v-btn>
                        </v-fab-transition>
                        <v-btn class="mg-left-10" color="black"
                          ><span class="font-color-light" >REVIEWED</span></v-btn
                        >
                      </div>
                      <div @click="handleSectionStatus(1)" v-else>
                        <v-fab-transition>
                          <v-btn color="black" icon="mdi-check"></v-btn>
                        </v-fab-transition>
                        <v-btn class="mg-left-10" color="black"
                          ><span class="font-color-light">COMPLETE</span></v-btn
                        >
                      </div>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </v-tab>
            <v-tab class="pd-0 mg-left-10">
              <v-icon v-if="sideTabValue == 1" size="30" color="blue">
                mdi-email
              </v-icon>
              <v-icon v-else size="30" color="black"> mdi-email </v-icon>
              <div class="v-title">Contact</div>
            </v-tab>
            <v-tab class="pd-0 mg-left-15">
              <v-icon v-if="sideTabValue == 2" size="30" color="blue">
                mdi-clipboard-check
              </v-icon>
              <v-icon v-else size="30" color="black">
                mdi-clipboard-check
              </v-icon>
              <div class="v-title">To-Do</div>
            </v-tab>
            <v-tab class="pd-0 mg-left-15">
              <v-icon v-if="sideTabValue == 3" size="30" color="blue">
                mdi-pill-multiple
              </v-icon>
              <v-icon v-else size="30" color="black">
                mdi-pill-multiple
              </v-icon>
              <div class="v-title">Meds</div>
            </v-tab>
            <v-tab size="30" color="black" class="pd-0">
              <v-icon v-if="sideTabValue == 4" size="30" color="blue">
                mdi-decagram
              </v-icon>
              <v-icon v-else size="30" color="black"> mdi-decagram </v-icon>
              <div class="v-title">Allergies</div>
            </v-tab>
            <v-tab class="pd-0 mg-left-10">
              <v-icon v-if="sideTabValue == 5" size="30" color="blue">
                mdi-menu
              </v-icon>
              <v-icon v-else size="30" color="black"> mdi-menu </v-icon>
              <div class="v-title">Issues</div>
            </v-tab>
            <v-tab class="pd-0 mg-left-10">
              <v-icon v-if="sideTabValue == 6" size="30" color="blue">
                mdi-help
              </v-icon>
              <v-icon v-else size="30" color="black"> mdi-help </v-icon>
              <div class="v-title">Other</div>
            </v-tab>
            <v-tab v-if="(selectisReview !== null || selectisReview) && appUser?.juniorClinician === false" class="pd-0 mg-left-10">
              <v-icon v-if="sideTabValue == 7" size="30" color="blue">
                mdi-magnify
              </v-icon>
              <v-icon v-else size="30" color="black"> mdi-magnify </v-icon>
              <div class="v-title">Review</div>
            </v-tab>
            <v-tab v-if="selectisReview === null || selectisReview === false" class="pd-0 mg-left-10">
              <v-icon v-if="sideTabValue == 7" size="30" color="blue">
                mdi-magnify
              </v-icon>
              <v-icon v-else size="30" color="black"> mdi-magnify </v-icon>
              <div class="v-title">Review</div>
            </v-tab>
            <v-tab v-if="selectisReview === null || selectisReview === false" class="pd-0 mg-left-5">
              <v-icon v-if="sideTabValue == 8" size="30" color="blue">
                mdi-content-copy
              </v-icon>
              <v-icon v-else size="30" color="black"> mdi-content-copy </v-icon>
              <div class="v-title">MTM Docs</div>
            </v-tab>
          </v-tabs>
        </v-col>
      </div>
      <div class="width-90-percent">
        <div v-if="sideTabValue === 0 && (selectisReview === null || selectisReview === false)" >
          <MTMMain @showSideNav="showSideNavBar" />
        </div>
        <div v-if="sideTabValue === 1">
          <Contact />
        </div>
        <div v-if="sideTabValue === 2">
          <ToDo />
        </div>
        <div v-if="sideTabValue === 3">
          <Meds />
        </div>
        <div v-if="sideTabValue === 4">
          <Allergies />
        </div>
        <div v-if="sideTabValue === 5">
          <Issues />
        </div>
        <div v-if="sideTabValue === 6">
          <Other />
        </div>
        <div >
          <KeepAlive>
            <component :is="review" v-if="sideTabValue === 7"></component>
          </KeepAlive>
        </div>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import MTMMain from "./MTMMain.vue";
import Contact from "./Contact.vue";
import Allergies from "./Allergies.vue";
import Issues from "./Issues.vue";
import Other from "./Other.vue";
import ToDo from "./ToDo.vue";
import Meds from "./Meds.vue";
import Review from "./Review.vue";
import store from '../store';
import axios from 'axios' ;
export default {
  name: "SideNavBar",
  components: {
    MTMMain,
    Contact,
    Allergies,
    Issues,
    Other,
    ToDo,
    Meds,
    Review
  },
  data() {
    return {
      sideTabValue: 0,
      isShowNav: false,
      ischeckingMainOnly: 0,
      documentStatus: undefined,
      clientData: null,
      isSelected: false,
      review: "Review"
    }
  },
  computed: {
    selectisReview() {
      return store.getters.selectisReview
    },
    selectdocumentStatus() {
      return store.getters.selectdocumentStatus;
    },
    selectdocumentId() {
      return store.getters.selectdocumentId;
    },
    selectIsSideTab() {
      return store.getters.isSideTabe;
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    appUser() {
      return store.getters.appUser;
    },
    selectReviewerId() {
      return store.getters.selectReviewerId;
    }
  },
  methods: {
    showSideNavBar (checkingOnlyMain, status) {
      this.documentStatus = status
      store.dispatch("setSelectDocumenStatus", status);
      this.isShowNav = true;
      this.sideTabValue = checkingOnlyMain;
    },
    changeMainTab (value){
      if(value===8 && this.ischeckingMainOnly === 0){
        this.sideTabValue = 0
        this.isShowNav = false
      } else if(value !== 0) {
        this.sideTabValue = value
        this.isShowNav = true
      }
      this.ischeckingMainOnly = 0
    },
    handleSectionStatus (value) {
      let statusValue = "";
      if(value === 1){
        statusValue = 'Complete'
      } else if(value === 0) {
        statusValue = 'Draft'
      }
      this.clientData = this.selectedClient;
      if(this.selectdocumentStatus !== statusValue) {
        store.dispatch("showLoadingSpinner");
        this.changeSectionStatus(value)
      }
    },
    changeSectionStatus(status) {
      axios
      .post('/mtm/document/updateSectionStatus?clientId='+this.clientData.clientId+'&documentId='+this.selectdocumentId+'&sectionStatus='+status+'&sectionType='+this.sideTabValue)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            if(status == '1') {
              store.dispatch("setSelectDocumenStatus", "Complete");
            } else {
              store.dispatch("setSelectDocumenStatus", "Draft");
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
      })
    },
    handleDocumentReviewChange(status) {
      this.clientData = this.selectedClient;
      if(this.clientData.clientId !== null && this.memberId !== null){
        this.getDocumentReviewStatusChange (this.clientData.clientId, status)
      }
  },
  getDocumentReviewStatusChange (clientId, status) {
    axios
      .post('/mtm/review/updateSectionStatus?clientId=' + clientId + '&reviewerId='+this.selectReviewerId +'&sectionStatus='+status + '&sectionType='+this.sideTabValue)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(status == '1') {
              store.dispatch("setSelectDocumenStatus", "Reviewer");
            } else {
              store.dispatch("setSelectDocumenStatus", "NotReviewer");
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    setTabs() {
      this.sideTabValue = 0;
      this.isShowNav = false;
      this.ischeckingMainOnly = 0;
      this.documentStatus = undefined;
      store.dispatch("setSelectDocumenStatus", this.documentStatus);
    },
    resetTabs() {
      this.sideTabValue = -1;
      this.isShowNav = false;
      this.ischeckingMainOnly = -1;
      this.documentStatus = undefined;
      store.dispatch("setSelectDocumenStatus", this.documentStatus);
    },
  },
  mounted () { 
    if(this.selectisReview !== null && this.selectisReview){
      this.isShowNav = true
      this.sideTabValue = 1;
    }
  }
};
</script>
<style>
.v-slide-group--vertical .v-tab {
  justify-content: start;
  height: 70px!important;
}
.mdi-check::before {
  content: "\F012C";
  color: white
}
</style>
