<template>
  <v-container fluid>
    <div>
    <!-- Header -->
    <Header/>
    <!-- Header End -->
    <!-- Body -->
    <div id="userInformations">
    <div class="page-header">
      <h1 class="mg-left-6rem">{{ "MemberFirst User Info" }}</h1>
    </div>
    <div class="mg-left-6rem mg-top-10 mg-bottom-20" v-if="appUser.user !== null">
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Old Password"
            variant="outlined"
            density="comfortable"
            v-model="oldPassword"
            @input="handleOldPassword"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="New Password"
            v-model="newPassword"
            @input="handleNewPassword"
            variant="outlined"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Re-Type New Password"
            v-model="reTypePassword"
            @input="handleReTypePass"
            variant="outlined"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>
       <v-btn
         @click="handleChangeUserPassword"
          depressed
          color="light-green"
          class="mg-top-10 pd-10"
          >
          <v-icon left size="15" color="#ffffff"> mdi-check </v-icon>
          <span class="font-color-light">Submit</span>
        </v-btn>
        <v-btn
          @click="handleUserPassCancel"
          variant="outlined"
          density="comfortable"
          class="mg-left-5" style="height: 38px; margin-top: 11px;">Cancel
        </v-btn>
    </div>
  </div>
    <!-- Body End -->
    <!--Footer  -->
     <Footer/>
    <!-- Footer End -->
  </div>
  <div>
    <AlertDialogs
      v-if="isHideShowAlertDialog"
      :alert-type="alertType"
      :alert-message="alertMessage"
      @closeDialog="closeAlertDialog"
    />
  </div>
  </v-container>
</template>
  <script>
  import store from "@/store";
  import Header from "@/components/Header.vue"
  import AlertDialogs from "@/components/dialogs/AlertDialogs.vue"
  import Footer from "@/components/Footer.vue"
  import axios from "axios";
  export default {
    name: "ChangePassword",
    components: {
      Header,
      Footer,
      AlertDialogs
    },
    data() {
    return {
      isUserEdit: true,
      oldPassword: '',
      newPassword: '',
      reTypePassword: '',
      isHideShowAlertDialog: false,
      alertType: "",
      alertMessage: ""
    };
  },
  computed: {
   appUser() {
     return store.getters.appUser;
   }
  },
  methods: {
    handleUserPassCancel() {
      this.$router.push({ name: 'MyProfile'})  
    },
    closeAlertDialog () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
    },
    handleUserEdit(){
      this.isUserEdit = false
    },
    handleOldPassword(value){
      this.oldPassword = value.target.value
    },
    handleNewPassword(value){
      this.newPassword = value.target.value
    },
    handleReTypePass(value){
      this.reTypePassword = value.target.value
    },
    handleChangeUserPassword() {
      const userPassObj = {
        userId: this.appUser.user.userId,
        username: this.appUser.user.username,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        retypePassword: this.reTypePassword,
      }
      this.changeUserPassword(userPassObj)
    },
    changeUserPassword(userPassObj) {
      axios
      .post('/user/changePassword', userPassObj)
      .then(response => {
        if (response.status === 200) {
          this.changePasswordFieldReset()
          if (response.data.code === 201) {
            this.isUserEdit = true
            //store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          } else if (response.data.code === 401) {
            //store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          //store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        //store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    changePasswordFieldReset () {
      this.oldPassword = ""
      this.newPassword = ""
      this.reTypePassword = ""
    }
  },
  mounted () {
  }
  };
  </script>
  <style>
  #userInformations{
    position: relative;
    top: 100px;
    height: 112vh;
    min-height: 800px;
  }
  </style>
  