<template>
  <v-container fluid>
    <div>
      <v-col cols="11">
        <div class="header-title">MTM Contact Info</div>
        <div class="contact-sub-title">
          The purpose of the cover letter(CL) is to remind the beneficiary of
          what occurred during the Comprehensive Medical Review, introduce the
          TO-Do List and Personal Medication List, and describe how the
          beneficiary can contact the MTM provider and /or plan. The information
          below shall be used to create necessary information for the cover
          letter for the MTM.
        </div>
        <v-row v-if="selectisReview">
          <v-col cols="12">
            <div class="review-cart-bg-color">
              <div class="header-quality-control">Quality Control Review</div>
              <v-row class="mg-top-5">
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="9" class="text-align-inherit fs-14">{{ "Submitted for Review date:" }}</v-col>
                  </v-row>
               </v-col>
                <v-col cols="6" class="text-align-inherit fs-14" v-if="smtForReviewDate !== null">{{ smtForReviewDate }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="9" class="qty-review-title fs-14">{{ "Clinician:" }}</v-col>
                  </v-row>
               </v-col>
                <v-col cols="6" class="qty-review-title fs-14">{{ "DR. "+selectClinicianName }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="9" class="qty-review-title fs-14">{{ "Member:" }}</v-col>
                  </v-row>
               </v-col>
                <v-col cols="6" class="qty-review-title fs-14">{{ memberName }}</v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <div v-if="(selectisReview !== null || selectisReview) && contactInfoData !== undefined">
        <v-row class="mg-top-10 mg-left-10 pd-unset" v-if="!isShowReviewComment">
          <v-col cols="9" ></v-col>
          <v-col cols="3" class="mouse-cursor" @click="handleReviewerComment">
            <v-row>
              <v-icon size="23" class="blue-color"> mdi-plus </v-icon>
              <div class="primary-color fs-15">ADD REVIEWER COMMENT</div>
            </v-row>
          </v-col>
      </v-row>
      </div>
      <v-row class="mg-top-20" v-if="isShowReviewComment">
        <v-col cols="12">
          <v-textarea
            variant="outlined"
            density="comfortable"
            class="mx-2"
            v-model="commentReview"
            @input="changeCommentReview"
            rows="4"
            label="QC Reviewer Comment"
            :readonly="!selectisReview"
          />
        </v-col>
      </v-row>
      <v-row class="mg-top-10 mg-right-10 pd-unset" v-if="(selectisReview !== null || selectisReview) && isShowReviewComment">
        <v-col cols="8"></v-col>
          <v-col cols="4" class="mouse-cursor">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="handleCancelReviewComment" v-if="isShowReviewBtn === false">CANCEL</v-btn>
              <v-btn variant="text" @click="handleClearReviewComment" v-if="isShowReviewBtn === false">CLEAR</v-btn>
              <v-btn variant="text" color="error" @click="handleDeleteReviewComment" v-if="isShowReviewBtn">DELETE</v-btn>
              <v-btn variant="text" color="info" @click="handleAddComment">{{isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn>
            </v-row>
          </v-col>
      </v-row>
      <div v-if="( selectisReview === null || selectisReview === false) && (commentReview !== '' || replyCommentReview !== '')">
        <v-row class="mg-top-10 mg-left-3rem pd-unset flex-nowrap">
          <v-col cols="7" ></v-col>
          <v-col cols="4" class="mouse-cursor" @click="handleReplyReviewerComment">
            <v-row>
              <v-icon size="23" class="blue-color pd-unset"> mdi-plus </v-icon>
              <div class="primary-color fs-15">ADD REPLY FOR THE ABOVE COMMENT</div>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-row class="mg-top-20" v-if="isShowReplyReviewComment">
        <v-col cols="12">
          <v-textarea
            variant="outlined"
            density="comfortable"
            class="mx-2"
            v-model="replyCommentReview"
            @input="changeReplyCommentReview"
            rows="4"
            label="Reply For QC Reviewer Comment"
            :readonly="selectisReview"
          />
        </v-col>
      </v-row>
      <v-row class="mg-top-10 mg-right-10 pd-unset" v-if="( selectisReview === null || selectisReview === false) && isShowReplyReviewComment">
        <v-col cols="8"></v-col>
          <v-col cols="4" class="mouse-cursor">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn variant="text" @click="handleCancelReplyReviewComment" v-if="isShowReviewReplyBtn === false">CANCEL</v-btn>
              <v-btn variant="text" @click="handleClearReplyReviewComment" v-if="isShowReviewReplyBtn === false">CLEAR</v-btn>
              <v-btn variant="text" color="error" @click="handleDeleteReplyReviewComment" v-if="isShowReviewReplyBtn">DELETE</v-btn>
              <v-btn variant="text" color="info" @click="handleAddReplyComment">{{isShowReviewReplyBtn ? "UPDATE" : "SAVE" }}</v-btn>
            </v-row>
          </v-col>
      </v-row>
        <v-row class="font-weight-black mg-top-20" v-if=" selectisReview === null || selectisReview === false">
          <v-col cols="2">
            <p class="font-weight-black font-size-13">{{"Member Information"}}</p>
          </v-col>
          <v-col cols="8">
           <v-divider class="mg-top-10"></v-divider>
          </v-col>
          <v-col cols="2">
          </v-col>
        </v-row>
        <v-row class="mg-top-10">
          <v-col cols="3">
            <v-text-field
              label="Member Name"
              :disabled="selectisReview"
              variant="outlined"
              density="comfortable"
              v-model="memberName"
              :error-messages="memberNameError.errorMessage"
              :error="memberNameError.isError"
             @input="changeMemberName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mg-top-5">
          <v-col cols="3">
            <v-text-field
              label="Member Address 1"
              @input="changeMemberAddress1"
              v-model="memberAddress1"
              :disabled="selectisReview"
              :error-messages="memberAddress1Error.errorMessage"
              :error="memberAddress1Error.isError"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mg-top-5">
          <v-col cols="3">
            <v-text-field
              @input="changeMemberAddress2"
              label="Member Address 2"
              v-model="memberAddress2"
              :disabled="selectisReview"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mg-top-5">
          <v-col cols="3">
            <v-text-field
             @input="changeMemberCity"
              v-model="memberCity"
              label="Member City"
              :disabled="selectisReview"
              :error-messages="memberCityError.errorMessage"
              :error="memberCityError.isError"
              variant="outlined"
              density="comfortable"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="usStateFormatsList"
              @update:modelValue="changeMemberTexas"
              item-value="abbreviation"
              v-model="memberState"
              hide-details="auto"
              density="comfortable"
              :disabled="selectisReview"
              :error-messages="memberStateError.errorMessage"
              :error="memberStateError.isError"
              item-title="name"
              variant="solo"
              label="State"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Zip Code"
              :error-messages="zipCodeError.errorMessage"
              :error="zipCodeError.isError"
              v-model="zipCode"
              :disabled="selectisReview"
              @input="changeZipCode"
              variant="outlined"
              density="comfortable"
            />
          </v-col>
        </v-row>
        <v-row class="font-weight-black mg-top-6rem" v-if="selectisReview === false || selectisReview === null">
          <v-col cols="2">
            <p class="font-weight-black font-size-13 mg-left-10">{{"Encounter Information"}}</p>
          </v-col>
          <v-col cols="8">
           <v-divider class="mg-top-10"></v-divider>
          </v-col>
          <v-col cols="2">
          </v-col>
        </v-row>
        <v-row class="mg-top-5">
          <v-col cols="3">
            <VueDatePicker 
              v-model="encounterDateSelected"
              :disabled="selectisReview"
              :class="{'dp__input_icons2 dp__disabled':selectisReview}"
              class="mg-left-10"
              auto-apply 
              :enable-time-picker="false" 
              placeholder="Date" format="MM/dd/yyyy">
            </VueDatePicker>
            <p v-if="encounterDateError.isError" style="font-size: 14px; color: #a50e0e; margin-left: 12px;">{{ encounterDateError.errorMessage }}</p>
           </v-col>
          <v-col cols="2">
            <p class="font-weight-black mg-top-20 sub-header-encounter" v-if="selectisReview === false || selectisReview === null">{{"Encounter Type"}}</p>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Encounter Type"
              :items="encounterTypeList"
              item-value="id"
              item-title="newEncounterDescription"
              density="comfortable"
              variant="solo"
              v-model="encounterId"
              :disabled="selectisReview"
              :error-messages="encounterTypeError.errorMessage"
              :error="encounterTypeError.isError"
              @update:modelValue="changeEncounterType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="font-weight-black mg-top-6rem" v-if="selectisReview === false || selectisReview === null">
          <v-col cols="2">
            <p class="font-weight-black font-size-13">{{"MTM Clinician Information"}}</p>
          </v-col>
          <v-col cols="8">
           <v-divider class="mg-top-10"></v-divider>
          </v-col>
          <v-col cols="2">
          </v-col>
        </v-row>
        <div class="mg-top-10">
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="Clinician Contact Text"
                hint="This field uses maxlength attribute"
                v-model="providerContactText"
                :disabled="selectisReview"
                :error-messages="providerContactTextError.errorMessage"
                :error="providerContactTextError.isError"
                @input="changeProviderContactText"
                variant="outlined"
                density="comfortable"
              ></v-text-field>
            </v-col>
            <v-col cols="6" v-if="selectisReview === false || selectisReview === null">
              <div class="contact-sub-title">
               For example, enter: "If you like to discuss these documents, please call at between 8am and 4pm central M-F."
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="mg-top-5">
          <v-col cols="3">
            <v-text-field
              label="Clinician Salutation Name"
              variant="outlined"
              density="comfortable"
              v-model="providerSalutationName"
              :error-messages="salutionError.errorMessage"
              :error="salutionError.isError"
              :disabled="selectisReview"
              @input="changeProviderSalutationName"
              hint="Name used in salutation of cover letter."
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              label="Clinician Salutation Title"
              variant="outlined"
              density="comfortable"
              v-model="providerSalutationTitle"
              :disabled="selectisReview"
              :error-messages="providerSalutionError.errorMessage"
              :error="providerSalutionError.isError"
              @input="changeProviderSalutationTitle"
              hint="Text used your in the salutation."
            ></v-text-field>
          </v-col>
          <v-col cols="3" v-if="selectisReview === false || selectisReview === null">
          <div v-if="isUpdate">
            <v-btn @click="handleAddContact" color="blue" rounded="pill" class="mg-top-10">
              <span class="font-color-light">Update</span>
            </v-btn>
          </div>
          <div v-else>
            <v-btn @click="handleAddContact" color="blue" rounded="pill" class="mg-top-10">
              <span class="font-color-light">Submit</span>
            </v-btn>
          </div>
          </v-col>
        </v-row>
        <div v-if="selectisReview !== null || selectisReview">
        <div class="mg-top-10 txt-align-center fs-25 font-weight-400">Document to Review</div>
        <div class="mg-top-10">
            <iframe id="pdf" :src="url" frameborder="1" scrolling="auto" width="100%" height="600px" class="pdf-viewer"></iframe>
        </div>
      </div>
        <v-row></v-row>
      </v-col>
    </div>
    <div>
      <AlertDialogs
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        @closeDialog="closeAlertDialog"
      />
      <v-snackbar v-model="snackbar" v-bind:color="snackColor" :timeout="snackTimeout">
        <span :class="{ 'black--text': snackColor== '#CDDC39', 'white--text': snackColor== '#DD2C00' }" class=""> {{snackStatus}} </span>
      </v-snackbar>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import interceptorSetup from '../helpers/httpInterceptor'
import store from '../store'
import { usStateFormats } from '../utils/globalConstants'
export default {
  name: "MtmContactUs",
  components: {
    AlertDialogs,
    VueDatePicker
  },
  data() {
    return {
        contactInfoId: null,
        memberName: '',
        memberNameError: { isError: false, errorMessage: '' },
        memberAddress1: '',
        memberAddress1Error: { isError: false, errorMessage: '' },
        memberAddress2: '',
        memberCity: '',
        memberCityError: { isError: false, errorMessage: '' },
        memberState: '',
        memberStateError: { isError: false, errorMessage: '' },
        zipCode: '',
        zipCodeError: { isError: false, errorMessage: '' },
        providerContactText: '',
        providerContactTextError: { isError: false, errorMessage: '' },
        providerSalutationName: '',
        salutionError: { isError: false, errorMessage: '' },
        providerSalutationTitle: '',
        providerSalutionError: { isError: false, errorMessage: '' },
        encounterId: '',
        encounterDescription: '',
        encounterTypeError: { isError: false, errorMessage: '' },
        alertType: '',
        alertMessage: '',
        isHideShowAlertDialog: false,
        clientData: null,
        memberId: null,
        documentId: null,
        dateMenuEncounterDateSlot: false,
        encounterDateSelected: null,
        memberDetails: undefined,
        encounterDateError: { isError: false, errorMessage: '' },
        encounterTypeList: undefined,
        usStateFormatsList: [],
        isUpdate: false,
        snackbar: false,
        snackStatus: null,
        snackColor: "black",
        interval: null,
        snackTimeout: "20000",
        autoSaveTimeout: "30000",
        errorTimout: null,
        autoSaveValidation: false,
        isShowReviewComment: false,
        isShowReplyReviewComment: false,
        url: null,
        commentReview: "",
        replyCommentReview: "",
        remark: "",
        isShowReviewBtn: false,
        isShowReviewReplyBtn: false,
        smtForReviewDate: null,
        contactInfoData: undefined
      }
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.errorTimout);
  },
  computed: {
    selectedMemberId () {
      return store.getters.selectedMemberId
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    selectdocumentId() {
      return store.getters.selectdocumentId;
    },
    memberDetail () {
      return store.getters.memberDetail;
    },
    selectisReview() {
      return store.getters.selectisReview
    },
    selectClinicianName() {
      return store.getters.selectClinicianName
    },
    selectReviewerId() {
      return store.getters.selectReviewerId;
    }
  },
  methods: {
    handleAddComment () {
      const clientId = this.clientData.clientId
      const contactInfoObj = {
        clientId: clientId,
        companyId: 1,
        contactText: this.providerContactText,
        documentId: this.documentId,
        memberAddress1: this.memberAddress1,
        memberAddress2: this.memberAddress2,
        memberCity: this.memberCity,
        memberId: this.memberId,
        memberName:  this.memberName,
        memberState: this.memberState,
        memberZip:  this.zipCode,
        practitionerId: 1,
        salutationName: this.providerSalutationName,
        salutationTitle: this.providerSalutationTitle,
        encounterDate: this.encounterDateSelected,
        encounterDescription: this.encounterDescription,
        encounterId: this.encounterId,
        activeStatus: 'active',
        remark: this.commentReview,
        id: this.contactInfoId
      }
      if(clientId !== null && this.memberId !== null ) {
        this.addContactInfo(contactInfoObj, clientId);
      }
    },
    handleAddReplyComment () {
      const clientId = this.clientData.clientId
      const contactInfoObj = {
        clientId: clientId,
        companyId: 1,
        contactText: this.providerContactText,
        documentId: this.documentId,
        memberAddress1: this.memberAddress1,
        memberAddress2: this.memberAddress2,
        memberCity: this.memberCity,
        memberId: this.memberId,
        memberName:  this.memberName,
        memberState: this.memberState,
        memberZip:  this.zipCode,
        practitionerId: 1,
        salutationName: this.providerSalutationName,
        salutationTitle: this.providerSalutationTitle,
        encounterDate: this.encounterDateSelected,
        encounterDescription: this.encounterDescription,
        encounterId: this.encounterId,
        activeStatus: 'active',
        remark: this.commentReview,
        remarkComment: this.replyCommentReview,
        id: this.contactInfoId
      }
      if(clientId !== null && this.memberId !== null ) {
        this.addContactInfo(contactInfoObj, clientId);
      }
    },
    handleDeleteReplyReviewComment (){
      this.isShowReplyReviewComment = false
      const clientId = this.clientData.clientId
      const contactInfoObj = {
        clientId: clientId,
        companyId: 1,
        contactText: this.providerContactText,
        documentId: this.documentId,
        memberAddress1: this.memberAddress1,
        memberAddress2: this.memberAddress2,
        memberCity: this.memberCity,
        memberId: this.memberId,
        memberName:  this.memberName,
        memberState: this.memberState,
        memberZip:  this.zipCode,
        practitionerId: 1,
        salutationName: this.providerSalutationName,
        salutationTitle: this.providerSalutationTitle,
        encounterDate: this.encounterDateSelected,
        encounterDescription: this.encounterDescription,
        encounterId: this.encounterId,
        activeStatus: 'active',
        remark: this.commentReview,
        remarkComment: null,
        id: this.contactInfoId
      }
      if(clientId !== null && this.memberId!== null ) {
        this.addContactInfo(contactInfoObj, clientId);
      }
    },
    handleDeleteReviewComment() {
      this.isShowReviewComment = false
      const clientId = this.clientData.clientId
      const contactInfoObj = {
        clientId: clientId,
        companyId: 1,
        contactText: this.providerContactText,
        documentId: this.documentId,
        memberAddress1: this.memberAddress1,
        memberAddress2: this.memberAddress2,
        memberCity: this.memberCity,
        memberId: this.memberId,
        memberName:  this.memberName,
        memberState: this.memberState,
        memberZip:  this.zipCode,
        practitionerId: 1,
        salutationName: this.providerSalutationName,
        salutationTitle: this.providerSalutationTitle,
        encounterDate: this.encounterDateSelected,
        encounterDescription: this.encounterDescription,
        encounterId: this.encounterId,
        activeStatus: 'active',
        remark: null,
        remarkComment: null,
        id: this.contactInfoId
      }
      if(clientId !== null && this.memberId!== null ) {
        this.addContactInfo(contactInfoObj, clientId);
      }
    },
    changeCommentReview(value) {
      this.commentReview = value.target.value
    },
    changeReplyCommentReview(value) {
      this.replyCommentReview = value.target.value
    },
    handleClearReviewComment () {
      this.commentReview = ""
    },
    handleClearReplyReviewComment () {
      this.replyCommentReview = ""
    },
    handleCancelReviewComment () {
      this.isShowReviewComment = false
    },
    handleCancelReplyReviewComment () {
      this.isShowReplyReviewComment = false
    },
    closeAlertDialog () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      if(this.pageReload){
        if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null){
          store.dispatch("showLoadingSpinner");
          this.getContactInfo(this.clientData.clientId, this.memberId, this.documentId)
          this.getEncodeType()
       }
      }
    },
    changeMemberName(value){
      this.memberName = value.target.value
      this.memberNameError.isError = false
      this.memberNameError.errorMessage = ''
    },
    changeMemberAddress1(value){
      this.memberAddress1 = value.target.value
      this.memberAddress1Error.isError = false
      this.memberAddress1Error.errorMessage = ''
    },
    changeMemberAddress2(value){
      this.memberAddress2 = value.target.value
    },
    changeMemberCity(value){
      this.memberCity = value.target.value
      this.memberCityError.isError = false
      this.memberCityError.errorMessage = ''
    },
    changeMemberTexas(value){
      this.memberState = value
      this.memberStateError.isError = false
      this.memberStateError.errorMessage = ''
    },
    changeZipCode(value){
      this.zipCode = value.target.value
      this.zipCodeError.isError = false
      this.zipCodeError.errorMessage = ''
    },
    changeProviderContactText(value){
      this.providerContactText = value.target.value
      this.providerContactTextError.isError = false
      this.providerContactTextError.errorMessage = ''
    },
    changeProviderSalutationName(value){
        this.providerSalutationName = value.target.value
        this.salutionError.isError = false
        this.salutionError.errorMessage = ''
    },
    changeProviderSalutationTitle(value){
      this.providerSalutationTitle = value.target.value
      this.providerSalutionError.isError = false
      this.providerSalutionError.errorMessage = ''
    },
    changeEncounterType(value){
      this.encounterTypeList.some(items => {
        if(value === items.id){
          this.encounterDescription = items.codeValue +" - "+ items.shortDesc
        } 
      });
      this.encounterId = value
      this.encounterTypeError.isError = false
      this.encounterTypeError.errorMessage = ''
    },
    handleAddContact () {
      let isValid = true
      if (this.memberName === '') {
        this.memberNameError.isError = true
        this.memberNameError.errorMessage = 'You must enter the Member name.'
        isValid = false
      }
      if (this.memberAddress1 === '') {
        this.memberAddress1Error.isError = true
        this.memberAddress1Error.errorMessage = 'You must enter the Member Address1.'
        isValid = false
      }
      if (this.memberCity === '') {
        this.memberCityError.isError = true
        this.memberCityError.errorMessage = 'You must enter the Member City.'
        isValid = false
      }
      if (this.zipCode === '') {
        this.zipCodeError.isError = true
        this.zipCodeError.errorMessage = 'You must enter the Zip Code.'
        isValid = false
      }
      if (this.memberState === '') {
        this.memberStateError.isError = true
        this.memberStateError.errorMessage = 'You must enter member State.'
        isValid = false
      }
      if (this.zipCode.length > 5) {
        this.zipCodeError.isError = true
        this.zipCodeError.errorMessage = 'You should only use 5 digits numbers.'
        isValid = false
      }
      if (this.providerContactText === '') {
        this.providerContactTextError.isError = true
        this.providerContactTextError.errorMessage = 'You must enter clinician contact.'
        isValid = false
      }
      if (this.providerSalutationName === '') {
        this.salutionError.isError = true
        this.salutionError.errorMessage = 'You must enter clinician salutation name.'
        isValid = false
      }
      if (this.providerSalutationTitle === '') {
        this.providerSalutionError.isError = true
        this.providerSalutionError.errorMessage = 'You must enter clinician salutation title.'
        isValid = false
      }
      if (this.encounterDateSelected === '' || this.encounterDateSelected === null) {
        this.encounterDateError.isError = true
        this.encounterDateError.errorMessage = 'You must select Encounter Date.'
        isValid = false
      }
      if (this.encounterId === '') {
        this.encounterTypeError.isError = true
        this.encounterTypeError.errorMessage = 'You must enter Encounter Type.'
        isValid = false
      }
      const clientData = this.selectedClient
      const memberId = this.selectedMemberId
      const documentId = this.selectdocumentId
      if(isValid) {
        if(clientData.clientId !== null && memberId !== null && documentId !== null){
          this.handleContactInfoFormAddSet(clientData, memberId, documentId)
        }
      } else if(this.autoSaveValidation) {
        this.autoSaveStatus(false);
      }
    },
    handleContactInfoFormAddSet (clientData, memberId, documentId) {
      const contactInfoForm = {
        clientId: clientData.clientId,
        companyId: 1,
        contactText: this.providerContactText,
        documentId: documentId,
        memberAddress1: this.memberAddress1,
        memberAddress2: this.memberAddress2,
        memberCity: this.memberCity,
        memberId: memberId,
        memberName:  this.memberName,
        memberState: this.memberState,
        memberZip:  this.zipCode,
        practitionerId: 1,
        salutationName: this.providerSalutationName,
        salutationTitle: this.providerSalutationTitle,
        encounterDate: this.encounterDateSelected,
        encounterDescription: this.encounterDescription,
        encounterId: this.encounterId,
        remark: this.commentReview,
        remarkComment: this.replyCommentReview,
        activeStatus: 'active',
        id: this.contactInfoId
      }
      if(clientData.clientId !== null && memberId!== null ) {
        if(!this.autoSaveValidation) {
          store.dispatch("showLoadingSpinner");
          this.addContactInfo(contactInfoForm, clientData.clientId);
        } else {
          this.saveByInterval(contactInfoForm, clientData.clientId);
        }
        this.autoSaveValidation = false;
      }
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    autoSaveByTwoMin() {
      this.interval = setInterval(() => {
        clearInterval(this.errorTimout);
        this.autoSaveValidation = true;
        this.handleAddContact ();  
      },this.autoSaveTimeout);
    },
    saveByInterval (contactInfoForm, clientId, memberId, documentId) {
      axios
      .post('/mtm/contact/addmtmContactInfo?clientId='+clientId,contactInfoForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.isUpdate= true;
            this.memberNameError.isError = false
            this.memberAddress1Error.isError = false
            this.memberCityError.isError = false
            this.zipCodeError.isError = false
            this.providerContactTextError.isError = false
            this.salutionError.isError = false
            this.providerSalutionError.isError = false
            this.encounterDateError.isError = false
            this.encounterTypeError.isError = false
            this.autoSaveStatus(true);
            this.getContactInfo(this.clientData.clientId, this.memberId, this.documentId);
          } else if (response.data.code === 401) {
            this.autoSaveStatus(false);
          }
        } else {
          this.autoSaveStatus(false);
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false);
      })
    },
    updateContactInfoAutoSave(contactInfoForm, clientId) {
      axios
      .post('/mtm/contact/updatecontactinfo?clientId='+clientId+ "&Id="+this.contactInfoId, contactInfoForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.autoSaveStatus(true);
          } else if (response.data.code === 401) {
            this.autoSaveStatus(false);
          }
        } else {
          this.autoSaveStatus(false);
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false);
      })
    },
    autoSaveStatus(success) {
      if(!success) {
        this.snackStatus = "Autosave Unsuccessful. Trying again in 15s";
          this.snackColor = "#DD2C00";
          this.snackbar = true
          this.autoSaveTimeout = 15000
          clearInterval(this.interval);
          this.autoSaveByTwoMin();
          var seconds = 15;
          this.snackTimeout = 15000;
          this.errorTimout = setInterval(() => {
            this.snackColor = "#DD2C00";
            seconds = seconds - 1;
            if(seconds == 0) {             
              this.snackTimeout = 20000;
              this.snackbar = false;
            }
            this.snackStatus = "Autosave Unsuccessful. Trying again in "+ seconds +"s";
          }, 1000);
      } else {
        this.autoSaveTimeout = 60000
        this.snackStatus = "Autosave Completed at "+ this.formatAMPM(new Date());
        this.snackColor = "#CDDC39";
        this.snackbar = true
      }
    },
    addContactInfo(contactInfoForm, clientId) {
      axios
      .post('/mtm/contact/addmtmContactInfo?clientId='+clientId, contactInfoForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true  
            this.resetData()
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    addReviewerComment(contactInfoForm, clientId) {
      axios
      .post('/mtm/contact/addmtmContactInfo?clientId='+clientId, contactInfoForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true  
            this.resetData()
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    resetData() {
      this.memberState = ""
      this.memberAddress1 = ""
      this.memberAddress2 = ""
      this.memberCity = ""
      this.memberName = ""
      this.memberState = ""
      this.providerSalutationTitle = ""
      this.providerSalutationName = ""
      this.providerContactText = ""
      this.zipCode = ""
      this.encounterDateSelected = ""
      this.encounterType = ""
      this.encounterDateError.isError = false
      this.memberNameError.isError = false
      this.memberAddress1Error.isError = false
      this.memberCityError.isError = false
      this.zipCodeError.isError = false
      this.providerContactTextError.isError = false
      this.salutionError.isError = false
      this.providerSalutionError.isError = false
      this.encounterDateError.isError = false
      this.encounterTypeError.isError = false
    },
    mapResponseWithFormData (contactInfoDataList) {
      if(contactInfoDataList.length > 0) {
        this.isUpdate= true
        this.contactInfoData = contactInfoDataList[0]
        this.contactInfoId = contactInfoDataList[0].id
        this.memberName = contactInfoDataList[0].memberName
        this.memberAddress1 = contactInfoDataList[0].memberAddress1
        this.memberAddress2 = contactInfoDataList[0].memberAddress2
        this.memberCity = contactInfoDataList[0].memberCity
        this.zipCode = contactInfoDataList[0].memberZip
        this.encounterDateSelected = contactInfoDataList[0].encounterDate
        this.encounterDateSelected = contactInfoDataList[0].encounterDate
        this.memberState = contactInfoDataList[0].memberState
        this.encounterId = contactInfoDataList[0].encounterId.toString();
        this.encounterDescription = contactInfoDataList[0].encounterDescription
        this.providerContactText = contactInfoDataList[0].contactText
        this.providerSalutationName = contactInfoDataList[0].salutationName
        this.providerSalutationTitle = contactInfoDataList[0].salutationTitle
        this.commentReview = contactInfoDataList[0].remark != null ? contactInfoDataList[0].remark : ""
        this.replyCommentReview = contactInfoDataList[0].remarkComment != null ? contactInfoDataList[0].remarkComment : ""
        if(this.commentReview === ''){
          this.isShowReviewComment = false
          this.isShowReviewBtn = false
        } else {
          this.isShowReviewComment = true
          this.isShowReviewBtn = true
        } 
        if(this.replyCommentReview === ''){
          this.isShowReplyReviewComment = false
          this.isShowReviewReplyBtn = false
        } else {
          this.isShowReviewReplyBtn = true
          this.isShowReplyReviewComment = true
        } 
      } else {
        this.isUpdate= false
        this.memberName = this.memberDetails.member.firstName +' '+this.memberDetails.member.lastName
        this.memberAddress1 = this.memberDetails.memberAddresses[0].streetAddress != null ? this.memberDetails.memberAddresses[0].streetAddress : ""
        this.memberAddress2 = this.memberDetails.memberAddresses[0].streetAddress2 != null ? this.memberDetails.memberAddresses[0].streetAddress2 : ""
        this.memberCity =  this.memberDetails.memberAddresses[0].city != null ? this.memberDetails.memberAddresses[0].city : ""
        this.memberState =  this.memberDetails.memberAddresses[0].state != null ? this.memberDetails.memberAddresses[0].state : ""
        this.zipCode =  this.memberDetails.memberAddresses[0].zipCode != null ? this.memberDetails.memberAddresses[0].zipCode : ""
      }
    },
    getContactInfo (clientId, memberId, documentId) {
      axios
        .get('/mtm/contact/getContactInfo?clientId='+clientId +'&memberId='+memberId+'&documentId='+documentId)
        .then(response => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              store.dispatch("hideLoadingSpinner");
              const [year, month, day] = response.data.document.displaySentDate.split("-");
              this.smtForReviewDate = `${month}/${day}/${year}`;
              if(this.selectisReview == null || this.selectisReview === false) {
                if(response.data.document?.contactStatus === 1) {
                  store.dispatch("setSelectDocumenStatus", 'Complete');
                } else {
                  store.dispatch("setSelectDocumenStatus", 'Draft');
                }
              }
              this.mapResponseWithFormData(response.data.dataLists)
            } else if (response.data.code === 401) {
              store.dispatch("hideLoadingSpinner");
              this.alertType = 'Failed'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
    },
    getEncodeType () {
      axios
      .get('/mtm/codes/getByCodeType?codeType=EN')
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataLists.length > 0) {
              const tempEncounterTypeList = [...response.data.dataLists]
              tempEncounterTypeList.forEach(items => {
                items.newEncounterDescription = items.codeValue +"-"+ items.shortDesc
              });
              tempEncounterTypeList.unshift({id: "0", newEncounterDescription: "--Please Select--", codeType: "", codeValue: "", shortDesc: "",longDesc: ""})
              this.encounterTypeList = tempEncounterTypeList
            } 
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    getDocReviewSectionStatus (clientId, reviewerId) {
      axios
      .get('/mtm/review?clientId='+clientId +'&reviewerId='+reviewerId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataList?.contactStatus === 1) {
              store.dispatch("setSelectDocumenStatus", 'Reviewer');
            } else {
              store.dispatch("setSelectDocumenStatus", 'NotReviewer');
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    handleContact () {
      this.clientData = this.selectedClient
      this.memberId = this.selectedMemberId
      this.documentId = this.selectdocumentId
      this.memberDetails = this.memberDetail
      store.dispatch("setSelectDocumenStatus", 'Draft');
      const tempUsStateFormatsList = [...usStateFormats]
      tempUsStateFormatsList.unshift({name: "--Please Select--", abbreviation: ""})
      this.usStateFormatsList = tempUsStateFormatsList
      if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null){
        store.dispatch("showLoadingSpinner");
        this.getContactInfo(this.clientData.clientId, this.memberId, this.documentId)
        this.getEncodeType()
        if(this.selectisReview){
          this.getDocReviewSectionStatus(this.clientData.clientId, this.selectReviewerId)
          setTimeout(() => {
            this.getReviewDocument(this.clientData.clientId , this.memberId, this.documentId)
          }, 1500);
        }
    }
    },
    // Review Code
    handleReviewerComment() {
      this.isShowReviewComment = true
    },
    handleReplyReviewerComment() {
      this.isShowReplyReviewComment = true
    },
    getReviewDocument(clientId, memberId, documentId) {
    store.dispatch("showLoadingSpinner");
      axios
      .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
        {responseType: "blob"},
      )
      .then((response) => { 
          // console.log(response);
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            this.url = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    }
  },
  mounted () {
    this.handleContact ();
    interceptorSetup.refreshToken();
    if(this.selectisReview == null || this.selectisReview === false) {
      this.autoSaveByTwoMin();
    } else {
      localStorage.removeItem("selectReview");
    }
  },
  unmounted(){ 
   clearInterval(this.interval)
   clearInterval(this.errorTimout);
  }
};
</script>


