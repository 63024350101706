<template>
  <v-tooltip top>
    <template v-slot:activator="{ props }">
      <span v-bind="props" style="display:inline-block;">
        {{ params.value }}
      </span>
    </template>
    <div>
      {{ getICDDescription(params.value) }}
    </div>
  </v-tooltip>
</template>
<script>
import store from "../../store";
export default {
  name: "renderICD10Cell",
  data() {
    return {
    }
  },
  computed: { 
    dcItems() {
      return store.getters.memberDetail.diagnosisCodes;
    },
  },
  methods: {
    getICDDescription(code) {
      if (code == null || code == "") {
        return null;
      }
      for (var i = 0; i < this.dcItems.length; i++) {
        if (this.dcItems[i].code == code) {
          return this.dcItems[i].description;
        }
      }
      return "unknown";
    }
  }
};

  </script>