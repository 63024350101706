<template>
  <div id="app">
    <v-container fluid>
      <div>
        <v-col cols="12">
          <div class="header-info">MTM Quality Control Queue</div>
        </v-col>
        <div class="mg-top-2rem">
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="11">
              <v-row>
                <v-col cols="4">
                  <div
                    class="status-card mouse-cursor"
                    :class="{ 'status-card2': isNotApproved }"
                    @click="handleStatus('notApproved')"
                  >
                    <v-row>
                      <v-col cols="2">
                        <v-icon
                          color="red"
                          size="45"
                          class="mg-top-20 mg-left-10"
                          >mdi-cancel</v-icon
                        >
                      </v-col>
                      <v-col cols="8">
                        <div class="mg-top-10 mg-left-15">
                          <div class="font-weight-400 p fs-25">
                            Not Approved
                          </div>
                          <div class="fs-28 p">
                            <b>{{notApprovedCount}}</b>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div
                    class="status-card mouse-cursor"
                    :class="{ 'status-card2': isNeedsReview }"
                    @click="handleStatus('needsReview')"
                  >
                    <v-row>
                      <v-col cols="2">
                        <v-icon
                          :color="gray"
                          size="45"
                          class="mg-top-20 mg-left-10"
                          >mdi-timer-sand</v-icon
                        >
                      </v-col>
                      <v-col cols="8">
                        <div class="mg-top-10 mg-left-15">
                          <div class="font-weight-400 p fs-25">
                            Needs Review
                          </div>
                          <div class="fs-28 p">
                            <b>{{needReviewCount}}</b>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div
                    class="status-card mouse-cursor"
                    :class="{ 'status-card2': isApproved }"
                    @click="handleStatus('isApproved')"
                  >
                    <v-row>
                      <v-col cols="2">
                        <v-icon
                          :color="lightGreen"
                          size="45"
                          class="mg-top-20 mg-left-10"
                          >mdi-check-circle</v-icon
                        >
                      </v-col>
                      <v-col cols="8">
                        <div class="mg-top-10 mg-left-15">
                          <div class="font-weight-400 p fs-25">
                            Approved
                          </div>
                          <div class="fs-28 p">
                            <b>{{approvedCount}}</b>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="mg-top-2rem">
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="9" class="mg-left-2rem">
                <v-card>
                  <div>
                    <v-row>
                      <v-col cols="11">
                        <v-card-title>
                          Documents Needing Review
                        </v-card-title>
                      </v-col>
                    </v-row>
                  </div>
                  <v-data-table
                    v-if="isNotApproved"
                    :headers="notApprovedHeader"
                    :items="notApprovedList"
                  >
                    <template v-slot:item.memberName="{ item }">
                      <td class="primary-color mouse-cursor">
                        {{ item.memberName }}
                      </td>
                    </template>
                    <template v-slot:no-data>
                      <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                        <span>No data available.</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-data-table
                    v-if="isNeedsReview"
                    :headers="needReviewHeader"
                    :items="needReviewList"
                  >
                    <template v-slot:item.memberName="{ item }">
                      <td class="primary-color mouse-cursor">
                        {{ item.memberName }}
                      </td>
                    </template>
                    <template v-slot:item.review="{ item }">
                      <v-chip color="blue" class="mouse-cursor" @click="handleMtmDocument(item.id, item.memberId, item.clinician, item.reviewerId)">
                        {{ item.review }}
                      </v-chip>
                    </template>
                    <template v-slot:no-data>
                      <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                        <span>No data available.</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-data-table
                    v-if="isApproved"
                    :headers="approvedHeader"
                    :items="approvedList"
                  >
                    <template v-slot:item.memberName="{ item }">
                      <td class="primary-color mouse-cursor">
                        {{ item.memberName }}
                      </td>
                    </template>
                    <template v-slot:item.review="{ item }">
                      <v-chip color="blue" class="mouse-cursor" @click="handleMtmDocument(item.id, item.memberId, item.clinician, item.reviewerId)">
                        {{ item.review }}
                      </v-chip>
                    </template>
                    <template v-slot:no-data>
                      <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                        <span>No data available.</span>
                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<style>
@import "../assets/StyleSheets/global.css";
</style>
<script>
import axios from 'axios'
// import { VDataTable } from 'vuetify/labs/VDataTable'
import store from '../store'
export default {
  name: "MtmQualityControlQueue",
  components: {
    // VDataTable
  },
  data() {
    return {
      drawer: false,
      lightGreen: '#cddc39',
      gray: '#666666',
      primary: '#2196f3',
      sortBy: 0,
      menuPop: false,
      tempQualityCtrlQueList: [],
      fromDate: null,
      toDate: null,
      selectedFromDate: null,
      selectedToDate: null,
      tempMainDocumentLists: undefined,
      dateMenuFromSlot: false,
      dateMenuToSlot: false,
      sentDateRadio: false,
      updateDateRadio: false,
      clinicianRadio: false,
      mainDocumentList: undefined,
      clientSelectModel: null,
      group: null,
      isNotApproved: true,
      isNeedsReview: false,
      isApproved: false,
      initIntervalRef: undefined,
      clinicianName: "",
      memberName: "",
      notApprovedHeader: [
      {
        title: "Criteria Team",
        key: "criteriaTeam"
      },
      {
        title: "Member Name",
        key: "memberName"
      },
      {
        title: "Member ID",
        key: "memberId"
      },
      {
        title: "Date of Birth",
        key: "dateOfBirth"
      },
      {
        title: "Not Appr Date",
        key: "notApprDate"
      },
      {
        title: "Clinician",
        key: "clinician"
      },
      {
        title: "Clinician Update Date",
        key: "clinicianUpdateDate"
      }
    ],
    notApprovedList: [],
    needReviewHeader: [
      {
        title: "Criteria Team",
        key: "criteriaTeam"
      },
      {
        title: "Member Name",
        key: "memberName"
      },
      {
        title: "Member ID",
        key: "memberId"
      },
      {
        title: "Date of Birth",
        key: "dateOfBirth"
      },
      {
        title: "Submitted for Review",
        key: "submittedForReview"
      },
      {
        title: "Clinician",
        key: "clinician"
      },
      {
        title: " ",
        key: "review"
      }
    ],
    needReviewList: [],
    approvedHeader: [
      {
        title: "Criteria Team",
        key: "criteriaTeam"
      },
      {
        title: "Member Name",
        key: "memberName"
      },
      {
        title: "Member ID",
        key: "memberId"
      },
      {
        title: "Date of Birth",
        key: "dateOfBirth"
      },
      {
        title: "Sent Date",
        key: "displaySentDate"
      },
      {
        title: "Clinician",
        key: "clinician"
      },
      {
        title: " ",
        key: "review"
      }
    ],
    approvedList: [],
    approvedCount: 0,
    needReviewCount: 0,
    notApprovedCount: 0
    }
  },
  watch: {
  // whenever question changes, this function will run
    selectedClient(newClient) {
    this.clientSelectModel = newClient;
    }
  },
  computed: {
    message() {
      return store.getters.message;
    },
    showMessage() {
      return store.getters.showMessage;
    },
    showMessageClass() {
      return "messageDiv " + store.getters.showMessageClass;
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    appUser() {
      return store.getters.appUser;
    },
    privacyMode() {
      return store.getters.privacyMode;
    },
    clientSelectDisabled() {
      if (
        this.appUser != null &&
        this.appUser.clients != null &&
        this.appUser.clients.length > 1
      ) {
        return false;
      }
      return true;
    },
    clientItems() {
      if (this.appUser != null && this.appUser.clients != null) {
        if (this.privacyMode) {
          var privateClients = [];
          for (var i = 0; i < this.appUser.clients.length; i++) {
            privateClients.push({
              clientId: this.appUser.clients[i].clientId,
              clientCode: this.appUser.clients[i].clientCode,
              active: this.appUser.clients[i].active,
              shortName: "Client Id " + this.appUser.clients[i].clientId,
              longName:
                "Demo Imaginary Client " + this.appUser.clients[i].clientId
            });
          }
          return privateClients;
        } else {
          return this.appUser.clients;
        }
      }
      return null;
    }
  },
  methods: {
    gotoClinicianPortal () {
      this.$router.push('/clinicianPortal')
    },
    gotoQualityControlQueue () {
      this.$router.push('/qualityControlQueue')
    },
    gotoHome () {
      this.$router.push('/')
    },
    gotoLogout () {
      this.$router.push('/logout')
    },
    gotoUserInfo () {
      this.$router.push('/myprofile')
    },
    handleGotoClinician(){
      this.$router.push({ name: 'MemberSearch'})
    },
    handleStatus(value) {
      if(value ==='notApproved') {
        this.isNotApproved = true
        this.isNeedsReview = false
        this.isApproved = false
      } else if(value ==='needsReview') {
        this.isNeedsReview = true
        this.isNotApproved = false
        this.isApproved = false
      } else if(value ==='isApproved'){
        this.isApproved = true
        this.isNeedsReview = false
        this.isNotApproved = false
      }
  },
  handleMtmDocument (documentId, memberId, clinicianName, reviewerId) {
    const reviewObj = {"isReview" : true, "documentId": documentId, "memberId": memberId, "clinicianName": clinicianName, 'reviewerId': reviewerId, clientId: this.selectedClient.clientId}
    localStorage.setItem('selectReview', JSON.stringify(reviewObj))
    this.$router.push({ name: 'DisplayMemberDetails'});
  },
  togglePrivacySwitch(switchState) {
    store.commit("SET_PRIVACY_MODE", switchState);
  },
  hideMessage() {
    store.commit("SET_SHOW_MESSAGE", false);
  },
  selectClientById(value) {
    const newValue = value.target.value
    if (newValue.clientId != this.selectedClient.clientId) {
      store.dispatch("setSelectedClientById", newValue.clientId);
    }
    if(this.selectedClient.clientId !== null ){
      store.dispatch("showLoadingSpinner");
      this.getQualityControl(this.selectedClient.clientId);
    }
  },
  setSelectedFromDates (value) {
    const newValue = value.target.value
    this.mainDocumentList = this.tempMainDocumentLists;
    this.fromDate = newValue;
    if (newValue === null) {
      this.selectedFromDate = "";
    } else {
      const [year, month, day] = newValue.split("-");
      this.selectedFromDate = `${month}/${day}/${year}`;
    }
    this.handleQualityCtrFilter(this.selectedFromDate, "datefilter");
  },
  setSelectedToDates (value) {
    const newValue = value.target.value
    this.mainDocumentList = this.tempMainDocumentLists;
    this.toDate = newValue;
    if (newValue === null) {
      this.selectedToDate = "";
    } else {
      const [year, month, day] = newValue.split("-");
      this.selectedToDate = `${month}/${day}/${year}`;
    }
    this.handleQualityCtrFilter(this.selectedToDate, "datefilter");
  },
  handleQualityCtrFilter (value, type){
    let qualityCtrlQueList = []
    if(this.isNeedsReview) {
      qualityCtrlQueList =  this.needReviewList;
      this.tempQualityCtrlQueList =  this.needReviewList;
    } else if(this.isNotApproved) {
      qualityCtrlQueList =  this.notApprovedList;
      this.tempQualityCtrlQueList =  this.notApprovedList;
    } else {
      qualityCtrlQueList =  this.approvedList;
      this.tempQualityCtrlQueList =  this.approvedList;
    }
    let finalResult = "";
    if (type === "clinician") {
      if (value !== '') {
        finalResult = qualityCtrlQueList.filter((items) => items.clinician === value);
      } else {
        finalResult = this.tempQualityCtrlQueList;
      }
    }
    if (type === "member") {
      if (value != '') {
        finalResult = qualityCtrlQueList.filter((items) => items.memberName === value);
      } else {
        finalResult = this.tempQualityCtrlQueList;
      }
    }
    if(type === "datefilter"){
    const selectedFromDate1 = this.selectedFromDate;
    const selectedToDate1 =
    this.selectedToDate == null ? "" : this.selectedToDate;
    if (selectedFromDate1 !== "" && selectedToDate1 !== "") {
      finalResult = qualityCtrlQueList.filter(function (items) {
        return (
          new Date(items.dateOfBirth) >= new Date(selectedFromDate1) &&
          new Date(items.dateOfBirth) <= new Date(selectedToDate1)
        );
      });
      } else {
        finalResult = this.tempQualityCtrlQueList;
      }
    }
    if (type === "sortBy") {
      if (value == 1) {
        finalResult =  qualityCtrlQueList.sort(function (a, b) {
          return a.memberName.localeCompare(b.memberName);
        });
      } else if (value === 2) {
        finalResult = qualityCtrlQueList.sort(function (a, b) {
          return new Date(a.sentDate) - new Date(b.sentDate);
        });
      }  else if (value === 3) {
        finalResult =  qualityCtrlQueList.sort(function (a, b) {
          return a.clinician.localeCompare(b.clinician);
        });
      } else {
        finalResult =  qualityCtrlQueList.sort(function (a, b) {
          return a.clinician.localeCompare(b.clinician);
        });
      }
    }
    if(this.isNeedsReview) {
      if(finalResult.length > 0){
        this.needReviewList = finalResult;
      } else {
        this.needReviewList = this.tempQualityCtrlQueList
      }
    } else if(this.isNotApproved) {
      if(finalResult.length > 0){
        this.notApprovedList = finalResult;
      } else {
        this.needReviewList = this.tempQualityCtrlQueList
      }
    } else {
      if(finalResult.length > 0){
        this.approvedList = finalResult;
      } else {
        this.needReviewList = this.tempQualityCtrlQueList
      }
    }
  },
  handleResetFilter () {
    if(this.isNeedsReview) {
      this.needReviewList = this.tempQualityCtrlQueList;
    } else if(this.isNotApproved) {
      this.notApprovedList = this.tempQualityCtrlQueList;
    } else {
      this.approvedList = this.tempQualityCtrlQueList;
    }
    this.sortBy = 0
    this.clinicianName = ""
    this.memberName = ""
  },
  mapResponseWithFormData (dataList) {
    const tempDataList = [...dataList];
    tempDataList.forEach(items => {
      items.criteriaTeam = "Team 1"
      items.memberName = items.firstName +' '+items.lastName
      items.clinician = items.appUser.firstname +' '+items.appUser.lastname
      if(items.statusUpdateDisplay !== null){
        const [year, month, day] = items.statusUpdateDisplay.split("-");
        items.notApprDate = `${month}-${day}-${year}`;
      }
      items.clinicianUpdateDate = "10/15/2023"
      items.submittedForReview = items.displaySentDate
      const [year1, month1, day1] = items.displaySentDate.split("-");
      items.submittedForReview = `${month1}-${day1}-${year1}`;
      const [year2, month2, day2] = items.dateOfBirth.split("-");
      items.dateOfBirth = `${month2}-${day2}-${year2}`;
      if(items.status == "Review"){
        this.needReviewCount++
        items.review = "review"
        this.needReviewList.push(items)
      } else if(items.status == "Not Approved") {
        this.notApprovedCount++
        this.notApprovedList.push(items)
      } else if(items.status == "Approved") {
        this.approvedCount++
        this.approvedList.push(items)
        items.review = "review"
      }
    });
  },
  getQualityControl (clientId) {
    axios
      .get(
        "/qc/qualityControl/getdocs?clientId=" + clientId
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            if(response.data.dataLists.length > 0) {
              this.mapResponseWithFormData(response.data.dataLists);
            }
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    getUser () {
      if(!this.appUser.admin && !this.appUser.leadClinician) {
        this.approvedHeader = this.approvedHeader.filter(res => res.value != "review")
        this.needReviewHeader = this.needReviewHeader.filter(res => res.value != "review")
      }
      if (this.appUser.user) {
        this.getQualityControl(this.appUser.user.lastClientId)
        store.dispatch(
          "setSelectedClientById",
          this.appUser.user.lastClientId
        );
      }
    }
  },
  mounted () {
    this.getUser()
  }
}
</script>
