// import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';
// Vue.use(Vuex);

export default new Vuex.Store({
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    // reports
    SET_SELECTED_REPORT(state, payload) {
      state.selectedReport = payload;
    },
    SET_REPORT_MEMBERS(state, payload) {
      state.reportMembers = payload;
    },
    SET_ALL_CRITERIA_REPORT_MEMBERS(state, payload) {
      state.allCriteriaReportMembers = payload;
    },
    SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS(state, payload) {
      state.criteriaReportSelectedMemberIds = payload;
    },
    // qualified reports
    SET_QUALIFIED_REPORT_MEMBERS(state, payload) {
      state.qualifiedReportMembers = payload;
    },
    SET_SELECTED_QUALIFIED_REPORT(state, payload) {
      state.selectedQualifiedReport = payload;
    },
    SET_QUALIFIED_REPORT_SELECTED_MEMBER_IDS(state, payload) {
      state.qualifiedReportSelectedMemberIds = payload;
    },
    //member detail
    SET_SELECTED_MEMBER_ID(state, payload) {
      state.selectedMemberId = payload;
    },
    SET_MEMBER_DETAIL(state, payload) {
      state.memberDetail = payload;
    },
    SET_USER_NOTES(state, payload) {
      state.memberDetail.notes = payload;
    },
    SET_MEMBER(state, payload) {
      state.memberDetail.member = payload;
    },
    SET_MEMBER_ADDRESS(state, payload) {
      state.memberDetail.memberAddresses = payload;
    },
    SET_MEMBER_PHONE(state, payload) {
      state.memberDetail.memberPhones = payload;
    },
    SET_MEMBER_PHONE2(state, payload) {
      state.memberDetail.memberPhones2 = payload;
    },
    SET_MEMBER_EMAIL(state, payload) {
      state.memberDetail.memberEmails = payload;
    },
    SET_MEMBER_FILES(state, payload) {
      state.memberDetail.memberFiles = payload;
    },
    SET_DELETE_DIALOG(state, payload) {
      state.deleteDialog = payload;
    },
    SET_DELETE_DIALOG_TEXT(state, payload) {
      state.deleteDialogText = payload;
    },
    //search
    SET_MEMBER_SEARCH_RESULTS(state, payload) {
      state.memberSearchResults = payload;
    },
    SET_MEMBER_SEARCH_SELECTED_MEMBER_IDS(state, payload) {
      state.memberSearchSelectedIds = payload;
    },
    SET_SEARCH_FIELDS(state, payload) {
      state.searchFields = payload;
    },
    SET_THERAPY_FIELDS(state, payload) {
      state.therapyFields = payload;
    },
    //nav
    SET_MAIN_TAB(state, payload) {
      state.mainTab = payload;
    },
    SET_APP_USER(state, payload) {
      state.appUser = payload;
    },
    SET_SELECTED_CLIENT(state, payload) {
      state.selectedClient = payload;
      state.appUser.user.lastClientId = payload.clientId;
      //clear all client-related data
      state.memberDetail = null;
      state.selectedMemberId = null;
      state.qualifiedReportMembers = [];
      state.allCriteriaReportMembers = [];
      state.reportMembers = [];
      state.therapyFields = null;
      state.memberSearchResults = [];
    },
    SET_PRIVACY_MODE(state, payload) {
      state.privacyMode = payload;
    },
    SET_RELOAD_METHOD(state, payload) {
      state.reloadMethod = payload;
    },
    //messaging
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    SET_SHOW_MESSAGE(state, payload) {
      state.showMessage = payload;
    },
    SET_SHOW_MESSAGE_CLASS(state, payload) {
      state.showMessageClass = payload;
    },
    SET_DOCUCUMENT_ID(state, payload) {
      state.documentId= payload;
    },
    SET_DOCUCUMENT_STATUS(state, payload) {
      state.documentStatus= payload;
    },
    SET_DOCUCUMENT_STATUS_REVIEW(state, payload) {
      state.documentStatusReview= payload;
    },
    SET_IS_REVIEW(state, payload) {
      state.isReview= payload;
    },
    SET_CLINICIAN_NAME(state, payload) {
      state.clinicianName = payload;
    },
    SET_REVIEWER_ID(state, payload) {
      state.reviewerId = payload;
    },
    //used for demo data
    ADD_USER_NOTE(state, payload) {
      if (state.memberDetail.notes == null) state.memberDetail.notes = [];
      state.memberDetail.notes.unshift(payload);
    },
    REMOVE_USER_NOTE(state, payload) {
      if (state.memberDetail != null && state.memberDetail.notes != null) {
        var remindex = -1;
        for (var i = 0; i < state.memberDetail.notes.length; i++) {
          if (state.memberDetail.notes[i].userNoteId == payload) {
            remindex = i;
            break;
          }
        }
        if (remindex > -1) {
          state.memberDetail.notes.splice(remindex, 1);
        }
      }
    },
    UPDATE_USER_NOTE(state, payload) {
      if (state.memberDetail != null && state.memberDetail.notes != null) {
        var remindex = -1;
        for (var i = 0; i < state.memberDetail.notes.length; i++) {
          if (state.memberDetail.notes[i].userNoteId == payload.userNoteId) {
            remindex = i;
            break;
          }
        }
        if (remindex > -1) {
          state.memberDetail.notes.splice(remindex, 1, payload);
        }
      }
    }
  },
  actions: {
    setToken(context, payload) {
      context.commit("SET_TOKEN", payload);
    },
    getTherapyFields(context) {
      if (context.state.selectedClient) {
        context.dispatch("showLoadingSpinner");
        if (!context.state.appStandAlone) {
          context.commit("SET_THERAPY_FIELDS", []);
          const url = '/clinicians/ajax/getTherapyFields';
          axios
            .post(url, {
              clientId: context.state.selectedClient.clientId,
              id: 0
            })
            .then(response => {
              if (response.status === 200) {
                // Handle successful response
                const responseObj = response.data;
                // console.log(responseObj);
                context.commit( 'SET_THERAPY_FIELDS', responseObj);
                context.dispatch('hideLoadingSpinner');
              } else {
                // Handle non-200 status
                const messageObj = {
                  message: 'Error loading therapy fields...',
                  class: 'errorMessage'
                };
                context.dispatch('setMessage', messageObj);
                context.dispatch('hideLoadingSpinner');
              }
            })
            .catch(error => {
              // Handle error
              if (error.response) {
                const status = error.response.status;
                const messageObj =
                  status === 504
                    ? {
                        message: 'Network timeout - therapy fields not loaded',
                        class: 'errorMessage'
                      }
                    : {
                        message: 'Error loading therapy fields...',
                        class: 'errorMessage'
                      };
                context.dispatch('setMessage', messageObj);
              } else if (error.request) {
                context.dispatch('setMessage', {
                  message: 'Network error - therapy fields',
                  class: 'errorMessage'
                });
              } else {
                context.dispatch('setMessage', {
                  message: 'Error loading therapy fields...',
                  class: 'errorMessage'
                });
              }
              context.dispatch('hideLoadingSpinner');
            });
          // var xmlhttp = new XMLHttpRequest();
          // var url = "/clinicians/ajax/getTherapyFields";
          // xmlhttp.open("POST", url, true);
          // xmlhttp.setRequestHeader("Content-type", "application/json");
          // xmlhttp.responseType = "json";

          // xmlhttp.onload = function() {
          //   if (xmlhttp.status == 200) {
          //     var responseObj = xmlhttp.response;
          //     //console.log(responseObj);
          //     context.commit("SET_THERAPY_FIELDS", responseObj);
          //     context.dispatch("hideLoadingSpinner");
          //   } else {
          //     var messageObj = {
          //       message:
          //         xmlhttp.status == 504
          //           ? "Network timeout - therapy fields not loaded"
          //           : "Error loading therapy fields...",
          //       class: "errorMessage"
          //     };
          //     context.dispatch("setMessage", messageObj);
          //     context.dispatch("hideLoadingSpinner");
          //   }
          // };
          // xmlhttp.onerror = function() {
          //   context.dispatch("setMessage", {
          //     message: "Network error therapy fields",
          //     class: "errorMessage"
          //   });
          //   context.dispatch("hideLoadingSpinner");
          // };

          // xmlhttp.send(
          //   JSON.stringify({
          //     clientId: context.state.selectedClient.clientId,
          //     id: 0
          //   })
          // );
        } else {
          setTimeout(function() {
            context.commit(
              "SET_THERAPY_FIELDS",
              context.state.demoTherapyFields
            );
            context.dispatch("setMessage", {
              message: "demo therapy fields loaded",
              class: "successMessage"
            });
            context.dispatch("hideLoadingSpinner");
          }, 800);
        }
      } else {
        context.dispatch("setMessage", {
          message: "No Selected Client",
          class: "errorMessage"
        });
      }
    },
    setMessage(context, payload) {
      if (
        !(typeof payload.message == "undefined") &&
        !(typeof payload.class == "undefined")
      ) {
        context.commit("SET_MESSAGE", payload.message);
        context.commit("SET_SHOW_MESSAGE_CLASS", payload.class);
        context.commit("SET_SHOW_MESSAGE", true);

        // auto close non-error messages
        //if (payload.class != "errorMessage") {
        setTimeout(function() {
          context.commit("SET_SHOW_MESSAGE", false);
        }, 5000);
        //}
      } else {
        context.commit("SET_SHOW_MESSAGE", false);
      }
    },
    getUser(context) {
      context.dispatch("showLoadingSpinner");
      if (!context.state.appStandAlone) {
        axios.
        get("/clinicians/ajax/getAppUser",)
        .then(function(response) {
          if (response.status === 200) {
            var responseObj = response.data;
            context.commit("SET_APP_USER", responseObj);
            if (responseObj.user) {
              context.dispatch("setSelectedClientById", responseObj.user.lastClientId);
            }
          } else {
            var messageObj = {
              message:
                response.status === 504
                  ? "Network timeout - user not loaded"
                  : "Error loading user details...",
              class: "errorMessage"
            };
            context.dispatch("setMessage", messageObj);
          }
          context.dispatch("hideLoadingSpinner");
        })
        .catch(function(error) {
          var messageObj = {
            message: "Network error loading user",
            class: "errorMessage"
          };
          context.dispatch("setMessage", messageObj);
          context.dispatch("hideLoadingSpinner");
        });
        } else {
          context.commit("SET_APP_USER", context.state.demoUser);
        console.log("setting timeout...");
        context.dispatch(
            "setSelectedClientById",
            context.state.demoUser.user.lastClientId
          );
        
        context.dispatch("setMessage", {
          message: "demo user loaded",
          class: "successMessage"
        });
        context.dispatch("hideLoadingSpinner");
      }
    },
    getMemberDetail(context) {
      if (context.state.selectedMemberId) {
        if (!context.state.appStandAlone) {
          context.dispatch("showLoadingSpinner");
          var selectReviewObj = JSON.parse(localStorage.getItem("selectReview")) 
          var isReview = false;
          var reviewClientId = 0;
          if(selectReviewObj !== null && selectReviewObj.isReview) {
            isReview = true
            reviewClientId = selectReviewObj.clientId
          } else {
            isReview = false
          } 
          const url = "/clinicians/ajax/getMemberDetails";
          const requestData = {
            clientId: isReview ? reviewClientId : context.state.selectedClient.clientId,
            id: context.state.selectedMemberId
          };
        axios
          .post(url, requestData, {
            headers: {
              "Content-Type": "application/json"
            },
            responseType: "json"
          })
          .then((response) => {
            if (response.status === 200) {
              const responseObj = response.data;
              if (responseObj == null || responseObj.member == null) {
                context.commit("SET_MEMBER_DETAIL", null);
                context.dispatch("setMessage", {
                  message: "Member Id not found",
                  class: "errorMessage"
                });
              } else {
                context.commit("SET_MEMBER_DETAIL", responseObj);
              }
            } else {
              const messageObj = {
                message:
                  response.status === 504
                    ? "Network timeout - results not loaded"
                    : "Error loading member details...",
                class: "errorMessage"
              };
              context.dispatch("setMessage", messageObj);
              context.commit("SET_MEMBER_DETAIL", null);
            }
            context.dispatch("hideLoadingSpinner");
          })
          .catch((error) => {
            context.commit("SET_MEMBER_DETAIL", null);
            context.dispatch("setMessage", {
              message: "Network Error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
          // var xmlhttp = new XMLHttpRequest();
          // var url = "/clinicians/ajax/getMemberDetails";
          // xmlhttp.open("POST", url, true);
          // xmlhttp.setRequestHeader("Content-type", "application/json");
          // xmlhttp.responseType = "json";
          // xmlhttp.onload = function() {
          //   if (xmlhttp.status == 200) {
          //     var responseObj = xmlhttp.response;
          //     if (responseObj == null || responseObj.member == null) {
          //       context.commit("SET_MEMBER_DETAIL", null);
          //       context.dispatch("setMessage", {
          //         message: "Member Id not found",
          //         class: "errorMessage"
          //       });
          //     } else {
          //       context.commit("SET_MEMBER_DETAIL", responseObj);
          //     }
          //     context.dispatch("hideLoadingSpinner");
          //   } else {
          //     var messageObj = {
          //       message:
          //         xmlhttp.status == 504
          //           ? "Network timeout - results not loaded"
          //           : "Error loading member details...",
          //       class: "errorMessage"
          //     };
          //     context.dispatch("setMessage", messageObj);
          //     context.commit("SET_MEMBER_DETAIL", null);
          //     context.dispatch("hideLoadingSpinner");
          //   }
          // };
          // xmlhttp.onerror = function() {
          //   context.commit("SET_MEMBER_DETAIL", null);
          //   context.dispatch("setMessage", {
          //     message: "Network Error...",
          //     class: "errorMessage"
          //   });
          //   context.dispatch("hideLoadingSpinner");
          // };
          // var selectReviewObj = JSON.parse(localStorage.getItem("selectReview")) 
          // var isReview = false;
          // var reviewClientId = 0;
          // if(selectReviewObj !== null && selectReviewObj.isReview) {
          //   isReview = true
          //   reviewClientId = selectReviewObj.clientId
          // } else {
          //   isReview = false
          // }
          // xmlhttp.send(
          //   JSON.stringify({
          //     clientId: isReview ? reviewClientId : context.state.selectedClient.clientId,
          //     id: context.state.selectedMemberId
          //   })
          // );
        } else {
          context.dispatch("showLoadingSpinner");
          setTimeout(function() {
            context.commit("SET_MEMBER_DETAIL", context.state.demoMember2);
            context.dispatch("setMessage", {
              message: "demo Load complete",
              class: "successMessage"
            });
            context.dispatch("hideLoadingSpinner");
          }, 600);
        }
      } else {
        context.commit("SET_MEMBER_DETAIL", null);
      }
    },
    saveMemberDetails(context, payload) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/editMemberDetails', {
          clientId: context.state.selectedClient.clientId,
          member: payload.member,
          memberPhones: payload.memberPhones,
          memberPhones2: payload.memberPhones2,
          memberAddresses: payload.memberAddresses,
          memberEmails: payload.memberEmails
        })
          .then(response => {
            if (response.status === 200) {
              var responseObj = response.data;
              if (responseObj != null) {
                context.dispatch("setMessage", {
                  message: "Save Complete",
                  class: "successMessage"
                });
                context.dispatch("hideLoadingSpinner");
                // reload data
                context.dispatch("getMemberDetail");
              } else {
                context.dispatch("setMessage", {
                  message: "No Data Changes Detected",
                  class: "successMessage"
                });
                context.dispatch("hideLoadingSpinner");
              }
            } else {
              context.dispatch("setMessage", {
                message: "Error saving member...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Error saving member...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          })
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/editMemberDetails";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     var responseObj = xmlhttp.response;
        //     //console.log(responseObj);
        //     if (responseObj != null) {
        //       context.dispatch("setMessage", {
        //         message: "Save Complete",
        //         class: "successMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //       // reload data
        //       context.dispatch("getMemberDetail");
        //     } else {
        //       context.dispatch("setMessage", {
        //         message: "No Data Changes Detected",
        //         class: "successMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //     }
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Error saving member...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };
        // //console.log(payload);
        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     member: payload.member,
        //     memberPhones: payload.memberPhones,
        //     memberPhones2: payload.memberPhones2,
        //     memberAddresses: payload.memberAddresses,
        //     memberEmails: payload.memberEmails
        //   })
        // );
      } else {
        context.dispatch("showLoadingSpinner");
        setTimeout(function() {
          context.commit("SET_MEMBER", payload.member);
          context.commit("SET_MEMBER_ADDRESS", payload.memberAddresses);
          context.commit("SET_MEMBER_EMAIL", payload.memberEmails);
          context.commit("SET_MEMBER_PHONE", payload.memberPhones);
          context.commit("SET_MEMBER_PHONE2", payload.memberPhones2);

          context.dispatch("setMessage", {
            message: "demo member updated",
            class: "successMessage"
          });
          context.dispatch("hideLoadingSpinner");
        }, 600);
      }
    },
    getReportMembers(context) {
      if (context.state.selectedReport) {
        if (!context.state.appStandAlone) {
          context.dispatch("showLoadingSpinner");
          axios.post('/clinicians/ajax/getIdentifiedMembers', {
            clientId: context.state.selectedClient.clientId,
            id: context.state.selectedReport.id
          })
            .then(response => {
              if (response.status === 200) {
                var responseObj = response.data;
                for (var i = 0; i < responseObj.length; i++) {
                  if (
                    context.state.selectedReport.id == 4 ||
                    context.state.selectedReport.id == 5
                  ) {
                    // spend reports
                    if (
                      typeof responseObj[i].names != "object" &&
                      responseObj[i].names != null
                    ) {
                      var spendObj = JSON.parse(responseObj[i].names);
                      var keys = Object.keys(spendObj);
                      var keyheaders = [];
                      for (var j = 0; j < keys.length; j++) {
                        var h = {
                          text: keys[j],
                          value: keys[j],
                          divider: true
                        };
                        keyheaders.push(h);
                      }
                      responseObj[i].names = {
                        headers: keyheaders,
                        items: [spendObj]
                      };
                    }
                  } else {
                    // other reports
                    if (
                      responseObj[i].criteriaName != "TopRxSpend" &&
                      responseObj[i].criteriaName != "TopMedicalSpend"
                    ) {
                      if (responseObj[i].list != null) {
                        responseObj[i].list = responseObj[i].list.replace(/,/g, ", ");
                      }
                      if (responseObj[i].names != null) {
                        responseObj[i].names = responseObj[i].names.replace(/\|/g, ", ");
                      }
                    }
                  }
                }
          
                var mappedmembers = responseObj.map((item, index) => ({
                  id: index,
                  totalCount: item.totalCount,
                  memberId: item.memberId,
                  dateOfBirth: item.dateOfBirth,
                  criteriaName: item.criteriaName,
                  mostRecent: item.mostRecent,
                  year: item.year,
                  period: item.period,
                  count: item.count,
                  list: item.list,
                  names: item.names,
                  lifetimeTotal: item.lifetimeTotal
                }));
          
                if (
                  context.state.selectedReport.id === 0 ||
                  context.state.selectedReport.id === 101
                ) {
                  var memberObjs = [];
          
                  for (var x = 0; x < mappedmembers.length; x++) {
                    var foundIndex = -1;
                    for (var y = 0; y < memberObjs.length; y++) {
                      if (mappedmembers[x].memberId == memberObjs[y].memberId) {
                        foundIndex = y;
                        break;
                      }
                    }
                    if (foundIndex > -1) {
                      memberObjs[foundIndex].criteriaString +=
                        (memberObjs[foundIndex].criteriaString.length > 0 ? ", " : "") +
                        mappedmembers[x].criteriaName;
                      memberObjs[foundIndex].items.push(mappedmembers[x]);
                    } else {
                      var newObj = {
                        totalCount: mappedmembers[x].totalCount,
                        memberId: mappedmembers[x].memberId,
                        dateOfBirth: mappedmembers[x].dateOfBirth,
                        criteriaString: "",
                        items: []
                      };
                      newObj.criteriaString +=
                        (newObj.criteriaString.length > 0 ? ", " : "") +
                        mappedmembers[x].criteriaName;
                      newObj.items.push(mappedmembers[x]);
                      memberObjs.push(newObj);
                    }
                  }
          
                  context.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", memberObjs);
                  context.dispatch("hideLoadingSpinner");
                } else {
                  context.commit("SET_REPORT_MEMBERS", mappedmembers);
                  context.dispatch("hideLoadingSpinner");
                }
          
                if (mappedmembers.length == 0) {
                  context.dispatch("setMessage", {
                    message: "Search returned no results",
                    class: ""
                  });
                }
              } else {
                var m2Obj = {
                  message:
                    response.status == 504
                      ? "Network timeout - results not loaded"
                      : "Error loading report data...",
                  class: "errorMessage"
                };
                context.dispatch("setMessage", m2Obj);
                context.commit("SET_REPORT_MEMBERS", []);
                context.dispatch("hideLoadingSpinner");
              }
            })
            .catch(error => {
              context.commit("SET_REPORT_MEMBERS", []);
              context.dispatch("setMessage", {
                message: "Network error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            });
          
          // ajax call to get results
          // var xmlhttp = new XMLHttpRequest();
          // var url = "/clinicians/ajax/getIdentifiedMembers";
          // xmlhttp.open("POST", url, true);
          // xmlhttp.setRequestHeader("Content-type", "application/json");
          // xmlhttp.responseType = "json";
          // xmlhttp.onload = function() {
          //   if (xmlhttp.status == 200) {
          //     var responseObj = xmlhttp.response;
          //     for (var i = 0; i < responseObj.length; i++) {
          //       if (
          //         context.state.selectedReport.id == 4 ||
          //         context.state.selectedReport.id == 5
          //       ) {
          //         //spend reports
          //         if (
          //           typeof responseObj[i].names != "object" &&
          //           responseObj[i].names != null
          //         ) {
          //           var spendObj = JSON.parse(responseObj[i].names);
          //           var keys = Object.keys(spendObj);
          //           var keyheaders = [];
          //           for (var j = 0; j < keys.length; j++) {
          //             var h = {
          //               text: keys[j],
          //               value: keys[j],
          //               divider: true
          //             };
          //             keyheaders.push(h);
          //           }
          //           responseObj[i].names = {
          //             headers: keyheaders,
          //             items: [spendObj]
          //           };
          //         }
          //       } else {
          //         //other reports
          //         if (
          //           responseObj[i].criteriaName != "TopRxSpend" &&
          //           responseObj[i].criteriaName != "TopMedicalSpend"
          //         ) {
          //           if (responseObj[i].list != null) {
          //             responseObj[i].list = responseObj[i].list.replace(
          //               /,/g,
          //               ", "
          //             );
          //           }
          //           if (responseObj[i].names != null) {
          //             responseObj[i].names = responseObj[i].names.replace(
          //               /\|/g,
          //               ", "
          //             );
          //           }
          //         }
          //       }
          //     }

          //     var mappedmembers = responseObj.map((item, index) => ({
          //       id: index,
          //       totalCount: item.totalCount,
          //       memberId: item.memberId,
          //       dateOfBirth: item.dateOfBirth,
          //       criteriaName: item.criteriaName,
          //       mostRecent: item.mostRecent,
          //       year: item.year,
          //       period: item.period,
          //       count: item.count,
          //       list: item.list,
          //       names: item.names,
          //       lifetimeTotal: item.lifetimeTotal
          //     }));

          //     if (
          //       context.state.selectedReport.id == 0 ||
          //       context.state.selectedReport.id == 101
          //     ) {
          //       var memberObjs = [];

          //       for (var x = 0; x < mappedmembers.length; x++) {
          //         var foundIndex = -1;
          //         for (var y = 0; y < memberObjs.length; y++) {
          //           if (mappedmembers[x].memberId == memberObjs[y].memberId) {
          //             foundIndex = y;
          //             break;
          //           }
          //         }
          //         if (foundIndex > -1) {
          //           memberObjs[foundIndex].criteriaString +=
          //             (memberObjs[foundIndex].criteriaString.length > 0
          //               ? ", "
          //               : "") + mappedmembers[x].criteriaName;
          //           memberObjs[foundIndex].items.push(mappedmembers[x]);
          //         } else {
          //           var newObj = {
          //             totalCount: mappedmembers[x].totalCount,
          //             memberId: mappedmembers[x].memberId,
          //             dateOfBirth: mappedmembers[x].dateOfBirth,
          //             criteriaString: "",
          //             items: []
          //           };
          //           newObj.criteriaString +=
          //             (newObj.criteriaString.length > 0 ? ", " : "") +
          //             mappedmembers[x].criteriaName;
          //           newObj.items.push(mappedmembers[x]);
          //           memberObjs.push(newObj);
          //         }
          //       }

          //       context.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", memberObjs);
          //       context.dispatch("hideLoadingSpinner");
          //     } else {
          //       context.commit("SET_REPORT_MEMBERS", mappedmembers);
          //       context.dispatch("hideLoadingSpinner");
          //     }

          //     if (mappedmembers.length == 0) {
          //       context.dispatch("setMessage", {
          //         message: "Search returned no results",
          //         class: ""
          //       });
          //     }
          //   } else {
          //     var m2Obj = {
          //       message:
          //         xmlhttp.status == 504
          //           ? "Network timeout - results not loaded"
          //           : "Error loading report data...",
          //       class: "errorMessage"
          //     };
          //     context.dispatch("setMessage", m2Obj);
          //     context.commit("SET_REPORT_MEMBERS", []);
          //     context.dispatch("hideLoadingSpinner");
          //   }
          // };
          // xmlhttp.onerror = function() {
          //   context.commit("SET_REPORT_MEMBERS", []);
          //   context.dispatch("setMessage", {
          //     message: "Network error...",
          //     class: "errorMessage"
          //   });
          //   context.dispatch("hideLoadingSpinner");
          // };

          // xmlhttp.send(
          //   JSON.stringify({
          //     clientId: context.state.selectedClient.clientId,
          //     id: context.state.selectedReport.id
          //   })
          // );
        } else {
          // demo data
          context.dispatch("showLoadingSpinner");
          var members;
          for (var a = 0; a < context.state.demoReportResults.length; a++) {
            if (
              context.state.demoReportResults[a].id ==
              context.state.selectedReport.id
            ) {
              members = context.state.demoReportResults[a].members;
              break;
            }
          }

          for (var i = 0; i < members.length; i++) {
            if (
              context.state.selectedReport.id == 4 ||
              context.state.selectedReport.id == 5
            ) {
              //spend reports
              if (
                typeof members[i].names != "object" &&
                members[i].names != null
              ) {
                var spendObj = JSON.parse(members[i].names);
                var keys = Object.keys(spendObj);
                var keyheaders = [];
                for (var j = 0; j < keys.length; j++) {
                  var h = {
                    text: keys[j],
                    value: keys[j],
                    divider: true
                  };
                  keyheaders.push(h);
                }
                members[i].names = {
                  headers: keyheaders,
                  items: [spendObj]
                };
              }
            } else {
              //other reports
              if (
                members[i].criteriaName != "TopRxSpend" &&
                members[i].criteriaName != "TopMedicalSpend"
              ) {
                if (members[i].list != null) {
                  members[i].list = members[i].list.replace(/,/g, ", ");
                }
                if (members[i].names != null) {
                  members[i].names = members[i].names.replace(/\|/g, ", ");
                }
              }
            }
          }
          var mappedmembers = members.map((item, index) => ({
            id: index,
            totalCount: item.totalCount,
            memberId: item.memberId,
            dateOfBirth: item.dateOfBirth,
            criteriaName: item.criteriaName,
            mostRecent: item.mostRecent,
            year: item.year,
            period: item.period,
            count: item.count,
            list: item.list,
            names: item.names,
            lifetimeTotal: item.lifetimeTotal
          }));

          if (
            context.state.selectedReport.id == 0 ||
            context.state.selectedReport.id == 101
          ) {
            var memberObjs = [];

            for (var x = 0; x < mappedmembers.length; x++) {
              var foundIndex = -1;
              for (var y = 0; y < memberObjs.length; y++) {
                if (mappedmembers[x].memberId == memberObjs[y].memberId) {
                  foundIndex = y;
                  break;
                }
              }
              if (foundIndex > -1) {
                memberObjs[foundIndex].criteriaString +=
                  (memberObjs[foundIndex].criteriaString.length > 0
                    ? ", "
                    : "") + mappedmembers[x].criteriaName;
                memberObjs[foundIndex].items.push(mappedmembers[x]);
              } else {
                var newObj = {
                  totalCount: mappedmembers[x].totalCount,
                  memberId: mappedmembers[x].memberId,
                  dateOfBirth: mappedmembers[x].dateOfBirth,
                  criteriaString: "",
                  items: []
                };
                newObj.criteriaString +=
                  (newObj.criteriaString.length > 0 ? ", " : "") +
                  mappedmembers[x].criteriaName;
                newObj.items.push(mappedmembers[x]);
                memberObjs.push(newObj);
              }
            }

            setTimeout(function() {
              context.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", memberObjs);
              context.dispatch("setMessage", {
                message: "demo load complete",
                class: "successMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }, 1100);
          } else {
            setTimeout(function() {
              context.commit("SET_REPORT_MEMBERS", mappedmembers);
              context.dispatch("setMessage", {
                message: "demo load complete",
                class: "successMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }, 1100);
          }
        }
      } else {
        context.commit("SET_REPORT_MEMBERS", []);
      }
    },
    getMemberSearchResults(context) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");

        axios.post('/clinicians/ajax/memberSearch', {
          memberText: context.state.searchFields.memberText,
          diagnosisText: context.state.searchFields.diagnosisText,
          therapyText: context.state.searchFields.therapyText,
          drugText: context.state.searchFields.drugText,
          includeNonTargeted: context.state.searchFields.includeNonTargeted,
          useAND: context.state.searchFields.useAND,
          clientId: context.state.selectedClient.clientId
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            if (response.status === 200) {
              var responseObj = response.data;
              context.commit("SET_MEMBER_SEARCH_RESULTS", responseObj);
              context.dispatch("hideLoadingSpinner");
            } else {
              var messageObj = {
                message:
                  response.status == 504
                    ? "Network timeout - results not loaded"
                    : "Error loading search results...",
                class: "errorMessage"
              };
              context.dispatch("setMessage", messageObj);
              context.commit("SET_MEMBER_SEARCH_RESULTS", []);
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.commit("SET_MEMBER_SEARCH_RESULTS", []);
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        //console.log(context.state.searchFields);
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/memberSearch";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     var responseObj = xmlhttp.response;
        //     context.commit("SET_MEMBER_SEARCH_RESULTS", responseObj);
        //     context.dispatch("hideLoadingSpinner");
        //   } else {
        //     var messageObj = {
        //       message:
        //         xmlhttp.status == 504
        //           ? "Network timeout - results not loaded"
        //           : "Error loading search results...",
        //       class: "errorMessage"
        //     };
        //     context.dispatch("setMessage", messageObj);
        //     context.commit("SET_MEMBER_SEARCH_RESULTS", []);
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.commit("SET_MEMBER_SEARCH_RESULTS", []);
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     memberText: context.state.searchFields.memberText,
        //     diagnosisText: context.state.searchFields.diagnosisText,
        //     therapyText: context.state.searchFields.therapyText,
        //     drugText: context.state.searchFields.drugText,
        //     includeNonTargeted: context.state.searchFields.includeNonTargeted,
        //     useAND: context.state.searchFields.useAND,
        //     clientId: context.state.selectedClient.clientId
        //   })
        // );
      } else {
        context.dispatch("showLoadingSpinner");
        setTimeout(function() {
          context.commit(
            "SET_MEMBER_SEARCH_RESULTS",
            context.state.demoSearchResults
          );
          context.dispatch("setMessage", {
            message: "demo search complete",
            class: "successMessage"
          });
          context.dispatch("hideLoadingSpinner");
        }, 600);
      }
    },
    addUserNote(context, payload) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/addNote', payload)
          .then(response => {
            if (response.status === 200) {
              var responseObj = response.data;
              context.commit("SET_USER_NOTES", responseObj);
              context.dispatch("hideLoadingSpinner");
            } else {
              context.dispatch("setMessage", {
                message: "Error saving note...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/addNote";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     var responseObj = xmlhttp.response;
        //     context.commit("SET_USER_NOTES", responseObj);
        //     context.dispatch("hideLoadingSpinner");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Error saving note...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(JSON.stringify(payload));
      } else {
        context.dispatch("showLoadingSpinner");
        setTimeout(function() {
          payload.userNoteId = new Date().getSeconds() + 200;
          payload.dateCreated = new Date();
          //console.log(payload);
          context.commit("ADD_USER_NOTE", payload);
          context.dispatch("setMessage", {
            message: "demo note added",
            class: "successMessage"
          });
          context.dispatch("hideLoadingSpinner");
        }, 1100);
      }
    },
    deleteNote(context, payload) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
         
        axios.post('/clinicians/ajax/editNote', {
          userNoteId: payload,
          memberId: context.state.selectedMemberId,
          userId: context.state.appUser.user.userId,
          delete: true,
          note: null
        })
          .then(response => {
            if (response.status === 200 && response.data != null) {
              var responseObj = response.data;
              // console.log(responseObj);
              context.commit("SET_USER_NOTES", responseObj);
              context.dispatch("hideLoadingSpinner");
            } else {
              context.dispatch("setMessage", {
                message: "Error deleting note...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });

        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/editNote";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200 && xmlhttp.response != null) {
        //     var responseObj = xmlhttp.response;
        //     //console.log(responseObj);
        //     context.commit("SET_USER_NOTES", responseObj);
        //     context.dispatch("hideLoadingSpinner");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Error deleting note...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     userNoteId: payload,
        //     memberId: context.state.selectedMemberId,
        //     userId: context.state.appUser.user.userId,
        //     delete: true,
        //     note: null
        //   })
        // );

        context.dispatch("hideLoadingSpinner");
      } else {
        context.dispatch("showLoadingSpinner");
        setTimeout(function() {
          context.commit("REMOVE_USER_NOTE", payload);
          context.dispatch("setMessage", {
            message: "demo note removed",
            class: "successMessage"
          });
          context.dispatch("hideLoadingSpinner");
        }, 1100);
      }
    },
    editUserNote(context, payload) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/editNote', {
          userNoteId: payload,
          memberId: context.state.selectedMemberId,
          userId: context.state.appUser.user.userId,
          delete: true,
          note: null
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            if (response.status === 200 && response.data != null) {
              var responseObj = response.data;
              // console.log(responseObj);
              context.commit("SET_USER_NOTES", responseObj);
              context.dispatch("hideLoadingSpinner");
            } else {
              context.dispatch("setMessage", {
                message: "Error deleting note...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/editNote";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200 && xmlhttp.response!=null) {
        //     context.commit("SET_USER_NOTES", xmlhttp.response);
        //     context.dispatch("hideLoadingSpinner");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Error saving note...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     userNoteId: payload.userNoteId,
        //     memberId: payload.memberId,
        //     userId: payload.userId,
        //     delete: false,
        //     note: payload.note
        //   })
        // );
      } else {
        context.dispatch("showLoadingSpinner");
        setTimeout(function() {
          context.commit("UPDATE_USER_NOTE", payload);
          context.dispatch("setMessage", {
            message: "demo note updated",
            class: "successMessage"
          });
          context.dispatch("hideLoadingSpinner");
        }, 1100);
      }
    },
    targetMembers(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      //alert(payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/targetingResponse', {
          clientId: context.state.selectedClient.clientId,
          status: payload.status,
          memberIds: payload.memberIds
        })
          .then(response => {
            if (response.status === 200) {
              var responseObj = response.data;
              if (responseObj != null && responseObj.length > 0) {
                var stringIds = "";
                for (var i = 0; i < responseObj.length; i++) {
                  stringIds += responseObj[i] + ",";
                }
                stringIds = stringIds.slice(0, -1);
                context.dispatch("setMessage", {
                  message: "Status Change Completed: " + stringIds,
                  class: "successMessage"
                });
                context.dispatch("hideLoadingSpinner");
                context.dispatch("reloadFromMethod");
              } else {
                context.dispatch("setMessage", {
                  message: "Error changing member status",
                  class: "errorMessage"
                });
                context.dispatch("hideLoadingSpinner");
              }
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/targetingResponse";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     var responseObj = xmlhttp.response;
        //     if (responseObj != null && responseObj.length > 0) {
        //       var stringIds = "";
        //       for (var i = 0; i < responseObj.length; i++) {
        //         stringIds += responseObj[i] + ",";
        //       }
        //       stringIds = stringIds.slice(0, -1);
        //       context.dispatch("setMessage", {
        //         message: "Status Change Completed : " + stringIds,
        //         class: "successMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //       context.dispatch("reloadFromMethod");
        //     } else {
        //       context.dispatch("setMessage", {
        //         message: "Error changing member status",
        //         class: "errorMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //     }
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     status: payload.status,
        //     memberIds: payload.memberIds
        //   })
        // );
      } else {
        // console.log(payload);
        var stringIds = "";
        for (var i = 0; i < payload.memberIds.length; i++) {
          stringIds += payload.memberIds[i] + ",";
        }
        stringIds = stringIds.slice(0, -1);
        context.dispatch("setMessage", {
          message:
            "Status Change to " + payload.status + " Completed : " + stringIds,
          class: "successMessage"
        });
      }
      context.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
    },
    unTargetMembers(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      //alert(payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");

        axios.post('/clinicians/ajax/unTargetingResponse', {
          clientId: context.state.selectedClient.clientId,
          status: payload.status,
          memberIds: payload.memberIds
        })
          .then(response => {
            if (response.status === 200) {
              var responseObj = response.data;
              if (responseObj != null && responseObj.length > 0) {
                var stringIds = "";
                for (var i = 0; i < responseObj.length; i++) {
                  stringIds += responseObj[i] + ",";
                }
                stringIds = stringIds.slice(0, -1);
                context.dispatch("setMessage", {
                  message: "Status Change Completed: " + stringIds,
                  class: "successMessage"
                });
                context.dispatch("hideLoadingSpinner");
                context.dispatch("reloadFromMethod");
              } else {
                context.dispatch("setMessage", {
                  message: "Error changing member status",
                  class: "errorMessage"
                });
                context.dispatch("hideLoadingSpinner");
              }
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/unTargetingResponse";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     var responseObj = xmlhttp.response;
        //     if (responseObj != null && responseObj.length > 0) {
        //       var stringIds = "";
        //       for (var i = 0; i < responseObj.length; i++) {
        //         stringIds += responseObj[i] + ",";
        //       }
        //       stringIds = stringIds.slice(0, -1);
        //       context.dispatch("setMessage", {
        //         message: "Status Change Completed : " + stringIds,
        //         class: "successMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //       context.dispatch("reloadFromMethod");
        //     } else {
        //       context.dispatch("setMessage", {
        //         message: "Error changing member status",
        //         class: "errorMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //     }
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     status: payload.status,
        //     memberIds: payload.memberIds
        //   })
        // );
      } else {
        // console.log(payload);
        var stringIds = "";
        for (var i = 0; i < payload.memberIds.length; i++) {
          stringIds += payload.memberIds[i] + ",";
        }
        stringIds = stringIds.slice(0, -1);
        context.dispatch("setMessage", {
          message:
            "Status Change to " + payload.status + " Completed : " + stringIds,
          class: "successMessage"
        });
      }
      context.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
    },
    printLetters(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/generateInvites', {
          clientId: context.state.selectedClient.clientId,
          status: payload.status,
          memberIds: payload.memberIds
        }, {
          responseType: 'arraybuffer'
        })
          .then(response => {
            if (response.status === 200 && response.data != null) {
              var filename = "";
              var disposition = response.headers['content-disposition'];
              if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
                }
              }
              var type = response.headers['content-type'];
              var blob =
                typeof File === "function"
                  ? new File([response.data], filename, { type: type })
                  : new Blob([response.data], { type: type });
              if (typeof window.navigator.msSaveBlob !== "undefined") {
                // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
              } else {
                var URL = window.URL || window.webkitURL;
                var downloadUrl = URL.createObjectURL(blob);
        
                if (filename) {
                  // use HTML5 a[download] attribute to specify filename
                  let a = document.createElement("a");
                  a.style = "display: none";
                  // safari doesn't support this yet
                  if (typeof a.download === "undefined") {
                    document.body.appendChild(a);
                    a.href = downloadUrl;
                    a.click();
                  } else {
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                  }
                } else {
                  window.location = downloadUrl;
                }
                setTimeout(function () {
                  URL.revokeObjectURL(downloadUrl);
                }, 100); // cleanup
              }
        
              context.dispatch("setMessage", {
                message: "File ready.",
                class: "successMessage"
              });
        
              context.dispatch("hideLoadingSpinner");
              context.dispatch("reloadFromMethod");
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/generateInvites";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        // xmlhttp.responseType = "arraybuffer";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200 && this.response != null) {
        //     var filename = "";
        //     var disposition = xmlhttp.getResponseHeader("Content-Disposition");
        //     if (disposition && disposition.indexOf("attachment") !== -1) {
        //       var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        //       var matches = filenameRegex.exec(disposition);
        //       if (matches != null && matches[1]) {
        //         filename = matches[1].replace(/['"]/g, "");
        //       }
        //     }
        //     var type = xmlhttp.getResponseHeader("Content-Type");
        //     var blob =
        //       typeof File === "function"
        //         ? new File([this.response], filename, { type: type })
        //         : new Blob([this.response], { type: type });
        //     if (typeof window.navigator.msSaveBlob !== "undefined") {
        //       // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
        //       // These URLs will no longer resolve as the data backing the URL has been freed."
        //       window.navigator.msSaveBlob(blob, filename);
        //     } else {
        //       var URL = window.URL || window.webkitURL;
        //       var downloadUrl = URL.createObjectURL(blob);

        //       if (filename) {
        //         // use HTML5 a[download] attribute to specify filename
        //         let a = document.createElement("a");
        //         a.style = "display: none";
        //         // safari doesn't support this yet
        //         if (typeof a.download === "undefined") {
        //           document.body.appendChild(a);
        //           a.href = downloadUrl;
        //           a.click();
        //         } else {
        //           a.href = downloadUrl;
        //           a.download = filename;
        //           document.body.appendChild(a);
        //           a.click();
        //         }
        //       } else {
        //         window.location = downloadUrl;
        //       }
        //       setTimeout(function() {
        //         URL.revokeObjectURL(downloadUrl);
        //       }, 100); // cleanup
        //     }

        //     context.dispatch("setMessage", {
        //       message: "File ready.",
        //       class: "successMessage"
        //     });

        //     context.dispatch("hideLoadingSpinner");
        //     context.dispatch("reloadFromMethod");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     status: payload.status,
        //     memberIds: payload.memberIds
        //   })
        // );
      } else {
        // console.log(payload);
        var stringIds = "";
        for (var i = 0; i < payload.memberIds.length; i++) {
          stringIds += payload.memberIds[i] + ",";
        }
        stringIds = stringIds.slice(0, -1);
        context.dispatch("setMessage", {
          message: "Letters Printed : " + stringIds,
          class: "successMessage"
        });
      }
      context.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
    },
    downloadFile(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");

        axios.post('/clinicians/ajax/downloadFile', {
          clientId: context.state.selectedClient.clientId,
          id: payload.fileId
        }, {
          responseType: 'arraybuffer'
        })
          .then(response => {
            if (response.status === 200 && response.data != null) {
              var filename = "";
              var disposition = response.headers['content-disposition'];
              if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
                }
              }
              var type = response.headers['content-type'];
              var blob =
                typeof File === "function"
                  ? new File([response.data], filename, { type: type })
                  : new Blob([response.data], { type: type });
              if (typeof window.navigator.msSaveBlob !== "undefined") {
                // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
              } else {
                var URL = window.URL || window.webkitURL;
                var downloadUrl = URL.createObjectURL(blob);
        
                if (filename) {
                  // use HTML5 a[download] attribute to specify filename
                  let a = document.createElement("a");
                  a.style = "display: none";
                  // safari doesn't support this yet
                  if (typeof a.download === "undefined") {
                    document.body.appendChild(a);
                    a.href = downloadUrl;
                    a.click();
                  } else {
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                  }
                } else {
                  window.location = downloadUrl;
                }
                setTimeout(function () {
                  URL.revokeObjectURL(downloadUrl);
                }, 100); // cleanup
              }
        
              context.dispatch("setMessage", {
                message: "File ready.",
                class: "successMessage"
              });
        
              context.dispatch("hideLoadingSpinner");
              context.dispatch("reloadFromMethod");
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/downloadFile";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        // xmlhttp.responseType = "arraybuffer";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200 && this.response != null) {
        //     var filename = "";
        //     var disposition = xmlhttp.getResponseHeader("Content-Disposition");
        //     if (disposition && disposition.indexOf("attachment") !== -1) {
        //       var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        //       var matches = filenameRegex.exec(disposition);
        //       if (matches != null && matches[1]) {
        //         filename = matches[1].replace(/['"]/g, "");
        //       }
        //     }
        //     var type = xmlhttp.getResponseHeader("Content-Type");
        //     var blob =
        //       typeof File === "function"
        //         ? new File([this.response], filename, { type: type })
        //         : new Blob([this.response], { type: type });
        //     if (typeof window.navigator.msSaveBlob !== "undefined") {
        //       // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
        //       // These URLs will no longer resolve as the data backing the URL has been freed."
        //       window.navigator.msSaveBlob(blob, filename);
        //     } else {
        //       var URL = window.URL || window.webkitURL;
        //       var downloadUrl = URL.createObjectURL(blob);

        //       if (filename) {
        //         // use HTML5 a[download] attribute to specify filename
        //         let a = document.createElement("a");
        //         a.style = "display: none";
        //         // safari doesn't support this yet
        //         if (typeof a.download === "undefined") {
        //           document.body.appendChild(a);
        //           a.href = downloadUrl;
        //           a.click();
        //         } else {
        //           a.href = downloadUrl;
        //           a.download = filename;
        //           document.body.appendChild(a);
        //           a.click();
        //         }
        //       } else {
        //         window.location = downloadUrl;
        //       }
        //       setTimeout(function() {
        //         URL.revokeObjectURL(downloadUrl);
        //       }, 100); // cleanup
        //     }

        //     context.dispatch("setMessage", {
        //       message: "File ready.",
        //       class: "successMessage"
        //     });

        //     context.dispatch("hideLoadingSpinner");
        //     context.dispatch("reloadFromMethod");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };
        // //window.downloadHeader = payload.fileHeader;
        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     id: payload.fileId
        //   })
        // );
      } else {
        context.dispatch("setMessage", {
          message: "Can't download without back end. ",
          class: "errorMessage"
        });
      }
    },
    downloadReport(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/generateReport', {
          clientId: context.state.selectedClient.clientId,
          status: payload.status,
          memberIds: payload.memberIds
        }, {
          responseType: 'blob'
        })
          .then(response => {
            if (response.status === 200 && response.data != null) {
              var filename = "";
              var disposition = response.headers['content-disposition'];
              if (disposition && disposition.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, "");
                }
              }
              var type = response.headers['content-type'];
              var blob =
                typeof File === "function"
                  ? new File([response.data], filename, { type: type })
                  : new Blob([response.data], { type: type });
              if (typeof window.navigator.msSaveBlob !== "undefined") {
                // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
                // These URLs will no longer resolve as the data backing the URL has been freed."
                window.navigator.msSaveBlob(blob, filename);
              } else {
                var URL = window.URL || window.webkitURL;
                var downloadUrl = URL.createObjectURL(blob);
        
                if (filename) {
                  // use HTML5 a[download] attribute to specify filename
                  let a = document.createElement("a");
                  a.style = "display: none";
                  // safari doesn't support this yet
                  if (typeof a.download === "undefined") {
                    document.body.appendChild(a);
                    a.href = downloadUrl;
                    a.click();
                  } else {
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                  }
                } else {
                  window.location = downloadUrl;
                }
                setTimeout(function () {
                  URL.revokeObjectURL(downloadUrl);
                }, 100); // cleanup
              }
        
              context.dispatch("setMessage", {
                message: "File ready.",
                class: "successMessage"
              });
        
              context.dispatch("hideLoadingSpinner");
              context.dispatch("reloadFromMethod");
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/generateReport";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        // xmlhttp.responseType = "blob";

        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200 && this.response != null) {
        //     var filename = "";
        //     var disposition = xmlhttp.getResponseHeader("Content-Disposition");
        //     if (disposition && disposition.indexOf("attachment") !== -1) {
        //       var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        //       var matches = filenameRegex.exec(disposition);
        //       if (matches != null && matches[1]) {
        //         filename = matches[1].replace(/['"]/g, "");
        //       }
        //     }
        //     var type = xmlhttp.getResponseHeader("Content-Type");
        //     var blob =
        //       typeof File === "function"
        //         ? new File([this.response], filename, { type: type })
        //         : new Blob([this.response], { type: type });
        //     if (typeof window.navigator.msSaveBlob !== "undefined") {
        //       // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
        //       // These URLs will no longer resolve as the data backing the URL has been freed."
        //       window.navigator.msSaveBlob(blob, filename);
        //     } else {
        //       var URL = window.URL || window.webkitURL;
        //       var downloadUrl = URL.createObjectURL(blob);

        //       if (filename) {
        //         // use HTML5 a[download] attribute to specify filename
        //         let a = document.createElement("a");
        //         a.style = "display: none";
        //         // safari doesn't support this yet
        //         if (typeof a.download === "undefined") {
        //           document.body.appendChild(a);
        //           a.href = downloadUrl;
        //           a.click();
        //         } else {
        //           a.href = downloadUrl;
        //           a.download = filename;
        //           document.body.appendChild(a);
        //           a.click();
        //         }
        //       } else {
        //         window.location = downloadUrl;
        //       }
        //       setTimeout(function() {
        //         URL.revokeObjectURL(downloadUrl);
        //       }, 100); // cleanup
        //     }

        //     context.dispatch("setMessage", {
        //       message: "File ready.",
        //       class: "successMessage"
        //     });

        //     context.dispatch("hideLoadingSpinner");
        //     context.dispatch("reloadFromMethod");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };

        // /*xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     // Create a new Blob object using the
        //     //response data of the onload object
        //     //var blobObj = new Blob([xmlhttp.response], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        //     var blobObj = new Blob([xmlhttp.response], {
        //       type: "octet/stream"
        //     });

        //     var today = new Date(),
        //       month = "" + (today.getMonth() + 1),
        //       day = "" + today.getDate(),
        //       year = today.getFullYear();
        //     if (month.length < 2) month = "0" + month;
        //     if (day.length < 2) day = "0" + day;
        //     var todayStr = [year, month, day].join("-");
        //     //console.log(blobObj);
        //     //Create a link element, hide it, direct
        //     //it towards the blob, and then 'click' it programatically
        //     let a = document.createElement("a");
        //     a.style = "display: none";
        //     document.body.appendChild(a);
        //     //Create a DOMString representing the blob
        //     //and point the link element towards it
        //     let url = window.URL.createObjectURL(blobObj);
        //     a.href = url;
        //     a.download = "MemberFirst_Report_" + todayStr + ".zip";
        //     //programatically click the link to trigger the download
        //     a.click();
        //     //release the reference to the file by revoking the Object URL
        //     window.URL.revokeObjectURL(url);
        //     context.dispatch("setMessage", {
        //       message: "File ready.",
        //       class: "successMessage"
        //     });

        //     context.dispatch("hideLoadingSpinner");
        //     context.dispatch("reloadFromMethod");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };*/
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     status: payload.status,
        //     memberIds: payload.memberIds
        //   })
        // );
      } else {
        // console.log(payload);
        var stringIds = "";
        for (var i = 0; i < payload.memberIds.length; i++) {
          stringIds += payload.memberIds[i] + ",";
        }
        stringIds = stringIds.slice(0, -1);
        context.dispatch("setMessage", {
          message: "Report Created : " + stringIds,
          class: "successMessage"
        });
      }
      context.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
    },
    downloadXLSReport(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
    
        const requestData = {
          clientId: context.state.selectedClient.clientId,
          message1: payload.reportType,
          id1: payload.reportId,
        };
    
        axios({
          method: "post",
          url: "/clinicians/ajax/downloadXLSReport",
          responseType: "blob",
          data: requestData,
          headers: {
            "Content-type": "application/json",
          },
        })
          .then((response) => {
            const contentDisposition = response.headers["Content-Disposition"];
            let filename = "downloaded_file.xlsx"; // Default filename
    
            if (contentDisposition) {
              const filenameRegex = /filename[^;=\n]=((['"]).?\2|[^;\n]*)/;
              const matches = filenameRegex.exec(contentDisposition);
              if (matches && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
              }
            }
    
            const type = response.headers["content-type"];
            const blob = new Blob([response.data], { type: type });
    
            // Convert blob to File object if File constructor is available
            let file;
            if (typeof File === "function") {
              file = new File([blob], filename, { type: type });
            }
    
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(downloadUrl);
    
            context.dispatch("setMessage", {
              message: "File ready.",
              class: "successMessage",
            });
    
            context.dispatch("hideLoadingSpinner");
            context.dispatch("reloadFromMethod");
          })
          .catch((error) => {
            context.dispatch("setMessage", {
              message: "Server Error...",
              class: "errorMessage",
            });
            context.dispatch("hideLoadingSpinner");
          });
      } else {
        context.dispatch("setMessage", {
          message: "Can't download without back end. ",
          class: "errorMessage",
        });
      }
    },
  //   downloadXLSReport(context, payload) {
  //     context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
  //     if (!context.state.appStandAlone) {
  //       context.dispatch("showLoadingSpinner");
        
  //       axios.post('/clinicians/ajax/downloadXLSReport', {
  //         clientId: context.state.selectedClient.clientId,
  //         message1: payload.reportType,
  //         id1: payload.reportId
  //       }, {
  //         responseType: 'blob'
  //       })
  //         .then(response => {
  //           if (response.status === 200 && response.data != null) {
  //     var filename = "";
  //     var disposition = response.headers['content-disposition'];
  //     if (disposition && disposition.indexOf("attachment") !== -1) {
  //       var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //       var matches = filenameRegex.exec(disposition);
  //       if (matches != null && matches[1]) {
  //         filename = matches[1].replace(/['"]/g, "");
  //       }
  //     }
  //     var type = response.headers['content-type'];
  //     var blob =
  //       typeof File === "function"
  //         ? new File([response.data], filename, { type: type })
  //         : new Blob([response.data], { type: type });
  //     if (typeof window.navigator.msSaveBlob !== "undefined") {
  //       // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
  //       // These URLs will no longer resolve as the data backing the URL has been freed."
  //       window.navigator.msSaveBlob(blob, filename);
  //     } else {
  //       var URL = window.URL || window.webkitURL;
  //       var downloadUrl = URL.createObjectURL(blob);

  //       if (filename) {
  //         // use HTML5 a[download] attribute to specify filename
  //         let a = document.createElement("a");
  //         a.style = "display: none";
  //         // safari doesn't support this yet
  //         if (typeof a.download === "undefined") {
  //           document.body.appendChild(a);
  //           a.href = downloadUrl;
  //           a.click();
  //         } else {
  //           a.href = downloadUrl;
  //           a.download = filename;
  //           document.body.appendChild(a);
  //           a.click();
  //         }
  //       } else {
  //         window.location = downloadUrl;
  //       }
  //       setTimeout(function () {
  //         URL.revokeObjectURL(downloadUrl);
  //       }, 100); // cleanup
  //     }

  //     context.dispatch("setMessage", {
  //       message: "File ready.",
  //       class: "successMessage"
  //     });

  //     context.dispatch("hideLoadingSpinner");
  //     context.dispatch("reloadFromMethod");
  //   } else {
  //     context.dispatch("setMessage", {
  //       message: "Server Error...",
  //       class: "errorMessage"
  //     });
  //     context.dispatch("hideLoadingSpinner");
  //   }
  // })
  // .catch(error => {
  //   context.dispatch("setMessage", {
  //     message: "Network error...",
  //     class: "errorMessage"
  //   });
  //   context.dispatch("hideLoadingSpinner");
  // });
  //       // ajax call to get results
  //       // var xmlhttp = new XMLHttpRequest();
  //       // var url = "/clinicians/ajax/downloadXLSReport";
  //       // xmlhttp.open("POST", url, true);
  //       // xmlhttp.setRequestHeader("Content-type", "application/json");
  //       // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
  //       // xmlhttp.responseType = "blob";
  //       // xmlhttp.onload = function() {
  //       //   if (xmlhttp.status == 200 && this.response != null) {
  //       //     var filename = "";
  //       //     var disposition = xmlhttp.getResponseHeader("Content-Disposition");
  //       //     if (disposition && disposition.indexOf("attachment") !== -1) {
  //       //       var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  //       //       var matches = filenameRegex.exec(disposition);
  //       //       if (matches != null && matches[1]) {
  //       //         filename = matches[1].replace(/['"]/g, "");
  //       //       }
  //       //     }
  //       //     var type = xmlhttp.getResponseHeader("Content-Type");
  //       //     var blob =
  //       //       typeof File === "function"
  //       //         ? new File([this.response], filename, { type: type })
  //       //         : new Blob([this.response], { type: type });
  //       //     if (typeof window.navigator.msSaveBlob !== "undefined") {
  //       //       // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
  //       //       // These URLs will no longer resolve as the data backing the URL has been freed."
  //       //       window.navigator.msSaveBlob(blob, filename);
  //       //     } else {
  //       //       var URL = window.URL || window.webkitURL;
  //       //       var downloadUrl = URL.createObjectURL(blob);

  //       //       if (filename) {
  //       //         // use HTML5 a[download] attribute to specify filename
  //       //         let a = document.createElement("a");
  //       //         a.style = "display: none";
  //       //         // safari doesn't support this yet
  //       //         if (typeof a.download === "undefined") {
  //       //           document.body.appendChild(a);
  //       //           a.href = downloadUrl;
  //       //           a.click();
  //       //         } else {
  //       //           a.href = downloadUrl;
  //       //           a.download = filename;
  //       //           document.body.appendChild(a);
  //       //           a.click();
  //       //         }
  //       //       } else {
  //       //         window.location = downloadUrl;
  //       //       }
  //       //       setTimeout(function() {
  //       //         URL.revokeObjectURL(downloadUrl);
  //       //       }, 100); // cleanup
  //       //     }

  //       //     context.dispatch("setMessage", {
  //       //       message: "File ready.",
  //       //       class: "successMessage"
  //       //     });

  //       //     context.dispatch("hideLoadingSpinner");
  //       //     context.dispatch("reloadFromMethod");
  //       //   } else {
  //       //     context.dispatch("setMessage", {
  //       //       message: "Server Error...",
  //       //       class: "errorMessage"
  //       //     });
  //       //     context.dispatch("hideLoadingSpinner");
  //       //   }
  //       // };
  //       // xmlhttp.onerror = function() {
  //       //   context.dispatch("setMessage", {
  //       //     message: "Network error...",
  //       //     class: "errorMessage"
  //       //   });
  //       //   context.dispatch("hideLoadingSpinner");
  //       // };

  //       // xmlhttp.send(
  //       //   JSON.stringify({
  //       //     clientId: context.state.selectedClient.clientId,
  //       //     message1: payload.reportType,
  //       //     id1: payload.reportId
  //       //   })
  //       // );
  //     } else {
  //       context.dispatch("setMessage", {
  //         message: "Can't download without back end. ",
  //         class: "errorMessage"
  //       });
  //     }
  //   },
    uploadFile(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
//         const formData = new FormData();
// formData.append('file', payload.formData.get('file'));

        axios.post('/clinicians/ajax/uploadFile', payload.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
          },
          responseType: 'json'
        })
          .then(response => {
            if (response.status === 200) {
              if (response.data.message === 'success') {
                context.dispatch("setMessage", {
                  message: "File Uploaded.",
                  class: "successMessage"
                });
                context.dispatch("hideLoadingSpinner");
                context.dispatch("reloadFromMethod");
              } else {
                context.dispatch("setMessage", {
                  message: response.data.message,
                  class: "errorMessage"
                });
                context.dispatch("hideLoadingSpinner");
              }
            } else if (response.status === 413) {
              context.dispatch("setMessage", {
                message: "Server - File Too Large Error",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/uploadFile";
        // xmlhttp.open("POST", url, true);
        // //xmlhttp.setRequestHeader("Content-type", "multipart/form-data");
        // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        // xmlhttp.responseType = "json";
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     if (xmlhttp.response.message == "success") {
        //       context.dispatch("setMessage", {
        //         message: "File Uploaded.",
        //         class: "successMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //       context.dispatch("reloadFromMethod");
        //     } else {
        //       context.dispatch("setMessage", {
        //         message: xmlhttp.response.message,
        //         class: "errorMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //     }
        //   } else if (xmlhttp.status == 413) {
        //     context.dispatch("setMessage", {
        //       message: "Server - File Too Large Error",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.send(payload.formData);
      } else {
        context.dispatch("setMessage", {
          message: "Can't upload without back end. ",
          class: "errorMessage"
        });
      }
    },
    deleteFile(context, payload) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/deleteFile', {
          clientId: context.state.selectedClient.clientId,
          id1: payload.fileId,
          id2: payload.memberId
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          responseType: 'json'
        })
          .then(response => {
            if (response.status === 200) {
              context.commit("SET_MEMBER_FILES", response.data);
              context.dispatch("setMessage", {
                message: "File Deleted",
                class: "successMessage"
              });
              context.dispatch("hideLoadingSpinner");
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/deleteFile";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        // xmlhttp.responseType = "json";
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     context.commit("SET_MEMBER_FILES", xmlhttp.response);
        //     context.dispatch("setMessage", {
        //       message: "File Deleted",
        //       class: "successMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     id1: payload.fileId,
        //     id2: payload.memberId
        //   })
        // );
      } else {
        for (
          let a = 0;
          a < context.state.memberDetail.memberFiles.length;
          a++
        ) {
          if (
            context.state.memberDetail.memberFiles[a].fileId == payload.fileId
          ) {
            context.state.memberDetail.memberFiles.splice(a, 1);
            context.dispatch("setMessage", {
              message: "File Deleted",
              class: "successMessage"
            });
            break;
          }
        }
      }
    },
    getFileAssignments(context, payload) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.post('/clinicians/ajax/getFileAssignments', {
          clientId: context.state.selectedClient.clientId,
          id: payload.fileId
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          responseType: 'json'
        })
          .then(response => {
            context.dispatch("hideLoadingSpinner");
            if (response.status === 200) {
              var responseObj = response.data;
              responseObj.deleteDialogText = context.state.deleteDialogText;
              if (responseObj.status == -1) {
                // can't delete file
                context.state.fileDeleteable = false;
                context.state.deleteDialogText =
                  "The file " +
                  responseObj.deleteDialogText +
                  " can't be deleted.";
              } else if (
                responseObj.memberIds != null &&
                responseObj.memberIds.length > 1
              ) {
                context.state.fileDeleteable = true;
                context.state.deleteDialogText =
                  "The file " +
                  responseObj.deleteDialogText +
                  " is also shared with the following members: " +
                  responseObj.memberIds +
                  ". Are you sure you want to delete this file?";
              } else {
                context.state.fileDeleteable = true;
                context.state.deleteDialogText =
                  "Are you sure you want to delete " +
                  responseObj.deleteDialogText +
                  "?";
              }
              context.state.deleteDialog = true;
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/getFileAssignments";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };
        // xmlhttp.onload = function() {
        //   context.dispatch("hideLoadingSpinner");
        //   if (xmlhttp.status == 200) {
        //     var responseObj = xmlhttp.response;
        //     responseObj.deleteDialogText = context.state.deleteDialogText;
        //     if (responseObj.status == -1) {
        //       // can't delete file
        //       context.state.fileDeleteable = false;
        //       context.state.deleteDialogText =
        //         "The file " +
        //         responseObj.deleteDialogText +
        //         " can't be deleted.";
        //     } else if (
        //       responseObj.memberIds != null &&
        //       responseObj.memberIds.length > 1
        //     ) {
        //       context.state.fileDeleteable = true;
        //       context.state.deleteDialogText =
        //         "The file " +
        //         responseObj.deleteDialogText +
        //         " is also shared with the following members: " +
        //         responseObj.memberIds +
        //         ". Are you sure you want to delete this file?";
        //     } else {
        //       context.state.fileDeleteable = true;
        //       context.state.deleteDialogText =
        //         "Are you sure you want to delete " +
        //         responseObj.deleteDialogText +
        //         "?";
        //     }
        //     context.state.deleteDialog = true;
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //   }
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     id: payload.fileId
        //   })
        // );
      } else {
        context.state.deleteDialogText =
          "Are you sure you want to delete " +
          context.state.deleteDialogText +
          "?";
        context.state.deleteDialog = true;
      }
    },
    renameFile(context, payload) {
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");

        axios.post('/clinicians/ajax/renameFile', {
          clientId: context.state.selectedClient.clientId,
          id1: payload.fileId,
          id2: payload.memberId,
          message1: payload.newFileName
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          responseType: 'json'
        })
          .then(response => {
            if (response.status === 200) {
              context.commit("SET_MEMBER_FILES", response.data);
              context.dispatch("setMessage", {
                message: "File Renamed",
                class: "successMessage"
              });
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
            }
            context.dispatch("hideLoadingSpinner");
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/renameFile";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        // xmlhttp.responseType = "json";
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     context.commit("SET_MEMBER_FILES", xmlhttp.response);
        //     context.dispatch("setMessage", {
        //       message: "File Renamed",
        //       class: "successMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: context.state.selectedClient.clientId,
        //     id1: payload.fileId,
        //     id2: payload.memberId,
        //     message1: payload.newFileName
        //   })
        // );
      } else {
        for (
          let a = 0;
          a < context.state.memberDetail.memberFiles.length;
          a++
        ) {
          if (
            context.state.memberDetail.memberFiles[a].fileId == payload.fileId
          ) {
            context.state.memberDetail.memberFiles[a].fileName =
              payload.newFileName;
            context.dispatch("setMessage", {
              message: "File Renamed",
              class: "successMessage"
            });
            break;
          }
        }
      }
    },
    reloadFromMethod(context) {
      switch (context.state.reloadMethod) {
        case "memberDetail":
          context.dispatch("getMemberDetail");
          break;
        case "qualifiedReport":
        case "printLetters":
        case "downloadReport":
          context.dispatch("getQualifiedReportMembers");
          break;
        case "criteriaReport":
          context.dispatch("getReportMembers");
          context.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
          break;
        case null:
        default:
      }
    },
    showLoadingSpinner() {
      var spinner = document.getElementById("loadingSpinner");
      var spinnerimg = document.getElementById("loadingSpinnerImg");
      spinnerimg.style.opacity = 0.01; // try to prevent jumping image
      spinner.style.display = "block"; // render before or else spinnerimg size = 0x0
      var myY = (window.innerHeight - spinnerimg.offsetHeight) / 2;
      var myX = (window.innerWidth - spinnerimg.offsetWidth) / 2;
      spinnerimg.style.left = myX + "px";
      spinnerimg.style.top = myY + "px";
      spinnerimg.style.opacity = 1;
    },
    hideLoadingSpinner() {
      var spinner = document.getElementById("loadingSpinner");
      spinner.style.display = "none";
    },
    setSelectedReport(context, payload) {
      context.commit("SET_SELECTED_REPORT", payload);
      context.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
    },
    setSelectedQualifiedReport(context, payload) {
      context.commit("SET_SELECTED_QUALIFIED_REPORT", payload);
    },
    getQualifiedReportMembers(context) {
      if (context.state.selectedQualifiedReport) {
        if (!context.state.appStandAlone) {
          context.dispatch("showLoadingSpinner");
          axios.post('/clinicians/ajax/getQualifiedMembers', {
            clientId: context.state.selectedClient.clientId,
            id: context.state.selectedQualifiedReport.id
          }, {
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'json'
          })
            .then(response => {
              if (response.status === 200) {
                var responseObj = response.data;
                context.commit("SET_QUALIFIED_REPORT_MEMBERS", responseObj);
                context.dispatch("hideLoadingSpinner");
              } else {
                var messageObj = {
                  message:
                    response.status === 504
                      ? "Network timeout - results not loaded"
                      : "Error loading report results...",
                  class: "errorMessage"
                };
                context.dispatch("setMessage", messageObj);
                context.commit("SET_QUALIFIED_REPORT_MEMBERS", []);
                context.dispatch("hideLoadingSpinner");
              }
            })
            .catch(error => {
              context.commit("SET_QUALIFIED_REPORT_MEMBERS", []);
              context.dispatch("setMessage", {
                message: "Network error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            });
          // ajax call to get results
          // var xmlhttp = new XMLHttpRequest();
          // var url = "/clinicians/ajax/getQualifiedMembers";
          // xmlhttp.open("POST", url, true);
          // xmlhttp.setRequestHeader("Content-type", "application/json");
          // xmlhttp.responseType = "json";
          // xmlhttp.onload = function() {
          //   if (xmlhttp.status == 200) {
          //     var responseObj = xmlhttp.response;
          //     context.commit("SET_QUALIFIED_REPORT_MEMBERS", responseObj);
          //     context.dispatch("hideLoadingSpinner");
          //   } else {
          //     var messageObj = {
          //       message:
          //         xmlhttp.status == 504
          //           ? "Network timeout - results not loaded"
          //           : "Error loading report results...",
          //       class: "errorMessage"
          //     };
          //     context.dispatch("setMessage", messageObj);
          //     context.commit("SET_QUALIFIED_REPORT_MEMBERS", []);
          //     context.dispatch("hideLoadingSpinner");
          //   }
          // };
          // xmlhttp.onerror = function() {
          //   context.commit("SET_QUALIFIED_REPORT_MEMBERS", []);
          //   context.dispatch("setMessage", {
          //     message: "Network error...",
          //     class: "errorMessage"
          //   });
          //   context.dispatch("hideLoadingSpinner");
          // };

          // xmlhttp.send(
          //   JSON.stringify({
          //     clientId: context.state.selectedClient.clientId,
          //     id: context.state.selectedQualifiedReport.id
          //   })
          // );
        } else {
          //demo data
          context.dispatch("showLoadingSpinner");
          setTimeout(function() {
            context.commit(
              "SET_QUALIFIED_REPORT_MEMBERS",
              context.state.demoQualifiedReportResults
            );
            context.dispatch("setMessage", {
              message: "demo Load complete",
              class: "successMessage"
            });
            context.dispatch("hideLoadingSpinner");
          }, 1000);
        }
      } else {
        context.commit("SET_QUALIFIED_REPORT_MEMBERS", []);
      }
    },
    setMainTab(context, payload) {
      context.commit("SET_MAIN_TAB", payload);
      context.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
    },
    setSelectDocumentId(context, payload) {
      context.commit("SET_DOCUCUMENT_ID", payload);
    },
    setSelectDocumenStatus(context, payload) {
      context.commit("SET_DOCUCUMENT_STATUS", payload);
    },
    setSelectDocumenStatusReview(context, payload) {
      context.commit("SET_DOCUCUMENT_STATUS_REVIEW", payload);
    },
    setSelectIsReview(context, payload) {
      context.commit("SET_IS_REVIEW", payload);
    },
    setClinicianName(context, payload) {
      context.commit("SET_CLINICIAN_NAME", payload);
    },
    setReviewerId(context, payload) {
      context.commit("SET_REVIEWER_ID", payload);
    },
    setSelectedClientById(context, payload) {
      var flag = false;
      if (
        context.state.appUser != null &&
        context.state.appUser.clients != null
      ) {
        if (payload == null || payload == 0) {
          // select first
          context.dispatch(
            "setSelectedClient",
            context.state.appUser.clients[0]
          );
          flag = true;
        } else {
          for (var i = 0; i < context.state.appUser.clients.length; i++) {
            if (context.state.appUser.clients[i].clientId == payload) {
              flag = true;
              context.dispatch(
                "setSelectedClient",
                context.state.appUser.clients[i]
              );
              break;
            }
          }
        }
      }
      if (!flag) {
        context.dispatch("setMessage", {
          message: "Error loading client",
          class: "errorMessage"
        });
      }
    },
    setSelectedClient(context, payload) {
      context.commit("SET_SELECTED_CLIENT", payload);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.
          post('/clinicians/ajax/updateLastClientId', {
            clientId: payload.clientId,
            id: context.state.appUser.user.userId
           })
          .then(response => {
            if (response.status !== 200) {
              context.dispatch("setMessage", {
                message: "Error updating user...",
                class: "errorMessage"
              });
            } else {
              context.dispatch("getTherapyFields");
            }
            context.dispatch("hideLoadingSpinner");
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        // ajax call to update db
        // const token = 'Bearer' + ' ' + localStorage.getItem('auth_token')
        // var xmlhttp = new XMLHttpRequest();
        // var url = "http://localhost:5000/clinicians/ajax/updateLastClientId";
        // xmlhttp.open("POST", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.setRequestHeader("Authorization", token);
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status != 200) {
        //     context.dispatch("setMessage", {
        //       message: "Error updating user...",
        //       class: "errorMessage"
        //     });
        //   } else {
        //     context.dispatch("getTherapyFields");
        //   }
        //   context.dispatch("hideLoadingSpinner");
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send(
        //   JSON.stringify({
        //     clientId: payload.clientId,
        //     id: context.state.appUser.user.userId
        //   })
        // );
      }
    },
    // demo
    resetDemoAcceptedInvite(context, payload) {
      context.commit("SET_RELOAD_METHOD", payload.reloadMethod);
      if (!context.state.appStandAlone) {
        context.dispatch("showLoadingSpinner");
        axios.get('/clinicians/ajax/resetDemoAcceptedInvite', {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'json'
        })
          .then(response => {
            if (response.status === 200) {
              var responseObj = response.data;
              if (responseObj >= 0) {
                context.dispatch("setMessage", {
                  message: "Demo Member Reset",
                  class: "successMessage"
                });
                context.dispatch("hideLoadingSpinner");
                context.dispatch("reloadFromMethod");
              } else {
                context.dispatch("setMessage", {
                  message: "Error resetting demo member",
                  class: "errorMessage"
                });
                context.dispatch("hideLoadingSpinner");
              }
            } else {
              context.dispatch("setMessage", {
                message: "Server Error...",
                class: "errorMessage"
              });
              context.dispatch("hideLoadingSpinner");
            }
          })
          .catch(error => {
            context.dispatch("setMessage", {
              message: "Network error...",
              class: "errorMessage"
            });
            context.dispatch("hideLoadingSpinner");
          });
        
        // ajax call to get results
        // var xmlhttp = new XMLHttpRequest();
        // var url = "/clinicians/ajax/resetDemoAcceptedInvite";
        // xmlhttp.open("GET", url, true);
        // xmlhttp.setRequestHeader("Content-type", "application/json");
        // xmlhttp.responseType = "json";
        // xmlhttp.onload = function() {
        //   if (xmlhttp.status == 200) {
        //     var responseObj = xmlhttp.response;
        //     if (responseObj >= 0) {
        //       context.dispatch("setMessage", {
        //         message: "Demo Member Reset",
        //         class: "successMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //       context.dispatch("reloadFromMethod");
        //     } else {
        //       context.dispatch("setMessage", {
        //         message: "Error resetting demo member",
        //         class: "errorMessage"
        //       });
        //       context.dispatch("hideLoadingSpinner");
        //     }
        //   } else {
        //     context.dispatch("setMessage", {
        //       message: "Server Error...",
        //       class: "errorMessage"
        //     });
        //     context.dispatch("hideLoadingSpinner");
        //   }
        // };
        // xmlhttp.onerror = function() {
        //   context.dispatch("setMessage", {
        //     message: "Network error...",
        //     class: "errorMessage"
        //   });
        //   context.dispatch("hideLoadingSpinner");
        // };

        // xmlhttp.send();
      } else {
        context.dispatch("setMessage", {
          message: "Reset Demo User - needs db connection",
          class: "errorMessage"
        });
      }
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated
    },
    tokenExpiration(state) {
      return state.tokenExpiration
    },
    // messaging
    message(state) {
      return state.message;
    },
    showMessage(state) {
      return state.showMessage;
    },
    showMessageClass(state) {
      return state.showMessageClass;
    },
    // criteria reports
    criteriaOptions(state) {
      return state.criteriaOptions;
    },
    selectedReport(state) {
      return state.selectedReport;
    },
    reportMembers(state) {
      return state.reportMembers;
    },
    allCriteriaReportMembers(state) {
      return state.allCriteriaReportMembers;
    },
    selectedMemberId(state) {
      return state.selectedMemberId;
    },
    //qualified reports
    qualifiedOptions(state) {
      return state.qualifiedOptions;
    },
    selectedQualifiedReport(state) {
      return state.selectedQualifiedReport;
    },
    qualifiedReportMembers(state) {
      return state.qualifiedReportMembers;
    },
    selectdocumentId(state) {
      return state.documentId;
    },
    selectdocumentStatus(state) {
      return state.documentStatus;
    },
    selectdocumentStatusReview(state) {
      return state.documentStatusReview;
    },
    selectisReview(state) {
      return state.isReview;
    },
    selectClinicianName(state) {
      return state.clinicianName;
    },
    selectReviewerId(state) {
      return state.reviewerId;
    },
    //member detail
    memberDetail(state) {
      return state.memberDetail;
    },
    criteriaReportSelectedMemberIds(state) {
      return state.criteriaReportSelectedMemberIds;
    },
    memberStatusItems(state) {
      return state.memberStatusItems;
    },
    deleteDialog(state) {
      return state.deleteDialog;
    },
    deleteDialogText(state) {
      return state.deleteDialogText;
    },
    fileDeleteable(state) {
      return state.fileDeleteable;
    },
    //nav
    mainTab(state) {
      return state.mainTab;
    },
    appStandAlone(state) {
      return state.appStandAlone;
    },
    appUser(state) {
      return state.appUser;
    },
    selectedClient(state) {
      return state.selectedClient;
    },
    privacyMode(state) {
      return state.privacyMode;
    },
    reloadMethod(state) {
      return state.reloadMethod;
    },
    //search
    memberSearchResults(state) {
      if (
        state.memberSearchResults != null &&
        state.memberSearchResults.length > 0
      ) {
        return state.memberSearchResults.map(item => ({
          memberId: item.member.memberId,
          firstName: item.member.firstName,
          middleName: item.member.middleName,
          lastName: item.member.lastName,
          dateOfBirth: item.member.dateOfBirth,
          gender: item.member.gender,
          memberSharedIdentityValue: item.memberSharedIdentityValue,
          memberIdentityValue: item.memberIdentityValue,
          memberCriteriaCount: item.memberCriteria.length,
          statusId:
            item.memberCriteria.length > 0
              ? item.memberCriteria[0].statusId
              : 0,
          statusName:
            item.memberCriteria.length > 0
              ? item.memberCriteria[0].statusName
              : "Dormant",
          invitationCode:
            item.memberRegistration == null
              ? null
              : item.memberRegistration.invitationCode,
          invitationFileId:
            item.memberRegistration != null &&
            item.memberRegistration.invitationFileId
              ? item.memberRegistration.invitationFileId
              : null
        }));
      } else {
        return [];
      }
    },
    memberSearchSelectedIds(state) {
      return state.memberSearchSelectedIds;
    },
    searchFields(state) {
      return state.searchFields;
    },
    therapyFields(state) {
      return state.therapyFields;
    },
    //demo data
    demoUser(state) {
      return state.demoAppUser;
    },
    demoTherapyFields(state) {
      return state.demoTherapyFields;
    }
  },
  state: {
    token: null,
    message: null,
    showMessage: false,
    showMessageClass: null,
    appStandAlone: false, // uses demo data instead of api calls if true
    privacyMode: true,
    appUser: null,
    selectedClient: null,
    selectedReport: null,
    selectedQualifiedReport: null,
    reloadMethod: null,
    documentId: null,
    documentStatus: null,
    documentStatusReview: null,
    isReview: null,
    clinicianName: null,
    reviewerId: null,
    criteriaOptions: [
      { id: 0, name: "All Criteria" },
      { id: 1, name: "GPI Limit" },
      { id: 2, name: "Prescriber Limit" },
      { id: 3, name: "Pharmacy Limit" },
      { id: 4, name: "Top Rx Spend" },
      { id: 5, name: "Top Medical Spend" },
      { id: 6, name: "GPI Inclusion" },
      { id: 7, name: "ICD-10 Inclusion" },
      { id: 101, name: "Re-Targeted" }
    ],
    qualifiedOptions: [
      // { id: 0, name: "Dormant" },
      { id: 1, name: "Targeted" },
      { id: 2, name: "Qualified" },
      { id: 100, name: "Not Qualified" },
      { id: 101, name: "Re-Targeted" },
      { id: 99, name: "Retired" },
      { id: 98, name: "Graduated" },
      { id: 10, name: "Invited - Team 1" },
      { id: 20, name: "Invited - Team 2" },
      { id: 11, name: "Accepted - Team 1" },
      { id: 21, name: "Accepted - Team 2" },
      { id: 12, name: "Managed - Team 1" },
      { id: 22, name: "Managed - Team 2" },
      { id: 19, name: "Rejected - Team 1" },
      { id: 29, name: "Rejected - Team 2" }
    ],
    memberStatusItems: [
      { value: 0, text: "Dormant" },
      { value: 1, text: "Targeted" },
      { value: 2, text: "Qualified" },
      { value: 100, text: "Not Qualified" },
      { value: 10, text: "Invited" },
      { value: 11, text: "Accepted" },
      { value: 12, text: "Managed" },
      { value: 19, text: "Declined" },
      { value: 98, text: "Graduated" },
      { value: 99, text: "Retired" },
    ],
    reportMembers: [],
    allCriteriaReportMembers: [],
    qualifiedReportMembers: [],
    selectedMemberId: null,
    memberDetail: null,
    criteriaReportSelectedMemberIds: [],
    qualifiedReportSelectedMemberIds: [],
    memberSearchSelectedIds: [],
    mainTab: null,
    memberSearchResults: [],
    searchFields: {
      memberText: null,
      diagnosisText: null,
      therapyText: null,
      drugText: null,
      includeNonTargeted: false,
      useAND: false
    },
    therapyFields: [],
    deleteDialog: false,
    deleteDialogText: "Are you sure you want to delete this file?",
    fileDeleteable: true,

    /** DEMO DATA **/
    /** not used when appStandAlone = false */
    demoMember2: {
      member: {
        memberId: 411,
        firstName: "JAMES",
        lastName: "ELLIOTT",
        middleName: null,
        nameSuffix: null,
        dateOfBirth: "1933-09-25",
        gender: "M",
        maritalStatus: "M",
        dateCreated: "2020-08-06T02:02:42.9066667",
        dateModified: null,
        vp_consent: true,
        vp_consent_time: "2020-08-06T02:02:42.9066667",
        vp_consent_user: "mvillanova",
        email_consent: false,
        email_consent_time: null,
        email_consent_user: null,
        phone_consent: false,
        phone_consent_time: null,
        phone_consent_user: null
      },
      memberPhones: [
        {
          memberPhoneId: 411,
          memberId: 411,
          phoneName: null,
          phoneNumber: "(626) 285-2963",
          confirmed: false,
          archived: false,
          dateCreated: "2020-08-06T02:02:42.91"
        }
      ],
      memberEmails: null,
      memberInsurances: [
        {
          memberInsuranceId: 411,
          memberId: 411,
          cardholderFirstName: null,
          cardholderLastName: null,
          cardholderMiddleName: null,
          eligibilityStatus: "N",
          eligibilityDate: "2002-10-01",
          stsDate: "1989-07-01",
          medicalPlan: "I",
          enrollCode: "",
          localNumber: "250",
          dateCreated: "2020-08-06T02:02:42.91"
        }
      ],
      memberAddresses: [
        {
          memberAddressId: 21,
          memberId: 411,
          addressName: null,
          streetAddress: "123 Main Street",
          streetAddress2: null,
          city: "Los Angeles",
          state: "CA",
          zipCode: "92110",
          zipPlus4: null,
          dateCreated: null,
          dateModified: null
        }
      ],
      medClaims: [
        {
          medClaimId: 123743,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CT ANGIOGRAPHY NECK W/CONT",
          serviceDate: "2019-07-28",
          serviceEndDate: null,
          procedureCode: "70498",
          procedureStartDate: "2019-07-28",
          procedureEndDate: null,
          totalPaidAmt: 18.17,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA RADIOLOGY MED GRP A PR",
          providerNPI: "1841228764",
          providerSpecialty: "",
          dateofDischarge: null,
          batchId: "CLMTAPE_OCT2019.csv",
          dateCreated: "2020-01-16T17:21:40.494",
          clientId: 2,
          icd10_01: "I65.22",
          icd10_02: "I67.2",
          icd10_04: "",
          icd10_03: "I63.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 35998,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 10",
          serviceDate: "2018-04-24",
          serviceEndDate:  null,
          procedureCode: "99212",
          procedureStartDate: "2018-04-24",
          procedureEndDate:  null,
          totalPaidAmt: 9.62,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAY2018.csv",
          dateCreated: "2020-01-16T17:16:11.821",
          clientId: 2,
          icd10_01: "L08.0",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 33509,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 10",
          serviceDate: "2018-04-17",
          serviceEndDate:  null,
          procedureCode: "99212",
          procedureStartDate: "2018-04-17",
          procedureEndDate:  null,
          totalPaidAmt: 9.62,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAY2018.csv",
          dateCreated: "2020-01-16T17:16:11.693",
          clientId: 2,
          icd10_01: "L08.0",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 32773,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 5",
          serviceDate: "2018-04-10",
          serviceEndDate:  null,
          procedureCode: "99211",
          procedureStartDate: "2018-04-10",
          procedureEndDate:  null,
          totalPaidAmt: 4.82,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAY2018.csv",
          dateCreated: "2020-01-16T17:16:11.669",
          clientId: 2,
          icd10_01: "L08.0",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 17421,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-03-16",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-03-16",
          procedureEndDate:  null,
          totalPaidAmt: 16.3,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_APR2018.csv",
          dateCreated: "2020-01-16T17:15:52.817",
          clientId: 2,
          icd10_01: "L13.9",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 17418,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 10",
          serviceDate: "2018-03-20",
          serviceEndDate:  null,
          procedureCode: "99212",
          procedureStartDate: "2018-03-20",
          procedureEndDate:  null,
          totalPaidAmt: 9.62,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_APR2018.csv",
          dateCreated: "2020-01-16T17:15:52.817",
          clientId: 2,
          icd10_01: "L08.0",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 16847,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-03-15",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-03-15",
          procedureEndDate:  null,
          totalPaidAmt: 15.81,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_APR2018.csv",
          dateCreated: "2020-01-16T17:15:52.8",
          clientId: 2,
          icd10_01: "L03.90",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 16846,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT NEW 30 M",
          serviceDate: "2018-03-16",
          serviceEndDate:  null,
          procedureCode: "99203",
          procedureStartDate: "2018-03-16",
          procedureEndDate:  null,
          totalPaidAmt: 23.27,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_APR2018.csv",
          dateCreated: "2020-01-16T17:15:52.8",
          clientId: 2,
          icd10_01: "B35.3",
          icd10_02: "L08.0",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 9289,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "RADEX SACRUM & COCCYX MINI",
          serviceDate: "2018-03-02",
          serviceEndDate:  null,
          procedureCode: "72220",
          procedureStartDate: "2018-03-02",
          procedureEndDate:  null,
          totalPaidAmt: 6.35,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAR2018.csv",
          dateCreated: "2020-01-16T17:15:32.732",
          clientId: 2,
          icd10_01: "M53.3",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 9288,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "RADIOLOGIC EXAMINATION KNE",
          serviceDate: "2018-03-02",
          serviceEndDate:  null,
          procedureCode: "73562",
          procedureStartDate: "2018-03-02",
          procedureEndDate:  null,
          totalPaidAmt: 8.07,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAR2018.csv",
          dateCreated: "2020-01-16T17:15:32.732",
          clientId: 2,
          icd10_01: "S89.91XA",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 8715,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-03-02",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-03-02",
          procedureEndDate:  null,
          totalPaidAmt: 33.78,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAR2018.csv",
          dateCreated: "2020-01-16T17:15:32.708",
          clientId: 2,
          icd10_01: "S89.90XA",
          icd10_02: "M53.3",
          icd10_04: "",
          icd10_03: "J06.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 2101,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-01-22",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-01-22",
          procedureEndDate:  null,
          totalPaidAmt: 10.54,
          patientCostShare: 68.51,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_FEB2018.csv",
          dateCreated: "2020-01-16T17:12:31.003",
          clientId: 2,
          icd10_01: "R42",
          icd10_02: "I10",
          icd10_04: "",
          icd10_03: "E78.5",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 2100,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-01-16",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-01-16",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 80.7,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_FEB2018.csv",
          dateCreated: "2020-01-16T17:12:31.003",
          clientId: 2,
          icd10_01: "R10.9",
          icd10_02: "R42",
          icd10_04: "",
          icd10_03: "N18.3",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 2099,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "COLLECTION VENOUS BLOOD VE",
          serviceDate: "2018-01-16",
          serviceEndDate:  null,
          procedureCode: "36415",
          procedureStartDate: "2018-01-16",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_FEB2018.csv",
          dateCreated: "2020-01-16T17:12:31.003",
          clientId: 2,
          icd10_01: "R10.9",
          icd10_02: "R42",
          icd10_04: "",
          icd10_03: "N18.3",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 107856,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "GROUND MILEAGE PER STATUTE",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "A0425",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 5.29,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "CARE AMBULANCE SERVICE INC",
          providerNPI: "1639131436",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.509",
          clientId: 2,
          icd10_01: "R56.9",
          icd10_02: "M62.81",
          icd10_04: "",
          icd10_03: "R73.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 107855,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "AMB SERVICE ALS EMERGENCY",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "A0427",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 100.15,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "CARE AMBULANCE SERVICE INC",
          providerNPI: "1639131436",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.509",
          clientId: 2,
          icd10_01: "R56.9",
          icd10_02: "M62.81",
          icd10_04: "",
          icd10_03: "R73.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103530,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "ASSAY OF THYROID STIMULATI",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "84443",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "P",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103529,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "BLOOD COUNT COMPLETE AUTO&",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "85025",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "P",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103528,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "COMPREHENSIVE METABOLIC PA",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "80053",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "P",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103527,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "GENERAL HEALTH PANEL",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "80050",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103526,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "ALBUMIN URINE MICROALBUMIN",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "82043",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103525,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "25 HYDROXY INCLUDES FRACTI",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "82306",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103524,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "HEMOGLOBIN FRACTJ/QUANTJ C",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "83021",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103523,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "URINALYSIS MICROSCOPIC ONL",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "81015",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103522,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "URNLS DIP STICK/TABLET RGN",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "81003",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103521,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "ASSAY OF BLOOD/URIC ACID",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "84550",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103520,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "COLLECTION VENOUS BLOOD VE",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "36415",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103519,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CREATININE OTHER SOURCE",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "82570",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103518,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "LIPID PANEL",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "80061",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103517,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CULTURE BACTERIAL QUANTTAT",
          serviceDate: "2019-08-26",
          serviceEndDate:  null,
          procedureCode: "87086",
          procedureStartDate: "2019-08-26",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "BIOLOGICAL LABORATORY INC",
          providerNPI: "1700913118",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.469",
          clientId: 2,
          icd10_01: "E11.9",
          icd10_02: "E78.5",
          icd10_04: "",
          icd10_03: "E55.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 103102,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "INITIAL HOSPITAL CARE/DAY",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "99223",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 43.74,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.454",
          clientId: 2,
          icd10_01: "I63.9",
          icd10_02: "K72.90",
          icd10_04: "I10",
          icd10_03: "E87.2",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 102495,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "MRI BRAIN BRAIN STEM W/O C",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "70551",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 16.22,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA RADIOLOGY MED GRP A PR",
          providerNPI: "1841228764",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_AUG2019.csv",
          dateCreated: "2020-01-16T17:21:03.02",
          clientId: 2,
          icd10_01: "I63.9",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 102488,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "RADIOLOGIC EXAMINATION, CH",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "71045",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 2.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA RADIOLOGY MED GRP A PR",
          providerNPI: "1841228764",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_AUG2019.csv",
          dateCreated: "2020-01-16T17:21:03.02",
          clientId: 2,
          icd10_01: "I63.9",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 102219,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CRITICAL CARE ILL/INJURED",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "99291",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 47.67,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "MICHAEL S AGRON MD INC",
          providerNPI: "1699716100",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_AUG2019.csv",
          dateCreated: "2020-01-16T17:21:03.008",
          clientId: 2,
          icd10_01: "I63.9",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 97109,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "INITIAL HOSPITAL CARE/DAY",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "99223",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 58.31,
          patientCostShare: 42.45,
          procedureType: "",
          providerName: "VINCENT M FORTANASCE MD INC A",
          providerNPI: "1568567055",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_AUG2019.csv",
          dateCreated: "2020-01-16T17:21:02.922",
          clientId: 2,
          icd10_01: "I63.9",
          icd10_02: "R53.1",
          icd10_04: "E78.5",
          icd10_03: "I25.10",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 67435,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "RADEX HIP UNILATERAL WITH",
          serviceDate: "2018-07-10",
          serviceEndDate:  null,
          procedureCode: "73502",
          procedureStartDate: "2018-07-10",
          procedureEndDate:  null,
          totalPaidAmt: 9.35,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_AUG2018.csv",
          dateCreated: "2020-01-16T17:17:09.673",
          clientId: 2,
          icd10_01: "M25.552",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 65195,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-07-20",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-07-20",
          procedureEndDate:  null,
          totalPaidAmt: 15.81,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_AUG2018.csv",
          dateCreated: "2020-01-16T17:17:09.58",
          clientId: 2,
          icd10_01: "I87.2",
          icd10_02: "L08.0",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 63362,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-07-10",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-07-10",
          procedureEndDate:  null,
          totalPaidAmt: 15.81,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_AUG2018.csv",
          dateCreated: "2020-01-16T17:17:09.526",
          clientId: 2,
          icd10_01: "I10",
          icd10_02: "M25.552",
          icd10_04: "",
          icd10_03: "R05",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 56282,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2018-06-25",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2018-06-25",
          procedureEndDate:  null,
          totalPaidAmt: 15.81,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_JUL2018.csv",
          dateCreated: "2020-01-16T17:16:50.742",
          clientId: 2,
          icd10_01: "M54.30",
          icd10_02: "M17.10",
          icd10_04: "I10",
          icd10_03: "H26.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 42110,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 5",
          serviceDate: "2018-05-08",
          serviceEndDate:  null,
          procedureCode: "99211",
          procedureStartDate: "2018-05-08",
          procedureEndDate:  null,
          totalPaidAmt: 4.82,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_JUN2018.csv",
          dateCreated: "2020-01-16T17:16:31.669",
          clientId: 2,
          icd10_01: "R60.0",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 37417,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 10",
          serviceDate: "2018-05-01",
          serviceEndDate:  null,
          procedureCode: "99212",
          procedureStartDate: "2018-05-01",
          procedureEndDate:  null,
          totalPaidAmt: 9.62,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAY2018.csv",
          dateCreated: "2020-01-16T17:16:11.862",
          clientId: 2,
          icd10_01: "L08.0",
          icd10_02: "L29.9",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 28197,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 10",
          serviceDate: "2018-04-03",
          serviceEndDate:  null,
          procedureCode: "99212",
          procedureStartDate: "2018-04-03",
          procedureEndDate:  null,
          totalPaidAmt: 9.62,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_APR2018.csv",
          dateCreated: "2020-01-16T17:15:53.109",
          clientId: 2,
          icd10_01: "L08.0",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 23644,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 10",
          serviceDate: "2018-03-27",
          serviceEndDate:  null,
          procedureCode: "99212",
          procedureStartDate: "2018-03-27",
          procedureEndDate:  null,
          totalPaidAmt: 9.62,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_APR2018.csv",
          dateCreated: "2020-01-16T17:15:53.036",
          clientId: 2,
          icd10_01: "L03.90",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 20793,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "PLATELET AGGREGATION IN VI",
          serviceDate: "2019-07-29",
          serviceEndDate:  null,
          procedureCode: "85576",
          procedureStartDate: "2019-07-29",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "PATHOLOGY ASSOCIATES OF ARCADI",
          providerNPI: "1659633493",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.336",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "",
          icd10_03: "E78.5",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 20792,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "PLATELET AGGREGATION IN VI",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "85576",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "PATHOLOGY ASSOCIATES OF ARCADI",
          providerNPI: "1659633493",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.336",
          clientId: 2,
          icd10_01: "I63.81",
          icd10_02: "E87.2",
          icd10_04: "",
          icd10_03: "G81.91",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19185,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 25",
          serviceDate: "2019-08-07",
          serviceEndDate:  null,
          procedureCode: "99232",
          procedureStartDate: "2019-08-07",
          procedureEndDate:  null,
          totalPaidAmt: 15.73,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.281",
          clientId: 2,
          icd10_01: "E87.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19180,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-08-05",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-08-05",
          procedureEndDate:  null,
          totalPaidAmt: 22.48,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.28",
          clientId: 2,
          icd10_01: "E87.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19179,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-08-04",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-08-04",
          procedureEndDate:  null,
          totalPaidAmt: 22.48,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.28",
          clientId: 2,
          icd10_01: "E78.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19178,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-08-03",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-08-03",
          procedureEndDate:  null,
          totalPaidAmt: 22.48,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.28",
          clientId: 2,
          icd10_01: "E87.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19177,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-08-02",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-08-02",
          procedureEndDate:  null,
          totalPaidAmt: 22.48,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.28",
          clientId: 2,
          icd10_01: "E87.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19176,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-08-01",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-08-01",
          procedureEndDate:  null,
          totalPaidAmt: 22.48,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.28",
          clientId: 2,
          icd10_01: "E87.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19175,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-07-31",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-07-31",
          procedureEndDate:  null,
          totalPaidAmt: 22.48,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.28",
          clientId: 2,
          icd10_01: "E87.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 19174,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-07-30",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-07-30",
          procedureEndDate:  null,
          totalPaidAmt: 22.48,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_DEC2019.csv",
          dateCreated: "2020-01-16T17:22:25.28",
          clientId: 2,
          icd10_01: "E87.5",
          icd10_02: "I25.10",
          icd10_04: "",
          icd10_03: "Z85.46",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130679,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT NEW 45 M",
          serviceDate: "2019-09-11",
          serviceEndDate:  null,
          procedureCode: "99204",
          procedureStartDate: "2019-09-11",
          procedureEndDate:  null,
          totalPaidAmt: 36.25,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "MEDFORD ORTHOPAEDIC MED CORP",
          providerNPI: "1831330844",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.37",
          clientId: 2,
          icd10_01: "M25.561",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130478,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "RADIOLOGIC EXAMINATION KNE",
          serviceDate: "2019-08-16",
          serviceEndDate:  null,
          procedureCode: "73562",
          procedureStartDate: "2019-08-16",
          procedureEndDate:  null,
          totalPaidAmt: 8.67,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "CHIEH-JEN YANG",
          providerNPI: "1902998370",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.363",
          clientId: 2,
          icd10_01: "M23.203",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130477,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "MRI ANY JT LOWER EXTREM W/",
          serviceDate: "2019-08-27",
          serviceEndDate:  null,
          procedureCode: "73721",
          procedureStartDate: "2019-08-27",
          procedureEndDate:  null,
          totalPaidAmt: 54.11,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "CHIEH-JEN YANG",
          providerNPI: "1902998370",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.363",
          clientId: 2,
          icd10_01: "S83.231A",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 119925,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "WALKER FOLDING WHEELED ADJ",
          serviceDate: "2019-08-09",
          serviceEndDate:  null,
          procedureCode: "E0143",
          procedureStartDate: "2019-08-09",
          procedureEndDate:  null,
          totalPaidAmt: 10.43,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "WILBUR HOME HLTH CARE AND MEDI",
          providerNPI: "1366646218",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_OCT2019.csv",
          dateCreated: "2020-01-16T17:21:40.349",
          clientId: 2,
          icd10_01: "I63.9",
          icd10_02: "G81.91",
          icd10_04: "L03.115",
          icd10_03: "I25.10",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 112806,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CT ANGIOGRAPHY HEAD W/CONT",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "70496",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 19.12,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA RADIOLOGY MED GRP A PR",
          providerNPI: "1841228764",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.588",
          clientId: 2,
          icd10_01: "I65.03",
          icd10_02: "I67.2",
          icd10_04: "",
          icd10_03: "I63.9",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 112593,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 25",
          serviceDate: "2019-08-06",
          serviceEndDate:  null,
          procedureCode: "99232",
          procedureStartDate: "2019-08-06",
          procedureEndDate:  null,
          totalPaidAmt: 15.73,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.579",
          clientId: 2,
          icd10_01: "I63.81",
          icd10_02: "M62.81",
          icd10_04: "",
          icd10_03: "E78.5",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 112592,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "HOSPITAL DISCHARGE DAY MAN",
          serviceDate: "2019-07-29",
          serviceEndDate:  null,
          procedureCode: "99238",
          procedureStartDate: "2019-07-29",
          procedureEndDate:  null,
          totalPaidAmt: 15.95,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "ARCADIA HOSPITALIST MED GRP IN",
          providerNPI: "1114365269",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_SEP2019.csv",
          dateCreated: "2020-01-16T17:21:22.579",
          clientId: 2,
          icd10_01: "I63.81",
          icd10_02: "M62.81",
          icd10_04: "",
          icd10_03: "E78.5",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 140894,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OFFICE OUTPATIENT VISIT 15",
          serviceDate: "2019-02-27",
          serviceEndDate:  null,
          procedureCode: "99213",
          procedureStartDate: "2019-02-27",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 82.86,
          procedureType: "",
          providerName: "HEALTHCARE PARTNERS MED GRP",
          providerNPI: "1659312593",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_MAR2019.csv",
          dateCreated: "2020-01-16T17:19:21.201",
          clientId: 2,
          icd10_01: "L08.0",
          icd10_02: "I87.2",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 131315,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SBSQ HOSPITAL CARE/DAY 35",
          serviceDate: "2019-07-29",
          serviceEndDate:  null,
          procedureCode: "99233",
          procedureStartDate: "2019-07-29",
          procedureEndDate:  null,
          totalPaidAmt: 52.03,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "KC TAN MD MEDICAL CORPORATION",
          providerNPI: "1215394036",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.388",
          clientId: 2,
          icd10_01: "I63.81",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 131314,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "INITIAL HOSPITAL CARE/DAY",
          serviceDate: "2019-07-28",
          serviceEndDate:  null,
          procedureCode: "99222",
          procedureStartDate: "2019-07-28",
          procedureEndDate:  null,
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "KC TAN MD MEDICAL CORPORATION",
          providerNPI: "1215394036",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.388",
          clientId: 2,
          icd10_01: "I63.81",
          icd10_02: "",
          icd10_04: "",
          icd10_03: "",
          icd10_07: "",
          icd10_05: "",
          icd10_06: "",
          icd10_08: "",
          icd10_11: "",
          icd10_10: "",
          icd10_09: "",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130894,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OTHER DIAGNOSTIC SERVICES",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00921",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.377",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130893,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "EKG/ECG (ELECTROCARDIOGRAM",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00730",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130892,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "MAGNETIC RESONANCE TECHNOL",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00611",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130891,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CARDIOLOGY - ECHOCARDIOLOG",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00483",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130890,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "PULMONARY FUNCTION - GENER",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00460",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130889,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "EMERGENCY ROOM - GENERAL C",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00450",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130888,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "SPEECH-LANGUAGE PATHOLOGY",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00444",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130887,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OCCUPATIONAL THERAPY - EVA",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00434",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130886,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "OCCUPATIONAL THERAPY - GEN",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00430",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130885,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "PHYSICAL THERAPY - EVALUAT",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00424",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130884,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "PHYSICAL THERAPY - GENERAL",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00420",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130883,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CT SCAN - HEAD SCAN",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00351",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130882,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "CT SCAN - GENERAL CLASSIFI",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00350",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130881,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "RADIOLOGY - DIAGNOSTIC - C",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00324",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130880,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "LABORATORY - GENERAL CLASS",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00300",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130879,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "'MEDICAL/SURGICAL SUPPLIES",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00272",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130878,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "'PHARMACY (ALSO SEE 063X)",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00250",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 1364.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130877,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "ROOM & BOARD - PRIVATE (ON",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00118",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        },
        {
          medClaimId: 130876,
          patientFirstName: "JAMES",
          patientLastName: "ELLIOTT",
          patientMiddleName: "A",
          patientNameSuffix: "",
          patientDateOfBirth: "1933-09-25 00:00:00.0000000",
          patientMemberId: "T50013165",
          planName: "PMII -RX - RETIREE MEDICARE II",
          medCondDesc: "HEALTH INSURANCE - PROSPEC",
          serviceDate: "2019-07-29",
          serviceEndDate: "2019-08-07",
          procedureCode: "00024",
          procedureStartDate: "2019-07-29",
          procedureEndDate: "2019-08-07",
          totalPaidAmt: 0.0,
          patientCostShare: 0.0,
          procedureType: "",
          providerName: "METHODIST HOSP OF SOUTHERN CAL",
          providerNPI: "1508851288",
          providerSpecialty: "",
          dateofDischarge:  null,
          batchId: "CLMTAPE_NOV2019.csv",
          dateCreated: "2020-01-16T17:21:57.376",
          clientId: 2,
          icd10_01: "I69.351",
          icd10_02: "N39.0",
          icd10_04: "B96.1",
          icd10_03: "E78.5",
          icd10_07: "K21.9",
          icd10_05: "I10",
          icd10_06: "I25.10",
          icd10_08: "N31.9",
          icd10_11: "Z85.46",
          icd10_10: "Z79.82",
          icd10_09: "Z79.02",
          icd10_12: "",
          providerNPIName: "Tommy R Doctorguy MD",
          providerNPIAddress: "123 Doctors Ave",
          providerNPICity: "Los Angeles",
          providerNPIState: "CA",
          providerNPIZip: "90210",
          providerNPIPhone: "213-555-1212",
          providerNPIFax: "213-555-3434"
        }
      ],
      rxClaims: [
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-10",
          dateService: "2018-10-03",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 15.0,
          totalRxCost: 5.69,
          patientPayAmt: 1.42,
          netAmtDue: 4.27,
          daysSupplyDisp: 5,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2152070,
          phaSvcProvId: "1477598472",
          prescrId: "1376892810",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT1253715810035G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-02",
          dateService: "2018-02-14",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "00406048410",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 43.34,
          patientPayAmt: 43.34,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2053919,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT1707515802142G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-02",
          dateService: "2019-02-04",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 38.49,
          patientPayAmt: 38.49,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2210215,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT1834282902042G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-02",
          dateService: "2020-02-10",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "MUPIROCIN OIN 2%",
          prodSvcId: "68462018022",
          drugGPI: "90100065104210",
          fillNbr: 0,
          nbrRefAuth: 1,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 22.0,
          totalRxCost: 9.89,
          patientPayAmt: 9.89,
          netAmtDue: 0.0,
          daysSupplyDisp: 14,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120876,
          phaSvcProvId: "1417989922",
          prescrId: "1528492808",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2114038002106G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antibiotics - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-02",
          dateService: "2020-02-10",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "FLUOCINONIDE CRE 0.05%",
          prodSvcId: "51672138603",
          drugGPI: "90550060003705",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 56.99,
          patientPayAmt: 56.99,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "U&C",
          rxSvcRefNbr: 1120877,
          phaSvcProvId: "1417989922",
          prescrId: "1528492808",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2116204002109G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Corticosteroids - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-02",
          dateService: "2020-02-10",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "DOXYCYCL HYC TAB 100MG",
          prodSvcId: "53489012005",
          drugGPI: "04000020100310",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 14.0,
          totalRxCost: 5.71,
          patientPayAmt: 5.71,
          netAmtDue: 0.0,
          daysSupplyDisp: 14,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120873,
          phaSvcProvId: "1417989922",
          prescrId: "1528492808",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2117064002106G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Tetracyclines",
          therapClass: "Anti-Infectives",
          therapSubClass: "Tetracyclines",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-02",
          dateService: "2020-02-10",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 3,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 30.0,
          totalRxCost: 10.03,
          patientPayAmt: 10.03,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120872,
          phaSvcProvId: "1417989922",
          prescrId: "1679675904",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2121170002107G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-02",
          dateService: "2020-02-10",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLOTRIM/BETA CRE DIPROP",
          prodSvcId: "00472037915",
          drugGPI: "90159902053710",
          fillNbr: 0,
          nbrRefAuth: 1,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 34.23,
          patientPayAmt: 34.23,
          netAmtDue: 0.0,
          daysSupplyDisp: 28,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120949,
          phaSvcProvId: "1417989922",
          prescrId: "1528492808",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2260902002104G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antifungals - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-02",
          dateService: "2020-02-10",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLOTRIMAZOLE SOL 1%",
          prodSvcId: "51672126003",
          drugGPI: "90154020002005",
          fillNbr: 0,
          nbrRefAuth: 3,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 30.0,
          totalRxCost: 59.74,
          patientPayAmt: 59.74,
          netAmtDue: 0.0,
          daysSupplyDisp: 28,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 1120947,
          phaSvcProvId: "1417989922",
          prescrId: "1528492808",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2281536002100G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antifungals - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-01",
          dateService: "2018-01-01",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 3,
          nbrRefAuth: 3,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 24.0,
          totalRxCost: 8.71,
          patientPayAmt: 8.71,
          netAmtDue: 0.0,
          daysSupplyDisp: 8,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 1911901,
          phaSvcProvId: "1477598472",
          prescrId: "1043498728",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2438556801014G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-14",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 1,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 24.0,
          totalRxCost: 8.71,
          patientPayAmt: 8.71,
          netAmtDue: 0.0,
          daysSupplyDisp: 8,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2061092,
          phaSvcProvId: "1477598472",
          prescrId: "1043498728",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2446321803149G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-04",
          dateService: "2018-04-11",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "00406048410",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 43.6,
          patientPayAmt: 10.9,
          netAmtDue: 32.7,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2078597,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2446902804116G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-15",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "00406048401",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 43.6,
          patientPayAmt: 43.6,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2066507,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2618116803151G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-02",
          dateService: "2019-02-27",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "MUPIROCIN OIN 2%",
          prodSvcId: "68462018022",
          drugGPI: "90100065104210",
          fillNbr: 0,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 22.0,
          totalRxCost: 8.79,
          patientPayAmt: 8.79,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2221945,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2697819902277G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antibiotics - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-03",
          dateService: "2020-03-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLOTRIM/BETA CRE DIPROP",
          prodSvcId: "00472037915",
          drugGPI: "90159902053710",
          fillNbr: 1,
          nbrRefAuth: 1,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 31.41,
          patientPayAmt: 31.41,
          netAmtDue: 0.0,
          daysSupplyDisp: 28,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120949,
          phaSvcProvId: "1417989922",
          prescrId: "1528492808",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2706023003276G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antifungals - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-01",
          dateService: "2018-01-02",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 44.9,
          patientPayAmt: 44.9,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2031991,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2849302801022G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-05",
          dateService: "2018-05-29",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLINDAMYCIN LOT 10MG/ML",
          prodSvcId: "59762374401",
          drugGPI: "90051010104105",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 96.59,
          patientPayAmt: 24.15,
          netAmtDue: 72.44,
          daysSupplyDisp: 30,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2101634,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2863659805290G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Acne Products",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-15",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "DOXYCYCL HYC TAB 100MG",
          prodSvcId: "53489012005",
          drugGPI: "04000020100310",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 14.0,
          totalRxCost: 14.67,
          patientPayAmt: 14.67,
          netAmtDue: 0.0,
          daysSupplyDisp: 7,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2068136,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2870897803155G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Tetracyclines",
          therapClass: "Anti-Infectives",
          therapSubClass: "Tetracyclines",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-06",
          dateService: "2020-06-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLOTRIM/BETA CRE DIPROP",
          prodSvcId: "00472037915",
          drugGPI: "90159902053710",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 29.3,
          patientPayAmt: 29.3,
          netAmtDue: 0.0,
          daysSupplyDisp: 28,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1145690,
          phaSvcProvId: "1417989922",
          prescrId: "1528492808",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT2933920006262G",
          uniqueTxRefIdNbr: null,
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antifungals - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-11",
          dateService: "2018-11-29",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 21.0,
          totalRxCost: 7.59,
          patientPayAmt: 1.9,
          netAmtDue: 5.69,
          daysSupplyDisp: 7,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2178752,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT3262346811296G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-16",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "TERBINAFINE TAB 250MG",
          prodSvcId: "65862007930",
          drugGPI: "11000080100310",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 14.0,
          totalRxCost: 6.6,
          patientPayAmt: 6.6,
          netAmtDue: 0.0,
          daysSupplyDisp: 14,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2068696,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT3297851803168G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antifungals",
          therapClass: "Anti-Infectives",
          therapSubClass: "Antifungals",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-16",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLINDAMYCIN LOT 10MG/ML",
          prodSvcId: "59762374401",
          drugGPI: "90051010104105",
          fillNbr: 0,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 101.7,
          patientPayAmt: 101.7,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2068697,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT3297983803168G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Acne Products",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-05",
          dateService: "2018-05-10",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 43.6,
          patientPayAmt: 10.9,
          netAmtDue: 32.7,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2092245,
          phaSvcProvId: "1477598472",
          prescrId: "1386751899",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT3492319805106G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-12",
          dateService: "2018-12-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 41.15,
          patientPayAmt: 10.29,
          netAmtDue: 30.86,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2191232,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT3715198812264G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-12",
          dateService: "2018-12-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 21.0,
          totalRxCost: 7.59,
          patientPayAmt: 1.9,
          netAmtDue: 5.69,
          daysSupplyDisp: 7,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2191233,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT3715679812260G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-08",
          dateService: "2019-08-06",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLOPIDOGREL TAB 75MG",
          prodSvcId: "60505025302",
          drugGPI: "85158020100320",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 30.0,
          totalRxCost: 8.62,
          patientPayAmt: 8.62,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2300710,
          phaSvcProvId: "1477598472",
          prescrId: "1033210497",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT4447062908061G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Platelet Aggregation Inhibitors",
          therapClass: "Hematologicals",
          therapSubClass: "HEMATOLOGICAL AGENTS - MISC.",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-08",
          dateService: "2019-08-06",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "ATORVASTATIN TAB 40MG",
          prodSvcId: "60505258009",
          drugGPI: "39400010100330",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 30.0,
          totalRxCost: 10.17,
          patientPayAmt: 10.17,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2300711,
          phaSvcProvId: "1477598472",
          prescrId: "1033210497",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT4447077908069G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "HMG CoA Reductase Inhibitors",
          therapClass: "Cardiovasculars",
          therapSubClass: "ANTIHYPERLIPIDEMICS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-04",
          dateService: "2018-04-17",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "LEVOFLOXACIN TAB 500MG",
          prodSvcId: "65862053750",
          drugGPI: "05000034000330",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 21.0,
          totalRxCost: 20.64,
          patientPayAmt: 5.16,
          netAmtDue: 15.48,
          daysSupplyDisp: 21,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2083174,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT4527307804173G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Fluoroquinolones",
          therapClass: "Anti-Infectives",
          therapSubClass: "Fluoroquinolones",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-03",
          dateService: "2019-03-05",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 38.49,
          patientPayAmt: 38.49,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2224785,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT4814242903053G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-11",
          dateService: "2019-11-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 36.14,
          patientPayAmt: 9.04,
          netAmtDue: 27.1,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2355860,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT5070601911263G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-06",
          dateService: "2020-06-14",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 3,
          nbrRefAuth: 3,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 30.0,
          totalRxCost: 8.66,
          patientPayAmt: 8.66,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120872,
          phaSvcProvId: "1417989922",
          prescrId: "1679675904",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT5468922006145G",
          uniqueTxRefIdNbr: null,
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-06",
          dateService: "2020-06-14",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "MUPIROCIN OIN 2%",
          prodSvcId: "68462018022",
          drugGPI: "90100065104210",
          fillNbr: 2,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 22.0,
          totalRxCost: 8.54,
          patientPayAmt: 8.54,
          netAmtDue: 0.0,
          daysSupplyDisp: 10,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1137672,
          phaSvcProvId: "1417989922",
          prescrId: "1063447134",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT5469200006149G",
          uniqueTxRefIdNbr: null,
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antibiotics - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-06",
          dateService: "2020-06-14",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "FLUOCINONIDE OIN 0.05%",
          prodSvcId: "00093026492",
          drugGPI: "90550060004205",
          fillNbr: 2,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 47.7,
          patientPayAmt: 47.7,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1137673,
          phaSvcProvId: "1417989922",
          prescrId: "1063447134",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT5469349006141G",
          uniqueTxRefIdNbr: null,
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Corticosteroids - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-24",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 2,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 24.0,
          totalRxCost: 8.71,
          patientPayAmt: 8.71,
          netAmtDue: 0.0,
          daysSupplyDisp: 8,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2061092,
          phaSvcProvId: "1477598472",
          prescrId: "1043498728",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT5847290803249G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-06",
          dateService: "2018-06-06",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 43.6,
          patientPayAmt: 10.9,
          netAmtDue: 32.7,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2105204,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT5897524806063G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-01",
          dateService: "2019-01-23",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 21.0,
          totalRxCost: 7.48,
          patientPayAmt: 7.48,
          netAmtDue: 0.0,
          daysSupplyDisp: 7,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2204409,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT5915164901231G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-10",
          dateService: "2018-10-17",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 41.15,
          patientPayAmt: 10.29,
          netAmtDue: 30.86,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2160173,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6067992810179G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-05",
          dateService: "2020-05-28",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "FLUOCINONIDE OIN 0.05%",
          prodSvcId: "00093026492",
          drugGPI: "90550060004205",
          fillNbr: 1,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 47.7,
          patientPayAmt: 47.7,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1137673,
          phaSvcProvId: "1417989922",
          prescrId: "1063447134",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6361105005283G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Corticosteroids - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-05",
          dateService: "2020-05-28",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "MUPIROCIN OIN 2%",
          prodSvcId: "68462018022",
          drugGPI: "90100065104210",
          fillNbr: 1,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 22.0,
          totalRxCost: 8.54,
          patientPayAmt: 8.54,
          netAmtDue: 0.0,
          daysSupplyDisp: 10,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1137672,
          phaSvcProvId: "1417989922",
          prescrId: "1063447134",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6362631005282G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antibiotics - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-04",
          dateService: "2019-04-18",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 38.23,
          patientPayAmt: 38.23,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2246891,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6567400904189G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-27",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "LEVOFLOXACIN TAB 750MG",
          prodSvcId: "65862053820",
          drugGPI: "05000034000340",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 10.0,
          totalRxCost: 14.98,
          patientPayAmt: 14.98,
          netAmtDue: 0.0,
          daysSupplyDisp: 10,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2073645,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6582742803273G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Fluoroquinolones",
          therapClass: "Anti-Infectives",
          therapSubClass: "Fluoroquinolones",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-05",
          dateService: "2020-05-11",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "MUPIROCIN OIN 2%",
          prodSvcId: "68462018022",
          drugGPI: "90100065104210",
          fillNbr: 0,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 22.0,
          totalRxCost: 8.54,
          patientPayAmt: 8.54,
          netAmtDue: 0.0,
          daysSupplyDisp: 10,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1137672,
          phaSvcProvId: "1417989922",
          prescrId: "1063447134",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6934984005114G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antibiotics - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-05",
          dateService: "2020-05-11",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "FLUOCINONIDE OIN 0.05%",
          prodSvcId: "00093026492",
          drugGPI: "90550060004205",
          fillNbr: 0,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 47.7,
          patientPayAmt: 47.7,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1137673,
          phaSvcProvId: "1417989922",
          prescrId: "1063447134",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6944805005117G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Corticosteroids - Topical",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-03",
          dateService: "2018-03-01",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 24.0,
          totalRxCost: 8.71,
          patientPayAmt: 8.71,
          netAmtDue: 0.0,
          daysSupplyDisp: 8,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2061092,
          phaSvcProvId: "1477598472",
          prescrId: "1043498728",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT6998361803019G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-10",
          dateService: "2019-10-29",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 36.14,
          patientPayAmt: 13.18,
          netAmtDue: 22.96,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2339647,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT7158595910294G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-09",
          dateService: "2019-09-12",
          channelCCRX: "Retail 90 Service Channel",
          groupId: "KS7",
          drugName: "CLOPIDOGREL TAB 75MG",
          prodSvcId: "60505025302",
          drugGPI: "85158020100320",
          fillNbr: 0,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 90.0,
          totalRxCost: 42.1,
          patientPayAmt: 42.1,
          netAmtDue: 0.0,
          daysSupplyDisp: 90,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2319145,
          phaSvcProvId: "1477598472",
          prescrId: "1679675904",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT7384846909124G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Platelet Aggregation Inhibitors",
          therapClass: "Hematologicals",
          therapSubClass: "HEMATOLOGICAL AGENTS - MISC.",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-09",
          dateService: "2019-09-12",
          channelCCRX: "Retail 90 Service Channel",
          groupId: "KS7",
          drugName: "ATORVASTATIN TAB 40MG",
          prodSvcId: "60505258009",
          drugGPI: "39400010100330",
          fillNbr: 0,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 90.0,
          totalRxCost: 50.57,
          patientPayAmt: 50.57,
          netAmtDue: 0.0,
          daysSupplyDisp: 90,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2319146,
          phaSvcProvId: "1477598472",
          prescrId: "1679675904",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT7385134909121G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "HMG CoA Reductase Inhibitors",
          therapClass: "Cardiovasculars",
          therapSubClass: "ANTIHYPERLIPIDEMICS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-07",
          dateService: "2018-07-03",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLINDAMYCIN LOT 10MG/ML",
          prodSvcId: "59762374401",
          drugGPI: "90051010104105",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 96.59,
          patientPayAmt: 24.15,
          netAmtDue: 72.44,
          daysSupplyDisp: 30,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2116732,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT7677122807039G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Acne Products",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-01",
          dateService: "2018-01-16",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "MECLIZINE TAB 25MG",
          prodSvcId: "42806001410",
          drugGPI: "50200050000310",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 42.0,
          totalRxCost: 12.7,
          patientPayAmt: 12.7,
          netAmtDue: 0.0,
          daysSupplyDisp: 14,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2038841,
          phaSvcProvId: "1477598472",
          prescrId: "1861422370",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT7750362801161G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Antiemetics - Anticholinergic",
          therapClass: "Gastrointestinal",
          therapSubClass: "ANTIEMETICS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-07",
          dateService: "2018-07-04",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "00093015010",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 29.27,
          patientPayAmt: 7.32,
          netAmtDue: 21.95,
          daysSupplyDisp: 30,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2115246,
          phaSvcProvId: "1477598472",
          prescrId: "1588819775",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT7791556807046G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-06",
          dateService: "2019-06-19",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 38.23,
          patientPayAmt: 38.23,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2277206,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT7816426906194G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-09",
          dateService: "2018-09-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 16.0,
          totalRxCost: 6.01,
          patientPayAmt: 1.5,
          netAmtDue: 4.51,
          daysSupplyDisp: 10,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2151583,
          phaSvcProvId: "1477598472",
          prescrId: "1376892810",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT8026879809262G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-08",
          dateService: "2019-08-30",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 46.86,
          patientPayAmt: 46.86,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2312285,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT8072820908306G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-03",
          dateService: "2020-03-07",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 1,
          nbrRefAuth: 3,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 30.0,
          totalRxCost: 9.25,
          patientPayAmt: 9.25,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120872,
          phaSvcProvId: "1417989922",
          prescrId: "1679675904",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT8852464003077G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-09",
          dateService: "2018-09-04",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 41.15,
          patientPayAmt: 10.29,
          netAmtDue: 30.86,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2141951,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT8883672809046G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-11",
          dateService: "2018-11-20",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 41.15,
          patientPayAmt: 10.29,
          netAmtDue: 30.86,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2174766,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9166486811209G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-04",
          dateService: "2020-04-27",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 2,
          nbrRefAuth: 3,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09160",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 30.0,
          totalRxCost: 8.66,
          patientPayAmt: 8.66,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 1120872,
          phaSvcProvId: "1417989922",
          prescrId: "1679675904",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9256172004273G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5622837"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-10",
          dateService: "2019-10-02",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 36.14,
          patientPayAmt: 36.14,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2326358,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9275520910025G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-07",
          dateService: "2019-07-29",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 21.0,
          totalRxCost: 7.08,
          patientPayAmt: 7.08,
          netAmtDue: 0.0,
          daysSupplyDisp: 7,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2296921,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9415722907296G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2019-07",
          dateService: "2019-07-29",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 38.23,
          patientPayAmt: 38.23,
          netAmtDue: 0.0,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2296923,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9416036907291G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-01",
          dateService: "2019-12-20",
          channelCCRX: "Retail 90 Service Channel",
          groupId: "KS7",
          drugName: "ATORVASTATIN TAB 40MG",
          prodSvcId: "60505258009",
          drugGPI: "39400010100330",
          fillNbr: 1,
          nbrRefAuth: 2,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 90.0,
          totalRxCost: 40.68,
          patientPayAmt: 10.17,
          netAmtDue: 30.51,
          daysSupplyDisp: 90,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2319146,
          phaSvcProvId: "1477598472",
          prescrId: "1679675904",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9576571001249G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "HMG CoA Reductase Inhibitors",
          therapClass: "Cardiovasculars",
          therapSubClass: "ANTIHYPERLIPIDEMICS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-01",
          dateService: "2019-12-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "APAP/CODEINE TAB 300-30M",
          prodSvcId: "13107005999",
          drugGPI: "65991002050315",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 120.0,
          totalRxCost: 36.14,
          patientPayAmt: 9.04,
          netAmtDue: 27.1,
          daysSupplyDisp: 30,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2373199,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9576575001247G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Opioid Combinations",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2020-01",
          dateService: "2019-12-26",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "AMOXICILLIN CAP 500MG",
          prodSvcId: "65862001705",
          drugGPI: "01200010100110",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY #09721",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 21.0,
          totalRxCost: 6.73,
          patientPayAmt: 1.68,
          netAmtDue: 5.05,
          daysSupplyDisp: 7,
          basisCostCcrx: "MAC",
          rxSvcRefNbr: 2373200,
          phaSvcProvId: "1477598472",
          prescrId: "1831465707",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9576577001241G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Aminopenicillins",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        },
        {
          clientCode: "ACREFRIG",
          cardholderId: "T50013165",
          adjYearMo: "2018-04",
          dateService: "2018-04-06",
          channelCCRX: "Retail Service Channel",
          groupId: "KS7",
          drugName: "CLINDAMYCIN LOT 10MG/ML",
          prodSvcId: "59762374401",
          drugGPI: "90051010104105",
          fillNbr: 0,
          nbrRefAuth: 0,
          cobClaimDesc: "PRIMARY",
          otherPayerPatRespAmtQual01: "",
          pharmacyName: "CVS PHARMACY",
          phaAddressLine1: "215 DEININGER CIRCLE",
          phaAddressLine2: null,
          phaCity: "CORONA",
          pharState: "CA",
          phaZipCode5: "92880",
          phaZip4: "",
          phaTelephoneNbr: "8006717171",
          brandClassCCRXPrice: "Generic",
          qtyDisp: 60.0,
          totalRxCost: 101.7,
          patientPayAmt: 91.18,
          netAmtDue: 10.52,
          daysSupplyDisp: 30,
          basisCostCcrx: "AWP",
          rxSvcRefNbr: 2070569,
          phaSvcProvId: "1477598472",
          prescrId: "1750439873",
          prescrFirstName: "Tommy",
          prescrLastName: "Doctorguy",
          prescrPhone: "213-555-1212",
          prescrNPIName: "Tommy R Doctorguy MD",
          prescrNPIAddress: "123 Doctors Ave",
          prescrNPICity: "Los Angeles",
          prescrNPIState: "CA",
          prescrNPIZip: "90210",
          prescrNPIPhone: "213-555-1212",
          prescrNPIFax: "213-555-3434",
          dispAsWritProdSelCode: "0",
          uniqueTxNbr: "ACREFRIGNAVIT9882237804066G",
          uniqueTxRefIdNbr: "",
          patientId: "T50013165-D12320",
          patientFirstName: "JAMES",
          patientMiddleInitial: "A",
          patientLastName: "ELLIOTT",
          patientDtOfBirth: "1933-09-25",
          patientPersonCode: "01",
          therapCategory: "Acne Products",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          patientGenderCode: "M",
          patientZipCode5: "91775",
          patientZip4: "1101",
          patientState: null,
          patientAddressLine1: null,
          patientAddressLine2: null,
          patientCity: null,
          ncpdpid: "5620415"
        }
      ],
      memberCriterias: [
        {
          memberId: 411,
          criteriaId: 7,
          criteriaName: "ICD10Inclusion",
          criteriaDescription:
            "Has an ICD-10 code that is on the inclusion list",
          dateIdentified: "2018-03-31",
          code:
            '{"version:1,"count:2,"list:"E78.5,I10","names:"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
          year: 2018,
          period: "Q1",
          statusId: 10,
          statusName: "Invited1",
          namelist: [
            "Hyperlipidemia, Unspecified",
            "Hypertension, Unspecified"
          ],
          codelist: ["E78.5", "I10"],
          version: 1,
          threshold: 0
        },
        {
          memberId: 411,
          criteriaId: 7,
          criteriaName: "ICD10Inclusion",
          criteriaDescription:
            "Has an ICD-10 code that is on the inclusion list",
          dateIdentified: "2018-06-30",
          code:
            '{"version:1,"count:1,"list:"I10","names:"Hypertension, Unspecified"}',
          year: 2018,
          period: "Q2",
          statusId: 10,
          statusName: "Invited1",
          namelist: ["Hypertension, Unspecified"],
          codelist: ["I10"],
          version: 1,
          threshold: 0
        },
        {
          memberId: 411,
          criteriaId: 7,
          criteriaName: "ICD10Inclusion",
          criteriaDescription:
            "Has an ICD-10 code that is on the inclusion list",
          dateIdentified: "2018-09-30",
          code:
            '{"version:1,"count:1,"list:"I10","names:"Hypertension, Unspecified"}',
          year: 2018,
          period: "Q3",
          statusId: 10,
          statusName: "Invited1",
          namelist: ["Hypertension, Unspecified"],
          codelist: ["I10"],
          version: 1,
          threshold: 0
        },
        {
          memberId: 411,
          criteriaId: 7,
          criteriaName: "ICD10Inclusion",
          criteriaDescription:
            "Has an ICD-10 code that is on the inclusion list",
          dateIdentified: "2019-09-30",
          code:
            '{"version:1,"count:7,"list:"E11.9,E55.9,E78.5,I10,I25.10,I63.9,R73.9","names:"Diabetes Mellitus, II Uncontrolled|Vitamin D Deficiency|Hyperlipidemia, Unspecified|Hypertension, Unspecified|ASHD Coronary Artery|Stroke|Hyperglycemia"}',
          year: 2019,
          period: "Q3",
          statusId: 10,
          statusName: "Invited1",
          namelist: [
            "Diabetes Mellitus, II Uncontrolled",
            "Vitamin D Deficiency",
            "Hyperlipidemia, Unspecified",
            "Hypertension, Unspecified",
            "ASHD Coronary Artery",
            "Stroke",
            "Hyperglycemia"
          ],
          codelist: [
            "E11.9",
            "E55.9",
            "E78.5",
            "I10",
            "I25.10",
            "I63.9",
            "R73.9"
          ],
          version: 1,
          threshold: 0
        }
      ],
      memberSharedIdentities: [
        {
          memberId: 411,
          identityTypeId: 1,
          identityValue: "T50013165-D12320",
          clientId: 2,
          dateCreated: "2020-08-06T02:02:42.91"
        },
        {
          memberId: 2475,
          identityTypeId: 2,
          identityValue: "T50013165",
          clientId: 2,
          dateCreated: "2020-08-06T02:04:52.58"
        },
        {
          memberId: 411,
          identityTypeId: 2,
          identityValue: "T50013165",
          clientId: 2,
          dateCreated: "2020-08-06T02:02:42.91"
        }
      ],
      memberFiles: [
        {
          fileId: 3,
          appUserOwnerId: 0,
          dateCreated: "2021-02-11T19:35:41.79",
          dateDeleted: null,
          fileName: "Invitations_Team1_2021-02-03_165057.pdf",
          fileSize: 224668,
          fileType: "application/pdf",
          active: true,
          memberId: 411,
          assignedOn: "2021-02-10T19:35:41.79"
        },
        {
          fileId: 4,
          appUserOwnerId: 100,
          dateCreated: "2021-02-05T12:23:25.48",
          dateDeleted: null,
          fileName: "flowers.png",
          fileSize: 35476,
          fileType: "image/png",
          active: true,
          memberId: 411,
          assignedOn: "2021-02-05T12:23:25.48"
        }
      ],
      familyMembers: [
        {
          memberId: 2475,
          firstName: "YA",
          lastName: "MAO ELLIOTT",
          middleName: "",
          nameSuffix: null,
          dateOfBirth: "1959-08-13",
          gender: null,
          maritalStatus: null,
          streetAddress: null,
          streetAddress2: null,
          city: null,
          state: null,
          zipCode: "91775",
          zipPlus4: null,
          email: null,
          dateCreated: "2020-08-06T02:04:52.5766667",
          dateModified: null
        }
      ],
      drugs: [
        {
          status: "D",
          labelName: "TRIMOX CAP 500MG",
          drugName: "TRIMOX",
          drugExtension: "",
          strength: 500.0,
          dosage: "CAPS",
          units: "MG",
          packageSize: 300.0,
          packageSizeUnits: "EA",
          packageQuantity: 12.0,
          repackCode: "",
          therapEquiv: "AB",
          genericName: "Amoxicillin (Trihydrate) Cap 500 MG",
          thirdParty: "",
          labeler: "00003",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "3",
          genericCode: "Y",
          genericID: "061336707",
          lastChanged: "2006-08-31",
          modified: "2009-06-11",
          therapClass: "Anti-Infectives",
          therapSubClass: "PENICILLINS",
          therapCategory: "Aminopenicillins",
          brandCode: null,
          deacode: 0,
          ndc: "00003010920",
          ahfscode: "081216",
          gpicode: "01200010100110",
          tcrfcode: "0120001010",
          gppc: null,
          ppg: "FB20B29"
        },
        {
          status: "I",
          labelName: "TYLENOL/COD TAB #3",
          drugName: "TYLENOL/CODEINE #3",
          drugExtension: null,
          strength: 0.0,
          dosage: "TABS",
          units: null,
          packageSize: 100.0,
          packageSizeUnits: "EA",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "AA",
          genericName: "Acetaminophen w/ Codeine Tab 300-30 MG",
          thirdParty: "",
          labeler: "00045",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "O",
          genericID: "999988740",
          lastChanged: "2010-12-22",
          modified: "2013-08-30",
          therapClass: "Analgesics/Anesthetics",
          therapSubClass: "ANALGESICS - OPIOID",
          therapCategory: "Opioid Combinations",
          brandCode: "O",
          deacode: 3,
          ndc: "00045051360",
          ahfscode: "280808",
          gpicode: "65991002050315",
          tcrfcode: "6599100205",
          gppc: "03639003",
          ppg: "FD10B50"
        },
        {
          status: "D",
          labelName: "BACTROBAN OIN 2%",
          drugName: "BACTROBAN",
          drugExtension: "",
          strength: 2.0,
          dosage: "OINT",
          units: "%",
          packageSize: 150.0,
          packageSizeUnits: "GM",
          packageQuantity: 1.0,
          repackCode: "",
          therapEquiv: "NR",
          genericName: "Mupirocin Oint 2%",
          thirdParty: "",
          labeler: "00029",
          rx_OTC: "R",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "EX",
          formType: "S",
          dollarRank: "2",
          rxRank: "2",
          genericCode: "N",
          genericID: "012650690",
          lastChanged: "2002-05-30",
          modified: "2009-06-11",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          therapCategory: "Antibiotics - Topical",
          brandCode: null,
          deacode: 0,
          ndc: "00029152522",
          ahfscode: "840404",
          gpicode: "90100065104210",
          tcrfcode: "9010006510",
          gppc: null,
          ppg: "TI95B10"
        },
        {
          status: "D",
          labelName: "LIDEX CRE 0.05%",
          drugName: "LIDEX",
          drugExtension: "",
          strength: 0.0,
          dosage: "CREA",
          units: "%",
          packageSize: 15.0,
          packageSizeUnits: "GM",
          packageQuantity: 1.0,
          repackCode: "",
          therapEquiv: "AB",
          genericName: "Fluocinonide Cream 0.05%",
          thirdParty: "",
          labeler: "00033",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "EX",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "O",
          genericID: "000356127",
          lastChanged: "1999-05-20",
          modified: "2009-06-11",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          therapCategory: "Corticosteroids - Topical",
          brandCode: null,
          deacode: 0,
          ndc: "00033251113",
          ahfscode: "840600",
          gpicode: "90550060003705",
          tcrfcode: "9055006000",
          gppc: null,
          ppg: "CR40B10"
        },
        {
          status: "D",
          labelName: "DOXYCYCL HYC TAB 100MG",
          drugName: "DOXYCYCLINE HYCLATE",
          drugExtension: "",
          strength: 100.0,
          dosage: "TABS",
          units: "MG",
          packageSize: 50.0,
          packageSizeUnits: "EA",
          packageQuantity: 1.0,
          repackCode: "",
          therapEquiv: "AB",
          genericName: "Doxycycline Hyclate Tab 100 MG",
          thirdParty: "",
          labeler: "00003",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "Y",
          genericID: "024390145",
          lastChanged: "2000-06-15",
          modified: "2009-06-11",
          therapClass: "Anti-Infectives",
          therapSubClass: "Tetracyclines",
          therapCategory: "Tetracyclines",
          brandCode: null,
          deacode: 0,
          ndc: "00003081240",
          ahfscode: "081224",
          gpicode: "04000020100310",
          tcrfcode: "0400002010",
          gppc: null,
          ppg: "TA84B40"
        },
        {
          status: "I",
          labelName: "LOTRISONE CRE",
          drugName: "LOTRISONE",
          drugExtension: null,
          strength: 0.0,
          dosage: "CREA",
          units: null,
          packageSize: 15.0,
          packageSizeUnits: "GM",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "AB",
          genericName: "Clotrimazole w/ Betamethasone Cream 1-0.05%",
          thirdParty: "",
          labeler: "00085",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "EX",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "O",
          genericID: "999989649",
          lastChanged: "2020-04-09",
          modified: "2020-04-17",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          therapCategory: "Antifungals - Topical",
          brandCode: "T",
          deacode: 0,
          ndc: "00085092401",
          ahfscode: "840408",
          gpicode: "90159902053710",
          tcrfcode: "9015990205",
          gppc: "05459D86",
          ppg: "OX77B10"
        },
        {
          status: "D",
          labelName: "MYCELEX SOL  1%",
          drugName: "MYCELEX",
          drugExtension: "",
          strength: 1.0,
          dosage: "SOLN",
          units: "%",
          packageSize: 10.0,
          packageSizeUnits: "ML",
          packageQuantity: 1.0,
          repackCode: "",
          therapEquiv: "AT",
          genericName: "Clotrimazole Soln 1%",
          thirdParty: "",
          labeler: "00026",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "EX",
          formType: "L",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "O",
          genericID: "023593751",
          lastChanged: "2001-09-26",
          modified: "2009-06-11",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          therapCategory: "Antifungals - Topical",
          brandCode: null,
          deacode: 0,
          ndc: "00026309210",
          ahfscode: "840408",
          gpicode: "90154020002005",
          tcrfcode: "9015402000",
          gppc: null,
          ppg: "DC50B30"
        },
        {
          status: "A",
          labelName: "CLEOCIN-T LOT 1%",
          drugName: "CLEOCIN-T",
          drugExtension: null,
          strength: 1.0,
          dosage: "LOTN",
          units: "%",
          packageSize: 60.0,
          packageSizeUnits: "ML",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "AB",
          genericName: "Clindamycin Phosphate Lotion 1%",
          thirdParty: "",
          labeler: "99907",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "EX",
          formType: "L",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "O",
          genericID: "024729962",
          lastChanged: "2020-01-02",
          modified: "2020-01-03",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          therapCategory: "Acne Products",
          brandCode: "T",
          deacode: 0,
          ndc: "00009332901",
          ahfscode: "840404",
          gpicode: "90051010104105",
          tcrfcode: "9005101010",
          gppc: "05336011",
          ppg: "AY40B50"
        },
        {
          status: "D",
          labelName: "TERBINAFINE TAB 250MG",
          drugName: "TERBINAFINE HCL",
          drugExtension: null,
          strength: 250.0,
          dosage: "TABS",
          units: "MG",
          packageSize: 30.0,
          packageSizeUnits: "EA",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "AB",
          genericName: "Terbinafine HCl Tab 250 MG",
          thirdParty: "",
          labeler: "00054",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "Y",
          genericID: "078628805",
          lastChanged: "2011-11-03",
          modified: "2013-08-31",
          therapClass: "Anti-Infectives",
          therapSubClass: "Antifungals",
          therapCategory: "Antifungals",
          brandCode: "Y",
          deacode: 0,
          ndc: "00054006513",
          ahfscode: "081404",
          gpicode: "11000080100310",
          tcrfcode: "1100008010",
          gppc: "10948070",
          ppg: "IY698YI"
        },
        {
          status: "A",
          labelName: "CLOPIDOGREL TAB 75MG",
          drugName: "CLOPIDOGREL",
          drugExtension: null,
          strength: 75.0,
          dosage: "TABS",
          units: "MG",
          packageSize: 500.0,
          packageSizeUnits: "EA",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "AB",
          genericName: "Clopidogrel Bisulfate Tab 75 MG (Base Equiv)",
          thirdParty: "",
          labeler: "00093",
          rx_OTC: "S",
          maintenance: "X",
          unitDose: "",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "Y",
          genericID: "120202666",
          lastChanged: "2019-03-21",
          modified: "2019-03-29",
          therapClass: "Hematologicals",
          therapSubClass: "HEMATOLOGICAL AGENTS - MISC.",
          therapCategory: "Platelet Aggregation Inhibitors",
          brandCode: "G",
          deacode: 0,
          ndc: "00093731405",
          ahfscode: "201218",
          gpicode: "85158020100320",
          tcrfcode: "8515802010",
          gppc: "22645027",
          ppg: "89Y7IGH"
        },
        {
          status: "A",
          labelName: "LIPITOR TAB 40MG",
          drugName: "LIPITOR",
          drugExtension: null,
          strength: 40.0,
          dosage: "TABS",
          units: "MG",
          packageSize: 90.0,
          packageSizeUnits: "EA",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "AB",
          genericName: "Atorvastatin Calcium Tab 40 MG (Base Equivalent)",
          thirdParty: "",
          labeler: "99907",
          rx_OTC: "S",
          maintenance: "X",
          unitDose: "",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "O",
          genericID: "134523038",
          lastChanged: "2020-01-02",
          modified: "2020-01-03",
          therapClass: "Cardiovasculars",
          therapSubClass: "ANTIHYPERLIPIDEMICS",
          therapCategory: "HMG CoA Reductase Inhibitors",
          brandCode: "T",
          deacode: 0,
          ndc: "00071015723",
          ahfscode: "240608",
          gpicode: "39400010100330",
          tcrfcode: "3940001010",
          gppc: "17607966",
          ppg: "AD09I65"
        },
        {
          status: "D",
          labelName: "LEVAQUIN TAB 500MG",
          drugName: "LEVAQUIN",
          drugExtension: null,
          strength: 500.0,
          dosage: "TABS",
          units: "MG",
          packageSize: 100.0,
          packageSizeUnits: "EA",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "NA",
          genericName: "Levofloxacin Tab 500 MG",
          thirdParty: "",
          labeler: "00045",
          rx_OTC: "R",
          maintenance: "",
          unitDose: "X",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "N",
          genericID: "138199710",
          lastChanged: "2011-09-15",
          modified: "2013-08-31",
          therapClass: "Anti-Infectives",
          therapSubClass: "Fluoroquinolones",
          therapCategory: "Fluoroquinolones",
          brandCode: "N",
          deacode: 0,
          ndc: "00045152510",
          ahfscode: "081218",
          gpicode: "05000034000330",
          tcrfcode: "0500003400",
          gppc: "17445028",
          ppg: "AD39L70"
        },
        {
          status: "D",
          labelName: "LIDEX OIN 0.05%",
          drugName: "LIDEX",
          drugExtension: "",
          strength: 0.0,
          dosage: "OINT",
          units: "%",
          packageSize: 15.0,
          packageSizeUnits: "GM",
          packageQuantity: 1.0,
          repackCode: "",
          therapEquiv: "AB",
          genericName: "Fluocinonide Oint 0.05%",
          thirdParty: "",
          labeler: "00033",
          rx_OTC: "S",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "EX",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "O",
          genericID: "000356127",
          lastChanged: "1999-05-20",
          modified: "2009-06-11",
          therapClass: "Topicals",
          therapSubClass: "DERMATOLOGICALS",
          therapCategory: "Corticosteroids - Topical",
          brandCode: null,
          deacode: 0,
          ndc: "00033251413",
          ahfscode: "840600",
          gpicode: "90550060004205",
          tcrfcode: "9055006000",
          gppc: null,
          ppg: "CR40B80"
        },
        {
          status: "D",
          labelName: "LEVAQUIN TAB LEVA-PA",
          drugName: "LEVAQUIN LEVA-PAK",
          drugExtension: null,
          strength: 750.0,
          dosage: "TABS",
          units: "MG",
          packageSize: 5.0,
          packageSizeUnits: "EA",
          packageQuantity: 3.0,
          repackCode: null,
          therapEquiv: "AB",
          genericName: "Levofloxacin Tab 750 MG",
          thirdParty: "",
          labeler: "00045",
          rx_OTC: "R",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "N",
          genericID: "138199710",
          lastChanged: "2011-01-13",
          modified: "2013-08-30",
          therapClass: "Anti-Infectives",
          therapSubClass: "Fluoroquinolones",
          therapCategory: "Fluoroquinolones",
          brandCode: "N",
          deacode: 0,
          ndc: "00045153005",
          ahfscode: "081218",
          gpicode: "05000034000340",
          tcrfcode: "0500003400",
          gppc: "30276HYK",
          ppg: "LEVAQUI"
        },
        {
          status: "D",
          labelName: "DRAMAMINE TAB 25MG",
          drugName: "DRAMAMINE LESS DROWSY",
          drugExtension: null,
          strength: 25.0,
          dosage: "TABS",
          units: "MG",
          packageSize: 8.0,
          packageSizeUnits: "EA",
          packageQuantity: 1.0,
          repackCode: null,
          therapEquiv: "NA",
          genericName: "Meclizine HCl Tab 25 MG",
          thirdParty: "",
          labeler: "57935",
          rx_OTC: "P",
          maintenance: "",
          unitDose: "U",
          routeOfAdmin: "OR",
          formType: "S",
          dollarRank: "0",
          rxRank: "0",
          genericCode: "Y",
          genericID: "036236676",
          lastChanged: "2018-11-22",
          modified: "2018-11-26",
          therapClass: "Gastrointestinal",
          therapSubClass: "ANTIEMETICS",
          therapCategory: "Antiemetics - Anticholinergic",
          brandCode: "T",
          deacode: 0,
          ndc: "00009364801",
          ahfscode: "562208",
          gpicode: "50200050000310",
          tcrfcode: "5020005000",
          gppc: "02735340",
          ppg: "YW84D20"
        }
      ],
      notes: [
        {
          userNoteId: 11,
          memberId: 411,
          userId: 1,
          username: "dbadmin1",
          dateCreated: "2020-08-21T15:29:15.137",
          dateModified: null,
          replacedByNoteId: null,
          replacedByUserId: null,
          note: "I think it's morning now? 9:30am pdt? yeah?"
        },
        {
          userNoteId: 7,
          memberId: 411,
          userId: 3,
          username: "dbclinician1",
          dateCreated: "2020-08-21T07:21:21.743",
          dateModified: null,
          replacedByNoteId: null,
          replacedByUserId: null,
          note: "my own note"
        },
        {
          userNoteId: 4,
          memberId: 411,
          userId: 1,
          username: "dbadmin1",
          dateCreated: "2020-08-21T06:03:57.903",
          dateModified: null,
          replacedByNoteId: null,
          replacedByUserId: null,
          note: "new note"
        },
        {
          userNoteId: 6,
          memberId: 411,
          userId: 1,
          username: "dbadmin1",
          dateCreated: "2020-08-21T00:01:49.393",
          dateModified: null,
          replacedByNoteId: null,
          replacedByUserId: null,
          note:
            "Is this a second real note? I am even more confused. and i'm making this note my own...."
        },
        {
          userNoteId: 1,
          memberId: 411,
          userId: 1,
          username: "dbadmin1",
          dateCreated: "2020-08-21T00:01:49.39",
          dateModified: null,
          replacedByNoteId: null,
          replacedByUserId: null,
          note: "This is a third test note."
        }
      ],
      diagnosisCodes: [
        {
          code: "L08.0",
          description: "Pyoderma",
          dateRecorded: "2018-04-10"
        },
        {
          code: "I10",
          description: "Essential (primary) hypertension",
          dateRecorded: "2018-07-10"
        },
        {
          code: "M25.552",
          description: "Pain in left hip",
          dateRecorded: "2018-07-10"
        },
        {
          code: "R05",
          description: "Cough",
          dateRecorded: "2018-07-10"
        },
        {
          code: "I87.2",
          description: "Venous insufficiency (chronic) (peripheral)",
          dateRecorded: "2018-07-20"
        },
        {
          code: "I63.9",
          description: "Cerebral infarction, unspecified",
          dateRecorded: "2019-07-28"
        },
        {
          code: "K72.90",
          description: "Hepatic failure, unspecified without coma",
          dateRecorded: "2019-07-28"
        },
        {
          code: "E87.2",
          description: "Acidosis",
          dateRecorded: "2019-07-28"
        },
        {
          code: "E11.9",
          description: "Type 2 diabetes mellitus without complications",
          dateRecorded: "2019-08-26"
        },
        {
          code: "E78.5",
          description: "Hyperlipidemia, unspecified",
          dateRecorded: "2019-08-26"
        },
        {
          code: "E55.9",
          description: "Vitamin D deficiency, unspecified",
          dateRecorded: "2019-08-26"
        },
        {
          code: "I63.81",
          description:
            "Other cerebral infarction due to occlusion or stenosis of small artery",
          dateRecorded: "2019-07-29"
        },
        {
          code: "M62.81",
          description: "Muscle weakness (generalized)",
          dateRecorded: "2019-07-29"
        },
        {
          code: "I65.22",
          description: "Occlusion and stenosis of left carotid artery",
          dateRecorded: "2019-07-28"
        },
        {
          code: "I67.2",
          description: "Cerebral atherosclerosis",
          dateRecorded: "2019-07-28"
        },
        {
          code: "I65.03",
          description: "Occlusion and stenosis of bilateral vertebral arteries",
          dateRecorded: "2019-07-28"
        },
        {
          code: "R53.1",
          description: "Weakness",
          dateRecorded: "2019-07-28"
        },
        {
          code: "I25.10",
          description:
            "Atherosclerotic heart disease of native coronary artery without angina pectoris",
          dateRecorded: "2019-07-28"
        },
        {
          code: "M54.30",
          description: "Sciatica, unspecified side",
          dateRecorded: "2018-06-25"
        },
        {
          code: "M17.10",
          description: "Unilateral primary osteoarthritis, unspecified knee",
          dateRecorded: "2018-06-25"
        },
        {
          code: "H26.9",
          description: "Unspecified cataract",
          dateRecorded: "2018-06-25"
        },
        {
          code: "R60.0",
          description: "Localized edema",
          dateRecorded: "2018-05-08"
        },
        {
          code: "L29.9",
          description: "Pruritus, unspecified",
          dateRecorded: "2018-05-01"
        },
        {
          code: "L03.90",
          description: "Cellulitis, unspecified",
          dateRecorded: "2018-03-27"
        },
        {
          code: "I69.351",
          description:
            "Hemiplegia and hemiparesis following cerebral infarction affecting right dominant side",
          dateRecorded: "2019-07-29"
        },
        {
          code: "N39.0",
          description: "Urinary tract infection, site not specified",
          dateRecorded: "2019-07-29"
        },
        {
          code: "G81.91",
          description: "Hemiplegia, unspecified affecting right dominant side",
          dateRecorded: "2019-07-28"
        },
        {
          code: "Z85.46",
          description: "Personal history of malignant neoplasm of prostate",
          dateRecorded: "2019-08-04"
        },
        {
          code: "E87.5",
          description: "Hyperkalemia",
          dateRecorded: "2019-08-03"
        },
        {
          code: "L13.9",
          description: "Bullous disorder, unspecified",
          dateRecorded: "2018-03-16"
        },
        {
          code: "B35.3",
          description: "Tinea pedis",
          dateRecorded: "2018-03-16"
        },
        {
          code: "M53.3",
          description: "Sacrococcygeal disorders, not elsewhere classified",
          dateRecorded: "2018-03-02"
        },
        {
          code: "S89.91XA",
          description:
            "Unspecified injury of right lower leg, initial encounter",
          dateRecorded: "2018-03-02"
        },
        {
          code: "S89.90XA",
          description:
            "Unspecified injury of unspecified lower leg, initial encounter",
          dateRecorded: "2018-03-02"
        },
        {
          code: "J06.9",
          description: "Acute upper respiratory infection, unspecified",
          dateRecorded: "2018-03-02"
        },
        {
          code: "R42",
          description: "Dizziness and giddiness",
          dateRecorded: "2018-01-22"
        },
        {
          code: "R10.9",
          description: "Unspecified abdominal pain",
          dateRecorded: "2018-01-16"
        },
        {
          code: "N18.3",
          description: "Chronic kidney disease, stage 3 (moderate)",
          dateRecorded: "2018-01-16"
        },
        {
          code: "M25.561",
          description: "Pain in right knee",
          dateRecorded: "2019-09-11"
        },
        {
          code: "M23.203",
          description:
            "Derangement of unspecified medial meniscus due to old tear or injury, right knee",
          dateRecorded: "2019-08-16"
        },
        {
          code: "S83.231A",
          description:
            "Complex tear of medial meniscus, current injury, right knee, initial encounter",
          dateRecorded: "2019-08-27"
        },
        {
          code: "L03.115",
          description: "Cellulitis of right lower limb",
          dateRecorded: "2019-08-09"
        },
        {
          code: "B96.1",
          description:
            "Klebsiella pneumoniae [K. pneumoniae] as the cause of diseases classified elsewhere",
          dateRecorded: "2019-07-29"
        },
        {
          code: "K21.9",
          description: "Gastro-esophageal reflux disease without esophagitis",
          dateRecorded: "2019-07-29"
        },
        {
          code: "N31.9",
          description: "Neuromuscular dysfunction of bladder, unspecified",
          dateRecorded: "2019-07-29"
        },
        {
          code: "Z79.02",
          description:
            "Long term (current) use of antithrombotics/antiplatelets",
          dateRecorded: "2019-07-29"
        },
        {
          code: "Z79.82",
          description: "Long term (current) use of aspirin",
          dateRecorded: "2019-07-29"
        },
        {
          code: "R56.9",
          description: "Unspecified convulsions",
          dateRecorded: "2019-07-28"
        },
        {
          code: "R73.9",
          description: "Hyperglycemia, unspecified",
          dateRecorded: "2019-07-28"
        }
      ],
      memberRegistration: {
        appUserId: null,
        dateAppUserCreated: null,
        dateCreated: "2020-11-03T06:29:41.813",
        dateInvited: null,
        dateRegistered: null,
        invitationCode: "UY8HD5",
        invitationFileId: 0,
        memberId: 411,
        memberRelationId: 1,
        statusId: 10
      }
    },

    demoTherapyFields: [
      {
        therapClass: "Analgesics/Anesthetics",
        therapSubClass: "ANALGESICS - OPIOID",
        therapCategory: "Opioid Partial Agonists"
      },
      {
        therapClass: "Analgesics/Anesthetics",
        therapSubClass: "Gout Agents",
        therapCategory: "Gout Agent Combinations"
      },
      {
        therapClass: "Analgesics/Anesthetics",
        therapSubClass: "Gout Agents",
        therapCategory: "Gout Agents"
      },
      {
        therapClass: "Analgesics/Anesthetics",
        therapSubClass: "LOCAL ANESTHETICS-Parenteral",
        therapCategory: "Local Anesthetics - Amides"
      },
      {
        therapClass: "Analgesics/Anesthetics",
        therapSubClass: "Migraine Products",
        therapCategory: "Calcitonin Gene-Related Peptide (CGRP) Receptor Antag"
      },
      {
        therapClass: "Analgesics/Anesthetics",
        therapSubClass: "Migraine Products",
        therapCategory: "Migraine Combinations"
      },
      {
        therapClass: "Analgesics/Anesthetics",
        therapSubClass: "Migraine Products",
        therapCategory: "Serotonin Agonists"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIANGINAL AGENTS",
        therapCategory: "Antianginals-Other"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIANGINAL AGENTS",
        therapCategory: "Nitrates"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIARRHYTHMICS",
        therapCategory: "Antiarrhythmics Type I-B"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIARRHYTHMICS",
        therapCategory: "Antiarrhythmics Type I-C"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIARRHYTHMICS",
        therapCategory: "Antiarrhythmics Type III"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "Antihyperlipidemics - Combinations"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "Antihyperlipidemics - Misc."
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "Bile Acid Sequestrants"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "Fibric Acid Derivatives"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "HMG CoA Reductase Inhibitors"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "Intestinal Cholesterol Absorption Inhibitors"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "Nicotinic Acid Derivatives"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERLIPIDEMICS",
        therapCategory: "PCSK9 Inhibitors"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERTENSIVES",
        therapCategory: "ACE Inhibitors"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERTENSIVES",
        therapCategory: "Angiotensin II Receptor Antagonists"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERTENSIVES",
        therapCategory: "Antiadrenergic Antihypertensives"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERTENSIVES",
        therapCategory: "Antihypertensive Combinations"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERTENSIVES",
        therapCategory: "Selective Aldosterone Receptor Antagonists (SARAs)"
      },
      {
        therapClass: "Cardiovasculars",
        therapSubClass: "ANTIHYPERTENSIVES",
        therapCategory: "Vasodilators"
      }
    ],

    demoAppUser: {
      user: {
        userId: 100,
        username: "testadmin100",
        password: null,
        firstname: "John",
        lastname: "Doe",
        enabled: true,
        lastClientId: 2
      },
      roles: [
        {
          userRoleId: 1,
          userId: 100,
          roleId: 1,
          clientId: 0
        }
      ],
      clients: [
        {
          clientId: 1,
          clientCode: "DEMOCLIENT",
          active: true,
          shortName: "Demo Client",
          longName: "Demonstratable Imaginary Client"
        },
        {
          clientId: 2,
          clientCode: "ACREFRIG",
          active: true,
          shortName: "A/C and Refrigeration",
          longName:
            "Airconditioning and Refrigeration Industry Health and Welfare Trust Fund"
        }
      ],
      admin: true,
      clinician: false,
      member: false
    },

    demoReportResults: [
      {
        id: 0,
        members: [
          {
            totalCount: 7,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 9,
            lifetimeTotal: 0,
            list:
              "24000015000320,28100010100322,39400058100330,49270060006520,57100060000305,57100060000310,58180090107030,72600030000130,86501030102020",
            names:
              "PREMARIN TAB 0.625MG|SYNTHROID TAB 112MCG|LIVALO TAB 2MG|OMEPRAZOLE CAP 20MG|LORAZEPAM TAB 0.5MG|LORAZEPAM TAB 1MG|VENLAFAXINE CAP 75MG ER|GABAPENTIN CAP 300MG|PILOCARPINE SOL 2% OP"
          },
          {
            totalCount: 7,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 6,
            lifetimeTotal: 0,
            list:
              "1003864737,1528191269,1780816025,1790036994,1831185792,1841386349",
            names: null
          },
          {
            totalCount: 7,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "PharmacyLimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 4,
            lifetimeTotal: 0,
            list: "5621417,5626746,5633753,5633753",
            names:
              "CVS PHARMACY #09765|CVS PHARMACY #07079|COSTCO PHARMACY|COSTCO PHARMACY #562"
          },
          {
            totalCount: 7,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "1529.24",
            names: null
          },
          {
            totalCount: 7,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "94.15",
            names: null
          },
          {
            totalCount: 7,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "GPIInclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 1,
            lifetimeTotal: 0,
            list: "86330052102020",
            names: "Latanoprostene Bunod Ophth Soln 0.024%"
          },
          {
            totalCount: 7,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 2,
            lifetimeTotal: 0,
            list: "E78.5,I10",
            names: "Hyperlipidemia, Unspecified|Hypertension, Unspecified"
          },
          {
            totalCount: 6,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 12,
            lifetimeTotal: 0,
            list:
              "05000020100315,16000035000310,33100045100310,34000003100330,36150030000320,39400010100320,49103010100105,49270025106540,65100095100320,66100007200630,75100050100303,83370060000340",
            names:
              "CIPROFLOXACN TAB 500MG|METRONIDAZOL TAB 500MG|SOTALOL HCL TAB 80MG|AMLODIPINE TAB 5MG|IRBESARTAN TAB 150MG|ATORVASTATIN TAB 20MG|DICYCLOMINE CAP 10MG|ESOMEPRA MAG CAP 40MG DR|TRAMADOL HCL TAB 50MG|DICLOFENAC TAB 75MG DR|CYCLOBENZAPR TAB 5MG|XARELTO TAB 20MG"
          },
          {
            totalCount: 6,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 5,
            lifetimeTotal: 0,
            list: "1093726721,1306075692,1760898431,1801063839,1912915364",
            names: null
          },
          {
            totalCount: 6,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "PharmacyLimit",
            mostRecent: "2018 Q1",
            year: 2018,
            period: "Q1",
            count: 5,
            lifetimeTotal: 0,
            list: "3842324,5618511,5620073,5633753,5658022",
            names:
              "WALGREENS #9875|CVS PHARMACY|CVS PHARMACY|COSTCO PHARMACY|WALGREENS"
          },
          {
            totalCount: 6,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "1396.19",
            names: null
          },
          {
            totalCount: 6,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "28.04",
            names: null
          },
          {
            totalCount: 6,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 1,
            lifetimeTotal: 0,
            list: "I10",
            names: "Hypertension, Unspecified"
          },
          {
            totalCount: 6,
            memberId: 1566,
            dateOfBirth: "1950-12-04",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 9,
            lifetimeTotal: 0,
            list:
              "01200010100110,28100010100322,39400075000340,44209902708030,58160060000310,58300040107430,72100010000310,86330050002020,88150020102012",
            names:
              "AMOXICILLIN CAP 500MG|LEVOTHYROXIN TAB 112MCG|SIMVASTATIN TAB 40MG|ADVAIR DISKU AER 250/50|PAROXETINE TAB 10MG|BUPROPION TAB 150MG S|CLONAZEPAM TAB 1MG|LATANOPROST SOL 0.005%|CHLORHEX GLU SOL 0.12%"
          },
          {
            totalCount: 6,
            memberId: 1566,
            dateOfBirth: "1950-12-04",
            criteriaName: "PrescriberLimit",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 5,
            lifetimeTotal: 0,
            list: "1316993546,1346535481,1790926210,1811964570,1922473388",
            names: null
          },
          {
            totalCount: 6,
            memberId: 1566,
            dateOfBirth: "1950-12-04",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "200.30",
            names: null
          },
          {
            totalCount: 6,
            memberId: 1566,
            dateOfBirth: "1950-12-04",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 3,
            lifetimeTotal: 0,
            list: "44.00",
            names: null
          },
          {
            totalCount: 6,
            memberId: 1566,
            dateOfBirth: "1950-12-04",
            criteriaName: "GPIInclusion",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 1,
            lifetimeTotal: 0,
            list: "44209902708030",
            names: "Fluticasone-Salmeterol Aer Powder BA 250-50 MCG/DOSE"
          },
          {
            totalCount: 6,
            memberId: 1566,
            dateOfBirth: "1950-12-04",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 1,
            lifetimeTotal: 0,
            list: "E78.5",
            names: "Hyperlipidemia, Unspecified"
          },
          {
            totalCount: 6,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "GPILimit",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 11,
            lifetimeTotal: 0,
            list:
              "13000020100305,31200010000310,33200020000303,39400075000340,44201010103410,44209902923420,56852070100110,65991002050315,65991702100356,83370060000340,85158020100320",
            names:
              "HYDROXYCHLOR TAB 200MG|DIGOX TAB 0.25MG|ATENOLOL TAB 25MG|SIMVASTATIN TAB 40MG|VENTOLIN HFA AER|STIOLTO AER 2.5-2.5|TAMSULOSIN CAP 0.4MG|APAP/CODEINE TAB 300-30M|HYDROCO/APAP TAB 5-325MG|XARELTO TAB 20MG|CLOPIDOGREL TAB 75MG"
          },
          {
            totalCount: 6,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 5,
            lifetimeTotal: 0,
            list: "1295777837,1518990035,1760682868,1821081423,1831118934",
            names: null
          },
          {
            totalCount: 6,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 3,
            lifetimeTotal: 0,
            list: "37.99",
            names: null
          },
          {
            totalCount: 6,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 3,
            lifetimeTotal: 0,
            list: "6718.03",
            names: null
          },
          {
            totalCount: 6,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "GPIInclusion",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 1,
            lifetimeTotal: 0,
            list: "44201010103410",
            names: "Albuterol Sulfate Inhal Aero 108 MCG/ACT (90MCG Base Equiv)"
          },
          {
            totalCount: 6,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 4,
            lifetimeTotal: 0,
            list: "E78.5,I10,I48.91,I73.9",
            names:
              "Hyperlipidemia, Unspecified|Hypertension, Unspecified|Atrial Fibrillation|Peripheral Vascular Disease"
          }
        ]
      },
      {
        id: 1,
        members: [
          {
            totalCount: 0,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 12,
            lifetimeTotal: 0,
            list:
              "05000020100315,16000035000310,33100045100310,34000003100330,36150030000320,39400010100320,49103010100105,49270025106540,65100095100320,66100007200630,75100050100303,83370060000340",
            names:
              "CIPROFLOXACN TAB 500MG|METRONIDAZOL TAB 500MG|SOTALOL HCL TAB 80MG|AMLODIPINE TAB 5MG|IRBESARTAN TAB 150MG|ATORVASTATIN TAB 20MG|DICYCLOMINE CAP 10MG|ESOMEPRA MAG CAP 40MG DR|TRAMADOL HCL TAB 50MG|DICLOFENAC TAB 75MG DR|CYCLOBENZAPR TAB 5MG|XARELTO TAB 20MG"
          },
          {
            totalCount: 0,
            memberId: 1566,
            dateOfBirth: "1950-12-04",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 9,
            lifetimeTotal: 0,
            list:
              "01200010100110,28100010100322,39400075000340,44209902708030,58160060000310,58300040107430,72100010000310,86330050002020,88150020102012",
            names:
              "AMOXICILLIN CAP 500MG|LEVOTHYROXIN TAB 112MCG|SIMVASTATIN TAB 40MG|ADVAIR DISKU AER 250/50|PAROXETINE TAB 10MG|BUPROPION TAB 150MG S|CLONAZEPAM TAB 1MG|LATANOPROST SOL 0.005%|CHLORHEX GLU SOL 0.12%"
          },
          {
            totalCount: 0,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "GPILimit",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 11,
            lifetimeTotal: 0,
            list:
              "13000020100305,31200010000310,33200020000303,39400075000340,44201010103410,44209902923420,56852070100110,65991002050315,65991702100356,83370060000340,85158020100320",
            names:
              "HYDROXYCHLOR TAB 200MG|DIGOX TAB 0.25MG|ATENOLOL TAB 25MG|SIMVASTATIN TAB 40MG|VENTOLIN HFA AER|STIOLTO AER 2.5-2.5|TAMSULOSIN CAP 0.4MG|APAP/CODEINE TAB 300-30M|HYDROCO/APAP TAB 5-325MG|XARELTO TAB 20MG|CLOPIDOGREL TAB 75MG"
          }
        ]
      },
      {
        id: 2,
        members: [
          {
            totalCount: 0,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 6,
            lifetimeTotal: 0,
            list:
              "1003864737,1528191269,1780816025,1790036994,1831185792,1841386349",
            names: null
          },
          {
            totalCount: 0,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 5,
            lifetimeTotal: 0,
            list: "1093726721,1306075692,1760898431,1801063839,1912915364",
            names: null
          }
        ]
      },
      {
        id: 3,
        members: [
          {
            totalCount: 0,
            memberId: 46,
            dateOfBirth: "1945-06-19",
            criteriaName: "PharmacyLimit",
            mostRecent: "2018 Q1",
            year: 2018,
            period: "Q1",
            count: 5,
            lifetimeTotal: 0,
            list: "3842324,5618511,5620073,5633753,5658022",
            names:
              "WALGREENS #9875|CVS PHARMACY|CVS PHARMACY|COSTCO PHARMACY|WALGREENS"
          },
          {
            totalCount: 0,
            memberId: 494,
            dateOfBirth: "1950-11-15",
            criteriaName: "PharmacyLimit",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 4,
            lifetimeTotal: 0,
            list: "5600994,5633753,5641697,5650800",
            names:
              "WALMART PHARMACY 10-2708|COSTCO PHARMACY #562|CURA PHARMACY|VILLAGE PHARMACY"
          }
        ]
      },
      {
        id: 4,
        members: [
          {
            totalCount: 0,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "TopRxSpend",
            mostRecent: "2018 Q2",
            year: 2018,
            period: "Q2",
            count: 3,
            lifetimeTotal: 1212.21,
            list: "543.45",
            names: '{"2018 Q1":432.65, "2018 Q2":543.45}'
          },
          {
            totalCount: 0,
            memberId: 2061,
            dateOfBirth: "1932-01-13",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 2332.43,
            list: "142.34",
            names:
              '{"2018 Q1":23.23, "2018 Q2":543.45, "2018 Q3":32.57, "2018 Q4":543.45, "2019 Q1":423.90, "2019 Q2":543.45, "2019 Q3":543.45, "2019 Q4":142.34}'
          }
        ]
      },
      {
        id: 5,
        members: [
          {
            totalCount: 0,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 3,
            lifetimeTotal: 654.45,
            list: "6718.03",
            names: '{"2018 Q1":23.23, "2018 Q2":543.45}'
          }
        ]
      },
      {
        id: 6,
        members: [
          {
            totalCount: 0,
            memberId: 821,
            dateOfBirth: "1948-02-15",
            criteriaName: "GPIInclusion",
            mostRecent: "2018 Q3",
            year: 2018,
            period: "Q3",
            count: 1,
            lifetimeTotal: 0,
            list: "44201010103410",
            names: "Albuterol Sulfate Inhal Aero 108 MCG/ACT (90MCG Base Equiv)"
          },
          {
            totalCount: 0,
            memberId: 494,
            dateOfBirth: "1950-11-15",
            criteriaName: "GPIInclusion",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 1,
            lifetimeTotal: 0,
            list: "3935001000D230",
            names: "--name not in drug DB--"
          },
          {
            totalCount: 0,
            memberId: 1658,
            dateOfBirth: "1940-06-24",
            criteriaName: "GPIInclusion",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 1,
            lifetimeTotal: 0,
            list: "44201010103410",
            names: "Albuterol Sulfate Inhal Aero 108 MCG/ACT (90MCG Base Equiv)"
          }
        ]
      },
      {
        id: 7,
        members: [
          {
            totalCount: 0,
            memberId: 821,
            dateOfBirth: "1948-02-15",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 2,
            lifetimeTotal: 0,
            list: "E78.5,I10",
            names: "Hyperlipidemia, Unspecified|Hypertension, Unspecified"
          },
          {
            totalCount: 0,
            memberId: 494,
            dateOfBirth: "1950-11-15",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 4,
            lifetimeTotal: 0,
            list: "E11.9,E78.5,I10,I25.10",
            names:
              "Diabetes Mellitus, II Uncontrolled|Hyperlipidemia, Unspecified|Hypertension, Unspecified|ASHD Coronary Artery"
          },
          {
            totalCount: 0,
            memberId: 430,
            dateOfBirth: "1946-09-09",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "E11.9,I25.10,I48.91",
            names:
              "Diabetes Mellitus, II Uncontrolled|ASHD Coronary Artery|Atrial Fibrillation"
          }
        ]
      },
      {
        id: 101,
        members: [
          {
            totalCount: 7,
            memberId: 1324,
            dateOfBirth: "1932-01-13",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 9,
            lifetimeTotal: 0,
            list:
              "24000015000320,28100010100322,39400058100330,49270060006520,57100060000305,57100060000310,58180090107030,72600030000130,86501030102020",
            names:
              "PREMARIN TAB 0.625MG|SYNTHROID TAB 112MCG|LIVALO TAB 2MG|OMEPRAZOLE CAP 20MG|LORAZEPAM TAB 0.5MG|LORAZEPAM TAB 1MG|VENLAFAXINE CAP 75MG ER|GABAPENTIN CAP 300MG|PILOCARPINE SOL 2% OP"
          },
          {
            totalCount: 7,
            memberId: 1324,
            dateOfBirth: "1932-01-13",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 6,
            lifetimeTotal: 0,
            list:
              "1003864737,1528191269,1780816025,1790036994,1831185792,1841386349",
            names: null
          },
          {
            totalCount: 7,
            memberId: 1324,
            dateOfBirth: "1932-01-13",
            criteriaName: "PharmacyLimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 4,
            lifetimeTotal: 0,
            list: "5621417,5626746,5633753,5633753",
            names:
              "CVS PHARMACY #09765|CVS PHARMACY #07079|COSTCO PHARMACY|COSTCO PHARMACY #562"
          },
          {
            totalCount: 7,
            memberId: 1324,
            dateOfBirth: "1932-01-13",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "1529.24",
            names: null
          },
          {
            totalCount: 7,
            memberId: 1324,
            dateOfBirth: "1932-01-13",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "94.15",
            names: null
          },
          {
            totalCount: 7,
            memberId: 1324,
            dateOfBirth: "1932-01-13",
            criteriaName: "GPIInclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 1,
            lifetimeTotal: 0,
            list: "86330052102020",
            names: "Latanoprostene Bunod Ophth Soln 0.024%"
          },
          {
            totalCount: 7,
            memberId: 1324,
            dateOfBirth: "1932-01-13",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 2,
            lifetimeTotal: 0,
            list: "E78.5,I10",
            names: "Hyperlipidemia, Unspecified|Hypertension, Unspecified"
          },
          {
            totalCount: 6,
            memberId: 65,
            dateOfBirth: "1945-06-19",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 12,
            lifetimeTotal: 0,
            list:
              "05000020100315,16000035000310,33100045100310,34000003100330,36150030000320,39400010100320,49103010100105,49270025106540,65100095100320,66100007200630,75100050100303,83370060000340",
            names:
              "CIPROFLOXACN TAB 500MG|METRONIDAZOL TAB 500MG|SOTALOL HCL TAB 80MG|AMLODIPINE TAB 5MG|IRBESARTAN TAB 150MG|ATORVASTATIN TAB 20MG|DICYCLOMINE CAP 10MG|ESOMEPRA MAG CAP 40MG DR|TRAMADOL HCL TAB 50MG|DICLOFENAC TAB 75MG DR|CYCLOBENZAPR TAB 5MG|XARELTO TAB 20MG"
          },
          {
            totalCount: 6,
            memberId: 65,
            dateOfBirth: "1945-06-19",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q1",
            year: 2019,
            period: "Q1",
            count: 5,
            lifetimeTotal: 0,
            list: "1093726721,1306075692,1760898431,1801063839,1912915364",
            names: null
          },
          {
            totalCount: 6,
            memberId: 65,
            dateOfBirth: "1945-06-19",
            criteriaName: "PharmacyLimit",
            mostRecent: "2018 Q1",
            year: 2018,
            period: "Q1",
            count: 5,
            lifetimeTotal: 0,
            list: "3842324,5618511,5620073,5633753,5658022",
            names:
              "WALGREENS #9875|CVS PHARMACY|CVS PHARMACY|COSTCO PHARMACY|WALGREENS"
          },
          {
            totalCount: 6,
            memberId: 65,
            dateOfBirth: "1945-06-19",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "1396.19",
            names: null
          },
          {
            totalCount: 6,
            memberId: 65,
            dateOfBirth: "1945-06-19",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "28.04",
            names: null
          },
          {
            totalCount: 6,
            memberId: 65,
            dateOfBirth: "1945-06-19",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 1,
            lifetimeTotal: 0,
            list: "I10",
            names: "Hypertension, Unspecified"
          },
          {
            totalCount: 6,
            memberId: 535,
            dateOfBirth: "1950-12-04",
            criteriaName: "GPILimit",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 9,
            lifetimeTotal: 0,
            list:
              "01200010100110,28100010100322,39400075000340,44209902708030,58160060000310,58300040107430,72100010000310,86330050002020,88150020102012",
            names:
              "AMOXICILLIN CAP 500MG|LEVOTHYROXIN TAB 112MCG|SIMVASTATIN TAB 40MG|ADVAIR DISKU AER 250/50|PAROXETINE TAB 10MG|BUPROPION TAB 150MG S|CLONAZEPAM TAB 1MG|LATANOPROST SOL 0.005%|CHLORHEX GLU SOL 0.12%"
          },
          {
            totalCount: 6,
            memberId: 535,
            dateOfBirth: "1950-12-04",
            criteriaName: "PrescriberLimit",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 5,
            lifetimeTotal: 0,
            list: "1316993546,1346535481,1790926210,1811964570,1922473388",
            names: null
          },
          {
            totalCount: 6,
            memberId: 535,
            dateOfBirth: "1950-12-04",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q4",
            year: 2019,
            period: "Q4",
            count: 3,
            lifetimeTotal: 0,
            list: "200.30",
            names: null
          },
          {
            totalCount: 6,
            memberId: 535,
            dateOfBirth: "1950-12-04",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 3,
            lifetimeTotal: 0,
            list: "44.00",
            names: null
          },
          {
            totalCount: 6,
            memberId: 535,
            dateOfBirth: "1950-12-04",
            criteriaName: "GPIInclusion",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 1,
            lifetimeTotal: 0,
            list: "44209902708030",
            names: "Fluticasone-Salmeterol Aer Powder BA 250-50 MCG/DOSE"
          },
          {
            totalCount: 6,
            memberId: 535,
            dateOfBirth: "1950-12-04",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 1,
            lifetimeTotal: 0,
            list: "E78.5",
            names: "Hyperlipidemia, Unspecified"
          },
          {
            totalCount: 6,
            memberId: 867,
            dateOfBirth: "1940-06-24",
            criteriaName: "GPILimit",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 11,
            lifetimeTotal: 0,
            list:
              "13000020100305,31200010000310,33200020000303,39400075000340,44201010103410,44209902923420,56852070100110,65991002050315,65991702100356,83370060000340,85158020100320",
            names:
              "HYDROXYCHLOR TAB 200MG|DIGOX TAB 0.25MG|ATENOLOL TAB 25MG|SIMVASTATIN TAB 40MG|VENTOLIN HFA AER|STIOLTO AER 2.5-2.5|TAMSULOSIN CAP 0.4MG|APAP/CODEINE TAB 300-30M|HYDROCO/APAP TAB 5-325MG|XARELTO TAB 20MG|CLOPIDOGREL TAB 75MG"
          },
          {
            totalCount: 6,
            memberId: 867,
            dateOfBirth: "1940-06-24",
            criteriaName: "PrescriberLimit",
            mostRecent: "2019 Q2",
            year: 2019,
            period: "Q2",
            count: 5,
            lifetimeTotal: 0,
            list: "1295777837,1518990035,1760682868,1821081423,1831118934",
            names: null
          },
          {
            totalCount: 6,
            memberId: 867,
            dateOfBirth: "1940-06-24",
            criteriaName: "TopRxSpend",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 3,
            lifetimeTotal: 0,
            list: "37.99",
            names: null
          },
          {
            totalCount: 6,
            memberId: 867,
            dateOfBirth: "1940-06-24",
            criteriaName: "TopMedicalSpend",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 3,
            lifetimeTotal: 0,
            list: "6718.03",
            names: null
          },
          {
            totalCount: 6,
            memberId: 867,
            dateOfBirth: "1940-06-24",
            criteriaName: "GPIInclusion",
            mostRecent: "2018 Q4",
            year: 2018,
            period: "Q4",
            count: 1,
            lifetimeTotal: 0,
            list: "44201010103410",
            names: "Albuterol Sulfate Inhal Aero 108 MCG/ACT (90MCG Base Equiv)"
          },
          {
            totalCount: 6,
            memberId: 867,
            dateOfBirth: "1940-06-24",
            criteriaName: "ICD10Inclusion",
            mostRecent: "2019 Q3",
            year: 2019,
            period: "Q3",
            count: 4,
            lifetimeTotal: 0,
            list: "E78.5,I10,I48.91,I73.9",
            names:
              "Hyperlipidemia, Unspecified|Hypertension, Unspecified|Atrial Fibrillation|Peripheral Vascular Disease"
          }
        ]
      }
    ],

    demoQualifiedReportResults: [
      {
        memberId: 10000,
        member: {
          memberId: 10000,
          firstName: "Tommy",
          lastName: "Niceguy",
          middleName: null,
          nameSuffix: null,
          dateOfBirth: "1933-09-25",
          gender: "M",
          maritalStatus: "M",
          dateCreated: "2020-08-06T02:02:42.9066667",
          dateModified: null
        },
        memberAddresses: [
          {
            memberAddressId: 10000,
            memberId: 10000,
            addressName: null,
            streetAddress: "123 Main Street",
            streetAddress2: null,
            city: "Los Angeles",
            state: "CA",
            zipCode: "92110",
            zipPlus4: null,
            dateCreated: null,
            dateModified: null
          }
        ],
        memberPhones: [
          {
            memberPhoneId: 10000,
            memberId: 10000,
            phoneName: null,
            phoneNumber: "(626) 285-2963",
            confirmed: false,
            archived: false,
            dateCreated: "2020-08-06T02:02:42.91"
          }
        ],
        memberEmails: [],
        memberInsurances: [],
        memberCriteria: [
          {
            memberId: 10000,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-03-31",
            code:
              '{"version:1,"count:2,"list:"E78.5,I10","names:"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2018,
            period: "Q1",
            statusId: 10,
            statusName: "Invited1",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 10000,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-06-30",
            code:
              '{"version:1,"count:1,"list:"I10","names:"Hypertension, Unspecified"}',
            year: 2018,
            period: "Q2",
            statusId: 10,
            statusName: "Invited1",
            namelist: ["Hypertension, Unspecified"],
            codelist: ["I10"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: {
          appUserId: null,
          dateAppUserCreated: null,
          dateCreated: "2020-11-03T06:29:41.813",
          dateInvited: "2020-11-03T06:29:41.813",
          dateRegistered: null,
          invitationCode: "Q4H4WC",
          invitationFileId: 12,
          memberId: 10000,
          memberRelationId: 1,
          statusId: 10
        },
        //statusId: 2,
        statusName: "Invited1",
        hasAddress: true
      },
      {
        memberId: 10001,
        member: {
          memberId: 10001,
          firstName: "Ricky",
          lastName: "Badguy",
          middleName: null,
          nameSuffix: null,
          dateOfBirth: "1954-02-16",
          gender: "M",
          maritalStatus: "M",
          dateCreated: "2020-08-06T02:02:42.9066667",
          dateModified: null
        },
        memberAddresses: [
          {
            memberAddressId: 10001,
            memberId: 10001,
            addressName: null,
            streetAddress: "",
            streetAddress2: null,
            city: null,
            state: "",
            zipCode: "",
            zipPlus4: null,
            dateCreated: null,
            dateModified: null
          }
        ],
        memberPhones: [],
        memberEmails: [],
        memberInsurances: [],
        memberCriteria: [
          {
            memberId: 10001,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-03-31",
            code:
              '{"version:1,"count:2,"list:"E78.5,I10","names:"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2018,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: null,
        //statusId: 2,
        statusName: "Qualified",
        hasAddress: false
      }
    ],

    demoSearchResults: [
      {
        member: {
          memberId: 2442,
          firstName: "DAVID",
          lastName: "LEE",
          middleName: "",
          nameSuffix: "",
          dateOfBirth: "1963-08-22",
          gender: null,
          maritalStatus: null,
          dateCreated: "2020-09-06T02:59:52.5166667",
          dateModified: null
        },
        memberIdentityValue: "T50009087-D23243",
        memberSharedIdentityValue: "T50009087",
        memberCriteria: [
          {
            memberId: 2442,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-06-30",
            code:
              '{"version":1,"count":1,"list":"I25.10","names":"ASHD Coronary Artery"}',
            year: 2018,
            period: "Q2",
            statusId: 10,
            statusName: "Invited1",
            namelist: ["ASHD Coronary Artery"],
            codelist: ["I25.10"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: {
          appUserId: null,
          dateAppUserCreated: null,
          dateCreated: "2020-11-03T06:29:41.813",
          dateInvited: "2020-12-04T06:29:41.813",
          dateRegistered: null,
          invitationCode: "UY8HD5",
          invitationFileId: 20,
          memberId: 2442,
          memberRelationId: 1,
          statusId: 10
        }
      },
      {
        member: {
          memberId: 968,
          firstName: "LEE",
          lastName: "MAULORICO",
          middleName: null,
          nameSuffix: null,
          dateOfBirth: "1955-08-28",
          gender: "M",
          maritalStatus: "M",
          dateCreated: "2020-09-06T02:46:59.33",
          dateModified: null
        },
        memberIdentityValue: "T50011017-D20327",
        memberSharedIdentityValue: "T50011017",
        memberCriteria: [
          {
            memberId: 968,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-06-30",
            code:
              '{"version":1,"count":1,"list":"Z13.220","names":"Screening for Lipid Disorders"}',
            year: 2018,
            period: "Q2",
            statusId: 10,
            statusName: "Invited1",
            namelist: ["Screening for Lipid Disorders"],
            codelist: ["Z13.220"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 968,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-12-31",
            code:
              '{"version":1,"count":2,"list":"E55.9,Z13.220","names":"Vitamin D Deficiency|Screening for Lipid Disorders"}',
            year: 2019,
            period: "Q4",
            statusId: 10,
            statusName: "Invited1",
            namelist: ["Vitamin D Deficiency", "Screening for Lipid Disorders"],
            codelist: ["E55.9", "Z13.220"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: {
          appUserId: null,
          dateAppUserCreated: null,
          dateCreated: "2020-11-03T06:29:41.813",
          dateInvited: null,
          dateRegistered: null,
          invitationCode: "OBK94V",
          invitationFileId: 0,
          memberId: 968,
          memberRelationId: 1,
          statusId: 10
        }
      },
      {
        member: {
          memberId: 1851,
          firstName: "KATHLEEN",
          lastName: "JORDAN",
          middleName: "M",
          nameSuffix: null,
          dateOfBirth: "1945-10-08",
          gender: null,
          maritalStatus: null,
          dateCreated: "2020-09-06T02:48:31.5133333",
          dateModified: null
        },
        memberIdentityValue: "T50011788-D16716",
        memberSharedIdentityValue: "T50011788",
        memberCriteria: [
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-03-31",
            code:
              '{"version":1,"count":2,"list":"E11.9,E55.9","names":"Diabetes Mellitus, II Uncontrolled|Vitamin D Deficiency"}',
            year: 2018,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Vitamin D Deficiency"
            ],
            codelist: ["E11.9", "E55.9"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-09-30",
            code:
              '{"version":1,"count":2,"list":"E11.9,E55.9","names":"Diabetes Mellitus, II Uncontrolled|Vitamin D Deficiency"}',
            year: 2018,
            period: "Q3",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Vitamin D Deficiency"
            ],
            codelist: ["E11.9", "E55.9"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-12-31",
            code:
              '{"version":1,"count":3,"list":"E11.9,E78.5,I10","names":"Diabetes Mellitus, II Uncontrolled|Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2018,
            period: "Q4",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E11.9", "E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-03-31",
            code:
              '{"version":1,"count":2,"list":"E11.9,E78.5","names":"Diabetes Mellitus, II Uncontrolled|Hyperlipidemia, Unspecified"}',
            year: 2019,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Hyperlipidemia, Unspecified"
            ],
            codelist: ["E11.9", "E78.5"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-06-30",
            code:
              '{"version":1,"count":3,"list":"E11.9,E78.5,I10","names":"Diabetes Mellitus, II Uncontrolled|Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q2",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E11.9", "E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-09-30",
            code:
              '{"version":1,"count":3,"list":"E11.9,E78.5,I10","names":"Diabetes Mellitus, II Uncontrolled|Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q3",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E11.9", "E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-12-31",
            code:
              '{"version":1,"count":1,"list":"I10","names":"Hypertension, Unspecified"}',
            year: 2019,
            period: "Q4",
            statusId: 1,
            statusName: "Targeted",
            namelist: ["Hypertension, Unspecified"],
            codelist: ["I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 1851,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2020-03-31",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2020,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: null
      },
      {
        member: {
          memberId: 848,
          firstName: "JACK",
          lastName: "LEEFLANG",
          middleName: null,
          nameSuffix: null,
          dateOfBirth: "1948-03-23",
          gender: "M",
          maritalStatus: "S",
          dateCreated: "2020-09-06T02:46:58.7666667",
          dateModified: null
        },
        memberIdentityValue: "T50012853-D17613",
        memberSharedIdentityValue: "T50012853",
        memberCriteria: [
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-03-31",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2018,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-09-30",
            code:
              '{"version":1,"count":3,"list":"E78.5,I10,I25.10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified|ASHD Coronary Artery"}',
            year: 2018,
            period: "Q3",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified",
              "ASHD Coronary Artery"
            ],
            codelist: ["E78.5", "I10", "I25.10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-12-31",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2018,
            period: "Q4",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-03-31",
            code:
              '{"version":1,"count":3,"list":"E55.9,E78.5,I10","names":"Vitamin D Deficiency|Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Vitamin D Deficiency",
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E55.9", "E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-06-30",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q2",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-09-30",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q3",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-12-31",
            code:
              '{"version":1,"count":4,"list":"E78.5,I10,I25.10,I65.23","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified|ASHD Coronary Artery|Carotid Artery Stenosis, Bilateral"}',
            year: 2019,
            period: "Q4",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified",
              "ASHD Coronary Artery",
              "Carotid Artery Stenosis, Bilateral"
            ],
            codelist: ["E78.5", "I10", "I25.10", "I65.23"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 848,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2020-03-31",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2020,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: null
      },
      {
        member: {
          memberId: 2041,
          firstName: "KATHLEEN",
          lastName: "MURRAY",
          middleName: "M",
          nameSuffix: null,
          dateOfBirth: "1963-01-18",
          gender: null,
          maritalStatus: null,
          dateCreated: "2020-09-06T02:48:38.2033333",
          dateModified: null
        },
        memberIdentityValue: "T50012877-D23027",
        memberSharedIdentityValue: "T50012877",
        memberCriteria: [
          {
            memberId: 2041,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-06-30",
            code:
              '{"version":1,"count":1,"list":"E78.5","names":"Hyperlipidemia, Unspecified"}',
            year: 2019,
            period: "Q2",
            statusId: 10,
            statusName: "Invited1",
            namelist: ["Hyperlipidemia, Unspecified"],
            codelist: ["E78.5"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2041,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-12-31",
            code:
              '{"version":1,"count":1,"list":"E78.5","names":"Hyperlipidemia, Unspecified"}',
            year: 2019,
            period: "Q4",
            statusId: 10,
            statusName: "Invited1",
            namelist: ["Hyperlipidemia, Unspecified"],
            codelist: ["E78.5"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2041,
            criteriaId: 6,
            criteriaName: "GPIInclusion",
            criteriaDescription: "Has a GPI that is on the inclusion list",
            dateIdentified: "2020-03-31",
            code:
              '{"version":1,"count":1,"list":"44201010103410","names":"Albuterol Sulfate Inhal Aero 108 MCG/ACT (90MCG Base Equiv)"}',
            year: 2020,
            period: "Q1",
            statusId: 10,
            statusName: "Invited1",
            namelist: [
              "Albuterol Sulfate Inhal Aero 108 MCG/ACT (90MCG Base Equiv)"
            ],
            codelist: ["44201010103410"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: {
          appUserId: null,
          dateAppUserCreated: null,
          dateCreated: "2020-11-03T06:29:41.813",
          dateInvited: null,
          dateRegistered: null,
          invitationCode: "EF7SD3",
          invitationFileId: null,
          memberId: 2041,
          memberRelationId: 1,
          statusId: 10
        }
      },
      {
        member: {
          memberId: 2446,
          firstName: "COLLEEN",
          lastName: "ROSS",
          middleName: "",
          nameSuffix: "",
          dateOfBirth: "1931-01-25",
          gender: null,
          maritalStatus: null,
          dateCreated: "2020-09-06T02:59:52.5533333",
          dateModified: null
        },
        memberIdentityValue: "T50014445-D11346",
        memberSharedIdentityValue: "T50014445",
        memberCriteria: [
          {
            memberId: 2446,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-03-31",
            code:
              '{"version":1,"count":3,"list":"E11.9,I10,I11.9","names":"Diabetes Mellitus, II Uncontrolled|Hypertension, Unspecified|Benign Hypertension Heart Disease"}',
            year: 2019,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Hypertension, Unspecified",
              "Benign Hypertension Heart Disease"
            ],
            codelist: ["E11.9", "I10", "I11.9"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2446,
            criteriaId: 5,
            criteriaName: "TopMedicalSpend",
            criteriaDescription:
              "Top x% of Medical spending (user-defined at query time)",
            dateIdentified: "2019-03-31",
            code:
              '{"startdate":"2019-01-01","enddate":"2019-03-31","count":5,"list":"11054.43"}',
            year: 2019,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: null,
            codelist: ["11054.43"],
            version: 0,
            threshold: 0
          },
          {
            memberId: 2446,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-06-30",
            code:
              '{"version":1,"count":2,"list":"E11.9,I10","names":"Diabetes Mellitus, II Uncontrolled|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q2",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Diabetes Mellitus, II Uncontrolled",
              "Hypertension, Unspecified"
            ],
            codelist: ["E11.9", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2446,
            criteriaId: 5,
            criteriaName: "TopMedicalSpend",
            criteriaDescription:
              "Top x% of Medical spending (user-defined at query time)",
            dateIdentified: "2019-06-30",
            code:
              '{"startdate":"2019-04-01","enddate":"2019-06-30","count":5,"list":"4603.44"}',
            year: 2019,
            period: "Q2",
            statusId: 1,
            statusName: "Targeted",
            namelist: null,
            codelist: ["4603.44"],
            version: 0,
            threshold: 0
          }
        ],
        memberRegistration: null
      },
      {
        member: {
          memberId: 2123,
          firstName: "KATHLEEN",
          lastName: "STONEBREAKER",
          middleName: "A",
          nameSuffix: null,
          dateOfBirth: "1958-07-24",
          gender: null,
          maritalStatus: null,
          dateCreated: "2020-09-06T02:48:42.48",
          dateModified: null
        },
        memberIdentityValue: "T50014458-D21388",
        memberSharedIdentityValue: "T50014458",
        memberCriteria: [
          {
            memberId: 2123,
            criteriaId: 5,
            criteriaName: "TopMedicalSpend",
            criteriaDescription:
              "Top x% of Medical spending (user-defined at query time)",
            dateIdentified: "2018-03-31",
            code:
              '{"startdate":"2018-01-01","enddate":"2018-03-31","count":5,"list":"5084.83"}',
            year: 2018,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: null,
            codelist: ["5084.83"],
            version: 0,
            threshold: 0
          }
        ],
        memberRegistration: null
      },
      {
        member: {
          memberId: 2081,
          firstName: "KATHLEEN",
          lastName: "WILKERSON",
          middleName: "C",
          nameSuffix: null,
          dateOfBirth: "1944-02-04",
          gender: null,
          maritalStatus: null,
          dateCreated: "2020-09-06T02:48:39.8366667",
          dateModified: null
        },
        memberIdentityValue: "T50015274-D16104",
        memberSharedIdentityValue: "T50015274",
        memberCriteria: [
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-03-31",
            code:
              '{"version":1,"count":5,"list":"E78.5,I10,I11.9,I50.9,R73.09","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified|Benign Hypertension Heart Disease|Congestive Heart Failure|Abnormal Glucose"}',
            year: 2018,
            period: "Q1",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified",
              "Benign Hypertension Heart Disease",
              "Congestive Heart Failure",
              "Abnormal Glucose"
            ],
            codelist: ["E78.5", "I10", "I11.9", "I50.9", "R73.09"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-06-30",
            code:
              '{"version":1,"count":4,"list":"E55.9,E78.5,I10,I11.9","names":"Vitamin D Deficiency|Hyperlipidemia, Unspecified|Hypertension, Unspecified|Benign Hypertension Heart Disease"}',
            year: 2018,
            period: "Q2",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: [
              "Vitamin D Deficiency",
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified",
              "Benign Hypertension Heart Disease"
            ],
            codelist: ["E55.9", "E78.5", "I10", "I11.9"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-09-30",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2018,
            period: "Q3",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-12-31",
            code:
              '{"version":1,"count":4,"list":"E78.5,I10,I11.9,I65.23","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified|Benign Hypertension Heart Disease|Carotid Artery Stenosis, Bilateral"}',
            year: 2018,
            period: "Q4",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified",
              "Benign Hypertension Heart Disease",
              "Carotid Artery Stenosis, Bilateral"
            ],
            codelist: ["E78.5", "I10", "I11.9", "I65.23"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2081,
            criteriaId: 1,
            criteriaName: "GPILimit",
            criteriaDescription:
              "Number of GPIs has exceeded the set threshold.",
            dateIdentified: "2019-03-31",
            code:
              '{"threshold":8,"count":9,"list":"30905030000105,33300007000330,36100005100340,36201010008820,37200030000310,39400010100320,68000010000310,79700030100440,83200030200305","names":"CALCITRIOL CAP 0.25MCG|CARVEDILOL TAB 25MG|BENAZEPRIL TAB 40MG|CLONIDINE DIS 0.2/24H|FUROSEMIDE TAB 40MG|ATORVASTATIN TAB 20MG|ALLOPURINOL TAB 300MG|POT CL MICRO TAB 20MEQ E|WARFARIN TAB 2MG"}',
            year: 2019,
            period: "Q1",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: [
              "CALCITRIOL CAP 0.25MCG",
              "CARVEDILOL TAB 25MG",
              "BENAZEPRIL TAB 40MG",
              "CLONIDINE DIS 0.2/24H",
              "FUROSEMIDE TAB 40MG",
              "ATORVASTATIN TAB 20MG",
              "ALLOPURINOL TAB 300MG",
              "POT CL MICRO TAB 20MEQ E",
              "WARFARIN TAB 2MG"
            ],
            codelist: [
              "30905030000105",
              "33300007000330",
              "36100005100340",
              "36201010008820",
              "37200030000310",
              "39400010100320",
              "68000010000310",
              "79700030100440",
              "83200030200305"
            ],
            version: 0,
            threshold: 8
          },
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-03-31",
            code:
              '{"version":1,"count":5,"list":"E55.9,I10,I11.9,I25.10,I48.91","names":"Vitamin D Deficiency|Hypertension, Unspecified|Benign Hypertension Heart Disease|ASHD Coronary Artery|Atrial Fibrillation"}',
            year: 2019,
            period: "Q1",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: [
              "Vitamin D Deficiency",
              "Hypertension, Unspecified",
              "Benign Hypertension Heart Disease",
              "ASHD Coronary Artery",
              "Atrial Fibrillation"
            ],
            codelist: ["E55.9", "I10", "I11.9", "I25.10", "I48.91"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-06-30",
            code:
              '{"version":1,"count":1,"list":"I10","names":"Hypertension, Unspecified"}',
            year: 2019,
            period: "Q2",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: ["Hypertension, Unspecified"],
            codelist: ["I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-09-30",
            code:
              '{"version":1,"count":3,"list":"E55.9,E78.5,Z79.899","names":"Vitamin D Deficiency|Hyperlipidemia, Unspecified|Long Term Use of High Risk Medication"}',
            year: 2019,
            period: "Q3",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: [
              "Vitamin D Deficiency",
              "Hyperlipidemia, Unspecified",
              "Long Term Use of High Risk Medication"
            ],
            codelist: ["E55.9", "E78.5", "Z79.899"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 2081,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-12-31",
            code:
              '{"version":1,"count":1,"list":"I10","names":"Hypertension, Unspecified"}',
            year: 2019,
            period: "Q4",
            statusId: 100,
            statusName: "Not Qualified",
            namelist: ["Hypertension, Unspecified"],
            codelist: ["I10"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: null
      },
      {
        member: {
          memberId: 2937,
          firstName: "BRYNLEE",
          lastName: "WILLEY",
          middleName: "L",
          nameSuffix: null,
          dateOfBirth: "2019-11-16",
          gender: null,
          maritalStatus: null,
          dateCreated: "2020-09-06T04:15:53.4266667",
          dateModified: null
        },
        memberIdentityValue: "T50017071-D43783",
        memberSharedIdentityValue: "T50017071",
        memberCriteria: [
          {
            memberId: 2937,
            criteriaId: 5,
            criteriaName: "TopMedicalSpend",
            criteriaDescription:
              "Top x% of Medical spending (user-defined at query time)",
            dateIdentified: "2020-03-31",
            code:
              '{"startdate":"2020-01-01","enddate":"2020-03-31","count":5,"list":"14517.77"}',
            year: 2020,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: null,
            codelist: ["14517.77"],
            version: 0,
            threshold: 0
          }
        ],
        memberRegistration: null
      },
      {
        member: {
          memberId: 402,
          firstName: "MARLEE",
          lastName: "EDGE",
          middleName: null,
          nameSuffix: null,
          dateOfBirth: "1943-08-24",
          gender: "F",
          maritalStatus: "W",
          dateCreated: "2020-09-06T02:46:56.7166667",
          dateModified: null
        },
        memberIdentityValue: "T50017632-D15940",
        memberSharedIdentityValue: "T50017632",
        memberCriteria: [
          {
            memberId: 402,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-09-30",
            code:
              '{"version":1,"count":1,"list":"I10","names":"Hypertension, Unspecified"}',
            year: 2018,
            period: "Q3",
            statusId: 1,
            statusName: "Targeted",
            namelist: ["Hypertension, Unspecified"],
            codelist: ["I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 402,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2018-12-31",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2018,
            period: "Q4",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 402,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-03-31",
            code:
              '{"version":1,"count":1,"list":"I10","names":"Hypertension, Unspecified"}',
            year: 2019,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: ["Hypertension, Unspecified"],
            codelist: ["I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 402,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-06-30",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q2",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 402,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2019-12-31",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2019,
            period: "Q4",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          },
          {
            memberId: 402,
            criteriaId: 7,
            criteriaName: "ICD10Inclusion",
            criteriaDescription:
              "Has an ICD-10 code that is on the inclusion list",
            dateIdentified: "2020-03-31",
            code:
              '{"version":1,"count":2,"list":"E78.5,I10","names":"Hyperlipidemia, Unspecified|Hypertension, Unspecified"}',
            year: 2020,
            period: "Q1",
            statusId: 1,
            statusName: "Targeted",
            namelist: [
              "Hyperlipidemia, Unspecified",
              "Hypertension, Unspecified"
            ],
            codelist: ["E78.5", "I10"],
            version: 1,
            threshold: 0
          }
        ],
        memberRegistration: null
      }
    ]
    /** end demo data **/
  }
});
