<template>
  <v-container fluid>
    <div class="main-header" v-if="mainDocumentList !== undefined">
      {{"Medication Therapy Management"}}
    </div>
    <div v-if="mainDocumentList !== undefined">
      <!-- <v-row>
        <v-col cols="11"></v-col>
        <v-col cols="1">
          <div>
            <v-row>
              <v-menu
                v-model="menuPop"
                :close-on-content-click="false"
                offset-x
              >
                <template v-slot:activator="{ props }">
                  <v-spacer></v-spacer>
                  <img src="../assets/hamburger.png" v-bind="props" />
                </template>
                <v-card width="240">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-600"
                          >Filters</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="menuPop = false">
                          <v-icon class="mg-top-10" size="25" color="black">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="cart-font-size font-weight-500"
                        >Draft</v-list-item-title
                      >
                      <v-list-item-action>
                        <v-switch
                          @change="handleDocFilter('Draft', $event)"
                          color="blue"
                        ></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-title class="cart-font-size font-weight-500"
                        >In Review</v-list-item-title
                      >
                      <v-list-item-action>
                        <v-switch
                          @change="handleDocFilter('complete', $event)"
                          color="blue"
                        ></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-title class="cart-font-size font-weight-500"
                        >Sent</v-list-item-title
                      >
                      <v-list-item-action>
                        <v-switch
                          @change="handleDocFilter('Sent', $event)"
                          color="blue"
                        ></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item-subtitle
                      class="mg-left-15 font-size-11 mg-top-20 font-weight-500"
                      >DATE SENT RANGE</v-list-item-subtitle
                    >
                    <v-list-item>
                      <v-list-item-title class="cart-font-size font-weight-500"
                        >Start</v-list-item-title
                      >
                      <v-list-item-action>
                        <v-menu
                          v-model="dateMenuFromSlot"
                          :close-on-content-click="false"
                          :nudge-left="240"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template #activator="{ on, attrs }">
                            <v-icon
                              class="mg-left-5"
                              size="25"
                              v-bind="attrs"
                              v-on="on"
                              color="blue"
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <v-date-picker
                           @input="dateMenuFromSlot = false"
                           v-model="fromDate"
                           @change="setSelectedFromDates"
                          />
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-title class="cart-font-size font-weight-500"
                        >End</v-list-item-title
                      >
                      <v-list-item-action>
                        <v-menu
                          v-model="dateMenuToSlot"
                          :close-on-content-click="false"
                          :nudge-left="240"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template #activator="{ props }">
                            <v-icon
                              class="mg-left-5"
                              size="25"
                              v-bind="props"
                              color="blue"
                            >
                              mdi-calendar
                            </v-icon>
                          </template>
                          <v-date-picker
                            @input="dateMenuToSlot = false"
                            v-model="toDate"
                            @change="setSelectedToDates"
                          />
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item-subtitle
                      class="mg-left-15 font-size-11 mg-top-20 font-weight-500"
                      >SORT BY</v-list-item-subtitle
                    >
                    <v-radio-group
                      v-model="sortBy"
                      column
                      @change="handleDocFilter('sortBy', $event)"
                    >
                      <v-row>
                        <v-list-item class="main-filter-border">
                          <v-list-item-title
                            class="cart-font-size mg-left-10 font-weight-500"
                            >Sent Date</v-list-item-title
                          >
                          <v-list-item-action>
                            <v-radio value="1" color="blue"></v-radio>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="main-filter-border">
                          <v-list-item-title
                            class="cart-font-size mg-left-10 font-weight-500"
                            >Update Date</v-list-item-title
                          >
                          <v-list-item-action>
                            <v-radio value="2" color="blue"></v-radio>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="main-filter-border">
                          <v-list-item-title
                            class="cart-font-size mg-left-10 font-weight-500"
                            >Clinician</v-list-item-title
                          >
                          <v-list-item-action>
                            <v-radio value="3" color="blue"></v-radio>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-row>
                    </v-radio-group>
                  </v-list>
                  <v-card-actions class="mg-top-20"> </v-card-actions>
                </v-card>
              </v-menu>
            </v-row>
          </div>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" class="pd-0">
          <v-row class="scroll mg-0">
            <v-col
              cols="4"
              class="pd-30"
              v-for="(items, index) in mainDocumentList"
              :key="index"
            >
              <v-card
                class="mx-auto card"
                max-width="400px"
                height="auto"
              >
                <div class="bg-primary-color" style="padding: 0 17px 20px">
                  <v-row class="flex-nowrap mg-top-10" style="margin-left: -1px; margin-top: 0px;">
                    <div class="mg-top-5">
                    <div class="list-item-title mg-top-10 black--text">
                      {{ "M" }}
                    </div>
                    <v-icon size="40" color="#f1eb21d9">
                      mdi-circle
                    </v-icon>
                  </div>
                    <div
                      class="mg-left-15 mg-top-10 font-color-light main-header-title"
                    >
                      {{ "MTM DOCUMENT" }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-icon
                      v-if="items.status === 'Review'"
                      size="20"
                      class="mg-top-10 mg-right-10"
                      @click="handleCopyMtmDocument(items.id)"
                      color="white"
                    >
                      mdi-content-copy
                    </v-icon>
                  </v-row>
                  <v-row class="mg-5">
                    <v-col cols="1" class="mg-left-10 pd-unset"></v-col>
                    <v-col cols="9" class="mg-left-13 pd-unset" v-if="!appUser.juniorClinician && items.status !== 'Review'">
                      {{items.status}}
                    </v-col>
                    <v-col cols="9" class="mg-left-13 color-red pd-unset" v-else>
                      {{"Currently In Review"}}
                    </v-col>
                  </v-row>
                </div>
                <div class="mg-left-15 mg-top-10 cart-padding">
                  <v-row>
                    <v-col cols="4" class="pd-0">
                      <p class="list-inner-text">{{ "Update Date:" }}</p>
                    </v-col>
                    <v-col cols="8" class="pd-0">
                      <p class="mg-top-5 cart-font-size">
                        {{ items.displayUpdatedAt2 }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pd-0">
                      <p class="list-inner-text">{{"Sent Date:"}}</p>
                    </v-col>
                    <v-col cols="8" class="pd-0">
                      <p
                        class="mg-top-5 cart-font-size"
                        v-if="items.status === 'Review' || items.status === 'Not Approved' || isCurrentDateUpdate"
                      >
                        {{ items.displaySentDate2 }}
                      </p>
                      <p class="mg-top-5 cart-font-size" v-if="items.status === 'Draft' && isCurrentDateUpdate === false">
                        {{ "Not Sent" }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pd-0">
                      <p class="list-inner-text">{{ "Clinician:" }}</p>
                    </v-col>
                    <v-col cols="8" class="pd-0">
                      <p class="mg-top-5 cart-font-size">{{appUser.user.firstname +" "+appUser.user.lastname}}</p>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="items.status === 'Draft' || items.status === 'Not Approved'">
                  <v-list-item-title
                    class="list-inner-text mg-left-15 mg-top-3rm pd-10"
                    >Percent Complete</v-list-item-title
                  >
                    <div class="mg-top-10 pd-15">
                      <v-slider
                        class="pd-0"
                        v-model="items.percentComplete"
                        readonly
                        track-color="primary-color"
                        color="indigo"
                        min="0"
                        max="100"
                        thumb-label="always"
                      >
                      </v-slider>
                    </div>
                  </div>
                  <div v-if="items.status === 'Sent' || items.status === 'Review' || items.status === 'Approved'">
                  <v-row class="mg-top-5">
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <v-icon size="117" color="#2196f3">
                        mdi-file-pdf-box
                      </v-icon>
                    </v-col>
                  </v-row>
                </div>
                  <v-row>
                    <v-col cols="4" v-if="appUser.juniorClinician === false && items.status !== 'Sent'">
                      <v-btn
                        color="blue-grey"
                        variant="text"
                        @click="handleReviewDocPdfConfirm(items.id)"
                      >
                        Send
                      </v-btn>
                      </v-col>
                    <v-col cols="4" v-if="appUser.juniorClinician  && items.status !== 'Sent'">
                      <v-btn
                        color="blue-grey"
                        variant="text"
                        @click="handleReviewDocument(items.id, items.status)"
                      >
                        Send
                      </v-btn>
                    </v-col>
                    <v-col cols="4" v-if="items.status === 'Review'">
                      <v-btn
                        color="blue-grey"
                        variant="text"
                        @click="handleReviewDocument(items.id, items.status)"
                      >
                        Review
                      </v-btn>
                    </v-col>
                    <v-col cols="4" v-if="items.status !== 'Sent' || items.status !== 'Review'">
                      <v-btn
                        color="blue"
                        variant="text"
                        @click="handleEditDocument(items.id, items.status)"
                      >
                        Edit
                      </v-btn>
                    </v-col>
                    <v-col cols="4" v-if="items.status === 'Draft'">
                      <v-btn
                        color="red"
                        variant="text"
                        @click="handleDeleteDocumentConfirm(items.id)"
                      >
                        Delete
                      </v-btn>
                    </v-col>
                  </v-row>
              </v-card>
            </v-col>
          </v-row>
          <div class="mg-top-10" style="text-align: end; margin-bottom: 10px;">
            <v-fab-transition>
              <v-btn
                v-if="createDocButton"
                class="floating-btn"
                color="blue"
                dark
                absolute
                bottom
                right
                depressed
                fab
                @click="handleCreatMTMDocumentConfirm"
              >
                <v-icon>mdi-plus</v-icon>CREATE</v-btn
              >
            </v-fab-transition>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="mainDocumentList === undefined && isDataNotFound">
      <v-row class="mg-top-30">
        <v-col cols="3"></v-col>
        <v-col cols="5">
          <div>
            <p
              class="text-center font-weight-bold font-size-19 mg-top-4rm mg-right-10"
            >
              No MTM documents have been created.
            </p>
          </div>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <div>
        <v-row>
          <v-spacer></v-spacer>
          <div class="pd-0">
            <v-fab-transition>
              <v-btn
                class="floating-btn"
                color="blue"
                dark
                bottom
                right
                depressed
                fab
                @click="handleCreatMTMDocumentConfirm"
              >
                <v-icon>mdi-plus</v-icon>CREATE</v-btn
              >
            </v-fab-transition>
          </div>
        </v-row>
      </div>
    </div>
    <div>
      <AlertDialogs
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        :alert-message-type="alertMessageType"
        @closeDialog="closeAlertDialog"
        @noDialog="noAlertDialog"
        @onConfirmOK="handleAlertConfirmation"
      />
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import store from "@/store";
import interceptorSetup from '../helpers/httpInterceptor'
export default {
name: "MTMMain",
components: {
  AlertDialogs,
},
data() {
  return {
    sortBy: 0,
    alertType: "",
    alertMessage: "",
    alertMessageType: "",
    confirmType: "",
    isHideShowAlertDialog: false,
    createDocButton: true,
    isNavBars : true,
    fav: true,
    menuPop: false,
    message: false,
    hints: true,
    fromDate: null,
    toDate: null,
    selectedFromDate: null,
    selectedToDate: null,
    tempMainDocumentLists: undefined,
    dateMenuFromSlot: false,
    dateMenuToSlot: false,
    sentDateRadio: false,
    updateDateRadio: false,
    clinicianRadio: false,
    mainDocumentList: undefined,
    mtmdocId: null,
    pageReload: false,
    isDataNotFound: false,
    isCurrentDateUpdate: false
  };
},
computed: {
  selectedMemberId () {
    return store.getters.selectedMemberId;
  },
  selectedClient() {
    return store.getters.selectedClient;
  },
  appUser() {
    return store.getters.appUser;
  }
},
methods: {
  handleEditDocument (id, status) {
    this.mtmdocId = id;
    const checkingOnlyMain = 1;
    store.dispatch("setSelectDocumentId", id);
    this.$emit("showSideNav", checkingOnlyMain, status);
  },
  handleReviewDocument (id, status) {
    this.mtmdocId = id;
    store.dispatch("setSelectDocumentId", id);
    this.$emit("showSideNav", 7, status);
  },
  setSelectedFromDates (value) {
    const newValue = value.target.value
    this.mainDocumentList = this.tempMainDocumentLists;
    this.fromDate = newValue;
    if (newValue === null) {
      this.selectedFromDate = "";
    } else {
      const [year, month, day] = newValue.split("-");
      this.selectedFromDate = `${month}/${day}/${year}`;
    }
    this.handleDocFilter("documentDates", this.selectedFromDate);
  },
  setSelectedToDates (value) {
    const newValue = value.target.value
    this.mainDocumentList = this.tempMainDocumentLists;
    this.toDate = newValue;
    if (newValue === null) {
      this.selectedToDate = "";
    } else {
      const [year, month, day] = newValue.split("-");
      this.selectedToDate = `${month}/${day}/${year}`;
    }
    this.handleDocFilter("documentDates", this.selectedToDate);
  },
  handleDeleteDocumentConfirm (id) {
    this.mtmdocId = id;
    this.alertMessage =
      "Please confirm if you want delete the currently incomplete MTM document";
    this.alertType = "Confirmation";
    this.alertMessageType = "No"
    this.confirmType = "remove";
    this.isHideShowAlertDialog = true;
  },
  handleCreatMTMDocumentConfirm () {
    this.alertMessage = "Would you like to create one?";
    this.alertType = "Confirmation";
    this.alertMessageType = "No"
    this.confirmType = "addMtmDocument";
    this.isHideShowAlertDialog = true;
  },
  closeAlertDialog () {
    this.isHideShowAlertDialog = false;
    this.alertType = "";
    this.alertMessage = "";
    this.confirmType = "";
    if(this.pageReload) {
      const clientData = this.selectedClient;
      const memberId = this.selectedMemberId;
      store.dispatch("showLoadingSpinner");
      this.handleAllMtmDocument(clientData.clientId, memberId);
    }
  },
  noAlertDialog () {
    this.isHideShowAlertDialog = false;
    this.alertType = "";
    this.alertMessage = "";
    this.confirmType = "";
    this.isDataNotFound = true
    this.mainDocumentList = undefined
  },
  handleAlertConfirmation () {
    this.isHideShowAlertDialog = false;
    this.alertType = "";
    this.alertMessage = "";
    const clientData = this.selectedClient;
    const memberId = this.selectedMemberId;
    if (this.confirmType === "remove") {
      if (this.mtmdocId !== null && clientData.clientId !== null && memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.getMtmdocdelete(clientData.clientId, memberId);
      }
    } else if (this.confirmType === "addMtmDocument" && clientData.clientId !== null && memberId !== null) {
      this.handleCreatMTMDocument(clientData.clientId);
    } else if (this.confirmType === "pdfDownload") {
       this.handleReviewDocumentPdf(clientData.clientId, memberId)
    }
  },
  createDocumentButtonStatus(documentList) {
     documentList?.map((item) => {
       if(item.status === "Draft") {
          this.createDocButton = false;
       }
    });
  },
  mapResponseWithFormData(documentList){
    const tempMtmMainDocumentList = [...documentList];
    tempMtmMainDocumentList.forEach(items => {
      const [year, month, day] = items.displayUpdatedAt.split("-");
      items.displayUpdatedAt2 = `${month}/${day}/${year}`;
      if(items.displaySentDate !== null){
        const [year, month, day] = items.displaySentDate.split("-");
        items.displaySentDate2 = `${month}/${day}/${year}`;
      }
    });
    this.mainDocumentList = tempMtmMainDocumentList;
    this.tempMainDocumentLists = tempMtmMainDocumentList;
    store.dispatch("setSelectDocumenStatusReview", 'Draft');
    this.createDocumentButtonStatus(documentList);
  },
  handleAllMtmDocument (clientId, memberId) {
    this.getAllMtmDocument(clientId, memberId);
  },
  handleCreatMTMDocument (clientId) {
    store.dispatch("showLoadingSpinner");
    const formData={
      companyId:1,
      clientId: clientId,
      memberId:this.selectedMemberId,
      clinicianId:this.appUser.user.userId,
      reviewerId:0,
      status:"Draft",
      percentComplete: 0
    };
    this.createMtmDocument(formData, clientId);
  },
  createMtmDocument (formData, clientId) {
    axios
      .post("/mtm/document/addmtmDocument?clientId=" + clientId, formData)
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
            store.dispatch("setSelectDocumenStatusReview", 'Draft');
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
  },
  getAllMtmDocument (clientId, memberId) {
    axios
      .get(
        "/mtm/document/getmtmdocs?clientId=" +
          clientId +
          "&memberId=" +
          memberId
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            if(response.data.dataLists.length > 0) {
              this.mapResponseWithFormData(response.data.dataLists);
            } else {
              this.alertType = "Confirmation";
              this.alertMessage = 'There are currently no MTM documents for this member. Would you like to create one?';
              this.confirmType = "addMtmDocument"
              this.alertMessageType = "Yes"
              this.isHideShowAlertDialog = true;
            }
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        //store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
  },
  getMtmdocdelete(clientId, memberId) {
    axios
      .delete(
        "/mtm/document/getdeleteById?clientId=" +
          clientId +"&memberId="+memberId +
          "&Id=" +
          this.mtmdocId
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            const responseIndex = this.mainDocumentList.findIndex((item) => item.id === this.mtmdocId);
            if (responseIndex !== -1) {
              this.mainDocumentList.splice(responseIndex, 1);
            }
              this.createDocButton = true;
              this.createDocumentButtonStatus(this.mainDocumentList);
              this.alertType = "Success";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
            } else if (response.data.code === 401) {
              this.alertType = "Failed";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
          console.log(error);
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        });
    },
    handleDocFilter (value, event){
      const tempMainDocumentList = [...this.tempMainDocumentLists];
      let finalResult = "";
      if (value === "Draft") {
        if (event === true) {
          finalResult = tempMainDocumentList.filter((items) => items.status === "Draft");
        } else {
          finalResult = this.tempMainDocumentList;
        }
      }
      if (value === "complete") {
        if (event === true) {
          finalResult = tempMainDocumentList.filter((items) => items.status === "complete");
        } else {
          finalResult = this.tempMainDocumentLists;
        }
      }
      if (value === "Sent") {
        if (event === true) {
          finalResult = tempMainDocumentList.filter((items) => items.status === "Sent");
        } else {
          finalResult = this.tempMainDocumentLists;
        }
      }
      if (value == "documentDates") {
        const selectedFromDate1 = this.selectedFromDate;
        const selectedToDate1 =
        this.selectedToDate == null ? "" : this.selectedToDate;
        if (selectedFromDate1 !== "" && selectedToDate1 !== "") {
          finalResult = tempMainDocumentList.filter(function (items) {
            return (
              new Date(items.updateDate) >= new Date(selectedFromDate1) &&
              new Date(items.updateDate) <= new Date(selectedToDate1)
            );
          });
        } else {
          finalResult = this.tempMainDocumentLists;
        }
      }
      if (value === "sortBy") {
        this.mainDocumentList = this.tempMainDocumentLists;
          if (event == 1) {
            finalResult = tempMainDocumentList.sort(function (a, b) {
            return new Date(a.sentDate) - new Date(b.sentDate);
          });
            } else if (event === 2) {
              finalResult = tempMainDocumentList.sort(function (a, b) {
            return new Date(a.updateDate) - new Date(b.updateDate);
          });
            } else {
              finalResult =  tempMainDocumentList.sort(function (a, b) {
            return a.clinician.localeCompare(b.clinician);
          });
        }
      }
      this.mainDocumentList = finalResult;
      },
      handleCopyMtmDocument (documentId) {
      if(this.createDocButton) {
        const formData = this.mainDocumentList.find((item) => item.id === documentId);
        const clientData = this.selectedClient;
          if(clientData.clientId !== null && this.selectedMemberId !== null) {
          store.dispatch("showLoadingSpinner");
          this.addCopyMtmDocument(formData, clientData.clientId, this.selectedMemberId);
        }
      } else {
        this.alertType = "Failed";
        this.alertMessage = 'Copy not available at this time. An existing MTM document is currently editable and is not complete.';
        this.isHideShowAlertDialog = true;
      }
      },
      addCopyMtmDocument (formData, clientId, memberId) {
      axios
        .post("/mtm/document/copymtmDocument?clientId=" + clientId + "&memberId=" + memberId, formData)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            if (response.data.code === 201) {
              this.alertType = "Success";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
              this.pageReload = true;
            } else if (response.data.code === 401) {
              this.alertType = "Failed";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
          console.log(error);
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        });
      },
      isComplete(mainObj) {
        let isCompleteSec = false
        if(mainObj.contactStatus === 1 && mainObj.todoStatus === 1 && mainObj.medsStatus === 1 && mainObj.allergiesStatus === 1 && mainObj.issuesStatus === 1 && mainObj.otherStatus === 1){
          isCompleteSec = true
        }
        return isCompleteSec
      },
      handleReviewDocPdfConfirm (documentId) {
        this.pdfDocumentId = documentId
        this.confirmType = 'pdfDownload'
        this.alertMessage = 'Are you want to send the document ?'
        this.alertType = 'Confirmation'
        this.alertMessageType= 'No'
        this.isHideShowAlertDialog = true;
      },
      handleReviewDocumentPdf(clientId, memberId) {
        const mainObj = this.mainDocumentList.find(item => item.id === this.pdfDocumentId);
        if(this.isComplete(mainObj)){
          if(clientId !== null && memberId !== null) {
            this.getReviewDocument(clientId, memberId, this.pdfDocumentId)
          }
        } else {
          this.alertType = "Failed";
          this.alertMessage = "MTM Document is not completed!";
          this.isHideShowAlertDialog = true;
        }
      },
      getReviewDocument(clientId, memberId, documentId) {
      axios
      .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
        {responseType: "blob"},
      )
      .then((response) => { 
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            var a = document.createElement("a");
            a.href = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
            a.setAttribute("download", "review");
            a.click();
            setTimeout(() => {
              this.updateSentDate(clientId, documentId)
            }, 1000);
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    updateSentDate (clientId, documentId) {
      axios
      .post('/mtm/document/updatesentdate?clientId='+clientId +'&id='+documentId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.isCurrentDateUpdate = true
            const clientData = this.selectedClient;
            const memberId = this.selectedMemberId;
            store.dispatch("showLoadingSpinner");
            this.handleAllMtmDocument(clientData.clientId, memberId);
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          // console.log("Update Sent Document Error");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    }
  },
  mounted () {
    const clientData = this.selectedClient;
    const memberId = this.selectedMemberId;
    store.dispatch("showLoadingSpinner");
    interceptorSetup.refreshToken();
    if(clientData.clientId !== null && memberId!== null) {
      this.handleAllMtmDocument(clientData.clientId, memberId);
    }
  }
};
</script>
<style>
.v-list {
  display: block;
  padding: 0px 0;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
img {
  width: 18px;
  margin: 10px 0px 0px 0px;
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: revert;
  min-height: 13px;
  outline: none;
  padding: 2px 16px;
  position: relative;
  text-decoration: auto;
}
.v-btn--absolute.v-btn--right,
.v-btn--fixed.v-btn--right {
  right: 51px;
}
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: 90px;
}
</style>
