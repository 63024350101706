<template>
  <div style="width:100%; height:100%; position:relative;">
    <ag-grid-vue
      class="ag-theme-alpine"
      style="width:100%; height: 500px;"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="filteredClaims"
      :pagination="true"
      @grid-ready="onGridReady"
      :paginationPageSize="selectedPageSize"
      :groupDisplayType="groupDisplayType"
      :rowSelection="rowSelection"
    >
    </ag-grid-vue>
    <v-row>
      <v-col cols="4" sm="3" md="2" lg="1" offset="8" offset-sm="9" offset-md="10" offset-lg="11" 
      class="mt-1">
        <v-select
          :items="pageSizeOptions"
          :value="selectedPageSize"
          @update:modelValue="changePageSize"
          label="Rows per Page"
          variant="outlined"
          density="comfortable"
          hide-details="auto"
          dense
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import store from "@/store";
import renderPharmacyCell from "./renderers/renderPharmacyCell.vue";
import renderPrescriberCell from "./renderers/renderPrescriberCell.vue";
import renderDrugCell from "./renderers/renderDrugCell.vue";

export default {
  name: "RxClaims",
  components: {
    AgGridVue,
    renderPharmacyCell,
    renderPrescriberCell,
    renderDrugCell
  },
  props: ["filter"],
  data() {
    return {
      groupDisplayType: 'groupRows',
      rowSelection: 'single',
      selectedPageSize: 25,
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1,
        minWidth: 120/*,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true*/
      },
      columnDefs: [
        {
          groupId: "date",
          headerName: "",
          marryChildren: true,
          children: [
            {headerName: "Service Date",field: "dateService" }
          ]
        },{
          groupId: "drug",
          headerName: "Drug",
          marryChildren: true,
          children: [
            {headerName: "Drug Name and Strength",field: "drugName", minWidth: 250, cellRenderer: "renderDrugCell", cellRendererParams : {
                action : this.drugNameClicked.bind(this) }, columnGroupShow: ["open","closed"] },
            {headerName: "Therap Class",field: "therapClass" , minWidth: 200, columnGroupShow: "open"  },
            {headerName: "Therap Sub Class",field: "therapSubClass" , minWidth: 200, columnGroupShow: "open"  },
            {headerName: "GPI",field: "drugGPI" , minWidth: 200, columnGroupShow: "open" },
            {headerName: "Drug Type",field: "drugTypeClient" , columnGroupShow: "open" },
            {headerName: "Rx Svc Ref Nbr",field: "rxSvcRefNbr" , columnGroupShow: "open" },
            {headerName: "Qty Disp",field: "qtyDisp" , columnGroupShow: ["open","closed"] },
            {headerName: "Days Supply",field: "daysSupplyDisp" , columnGroupShow: ["open","closed"] },
            /*
            {headerName: "Fill Nbr",field: "fillNbr"  },
            {headerName: "Nbr Ref Auth",field: "nbrRefAuth"  },
            {headerName: "Disp As Writ Prod Sel Code",field: "dispAsWritProdSelCode"  },
            {headerName: "Therap Category",field: "therapCategory" , minWidth: 200  },
            {headerName: "Generic Name",field: "genericName", minWidth: 250  },
            {headerName: "Generic GPI Code",field: "genericGPI" , minWidth: 200 },
            */
          ]
        },{
          groupId: "financials",
          headerName: "Financials",
          marryChildren: true,
          children: [
            {headerName: "Patient Cost",field: "patientPayAmt", valueFormatter: currencyFormatter  },
            {headerName: "Total Rx Cost",field: "totalRxCost", valueFormatter: currencyFormatter },
            /*
            {headerName: "Net Amt Due",field: "netAmtDue", valueFormatter: currencyFormatter  },
            {headerName: "Brand Class CCRX Price",field: "brandClassCCRXPrice", valueFormatter: currencyFormatter },
            {headerName: "Basis Cost CCRX",field: "basisCostCcrx", valueFormatter: currencyFormatter },
             */
          ]
        },{
          groupId: "pharmacy",
          headerName: "Pharmacy",
          marryChildren: true,
          children: [
            {headerName: "Pharmacy Name", field: "pharmacyName", minWidth: 280, cellRenderer: "renderPharmacyCell", columnGroupShow: ["open","closed"]},
            {headerName: "NCPDP #", field: "ncpdpid", cellRenderer: "renderPharmacyCell", columnGroupShow: "open"},
            /*
            {headerName: "Pha Svc Prov Id" , field: "phaSvcProvId"  },
            */
          ]
        },{
          groupId: "prescriber",
          headerName: "Prescriber",
          marryChildren: true,
          children: [
            {headerName: "Prescriber Name", field: "prescrNPIName", minWidth: 280, cellRenderer: "renderPrescriberCell", columnGroupShow: ["open","closed"] },
            {headerName: "Prescr Id", field: "prescrId", cellRenderer: "renderPrescriberCell" , columnGroupShow: "open" },
          ]
        }
        /*
        ,{
          groupId: "patient",
          headerName: "Patient Details",
          marryChildren: true,
          openByDefault: true,
          columnGroupShow: "open",
          children: [
            {headerName: "PatientId",field: "patientId" , minWidth: 200  },
            {headerName: "PatientFirstName",field: "patientFirstName" , valueFormatter: privacyFormatter },
            {headerName: "PatientMiddleInitial",field: "patientMiddleInitial" , cellRenderer: params => { return (this.privacyMode==true) ? "*" : params.value ; } },
            {headerName: "PatientLastName",field: "patientLastName" , valueFormatter: privacyFormatter  },
            {headerName: "PatientDtOfBirth",field: "patientDtOfBirth" , cellRenderer: params => { return (this.privacyMode==true) ? this.showAge(params.value) : params.value ; } },
            {headerName: "PatientPersonCode",field: "patientPersonCode"  },
            {headerName: "PatientGenderCode",field: "patientGenderCode"  },           
            {headerName: "PatientAddressLine1",field: "patientAddressLine1" , valueFormatter: privacyFormatter },
            {headerName: "PatientAddressLine2",field: "patientAddressLine2" , valueFormatter: privacyFormatter },
            {headerName: "PatientCity",field: "patientCity", valueFormatter: privacyFormatter  },
            {headerName: "PatientState",field: "patientState" , valueFormatter: privacyFormatter  },
            {headerName: "PatientZipCode5",field: "patientZipCode5", valueFormatter: privacyFormatter   },
            {headerName: "PatientZip4",field: "patientZip4", valueFormatter: privacyFormatter  }
          ]
        }

         ***the following fields removed from data pull ***
        {headerName: "Unit Cost",field: "unit_Cost", hide: true, valueFormatter: currencyFormatter  },
        {headerName: "Unit Cost Plus",field: "unit_Cost_Plus", hide: true, valueFormatter: currencyFormatter  },
        {headerName: "Ingr Cost Paid",field: "ingrCostPaid", hide: true, valueFormatter: currencyFormatter  },
        {headerName: "Disp Fee Paid",field: "dispFeePaid", hide: true, valueFormatter: currencyFormatter  },
        {headerName: "Net Claim Ind",field: "netClaimInd", hide: true  },
        {headerName: "Unc Charge",field: "uncCharge", hide: true  },
        {headerName: "Rx Flag",field: "rxFlag", hide: true  },
        {headerName: "Paid Rev Den Flag",field: "paidRevDenFlag", hide: true  },
        {headerName: "CCRX AWP Full Price",field: "ccrxAwpFullPrice", hide: true, valueFormatter: currencyFormatter  },
        {headerName: "AEP Total Client",field: "awpTotalClient", hide: true  },
        {headerName: "AWP Discount",field: "awp_Discount", hide: true  },
        {headerName: "UniqueTxNbr",field: "uniqueTxNbr", hide: true  },
        {headerName: "UniqueTxRefIdNbr",field: "uniqueTxRefIdNbr", hide: true  },
        {headerName: "Transaction Id",field: "transactionId", hide: true  },
        {headerName: "Date Adjud",field: "dateAdjud", hide: true  },
        {headerName: "Compound Flag",field: "compoundFlag", hide: true  },
        {headerName: "ClientCode", field: "clientCode", hide: true },
        {headerName: "cardholderId", field: "cardholderId", hide: true },
        {headerName: "adjYearMo", field: "adjYearMo", hide: true },
        {headerName: "channelCCRX", field: "channelCCRX", hide: true },
        {headerName: "groupId", field: "groupId", hide: true },
        {headerName: "prodSvcId", field: "prodSvcId", hide: true },
        {headerName: "cobClaimDesc", field: "cobClaimDesc", hide: true },
        {headerName: "otherPayerPatRespAmtQual01", field: "otherPayerPatRespAmtQual01", hide: true },
        */
      ],
      /*
      sideBarDef: {
        toolPanels: [
          {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: false,
                suppressPivotMode: false,
                suppressValues: false
              },
              minWidth: 225,
              maxWidth: 225,
              width: 225
          },
          {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
              minWidth: 180,
              maxWidth: 400,
              width: 250
          }
        ],
        position: 'right'
      }
      ,groupDisplayType: 'groupRows'
      */
    }
  },
  methods: {
    drugNameClicked(value) {
      this.$emit("drugNameClicked", value)
    },
    formatPhone(number) {
      if (number == null || number.length == 0) return "";
      if (number.length == 7)
        return number.substr(0, 3) + "-" + number.substr(3, 4);
      if (number.length == 10)
        return (
          "(" +
          number.substr(0, 3) +
          ") " +
          number.substr(3, 3) +
          "-" +
          number.substr(6, 4)
        );
      return number;
    },
    showAge(dob) {
      if(dob != null && dob != ""){
        var year = Number(dob.substr(0, 4));
        var month = Number(dob.substr(4, 2)) - 1;
        var day = Number(dob.substr(6, 2));
        var today = new Date();
        var age = today.getFullYear() - year;
        if (
          today.getMonth() < month ||
          (today.getMonth() == month && today.getDate() < day)
        ) {
          age--;
        }
        if (age < 2) {
          age = today.getMonth() - month;
          if (today.getDate() < day) {
            age--;
          }
          return age + " months";
        }
        return age + " years";
      }else{
        return "";
      }
    },
    changePageSize(value) {
      this.selectedPageSize = value;
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  },
  computed: {
    claims() {
      return store.getters.memberDetail.rxClaims;
    },
    privacyMode() {
      return store.getters.privacyMode;
    },
    filteredClaims() {
      var fClaims;
      if (this.filter == null || this.filter == "") {
        fClaims = [...this.claims];
      } else {
        var fgpi = [];
        for (var j = 0; j < store.getters.memberDetail.drugs.length; j++) {
          if (store.getters.memberDetail.drugs[j].genericID == this.filter) {
            fgpi.push(store.getters.memberDetail.drugs[j].gpicode);
          }
        }
        fClaims = this.claims.filter(claim => fgpi.indexOf(claim.drugGPI) != -1);
      }

      return fClaims;
    },
    pageSizeOptions() {
      var sizes = [25];
      var numclaims = this.filteredClaims.length;
      if(numclaims > 25) sizes.push(50);
      if(numclaims > 50) sizes.push(100);
      if(numclaims > 100) sizes.push(numclaims);
      return sizes;
    },
    

  },
  watch: {
    privacyMode() {
      this.gridApi.refreshCells({force:true});
    }
  }
};

</script>
<style>
.ag-theme-alpine .ag-row.highlighted-row {
  background-color: yellow; /* You can change this to the color you prefer */
}
</style>