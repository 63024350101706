<template>
  <v-container fluid>
    <v-window v-model="tabValue">
      <v-window-item value="0">
        <!-- <SelectCriteriaReport  v-if="showTopButton" :reportNameTarget="reportName" /> -->

        <!-- <DisplayAllCriteriaReport
          v-if="
            selectedReport != null &&
              (selectedReport.id == 0 || selectedReport.id == 101)
          "
        />
        <DisplayMoneyCriteriaReport
          v-if="
            selectedReport != null &&
              (selectedReport.id == 4 || selectedReport.id == 5)
          "
        />
        <DisplayPrescriberCriteriaReport
          v-if="selectedReport != null && selectedReport.id == 2"
        />
        <DisplayDrugCriteriaReport
          v-if="
            selectedReport != null &&
              (selectedReport.id == 1 || selectedReport.id == 6)
          "
        />
        <DisplayPharmacyCriteriaReport
          v-if="selectedReport != null && selectedReport.id == 3"
        />
        <DisplayICDCriteriaReport
          v-if="selectedReport != null && selectedReport.id == 7"
        /> -->
      </v-window-item>
      <v-window-item value="1">
        <DisplayMemberDetails />
      </v-window-item>
      <v-window-item value="2">
        <MemberSearch />
      </v-window-item>
      <v-window-item value="3">
        <QualifiedReport :reportNames="reportName" />
      </v-window-item>
    </v-window>
  </v-container>
  <v-footer app
    ><div>
      &copy; {{ new Date().getFullYear() }} MemberFirstRx
    </div></v-footer
  >
  <div class="loadingSpinner" id="loadingSpinner">
    <div class="loadingSpinnerImg" id="loadingSpinnerImg">
      <img src="/css/images/spinner.gif" id="spinnerImg" />
    </div>
  </div>
</template>
<style>
@import '../assets/StyleSheets/global.css';
@import '../assets/StyleSheets/ag-grid.css';
@import '../assets/StyleSheets/ag-theme-alpine.css';
</style>
<script>
import SelectCriteriaReport from "@/components/SelectCriteriaReport.vue";
import DisplayAllCriteriaReport from "@/views/DisplayAllCriteriaReport.vue";
import DisplayDrugCriteriaReport from "@/views/DisplayDrugCriteriaReport.vue";
import DisplayICDCriteriaReport from "@/views/DisplayICDCriteriaReport.vue";
import DisplayMoneyCriteriaReport from "@/views/DisplayMoneyCriteriaReport.vue";
import DisplayPharmacyCriteriaReport from "@/views/DisplayPharmacyCriteriaReport.vue";
import DisplayPrescriberCriteriaReport from "@/views/DisplayPrescriberCriteriaReport.vue";
import DisplayMemberDetails from "@/views/DisplayMemberDetails.vue";
import MemberSearch from "@/views/MemberSearch.vue";
import QualifiedReport from "@/views/QualifiedReport.vue";
import store from "@/store";
import axios from 'axios';
// import eventBus  from '../EventBus';

export default {
name: "App",
components: {
  SelectCriteriaReport,
  DisplayAllCriteriaReport,
  DisplayDrugCriteriaReport,
  DisplayICDCriteriaReport,
  DisplayMoneyCriteriaReport,
  DisplayPharmacyCriteriaReport,
  DisplayPrescriberCriteriaReport,
  DisplayMemberDetails,
  MemberSearch,
  QualifiedReport
},
mounted() {
  store.dispatch("setSelectedReport", null);
  document.title = "MemberFirst Pharmacy Program";
  this.getUser();
  // store.dispatch("hideLoadingSpinner");
  const selectReviewObj = JSON.parse(localStorage.getItem("selectReview"))
  if(selectReviewObj !== null && selectReviewObj.isReview) {
    store.dispatch("setMainTab", 1);
  }
},
computed: {
  qualifiedOptions() {
    var options = store.getters.qualifiedOptions.filter(x => {
      return x.id != 1 && x.id != 30 && x.id != 101;
    });

    return options;
    //return store.getters.qualifiedOptions;
  },
  criteriaOptions() {
    return store.getters.criteriaOptions;
  },
  message() {
    return store.getters.message;
  },
  showMessage() {
    return store.getters.showMessage;
  },
  showMessageClass() {
    return "messageDiv " + store.getters.showMessageClass;
  },
  selectedReport() {
    // if (store.getters.selectedReport && store.getters.selectedReport.length > 0) {
    //   store.dispatch("hideLoadingSpinner");
    // }
    return store.getters.selectedReport;
  },
  selectedMemberId() {
    return store.getters.selectedMemberId;
  },
  selectedClient() {
    return store.getters.selectedClient;
  },
  memberDetail() {
    return store.getters.memberDetail;
  },
  mainTab() {
    return store.getters.mainTab;
  },
  appUser() {
    return store.getters.appUser;
  },
  privacyMode() {
    return store.getters.privacyMode;
  },
  clientItems() {
    if (this.appUser != null && this.appUser.clients != null) {
      if (this.privacyMode) {
        var privateClients = [];
        for (var i = 0; i < this.appUser.clients.length; i++) {
          privateClients.push({
            clientId: this.appUser.clients[i].clientId,
            clientCode: this.appUser.clients[i].clientCode,
            active: this.appUser.clients[i].active,
            shortName: "Client Id " + this.appUser.clients[i].clientId,
            longName:
              "Demo Imaginary Client " + this.appUser.clients[i].clientId
          });
        }
        return privateClients;
      } else {
        return this.appUser.clients;
      }
    }
    return null;
  },
  clientSelectDisabled() {
    if (
      this.appUser != null &&
      this.appUser.clients != null &&
      this.appUser.clients.length > 1
    ) {
      return false;
    }
    return true;
  }
},
methods: {
  reportSelectedTargetingReports: function(val) {
    store.dispatch("setSelectedReport", val);
    store.commit("SET_REPORT_MEMBERS", []);
    store.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", []);
    store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
    store.dispatch("getReportMembers");
  },
  reportSelectedPatientStatusReports(val) {
    console.log("report ", val, " selected");
    store.dispatch("setSelectedQualifiedReport", val);
    store.dispatch("getQualifiedReportMembers");
  },
  getUser() {
    store.dispatch("showLoadingSpinner");
    if (!store.getters.appStandAlone) {
      if (this.appUser !== null) {
        store.dispatch("setSelectedClientById", this.appUser.user.lastClientId);
      } else {
         this.appUserIntervalRef =  setInterval(()=>{
            if (this.appUser !== null) {
              store.dispatch("setSelectedClientById", this.appUser.user.lastClientId);
              clearInterval(this.appUserIntervalRef)
            }
          },1000)
        }
       
      } else {
      store.commit("SET_APP_USER", store.getters.demoUser);
      console.log("setting timeout...");
        store.dispatch(
          "setSelectedClientById",
          store.getters.demoUser.user.lastClientId
        );
      
      store.dispatch("setMessage", {
        message: "demo user loaded",
        class: "successMessage"
      });
      store.dispatch("hideLoadingSpinner");
    }
  }
},
destroyed() {
  this.showTopButton = false
},
data() {
  return {
    reportName: '',
    showTopButton: false,
    drawer: false,
    group: null,
    clientSelectModel: null,
    tabValue: '0',
    userLogoutName: null,
    appUserIntervalRef: undefined
  };
},
watch: {
  selectedClient(newClient) {
    this.clientSelectModel = 'clientId ' + newClient.clientId;
  }
}
};

window.privacyFormatter = function privacyFormatter(params) {
if (window.App.$store.getters.privacyMode == true)
  return '******';
else
  return params.value;
};

window.currencyFormatter = function currencyFormatter(params) {
if(params.value){
  return params.value.toLocaleString('en-US',
    {style: 'currency', currency: 'USD'}
  );
}else{
  return "";
}
};
</script>
<style>
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: revert;
  min-height: 15px;
  outline: none;
  padding: 11px 16px!important;
  position: relative;
  text-decoration: auto;
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: revert;
  min-height: 15px;
  outline: none;
  padding: 11px 16px!important;
  position: relative;
  text-decoration: auto;
}
.v-list-item__append {
  margin: auto !important;
  margin-right: 0 !important;
}  
.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  font-size: x-large;
  color: black;
}
</style>
