<template>
  <v-container fluid>
    <div>
      <v-col cols="12"> 
          <div class="header-info">MTM Review</div>
          <div class="pd-top-30">You may generate and review the PDF document generated from the completion of the sections of the MTM docuement. Depending on configuration, you may have to submit the document for review by a peer. </div>
          <div class="pd-top-30 mg-bottom-50" height="100%" v-if="selectisReview === null || selectisReview === false">                          
            <v-row>
                <v-col cols="5" class="mg-right-20">
                  <v-row class=" pd-top-10">  
                    <v-col>
                      <div class="fs-18 pd-top-20"><b> Step 1:</b></div>    
                      <div class="pd-top-20"><v-btn  color="blue white--text" @click="handleReViewDocument" ><v-icon size="20" color="white"> mdi-refresh </v-icon> GENERATE MTM DOCUMENT </v-btn></div>
                    </v-col>
                  </v-row>

                  <v-row class="pd-top-20">  
                    <v-col>
                      <div class="fs-18 pd-top-20"><b> Step 2:</b></div>
                      <div class="pd-top-20"> Review the document below completly before finalizing to send.</div>
                    </v-col>
                  </v-row>
                  
                </v-col>
                <v-col>
                  <v-divider class="mx-4" vertical></v-divider>
                </v-col>
                <v-col cols="5"> 
                  <v-row class=" pd-top-10">  
                    <v-col>
                      <div class="fs-18 pd-top-20"><b> Step 3:</b></div>  
                      <div class="pd-top-10">Change the status of your document</div>  
                      <div v-if="documentStatusReview === 'complete' || documentStatusReview === 'Review'" class="pd-top-10"><v-icon size="30" color="green"> mdi-check </v-icon> <span class="light-green-btn pd-2 fs-16">FINALIZED</span> <v-btn  color="blue white--text" @click="handleDocumentChange('incomplete')" class="mg-left-25" > <v-icon size="20" color="white"> mdi-close-circle </v-icon> <span class="pd-left-5">MARK INCOMPLETE </span></v-btn></div>
                      <div v-if="documentStatusReview === 'incomplete' || documentStatusReview === 'Draft' || documentStatusReview === 'Not Approved' || documentStatusReview === 'Approved'" class="pd-top-10"><v-icon size="30" color="red"> mdi-information-outline </v-icon> <span class="red-color pd-2 fs-16">INCOMPLETE</span> <v-btn  color="blue white--text" @click="handleDocumentChange('complete')" class="mg-left-25" > <v-icon size="20" color="white">  mdi-check </v-icon> <span class="pd-left-5">MARK COMPLETE </span></v-btn></div>
                    </v-col>
                  </v-row>
                  <v-row class="pd-top-20"> 
                    <v-col>
                      <div class="fs-18 pd-top-20"><b> Step 4:</b></div>
                      <div v-if="documentStatusReview === 'Review' || documentStatusReview === 'Approved'" class="pd-top-20"><v-icon size="30" color="green"> mdi-check </v-icon> <span class="light-green-btn pd-2 fs-16">SUBMITTED</span> <v-btn  color="grey white--text" class="mg-left-20" > <v-icon size="20" color="white"> mdi-share-variant </v-icon> <span class="pd-left-5">SUBMIT FOR REVIEW </span></v-btn></div>
                      <div v-if="documentStatusReview !== 'Review'"><v-icon size="30" color="red"> mdi-information-outline </v-icon> <span class="red-color pd-2 fs-16">NOT SUBMITTED</span> <v-btn  color="blue white--text" @click="handleDocumentReviewChange('Review')" :disabled="documentStatusReview !== 'complete'" class="mg-left-20" > <v-icon size="20" color="white"> mdi-share-variant </v-icon> <span class="pd-left-5">SUBMIT FOR REVIEW </span></v-btn></div>
                    </v-col>
                  </v-row>
                </v-col>
            </v-row>
          </div>
          <div>
          <div v-if="selectisReview !== null || selectisReview">
          <v-row>
          <v-col cols="12" class="mg-left-10 mg-right-15">
            <div class="review-cart-bg-color">
              <div class="header-quality-control">Quality Control Review</div>
              <v-row class="mg-top-5">
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="text-align-inherit fs-14"
                      >{{ "Submitted for Review date:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="text-align-inherit fs-14"
                  v-if="smtForReviewDate !== null"
                  >{{ smtForReviewDate }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Clinician:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ "DR. "+selectClinicianName }}</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col
                      cols="9"
                      class="qty-review-title fs-14"
                      >{{ "Member:" }}</v-col
                    >
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  class="qty-review-title fs-14"
                  >{{ memberName }}</v-col
                >
              </v-row>
            </div>
          </v-col>
          </v-row>
          <v-row class="mg-top-20">
            <v-col cols="3"></v-col>
            <v-col cols="3">
              <v-btn variant="text"
                color="error"
                :disabled="documentStatusReview === 'Not Approved'"
                @click="handleDocumentChange('Not Approved')">
                 <span class="pd-left-5">MARK NOT APPROVED</span>
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn  color="info white--text"
               :disabled="documentStatusReview === 'Approved'"
               @click="handleDocumentChange('Approved')">
               <v-icon size="20" color="white"> mdi-check </v-icon> 
               <span class="pd-left-5">MARK APPROVED </span>
              </v-btn>
            </v-col>
          </v-row>
        <v-row>
          <v-col cols="3"></v-col>
          <v-col cols="6" :cols="{'9':documentStatusReview === 'Approved'}">
            <div class="fs-15" v-if="documentStatusReview === 'Review'">{{"Making Not Approved shall send the MTM document back to the clinician to review comments and update as needed. When approved, the document shall be available for the Clinician to send to member."}}</div>
            <div class="fs-15" v-if="documentStatusReview === 'Not Approved'">{{"Document has been marked not approved and has been sent back to the clinican for review. You may mark the document as approved at anytime."}}</div>
            <div class="fs-15" v-if="documentStatusReview === 'Approved'">{{"Document has been marked as approved and can not longer be edited by clinican. As a reviewer, you may choose to mark as not approved and the document will be editable by the clinician again."}}</div>
          </v-col>
          <v-col cols="3" v-if="documentStatusReview === 'Review' || documentStatusReview === 'Not Approved'"></v-col>
        </v-row>
        <v-row>
          <v-col cols="3"></v-col>
          <v-col cols="5"><div class="fs-15">{{"Submitted At:  "+submittedDate}}</div></v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <v-row >
          <v-col cols="11" class="header-info mg-top-2rem">
            <div class="mg-left-3rem"><span class="color-red" v-if="documentStatusReview === 'Not Approved'">DOCUMENT IS NOT APPROVED</span></div>
            <div class="mg-left-3rem"><span class="sec-color"  v-if="documentStatusReview === 'Approved'">DOCUMENT IS APPROVED</span></div>
          </v-col>  
        </v-row>
        </div>
        <div class="mg-top-10" :class="{'mg-top-12rm': selectisReview}">
        <v-row v-if="(selectisReview !== null || selectisReview) || isShowReviewComment">
          <div class="mg-left-20">{{"Optional Overall General Comments"}}</div>
          <v-col cols="12">
            <v-textarea
              auto-grow
              variant="outlined"
              density="comfortable"
              class="mx-2"
              @input="changeCommentReview"
              v-model="commentReview"
              rows="4"
              label="QC Reviewer Comment"
              :readonly="!selectisReview"
            />
          </v-col>
      </v-row>
      <v-row class="mg-bottom-10 mg-right-10 pd-unset" v-if="selectisReview !== null || selectisReview">
        <v-col cols="8"></v-col>
          <v-col cols="4" class="mouse-cursor">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn variant="text" color="info" @click="handleAddComment">{{isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn>
              <v-btn variant="text" @click="handleClearReviewComment" v-if="isShowReviewBtn === false">CLEAR</v-btn>
              <v-btn variant="text" color="error" @click="handleDeleteReviewComment" v-if="isShowReviewBtn">DELETE</v-btn>
            </v-row>
          </v-col>
      </v-row>
       </div>
        </div>
        <div>
          <div class="mg-bottom-20 btn-right"><v-btn  color="blue white--text" @click="downloadPdf" ><v-icon size="20" color="white"> mdi-download </v-icon> DOWNLOAD PDF </v-btn></div>
          <iframe id="pdf" :src="url" frameborder="1" scrolling="auto" width="100%" height="600px" class="pdf-viewer"></iframe>
        </div>
      </v-col>
    </div>
    <div>
      <AlertDialogs
      v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        @closeDialog="closeAlertDialog"
      />
    </div>
  </v-container>
</template>
<script>
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import interceptorSetup from '../helpers/httpInterceptor'
import store from "@/store";
import axios from "axios";
export default {
  name: "MtmReview",
  components: {
    AlertDialogs
  },
  data() {
    return {
      isSubmited: true,
      url: null, 
      clientData: null,
      memberId : null,
      documentId: null,
      complateStatus: "incomplete",
      isHideShowAlertDialog: false,
      alertType: "",
      alertMessage: "",
      reviewSubmitStatus: '',
      documentStatusReview: null,
      memberName: "",
      smtForReviewDate: null,
      commentReview: "",
      isShowReviewBtn: false,
      documentData: undefined,
      isShowReviewComment: false,
      pageReload: false,
      submittedDate: ""
    }
  },
  computed: {
  selectisReview() {
    return store.getters.selectisReview
  },
  selectedMemberId() {
    return store.getters.selectedMemberId;
  },
  selectedClient() {
    return store.getters.selectedClient;
  },
  selectdocumentId() {
    return store.getters.selectdocumentId;
  },
  selectdocumentStatusReview() {
    return store.getters.selectdocumentStatusReview;
  },
  memberDetail () {
    return store.getters.memberDetail;
  },
  selectClinicianName() {
    return store.getters.selectClinicianName
  },
  selectReviewerId() {
    return store.getters.selectReviewerId;
  }
},
methods: {
  handleAddComment() {
    const reviewObj = this.documentData
    reviewObj.remark = this.commentReview
    if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null){
      this.addReviewCommentChange(this.clientData.clientId, this.documentId, reviewObj)
    }
  },
  changeCommentReview (value) {
    this.commentReview = value.target.value
  },
  handleClearReviewComment () {
    this.commentReview = "";
  },
  handleDeleteReviewComment () {
    const reviewObj = this.documentData
    reviewObj.remark = null
    if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null){
      this.addReviewCommentChange(this.clientData.clientId, this.documentId, reviewObj)
    }
  },
  closeAlertDialog () {
    this.isHideShowAlertDialog = false
    this.alertType = ''
    this.alertMessage = ''
    if(this.pageReload) {
      this.getMtmDocById()
    }
  },
  handleDocumentChange(value) {
    if(value === "complete") {
      this.complateStatus = "complete"
    } else if(value === "incomplete") {
      this.complateStatus = "Draft"
    } else {
      this.complateStatus = value;
    }
    if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null){
      this.getDocumentStatusChange (this.clientData.clientId, this.documentId, this.complateStatus)
    }
  },
  addReviewCommentChange (clientId, documentId, reviewerObj) {
  axios
    .post('/mtm/document/updatedocument?clientId='+clientId +'&id='+documentId, reviewerObj)
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 201) {
          this.alertType = "Success";
          this.alertMessage = response.data.message;
          this.isHideShowAlertDialog = true;
          this.pageReload = true  
        } else if (response.data.code === 401) {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
        }
      } else {
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      }
    })
    .catch((error) => {
      console.log(error)
      store.dispatch("hideLoadingSpinner");
      this.alertType = 'Failed'
      this.alertMessage = 'Something went wrong'
      this.isHideShowAlertDialog = true
    })
  },
  getDocumentStatusChange (clientId, documentId, status) {
  axios
    .get('/mtm/document/updateDocumentStatus?clientId='+clientId+'&documentId='+documentId +'&status='+status+'&remark='+this.commentReview)
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 201) {
          this.alertType = "Success";
          this.alertMessage = response.data.message;
          this.isHideShowAlertDialog = true;
          this.pageReload = true
          this.responseDataMapping(status)
        } else if (response.data.code === 401) {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
        }
      } else {
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      }
    })
    .catch((error) => {
      console.log(error)
      store.dispatch("hideLoadingSpinner");
      this.alertType = 'Failed'
      this.alertMessage = 'Something went wrong'
      this.isHideShowAlertDialog = true
    })
  },
  handleDocumentReviewChange() {
    if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null){
      this.getDocumentStatusReviewChange (this.clientData.clientId, this.documentId)
    }
  },
  getDocumentStatusReviewChange (clientId, documentId) {
  axios
    .post('/mtm/review/document?clientId='+clientId+'&documentId='+documentId)
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 201) {
          this.alertType = "Success";
          this.alertMessage = response.data.message;
          this.isHideShowAlertDialog = true;
          this.responseDataMapping("Review")
        } else if (response.data.code === 401) {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
        }
      } else {
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      }
    })
    .catch((error) => {
      console.log(error)
      store.dispatch("hideLoadingSpinner");
      this.alertType = 'Failed'
      this.alertMessage = 'Something went wrong'
      this.isHideShowAlertDialog = true
    })
  },
  responseDataMapping (status) {
    store.dispatch("setSelectDocumenStatusReview", status);
    this.documentStatusReview = status
  },
  submitForReview(value) {
    if(value === "Review") {
        this.reviewSubmitStatus = "Review"
        if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null){
        this.getDocumentStatusChange (this.clientData.clientId, this.documentId, value)
      }
    }
  },
  handleReViewDocument () {
    if (this.clientData.clientId !== null && this.memberId !== null) {
      store.dispatch("showLoadingSpinner");
      this.getReviewDocument(this.clientData.clientId, this.memberId, this.documentId)
    }
  },
  getReviewDocument(clientId, memberId, documentId) {
    axios
    .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
      {responseType: "blob"},
    )
    .then((response) => { 
        // console.log(response);
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          this.url = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
      console.log(error);
      store.dispatch("hideLoadingSpinner");
      this.alertType = "Failed";
      this.alertMessage = "Something went wrong";
      this.isHideShowAlertDialog = true;
    });
  },
  downloadPdf() {
    var a = document.createElement("a");
    a.href = this.url;
    a.setAttribute("download", "review");
    a.click();
  },
  getMtmDocById () {
    axios
      .get('/mtm/document/getmtmdocById?clientId='+this.clientData.clientId +'&Id='+this.documentId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.documentData = response.data.dataLists
            this.documentStatusReview = response.data.dataLists.status;
            this.commentReview = response.data.dataLists.remark !== null ?  this.commentReview = response.data.dataLists.remark : "";
            if(response.data.dataLists.displaySentDate !== null) {
              const [year, month, day] = response.data.dataLists.displaySentDate.split("-");
              this.smtForReviewDate = `${month}/${day}/${year}`;
            } else {
              this.smtForReviewDate = ""
            }
            if(response.data.dataLists.displayUpdatedAt !== null) {
              const [yearly, monthly, days] = response.data.dataLists.displayUpdatedAt.split("-");
              this.submittedDate = `${monthly}/${days}/${yearly}`;
            } else {
              this.submittedDate = ""
            }
            if(response.data.dataLists.remark === null || response.data.dataLists.remark === ""){
              this.isShowReviewComment = false
              this.isShowReviewBtn = false
            } else {
              this.isShowReviewComment = true
              this.isShowReviewBtn = true
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    loadData() {
      this.clientData = this.selectedClient
      this.memberId = this.selectedMemberId
      this.documentId = this.selectdocumentId
      this.memberName = this.memberDetail.member.firstName +' '+this.memberDetail.member.lastName
      this.documentStatusReview = this.selectdocumentStatusReview;
      interceptorSetup.refreshToken();
      if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        store.dispatch("showLoadingSpinner");
        this.getMtmDocById();
        setTimeout(() => {
          this.getReviewDocument(this.clientData.clientId, this.memberId, this.documentId);
        }, 1000);
      }
    }
  },
  mounted () {
    this.loadData();
  },
  activated() {
    if(this.selectdocumentId !== this.documentId) {
      this.loadData();
    }
  }
}
</script>
<style>
.light-green-btn{
  color: #63db4b;
}
</style>


