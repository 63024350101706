<template>
  <v-container fluid>
    <v-row class="mg-top-5 pd-10">
      <v-col cols="6" md="3">
        <v-text-field
          v-model="memberSearchText"
          label="Search by Member fields"
          variant="outlined"
          density="comfortable"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="diagnosisSearchText"
          label="Search by Diagnosis fields"
          variant="outlined"
          density="comfortable"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-combobox
          v-model="therapySearchText"
          variant="outlined"
          density="comfortable"
          :items="therapyItems"
          item-title="text"
          label="Search by Therapy"
          hide-details="auto"
        ></v-combobox>
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          v-model="drugSearchText"
          label="Search by Drug fields"
          variant="outlined"
          density="comfortable"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" sm="3">
        <v-btn dark large color="#030c4d" style="color:white" class="mg-left-20" v-on:click="runSearch">
          <v-icon dark>mdi-account-search</v-icon>
          Search
        </v-btn>
      </v-col>
      <v-col cols="6" sm="3">
        <v-switch
          class="mt-1"
          dense
          v-model="includeNonTargetedBox"
          label="Included Non-Targeted Members?"
        ></v-switch>
      </v-col>
      <v-col cols="6" sm="3">
        <v-switch
          class="mt-1"
          dense
          v-model="useANDBox"
          label="Use AND search across fields?"
        ></v-switch>
      </v-col> 
      <v-col cols="6" sm="3">
        <p class="font-smaller ltgray txt-align-center">(Comma-separated values within a field use OR search, underscore-separated values use AND.)</p>
      </v-col>
    </v-row>
    <!--
    <v-row>
      <v-col cols="12">
        <v-btn
          class="ms-4"
          small
          :disabled="disableQualifyButton"
          @click="markQualified"
        >
          Mark Qualified
        </v-btn>
        <v-btn
          class="ms-4"
          small
          :disabled="disableUnqualifyButton"
          @click="markNotQualified"
        >
          Mark Not Qualified
        </v-btn>
      </v-col>
    </v-row>
    -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="searchSelectedMembers"
          :items="searchResults"
          :headers="headers"
          item-value="memberId"
          class="elevation-2"
          show-select
          return-object
          multi-sort
          :sort-by="[{ key: 'lastName', order: 'asc' }, { key: 'firstName', order: 'asc' }]"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, -1]
          }"
          :items-per-page="20"
        >
          <template v-slot:item.memberId="{item}">
            <span @click="goToMemberView(item.memberId)" class="pointer"
              ><a>{{ item.memberId }}</a></span
            >
          </template>
          <template v-slot:item.firstName="{item}">
            <div v-if="!privacyMode">{{ item.firstName }}</div>
            <div v-if="privacyMode">******</div>
          </template>
          <template v-slot:item.middleName="{item}">
            <div v-if="!privacyMode">{{ item.middleName }}</div>
            <div v-if="privacyMode">******</div>
          </template>
          <template v-slot:item.lastName="{item}">
            <div v-if="!privacyMode">{{ item.lastName }}</div>
            <div v-if="privacyMode">******</div>
          </template>
          <template v-slot:item.dateOfBirth="{item}">
            <span v-if="!privacyMode" style="white-space: nowrap">{{
              item.dateOfBirth
            }}</span>
            <span v-if="privacyMode" style="white-space: nowrap">{{
              showAge(item.dateOfBirth)
            }}</span>
          </template>
          <template v-slot:item.invitationCode="{item}">
            <v-tooltip bottom v-if="item.invitationFileId">
              <template v-slot:activator="{ props }">
                <a
                  v-bind="props"
                  v-on:click="downloadFile(item.invitationFileId)"
                >
                  {{ item.invitationCode }}
                </a>
              </template>
              <span>Download File</span>
            </v-tooltip>
            <span v-if="!item.invitationFileId">
              {{ item.invitationCode }}
            </span>
          </template>
          <template v-slot:no-data>
            <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
              <span>No data available.</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
// import { VDataTable } from 'vuetify/labs/VDataTable'
export default {
  name: "MemberSearch",
  components: {
    // VDataTable
  },data() {
    return {
      memberSearchText: null,
      diagnosisSearchText: null,
      therapySearchText: null,
      drugSearchText: null,
      includeNonTargetedBox: false,
      useANDBox: true,
      searchSelectedMembers: [],
      headers: [
        {
          title: "Member ID",
          key: "memberId",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "First Name",
          key: "firstName",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "Middle Name",
          key: "middleName",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "Last Name",
          key: "lastName",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "DOB",
          key: "dateOfBirth",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "Gender",
          key: "gender",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "Cardholder ID",
          key: "memberSharedIdentityValue",
          sortable: true,
          filterable: false,
          divider: true
        },
        /*{
          text: "MF Generated ID",
          value: "memberIdentityValue",
          sortable: true,
          filterable: false,
          divider: true
        },*/
        {
          title: "Status",
          key: "statusName",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "# Of Criteria",
          key: "memberCriteriaCount",
          sortable: true,
          filterable: false,
          divider: true
        },
        {
          title: "Invitation Code",
          key: "invitationCode",
          sortable: true,
          filterable: false,
          divider: true
        }
      ]
    };
  },
  computed: {
    appUser() {
      return store.getters.appUser;
    },
    therapyItems() {
      var res = [];
      if (store.getters.therapyFields != null) {
        for (var i = 0; i < store.getters.therapyFields.length; i++) {
          if (
            i == 0 ||
            store.getters.therapyFields[i].therapClass !=
              store.getters.therapyFields[i - 1].therapClass
          ) {
            res.push({
              text: store.getters.therapyFields[i].therapClass + " (class)",
              value: "1" + store.getters.therapyFields[i].therapClass
            });
          }
          if (
            i == 0 ||
            store.getters.therapyFields[i].therapSubClass !=
              store.getters.therapyFields[i - 1].therapSubClass
          ) {
            res.push({
              text:
                store.getters.therapyFields[i].therapSubClass + " (subclass)",
              value: "2" + store.getters.therapyFields[i].therapSubClass
            });
          }
          res.push({
            text: store.getters.therapyFields[i].therapCategory + " (category)",
            value: "3" + store.getters.therapyFields[i].therapCategory
          });
        }
      }
      return res;
    },
    /*
    disableQualifyButton() {
      console.log("disableQualifyButton", this.searchSelectedMemberStatusIds);
      if (
        this.searchSelectedMemberStatusIds == null ||
        this.searchSelectedMemberStatusIds.length == 0
      )
        return true;
      console.log("testing");
      var flag = false;
      this.searchSelectedMemberStatusIds.forEach(function(id) {
        if (id != 1 || id != 101) flag = true;
      });
      return flag;
    },
    disableUnqualifyButton() {
      console.log("disableUnqualifyButton", this.searchSelectedMemberStatusIds);
      if (
        this.searchSelectedMemberStatusIds == null ||
        this.searchSelectedMemberStatusIds.length == 0
      )
        return true;
      console.log("testing");
      var flag = false;
      this.searchSelectedMemberStatusIds.forEach(function(id) {
        if (id != 2) flag = true;
      });
      return flag;
    },
    searchSelectedMemberIds() {
      console.log("searchSelectedMemberIds");
      console.log(this.searchSelectedMembers);
      var memberIds = [];
      if (
        this.searchSelectedMembers == null ||
        this.searchSelectedMembers.length == 0
      )
        return memberIds;

      this.searchSelectedMembers.forEach(function(member) {
        if (memberIds.indexOf(member.memberId) == -1)
          memberIds.push(member.memberId);
      });
      console.log("memberIds",memberIds);
      return memberIds;
    },
    searchSelectedMemberStatusIds() {
      console.log("searchSelectedMemberStatusIds");
      console.log("this.searchSelectedMembers",this.searchSelectedMembers);
      var statusIds = [];
      if (
        this.searchSelectedMembers == null ||
        this.searchSelectedMembers.length == 0
      )
        return statusIds;

      this.searchSelectedMembers.forEach(function(member) {
        if (member.memberCriteria != null && member.memberCriteria.length > 0) {
          member.memberCriteria.forEach(function(item) {
            if (statusIds.indexOf(item.statusId) == -1)
              statusIds.push(item.statusId);
          });
        }
      });
      console.log("statusIds",statusIds);
      return statusIds;
    },
    */
    privacyMode() {
      return store.getters.privacyMode;
    },
    searchResults() {
      return store.getters.memberSearchResults;
    }
  },
  methods: {
    runSearch() {
      // store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
      if (
        (this.memberSearchText != null && this.memberSearchText != "") ||
        (this.diagnosisSearchText != null && this.diagnosisSearchText != "") ||
        (this.therapySearchText != null && this.therapySearchText != "") ||
        (this.drugSearchText != null && this.drugSearchText != "")
      ) {
        store.commit("SET_SEARCH_FIELDS", {
          memberText: this.memberSearchText,
          diagnosisText: this.diagnosisSearchText,
          therapyText:
            this.therapySearchText != null ? this.therapySearchText.value : "",
          drugText: this.drugSearchText,
          includeNonTargeted: this.includeNonTargetedBox,
          useAND: this.useANDBox
        });
        store.dispatch("getMemberSearchResults");
      }
    },
    goToMemberView(mId) {
      store.commit("SET_SELECTED_MEMBER_ID", mId);
      store.dispatch("getMemberDetail");
      this.searchSelectedMembers = [];
      // store.dispatch("setMainTab", 1);
      this.$router.push('/displayMemberDetails')
    },
    downloadFile(fileId) {
      store.dispatch("downloadFile", {
        fileId: fileId,
        reloadMethod: null
      });
    },
    /*
    markQualified() {
      store.dispatch("targetMembers", {
        status: 2,
        memberIds: this.searchSelectedMemberIds,
        reloadMethod: "memberSearch"
      });
    },
    markNotQualified() {
      store.dispatch("targetMembers", {
        status: 100,
        memberIds: this.searchSelectedMemberIds,
        reloadMethod: "memberSearch"
      });
    },
    inputEvent() {
      store.commit(
        "SET_MEMBER_SEARCH_SELECTED_MEMBER_IDS",
        this.searchSelectedMemberIds
      );
    }, 
    */
    showAge(dob) {
      if (dob !== undefined) {
      var year = Number(dob.substr(0, 4));
      var month = Number(dob.substr(4, 2)) - 1;
      var day = Number(dob.substr(6, 2));
      var today = new Date();
      var age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() == month && today.getDate() < day)
      ) {
        age--;
      }
      if (age < 2) {
        age = today.getMonth() - month;
        if (today.getDate() < day) {
          age--;
        }
        return age + " months";
      }
      return age + " years";
    }
    }
  },
  mounted () {
  }
};
</script>
