<template>
  <v-container id="signUp" fluid>
      <Header />
      <div class="signup-container">
        <div class="sub-header">
          <h1>REGISTRATION SUCCESSFUL</h1>
          <span>Registration Complete</span>
        </div>
        <div class="signup-card">
            <h3>Thank you, <span style="color: #8ab933">{{registerMemberInfo.memberName}}</span></h3>
            <p>Your registration has been accepted. A Member First clinician will contact you with-in 48-hours to complete your registation.</p>  
           <v-divider style="16px 0"></v-divider>
            <p>Thank you very much for choosing us, <br>
            <strong>Member First Rx Medication Management</strong></p>
         
        </div>
      </div>
      
      <Footer />
  </v-container>
</template>
<script>
 import Header from "@/components/Header.vue" 
 import Footer from "@/components/Footer.vue" 
export default {
  name: "SignUp",
  components: {
      Header,
      Footer
  },
  data() {
    return {
      registerMemberInfo:{}
  };
},
computed: {
},
methods: {
  // handleGotoAbout(){
  //   this.$router.push({ name: 'About'})
  // }
},
mounted () {
  this.registerMemberInfo = JSON.parse(localStorage.signupUsername)
}
};
</script>
<style>
.signup-container{
  position: relative;
  top:100px;
  height: 100vh
}

.signup-container .sub-header{
  background: rgba(148,149,150,0.05);
  padding: 50px 170px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.signup-container .sub-header h1{
  margin: 0px;
  padding: 0px;
  font-size: 26px;
  font-weight: 300;
}


.signup-container .sub-header span{
  padding: 8px 0px;
}
.signup-card{
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  margin:5rem 10rem;
  padding: 1.25rem;
}
.signup-card h3{
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
    font-weight: 500;
    color: #414141;
    position: relative;
    margin: 0 0 30px 0;
    line-height: 1.5;
    z-index: 0;
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    letter-spacing: normal;
    margin: 0 0 32px 0;

}

.signup-card p{
margin:1.6px 0 30px;
}
.signup-card .theme--light.v-divider {
    border-color: rgba(0, 0, 0, 0.12);
    margin: 0 0 16px 0;
}

</style>

  