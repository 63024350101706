<template>
    <v-row>
      <v-col cols="12">
        <SelectCriteriaReport :reportNameTarget="reportName"/>
        <v-data-table
          v-model="reportSelectedMembers"
          @input="inputEvent"
          :items="reportMembers"
          :headers="headers"
          item-value="memberId"
          show-select
          class="elevation-2"
          multi-sort
          :sort-by="[{ key: 'count', order: 'desc' }]"
          sort-desc
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, -1],
          }"
          :items-per-page="20"
        >
          <template v-slot:item.memberId="{item}">
            <span @click="goToMemberView(item.memberId)" class="pointer"
              ><a>{{ item.memberId }}</a></span
            >
          </template>
          <template v-slot:item.dateOfBirth="{item}">
            <span v-if="!privacyMode" style="white-space: nowrap">{{
              item.dateOfBirth
            }}</span>
            <span v-if="privacyMode" style="white-space: nowrap">{{
              showAge(item.dateOfBirth)
            }}</span>
          </template>
          <template v-slot:item.mostRecent="{item}">
            <span style="white-space: nowrap">{{ item.mostRecent }}</span>
          </template>
          <template v-slot:item.names="{item}">
            <span v-html="item.names"></span>
          </template>
          <template v-slot:no-data>
            <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
              <span>No data available.</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import SelectCriteriaReport from "@/components/SelectCriteriaReport.vue";
  import store from "@/store";
  // import { VDataTable } from 'vuetify/labs/VDataTable'
  export default {
    name: "GPIInclusionReport",
    components: {
      // VDataTable,
      SelectCriteriaReport
    },
    computed: {
      selectedClient() {
      return store.getters.selectedClient;
    },
      reportMembers() {
        return store.getters.reportMembers;
      },
      reportSelectedMemberIds() {
        var memberIds = [];
        this.reportSelectedMembers.forEach(function (item) {
          if (memberIds.indexOf(item.memberId) == -1)
            memberIds.push(item.memberId);
        });
        return memberIds;
      },
      privacyMode() {
        return store.getters.privacyMode;
      },
    },
    methods: {
      goToMemberView(mId) {
        store.commit("SET_SELECTED_MEMBER_ID", mId);
        store.dispatch("getMemberDetail");
        // store.dispatch("setMainTab", 1);
        this.$router.push('/displayMemberDetails')
        this.reportSelectedMembers = [];
      },
      inputEvent() {
        store.commit(
          "SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS",
          this.reportSelectedMemberIds
        );
      },
      showAge(dob) {
        var year = Number(dob.substr(0, 4));
        var month = Number(dob.substr(4, 2)) - 1;
        var day = Number(dob.substr(6, 2));
        var today = new Date();
        var age = today.getFullYear() - year;
        if (
          today.getMonth() < month ||
          (today.getMonth() == month && today.getDate() < day)
        ) {
          age--;
        }
        if (age < 2) {
          age = today.getMonth() - month;
          if (today.getDate() < day) {
            age--;
          }
          return age + " months";
        }
        return age + " years";
      },
    },
    mounted () { 
            this.reportData = this.$route.params.selectedValue !== "" ? this.$route.params.selectedValue : undefined
            if ( this.selectedClient != null) {
        if (this.reportData !== undefined) {
          store.dispatch("showLoadingSpinner");
          store.dispatch("setSelectedReport",  JSON.parse(this.reportData));
          store.commit("SET_REPORT_MEMBERS", []);
          store.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", []);
          store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
          store.dispatch("getReportMembers");
        }
      } else {
        const intervalRef = setInterval(() => {
          if (this.selectedClient !== null) {
            clearInterval(this.initIntervalRef)
            if (this.reportData !== undefined) {
              store.dispatch("showLoadingSpinner");
              store.dispatch("setSelectedReport",  JSON.parse(this.reportData));
              store.commit("SET_REPORT_MEMBERS", []);
              store.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", []);
              store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
              store.dispatch("getReportMembers");
            }
          }
      }, 1000)
      this.initIntervalRef = intervalRef
    }
        },
    data() {
      return {
        reportData:undefined,
        reportName: 'GPI Inclusion',
        reportSelectedMembers: [],
        headers: [
          {
            title: "MemberId",
            key: "memberId",
            sortable: true,
            filterable: false,
            divider: true,
          },
          {
            title: "DOB",
            key: "dateOfBirth",
            sortable: true,
            filterable: false,
            divider: true,
          },
          {
            title: "Most Recent",
            key: "mostRecent",
            sortable: true,
            filterable: false,
            divider: true,
          },
          {
            title: "Count",
            key: "count",
            sortable: true,
            filterable: false,
            divider: true,
          },
          {
            title: "Drug Names",
            key: "names",
            sortable: false,
            filterable: false,
            divider: true,
          },
        ],
      };
    },
  };
  </script>
  