<template>
    <div class="paginations">
        <div class="page-size">
          <span>Rows per page:</span>
          <select v-model="localPagesize" @change="emitHandlePageSizeChange">
            <option v-for="option in pageSizes" :key="option" :value="option">{{option}}</option>
          </select>
        </div>
        <div class="page-navigation">
          <span class="page-info">{{startRow}}-{{ endRow }} of {{totalRecords}}</span>
          <button class="page-btn" :disabled="currentPage === 1" @click="onPrevPage"><span class="mdi mdi-chevron-left"></span></button>
          <button class="page-btn" :disabled="currentPage === totalPages" @click="onNextPage"><span class="mdi mdi-chevron-right"></span></button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'AgGridCustomPagination',
  components: {
  },
  props: {
    pageSize: { type: Number, default: 20 },
    pageSizes: { type: Array, default: function () { return [1, 2, 3, 4, 5] } },
    currentPage: { type: Number, default: 1 },
    totalPages: { type: Number, default: 1 },
    visibleCount: { type: Number, default: 5 },
    startRow: { type: Number, default: 0 },
    endRow: { type: Number, default: 0 },
    totalRecords: { type: Number, default: 0 },
    isRowCountLableInternal: { type: Boolean, default: true },
    isShowPageSizeSelector: { type: Boolean, default: true }
  },
  data: function () {
    const self = this
    return {
      localCurrentPage: self.currentPage,
      localPagesize: 10
    }
  },
  computed: {
  },
  mounted () {
    this.localPagesize = this.pageSize
  },
  methods: {
    onNextPage () {
      this.localCurrentPage++
      this.$emit('handlePageChange', this.localCurrentPage)
    },
    onPrevPage () {
      this.localCurrentPage--
      this.$emit('handlePageChange', this.localCurrentPage)
    },
    emitHandlePageSizeChange (value) {
      this.$emit('handlePageSizeChange', this.localPagesize)
    }
  }
}
</script>
<style>
.paginations select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: white;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  font-size: 12px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z' style='fill:%23888888;' /%3E%3C/svg%3E") no-repeat right 0.5rem center;
}

.paginations select:focus {
  outline: none;
  box-shadow: none;
}

.paginations option {
  border: none;
  background-color: white;
  color: #333;
}

.page-size {
  display: flex;
  align-items: center;
}

.page-navigation {
  display: flex;
  align-items: center;
}
/* --------------- */
.paginations {
  display: flex;
  /* justify-content: space-between;
  background-color: #fafafa; */
  align-items: center;
  font-size: 12px;
  margin-bottom: none !important;
  padding: 2px 18px 0px 18px;
}

.page-size {
  display: flex;
  align-items: center;
}

.page-navigation {
  display: flex;
  align-items: center;
}

.page-info {
  margin-right: 10px;
}

.page-btn {
  color: #666;
  cursor: pointer;
  font-size: 20px;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-btn:hover {
  background-color: #f0f0f0;
}

.page-btn:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.mdi {
  font-size: 1.2em;
}

</style>
