<template>
  <v-container fluid>
    <div>
      <v-col cols="11">
        <div class="header-info">MTM Other</div>
        <div class="text-sub-info mg-top-20">
          This optional field may be personalized for the beneficiary, such as
          including a list of the beneficiary's medical conditions, primary care
          provider, primary pharmacy, or emergency information
        </div>
      </v-col>
      <v-row v-if="selectisReview">
        <v-col cols="12" class="mg-left-10 mg-right-15">
          <div class="review-cart-bg-color">
            <div class="header-quality-control">Quality Control Review</div>
            <v-row class="mg-top-5">
              <v-col cols="3"></v-col>
              <v-col cols="3">
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col
                    cols="9"
                    class="text-align-inherit fs-14"
                    >{{ "Submitted for Review date:" }}</v-col
                  >
                </v-row>
              </v-col>
              <v-col
                cols="6"
                class="text-align-inherit fs-14"
                v-if="smtForReviewDate !== null"
                >{{ smtForReviewDate }}</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="3">
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col
                    cols="9"
                    class="qty-review-title fs-14"
                    >{{ "Clinician:" }}</v-col
                  >
                </v-row>
              </v-col>
              <v-col
                cols="6"
                class="qty-review-title fs-14"
                >{{ "DR. "+selectClinicianName }}</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="3">
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col
                    cols="9"
                    class="qty-review-title fs-14"
                    >{{ "Member:" }}</v-col
                  >
                </v-row>
              </v-col>
              <v-col
                cols="6"
                class="qty-review-title fs-14"
                >{{ memberName }}</v-col
              >
            </v-row>
          </div>
        </v-col>
      </v-row>
      <div class="mg-top-20">
        <div v-if="otherDataList.length < 1">
        <div v-if="(selectisReview === null || selectisReview === false) && !isOther">
          <v-col cols="11">
            <v-row>
              <v-col cols="12" class="pd-0">
                <v-textarea
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  rows="4"
                  name="input-7-4"
                  label="Other Information"
                  v-model="otherDetailsForm.otherInfo"
                  @input="handleOtherNotes($event, 'otherForm', '-1')"
                >
                </v-textarea>
              </v-col>
              <v-row>
                <v-col cols="9" class="pd-0"></v-col>
                <v-col cols="3" class="pd-0">
                  <v-row class="mg-0">
                    <v-col cols="4" class="pd-0">
                      <v-btn
                        color="black"
                        variant="text"
                        @click="handleCancelOther('-1','otherFrom')"
                      >
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="4" class="pd-0">
                      <v-btn
                        color="black"
                        variant="text"
                        @click="handleClearOther('-1', 'otherForm')"
                      >
                        Clear
                      </v-btn>
                    </v-col>
                    <v-col cols="4" class="pd-0">
                      <v-btn
                        color="blue"
                        :disabled="otherDetailsForm.otherInfo === ''"
                        @click="handleAddOtherDetails"
                        variant="text"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </div>
      </div>
        <div>
          <v-col cols="11" v-if="otherDataList.length > 0" :class="{ 'mg-left-30': selectisReview, 'mg-right-30': selectisReview}">
            <div v-for="(item, index) in otherDataList" :key="index">
              <v-card class="mg-top-30 card-border-blue" :class="{ 'card-border-blue': item.isActive, 'card-border-gray': !item.isActive}" height="160">
                <v-row class="mg-top-10">
                  <v-col cols="11">
                    <div class="mg-left-20"></div>
                    <div class="mg-left-20" v-if="!item.editOther">
                      <v-row>
                        <div class="title-bold">Other Information</div>
                      </v-row>
                      <v-row>
                        <div class="mg-top-10">{{item.otherInfo}}</div>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-textarea
                        variant="outlined"
                        density="comfortable"
                        class="mx-2"
                        rows="3"
                        name="input-7-4"
                        label="Other Info"
                        v-model="item.otherInfo"
                        @input="handleOtherNotes($event, 'otherList', index)"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="1"
                    v-if="(selectisReview === false || selectisReview === null) && (isItemEdit === false)"
                  >
                    <div class="mg-right-2rem">
                      <v-menu bottom left>
                        <template v-slot:activator="{ props }">
                          <v-icon
                            dark
                            icon
                            v-bind="props"
                            class="mg-right-20"
                            size="20"
                            color="black"
                          >
                            mdi-dots-vertical</v-icon
                          >
                        </template>
                        <v-list>
                          <v-list-item class="dot-item-list" v-if="item.activeStatus === 'active'"  >
                            <v-list-item-title
                              @click="handleEditOther(index)"
                              >{{ "Edit" }}</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                        class="dot-item-list"
                        v-if="item.activeStatus === 'active'"
                      >
                        <v-list-item-title
                          @click="handleActAndDeactive(index, 'deactive')"
                          >{{ "Deactivate" }}</v-list-item-title
                        >
                        </v-list-item>
                          <v-list-item
                            class="dot-item-list"
                            v-if="item.activeStatus === 'deactive'"
                          >
                          <v-list-item-title
                            @click="handleActAndDeactive(index, 'active')"
                            >{{ "Active" }}</v-list-item-title
                          >
                        </v-list-item>
                          <v-list-item class="dot-item-list">
                            <v-list-item-title
                              @click="handleDeleteOther(item.id)"
                              >{{ "Delete" }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <v-row v-if="item.editOther">
                <v-spacer></v-spacer>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="4">
                      <v-btn
                        color="black"
                        variant="text"
                        @click="handleCancelOther(index, 'otherList')"
                      >
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        color="black"
                        variant="text"
                        @click="handleClearOther(index, 'otherList')"
                      >
                        Clear
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        :disabled="(item.otherInfo == '')"
                        color="blue"
                        variant="text"
                        @click="handleUpdateOtherDetails(index)"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div v-if="selectisReview !== null || selectisReview">
                <v-row
                  class="mg-top-30 mg-left-10 pd-unset"
                  v-if="!item.isShowReviewComment"
                >
                  <v-col cols="9"></v-col>
                  <v-col
                    cols="3"
                    class="mouse-cursor"
                    :class="{ 'disabled-element': isCommentAdd}"
                    @click="handleIsReviewerComment(index)"
                  >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isCommentAdd, 'blue-color': isCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isCommentAdd}">ADD REVIEWER COMMENT</div>
                  </v-row>
                  </v-col>
                </v-row>
              </div>
              <v-row class="mg-top-20" v-if="item.isShowReviewComment">
                <v-col cols="12" class="pd-0">
                  <v-textarea
                    variant="outlined"
                    density="comfortable"
                    class="mx-2"
                    v-model="item.remark"
                    :disabled="(selectisReview !== null || selectisReview) && !item.isReviewComment"
                    @input="changeCommentReview($event, index)"
                    rows="4"
                    label="QC Reviewer Comment"
                    :readonly="!selectisReview"
                  />
                </v-col>
              </v-row>
              <v-row
                class="mg-top-10 mg-right-10 pd-unset"
                v-if="(selectisReview !== null || selectisReview) && item.isShowReviewComment"
              >
                <v-col cols="8"></v-col>
                <v-col cols="4" class="mouse-cursor">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn variant="text" @click="handleCancelReviewComment(index)" 
                    v-if="item.isShowReviewBtn === false"
                    >CANCEL</v-btn>
                    <v-btn
                      variant="text"
                      @click="handleClearReviewComment(index)"
                      v-if="item.isShowReviewBtn === false"
                      >CLEAR</v-btn
                    >
                    <v-btn
                      variant="text"
                      color="error"
                      @click="handleDeleteReviewComment(index)"
                      v-if="item.isReviewComment && item.isShowReviewBtn"
                      >DELETE</v-btn
                    >
                    <v-btn
                      v-if="item.isReviewComment"
                      variant="text"
                      color="info"
                      @click="handleAddReviewComment(index)"
                      >{{item.isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
              <div v-if="selectisReview === null || selectisReview === false">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset"
                v-if="item.isShowReviewComment && item.isShowReplyReviewComment === false"
              >
                <v-col cols="8"></v-col>
                <v-col
                  cols="4"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isReplyCommentAdd}"
                  @click="handleIsReplyReviewerComment(index)"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isReplyCommentAdd, 'blue-color': isReplyCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isReplyCommentAdd}">ADD REPLY FOR THE ABOVE COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30" v-if="(selectisReview && item.isShowReviewComment && item.isShowReplyReviewComment) || (item.isShowReplyReviewComment)">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="item.remarkComment"
                  @input="changeReplyCommentReview($event, index)"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview === true) && !item.isReplyReviewComment"
                  :readonly="selectisReview"
                  label="Reply For QC Reviewer Comment"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview === null || selectisReview === false) && item.isShowReplyReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReplyReviewComment(index)" 
                    v-if="item.isShowReviewReplyBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReplyReviewComment(index)"
                    v-if="item.isShowReviewReplyBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReplyReviewComment(index)"
                    v-if="item.isReplyReviewComment && item.isShowReviewReplyBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                  v-if="item.isReplyReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReplyComment(index)"
                    >{{item.isShowReviewReplyBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            </div>
          </v-col>
        </div>
      </div>
      <div v-if="selectisReview !== null || selectisReview">
        <div class="mg-top-6rm txt-align-center fs-25 font-weight-400">
          Document to Review
        </div>
        <div class="mg-top-10">
          <iframe
            id="pdf"
            :src="url"
            frameborder="1"
            scrolling="auto"
            width="100%"
            height="600px"
            class="pdf-viewer"
          ></iframe>
        </div>
      </div>
    </div>
    <div>
      <AlertDialogs
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        :alert-message-type="alertMessageType"
        @closeDialog="closeAlertDialog"
        @onConfirmOK="handleAlertConfirmation"
      />
      <v-snackbar
        v-model="snackbar"
        v-bind:color="snackColor"
        :timeout="snackTimeout"
      >
        <span
          :class="{ 'black--text': snackColor== '#CDDC39', 'white--text': snackColor== '#DD2C00' }"
          class=""
        >
          {{snackStatus}}
        </span>
      </v-snackbar>
    </div>
  </v-container>
</template>
<script>
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import store from '../store'
import axios from 'axios'
import interceptorSetup from '../helpers/httpInterceptor'
export default {
  name: "MTMOther",
  components: {
    AlertDialogs
  },
  data() {
    return {
      otherDetailsForm : {
        companyId: 1,
        clientId: "",
        memberId: "",
        documentId: "",
        otherInfo: ""
      },
      alertType: '',
      alertMessage: '',
      confirmType: '',
      isHideShowAlertDialog: false,
      alertMessageType: "",
      otherDataList: [],
      clientData: null,
      memberId: null,
      documentId: null,
      cancelIndex: '',
      cancelOtherType:'',
      clearOtherIndex: '',
      clearOtherType: '',
      isItemEdit: false,
      snackbar: false,
      snackStatus: null,
      snackColor: "black",
      interval: null,
      snackTimeout: "20000",
      autoSaveTimeout: "30000",
      errorTimout: null,
      autoSaveValidation: false,
      cancelIndex: "",
      allergyType: "",
      clearIndex: "",
      prevText: "",
      isShowReviewComment: false,
      url: null,
      commentReview: "",
      memberDetails: undefined,
      smtForReviewDate: null,
      memberName: '',
      isCommentAdd: false,
      isOther: false,
      isReplyCommentAdd: false
    }
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.errorTimout);
  },
  computed: {
    selectedMemberId () {
      return store.getters.selectedMemberId
    },
    selectedClient() {
      return store.getters.selectedClient;
    },
    selectdocumentId() {
      return store.getters.selectdocumentId;
    },
    selectisReview() {
      return store.getters.selectisReview
    },
    memberDetail () {
      return store.getters.memberDetail;
    },
    selectClinicianName() {
      return store.getters.selectClinicianName
    },
    selectReviewerId() {
      return store.getters.selectReviewerId;
    }
  },
  methods: {
    handleActAndDeactive (index, actionType) {
      let tempOtherDetailsForm = this.otherDataList[index];
      tempOtherDetailsForm.activeStatus = actionType;
      if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        this.updateMtmOtherDetails (tempOtherDetailsForm)
      }
    },
    handleAddReviewComment(index) {
      const otherObj = this.otherDataList[index]
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmOtherDetails(otherObj)
      }
    },
    handleDeleteReviewComment(index) {
      const otherObj = this.otherDataList[index]
      otherObj.remark = null
      otherObj.remarkComment = null
      otherObj.isShowReviewComment = false
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null ) {
        this.createMtmOtherDetails(otherObj)
      }
    },
    changeCommentReview(value, index) {
      this.otherDataList.forEach(items => {
        items.isReviewComment = false
      });
      this.isCommentAdd = true
      this.otherDataList[index].isReviewComment = true
      this.otherDataList[index].remark = value.target.value
    },
    changeReplyCommentReview(value, index) {
      this.otherDataList.forEach(items => {
        items.isReplyReviewComment = false
      });
      this.isReplyCommentAdd = true
      this.otherDataList[index].isReplyReviewComment = true
      this.otherDataList[index].remarkComment = value.target.value
    },
    handleClearReplyReviewComment(index) {
      this.otherDataList[index].remarkComment = ""
    },
    handleCancelReplyReviewComment(index) {
      this.otherDataList.forEach(items => {
        items.isReplyReviewComment = true
        items.remarkComment = ""
      });
      this.otherDataList[index].isShowReplyReviewComment = false
      this.isReplyCommentAdd = false
    },
    handleIsReplyReviewerComment(index){
      this.otherDataList[index].isShowReplyReviewComment = true
      this.isReplyCommentAdd = true
    },
    handleAddReplyComment(index) {
      const otherObj = this.otherDataList[index]
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmOtherDetails (otherObj)
      }
    },
    handleDeleteReplyReviewComment(index) {
      const otherObj = this.otherDataList[index]
      otherObj.remarkComment = null
      otherObj.isShowReplyReviewComment = false
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmOtherDetails (otherObj)
      }
    },
    handleCancelReviewComment(index) {
      this.otherDataList[index].isReviewComment = true 
      this.otherDataList[index].remark = ""
      this.otherDataList[index].isShowReviewComment = false
      this.isCommentAdd = false
    },
    handleClearReviewComment(index) {
      this.otherDataList[index].remark = ""
    },
    handleIsReviewerComment(index) {
      this.otherDataList[index].isShowReviewComment = true
      this.isCommentAdd = true
    },
    handleCancelOther (index, otherType) {
      this.cancelIndex = index
      this.cancelOtherType = otherType
      this.alertMessage = 'Are you sure you want to cancel?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'cancelOther'
      this.isHideShowAlertDialog = true
    },
    cancelOther (index, otherType) {
      this.isItemEdit = false;
      if(otherType === 'otherList') {
        this.otherDataList[index].editOther = false
        if(this.prevText != "") {
          this.otherDataList[index].otherInfo = this.prevText;
        }
      } else if(otherType === 'otherFrom') {
        this.isOther = true
        this.isItemEdit = false;
        if(this.otherDetailsForm.hasOwnProperty('id')) {
          this.getMtmOtherdelete(this.otherDetailsForm.id, this.memberId, this.documentId);
        }
        this.otherDataReset();
      }
    },
    handleEditOther (index) {
      this.otherDataList[index].editOther = true
      this.isItemEdit = true
    },
    closeAlertDialog () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      this.confirmType = ''
      if(this.pageReload) {
        if (this.clientData.clientId !== null && this.memberId !== null && this.documentId != null) {
          store.dispatch("showLoadingSpinner");
          this.getAllMtmOther(this.clientData.clientId, this.memberId, this.documentId);
        }
      }
    },
    handleAlertConfirmation (index) {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      if(this.confirmType === 'cancelOther') {
        this.cancelOther (this.cancelIndex, this.cancelOtherType)
      } else if(this.confirmType === 'clearOther') {
        this.clearOther (this.clearOtherIndex, this.clearOtherType)
      }
    },
    handleDeleteOther (id) {
      if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        store.dispatch("showLoadingSpinner");
        this.getMtmOtherdelete(id)
      }
    },
    getMtmOtherdelete(otherId) {
      axios
      .delete('/mtm/otherdetails/getdeleteById?clientId='+ this.clientData.clientId +'&id='+otherId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            const responseIndex = this.otherDataList?.findIndex(item => item.id === otherId);
            // console.log("response index:", responseIndex);
            if (responseIndex !== undefined) {
              this.otherDataList.splice(responseIndex, 1);
              this.alertType = 'Success'
              this.alertMessage = response.data.message
              this.isHideShowAlertDialog = true
              this.pageReload = true
            }
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    handleOtherNotes (value, otherType, index) {
      const newValue = value.target.value
      if(otherType === 'otherList' && index !== "-1") {
        this.otherDataList[index].otherInfo = newValue
      } else if(otherType == "otherForm") {
        this.otherDetailsForm.otherInfo = newValue;
        this.isItemEdit = true;
      }
    },
    // isUpdating(value) {
    //   if(value.target.value.length < 1) {
    //     this.isItemEdit = false;
    //   }
    // },
    handleClearOther (index, otherType) {
      this.clearOtherIndex = index
      this.clearOtherType = otherType
      this.alertMessage = 'Are you sure you want to clear the content?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'clearOther'
      this.isHideShowAlertDialog = true
    },
    clearOther (index, otherType) {
      if(otherType === 'otherList') {
        this.prevText = this.otherDataList[index].otherInfo;
        this.otherDataList[index].otherInfo = '';
      } else if(otherType === 'otherForm') {
        this.isItemEdit = false;
        if(this.otherDetailsForm.hasOwnProperty('id')) {
          this.getMtmOtherdelete(this.otherDetailsForm.id, this.memberId, this.documentId);
        }
        this.otherDataReset();
      }
    },
    handleAddOtherDetails () {
    if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
      store.dispatch("showLoadingSpinner");
      if(this.otherDetailsForm.hasOwnProperty('id')) {
        this.updateMtmOtherDetails (this.otherDetailsForm)
      } else {
        this.createMtmOtherDetails(this.otherDetailsForm)
      }

    }
  },
  otherDataReset () {
    this.otherDetailsForm.otherInfo ="";
    delete this.otherDetailsForm.id;
    this.isItemEdit = false;
  },
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  },
  autoSaveByTwoMin() {
    this.interval = setInterval(() => {
      // console.log("interval is running");
      clearInterval(this.errorTimout);
      if(this.otherDetailsForm.otherInfo !== "") {
        this.saveByInterval (this.otherDetailsForm, this.otherDetailsForm.clientId, this.otherDetailsForm.memberId, this.otherDetailsForm.documentId);
      }
    },this.autoSaveTimeout);
  },
  saveByInterval (tempOtherDetailsForm, clientId, memberId, documentId) {
    if(this.otherDetailsForm.hasOwnProperty('id')) {
      axios
      .post('/mtm/otherdetails/updateotherdetails?clientId=' +this.clientData.clientId, tempOtherDetailsForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.autoSaveStatus(true);
          } else if (response.data.code === 401) {
            this.autoSaveStatus(false);
        }
        } else {
          this.autoSaveStatus(false);
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false);
      })
    } else {
      axios
      .post('/mtm/otherdetails/addmtmotherdetails?clientId='+this.clientData.clientId, tempOtherDetailsForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201 ) {
            this.getAutoSaveMtmOther(clientId, memberId, documentId)
          } else if (response.data.code === 401) {
            this.autoSaveStatus(false);
         }
        } else {
          this.autoSaveStatus(false);
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false);
      })
    }
  },
  autoSaveStatus(success) {
    if(!success) {
      this.snackStatus = "Autosave Unsuccessful. Trying again in 15s";
        this.snackColor = "#DD2C00";
        this.snackbar = true
        this.autoSaveTimeout = 15000
        clearInterval(this.interval);
        this.autoSaveByTwoMin();
        var seconds = 15;
        this.snackTimeout = 15000;
        this.errorTimout = setInterval(() => {
          this.snackColor = "#DD2C00";
          seconds = seconds - 1;
          if(seconds == 0) {
            this.snackTimeout = 20000;
            this.snackbar = false;
          }
          this.snackStatus = "Autosave Unsuccessful. Trying again in "+ seconds +"s";
        }, 1000);
    } else {
      this.autoSaveTimeout = 60000
      this.snackStatus = "Autosave Completed at "+ this.formatAMPM(new Date());
      this.snackColor = "#CDDC39";
      this.snackbar = true
    }
  },
  getAutoSaveMtmOther (clientId, memberId, documentId) {
    axios
      .get(
        "/mtm/otherdetails/getmtmotherdetails?clientId=" +
          clientId +
          "&memberId=" +
          memberId +"&documentId=" + documentId
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataLists !== undefined) {
              response.data.dataLists.map((other, index) => {
                var exist = this.allegyList?.some(element => element.id == other.id);
                if(!exist) {
                  this.otherDetailsForm['id'] = other.id;
                  this.autoSaveStatus(true);
                }
              });
            } else {
              this.autoSaveStatus(false);
            }
          } else if (response.data.code === 401) {
            this.autoSaveStatus(false);
          }
        } else {
          this.autoSaveStatus(false);
        }
      })
    .catch((error) => {
      this.autoSaveStatus(false);
    });
  },
  createMtmOtherDetails (tempOtherDetailsForm) {
    axios
      .post('/mtm/otherdetails/addmtmotherdetails?clientId='+this.clientData.clientId, tempOtherDetailsForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.pageReload = true
            this.isCommentAdd = false
            this.isReplyCommentAdd = false
            this.otherDataReset()
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
        }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
      })
    },
    handleUpdateOtherDetails(index) {
      const tempOtherDetailsForm = this.otherDataList[index];
      if(this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
        this.updateMtmOtherDetails (tempOtherDetailsForm)
      }
    },
    updateMtmOtherDetails (tempOtherDetailsForm) {
    axios
      .post('/mtm/otherdetails/updateotherdetails?clientId=' +this.clientData.clientId, tempOtherDetailsForm)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Success'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
            this.otherDetailsForm.otherInfo = ""
            this.pageReload = true
            this.isItemEdit = false
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
        }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
          console.log(error)
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
      })
    },
    mapResponseWithFormData (otherData) {
      if(otherData !== undefined) {
        const tempOtherData = [...otherData]
        tempOtherData.forEach(item => {
          item.isReviewComment = true;
          item.isReplyReviewComment = true;
          item.editOther  = false
          if(item.remark === null || item.remark === ''){
            item.isShowReviewComment = false
            item.isShowReviewBtn = false
          } else {
            item.isShowReviewComment = true
            item.isShowReviewBtn = true
          }
          if(item.remarkComment === null || item.remarkComment === ''){
            item.isShowReplyReviewComment = false
            item.isShowReviewReplyBtn = false
          } else {
            item.isShowReplyReviewComment = true
            item.isShowReviewReplyBtn = true
          }
          if(item.activeStatus === "active") {
            item.isActive = true
          } else {
            item.isActive = false
          }
        });
        this.otherDataList = tempOtherData
      } else {
        this.otherDataLis = undefined
      }
    },
    getAllMtmOther (clientId, memberId, documentId) {
    axios
      .get(
        "/mtm/otherdetails/getmtmotherdetails?clientId=" +
          clientId +
          "&memberId=" +
          memberId +"&documentId=" + documentId
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            const [year, month, day] = response.data.document.displaySentDate.split("-");
            this.smtForReviewDate = `${month}/${day}/${year}`;
            this.memberName = this.memberDetails.member.firstName +' '+this.memberDetails.member.lastName
            if(this.selectisReview == null || this.selectisReview === false) {
              if(response.data.document?.otherStatus === 1) {
                store.dispatch("setSelectDocumenStatus", 'Complete');
              } else {
                store.dispatch("setSelectDocumenStatus", 'Draft');
              }
            }
            if(response.data.dataLists.length > 0) {
              this.mapResponseWithFormData(response.data.dataLists);
            }
          } else if (response.data.code === 401) {
            store.dispatch("hideLoadingSpinner");
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    getReviewDocument(clientId, memberId, documentId) {
    store.dispatch("showLoadingSpinner");
      axios
      .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
        {responseType: "blob"},
      )
      .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            this.url = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    getDocReviewSectionStatus (clientId, reviewerId) {
      axios
      .get('/mtm/review?clientId='+clientId +'&reviewerId='+reviewerId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataList?.otherStatus === 1) {
              store.dispatch("setSelectDocumenStatus", 'Reviewer');
            } else {
              store.dispatch("setSelectDocumenStatus", 'NotReviewer');
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    }
  },
  mounted () {
    this.clientData = this.selectedClient;
    this.memberId = this.selectedMemberId;
    this.documentId = this.selectdocumentId;
    this.memberDetails = this.memberDetail;
    interceptorSetup.refreshToken();
    store.dispatch("setSelectDocumenStatus", 'Draft');
    this.otherDetailsForm.companyId = 1;
    this.otherDetailsForm.clientId = this.clientData.clientId;
    this.otherDetailsForm.memberId = this.memberId;
    this.otherDetailsForm.documentId = this.documentId;
    this.otherDetailsForm.otherInfo = "";
    this.otherDetailsForm.activeStatus = "active";
    if(this.selectisReview == null|| this.selectisReview === false) {
      this.autoSaveByTwoMin();
    }
    store.dispatch("showLoadingSpinner");
    if(this.clientData.clientId !== null && this.memberId!== null && this.documentId !== null) {
      this.getAllMtmOther(this.clientData.clientId, this.memberId, this.documentId);
      if(this.selectisReview){
        this.getDocReviewSectionStatus(this.clientData.clientId, this.selectReviewerId)
        setTimeout(() => {
          this.getReviewDocument(this.clientData.clientId , this.memberId, this.documentId)
        }, 1000);
      }
    }
  },
  unmounted(){ 
   clearInterval(this.interval)
   clearInterval(this.errorTimout);
  }
}
</script>
