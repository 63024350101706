<template>
  <v-row>
    <v-col cols="12">
      <SelectCriteriaReport :reportNameTarget="reportName"/>
      <v-data-table
        v-model="reportSelectedMembers"
        @input="inputEvent"
        :items="allCriteriaReportMembers"
        :headers="headers"
        :expanded.sync="expanded"
        item-value="memberId"
        multi-sort
        show-expand
        show-select
        :sort-by="[{ key: 'totalCount', order: 'desc' }]"
        class="elevation-2 custom-table"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, -1],
        }"
        :items-per-page="20"
      >
        <template v-slot:item.memberId="{item}">
          <span @click="goToMemberView(item.memberId)" class="pointer"
            ><a>{{ item.memberId }}</a></span
          >
        </template>
        <template v-slot:item.dateOfBirth="{item}">
          <span v-if="!privacyMode">{{ item.dateOfBirth }}</span>
          <span v-if="privacyMode">{{ showAge(item.dateOfBirth) }}</span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              :items="item.items"
              item-value="id"
              :headers="detailHeaders"
              hide-default-footer
              :items-per-page="-1"
              class="ml-12"
              dark
            >
            </v-data-table>
          </td>
        </template>
        <template v-slot:no-data>
          <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <span>No data available.</span>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import SelectCriteriaReport from "@/components/SelectCriteriaReport.vue";
import store from "@/store"
// import { VDataTable } from 'vuetify/labs/VDataTable'
export default {
  name: "DisplayAllCriteriaReport",
  components: {
    // VDataTable,
    SelectCriteriaReport
  },
  computed: {
    selectedClient() {
      return store.getters.selectedClient;
    },
    allCriteriaReportMembers() {    
      return store.getters.allCriteriaReportMembers;
    },
    reportSelectedMemberIds() {
      var memberIds = [];
      this.reportSelectedMembers.forEach(function (item) {
        if (memberIds.indexOf(item.memberId) == -1)
          memberIds.push(item.memberId);
      });
      return memberIds;
    },
    privacyMode() {
      return store.getters.privacyMode;
    },
  },
  methods: {
    goToMemberView(mId) {
      store.commit("SET_SELECTED_MEMBER_ID", mId);
      store.dispatch("getMemberDetail");
      // store.dispatch("setMainTab", 1);
      this.$router.push('/displayMemberDetails')
      this.reportSelectedMembers = [];
    },
    inputEvent() {
      store.commit(
        "SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS",
        this.reportSelectedMemberIds
      );
    },
    showAge(dob) {
      if (dob !== undefined) {
      var year = Number(dob.substr(0, 4));
      var month = Number(dob.substr(4, 2)) - 1;
      var day = Number(dob.substr(6, 2));
      var today = new Date();
      var age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() == month && today.getDate() < day)
      ) {
        age--;
      }
      if (age < 2) {
        age = today.getMonth() - month;
        if (today.getDate() < day) {
          age--;
        }
        return age + " months";
      }
      return age + " years";
    }
    },
  },
  mounted() {
    this.reportData = this.$route.params.selectedValue !== "" ? this.$route.params.selectedValue : undefined
    if ( this.selectedClient != null) {
        if (this.reportData !== undefined) {
          store.dispatch("showLoadingSpinner");
          store.dispatch("setSelectedReport",  JSON.parse(this.reportData));
          store.commit("SET_REPORT_MEMBERS", []);
          store.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", []);
          store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
          store.dispatch("getReportMembers");
        }
      } else {
        const intervalRef = setInterval(() => {
          if (this.selectedClient !== null) {
            clearInterval(this.initIntervalRef)
            if (this.reportData !== undefined) {
              store.dispatch("showLoadingSpinner");
              store.dispatch("setSelectedReport",  JSON.parse(this.reportData));
              store.commit("SET_REPORT_MEMBERS", []);
              store.commit("SET_ALL_CRITERIA_REPORT_MEMBERS", []);
              store.commit("SET_CRITERIA_REPORT_SELECTED_MEMBER_IDS", []);
              store.dispatch("getReportMembers");
            }
          }
      }, 1000)
      this.initIntervalRef = intervalRef
    }
  },
  destroyed() {
   this.reportSelectedMembers = []
   this.headers = []
   this.allCriteriaReportMembers = [] 
  },
  data() {
    return {
      initIntervalRef: undefined,
      reportData:undefined,
      reportName:'All Criteria',
      expanded: [],
      reportSelectedMembers: [],
      headers: [
        {
          title: "MemberId",
          key: "memberId",
          sortable: true,
          filterable: false,
          divider: true,
        },
        {
          title: "Total Count",
          key: "totalCount",
          sortable: true,
          filterable: false,
          divider: true,
        },
        {
          title: "DOB",
          key: "dateOfBirth",
          sortable: true,
          filterable: false,
          divider: true,
        },
        {
          title: "Criteria",
          key: "criteriaString",
          sortable: false,
          filterable: false,
          divider: true,
        },
      ],
      detailHeaders: [
        {
          title: "Criteria",
          key: "criteriaName",
          sortable: true,
          filterable: false,
          divider: true,
        },
        {
          title: "Most Recent",
          key: "mostRecent",
          sortable: true,
          filterable: false,
          divider: true,
        },
        {
          title: "Count / Top %",
          key: "count",
          sortable: false,
          filterable: false,
          divider: true,
        },
        {
          title: "List / Total Spend",
          key: "list",
          sortable: false,
          filterable: false,
          divider: true,
        },
        {
          title: "Names",
          key: "names",
          sortable: false,
          filterable: false,
          divider: true,
        },
      ],
    };
  },
};
</script>
<style>
    .custom-table .v-data-table .v-table__wrapper > table > thead > tr > td.v-data-table-column--no-padding, .v-data-table .v-table__wrapper > table > thead > tr th.v-data-table-column--no-padding, .v-data-table .v-table__wrapper > table tbody > tr > td.v-data-table-column--no-padding, .v-data-table .v-table__wrapper > table tbody > tr th.v-data-table-column--no-padding {
    border: 1px solid #e0e0e0 !important;
  }

  .custom-table .v-table > .v-table__wrapper > table > tbody > tr > th, .v-table > .v-table__wrapper > table > thead > tr > th, .v-table > .v-table__wrapper > table > tfoot > tr > th  {
    border: 1px solid #e0e0e0 !important;
  }
  .custom-table .v-data-table .v-table__wrapper > table > thead > tr > td, .v-data-table .v-table__wrapper > table > thead > tr th, .v-data-table .v-table__wrapper > table tbody > tr > td, .v-data-table .v-table__wrapper > table tbody > tr th
{
  border: 1px solid #e0e0e0 !important;
}
</style>
