<template>
  <v-container id="login" fluid>
    <div id="preloader" v-if="showLoader">
    <div class="inner">
      <div  class="loader">
      </div>
    </div>
  </div>
    <div v-else>
    <Header />
    <div id="background_img" class="background_img_login">
      <v-row class="login_paragraph">
        <v-col cols="8" class="mg-btm">
          <h2 class="fs-20">Member First Prescription Management Program</h2>
          <p>This software application is the property of Member First RXM, Inc. (MFRXM)
                It is for authorized use only.
          </p>
          <p>Users have no personal privacy rights in any material they place, view, 
            access, or transmit in this application. MFRXM complies with state and federal 
            law regarding certain legally protected confidential information, but makes 
            no representation that nay uses of this application will be private or confidential.
          </p>
          <p>Any or all users of this application and all files in this application may 
            be intercepted, monitored, recorded, copied, audited, inspected, and disclosed 
            to authorized MFRXM and law enforcement personnel, as well as, authorized users 
            individuals of other organizations. By using this application, the user consents 
            to such interception, monitoring, recording, copying, auditing, inspection, 
            and disclosure at the discretion of authorized MFRXM personnel.
          </p>
          <p>Unauthorized or improper use of this application may result in administrative, 
            disciplinary action, civil charges/criminal penalties, and/or other sanctions. 
            By continuing to use this system you indicate your awareness of and consent to 
            these terms and conditions of use
          </p>
          <p><b>DO NOT LOG IN</b> if you do not agree to the conditions stated in this warning.</p>
                
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title>
              <v-icon class="mg-right-20" size="20" color="black">
                mdi-account-group
              </v-icon>
              Clinician Login
            </v-card-title>
            <v-divider></v-divider>
            <v-col >
              <v-card-text v-if="errorMsg">
                <div 
                  style="color:red
                  margin:10px 0px;">
                  Login Failed!!!<br>
                  Reason :
                  <span>Bad credentials</span>			         
                </div>
              </v-card-text>
              <div>
                <v-text-field 
                  variant="outlined"
                  density="comfortable" 
                  dense 
                  v-model="usernameInput" 
                  @input="handleUsernameInput"
                  label="Username" 
                  :error-messages="usernameInputError.errorMessage"
                  :error="usernameInputError.isError"
                  class="registrationTextField"
                  @keydown.enter="login()"
                >
                </v-text-field>
              </div> 
              <div>    
                <v-text-field 
                  variant="outlined"
                  density="comfortable" 
                  dense 
                  v-model="passwordInput" 
                  @input="handlePasswordInput" 
                  label="Password"
                  type="password"
                  :error-messages="passwordInputError.errorMessage"
                  :error="passwordInputError.isError"
                  class="registrationTextField"
                  @keydown.enter="login()"
                >
                </v-text-field>
              </div>
              <v-btn @click="login()" class="login-btn">
                <v-icon
                  size="20"
                  class="mg-right-10"
                  color="white"
                  >mdi-check 
                </v-icon>
                <span style="color:white">
                  LOGIN
                </span>
              </v-btn>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
    <!-- <SignUp /> -->
    <div style="width: 100%;" v-if="isShowChevron" >
      <v-icon size="40" color="white" @click="gotoTop()" class="chevron_icon">mdi-chevron-double-up</v-icon>
  </div>
    <Footer />
    <AlertDialogs
    v-if="isHideShowAlertDialog"
    :alert-type="alertType"
    :alert-message="alertMessage"
    @closeDialog="closeAlertDialog"
  />
  </v-container>
</template>
<script>
import store from "@/store";
import AlertDialogs from "@/components/dialogs/AlertDialogs.vue";
import axios from "axios";
import Header from "@/components/Header.vue" 
import Footer from "@/components/Footer.vue" 
import interceptorSetup from '../helpers/httpInterceptor'
export default {
  name: "Login",
  components: {
   Header,
   Footer,
   AlertDialogs
  },
  data() {
    return {
      showLoader: true,
      isValid:true,
      passwordInput:'',
      usernameInput:'',
      errorMsg:false,
      isShowChevron:false,
      isHideShowAlertDialog:false,
      alertMessage:'',
      alertType:'',
      usernameInputError: { isError: false, errorMessage: '' },
      passwordInputError: { isError: false, errorMessage: '' },
  };
},
computed: {
  
},
methods: {
  handleUsernameInput(){
    this.usernameInputError.isError = false
    this.usernameInputError.errorMessage = ''
  },
  handlePasswordInput(){
    this.passwordInputError.isError = false
    this.passwordInputError.errorMessage = ''
  },
  login() {
    this.isValid = true
    if (this.usernameInput === '') {
      
      this.usernameInputError.isError = true
      this.usernameInputError.errorMessage = 'Username Required'
      this.isValid = false
    }
    if (this.passwordInput === '') {
      this.passwordInputError.isError = true
      this.passwordInputError.errorMessage = 'Password Required'
      this.isValid = false
    }
    if(this.usernameInput!='' && this.passwordInput!=""){
      localStorage.setItem('userName', this.usernameInput);
      let formData = new FormData();
      formData.append('username', this.usernameInput);
      formData.append('password', this.passwordInput);
      axios
      .post('/auth/login', formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.statusCode === 201) {
            localStorage.setItem('isLoggedIn', true)
            interceptorSetup.saveToken(response.data.token);
            store.dispatch('setToken', response.data.token)
            store.dispatch('getUser')
            this.$router.push({ name: 'MemberSearch'});
          } else if (response.data.statusCode === 401) {
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }else{
            this.alertType = 'Failed'
            this.alertMessage = "Please enter valid credentials."
            this.isHideShowAlertDialog = true
          }
        } else {
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    }
  },
  onScroll(e) {
    this.windowTop = window.top.scrollY;
    if(this.windowTop>=46){
      this.isShowChevron=true;
    }else{
      this.isShowChevron=false;
    }
  },
  gotoTop()
  {
    document.documentElement.scrollTop = 0;
  },
  closeAlertDialog () {
    this.isHideShowAlertDialog = false
    this.alertType = ''
    this.alertMessage = ''
  }
},
mounted () {
    // location.reload()
    // this.usernameInput = localStorage.getItem('userName').replace (/,/g, "");
    window.addEventListener("scroll", this.onScroll);
    // store.commit("SET_APP_USER", null);
    // interceptorSetup.removeToken();
    setTimeout(() => {
      this.showLoader = false;
    }, 3000);
  }
};
</script>
<style>
/* .v-sheet.v-card {
border-radius: 0px;
} */
.login_paragraph{
padding:0 12rem;
}
.background_img_login{
display: block;
background: url(../assets/grain_bg.png);
background-size: cover;
  height: 100% !important;
  position: relative;
  top: 100px;
  padding: 100px 0;
}
.fs-20{
font-size: 20px !important;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 20px;
}

.mg-btm p {
  margin-bottom: 20px;
  color: #666;
}

.theme--light.v-input {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 12px;
}

/* .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 20px;
} */
.password-field{
  margin-bottom: 27px !important;
}
.login-btn  {
  background-color: #8ab933 !important;
  margin-left: 11px;
}
.login-btn:active{
  background-color: #005cbf !important;
}
/*  */
.chevron_icon{
  position:fixed !important;
  bottom:0 !important;
  right:0 !important;
  margin: 6px;
  background: rgba(0,0,0,0.3);
  opacity: 0.9;
  z-index: 1;
}
</style>
