<template>
  <v-container fluid>
    <div>
      <div class="mg-top-10 header-intervention-info">{{ "Intervention Ad-Hoc Report" }}</div> 
        <div class="mg-top-10 pd-20">
          <v-row>
            <v-col cols="9"></v-col>
            <v-col cols="3">
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="10">
                  <v-text-field
                      class="mg-bottom-10"
                      variant="outlined"
                      density="comfortable"
                      v-model="searchValue"
                      :append-inner-icon="setIcon()"
                      hide-details="auto"
                      @click:append-inner="searchResetData()"
                      label="Search" 
                      @input="filterData">
                  </v-text-field>
              </v-col>
              </v-row>
            </v-col>
          </v-row>  
          <v-data-table
            v-model:items-per-page="itemsPerPage"
            :headers="adHocHeader"
            :items="reportData"
            show-expand
            class="elevation-1">
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                      <div class="title-ad-hoc-subtable">{{ "Impacted Drugs" }}</div>
                        <v-table
                            fixed-header
                            class="mg-top-10 mg-bottom-10"
                          >
                            <thead class="table-expand">
                              <tr>
                                <th class="text-left">
                                  Medication
                                </th>
                                <th class="text-left">
                                  QTY
                                </th>
                                <th class="text-left">
                                  DS
                                </th>
                                <th class="text-left">
                                  Cmpnd
                                </th>
                                <th class="text-left">
                                  Prescriber Name
                                </th>
                                <th class="text-left">
                                  Last Service Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in item.impactedDrugs"
                                v-bind:key="item.index">
                                <td>{{ item.drugName }}</td>
                                <td>{{ item.qtyDisp }}</td>
                                <td>{{ item.daysSupplyDisp }}</td>
                                <td>{{ item.cmpnd }}</td>
                                <td>{{ item.prescriberName }}</td>
                                <td>{{ item.impactedDrugsDate }}</td>
                              </tr>
                            </tbody>
                          </v-table>
                    </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <div class="text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                  <span>No data available.</span>
                </div>
              </template>
            </v-data-table>
        </div>
     </div>
    <div>
    <AlertDialogs
      v-if="isHideShowAlertDialog"
      :alert-type="alertType"
      :alert-message="alertMessage"
      :alert-message-type="alertMessageType"
      @closeDialog="closeAlertDialog"
    />
    </div>
  </v-container>
</template>
<script>
import AlertDialogs from "@/components/dialogs/AlertDialogs.vue";
import store from '../store'
import axios from 'axios'
// import { VDataTable } from 'vuetify/labs/VDataTable'
export default {
  name: "adHocReport",
  components: {
    // VDataTable
  },
  data() {
    return {
      itemsPerPage: 10,
      searchValue: '',
      isReportData: false,
      reportData: [],
      reportDataBackUp: [],
      adHocHeader: [
      {
          title: "Intervention Date",
          key: "newInterventionDate",
          align: 'start',
          sortable: true
      },
      {
          title: "Member Id",
          key: "memberId",
          align: 'start',
          sortable: true
      },
      {
          title: "Medical Provider Name",
          key: "name",
          align: 'start',
          sortable: true
      },
      {
          title: "Recom to Medical Provider",
          key: "recMedicalProvider",
          align: 'start',
          sortable: true
      },
      {
          title: "Clinical Issue Identified",
          key: "clinicalIssueIdentified",
          align: 'start',
          sortable: true
      },
      {
          title: "Clinical Impact",
          key: "clinicalImpact",
          align: 'start',
          sortable: true
      },
      {
          title: "Clinical Guidelines Ref",
          key: "clinicalGuidelinesRef",
          align: 'start',
          sortable: true
      },
      {
          title: "Response Or Outcome",
          key: "responseOrOutcome",
          align: 'start',
          sortable: true
      },
      { 
        title: 'Impacted Drugs',
        key: 'data-table-expand' 
      }
    ],
    alertType: '',
    alertMessage: '',
    isHideShowAlertDialog: false,
    alertMessageType: "",
    intervalRef: undefined
    }
  },
  methods: {
    setIcon(){
      if(this.searchValue !== ''){
        return 'mdi-close-circle-outline'
      }
    },
    getadHocReport () {
      axios
      .get('/clinicians/interRecord/getAllInterventionAdHocRRecords')
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            this.mapResponseWithFormData(response.data)
          } else if(response.data.code === 401) {
            this.alertType = 'Failed'
            this.alertMessage = response.data.message
            this.isHideShowAlertDialog = true
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    mapResponseWithFormData(responseData) {
      store.dispatch("hideLoadingSpinner");
      this.reportData = responseData.reportData
      const tempReportData = [...this.reportData]
      tempReportData.forEach(items => {
        items.name = items.firstName +' '+items.lastName
        const [year, month, day] = items.interventionDate.split("-");
        items.newInterventionDate = `${month}/${day}/${year}`;
        items.impactedDrugs.forEach(impactedDrugsItems => { 
          const [year, month, day] = impactedDrugsItems.lastServiceDate.split("-");
          impactedDrugsItems.impactedDrugsDate = `${month}/${day}/${year}`;
        })
      })
      this.reportData = tempReportData
      this.reportDataBackUp = tempReportData
      if( this.reportData.length > 0){
        this.isReportData = true
      }
    },
    closeAlertDialog () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
    },
    handleAdHocReport() {
      store.dispatch("showLoadingSpinner");
      this.getadHocReport()
    },
    filterData(value) {
      let searchValue = value.target.value
      let tempReportData = []
      tempReportData = this.reportDataBackUp.filter(item =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      )
      if(tempReportData == 0){
        tempReportData = this.reportDataBackUp.filter(item =>
          item.memberId.toString().toLowerCase().includes(searchValue.toString().toLowerCase())
        )
      }
      if(tempReportData == 0){
        tempReportData = this.reportDataBackUp.filter(item =>
          item.newInterventionDate.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      if(tempReportData == 0){
        tempReportData = this.reportDataBackUp.filter(item =>
          item.recMedicalProvider.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      if(tempReportData == 0){
        tempReportData = this.reportDataBackUp.filter(item =>
          item.clinicalIssueIdentified.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      if(tempReportData == 0){
        tempReportData = this.reportDataBackUp.filter(item =>
          item.clinicalImpact.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      if(tempReportData == 0){
        tempReportData = this.reportDataBackUp.filter(item =>
          item.clinicalGuidelinesRef.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      if(tempReportData == 0){
        tempReportData = this.reportDataBackUp.filter(item =>
          item.responseOrOutcome.toLowerCase().includes(searchValue.toLowerCase())
        )
      }
      this.reportData = tempReportData
    },
    searchResetData (){
      this.searchValue = ''
      this.reportData = this.reportDataBackUp
      this.setIcon()
    }
  },
  computed: {
    appUser() {
      return store.getters.appUser;
    },
    selectedClient() {
      return store.getters.selectedClient;
    }
  },
  mounted () { 
    if (this.selectedClient != null) {
      this.handleAdHocReport()
    } else {
        this.intervalRef = setInterval(() => {
          if (this.selectedClient !== null) {
            clearInterval(this.intervalRef)
            this.handleAdHocReport()
          }
        }, 1000)
    }
  },
  unmounted(){ 
   clearInterval(this.intervalRef)
  }
};
</script>
<style>
.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th {
  background: #000000;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}
.title-ad-hoc-subtable{
  text-align: center;
  margin-top: 7px;
  font-size: 22px;
}
.mdi-close-circle-outline::before {
  content: "\F015A";
  font-size: 25px;
  color: red;
}
</style>
