<template>
    <v-dialog
        v-model="dialog"
        persistent
        min-width="200"
        width="720"
        height="auto"
        scrollable
        class="bg-dia"
    >
        <v-card>
        <!-- <v-card-title class="text-h5 card-title">
          <h4 class="title-center" v-if="signType==='continue'">You're about to be signed out.</h4>
          <h4 class="title-center" v-if="signType==='stop'">You're already signed out.</h4>
          <v-icon
              size="25"
              class="icon-alert-box"
              color="success"
              @click="$emit('closeDialog')"
              style="cursor: pointer"
              v-if="signType==='continue'"
          >
              mdi-close
          </v-icon>
        </v-card-title> -->
        <v-card-title class="text-h5 card-title" style="display: flex; align-items: center; justify-content: center; margin: 24px 0 10px 0">
    <h3 class="title-center" v-if="signType==='continue'">You're about to be signed out.</h3>
    <h3 class="title-center" v-if="signType==='stop'">You're already signed out.</h3>
    <v-icon
      size="25"
      class="icon-alert-box"
      color="success"
      @click="$emit('closeDialog')"
      style="cursor: pointer"
      v-if="signType==='continue'"
    >
      mdi-close
    </v-icon>
  </v-card-title>
        <v-card-text class="card-text-pd">
          <div class="flex-row wrapper" >
            <div class="alert-message-text minimum-width">
              <p v-if="signType==='continue'">
                  For security reasons, your connections times out after you've been <br> inactive for while, Click Continue to stay signed in.
              </p>
              <p v-if="signType==='stop'">
                   Please sign in again.
              </p>
            </div>

          </div>
        </v-card-text>
        <v-card-actions >
            <v-col>
            <v-row class="action-flex marbot-12" >
            <v-btn
                v-if="signType==='continue'"
                color="blue darken-1"
                style="color: #ffffff"
                variant="elevated"
                @click="handleContinueClick()"
            >
                Continue
            </v-btn>
            <!-- <v-btn
                v-if="signType==='stop'"
                color="red darken-1"
                variant="elevated"
                style="color: #ffffff"
                @click="handleContinueClick()"
            >
                Goto Login
            </v-btn> -->
          </v-row>
            <v-row v-if="signType==='continue'" class="action-flex"   >
              <p>{{ timeRemaining }}</p>
            </v-row>
          </v-col>
          </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import store from '../../store'
import interceptorSetup from '../../helpers/httpInterceptor'
export default {
  name: 'AlertTokenExpiryDialogs',
  components: {
  },
  props: {
    signType: { type: String, default: '' },
    tokenExpiry: { type: String, default: '' }
  },
  data: () => ({
    dialog: true,
    countdown: 300,
    countdownTimer: null,
    timeRemaining: ''
  }),
  computed: {
  },
  methods: {
    formatTime (seconds) {
      this.countdownTimer = setInterval(() => {
        if (this.countdown > 0) {
          const expiryDate = new Date(this.tokenExpiry)
          expiryDate.setSeconds(expiryDate.getSeconds())
          this.countdown -= 1
          const differenceInSeconds = Math.floor((expiryDate - new Date()) / 1000)
          const minutesLeft = Math.floor(differenceInSeconds / 60)
          const secondsLeft = differenceInSeconds % 60
          const timeRemaining = `0${minutesLeft}:${secondsLeft < 10 ? '0' : ''}${secondsLeft} minutes remaining`
          this.timeRemaining = timeRemaining
        } else {
          clearInterval(this.countdownTimer)
        }
      }, 1000)
    },
    handleContinueClick () {
      // store.dispatch('refreshAuthToken')
      interceptorSetup.refreshToken();
      this.$emit('closeDialog')
    }
  },
  mounted () {
    // this.startCountdown()
    this.formatTime()
  },
  beforeDestroy () {
    // Clear the interval when the component is destroyed to prevent memory leaks
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer)
    }
  }
}
</script>
<style>
.wrapper {
text-align: center;
width: 100%
}
.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center
}
.title-center {
  text-align: center; flex: 1;
}
.minimum-width {
  min-width: 100%;
}
.action-flex {
  display: flex;
  justify-content: center;
}
.card-text-pd {
  padding: 0px 24px 8px !important;
}

.bg-dia {
    background-color: #c0c0c0;;
} 

.marbot-12 {
  margin-bottom: 20px !important;
}
</style>
