<template>
    <v-container id="logout" fluid>
        <Header />
        <div class="logout-container">
            <h1>LOGOUT SUCCESSFUL</h1>
        </div>
        <Footer />
    </v-container>
  </template>
  <script>
   import Header from "@/components/Header.vue" 
   import Footer from "@/components/Footer.vue" 
   import interceptorSetup from '../helpers/httpInterceptor'
   import store from "@/store";
  export default {
    name: "Logout",
    components: {
        Header,
        Footer
    },
    data() {
      return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted () {
    store.commit("SET_APP_USER", null);
    localStorage.removeItem('isLoggedIn')
    localStorage.removeItem('userName')
    interceptorSetup.removeToken();
    // localStorage.setItem('isLoggedIn', false)
    // localStorage.setItem('userName', null)
  }
  };
  </script>
  <style>
  .logout-container{
    position: relative;
    top:100px;
    height: 60vh
    
  }

  .logout-container h1{
    margin: 0px;
    padding: 0px;
    font-size: 26px;
    font-weight: 300;
    background: rgba(148,149,150,0.05);
    padding: 50px 170px;
  }
</style>