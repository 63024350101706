import Login from '../views/Login.vue'
import Clinician from '../views/Clinician.vue'
import LandingPage from '../views/LandingPage.vue'
import Privacy from '../views/Privacy.vue'
import MyProfile from '../views/MyProfile.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Register from '../views/Register.vue'
import TermsService from '../components/termsAndCondition/TermsService.vue'
import Nopp from '../components/termsAndCondition/Nopp.vue'
import RegisterProfile from '../views/RegisterProfile.vue'
import Logout from '../views/Logout.vue'
import SignUp from '../views/SignUp.vue'
import MtmQualityControlQueue from '../views/MtmQualityControlQueue.vue'
import InterventionReport from '../views/InterventionReport.vue'
import InterventionRecords from '../views/InterventionRecords.vue'
import FaxDashboard from '../views/FaxDashboard.vue'
import SelectCriteriaReport from '../components/SelectCriteriaReport.vue'
import DisplayAllCriteriaReport from '../views/DisplayAllCriteriaReport.vue'
import DisplayReTargetedReport from '../views/DisplayReTargetedReport.vue'
import DisplayMoneyCriteriaReport from '../views/DisplayMoneyCriteriaReport.vue'
import TopMedicalSpend from '../views/TopMedicalSpend.vue'
import DisplayDrugCriteriaReport from '../views/DisplayDrugCriteriaReport.vue'
import GPIInclusionReport from '../views/GPIInclusionReport.vue'
import DisplayICDCriteriaReport from '../views/DisplayICDCriteriaReport.vue'
import DisplayPharmacyCriteriaReport from '../views/DisplayPharmacyCriteriaReport.vue'
import DisplayPrescriberCriteriaReport from '../views/DisplayPrescriberCriteriaReport.vue'
import DisplayMemberDetails from '../views/DisplayMemberDetails.vue'
import MemberSearch from '../views/MemberSearch.vue'
import QualifiedReport from '../views/QualifiedReport.vue'
import DormantReport from '../views/DormantReport.vue'
import DeclinedReport from '../views/DeclinedReport.vue'
import TargetedReport from '../views/TargetedReport.vue'
import InvitedReport from '../views/InvitedReport.vue'
import AcceptedReport from '../views/AcceptedReport.vue'
import ManagedReport from '../views/ManagedReport.vue'
import RetiredReport from '../views/RetiredReport.vue'
import GraduatedReport from '../views/GraduatedReport.vue'
import adHocReport from '../views/adHocReport.vue'
import { createWebHistory, createRouter } from "vue-router";
import interceptorSetup from '../helpers/httpInterceptor'
import store from "@/store";

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'catch-all',
    component: LandingPage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/clinician',
    name: 'Clinician',
    component: Clinician,
    meta: { requiresAuth: true }
  },{
    path:"/privacy",
    name:'Privacy',
    component:Privacy
  },
  {
    path: '/myprofile',
    name: 'MyProfile',
    component: MyProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/terms-service',
    name: 'TermsService',
    component: TermsService,
  },
  {
    path: '/nopp',
    name: 'Nopp',
    component: Nopp,
  },
  {
    path: '/registerprofile',
    name: 'RegisterProfile',
    component: RegisterProfile
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path:'/qualityControlQueue',
    name:'MtmQualityControlQueue',
    component: MtmQualityControlQueue,
    meta: { requiresAuth: true }
  },
  {
    path:'/interventionReport/:id?',
    name:'InterventionReport',
    component: InterventionReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/interventionRecords',
    name:'InterventionRecords',
    component: InterventionRecords,
    meta: { requiresAuth: true }
  },
  {
    path:'/faxDashboard',
    name:'FaxDashboard',
    component: FaxDashboard,
    meta: { requiresAuth: true }
  },
  {
    path:'/selectCriteriaReport',
    name:'SelectCriteriaReport',
    component: SelectCriteriaReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayAllCriteriaReport/:selectedValue?',
    name:'DisplayAllCriteriaReport',
    component: DisplayAllCriteriaReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayReTargetedReport/:selectedValue?',
    name:'DisplayReTargetedReport',
    component: DisplayReTargetedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayTopRxSpendReport/:selectedValue?',
    name:'DisplayTopRxSpendReport',
    component: DisplayMoneyCriteriaReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayTopMedicalSpendReport/:selectedValue?',
    name:'TopMedicalSpendReport',
    component: TopMedicalSpend,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayDrugCriteriaReport/:selectedValue?',
    name:'DisplayDrugCriteriaReport',
    component: DisplayDrugCriteriaReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/gPIInclusionReport/:selectedValue?',
    name:'GPIInclusionReport',
    component: GPIInclusionReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayICDCriteriaReport/:selectedValue?',
    name:'DisplayICDCriteriaReport',
    component: DisplayICDCriteriaReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayPharmacyCriteriaReport/:selectedValue?',
    name:'DisplayPharmacyCriteriaReport',
    component: DisplayPharmacyCriteriaReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayPrescriberCriteriaReport/:selectedValue?',
    name:'DisplayPrescriberCriteriaReport',
    component: DisplayPrescriberCriteriaReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/displayMemberDetails',
    name:'DisplayMemberDetails',
    component: DisplayMemberDetails,
    meta: { requiresAuth: true }
  },
  {
    path:'/memberSearch',
    name:'MemberSearch',
    component: MemberSearch,
    meta: { requiresAuth: true }
  },
  {
    path:'/qualifiedReport',
    name:'QualifiedReport',
    component: QualifiedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/dormantReport/:selectedValue?',
    name:'DormantReport',
    component: DormantReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/declinedReport/:selectedValue?',
    name:'DeclinedReport',
    component: DeclinedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/targetedReport/:selectedValue?',
    name:'TargetedReport',
    component: TargetedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/invitedReport/:selectedValue?',
    name:'InvitedReport',
    component: InvitedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/acceptedReport/:selectedValue?',
    name:'AcceptedReport',
    component: AcceptedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/managedReport/:selectedValue?',
    name:'ManagedReport',
    component: ManagedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/retiredReport/:selectedValue?',
    name:'RetiredReport',
    component: RetiredReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/graduatedReport/:selectedValue?',
    name:'GraduatedReport',
    component: GraduatedReport,
    meta: { requiresAuth: true }
  },
  {
    path:'/adHocReport',
    name:'adHocReport',
    component: adHocReport,
    meta: { requiresAuth: true }
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes,
});
function getTokenExpiration(token) {
  if (!token) {
    return null; 
  }
  try {
    const tokenPayload = token.split('.')[1];
    const decodedPayload = atob(tokenPayload);
    const parsedPayload = JSON.parse(decodedPayload);
    const expiration = parsedPayload.exp;

    if (!expiration || typeof expiration !== 'number') {
      return null; 
    }
    return expiration;
  } catch (error) {
    return null; 
  }
}

router.beforeEach(async (to, from, next) => {
  const routeExists = routes.find(route => route.path === to.path);
  const authToken = interceptorSetup.getAuthToken();
  if (to.meta.requiresAuth && !authToken) {
    store.commit("SET_APP_USER", null);
    next('/');
  } else if (to.meta.requiresAuth && authToken) {
    const tokenExpiration = getTokenExpiration(authToken);
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
    if (tokenExpiration && tokenExpiration < currentTime) {
        store.commit("SET_APP_USER", null);
        interceptorSetup.removeToken()
        next('/');
    } else if (tokenExpiration && tokenExpiration - currentTime <= 900) {
      try {
        await interceptorSetup.refreshToken(); 
        if (from.name === undefined ) {
          store.dispatch('getUser')
        }
      } catch (error) {
        store.commit("SET_APP_USER", null);
        interceptorSetup.removeToken()
        next('/');
        return;
      }
    }
    next();
  } else {
    next();
  }
});

export default router
