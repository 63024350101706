<template>
    <v-row>
        <v-menu>
            <template v-slot:activator="{ props }">
                <v-icon
                    dark
                    icon
                    v-bind="props"
                    size="20"
                    color="black">
                    mdi-dots-vertical
                </v-icon>
            </template>
            <v-list>
                <v-list-item>
                <v-list-item-title>{{ "Cancel" }}</v-list-item-title>
                <v-list-item-title>{{ "Download" }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-row>
  </template>
  <script>
  export default {
    name: "rendererFaxDeshboardAction",
    data() {
      return {
      }
    },
    computed: { 
    },
    methods: {
    }
  };
</script>
