<template>
  <span v-on:click="addDrugTab(params.data.drugGPI)" class="pointer">
    {{ params.value }}
  </span>
</template>
<script>
export default {
  name: "renderDrugCell",
  data() {
    return {
    }
  },
  methods: {
    addDrugTab(drugGPI) {
      this.params.action(drugGPI)
    }
  }
}
</script>