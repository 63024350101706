<template>

<div id="main_header" class="mg-0">
        <v-row class="mg-0">
          <v-toolbar id="navbar" class="mg-0"  height="100" >
            <img id="logoImage" class="header-logo mouse-cursor" @click="handleLandingPage" src="../assets/ClinicalLensLogo.jpg" weight="100" height="75" />
            <v-spacer></v-spacer>
            <!-- <span class="pd-right-10rem"> -->
            <v-toolbar-items :class="{'pd-right-10rem': routeName==='LandingPage'}" v-if="routeName==='LandingPage' && appUser?.user === undefined">
              <v-btn  text @click="handleClinicianLogin()" >{{ "CLINICIAN LOGIN" }}</v-btn>
            </v-toolbar-items>
            <v-toolbar-items :class="{'pd-right-10rem': routeName==='LandingPage'}" v-if="routeName==='Logout'">
            <v-btn  text @click="handleClinicianLogin()" >{{ "CLINICIAN LOGIN" }}</v-btn>
            </v-toolbar-items>
            <v-toolbar-items class="pd-right-10rem" v-if="routeName=='Login'|| routeName=='Register' || routeName==='Logout'">
            <v-btn  text @click="handleLandingPage()">{{ "HOME" }}</v-btn>
            </v-toolbar-items>
            <v-toolbar-items  v-if="routeName=='MyProfile'">
            <v-btn  text @click="handleGotoUserProfile()">{{ "USER PROFILE" }}</v-btn>
            </v-toolbar-items>
            <v-toolbar-items  v-if="routeName=='MyProfile'">
            <v-btn  text @click="handleGotoClinicianPortal()">{{ "CLINICIAN PORTAL" }}</v-btn>
            </v-toolbar-items>
            <v-toolbar-items class="pd-right-10rem" v-if="routeName=='MyProfile'">
            <v-btn  text @click="handleLandingPage()">{{ "LOGOUT "}} <span style="text-transform: lowercase"> &nbsp; {{ appUser.user.username.toLowerCase()   }}</span>
            </v-btn>
            </v-toolbar-items>
            <v-toolbar-items v-if="routeName==='LandingPage' && appUser?.user !== undefined">
              <v-btn  text @click="handleGotoUserProfile()">{{ "USER PROFILE" }}</v-btn>
            </v-toolbar-items>
            <v-toolbar-items v-if="routeName==='LandingPage' && appUser?.user !== undefined">
              <v-btn  text @click="handleGotoClinicianPortal()">{{ "CLINICIAN PORTAL" }}</v-btn>
            </v-toolbar-items>
            <v-toolbar-items class="pd-right-10rem" v-if="routeName==='LandingPage' && appUser?.user !== undefined">
              <v-btn  text @click="handleGotoLogout()">{{ "LOGOUT "}} <span style="text-transform: lowercase"> &nbsp; {{ appUser.user.username.toLowerCase()   }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-row>
      </div>
  </template>
  <script>
  
 import store from "@/store";
  export default {
    name: "Header",
    components: {
    },
    data() {
    return {
      routeName:'',
    };
  },
  computed: {
    appUser() {
      return store.getters.appUser;
  }
  },
  methods: {
    handleGotoLogout () {
      store.commit("SET_APP_USER", null);
      this.$router.push({ name: 'LandingPage'})
    },
    handleGotoClinicianPortal () {
      this.$router.push({ name: 'MemberSearch'});
    },
    handleGotoUserProfile () {
      this.$router.push({ name: 'MyProfile'});
    },
    handleLandingPage() {
      this.$router.push({ name: 'LandingPage'});
    },
    handleClinicianLogin()
    {
      this.$router.push({ name: 'Login'});  
    },
    onScroll(e) {
    this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
    if(this.windowTop !==0){
      if(document.getElementById("logoImage")!=null){
      document.getElementById("logoImage").style.height="60px";
      document.getElementById("logoImage").style.width="49px";
      }
      if(document.getElementById("background_image")!=null){
        document.getElementById("background_image").style.top="64px";
      }
      if(document.getElementById("navbar")!=null){
        document.getElementById("navbar").style.height="64px";
      }
      if(document.querySelector("#navbar .v-toolbar__content")!=null){
        document.querySelector("#navbar .v-toolbar__content").style.height="64px";
      }
    }else{
      if(document.getElementById("logoImage")!=null){
        document.getElementById("logoImage").style.height="95px";
        document.getElementById("logoImage").style.width="86px";
      }
      if(document.getElementById("background_image")!=null){
        document.getElementById("background_image").style.top="100px";
      }
      if(document.getElementById("navbar")!=null){
        document.getElementById("navbar").style.height="100px";
      }
      if(document.querySelector("#navbar .v-toolbar__content")!=null){
        document.querySelector("#navbar .v-toolbar__content").style.height="100px";
      }
    }
  }
  },
  mounted () {
    window.addEventListener("scroll", this.onScroll);
    this.routeName = this.$router.currentRoute._value.name;
  }
  };
  </script>
  <style>

#main_header .mg-0{
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  .v-sheet.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 2%)!important;
  }
  .container {
    padding: 0px;
  }
    .header-logo {
      width: 86px;
      margin: 0px 0px 0px 10rem;
      height: 95px;
    }
    #navbar{
      background-color: white;
    }
 </style>
  