<template>
  <v-container fluid>
    <div>
      <v-col cols="11">
        <div class="header-info">MTM Issues / Side Effects</div>
        <div class="text-sub-info mg-top-20">
          Enter the beneficiary's adverse drug reactions that are not true
          allergies. Record the medication and note what happened to the
          beneficiary.
        </div>
        <div class="title-bold">Known Issues and Side Effects</div>
          <v-row v-if="selectisReview">
            <v-col cols="12" class="mg-left-10 mg-right-15">
              <div class="review-cart-bg-color">
                <div class="header-quality-control">Quality Control Review</div>
                <v-row class="mg-top-5">
                  <v-col cols="3"></v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="3"></v-col>
                      <v-col
                        cols="9"
                        class="text-align-inherit fs-14"
                        >{{ "Submitted for Review date:" }}</v-col
                      >
                    </v-row>
                  </v-col>
                  <v-col
                    cols="6"
                    class="text-align-inherit fs-14"
                    v-if="smtForReviewDate !== null"
                    >{{ smtForReviewDate }}</v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="3"></v-col>
                      <v-col
                        cols="9"
                        class="qty-review-title fs-14"
                        >{{ "Clinician:" }}</v-col
                      >
                    </v-row>
                  </v-col>
                  <v-col
                    cols="6"
                    class="qty-review-title fs-14"
                    >{{ "DR. "+selectClinicianName }}</v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="3"></v-col>
                      <v-col
                        cols="9"
                        class="qty-review-title fs-14"
                        >{{ "Member:" }}</v-col
                      >
                    </v-row>
                  </v-col>
                  <v-col
                    cols="6"
                    class="qty-review-title fs-14"
                    >{{ memberName }}</v-col
                  >
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row
            class="mg-top-10 pd-unset"
            v-if=" selectisReview === null || selectisReview === false"
          >
            <v-col
              cols="3"
              class="mouse-cursor"
              @click="handleAddIssue"
              :class="{ 'disabled-element': isItemEdit, 'gray-primary': isItemEdit}"
            >
              <v-row>
                <v-icon
                  size="25"
                  class="pd-0 mg-top-15"
                  :class="{ 'gray-primary': isItemEdit, 'blue-color': isItemEdit == false}"
                >
                  mdi-plus
                </v-icon>
                <div
                  class="primary-color mg-top-20 fs-15"
                  :class="{ 'gray-primary': isItemEdit}"
                >
                  ADD ISSUE OR SIDE EFFECTS
                </div>
              </v-row>
            </v-col>
          </v-row>
          <div
            class="mg-top-30"
            v-if=" selectisReview === null || selectisReview === false"
          >
            <div v-for="(item, index) in issueForm" :key="index">
              <v-card class="mg-bottom-40 card-border-blue" height="100%">
                <v-row class="mg-top-20">
                  <v-col cols="11">
                    <v-textarea
                      variant="outlined"
                      density="comfortable"
                      class="mx-2"
                      auto-grow
                      rows="4"
                      v-model="item.issue"
                      name="input-7-4"
                      label="Issue or side effect"
                      @input="handleIssueNotes(index, $event, 'issueForm')"
                      :error="item.issuesError.isError"
                      :error-messages="item.issuesError.errorMessage"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col cols="9"></v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="4">
                      <v-btn color="black" variant="text" @click="handleCancelIssue(index)">
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        color="black"
                        variant="text"
                        @click="handleClearIssue(index, 'issueForm')"
                      >
                        Clear
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        color="blue"
                        @click="handleMtmAddIssue(index)"
                        variant="text"
                        :disabled="(item.issue == '')"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="issueListData !== undefined"  :class="{ 'mg-left-30': selectisReview, 'mg-right-30': selectisReview}">
          <div
            v-for="(item, index) in issueListData"
            :key="index"
            class="mg-top-30"
          >
            <v-card height="100%" class="card-border-blue" :class="{ 'card-border-blue': item.isActive, 'card-border-gray': !item.isActive}">
              <v-row class="main-row" height="150">
                <v-col cols="10" class="mg-left-20">
                  <div v-if="!item.isEdit">
                    <v-row>
                      <div class="title-bold">Issue or side effect</div>
                    </v-row>
                    <v-row>
                      <div class="mg-top-10">{{item.issue}}</div>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-textarea
                      variant="outlined"
                      density="comfortable"
                      class="mx-2"
                      rows="4"
                      name="input-7-4"
                      label="Issue or side effect"
                      v-model="item.issue"
                      @input="handleIssueNotes(index, $event, 'issueListData')"
                    ></v-textarea>
                  </div>
                </v-col>
                <v-col
                  cols="1"
                  class="mg-left-30"
                  v-if="(selectisReview === false || selectisReview === null) && (isItemEdit === false || isConfirm)"
                >
                  <v-menu bottom right>
                    <template v-slot:activator="{ props }">
                      <v-icon
                        dark
                        icon
                        v-bind="props"
                        class="mg-left-3rem"
                        size="20"
                        color="black"
                      >
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        class="dot-item-list"
                        v-if="item.activeStatus === 'active'"
                      >
                        <v-list-item-title @click="handleisEdit(index)">
                          {{"Edit"}}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        class="dot-item-list"
                        v-if="item.activeStatus === 'active'"
                      >
                        <v-list-item-title
                          @click="handleActAndDeactive(item.id, 'deactive')"
                          >{{ "Deactivate" }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        class="dot-item-list"
                        v-if="item.activeStatus === 'deactive'"
                      >
                        <v-list-item-title
                          @click="handleActAndDeactive(item.id, 'active')"
                          >{{ "Active" }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item class="dot-item-list">
                        <v-list-item-title @click="handleDeleteIssue(item.id)">{{
                      "Delete"
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card>
            <v-row v-if="item.isEdit">
              <v-col cols="9"></v-col>
              <v-col cols="3">
                <v-row>
                  <v-col cols="4">
                    <v-btn
                      color="black"
                      variant="text"
                      @click="handleCancelIssue(index, 'isIssueList')"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="black"
                      variant="text"
                      @click="handleClearIssue(index, 'isIssueList')"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="blue"
                      @click="handleIssueUpdate(index)"
                      variant="text"
                      :disabled="(item.issue == '')"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="selectisReview !== null || selectisReview">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset"
                v-if="!item.isShowReviewComment"
              >
                <v-col cols="9"></v-col>
                <v-col
                  cols="3"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isCommentAdd}"
                  @click="handleIsReviewerComment(index)"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isCommentAdd, 'blue-color': isCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isCommentAdd}">ADD REVIEWER COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30" v-if="item.isShowReviewComment">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="item.remark"
                  :disabled="(selectisReview !== null || selectisReview) && !item.isReviewComment"
                  @input="changeCommentReview($event, index)"
                  rows="4"
                  label="QC Reviewer Comment"
                  :readonly="!selectisReview"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview !== null || selectisReview) && item.isShowReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReviewComment(index)" 
                    v-if="item.isShowReviewBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReviewComment(index)"
                    v-if="item.isShowReviewBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReviewComment(index)"
                    v-if="item.isReviewComment && item.isShowReviewBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                    v-if="item.isReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReviewComment(index)"
                    >{{item.isShowReviewBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
            <div v-if="selectisReview === null || selectisReview === false">
              <v-row
                class="mg-top-30 mg-left-10 pd-unset"
                v-if="item.isShowReviewComment && item.isShowReplyReviewComment === false"
              >
                <v-col cols="8"></v-col>
                <v-col
                  cols="4"
                  class="mouse-cursor"
                  :class="{ 'disabled-element': isReplyCommentAdd}"
                  @click="handleIsReplyReviewerComment(index)"
                >
                  <v-row>
                    <v-icon size="23" :class="{'gray-primary': isReplyCommentAdd, 'blue-color': isReplyCommentAdd === false}"> mdi-plus </v-icon>
                    <div class="primary-color fs-15" :class="{'gray-primary': isReplyCommentAdd}">ADD REPLY FOR THE ABOVE COMMENT</div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-row class="mg-top-30" v-if="(selectisReview && item.isShowReviewComment && item.isShowReplyReviewComment) || (item.isShowReplyReviewComment)">
              <v-col cols="12" class="pd-0">
                <v-textarea
                  variant="outlined"
                  density="comfortable"
                  class="mx-2"
                  v-model="item.remarkComment"
                  @input="changeReplyCommentReview($event, index)"
                  rows="4"
                  :disabled="(selectisReview !== null || selectisReview === true) && !item.isReplyReviewComment"
                  :readonly="selectisReview"
                  label="Reply For QC Reviewer Comment"
                />
              </v-col>
            </v-row>
            <v-row
              class="mg-top-10 mg-right-10 pd-unset"
              v-if="(selectisReview === null || selectisReview === false) && item.isShowReplyReviewComment"
            >
              <v-col cols="8"></v-col>
              <v-col cols="4" class="mouse-cursor">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn variant="text" @click="handleCancelReplyReviewComment(index)" 
                    v-if="item.isShowReviewReplyBtn === false"
                    >CANCEL</v-btn>
                  <v-btn
                    variant="text"
                    @click="handleClearReplyReviewComment(index)"
                    v-if="item.isShowReviewReplyBtn === false"
                    >CLEAR</v-btn
                  >
                  <v-btn
                    variant="text"
                    color="error"
                    @click="handleDeleteReplyReviewComment(index)"
                    v-if="item.isReplyReviewComment && item.isShowReviewReplyBtn"
                    >DELETE</v-btn
                  >
                  <v-btn
                  v-if="item.isReplyReviewComment"
                    variant="text"
                    color="info"
                    @click="handleAddReplyComment(index)"
                    >{{item.isShowReviewReplyBtn ? "UPDATE" : "SAVE" }}</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
          </div>
        <div v-if="selectisReview !== null || selectisReview">
          <div class="mg-top-6rm txt-align-center fs-25 font-weight-400">
            Document to Review
          </div>
          <div class="mg-top-20">
            <iframe
              id="pdf"
              :src="url"
              frameborder="1"
              scrolling="auto"
              width="100%"
              height="600px"
              class="pdf-viewer"
            ></iframe>
          </div>
        </div>
      </v-col>
     </div>
    <AlertDialogs
      v-if="isHideShowAlertDialog"
      :alert-type="alertType"
      :alert-message="alertMessage"
      :alert-message-type="alertMessageType"
      @closeDialog="closeAlertDialog"
      @onConfirmOK="handleAlertConfirmation"
    />
    <v-snackbar
      v-model="snackbar"
      v-bind:color="snackColor"
      :timeout="snackTimeout"
    >
      <span
        :class="{ 'black--text': snackColor== '#CDDC39', 'white--text': snackColor== '#DD2C00' }"
        class=""
      >
        {{snackStatus}}
      </span>
    </v-snackbar>
  </v-container>
</template>
<script>
import AlertDialogs from "./dialogs/AlertDialogs.vue";
import store from "@/store";
import axios from "axios";
import interceptorSetup from '../helpers/httpInterceptor'
export default {
  name: "MtmIssue",
  components: {
  AlertDialogs,
  },
  data() {
    return {
    gray: '#E1DA9B',
    clientData: null,
    memberId : null,
    documentId: null,
    issueForm: [],
    alertType: "",
    alertMessage: "",
    confirmType: "",
    alertMessageType: "",
    pageReload: false,
    isHideShowAlertDialog: false,
    issueListData: undefined,
    memberDetails: undefined,
    cancelIssuesIndex: '',
    cancelForIssues: '',
    clearIssuesIndex: '',
    clearIssuesType: '',
    isItemEdit: false,
    snackbar: false,
    snackStatus: null,
    snackColor: "black",
    interval: null,
    snackTimeout: "20000",
    autoSaveTimeout: "30000",
    errorTimout: null,
    autoSaveValidation: false,
    isConfirm: true,
    url: null,
    smtForReviewDate: null,
    memberName: '',
    isCommentAdd: false,
    isReplyCommentAdd: false
  };
},
destroyed() {
  clearInterval(this.interval);
  clearInterval(this.errorTimout);
},
computed: {
  selectedMemberId() {
    return store.getters.selectedMemberId;
  },
  selectedClient() {
    return store.getters.selectedClient;
  },
  selectdocumentId() {
    return store.getters.selectdocumentId;
  },
  selectisReview() {
    return store.getters.selectisReview
  },
  memberDetail () {
    return store.getters.memberDetail;
  },
  selectClinicianName() {
    return store.getters.selectClinicianName
  },
  selectReviewerId() {
    return store.getters.selectReviewerId;
  }
},
methods: {
    handleAddReviewComment(index) {
      const issuesObj = this.issueListData[index]
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmIssues (issuesObj, clientId, this.memberId, index)
      }
    },
    handleDeleteReviewComment(index) {
      const issuesObj = this.issueListData[index]
      issuesObj.remark = null
      issuesObj.remarkComment = null
      issuesObj.isShowReviewComment = false
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null ) {
        this.createMtmIssues (issuesObj, clientId, this.memberId, index)
      }
    },
    changeCommentReview(value, index) {
      this.issueListData.forEach(items => {
        items.isReviewComment = false
      });
      this.isCommentAdd = true
      this.issueListData[index].isReviewComment = true
      this.issueListData[index].remark = value.target.value
    },
    changeReplyCommentReview(value, index) {
      this.issueListData.forEach(items => {
        items.isReplyReviewComment = false
      });
      this.isReplyCommentAdd = true
      this.issueListData[index].isReplyReviewComment = true
      this.issueListData[index].remarkComment = value.target.value
    },
    handleClearReplyReviewComment(index) {
      this.issueListData[index].remarkComment = ""
    },
    handleCancelReplyReviewComment(index) {
      this.issueListData.forEach(items => {
        items.isReplyReviewComment = true
        items.remarkComment = ""
      });
      this.issueListData[index].isShowReplyReviewComment = false
      this.isReplyCommentAdd = false
    },
    handleIsReplyReviewerComment(index){
      this.issueListData[index].isShowReplyReviewComment = true
      this.isReplyCommentAdd = true
    },
    handleAddReplyComment(index) {
      const issuesObj = this.issueListData[index]
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmIssues (issuesObj, clientId,this.memberId, index)
      }
    },
    handleDeleteReplyReviewComment(index) {
      const issuesObj = this.issueListData[index]
      issuesObj.remarkComment = null
      issuesObj.isShowReplyReviewComment = false
      const clientId = this.clientData.clientId
      if (clientId !== null && this.memberId !== null) {
        this.createMtmIssues (issuesObj, clientId, this.memberId, index)
      }
    },
    handleCancelReviewComment(index) {
      this.issueListData.forEach(items => {
        items.isReviewComment = true
        items.remark = ""
      });
      this.issueListData[index].isShowReviewComment = false
      this.isCommentAdd = false
    },
    handleClearReviewComment(index) {
      this.issueListData[index].remark = ""
    },
    handleIsReviewerComment(index) {
      this.issueListData[index].isShowReviewComment = true
      this.isCommentAdd = true
    },
    closeAlertDialog () {
      this.isHideShowAlertDialog = false;
      this.alertType = "";
      this.alertMessage = "";
      this.confirmType = "";
      if(this.pageReload) {
        if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
          store.dispatch("showLoadingSpinner");
          this.getAllMtmIssues(this.clientData.clientId, this.memberId, this.documentId);
        }
      }
    },
    handleAlertConfirmation (index) {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      if(this.confirmType === 'cancelIssues') {
        this.cancelIssue (this.cancelIssuesIndex, this.cancelForIssues)
      } else if(this.confirmType === 'clearIssues') {
        this.clearIssue (this.clearIssuesIndex, this.clearIssuesType)
      }
    },
    handleActAndDeactive(id, action) {
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.updateStatusAction(id, action);
      }
    },
    updateStatusAction (id, action) {
    axios
      .get(
        "/mtm/sideeffect/updateaction?clientId=" +this.clientData.clientId+ "&id=" +
          id +
          "&action=" +
          action
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    handleDeleteIssue (id) {
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.getMtmIssuedelete(id);
      }
    },
    getMtmIssuedelete(id) {
      axios
      .delete("/mtm/sideeffect/getdeleteById?clientId=" +this.clientData.clientId+ "&Id=" + id)
      .then((response) => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            if (response.data.code === 201) {
              const responseIndex = this.issueListData.findIndex((item) => item.id === id);
              if (responseIndex !== -1) {
                this.issueListData.splice(responseIndex, 1);
              }
              this.alertType = "Success";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
              this.pageReload = true;
            } else if (response.data.code === 401) {
              this.alertType = "Failed";
              this.alertMessage = response.data.message;
              this.isHideShowAlertDialog = true;
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    handleIssueUpdate (index, id) {
      const tempIssueForm = this.issueListData[index];
      if (this.clientData.clientId !== null && this.memberId !== null) {
        store.dispatch("showLoadingSpinner");
        this.issueUpdate(tempIssueForm, this.clientData.clientId, index);
      }
    },
    issueUpdate(issueForm, clientId, index) {
      axios
      .post(
        "/mtm/sideeffect/addmtmsideeffect?clientId=" + clientId,
        issueForm
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            this.pageReload = true;
            this.issueListData[index].isEdit = false
            this.isItemEdit = false
            this.isConfirm = true
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    handleisEdit (index) {
      this.issueListData[index].isEdit = true;
      this.isItemEdit = true;
      this.isConfirm = false;
    },
    handleAddIssue(){
      this.issueForm.push({
        companyId: 1,
        clientId: "",
        memberId: "",
        documentId: "",
        activeStatus: "active",
        issue: "",
        issuesError: { isError: false, errorMessage: '' }
      });
      this.isItemEdit = true
      this.isConfirm = true
    },
    handleClearIssue (index, issueType) {
      this.clearIssuesIndex = index
      this.clearIssuesType = issueType
      this.alertMessage = 'Are you sure you want to clear the content?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'clearIssues'
      this.isHideShowAlertDialog = true
    },
    clearIssue (index, issueType) {
      if (issueType == "isIssueList") {
        this.issueListData[index].issue = "";
      } else {
        this.issueForm[index].issue = "";
      }
    },
    handleCancelIssue(index, cancelForIssue) {
      this.cancelIssuesIndex = index
      this.cancelForIssues = cancelForIssue
      this.alertMessage = 'Are you sure you want to cancel?'
      this.alertType = 'Confirmation'
      this.alertMessageType= 'No'
      this.confirmType = 'cancelIssues'
      this.isHideShowAlertDialog = true
    },
    cancelIssue(index, cancelForIssue) {
      this.isItemEdit = false;
      this.isConfirm = true;
      if (cancelForIssue == "isIssueList") {
        this.issueListData[index].isEdit = false;
      } else {
        if(this.issueForm.length > 0){
          if(this.issueForm[index].hasOwnProperty('id')) {
            axios
              .delete('/mtm/sideeffect/getdeleteById?clientId='+ this.clientData.clientId +'&Id='+this.issueForm[index].id)
              .then(response => {
                if (response.status === 200) {
                  if (response.data.code === 201) {
                    // this.autoSaveStatus(false);
                  } else {
                    this.autoSaveStatus(true);
                  }
                }
              });
              this.issueForm.splice(index, 1);
          } else {
            this.issueForm.splice(index, 1);
          }
      }
      }
    },
    handleIssueNotes(index, value, issueType) {
      const newValue = value.target.value
      if (issueType === "issueForm") {
        this.issueForm[index].issue = newValue;
        this.issueForm[index].issuesError.isError = false;
        this.issueForm[index].issuesError.errorMessage = "";
      } else {
        this.issueListData[index].issue = newValue;
      }
    },
    handleMtmAddIssue (index) {
      const tempIssueForm = this.issueForm[index];
      tempIssueForm.clientId = this.clientData.clientId;
      tempIssueForm.memberId = this.memberId;
      tempIssueForm.documentId = this.documentId;
      let isValid = true
      if (this.issueForm[index].issue === '') {
        this.issueForm[index].issuesError.isError = true
        this.issueForm[index].issuesError.errorMessage = 'You must enter issue or side effect.'
        isValid = false
      }
      if(isValid) {
        if (this.clientData.clientId !== null && this.memberId !== null && this.documentId !== null) {
          if(!this.autoSaveValidation) {
            store.dispatch("showLoadingSpinner");
            this.createMtmIssues(tempIssueForm, this.clientData.clientId, this.memberId, index);
          } else {
            this.saveByInterval(tempIssueForm, this.clientData.clientId, index, this.memberId, this.documentId);
          }
        }
      } else if(this.autoSaveValidation) {
        this.autoSaveStatus(false, index);
      }
      this.autoSaveValidation = false;
    },
    createMtmIssues (issuesForm, clientId, memberId, index) {
      axios
      .post(
        "/mtm/sideeffect/addmtmsideeffect?clientId=" + clientId +"&memberId=" +memberId,
        issuesForm
      )
      .then((response) => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            this.isItemEdit = false
            this.isConfirm = true
            if(this.selectisReview === null || this.selectisReview === false) {
              this.issueForm.issue= " "
            }
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            this.isCommentAdd = false
            this.isReplyCommentAdd = false
            this.pageReload = true;
            this.issueForm.splice(index, 1);
          } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
        }
      })
      .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    autoSaveByTwoMin() {
      this.interval = setInterval(() => {
        clearInterval(this.errorTimout);
        this.issueForm.map((items, index) => {
          items.clientId = this.clientData.clientId;
          items.memberId = this.memberId;
          items.documentId = this.documentId;
          this.autoSaveValidation = true;
          this.handleMtmAddIssue (index);
        });
      },this.autoSaveTimeout);
    },
    saveByInterval (issueForm, clientId, indexOfIssues, memberId, documentId) {
    axios
      .post("/mtm/sideeffect/addmtmsideeffect?clientId=" + clientId +"&memberId=" +memberId, issueForm)
      .then(response => {
        if (response.status == 200) {
          if (response.data.code === 201 && !issueForm.hasOwnProperty('id')) {
            axios
              .get('/mtm/sideeffect/getsideeffects?clientId=' +clientId +'&memberId=' +memberId +'&documentId=' +documentId)
              .then(response => {
                if (response.status === 200) {
                  if (response.data.code === 201) {
                    if(response.data.dataLists.length > 0) {
                      response.data.dataLists.map((items, index) => {
                        var exist = this.issueListData?.some(element => element.id == items.id);
                        if(!exist) {
                          this.issueForm[indexOfIssues]['id'] = items.id;
                          this.autoSaveStatus(true, indexOfIssues);
                        }
                      })
                    } else {
                      this.autoSaveStatus(false, indexOfIssues);
                    }
                  } else {
                    this.autoSaveStatus(false, indexOfIssues);
                  }
                } else {
                  this.autoSaveStatus(false, indexOfIssues);
                }
              });
          } else if(issueForm.hasOwnProperty('id')) {
            this.autoSaveStatus(true, indexOfIssues);
          }
        } else {
           this.autoSaveStatus(false, indexOfIssues);
        }
      })
      .catch((error) => {
        this.autoSaveStatus(false, indexOfIssues);
      })
    },
    autoSaveStatus(success, index) {
      if(!success) {
        this.snackStatus = "Autosave Unsuccessful. Trying again in 15s";
        if(index == this.issueForm.length-1) {
          this.snackColor = "#DD2C00";
          this.snackbar = true
          this.autoSaveTimeout = 15000
          clearInterval(this.interval);
          this.autoSaveByTwoMin();
          var seconds = 15;
          this.snackTimeout = 15000;
          this.errorTimout = setInterval(() => {
            this.snackColor = "#DD2C00";
            seconds = seconds - 1;
            if(seconds == 0) {
              this.snackTimeout = 20000;
              this.snackbar = false;
            }
            this.snackStatus = "Autosave Unsuccessful. Trying again in "+ seconds +"s";
          }, 1000);
        }
      } else {
        this.autoSaveTimeout = 60000
        this.snackStatus = "Autosave Completed at "+ this.formatAMPM(new Date());
        this.snackColor = "#CDDC39";
        if(index == this.issueForm.length-1) {
          this.snackbar = true
        }
      }
    },
    mapResponseWithFormData (issuesData) {
      const tempIssuesData = [...issuesData];
      tempIssuesData.forEach((items) => {
        items.isReviewComment = true;
        items.isReplyReviewComment = true;
        items.isEdit = false;
        if(items.remark === null || items.remark === ''){
          items.isShowReviewComment = false
          items.isShowReviewBtn = false
        } else {
          items.isShowReviewComment = true
          items.isShowReviewBtn = true
        }
        if(items.activeStatus === "active") {
          items.isActive = true
        } else {
          items.isActive = false
        }
        if(items.remarkComment === null || items.remarkComment === ''){
          items.isShowReplyReviewComment = false
          items.isShowReviewReplyBtn = false
        } else {
          items.isShowReplyReviewComment = true
          items.isShowReviewReplyBtn = true
        }
      });
      this.issueListData = tempIssuesData;
    },
    getAllMtmIssues (clientId, memberId, documentId) {
      axios
        .get(
          "/mtm/sideeffect/getsideeffects?clientId=" +
          clientId +
          "&memberId=" +
          memberId +"&documentId=" +documentId
        )
        .then((response) => {
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            if (response.data.code === 201) {
              const [year, month, day] = response.data.document.displaySentDate.split("-");
              this.smtForReviewDate = `${month}/${day}/${year}`;
              this.memberName = this.memberDetails.member.firstName +' '+this.memberDetails.member.lastName
              if(this.selectisReview == null || this.selectisReview === false) {
                if(response.data.document?.issuesStatus === 1) {
                  store.dispatch("setSelectDocumenStatus", 'Complete');
                } else {
                  store.dispatch("setSelectDocumenStatus", 'Draft');
                }
              }
              if(response.data.dataLists.length > 0) {
                this.mapResponseWithFormData(response.data.dataLists);
              }
            } else if (response.data.code === 401) {
            this.alertType = "Failed";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
            }
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
          console.log(error);
          store.dispatch("hideLoadingSpinner");
          this.alertType = "Failed";
          this.alertMessage = "Something went wrong";
          this.isHideShowAlertDialog = true;
      });
    },
    getReviewDocument(clientId, memberId, documentId) {
    store.dispatch("showLoadingSpinner");
      axios
      .get( "/pdf/generator?clientId=" +clientId +"&memberId=" +memberId +"&documentId=" +documentId,
        {responseType: "blob"},
      )
      .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            store.dispatch("hideLoadingSpinner");
            this.url = (window.URL || window.webkitURL).createObjectURL(new Blob([response.data], {type: 'application/pdf'}))+"#toolbar=0&navpanes=0&scrollbar=0";
          } else {
            store.dispatch("hideLoadingSpinner");
            this.alertType = "Failed";
            this.alertMessage = "Something went wrong";
            this.isHideShowAlertDialog = true;
          }
        })
        .catch((error) => {
        console.log(error);
        store.dispatch("hideLoadingSpinner");
        this.alertType = "Failed";
        this.alertMessage = "Something went wrong";
        this.isHideShowAlertDialog = true;
      });
    },
    getDocReviewSectionStatus (clientId, reviewerId) {
      axios
      .get('/mtm/review?clientId='+clientId +'&reviewerId='+reviewerId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            if(response.data.dataList?.issuesStatus === 1) {
              store.dispatch("setSelectDocumenStatus", 'Reviewer');
            } else {
              store.dispatch("setSelectDocumenStatus", 'NotReviewer');
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    }
  },
  mounted () {
    this.clientData = this.selectedClient;
    this.memberId = this.selectedMemberId;
    this.documentId = this.selectdocumentId;
    this.memberDetails = this.memberDetail
    interceptorSetup.refreshToken();
    store.dispatch("setSelectDocumenStatus", 'Draft');
    if (this.clientData.clientId !== null && this.memberId && this.documentId !== null) {
      store.dispatch("showLoadingSpinner");
      this.getAllMtmIssues(this.clientData.clientId, this.memberId, this.documentId);
      if(this.selectisReview){
        this.getDocReviewSectionStatus(this.clientData.clientId, this.selectReviewerId)
        setTimeout(() => {
          this.getReviewDocument(this.clientData.clientId , this.memberId, this.documentId) 
        }, 1000);
      }
    }
    if(this.selectisReview == null|| this.selectisReview === false) {
      this.autoSaveByTwoMin();
    }
  },
  unmounted(){ 
   clearInterval(this.interval)
   clearInterval(this.errorTimout);
  }
};
</script>
