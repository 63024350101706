<template>
    <v-container fluid>
      <div class="pd-30">

      <h1 class="heading">Notice of Privacy Practices</h1>
     <!-- Print Button -->
  
     <v-divider></v-divider>
            <div class="pd-16">
                <v-btn v-if="isShow"
                class="mg-right-10"
                    @click="cancel()">
                    Cancel
                </v-btn>
                <v-btn v-if="isShow"
                    @click="print()"
                    class="print-btn">
                    <v-icon
                    size="20"
                    class="mg-right-10"
                    color="white"
                    >mdi-printer-outline
                    </v-icon>
                    <span>Print</span>
                </v-btn>
            </div>
            <v-col class="paragraph" style="padding-left: 0px;">
        <p><i><strong>The Health Insurance Portability, and Accountability Act of 1996 (HIPAA) requires that Member First, Inc. provide all patients with a Notice of Privacy Practices. If you
        have any questions about this notice, contact our Privacy Official at [insert main company phone number]. Please address all written correspondence to Member First, Inc., ATTN: Privacy Official,
        13809 Research Blvd., Suite 500, Austin, TX 78750.</strong></i></p>
        
        <h2><u><strong>Your Information. Your Rights.  Our Responsibilities.</strong></u></h2>
        <p>This notice describes how medical information about you may be used and disclosed and how you can get access to this information. <strong>Please review it carefully.</strong></p>
        
        <h4 class="sub-heading">Your Rights</h4>
        <p>You have a right to:</p>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>Get a copy of your health and claims records</li>
            <li>Correct your health and claims records</li>
            <li>Request confidential communication</li>
            <li>Ask us to limit the information we share</li>
            <li>Get a list of those with whom we’ve shared your information</li>
            <li>Get a copy of this privacy notice</li>
            <li>Choose someone to act for you</li>
            <li>File a complaint if you believe your privacy rights have been violated</li>
          </ul>
          
        <h4 class="sub-heading">Your Choices</h4>
        <p>You have some choices in the way that we use and share information as we:</p>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>Answer questions from your Health Plan or PBM</li>
            <li>Perform auditing of claim submissions from providers to claim processors</li>
          </ul>

        <h4 class="sub-heading">Our Uses and Disclosures</h4>
        <p>We may use and share your information as we:</p>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>Run our organization</li>
            <li>Ensure proper payment for your health services</li>
            <li>Do research</li>
            <li>Comply with the law</li>
            <li>Respond to lawsuits and legal actions</li>
          </ul>

        <h4 class="sub-heading">Your Rights</h4>
        <p><strong>When it comes to your health information, you have certain rights.</strong> This section explains your rights and some of our responsibilities to help you.</p>
        
        <h5>Get a copy of health and claims records</h5>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>You can ask to see or get a copy of your health and claims records and other health information we have about you. Ask us how to do this.</li>
            <li>We will provide a copy or a summary of your health and claims records, usually within 30 days of your request. We may charge a reasonable, cost-based fee.</li>
          </ul>
        
        <h5>Ask us to correct health and claims records</h5>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>You can ask us to correct your health and claims records if you think they are incorrect or incomplete. Ask us how to do this.</li>
            <li>We may say “no” to your request, but we’ll tell you why in writing within 60 days.</li>
          </ul>
        
        <h5>Request confidential communications</h5>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.</li>
            <li>We will consider all reasonable requests, and must say “yes” if you tell us you would be in danger if we do not.</li>
          </ul>
        
        <h5>Ask us to limit what we use or share</h5>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to the date you ask, who we shared it with, and why.</li>
            <li>We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make).
            We’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.</li>
          </ul>
        
        <h5>Get a copy of this privacy notice</h5>
        <p>You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.</p>
        
        <h5>Choose someone to act for you</h5>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.</li>
            <li>We will make sure the person has this authority and can act for you before we take any action.</li>
          </ul>
        
        <h5>File a complaint if you feel your rights are violated</h5>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>You can complain if you feel we have violated your rights by contacting us using the information on page 1.</li>
            <li>You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201,
            calling 1-877-696-6775, or visiting <strong>www.hhs.gov/ocr/privacy/hipaa/complaints/</strong>.</li>
            <li>We will not retaliate against you for filing a complaint.</li>
          </ul>
          
        <h4 class="sub-heading">Your Choices</h4>
        <p><strong>For certain health information, you can tell us your choices about what we share.</strong> If you have a clear preference for how we share your information in the situations
        described below, talk to us. Tell us what you want us to do, and we will follow your instructions.</p>
        <p>In these cases, you have both the right and choice to tell us to:</p>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>Share information with your family, close friends, or others involved in payment for your care</li>
            <li>Share information in a disaster relief situation</li>
          </ul>
        <p class="text-center"><i><u>If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information if we believe it is in your best interest.
        We may also share your information when needed to lessen a serious and imminent threat to health or safety.</u></i></p>
        
        
        <p>In these cases we never share your information unless you give us written permission:</p>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>Marketing purposes</li>
            <li>Sale of your information</li>
          </ul>
      
        <h4 class="sub-heading">Our Uses and Disclosures</h4>
        <p><strong>How do we typically use or share your health information?</strong></p>
        <p>We typically use or share your health information in the following ways.</p>
        
        <h5>Help manage the health care treatment you receive</h5>
        <p>We can use your health information and share it with professionals who are treating you.<br>
        <i>Example: A doctor sends us information about your diagnosis and treatment plan so we can arrange additional services.</i></p>
        
        <h5>Run our organization</h5>
        <p>We can use and disclose your information to run our organization and contact you when necessary.</p>
        
        <h5>Administer your plan</h5>
        <p>We may disclose your health information to your health plan sponsor for plan administration.<br><i>Example: Your company contracts with us to provide information to your health plan, and we
        provide your company with certain statistics.</i></p>
        
        <p><b>How else can we use or share your health information?</b></p>
        <p>We are allowed or required to share your information in other ways – usually in ways that contribute to the public good, such as public health and research. We have to meet many conditions
        in the law before we can share your information for these purposes. For more information see: <u>www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html</u>.</p>
        
        <h5>Help with public health and saftey issues</h5>
        <p>We can share health information about you for certian situations such as:</p>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>Preventing disease</li>
            <li>Helping with product recalls</li>
            <li>Reporting adverse reactions to medications</li>
            <li>Reporting suspected abuse, neglect, or domestic violence</li>
            <li>Preventing or reducing a serious threat to anyone's health or saftey</li>
          </ul>
          
        <h5>Do research</h5>
        <p>We can use or share your information for health research.</p>
        
        <h5>Comply with the law</h5>
        <p>We will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that we’re complying with federal privacy law.</p>
        
        <h5>Address workers’ compensation, law enforcement, and other government requests</h5>
        <p>We can use or share health information about you:</p>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>For workers’ compensation claims</li>
            <li>For law enforcement purposes or with a law enforcement official</li>
            <li>With health oversight agencies for activities authorized by law</li>
            <li>For special government functions such as military, national security, and presidential protective services</li>
          </ul>
          
        <h5>Respond to lawsuits and legal actions</h5>
        <p>We can share health information about you in response to a court or administrative order, or in response to a subpoena.</p>
        
        <h4 class="sub-heading">Our Responsibilities</h4>
          <ul class="mg-b-list pd_left" style="list-style-type:disc;">
            <li>We are required by law to maintain the privacy and security of your protected health information.</li>
            <li>We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information.</li>
            <li>We must follow the duties and privacy practices described in this notice and give you a copy of it.</li>
            <li>We will not use or share your information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing
            if you change your mind.</li>
          </ul>
        <p>For more information see:<u>www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html</u>.</p>
        
        <h4 class="sub-heading">Changes to the Terms of this Notice</h4>
        <p>We can change the terms of this notice, and the changes will apply to all information we have about you. The new notice will be available upon request, on our web site, and we will mail a copy to you.</p>
        
        <div class="col-md-8">
          <h6><v-icon left size="14"> mdi-file </v-icon>Document: 70006 r2.2 / Last Review: 4/28/2020</h6>
        </div>
      </v-col>
      </div>
    </v-container>
  </template>
  <script>
  export default {
    name: "TermsService",
    components: {
    },
    data() {
    return {
      isShow:true,
    };
  },
  computed: {
  },
  methods: {
   cancel(){
    this.$router.push({ name: 'Register'})
   }
   ,
    print()    
    { 
       this.isShow=false
       setTimeout(()=>{
            window.print();
            this.isShow=true;
      },50)
    }
  },
  mounted () {
    // window.print()
  }
  };
  </script>
  <style>
    /* ul > li{
      list-style-type: circle;
    } */
   .content{
      font-style: oblique;
      line-height: 25px;
      font-size: 16px;
      color: #696969;
    }
    .sub-title{
      font-style: oblique;
      line-height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #000000!important;
    }
  .header-logo {
    width: 86px;
    margin: 0px 0px 0px 10rem;
    height: 95px;
  }
  .theme--light.v-btn.v-btn--has-bg {
    background-color: #ffffff;
  }
  .v-btn--is-elevated {
    box-shadow: 0px 0px 0px 0px;
  }
  .container {
    padding: 0px;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 1px 4px;
  }
  .text-center-xs {
    text-align: center !important;
    float: none !important;
  }
</style>
  