<template>
  <v-container fluid>
    <MemberDetailsTopSection/>
    <div>
      <div class="inter-header">Intervention Report</div>
      <div class="mg-top-20">
         <v-row>
          <v-col cols="3">
          </v-col>
          <v-col cols="4">
              <v-row>
              <v-col cols="4">
              </v-col>
              <v-col cols="8">
                  <div class="mg-top-10 mg-left-2rem">
                    <VueDatePicker v-model="interventionDate"
                     auto-apply :enable-time-picker="false" 
                     placeholder="Date" format="MM/dd/yyyy">
                    </VueDatePicker>
                    <p v-if="interventionDateError.isError" class="error-msg">{{ interventionDateError.errorMessage }}</p>
                  </div>
              </v-col>
              </v-row>
          </v-col>
          <v-col cols="3">
          </v-col>
          </v-row>
      </div>
      <div class="mg-top-30">
          <v-row>
              <v-col cols="2">
              </v-col>
              <v-col cols="8">
              <div><b>Intervention</b></div>    
              <v-divider></v-divider>
              <v-row class="mg-top-15">
                  <v-col cols="5">
                      <v-select
                          :items="audienceList"
                          item-value="id"
                          item-title="value"
                          label="Audience"
                          v-model="audienceId"
                          hide-details="auto"
                          density="comfortable"
                          variant="outlined"
                          @update:modelValue="handleAudience"
                          :error="audienceError.isError"
                          :error-messages="audienceError.errorMessage"
                      ></v-select>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5">
                      <v-select
                          :items="categoryList"
                          item-value="id"
                          item-title="value"
                          label="Category"
                          v-model="categoryId"
                          density="comfortable"
                          variant="outlined"
                          :error="categoryError.isError"
                          :error-messages="categoryError.errorMessage"
                          @update:modelValue="handleCategory"
                          ></v-select>
                      </v-col>
              </v-row>
              <v-row class="mg-top-15">
                  <v-col cols="5">
                      <v-select
                      :items="communicationMethodList"
                      item-value="id"
                      item-title="value"
                      label="Communication Method"
                      v-model="communicationMethodId"
                      density="comfortable"
                      variant="outlined"
                      :error="communicationMethodError.isError"
                      :error-messages="communicationMethodError.errorMessage"
                      @update:modelValue="handleCommunicationMethod"
                      ></v-select>
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5">
                      <v-select
                          :items="statusList"
                          item-value="id"
                          item-title="value"
                          label="Status"
                          v-model="statusId"
                          density="comfortable"
                          variant="outlined"
                          :error="statusError.isError"
                          :error-messages="statusError.errorMessage"
                          @update:modelValue="handleStatus"
                          ></v-select>
                      </v-col>
                  </v-row>
              </v-col>
              <v-col cols="2">
              </v-col>
          </v-row>
      </div>
      <div class="mg-top-30">
          <v-row>
              <v-col cols="2">
              </v-col>
              <v-col cols="8">
              <div><b>Medical provider</b></div>    
              <v-divider></v-divider>
              <v-row class="mg-top-15">
                  <v-col cols="5">
                      <v-text-field
                          label="First Name"
                          density="comfortable"
                          v-model="firstName"
                          variant="outlined"
                          :error="firstNameError.isError"
                          :error-messages="firstNameError.errorMessage"
                          @input="handleFirstName"
                      />
                  </v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="5">
                  <v-text-field
                      label="Last Name"
                      v-model="lastName"
                      density="comfortable"
                      variant="outlined"
                      :error="lastNameError.isError"
                      :error-messages="lastNameError.errorMessage"
                      @input="handleLastName"
                      />
                  </v-col>
                  <v-textarea label="Recommendation to Medical Provider"
                    v-model="recMedicationProvider" 
                    class="mg-top-10 mg-left-10 mg-right-10" 
                    variant="outlined"
                    @input="handleRecMedicationProv"
                    :maxlength="1000"
                  ></v-textarea>
                  <div v-if="remainingRecMedicationProviderCharacters <= 999" class="character-count width100">{{ remainingRecMedicationProviderCharacters }} characters remaining</div>
              </v-row>
              </v-col>
              <v-col cols="2">
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="1">
              </v-col> 
              <v-col cols="10">
                <div class="inter-subheader">Impacted Drugs</div>
                <div class="inter-subheader-text">Pick up to Three</div>
                <div v-if="isClaimRxSelectMore" class="error-msg">{{ claimRxSelectMore }}</div>  
                <div :class="{ 'error-border':isClaimRxSelectMore}">
                <div class="table-border table-container" v-if="impactedDrugsList.length > 0">
                  <v-table>
                    <tr v-for="item in impactedDrugsList" :key="index"  style="border-bottom:1px solid #000000">
                      <td>
                        <v-checkbox class="small-checkbox" :disabled="item.isMoreSelected" dense v-model="item.isAdded" 
                          @change="handleSelectImpactedDrug(item.drugGPI, $event)">
                        </v-checkbox>
                      </td>
                      <td><span><b>Medication: </b></span>{{ item.drugName }}</td>
                      <td><span><b> QTY: </b></span>{{ item.qtyDisp }}</td>
                      <td><span><b> DS: </b></span>{{ item.daysSupplyDisp }}</td>
                      <td>
                        <template cols="2" v-if="item.compoundFlag === 'Y'">
                          <b>Cmpnd: &nbsp;</b> {{"Yes" }}
                        </template>
                        <template cols="2" v-if="item.compoundFlag === 'N'">
                          <b>Cmpnd: &nbsp;</b> {{"Yes" }}
                        </template>
                        <template cols="2" v-else>
                          <b>Cmpnd: &nbsp;</b> {{"Unkown" }}
                        </template>
                      </td>
                      <td><span><b> Prescriber Name: </b></span>{{ item.prescrFirstName +" "+item.prescrLastName }}</td>
                      <td><span><b> Last Service Date: </b></span>{{ item.displayDateService }}</td>
                    </tr>
                  </v-table>  
                </div>
               </div>
              </v-col> 
              <v-col cols="1">
              </v-col> 
          </v-row>
          <v-row class="mg-top-15">
              <v-col cols="2">
              </v-col>
              <v-col cols="8">
                  <v-row>
                    <v-textarea label="Clinical Issue Identified" 
                     :error="clinicalIssuesIdentifiedError.isError"
                      :error-messages="clinicalIssuesIdentifiedError.errorMessage"
                      v-model="clinicalIssuesIdentified" rows="4"
                      class="mg-top-20 mg-left-10 mg-right-10"
                      variant="outlined"
                      @input="handleClinicalIssueIdentified"
                      :maxlength="1000"
                    ></v-textarea>
                    <div v-if="remainingClinicalIssuesIdentifiedCharacters <= 999" class="character-count width100">{{ remainingClinicalIssuesIdentifiedCharacters }} characters remaining</div>
                  </v-row>    
                  <v-row>
                     <v-textarea label="Clinical Impact" 
                        v-model="clinicalImpact" rows="4"
                        class="mg-top-20 mg-left-10 mg-right-10"
                        variant="outlined"
                        @input="handleClinicalImpact"
                        :maxlength="1000"
                     ></v-textarea>
                     <div v-if="remainingClinicalImpactCharacters <= 999" class="character-count width100">{{ remainingClinicalImpactCharacters }} characters remaining</div>
                  </v-row>    
                  <v-row>
                     <v-textarea label="Clinical Guidelines Reference"
                     v-model="clinicalGuidelinesRef" 
                     rows="4" class="mg-top-20 mg-left-10 mg-right-10" 
                     variant="outlined"
                     @input="handleClinicalGuidelinesRef"
                     :maxlength="1000"
                     ></v-textarea>
                     <div v-if="remainingClinicalGuidelinesRefCharacters <= 999" class="character-count width100">{{ remainingClinicalGuidelinesRefCharacters }} characters remaining</div>
                  </v-row>    
                  <v-row>
                     <v-textarea label="Response / Outcome"
                     v-model="responseOrOutcome"  rows="4" 
                     class="mg-top-20 mg-left-10 mg-right-10" 
                     variant="outlined"
                     @input="handleResponseOrOutcome"
                     :maxlength="1000"
                     ></v-textarea>
                     <div v-if="remainingResponseOrOutcomeCharacters <= 999" class="character-count width100">{{ remainingResponseOrOutcomeCharacters }} characters remaining</div>
                  </v-row>    
              </v-col>
              <v-col cols="2">
              </v-col>
          </v-row>
          <v-row class="mg-top-15">
              <v-col cols="6">
              </v-col>
              <v-col cols="4">
                <v-row class="mg-left-3rem pd-10" v-if="!isEditIntervention">
                    <v-btn
                      class="mg-left-20"
                      color="gray"
                      @click="gotoInterventionRecords"
                      variant="text">
                      <span style="font-size: 12px">CANCEL</span>
                    </v-btn>
                    <v-btn v-if="!isEditIntervention"
                      style="text-align: right;"
                      class="mg-left-20"
                      color="#2196f3"
                      @click="handleClearInterventionRep"
                      variant="text">
                      <span style="font-size: 12px">CLEAR</span>
                    </v-btn>
                    <v-btn
                      style="float: right;"
                      color="#2196f3">
                      <span style="color: #ffffff; font-size: 12px;" 
                      @click="handleSaveInterventionRep">SAVE INTERVENTION</span>
                    </v-btn>
                  </v-row>
                <v-row v-else style="float:right" class="pd-10">
                  <v-btn
                      color="gray"
                      @click="gotoInterventionRecords"
                      variant="text">
                      <span style="font-size: 12px">CANCEL</span>
                    </v-btn>
                    <v-btn
                      color="#2196f3">
                      <span style="color: #ffffff; font-size: 12px;" 
                      @click="handleSaveInterventionRep">UPDATE INTERVENTION</span>
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="2">
              </v-col>
          </v-row>
      </div>
      </div>
      <div>
      <AlertDialogs
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        :alert-message-type="alertMessageType"
        @closeDialog="closeAlertDialog"
        @onConfirmOK="handleAlertConfirmation"
    />
      <DisplayMemberDetails
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        :alert-message-type="alertMessageType"
        @closeDialog="closeAlertDialog"
        @onConfirmOK="handleAlertConfirmation"
      />
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import MemberDetailsTopSection from "@/components/MemberDetailsTopSection.vue";
import AlertDialogs from "@/components/dialogs/AlertDialogs.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import store from '../store'
import { audienceLists, categoryLists, communicationMethodLists, statusLists } from '../utils/globalConstants'
export default {
  name: "InterventionReport",
  components: {
    AlertDialogs,
    VueDatePicker,
    MemberDetailsTopSection
  },
  data() {
    return {
      maxCharacters: 1000,
      audienceList: [],
      categoryList: [],
      statusList: [],
      communicationMethodList: [],
      impactedDrugsList: [],
      alertType: '',
      alertMessage: '',
      confirmType: '',
      alertMessageType: '',
      interventionDate: '',
      interventionDateError: { isError: false, errorMessage: '' },
      firstName: '',
      firstNameError: { isError: false, errorMessage: '' },
      lastName: '',
      lastNameError: { isError: false, errorMessage: '' },
      category: '',
      status: '',
      clinicalImpact: '',
      responseOrOutcome: '',
      communicationMethod: '',
      recMedicationProvider: '',
      clinicalGuidelinesRef: '',
      clinicalIssuesIdentified: '',
      clinicalIssuesIdentifiedError: { isError: false, errorMessage: '' },
      categoryId: '',
      categoryError: { isError: false, errorMessage: '' },
      audienceId: '',
      audienceError: { isError: false, errorMessage: '' },
      communicationMethodId: '',
      communicationMethodError:{ isError: false, errorMessage: '' },
      statusId: '',
      statusError:{ isError: false, errorMessage: '' },
      isHideShowAlertDialog: false,
      claimRxList: [],
      isClaimRxSelectMore: false,
      claimRxSelectMore: '',
      clientData: undefined, 
      memberId: undefined,
      isEditIntervention: false,
      interventionId: null,
      isAddInterventionReport: false
  };
},
computed: {
  remainingRecMedicationProviderCharacters() {
      return this.maxCharacters - this.recMedicationProvider.length;
  },
  remainingClinicalIssuesIdentifiedCharacters () {
    return this.maxCharacters - this.clinicalIssuesIdentified.length;
  },
  remainingClinicalImpactCharacters () {
    return this.maxCharacters - this.clinicalImpact.length;
  },
  remainingClinicalGuidelinesRefCharacters () {
    return this.maxCharacters - this.clinicalGuidelinesRef.length;
  },
  remainingResponseOrOutcomeCharacters () {
    return this.maxCharacters - this.responseOrOutcome.length;
  },
  selectedMemberId () {
    return store.getters.selectedMemberId
  },
  selectedClient() {
    return store.getters.selectedClient;
  },
  appUser() {
    return store.getters.appUser;
  }
},
methods: {
  gotoInterventionRecords () {
    this.$router.push('/interventionRecords')
  },
  handleAudience (value) {
    this.audienceId = value
    this.audienceError.isError = false
    this.audienceError.errorMessage = ''
  },
  handleCategory (value) {
    this.categoryId = value
    this.categoryError.isError = false
    this.categoryError.errorMessage = ''
  },
  handleCommunicationMethod (value) {
    this.communicationMethodId = value
    this.communicationMethodError.isError = false
    this.communicationMethodError.errorMessage = ''
  },
  handleStatus (value) {
    this.statusId = value
    this.statusError.isError = false
    this.statusError.errorMessage = ''
  },
  handleFirstName (value) {
    this.firstName = value.target.value  
    this.firstNameError.isError = false
    this.firstNameError.errorMessage = ''
  },
  handleLastName (value) {
    this.lastName = value.target.value 
    this.lastNameError.isError = false
    this.lastNameError.errorMessage = '' 
  },
  handleRecMedicationProv (value) {
    this.recMedicationProvider = value.target.value
  },
  handleClinicalIssueIdentified (value) {
    this.clinicalIssuesIdentified = value.target.value
    this.clinicalIssuesIdentifiedError.isError = false
    this.clinicalIssuesIdentifiedError.errorMessage = ''
  },
  handleClinicalImpact (value) {
    this.clinicalImpact = value.target.value
  },
  handleClinicalGuidelinesRef (value) {
    this.clinicalGuidelinesRef = value.target.value
  },
  handleResponseOrOutcome (value) {
    this.responseOrOutcome = value.target.value
  },
  handleClearInterventionRep () {
    this.interventionDate = ''
    this.firstName = ''
    this.lastName = ''
    this.audience = ''
    this.category = ''
    this.status = ''
    this.clinicalImpact = ''
    this.responseOrOutcome = ''
    this.communicationMethod =''
    this.recMedicationProvider = ''
    this.clinicalGuidelinesRef = ''
    this.clinicalIssuesIdentified = ''
    this.claimRxList = []
  },
  handleSaveInterventionRep () {
    let isValid = true
    if (this.interventionDate === '') {
      this.interventionDateError.isError = true
      this.interventionDateError.errorMessage = 'You must enter the Date.'
      isValid = false
    }
    if (this.audienceId === '') {
      this.audienceError.isError = true
      this.audienceError.errorMessage = 'You must enter the Audience.'
      isValid = false
    }
    if (this.categoryId === '') {
      this.categoryError.isError = true
      this.categoryError.errorMessage = 'You must enter the Category.'
      isValid = false
    }
    if (this.communicationMethodId === '') {
      this.communicationMethodError.isError = true
      this.communicationMethodError.errorMessage = 'You must enter the Communication Method.'
      isValid = false
    }
    if (this.statusId === '') {
      this.statusError.isError = true
      this.statusError.errorMessage = 'You must enter the Status.'
      isValid = false
    }
    if (this.firstName === '') {
      this.firstNameError.isError = true
      this.firstNameError.errorMessage = 'You must enter the First Name.'
      isValid = false
    }
    if (this.lastName === '') {
      this.lastNameError.isError = true
      this.lastNameError.errorMessage = 'You must enter the Last Name.'
      isValid = false
    }
    if (this.claimRxList.length === 0) {
      this.isClaimRxSelectMore = true
      this.claimRxSelectMore = 'You must select the Impacted Drugs atleast 1.'
      isValid = false
    }
    if (this.clinicalIssuesIdentified === '') {
      this.clinicalIssuesIdentifiedError.isError = true
      this.clinicalIssuesIdentifiedError.errorMessage = 'You must enter the Clinical Issue Identified.'
      isValid = false
    }
    const interventionRepObj = {
      id: this.interventionId,
      clientId: this.clientData.clientId,
      memberId: this.memberId,
      userId: this.appUser.user.userId,
      interventionDate: this.interventionDate,
      impactedDrugsForm: this.claimRxList,
      audienceId: this.audienceId,
      categoryId: this.categoryId,
      communicationId: this.communicationMethodId,
      status: this.statusId,
      firstName: this.firstName,
      lastName: this.lastName,
      recMedicalProvider: this.recMedicationProvider,
      clinicalIssueIdentified: this.clinicalIssuesIdentified,
      clinicalImpact: this.clinicalImpact,
      clinicalGuidelinesRef: this.clinicalGuidelinesRef,
      responseOrOutcome: this.responseOrOutcome
    }
    if(isValid) {
       this.addOrUpdateInterventionRecord(this.clientData.clientId, interventionRepObj)
    }
  },
  handleSelectImpactedDrug (value, events) {
    let event = events.target.checked
    if(event){
      const index = this.impactedDrugsList.findIndex(item => item.drugGPI === value)
      this.impactedDrugsList[index].isAdded = true
      this.impactedDrugsList[index].isMoreSelected = false
      if(this.claimRxList.length <= 2){
        this.isClaimRxSelectMore = false
        const impactedObj = this.impactedDrugsList[index]
        let compoundFlagFullValue = ""
        if(impactedObj.compoundFlag === "Y"){
          compoundFlagFullValue = "Yes"
        } else if(impactedObj.compoundFlag === "N"){
          compoundFlagFullValue = "No"
        } else {
          compoundFlagFullValue = "Unkown"
        }
        const claimRxObj = {
          rxClaim: impactedObj.drugGPI,
          drugName: impactedObj.drugName,
          qtyDisp: impactedObj.qtyDisp,
          daysSupplyDisp: impactedObj.daysSupplyDisp,
          cmpnd: compoundFlagFullValue,
          prescriberName: impactedObj.prescrFirstName +' '+ impactedObj.prescrLastName,
          lastServiceDate: impactedObj.displayDateService
        }
        this.claimRxList.push(claimRxObj)
      } else {
        const index = this.impactedDrugsList.findIndex(item => item.drugGPI === value)
        this.impactedDrugsList[index].isAdded = false
        this.impactedDrugsList[index].isMoreSelected = true
        const tempImpactedDrugsList = [...this.impactedDrugsList]
          tempImpactedDrugsList.forEach(drugItem => {
            if (!drugItem.isAdded) {
              drugItem.isMoreSelected = true;
            }
          })
        this.impactedDrugsList = tempImpactedDrugsList
        this.isClaimRxSelectMore = true
        this.claimRxSelectMore = "You have selected more than 3 Impacted Drugs. Please select only 3"
      }
    } else {
        const index = this.claimRxList.findIndex(item => item.rxClaim === value)
        if (index !== -1) {
          this.claimRxList.splice(index, 1);
        }
    }
    if(this.claimRxList.length <= 2 ) {
      this.isClaimRxSelectMore = false
      const tempImpactedDrugsList = [...this.impactedDrugsList]
      tempImpactedDrugsList.forEach(item => {
        item.isMoreSelected = false
      })
      this.impactedDrugsList = tempImpactedDrugsList
    }
  },
  addOrUpdateInterventionRecord (clientId, interventionObj) {
    axios
    .post('/clinicians/interRecord/addOrUpdateInterventionRecord?clientId='+clientId, interventionObj)
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 201) {
          this.alertType = 'Success'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
          this.isAddInterventionReport = true
        } else if (response.data.code === 401) {
          this.alertType = 'Failed'
          this.alertMessage = response.data.message
          this.isHideShowAlertDialog = true
       } else {
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
      }
      } else {
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      }
    })
    .catch((error) => {
      console.log(error)
      this.alertType = 'Failed'
      this.alertMessage = 'Something went wrong'
      this.isHideShowAlertDialog = true
    })
  },
  getClaimHist (clientId, memberId) {
    axios
    .get('/clinicians/interRecord/getClaimHist?clientId='+clientId +'&memberId='+memberId)
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 201) {
          if(response.data.dataLists.length > 0) {
            this.impactedDrugsList = this.removeDuplicates(response.data.dataLists, 'drugGPI')
            const tempImpactedDrugsList = [...this.impactedDrugsList] 
            tempImpactedDrugsList.forEach(drugsItems => {
              drugsItems.isAdded = false
            })
            this.impactedDrugsList = tempImpactedDrugsList
          }
        }
      } else {
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      }
    })
    .catch((error) => {
      console.log(error)
      store.dispatch("hideLoadingSpinner");
      this.alertType = 'Failed'
      this.alertMessage = 'Something went wrong'
      this.isHideShowAlertDialog = true
    })
  },
  getInterventionReportById (clientId, memberId, id) {
    axios
    .get('/clinicians/interRecord/getInterventionRecordById?clientId='+clientId+'&memberId='+memberId+'&Id='+id)
    .then(response => {
      if (response.status === 200) {
        if (response.data.code === 201) {
          if(response.data.dataObj !== null) {
            this.mapResponseWithFormData(response.data.dataObj)
          }
        }
      } else {
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      }
    })
    .catch((error) => {
      console.log(error)
      //store.dispatch("hideLoadingSpinner");
      this.alertType = 'Failed'
      this.alertMessage = 'Something went wrong'
      this.isHideShowAlertDialog = true
    })
  },
  mapResponseWithFormData (dataList) {
    const tempInterventionRecord = dataList.interventionRecordObj
    const tempClaimHist = [...dataList.claimHistList]
    tempClaimHist.forEach(items => {
      tempInterventionRecord.impactedDrugs.forEach(items2 => {
          if(items.drugGPI === items2.rxClaim) {
            items.isAdded = true
          }
        });
    });
    this.impactedDrugsList = tempClaimHist
    this.claimRxList = tempInterventionRecord.impactedDrugs
    this.interventionDate = tempInterventionRecord.interventionDate != null ? tempInterventionRecord.interventionDate : ""
    this.audienceId = tempInterventionRecord.audienceId.toString() != null ? tempInterventionRecord.audienceId.toString() : ""
    this.categoryId = tempInterventionRecord.categoryId.toString() != null ? tempInterventionRecord.categoryId.toString() : ""
    this.communicationMethodId = tempInterventionRecord.communicationId.toString() != null ? tempInterventionRecord.communicationId.toString() : ""
    this.statusId = tempInterventionRecord.status.toString() != null ? tempInterventionRecord.status.toString() : ""
    this.firstName = tempInterventionRecord.firstName != null ? tempInterventionRecord.firstName : ""
    this.lastName = tempInterventionRecord.lastName != null ? tempInterventionRecord.lastName : ""
    this.recMedicationProvider = tempInterventionRecord.recMedicalProvider != null ? tempInterventionRecord.recMedicalProvider : ""
    this.clinicalIssuesIdentified = tempInterventionRecord.clinicalIssueIdentified != null ? tempInterventionRecord.clinicalIssueIdentified : ""
    this.clinicalImpact = tempInterventionRecord.clinicalImpact != null ? tempInterventionRecord.clinicalImpact : ""
    this.clinicalGuidelinesRef = tempInterventionRecord.clinicalGuidelinesRef != null ? tempInterventionRecord.clinicalGuidelinesRef : ""
    this.responseOrOutcome = tempInterventionRecord.responseOrOutcome != null ? tempInterventionRecord.responseOrOutcome : ""
    this.impactedDrugsList = this.removeDuplicates(this.impactedDrugsList, 'drugGPI')
  },
  handleInterventionReport () {
    this.statusList = statusLists
    this.audienceList = audienceLists
    this.categoryList = categoryLists
    this.communicationMethodList = communicationMethodLists
    this.clientData = this.selectedClient;
    this.memberId = this.selectedMemberId;
    const id = this.$route.params.id !== "" ? this.$route.params.id : undefined
    if (id !== undefined) {
      this.isEditIntervention = true
      this.interventionId = id
      if(this.clientData.clientId !== null && this.memberId !== null) {
        this.getInterventionReportById(this.clientData.clientId, this.memberId, id)
      }
    } else {
      if(this.clientData.clientId !== null && this.memberId !== null) {
        this.getClaimHist(this.clientData.clientId, this.memberId)
      }
    }
  },
  closeAlertDialog () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      this.confirmType = ''
      if(this.isEditIntervention || this.isAddInterventionReport) {
        this.$router.push('/interventionRecords')
      }
  },
  handleAlertConfirmation () {
    this.isHideShowAlertDialog = false
    this.alertType = ''
    this.alertMessage = ''
  },
  removeDuplicates(list, uniqueProperty) {
    const lookupTable = {}
    return list.filter(obj => {
      const propertyValue = obj[uniqueProperty];
      if (!lookupTable[propertyValue]) {
        lookupTable[propertyValue] = true
        return true
      }
      return false
    })
  },
},
mounted () {
  this.handleInterventionReport ()
}
};
</script>
<style>
  .dp__input {
    border: 1px solid #af9a9a;
    height: 49px;
    padding-left: 2rem;
  }
  .error-border{
    padding: 5px;
    border: 1px solid red;
  } 
.table-container{
  height: 300px; 
  overflow-y: auto; 
}
.table-border{
  border:1px solid #000000;
  margin-top: 15px;
}
.inter-header{
  font-size: 32px;
  color: rgba(0,0,0,0.87);
  letter-spacing: 0;
  line-height: 56px;
  text-align: center;
  margin-top: 20px;
  margin-right: 2rem;
  font-weight: 500;
 } 
 .inter-subheader{
    font-size: 30px;
    color: rgba(0,0,0,0.87);
    letter-spacing: 0;
    line-height: 56px;
    text-align: center;
  } 
 .inter-subheader-text{
    font-size: 14px;
    color: rgba(0,0,0,0.87);
    letter-spacing: 0;
    line-height: 13px;
    text-align: center;
  } 
  td, th {
    border: none!important;
    text-align: left;
    padding: 0px;
    font-size: 13px;
    color: #000000;
  }
  .v-input__details {
    min-height: 0px!important;  
  }
  .v-input--density-default {
    --v-input-control-height: 40px;
    --v-input-padding-top: 8px;
  }
  p, pre, ol, dl, dd, blockquote, table {
    margin-bottom: 0px;
    color: #666;
  }
  tr:nth-child(odd) {
    background-color: rgb(0 75 255 / 13%);
  }

  .width100{
    width: 100%;
    margin-left: 21px;
  }
</style>
