<template>
  <v-container fluid id="interventionReport">
    <MemberDetailsTopSection/>
    <h1 v-if="memberDetail !== null" cols="12" style="display: flex; justify-content: center; color: black; font-weight: 500; padding: 30px 10px 60px;">
      Intervention Records
    </h1>
    <div v-if="memberDetail !== null">
    <div v-if="interventionReport.length > 0">
      <v-row v-for="(report, index) in interventionReport" :key="index">
        <v-card style="margin: 10px 140px; width: 100%; ">
          <v-row style="padding: 0 20px 20px">
            <v-col cols="4" class="calendarPosition">
              <VueDatePicker 
              v-model="report.interventionDate" 
              readonly
              menu-class-name="dp-custom-menu" 
              calendar-class-name="dp-custom-calendar" 
              inline auto-apply :enable-time-picker="false" 
              calendar-cell-class-name="dp-custom-cell" />
            </v-col>
            <v-col cols="8">
              <v-row style="padding-right: 20px;">
                <v-col cols="12" class="text-right">
                  <v-menu bottom left>
                    <template v-slot:activator="{ props }">
                      <v-icon
                        dark
                        icon
                        v-bind="props"
                        class="mg-top-10"
                        size="20"
                        color="black"
                      >
                        mdi-dots-vertical</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item
                        class="dot-item-list"
                      >
                        <v-list-item-title
                          @click="handleIsEdit(report.id)"
                          >{{ "Edit" }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item class="dot-item-list">
                        <v-list-item-title
                          @click="handleDeleteInterventionRecords(report.id)"
                          >{{ "Delete" }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item class="dot-item-list">
                        <v-list-item-title
                          >{{ "Generate PDF" }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <template v-if="!report.isEdit">
                <v-row style="display: flex; justify-content:start" >
                  <v-col cols="3" class="items-text">
                    Status
                  </v-col>
                  <v-col cols="3" class="items-text">
                    Communication Methods
                  </v-col>
                  <v-col cols="3" class="items-text">
                    Audience
                  </v-col>
                  <v-col cols="3" class="items-text">
                    Category
                  </v-col>
                </v-row>
                <v-row style="display: flex; justify-content:start">
                  <v-col cols="3" class="item-data-custom">
                    {{report.statusIntervention}}
                  </v-col>
                  <v-col cols="3" class="item-data-custom">
                    {{report.communicationMethod}}
                  </v-col>
                  <v-col cols="3" class="item-data-custom">
                    {{report.audience}}
                  </v-col>
                  <v-col cols="3" class="item-data-custom">
                    {{report.category}}
                  </v-col>
                </v-row>
                <v-row style="display: flex; justify-content:start" >
                  <v-col cols="3" class="items-text">
                    Medical Provider
                  </v-col>
                </v-row>
                <v-row style="display: flex; justify-content:start">
                  <v-col cols="3" class="item-data-custom">
                    {{report.providerName}}
                  </v-col>
                </v-row>
                <v-row style="display: flex; justify-content:start" >
                  <v-col class="items-text">
                    CLINICAL ISSUE IDENTIFIED
                  </v-col>
                </v-row>
                <v-row style="display: flex; justify-content:start">
                  <v-col cols="8" class="item-data-custom">
                    {{report.clinicalIssueIdentified}}
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </div> 
    <div :class="{'floating-btn-right':isInterventionRecord, 'floating-btn-center': !isInterventionRecord }">
      <v-btn
        class="floating-btn mg-bottom-10"
        color="blue"
        dark
        bottom
        right
        depressed
        fab
        @click="handleCreatInterventionReport">
        <v-icon>mdi-plus</v-icon>CREATE</v-btn
      >
    </div>
  </div>
  <div>
    <AlertDialogs
      v-if="isHideShowAlertDialog"
      :alert-type="alertType"
      :alert-message="alertMessage"
      :alert-message-type="alertMessageType"
      @closeDialog="closeAlertDialog"
      @onConfirmOK="handleAlertConfirmation"
    />
  </div>
  </v-container>
</template>
<script>
import axios from "axios";
import store from '../store'
import VueDatePicker from '@vuepic/vue-datepicker';
import AlertDialogs from "@/components/dialogs/AlertDialogs.vue";
import MemberDetailsTopSection from "@/components/MemberDetailsTopSection.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import { audienceLists, categoryLists, communicationMethodLists, statusLists } from '../utils/globalConstants'
export default {
  name: "InterventionRecord",
  components: {
    VueDatePicker,
    AlertDialogs,
    MemberDetailsTopSection
  },
  data() {
    return {
      initIntervalRef: undefined,
      date: new Date(),
      interventionReport: [],
      audienceList: [],
      categoryList: [],
      statusList: [],
      communicationMethodList: [],
      isInterventionRecord: false,
      clientData: undefined, 
      memberId: undefined,
      alertType: '',
      alertMessage: '',
      confirmType: '',
      isHideShowAlertDialog: false,
      alertMessageType: "",
      interventionReportId: null
    }
  },
  computed: {
    memberDetail() {
      return store.getters.memberDetail;
    },
  selectedMemberId () {
    return store.getters.selectedMemberId
  },
  selectedClient() {
    return store.getters.selectedClient;
  }
},
methods: {
  handleCreatInterventionReport () {
    this.$router.push('/interventionReport')
  },
  handleIsEdit(ids) {
    this.$router.push({ name: 'InterventionReport', params: { id: ids } })
  },
  handleDeleteInterventionRecords (id) {
    this.interventionReportId = id
    this.alertMessage = 'Are you sure you want to delete this item!'
    this.alertType = 'Confirmation'
    this.alertMessageType = 'No'
    this.confirmType = 'remove'
    this.isHideShowAlertDialog = true
  },
  closeAlertDialog () {
    this.interventionReportId = null
    this.isHideShowAlertDialog = false
    this.alertType = ''
    this.alertMessage = ''
    this.alertMessageType = ''
    this.confirmType = ''
  },
  handleAlertConfirmation () {
      this.isHideShowAlertDialog = false
      this.alertType = ''
      this.alertMessage = ''
      if(this.confirmType === 'remove') {
        if(this.clientData.clientId !== null && this.memberId!== null) {
          store.dispatch("showLoadingSpinner");
          this.deleteInterventionRecords(this.clientData.clientId)
        }
      }
    },
  deleteInterventionRecords(clientId) {
    axios
      .delete('/clinicians/interRecord/deleteById?clientId='+clientId +'&Id='+this.interventionReportId)
      .then(response => {
        if (response.status === 200) {
          if (response.data.code === 201) {
            store.dispatch("hideLoadingSpinner");
            const responseIndex = this.interventionReport.findIndex((item) => item.id === this.interventionReportId);
            if (responseIndex !== -1) {
              this.interventionReport.splice(responseIndex, 1);
            }
            if(this.interventionReport.length === 0) {
              this.isInterventionRecord = false
            }
            this.alertType = "Success";
            this.alertMessage = response.data.message;
            this.isHideShowAlertDialog = true;
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
  },
  getInterventionRecord (clientId, memberId) {
    axios
      .get('/clinicians/interRecord/getAllInterventionRecords?clientId='+clientId +'&memberId='+memberId)
      .then(response => {
        if (response.status === 200) {
          store.dispatch("hideLoadingSpinner");
          if (response.data.code === 201) {
            if(response.data.dataList.length > 0) {
              this.isInterventionRecord = true
              this.mapResponseWithFormData(response.data.dataList)
            }
          }
        } else {
          store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        }
      })
      .catch((error) => {
        console.log(error)
        store.dispatch("hideLoadingSpinner");
        this.alertType = 'Failed'
        this.alertMessage = 'Something went wrong'
        this.isHideShowAlertDialog = true
      })
    },
    mapResponseWithFormData (dataList) {
      const tempInterRecordsDataList = [...dataList]
      tempInterRecordsDataList.forEach(items => {
        items.providerName = items.firstName +' '+ items.lastName
        this.audienceList.some(items2 => {
          if(items.audienceId.toString() === items2.id){
            items.audience = items2.value 
          }
        })
        this.categoryList.some(items2 => {
          if(items.categoryId.toString() === items2.id){
            items.category = items2.value 
          }
        })
        this.communicationMethodList.some(items2 => {
          if(items.communicationId.toString() === items2.id){
            items.communicationMethod = items2.value 
          }
        })
        this.statusList.some(items2 => {
          if(items.status.toString() === items2.id){
            items.statusIntervention = items2.value 
          }
        })
      });
      this.interventionReport = tempInterRecordsDataList
    },
    getInterventionRecords() {
      this.audienceList = audienceLists
      this.categoryList = categoryLists
      this.communicationMethodList = communicationMethodLists
      this.statusList = statusLists
      this.clientData = this.selectedClient;
      this.memberId = this.selectedMemberId;
      if(this.clientData.clientId !== null && this.memberId!== null) {
        this.getInterventionRecord(this.clientData.clientId, this.memberId)
      }
    }
},
mounted () {
  store.dispatch("showLoadingSpinner");
  if ( this.selectedClient != null) {
    this.clientData = this.selectedClient
    this.getInterventionRecords()
  } else {
      const intervalRef = setInterval(() => {
        if (this.selectedClient !== null) {
          clearInterval(this.initIntervalRef)
          this.getInterventionRecords()
        }
      }, 1000)
      this.initIntervalRef = intervalRef
 }
}
};
</script>
<style> 
.gap-16 {
  margin-right: 16px
}
.items-text{
  font-size: 11px;
  font-weight: 400;
  color: black;
  text-transform: uppercase;
}
.item-data-custom{
  font-size: 15px;
  padding: 0px 12px 12px;
}
.dp-custom-cell {
  border-radius: 50%;
}
.dp-custom-calendar .dp__calendar_item {
  border: 1px solid #e7e7e7;
}
.dp-custom-calendar .dp__calendar_row {
  margin: 0px !important;
}
.dp-custom-menu {
  box-shadow: 0 0 6px #B1B2B0;
}
.calendarPosition {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
#interventionReport .v-text-field .v-input__details {
  padding-inline-start: 6px; 
  padding-inline-end: 6px;
}
.floating-btn-center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.floating-btn-right{
  margin-top: 6rem;
  margin-right: 4rem;
  float: right;
}
.dp__today {
  border: none;
}
</style>

  