<template>
  <v-tooltip top>
    <template v-slot:activator="{ props }">
      <span v-bind="props" style="display:inline-block;">
        {{ params.value }}
      </span>
    </template>
    <div>
      {{ params.data.pharmacyName }}<br />
      {{ params.data.phaAddressLine1 }}<br />
      <span
        v-if="
          params.data.phaAddressLine2 != null && params.data.phaAddressLine2.length > 0
        "
      >
        {{ params.data.phaAddressLine2 }} <br />
      </span>
      {{ params.data.phaCity }}, {{ params.data.phaState }} {{ params.data.phaZipCode5
      }}<span v-if="params.data.phaZip4 != null && params.data.phaZip4.length > 0"
        >-{{ params.data.phaZip4 }}</span
      ><br />
      {{ formatPhone(params.data.phaTelephoneNbr) }}
    </div>
  </v-tooltip>
</template>
<script>
export default {
  name: "renderPharmacyCell",
  data() {
    return {
    }
  },
  methods: {
    formatPhone(number) {
      if (number == null || number.length == 0) return "";
      if (number.length == 7)
        return number.substr(0, 3) + "-" + number.substr(3, 4);
      if (number.length == 10)
        return (
          "(" +
          number.substr(0, 3) +
          ") " +
          number.substr(3, 3) +
          "-" +
          number.substr(6, 4)
        );
      return number;
    },
  }
};

  </script>