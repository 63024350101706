<template>
  <v-tooltip top>
    <template v-slot:activator="{ props }">
      <span v-bind="props" style="display:inline-block;">
        {{ params.value }}
      </span>
    </template>
    <div>
      {{ params.data.providerNPIName }}<br />
      {{ params.data.providerNPIAddress }}<br />
      {{ params.data.providerNPICity }}, {{ params.data.providerNPIState }}
      {{ params.data.providerNPIZip }} <br />
      Phone: {{ formatPhone(params.data.providerNPIPhone) }}<br />
      Fax: {{ formatPhone(params.data.providerNPIFax) }}<br />
    </div>
  </v-tooltip>
</template>
<script>
export default {
  name: "renderProviderCell",
  data() {
    return {
    }
  },
  methods: {
    formatPhone(number) {
      if (number == null || number.length == 0) return "";
      if (number.length == 7)
        return number.substr(0, 3) + "-" + number.substr(3, 4);
      if (number.length == 10)
        return (
          "(" +
          number.substr(0, 3) +
          ") " +
          number.substr(3, 3) +
          "-" +
          number.substr(6, 4)
        );
      return number;
    },
  }
};

  </script>