<template>
  <v-container fluid>
    <Header />
    <div class="container-register">
      <div class="page-header">
        <div class="member-profile-title">MEMBER PROFILE CONFIRMATION</div>
      </div>
      <v-container>
        <v-row>
          <v-col cols="10"  >
            <div class="confirm-reg ml-4">
              Please review and update your contact information as well as
              let us know the best times to contact you in the tabs below.
              Once it all looks good, select the
              <b>Confirm Registration</b> and our pharmacist will contact
              you to begin your Rx Medication Management.
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-card elevation="2">
            <v-tabs v-model="tabValue"
              id="registerProfileSlider"
              dark
              class="regTab"
              slider-color="light-green"
              selected-class="regTabSelected"
            >
              <v-tab value="0">Contact Infomation</v-tab>
              <v-tab value="1">Best Availability</v-tab>
            </v-tabs>
            <v-card-text>
              <v-window v-model="tabValue">
                <v-window-item value="0">
                  <v-row class="mg-top-10">
                    <v-col cols="4">
                      <v-text-field
                        v-model="firstName"
                        label="First Name"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="phoneNumber"
                        label="Phone Number"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="address"
                        label="Address"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mg-top-10">
                    <v-col cols="4">
                      <v-text-field
                        v-model="middleName"
                        label="Middle Name"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="email"
                        placeholder="name@email.com"
                        label="Email"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="address2"
                        label="Address 2"
                        placeholder="apt 1A"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mg-top-10">
                    <v-col cols="4">
                      <v-text-field
                        v-model="lastName"
                        label="Last Name"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="employerId"
                        label="Employer Carrier ID"
                        variant="outlined"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="city"
                        label="City"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mg-top-10">
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="gender"
                            label="Gender"
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="maritalStatus"
                            label="Marital Status"
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="patientId"
                        disabled
                        label="Patient ID"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="state"
                        label="State"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mg-top-10">
                    <v-col cols="8"> </v-col>
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="zipCode"
                            label="Zip Code"
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1"
                          ><div class="mg-top-15"><b>-</b></div></v-col
                        >
                        <v-col cols="5">
                          <v-text-field
                            v-model="zipPlus"
                            placeholder="0000"
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item value="1">
                  <table>
                    <tr>
                      <td class="row-td-width">
                        You may contact me via email.
                      </td>
                      <td>
                        <v-radio-group
                          row
                          v-model="contactEmail"
                          hide-details
                        >
                          <v-radio label="Yes" value="Yes"></v-radio>
                          <v-radio label="No" value="No"></v-radio>
                        </v-radio-group>
                      </td>
                    </tr>
                    <tr>
                      <td class="row-td-width">
                        You may contact me via telephone.
                      </td>
                      <td>
                        <v-radio-group
                          row
                          v-model="contacttelePhone"
                          hide-details
                        >
                          <v-radio label="Yes" value="Yes"></v-radio>
                          <v-radio label="No" value="No"></v-radio>
                        </v-radio-group>
                      </td>
                    </tr>
                    <tr>
                      <td class="row-td-width">
                        I prefer to be contacted by
                      </td>
                      <td>
                        <v-radio-group
                          row
                          v-model="preferCon"
                          hide-details>
                          <v-radio label="Telephone" value="Telephone"></v-radio>
                          <v-radio label="Email" value="Email"></v-radio>
                        </v-radio-group>
                      </td>
                    </tr>
                    <tr>
                      <td class="row-td-width">
                        Best days to contact me
                      </td>
                      <td>
                        <v-row class="pd-10">
                          <v-checkbox label="Monday" v-model="selectedDay" value="M" hide-details>
                          </v-checkbox>
                          <v-checkbox label="Tuesday" v-model="selectedDay" value="Tu"  hide-details>
                          </v-checkbox>
                          <v-checkbox label="Wednesday" v-model="selectedDay" value="W" hide-details>
                          </v-checkbox>
                          <v-checkbox label="Thursday" v-model="selectedDay" value="Th" hide-details>
                          </v-checkbox>
                          <v-checkbox label="Friday" v-model="selectedDay" value="F" hide-details>
                          </v-checkbox>
                        </v-row>
                      </td>
                    </tr>
                    <tr>
                      <td class="row-td-width">
                        Best times to contact me
                      </td>
                      <td>
                        <v-row class="pd-10">
                          <v-checkbox label="8am to 11am" v-model="selectedTime" value="8a-11a" hide-details>
                          </v-checkbox>
                          <v-checkbox label="11am to 1pm" v-model="selectedTime" value="11a-1p" hide-details>
                          </v-checkbox>
                          <v-checkbox label="1pm to 3pm" v-model="selectedTime" value="1p-3p" hide-details>
                          </v-checkbox>
                          <v-checkbox label="3pm to 5pm" v-model="selectedTime" value="3p-5p" hide-details>
                          </v-checkbox>
                        </v-row>
                      </td>
                    </tr>
                    <tr>
                      <td class="row-td-width">My time zone is</td>
                      <td>
                        <v-radio-group
                          row
                              v-model="myTimeZone"
                              hide-details>
                          <v-radio label="Eastern" value="Eastern"></v-radio>
                          <v-radio label="Central" value="Central"></v-radio>
                          <v-radio label="Mountain" value="Mountain"></v-radio>
                          <v-radio label="Pacific" value="Pacific"></v-radio>
                        </v-radio-group>
                      </td>
                    </tr>
                  </table>
                </v-window-item>
              </v-window>
              <div class="user-reg-text mg-top-10">
                By selecting confirm and completing registration you agree
                that Member First Professionals may receive and create
                personal health information to facilitate your care under
                this program.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                depressed
                color="#ffffff"
                class="confirmBtn"
                @click="handleConfirmRegistration"
              >
                <v-icon left size="15" color="#ffffff">
                  mdi-check
                </v-icon>
                <span >Confirm Registration</span>
              </v-btn>
              <v-btn 
                variant="outlined"
                class="ml-5"
              >
                Cancel 
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-container>
    </div>
    <Footer />
    <div>
      <AlertDialogs
        v-if="isHideShowAlertDialog"
        :alert-type="alertType"
        :alert-message="alertMessage"
        @closeDialog="closeAlertDialog"
      />
    </div>
  </v-container>
</template>
<script>
  import Header from "@/components/Header.vue"
  import Footer from "@/components/Footer.vue"
  import axios from "axios";
  export default {
    name: "RegisterProfile",
    components: {
      Header,
      Footer
    },
    data() {
      return {
        isHideShowAlertDialog: false,
        alertType: "",
        alertMessage: "",
        tabValue: 0,
        registerMemberInfo: undefined,
        firstName:'',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        address: '',
        email: '',
        address2: '',
        employerId: '',
        city:'',
        gender:'',
        maritalStatus:'',
        patientId: '',
        status: '',
        zipCode: '',
        zipPlus: '',
        contacttelePhone: '',
        myTimeZone:'',
        preferCon: '',
        contactEmail:'',
        selectedTime:[],
        selectedDay:[],
        state:'',
      };
    },
    computed: {
    },
    methods: {
      /*
       //   handleBestDays(event){
       //     this.bestDaysContacts = event
       //   },
       //   handleBestTimeCont(event){
       //     this.bestTimeToContacts = event
       //   },
      handleContactEmail (event){
        this.contactEmail = event.target.value
      },
      handleContTelePhone (event){
        this.contactTelePhone = event.target.value
      },
      handlemyTimeZone (event) {
        this.myTimeZone = event.target.value
      },
      handlePreferCon (event) {
        this.preferCon = event.target.value
      },
      handlefirstName (value){
        this.firstName = value.target.value
      },
      handlePhoneNumber(value){
        this.phoneNumber = value.target.value
      },
      handleAddress(value){
        this.address = value.target.value
      },
      handleMiddleName(value){
        this.middleName = value.target.value
      },
      handleEmail(value){
        this.email = value.target.value
      },
      handleAddress2(value){
        this.address2 = value.target.value
      },
      handleLastName(value){
        this.lastName = value.target.value
      },
      handleCity(value){
        this.city = value.target.value
      },
      handleGender(value){
        this.gender = value.target.value
      },
      handleMaritalStatus(value){
        this.maritalStatus = value.target.value
      },
      handleState(value){
        this.state = value.target.value
      },  
      handleZipCode(value){
        this.zipCode = value.target.value
      },
      handleZipPlus(value){
        this.zipPlus = value.target.value
      },
      */
      closeAlertDialog () {
        this.isHideShowAlertDialog = false
        this.alertType = ''
        this.alertMessage = ''
      },
      handleConfirmRegistration(){
        const obj={
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          gender: this.gender,
          maritalStatus:  this.status,
          phone: this.phoneNumber,
          email:this.email ,
          clientCode: 'SDMB',
          patientId: this.patientId,
          address1: this.address,
          address2:this.address2 ,
          city: this.city,
          state: this.state ,
          zip: this.zipCode,
          zipPlus: this.zipPlus,
          phoneConfirm: this.contacttelePhone,
          emailConfirm: this.contactEmail,
          contactPreference: this.prefertoCon,
          contactDays:this.selectedDay.toString(),
          contactTimes: this.selectedTime.toString(),
          timeZone: this.time_Zone,
          memberId: this.registerMemberInfo.mId,
          dateOfBirth: this.registerMemberInfo.dBirth,
          enrollmentCode: this.employerId,
        }
        console.log(obj)
        axios
        .post('/auth/signup', obj)
        .then(response => {
          if (response.status === 200) {
            if (response.data.code === 201) {
              this.isUserEdit = true
              localStorage.signupUsername = JSON.stringify(response.data)
              this.$router.push({ name: 'SignUp'})
              //store.dispatch("hideLoadingSpinner");
              // this.alertType = 'Success'
              // this.alertMessage = "Member Register Successfully"
              // this.isHideShowAlertDialog = true 
            } else if (response.data.code === 401) {
              //store.dispatch("hideLoadingSpinner");
              this.alertType = 'Failed'
              this.alertMessage = "Member Register Unsuccessful"
              this.isHideShowAlertDialog = true
            }else{
              this.alertType = 'Failed'
              this.alertMessage ='Something went wrong'
              this.isHideShowAlertDialog = true
            }
          } else {
            this.alertType = 'Failed'
            this.alertMessage = 'Something went wrong'
            this.isHideShowAlertDialog = true
          }
        })
        .catch((error) => {
          console.log(error)
          //store.dispatch("hideLoadingSpinner");
          this.alertType = 'Failed'
          this.alertMessage = 'Something went wrong'
          this.isHideShowAlertDialog = true
        })
      }
    },
    mounted () {
      // this.registerMemberInfo = JSON.parse(this.$route.params.userInfo)
       this.registerMemberInfo = JSON.parse(localStorage.regprofile)
       console.log(this.registerMemberInfo)
       if(this.registerMemberInfo !== undefined){
         this.firstName = this.registerMemberInfo.mFName
         this.lastName = this.registerMemberInfo.mLName
         this.middleName= this.registerMemberInfo.mMName
         this.status = this.registerMemberInfo.mMStatus
         this.gender = this.registerMemberInfo.mGend
         this.phoneNumber = this.registerMemberInfo.mPhone
         this.email = this.registerMemberInfo.mEmail
         this.address = this.registerMemberInfo.mAddress1
         this.address2 = this.registerMemberInfo.mAddress2
         this.city = this.registerMemberInfo.mCity
         this.state = this.registerMemberInfo.mState
         this.zipCode = this.registerMemberInfo.mZip
         this.employerId = this.registerMemberInfo.eCode
         this.maritalStatus = this.registerMemberInfo.mMStatus
         this.patientId = this.registerMemberInfo.mPatientId
         this.zipPlus = this.registerMemberInfo.mZipPlus !== null ? this.registerMemberInfo.mZipPlus : '0000'
       }
    }
  };
  </script>
  <style>
  .main-section {
    display: block;
    position: relative;
    padding: 100px 0;
    border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
    background-color: #fff;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    background-size: cover !important;
    box-sizing: border-box !important;
    background: url(../assets/grain_bg.png) no-repeat center;
  }
  .display-table {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
  }
  .vertical-align-middle {
    vertical-align: middle;
  }
  .display-table-cell {
    display: table-cell;
    width: 100%;
    height: 100%;
  }
  h2 {
    font-size: 30px;
    margin: 0 0 32px 0;
  }
  p,
  pre,
  ol,
  dl,
  dd,
  blockquote,
  table {
    margin-bottom: 30px;
    color: #666;
  }
  .confirmBtn {
    background-color:#8ab933;
  }
  .term-service:hover {
    color: #000000;
    cursor: pointer;
  }
  .pres-mag-prog > li {
    color: gray;
    font-weight: 300;
  }
  .complete-reg > span {
    color: gray;
  }
  .sub-title-reg {
    font-style: oblique;
    color: #666;
  }
  .v-input__icon--append .v-icon {
    font-size: 18px;
  }
  /* Registration Profile */
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 5%), 5px -3px 0px 0px rgb(0 0 0 / 0%),
      0px 1px 5px 0px rgb(0 0 0 / 0%);
  }
  #registerProfileSlider .v-tabs-slider-wrapper {
    top: 0;
    margin: 0 !important;
    position: absolute;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    z-index: 1;
  }
  .regTab button{
    background-color: #f4f4f4;
  }
  .regTab button.regTabSelected {
    color: #8ab933;
    background-color: #ffffff;
  }
  .member-profile-title {
    margin-left: 10rem;
    padding: 0;
    font-size: 26px;
    font-weight: 300;
  }
  .page-header {
    position: relative;
    padding: 50px 0 50px 0;
    margin-bottom: 0 !important;
    background-color: rgba(148, 149, 150, 0.05);
    border-bottom: 0;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
  }

  .container-register{
    position: relative;
    top:100px;
    margin-bottom:120px;
  }
  .confirm-reg {
    color: #666;
    background-color: #fff;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.5;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    border: 1px solid #baadad54;
    text-align: left;
    padding: 9px;
  }
  .row-td-width {
    width: 330px;
    color: #333;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
  }
  
  tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .v-input {
    margin-left: 11px;
  }
  .mt-20{
    margin-top: 20px;
  }
  .v-input--selection-controls .v-input__slot >
   .v-label, .v-input--selection-controls .v-radio >
    .v-label {
    font-weight: 500 !important;
    color: black !important;
  }
  </style>
  
