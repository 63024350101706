<template>
    <div style="width:100%; height:100%; position:relative;">
    <ag-grid-vue
      class="ag-theme-alpine"
      style="width:100%; height: 500px;"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="claims"
      :pagination="true"
      @grid-ready="onGridReady"
      :paginationPageSize="selectedPageSize"
      :groupDisplayType="groupDisplayType"
      :rowSelection="rowSelection"
    >
    </ag-grid-vue>
    <v-col cols="4" sm="3" md="2" lg="1" offset="8" offset-sm="9" offset-md="10" offset-lg="11" 
      class="mt-1">
      <v-select
        :items="pageSizeOptions"
        :value="selectedPageSize"
        @update:modelValue="changePageSize"
        label="Rows per Page"
        variant="outlined"
        density="comfortable"
        hide-details="auto"
        dense
      ></v-select>
    </v-col>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import store from "@/store";
import renderProviderCell from "./renderers/renderProviderCell.vue";
import renderICD10Cell from "./renderers/renderICD10Cell.vue";

export default {
  name: "MedicalClaims",
  components: {
    AgGridVue,
    renderProviderCell,
    renderICD10Cell
  },
  props: ["filter"],
  data() {
    return {
      groupDisplayType: 'groupRows',
      rowSelection: 'single',
      columnDefs: [
      {
          groupId: "date",
          headerName: "",
          marryChildren: true,
          children: [
            {headerName: "Service Date",field: "serviceDate" }
          ]
        },{
          headerName: "Service Details",
          children: [
            {headerName: "Medical Condition",field: "medCondDesc", minWidth: 280, columnGroupShow: ["open","closed"]},
            {headerName: "Procedure Type",field: "procedureType", columnGroupShow: "open" },
            {headerName: "Total Paid Amt",field: "totalPaidAmt", valueFormatter: currencyFormatter, columnGroupShow: "open"  },
            {headerName: "Patient Cost Share",field: "patientCostShare", valueFormatter: currencyFormatter, columnGroupShow: "open"  },
            {headerName: "Procedure Code",field: "procedureCode", columnGroupShow: "open" },
            {headerName: "Plan Name",field: "planName", minWidth: 280, columnGroupShow: "open" },
            /*
            {headerName: "Service End",field: "serviceEndDate"},
            {headerName: "Procedure Start",field: "procedureStartDate"},
            {headerName: "Procedure End",field: "procedureEndDate"},
            {headerName: "Discharge Date",field: "dateofDischarge"},
            */
          ]
        },{
          headerName: "Diagnosis",
          children: [
            {headerName: "ICD10-01",field: "icd10_01", cellRenderer: "renderICD10Cell", columnGroupShow: ["open","closed"]},
            {headerName: "ICD10-02",field: "icd10_02", cellRenderer: "renderICD10Cell", columnGroupShow: ["open","closed"]},
            {headerName: "ICD10-03",field: "icd10_03", cellRenderer: "renderICD10Cell", columnGroupShow: ["open","closed"] },
            {headerName: "ICD10-04",field: "icd10_04", cellRenderer: "renderICD10Cell", columnGroupShow: ["open","closed"] },
            {headerName: "ICD10-05",field: "icd10_05", cellRenderer: "renderICD10Cell", columnGroupShow: "open" },
            {headerName: "ICD10-06",field: "icd10_06", cellRenderer: "renderICD10Cell", columnGroupShow: "open" },
            {headerName: "ICD10-07",field: "icd10_07", cellRenderer: "renderICD10Cell", columnGroupShow: "open" },
            {headerName: "ICD10-08",field: "icd10_08", cellRenderer: "renderICD10Cell", columnGroupShow: "open" },
            {headerName: "ICD10-09",field: "icd10_09", cellRenderer: "renderICD10Cell", columnGroupShow: "open" },
            {headerName: "ICD10-10",field: "icd10_10", cellRenderer: "renderICD10Cell", columnGroupShow: "open" },
            {headerName: "ICD10-11",field: "icd10_11", cellRenderer: "renderICD10Cell", columnGroupShow: "open" },
            {headerName: "ICD10-12",field: "icd10_12", cellRenderer: "renderICD10Cell", columnGroupShow: "open" }
          ]
        },{
          headerName: "Provider",
          children: [
            {headerName: "Provider Name",field: "providerName", minWidth: 280, cellRenderer: "renderProviderCell", columnGroupShow: ["open","closed"] },
            {headerName: "Provider NPI",field: "providerNPI", cellRenderer: "renderProviderCell", columnGroupShow: "open"},
            {headerName: "Provider Specialty",field: "providerSpecialty", columnGroupShow: "open"}
          ]
        }
        /*
        ,{
          headerName: "Member",
          children: [
            {headerName: "Member Id",field: "patientMemberId"},
            {headerName: "Patient First Name",field: "patientFirstName", valueFormatter: privacyFormatter},
            {headerName: "Patient Last Name",field: "patientLastName", valueFormatter: privacyFormatter},
            {headerName: "Patient Middle Name",field: "patientMiddleName", valueFormatter: privacyFormatter},
            {headerName: "Patient Name Suffix",field: "patientNameSuffix", valueFormatter: privacyFormatter},
            {headerName: "Patient DOB",field: "patientDateOfBirth", cellRenderer: params => { return (this.privacyMode==true) ? this.showAge(params.value) : params.value ; }}
          ]
        }
        //{ headerName: "Client Id",field: "clientId"},
        //{ headerName: "Batch Id",field: "batchId"},
        //{ headerName: "Med Claim Id",field: "medClaimId"},
        //{ headerName: "Date Created",field: "dateCreated"},
        */
      ],    
      selectedPageSize: 25,
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        editable: false,
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1,
        minWidth: 120,
        /*enableValue: true,
        enableRowGroup: true,
        enablePivot: true*/
      }
      /*
        ,sideBarDef: {
          toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressRowGroups: false,
                  suppressPivotMode: false,
                  suppressValues: false
                },
                minWidth: 225,
                maxWidth: 225,
                width: 225
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                minWidth: 180,
                maxWidth: 400,
                width: 250
            }
          ],
          position: 'right'
        }
        ,groupDisplayType: 'groupRows'
      */
    }
  },
  methods: {
    formatPhone(number) {
      if (number == null || number.length == 0) return "";
      if (number.length == 7)
        return number.substr(0, 3) + "-" + number.substr(3, 4);
      if (number.length == 10)
        return (
          "(" +
          number.substr(0, 3) +
          ") " +
          number.substr(3, 3) +
          "-" +
          number.substr(6, 4)
        );
      return number;
    },
    showAge(dob) {
      if (dob !== undefined) {
      var year = Number(dob.substr(0, 4));
      var month = Number(dob.substr(4, 2)) - 1;
      var day = Number(dob.substr(6, 2));
      var today = new Date();
      var age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() == month && today.getDate() < day)
      ) {
        age--;
      }
      if (age < 2) {
        age = today.getMonth() - month;
        if (today.getDate() < day) {
          age--;
        }
        return age + " months";
      }
      return age + " years";
    }
    },
    changePageSize(value) {
      this.selectedPageSize = value;
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }
  },
  computed: {
    claims() {
      return store.getters.memberDetail.medClaims;
    },
    privacyMode() {
      return store.getters.privacyMode;
    },
    pageSizeOptions() {
      var sizes = [25];
      var numclaims = this.claims.length;
      if(numclaims > 25) sizes.push(50);
      if(numclaims > 50) sizes.push(100);
      if(numclaims > 100) sizes.push(numclaims);
      return sizes;
    }
  },
  watch: {
    privacyMode() {
      this.gridApi.refreshCells({force:true});
    }
  }
};
</script>
<style>
.ag-theme-alpine .ag-row.highlighted-row {
  background-color: yellow; /* You can change this to the color you prefer */
}
</style>
