<template>

  <div id="footer" height="300">
      <div class="container">
        <v-row>
          <v-col cols="3">
            <h2 class="fs-20 mg-left-2rem">Contact Us</h2>
            <!-- Contact Address -->
            <address class="mg-top-15">
              <ul class="list-unstyled">
                <li class="footer-sprite address">
                  <v-row class="flex-nowrap">
                  <v-icon size="20" color="white" class="mg-top-10"> mdi-map-marker </v-icon>
                  <v-col><div>13809 Research Blvd.</div>
                  <div>Suite 500</div>
                <div>Austin, TX 78750</div></v-col>
              </v-row>
                </li>
                <li class="footer-sprite phone">
                <v-row class="flex-nowrap">
                  <v-icon size="20" color="white" class="mg-top-10"> mdi-phone </v-icon>
                  <span style="padding: 12px;"> Phone: 888.627.3778</span>
                </v-row>
                </li>
                <li class="footer-sprite email">
                  <v-row class="flex-nowrap">
                  <v-icon size="20" color="white" class="mg-top-10"> mdi-email </v-icon>
                  <span style="padding: 12px;"> <a href="mailto:support@memberfirstpharmacy.com"
                    >support@memberfirstpharmacy.com</a
                  ></span>
                </v-row>
                </li>
              </ul>
            </address>
            <!-- /Contact Address -->
          </v-col>
        </v-row>
      </div>
      <div class="copyright">
        <div class="container">
          <ul class="float-right m-0 list-inline mobile-block">
            <li><a href="/terms-service" target="_blank">Terms of Service</a></li>
            <li>&bull;</li>
            <li><a href="/privacy" target="_blank">Privacy</a></li>
            <li>&bull;</li>
            <li>
              <a href="/nopp" target="_blank">Notice of Privacy Practices</a>
            </li>
          </ul>
          &copy; All Rights Reserved, Member First, Inc.
        </div>
      </div>
    </div>
    <!-- <div style="width: 100%;">
      <v-icon size="40" color="black" class="chevron_icon">mdi-chevron-double-up</v-icon>
  </div> -->
</template>
<script>
export default {
name: "Footer",
components: {
},
data() {
return {
};
},
computed: {
},
methods: {
},
mounted () {
}
};
</script>
<style>
/* .v-toolbar__content,
.v-toolbar__extension {
padding: 1px 4px;
} */
.text-center-xs {
text-align: center !important;
float: none !important;
}
#footer{
position: relative;
}
#footer > .container {
padding-top: 60px;
margin-bottom: 60px;
}
#footer address .footer-sprite.address {
background-position: 0 0;
}
#footer address .footer-sprite {
margin-bottom: 20px;
padding-left: 0px;
}
.copyright {
background-color: #222222f7;
text-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
padding: 25px 0;
font-size: 13px;
display: block;
color: rgba(255, 255, 255, 0.6);
}
#footer ul {
margin-bottom: 0;
}
ul.list-inline > li {
display: inline-block;
}
.list-inline > li {
display: inline-block;
padding-right: 5px;
padding-left: 5px;
}
#footer a {
color: rgba(255, 255, 255, 0.6);
text-decoration: none;
}
.copyright a {
color: rgba(255, 255, 255, 0.6) !important;
text-decoration: none;
}
#footer h1,
.footer h2,
.footer h3,
.footer h4,
.footer h5,
.footer h6 {
color: rgba(255, 255, 255, 0.8);
font-weight: 600;
}
#footer address .footer-sprite.address {
background-position: 0 0;
}
#footer {
font-size: 14px;
color: rgba(255, 255, 255, 0.6);
display: block;
background: #313131;
background: -moz-linear-gradient(top, #555555 0%, #313131 100%);
background: -webkit-gradient(
  linear,
  left top,
  left bottom,
  color-stop(0%, #1a1d2b),
  color-stop(100%, #313131)
);
background: -webkit-linear-gradient(top, #555555 0%, #313131 100%);
background: -o-linear-gradient(top, #555555 0%, #313131 100%);
background: -ms-linear-gradient(top, #555555 0%, #313131 100%);
background: linear-gradient(to bottom, #555555 0%, #313131 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#555555', endColorstr='#313131',GradientType=0 );
}
#footer address {
background: url(../assets/world-map.png) no-repeat center;
}
.list-unstyled {
padding-left: 0;
list-style: none;
}
.fs-20 {
font-size: 20px !important;
}
#footer h2 {
font-size: 26px;
text-shadow: rgb(0 0 0 / 30%) 3px 3px 5px;
}
.text-center-xs {
text-align: center !important;
float: none !important;
}
v-btn__content {
font-weight: 100;
}
.page-header {
position: relative;
padding: 50px 0 50px 0;
border-top: 0;
margin-top: 0;
margin-bottom: 0 !important;
background-color: rgba(148,149,150,0.05);
border-bottom: 0;
-webkit-transition: all 0s;
-moz-transition: all 0s;
-o-transition: all 0s;
transition: all 0s;
}
</style>
